import { Form, Radio, Select } from "antd";
import DraggableModal from "../../DraggableModal";
import { useEffect, useMemo, useState } from "react";
import { TBreak, ISurfaceBreak } from "../../../Entities/Database";
import { nameof } from "../../../System/Utils";
import { useForm } from "antd/es/form/Form";
import { addBreakModalTitles } from "./locale/ru";
import { RangePicker } from "../../shared/TimePicker";
import { ICustomBreakRange, rangeToDates } from "../CustomBreaksListEditor/helpers";

import "./style.css";

export interface IAddBreakModalLocale {
    title: string,
    duration: string,
    type: string,
    paid: string,
    offsetRange: string,
}

export enum Paid {
    NotPaid = 0,
    Paid = 1,
}

export interface INewBreakForm {
    duration: ISurfaceBreak['durn'],
    type: ISurfaceBreak['type'],
    paid: Paid,
}

export interface INewBreak extends INewBreakForm {
    range: ICustomBreakRange | null,
}

export interface IBreakModalEditorProps {
    open: boolean,
    data?: ISurfaceBreak[],
    locale?: IAddBreakModalLocale,
    loading?: boolean,
    saving?: boolean,
    breakItem?: TBreak,
    breakRange?: ICustomBreakRange,
    onCancel?: () => void,
    onChange?: (breakItem: TBreak, updated: INewBreak) => void,
    onCreate?: (newBreak: INewBreak) => void,
}

export function BreakModalEditor({
    onCancel,
    onChange,
    onCreate,
    open,
    data,
    breakItem,
    breakRange,
    locale = addBreakModalTitles,
    loading,
    saving,
}: IBreakModalEditorProps) {

    const [internalType, setInternalType] = useState<ISurfaceBreak['type']>();
    const [form] = useForm<INewBreakForm>();
    const [titles, durations] = useMemo(() => {

        const titles    = new Map<ISurfaceBreak['type'], { label: ISurfaceBreak['title'], value: ISurfaceBreak['type']}>();
        const durations = new Map<ISurfaceBreak['type'], { label: ISurfaceBreak['durn'], value: ISurfaceBreak['durn']}[]>();

        data?.forEach(x => {

            if (!titles.has(x.type)) {
                titles.set(x.type, { label: x.title, value: x.type });
            }

            if (breakRange) {

                const maxDurn = breakRange.rightOffset - breakRange.leftOffset;

                if (x.durn > maxDurn) {
                    return;
                }
            }

            let durationsArr = durations.get(x.type);
            if (!durationsArr) {
                durationsArr = [];
                durations.set(x.type, durationsArr);
            }

            durationsArr.push({
                label: x.durn,
                value: x.durn
            })
        });

        return [Array.from(titles.values()), durations];

    }, [data, breakRange]);

    const datesRange = useMemo(() => breakRange ? rangeToDates(breakRange) : undefined, [breakRange]);

    const handleFormChange = (changedValues: any, values: any) => {
        if (Object.hasOwn(changedValues, nameof<INewBreak>("type"))) {
            setInternalType(values.type);
            form.setFieldsValue({
                [nameof<INewBreak>("duration")]: durations.get(values.type)![0].value,
            });
        }
    };

    const handleOk = () => {
        form.submit();
    }

    const handleFinishForm = (values: INewBreakForm) => {

        const newBreakValues: INewBreak = {
            ...values,
            range: breakRange || null,
        }

        if (breakItem) {
            
            if (onChange) {
                onChange(breakItem, newBreakValues);
            }

            return;
        }

        if (onCreate) {
            onCreate(newBreakValues);
        }
    }

    useEffect(() => {

        if (open) {

            form.resetFields();

            if (breakItem) {

                form.setFieldsValue({
                    [nameof<INewBreak>("duration")]: breakItem.durn,
                    [nameof<INewBreak>("type")]: breakItem.type,
                    [nameof<INewBreak>("paid")]: breakItem.isPayed ? Paid.Paid : Paid.NotPaid,
                });

                setInternalType(breakItem.type);
                return;
            }

            if (titles.length > 0) {

                const firstKey = titles[0].value;
                const durectionItem = durations.get(firstKey);

                // Пользователь может задать не доступную продолжительность
                if (durectionItem) {

                    const duration = durectionItem![0].value;
                    form.setFieldsValue({
                        [nameof<INewBreak>("duration")]: duration,
                        [nameof<INewBreak>("type")]: firstKey,
                        [nameof<INewBreak>("paid")]: Paid.Paid
                    });

                    setInternalType(firstKey);
                    return;
                }
            }

            setInternalType(undefined);
        }

    }, [open, breakItem]);

    return ( 
        <DraggableModal
            open={open}
            onOk={handleOk}
            onCancel={onCancel}
            width={361}
            className="breakModalEditor"
            title={breakItem ? "Редактирование перерыва" : "Добавление перерыва"}
            okText={breakItem ? "Изменить" : "Добавить"}
            closable={!saving}
            keyboard={!saving}
            okButtonProps={{
                loading: saving,
                disabled: loading,
            }}
            cancelButtonProps={{
                disabled: saving,
            }}
        >
            <Form
                form={form}
                disabled={saving}
                layout="vertical"
                onValuesChange={handleFormChange}
                onFinish={handleFinishForm}
            >
                {datesRange &&
                <Form.Item
                    label={locale.offsetRange}
                >
                    <RangePicker
                        style={{width: "100%"}}
                        inputReadOnly={true}
                        disabled={true}
                        value={datesRange}
                    />
                </Form.Item>}
                <Form.Item
                    label={locale.duration}
                    name={nameof<INewBreakForm>("duration")}
                    rules={[{
                        required: true,
                        message: "Выберите длительность перерыва"
                    }]}
                >
                    <Select
                        style={{width: "100%"}}
                        options={internalType !== undefined ? durations.get(internalType) : []}
                    />
                </Form.Item>
                <Form.Item
                    
                    label={locale.type}
                    name={nameof<INewBreakForm>("type")}
                    rules={[{
                        required: true,
                        message: "Выберите тип перерыва"
                    }]}
                >
                    <Select
                        style={{width: "100%"}}    
                        options={titles}
                    />
                </Form.Item>
                <Form.Item
                    label={locale.paid}
                    name={nameof<INewBreakForm>("paid")}
                    rules={[{
                        required: true,
                        message: "Выберите тип оплаты перерыва"
                    }]}
                >
                    <Radio.Group
                        style={{width: "100%"}}
                    >
                        <Radio.Button 
                            value={Paid.Paid}
                            style={{width: "50%"}} 
                        >
                            Оплачиваемый
                        </Radio.Button>
                        <Radio.Button 
                            value={Paid.NotPaid}
                            style={{width: "50%"}} 
                        >
                            Неоплачиваемый
                        </Radio.Button>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </DraggableModal>
    )
}