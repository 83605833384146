import { useEffect, useState } from "react";
import useAuth from "../../Hooks/useAuth";
import useEntityLoader from "../../Hooks/useEntityLoader";
import DraggableModal from "../DraggableModal";
import { abortShiftTradeRequest, getShiftTradeRequestList, IShiftTradeRequest } from "./index.db";
import moment, { Moment } from "moment";
import { Badge, Button, Popconfirm, Space, Table, Tooltip } from "antd";
import { delay, nameOf } from "../../System/Utils";
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, CloseOutlined, LoadingOutlined, QuestionCircleOutlined, UsergroupAddOutlined, UserOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import useActual from "../../Hooks/useActual";
import { ShiftTradeRequestDetail } from "./detail";

export interface IShiftTradeRequestListModalProps {
    open?: boolean,
    onCancel?: () => void,
}

export function ShiftTradeRequestListModal({
    open,
    onCancel,
}: IShiftTradeRequestListModalProps) {

    const auth = useAuth();
    const [openResponsesModal, setOpenResponsesModal] = useState(false);
    const [selectedRequest, setSelectedRequest] = useState<IShiftTradeRequest>();
    const [range, setRange] = useState<[Moment, Moment]>(() => [
        moment().subtract(1, 'month').startOf('month'),
        moment().add(1, 'month').endOf('month')
    ]);
    const [requestListState, reloadRequestList] = useEntityLoader(x => getShiftTradeRequestList(auth, range, x), [auth.data?.staticId, range]);

    const handleUpdate = () => {
        reloadRequestList();
    }

    const handleCancel = () => {

        if (onCancel) {
            onCancel();
        }
    }

    const handleCancelResponsesModal = () => {
        setOpenResponsesModal(false);
    }

    useEffect(() => {

        if (open) {
            return reloadRequestList();
        }

    }, [open]);

    const columns: ColumnsType<IShiftTradeRequest> = [/*{
        title: 'Id',
        dataIndex: nameOf<IShiftTradeRequest>('id'),
    },*/ {
        title: 'Дата',
        render: (value: any, item: IShiftTradeRequest) => item.dateSlot?.format("YYYY-MM-DD"),
        sorter: (a, b) => +b.dateSlot - +a.dateSlot,
    }, {
        title: 'Интервал',
        dataIndex: nameOf<IShiftTradeRequest>('intervalSlot'),
    }, {
        title: 'Создатель',
        dataIndex: nameOf<IShiftTradeRequest>('creator'),
        sorter: (a, b) => b.creator.localeCompare(a.creator),
    }, {
        title: 'Проектная группа',
        dataIndex: nameOf<IShiftTradeRequest>('projectGroup'),
        sorter: (a, b) => b.projectGroup.localeCompare(a.projectGroup),
    }, {
        title: 'Коэффициент',
        dataIndex: nameOf<IShiftTradeRequest>('k'),
        sorter: (a, b) => b.k - a.k,
    }, {
        title: 'Актуально до',
        render: (value: any, item: IShiftTradeRequest) => item.dtValid.format("YYYY-MM-DD HH:mm"),
        sorter: (a, b) => +b.dtValid - +a.dtValid,
    }, {
        title: <Tooltip title="Количество слотов"><UserOutlined /></Tooltip>,
        showSorterTooltip: false,
        dataIndex: nameOf<IShiftTradeRequest>('qtySlots'),
        sorter: (a, b) => b.qtySlots - a.qtySlots,
        render: (value, record, index) => {
            return <Badge count={record.qtySlots} color="magenta" />
        },
    }, {
        title: <Tooltip title="Количество кандидатов"><UsergroupAddOutlined /></Tooltip>,
        showSorterTooltip: false,
        dataIndex: nameOf<IShiftTradeRequest>('qtyPotentcial'),
        sorter: (a, b) => b.qtyPotentcial - a.qtyPotentcial,
        render: (value, record, index) => {
            return <Badge count={record.qtyPotentcial} color="blue" />
        },
    }, {
        title: <Tooltip title="Количество подтверждений ожидаемых сотрудниками"><ClockCircleOutlined /></Tooltip>,
        showSorterTooltip: false,
        dataIndex: nameOf<IShiftTradeRequest>('qtyResponseAwait'),
        sorter: (a, b) => b.qtyResponseAwait - a.qtyResponseAwait,
        render: (value, record, index) => {
            return <Badge count={record.qtyResponseAwait} color="yellow" />
        },
    }, {
        title: <Tooltip title="Количество принявших"><CheckCircleOutlined /></Tooltip>,
        showSorterTooltip: false,
        dataIndex: nameOf<IShiftTradeRequest>('qtyResponseApproved'),
        sorter: (a, b) => b.qtyResponseApproved - a.qtyResponseApproved,
        render: (value, record, index) => {
            return <Badge count={record.qtyResponseApproved} color="green" />
        },
    }, {
        title: <Tooltip title="Количество отклонивших"><CloseCircleOutlined /></Tooltip>,
        showSorterTooltip: false,
        dataIndex: nameOf<IShiftTradeRequest>('qtyResponseRejected'),
        sorter: (a, b) => b.qtyResponseRejected - a.qtyResponseRejected,
        render: (value, record, index) => {
            return <Badge count={record.qtyResponseRejected} color="red" />
        },
    }, {
        title: 'Действия',
        render: (value: any, item: IShiftTradeRequest) => {

            const handleClickDetail = () => {
                setSelectedRequest(item);
                setOpenResponsesModal(true);
            }

            const abortBtn = !!item.canCancel && (
                <AbortRequestButton
                    modalOpen={open}
                    request={item}
                    onFinish={handleUpdate}
                />
            );

            return (
                <Space>
                    <Button onClick={handleClickDetail} size="small">Подробнее</Button>
                    {abortBtn}
                </Space>
            );
        }
    }];

    return (
        <>
            <DraggableModal
                forceRender
                title="Список заявок"
                width="80%"
                open={open}
                onCancel={handleCancel}
                okButtonProps={{style:{display:'none'}}}
                footer={(a) => (
                    <>
                        <Button key="update" onClick={handleUpdate} loading={requestListState.loading}>Обновить</Button>
                        {a}
                    </>
                )}
            >
                <Table<IShiftTradeRequest>
                    size="small"
                    rowKey="id"
                    columns={columns}
                    dataSource={requestListState.entity}
                    loading={requestListState.loading}
                    pagination={{position: ['bottomCenter']}}
                />
            </DraggableModal>
            <ShiftTradeRequestDetail
                request={selectedRequest}
                open={openResponsesModal}
                onCancel={handleCancelResponsesModal}
            />
        </>
    );
}

export interface IAbortRequestButtonProps {
    modalOpen?: boolean,
    request: IShiftTradeRequest,
    onFinish?: () => void,
}

export function AbortRequestButton({
    modalOpen,
    request,
    onFinish
}: IAbortRequestButtonProps) {

    const auth = useAuth();
    const [open, setOpen] = useState(false);
    const [proccess, setProccess] = useState(false);
    const onFinishActual = useActual(onFinish);

    const handleConfirm = async () => {

        try {
            setOpen(false);
            setProccess(true);
            
            await abortShiftTradeRequest(auth, request);
            
            if (onFinishActual.current) {
                onFinishActual.current();
            }
        }
        catch (ex) {
            console.log(ex);
        }
        finally {
            setProccess(false);
        }
    }

    const handleOpenChange = () => {
        if (proccess) {
            setOpen(true);
        }
    }

    const handleClick = () => {
        setOpen(true);
    }

    const handleCancel = () => {
        setOpen(false);
    }

    return (
        <Popconfirm
            open={open}
            title="Отменить слот"
            description="Вы уверены, что хотите отменить слот ?"
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            cancelButtonProps={{disabled: proccess}}
            onOpenChange={handleOpenChange}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
            destroyTooltipOnHide
        >
            <Button loading={proccess} size="small" onClick={handleClick} danger>Отмена</Button>
        </Popconfirm>
    );
}

export default ShiftTradeRequestListModal;