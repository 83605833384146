import { IScheduleRequestHandlerModalLocale } from "..";
import confirmPopupLocaleRu from "../../ConfirmPopup/locale/ru";
import { localeRu } from "../../shared/lib";

export const ru: IScheduleRequestHandlerModalLocale = {
    confirmLocale: confirmPopupLocaleRu,
    acceptBtnTitle: "Принять",
    rejectBtnTitle: "Отклонить",
    closeBtnTitle: "Закрыть",
    skillsBtnTitle: "Навыки сотрудников",
    commentTitle: "Комментарий",
    activitiesViewer: localeRu,
}