import { Button, CollapseProps, Skeleton, Space } from "antd";
import { IEmployee, GroupMember, IProjectGroup, ISkill, ISpecialMark } from "../../../Entities/Database";
import { ListItemType } from "../../../Entities/EmployeeCard";
import { ListItemCollapse } from "../ListItemCollapse";
import { classNames } from "../../../System/ClassNames";
import { useCallback, useEffect, useState } from "react";
import { TagItem } from "../TagItem";
import { getActiveList, getBasicItemsArray, getItemLabel } from "../ListItemContainer/helper";
import { listItemTitlesLangRu } from "../ListItemContainer/locale/ru";

export interface ISpecialMarksProps {
    data?: ISpecialMark[],
    onFinish?: () => void,
    edit?: boolean,
    loading: boolean,
    employee?: IEmployee,
}

export interface IListItemTitlesLocale {
    projectGroup: string,
    skills: string,
    specialMarks: string,
}

export function SpecialMarks({onFinish, data, edit, loading, employee}: ISpecialMarksProps) {

    const [itemsArray, setItemsArray] = useState<ISpecialMark[] | undefined>(data);
    const [hideTag, setHideTag] = useState(true);

    useEffect(() => {
        setItemsArray(getActiveList(data || [], ListItemType.SpecialMarks));
    }, [data]);

    useEffect(() => { 
        return () => setHideTag(true);
    }, [data]);

    const handleShowMore = () => {
        setHideTag(false);
    };

    const getListCollapseItems = () => { 
        const items: CollapseProps['items']  = [
            {
                label: (
                    <Space className="tagCollapse__space">
                        <p className="tagCollapse__title">{getItemLabel(ListItemType.SpecialMarks, listItemTitlesLangRu)}</p>
                    </Space>
                ),
                children: (
                    <div className={classNames("tagCollapse__wrap", "tagCollapse__bg-white")}>
                        {loading &&  
                        <Skeleton 
                            active={true}
                            className="employee-skeleton"
                        />}
                        {!loading && itemsArray &&
                        <>
                            {itemsArray.map((item, index) => {

                                if (index > 3) {
                                    
                                    return ( 
                                        <TagItem 
                                            item={item}
                                            key={item.id} 
                                            listItemType={ListItemType.SpecialMarks} 
                                            hide={hideTag}
                                            elementsAmount={itemsArray.length}
                                        />
                                    );
                                }
        
                                return (
                                    <TagItem 
                                        item={item}
                                        key={item.id} 
                                        listItemType={ListItemType.SpecialMarks}
                                        hide={false}
                                        elementsAmount={itemsArray.length}
                                    />
                                );
                            })}
                            {itemsArray.length > 4 &&
                            <Button
                                onClick={handleShowMore}
                                className={hideTag ? "show-more" : "hide"}
                                type="text"
                            >
                                Показать все
                            </Button>}
                        </>}
                    </div>
                )
            },
        ];
    
        return items;
    };

    return (
        <ListItemCollapse 
            data={data}
            edit={edit}
            listItemType={ListItemType.SpecialMarks}
            employee={employee}
            onFinish={onFinish}
            loading={loading}
            items={getListCollapseItems()}
        />
    )
   
};