import { Button, Collapse, CollapseProps, Input, message, Skeleton, Space } from "antd";
import { IEmployee, ISkill, ISkillsData, ITagsData, systemSkillId } from "../../../Entities/Database";
import { ITagItem, ListItemType } from "../../../Entities/EmployeeCard";
import { useEffect, useRef, useState } from "react";
import { TagItem } from "../TagItem";
import { getActiveList, getBasicItemsArray, getItemLabel } from "../ListItemContainer/helper";
import { listItemTitlesLangRu } from "../ListItemContainer/locale/ru";
import { EditOutlined } from "@ant-design/icons";
import { EditButton } from "../EditButton";
import { MainSkillModal } from "../MainSkillModal";
import { skillLocaleRu } from "./locale/ru";

import "./style.css";

export interface ISkillsProps  {
    onFinish?: () => void,
    loading: boolean,
    employee?: IEmployee,
    data?: ISkillsData<ITagItem>,
}


export interface IListItemTitlesLocale {
    projectGroup: string,
    skills: string,
    specialMarks: string,
}

export function Skills({
    data, 
    loading, 
    employee,
    onFinish, 
}: ISkillsProps) {

    const [itemsArray, setItemsArray] = useState<ISkill[] | undefined>(() => data?.tags);
    const [hideTag, setHideTag] = useState(true);
    const [openMainSkillModal, setOpenMainSkillModal] = useState(false);
    const [extSkillList, setExtSkillList] = useState<ISkill[] | undefined>(() => data?.tags);

    const systemSkill = useRef({
        id: systemSkillId,
        content: skillLocaleRu.systemSkillTitle
    })

    const [mainSkill, setMainSkill] = useState<ISkill>(() => {

        const elem = data?.tags.find(x => x.id === data.mainSkillId);

        if (elem) {

            return {
                id: elem.id,
                content: elem.content
            };
        } 

        return {
            id: systemSkill.current.id,
            content: systemSkill.current.content
        };
    });

    const handleChangeMainSkill = (newValue: ISkill) => {
        setMainSkill(newValue)
    }

    const handleFinishSave = () => {
        
        message.success(skillLocaleRu.saveMessage);
        handleCloseMainSkillModal();

        if (onFinish) {
            onFinish();
        }
    }

    const handleOpenMainSkillModal = () => {
        setOpenMainSkillModal(true);
    }

    const handleCloseMainSkillModal = () => {
        setOpenMainSkillModal(false);
    }

    const handleShowMore = () => {
        setHideTag(false);
    }

    useEffect(() => {

        const items = data?.tags || [];
        setItemsArray(getActiveList(items, ListItemType.Skills));
        setExtSkillList([
            ...items,
            systemSkill.current
        ]);
        return () => setHideTag(true);

    }, [data?.tags]);

    const getListCollapseItems = () => {
        const items: CollapseProps['items']  = [
            {
                label: (
                    <Space className="skills__space">
                        <p className="skills__title">{getItemLabel(ListItemType.Skills, listItemTitlesLangRu)}</p>
                    </Space>
                ),
                children: (
                    <>
                        <div>
                            {loading ? 
                            <Skeleton 
                                active={true}
                                className="employee-skeleton"
                            /> :
                            <>
                                <p style={{margin: "0 0 5px 0"}}>{skillLocaleRu.mainSkillTitle}</p> 
                                <Space.Compact style={{width: "100%"}}>
                                    <Input 
                                        defaultValue={mainSkill.content}
                                        readOnly={true}
                                        style={{marginBottom: "12px"}}
                                    />
                                    
                                    <Button 
                                        onClick={handleOpenMainSkillModal}
                                    >
                                        <EditOutlined />
                                    </Button>
                                </Space.Compact>
                            </>
                            }
                            
                        </div>
                        <div className="skills__wrap">
                            {loading &&  
                            <Skeleton 
                                active={true}
                                className="employee-skeleton"
                            />}
                            {!loading && itemsArray &&
                            <>
                                {itemsArray.map((item, index) => {
                                    if (index > 3) {
                                        
                                        return ( 
                                            <TagItem 
                                                item={item}
                                                key={item.id} 
                                                listItemType={ListItemType.Skills} 
                                                hide={hideTag}
                                                elementsAmount={itemsArray.length}
                                            />
                                        )
                                    }
            
                                    return (
                                        <TagItem 
                                            item={item}
                                            key={item.id} 
                                            listItemType={ListItemType.Skills}
                                            hide={false}
                                            elementsAmount={itemsArray.length}
                                        />
                                        
                                    );
                                })}
                                {itemsArray.length > 4 &&
                                <Button 
                                    onClick={handleShowMore} 
                                    className={hideTag ? "show-more" : "hide"} 
                                    type="text"
                                >
                                    {skillLocaleRu.showMoreBtnTitle}
                                </Button>}
                            </>}
                        </div>
                    </>
                    
                )
            },
        ];
    
        return items;
    };

    return (
        <>
            <div className="skills__relative-wrap">
                <Collapse 
                    className="employee-collapse tag-colapse" 
                    items={getListCollapseItems()}  
                    
                />
                {!loading && data && data.canEdit && 
                <EditButton 
                    data={data.tags} 
                    listItemType={ListItemType.Skills}
                    employee={employee}
                    onFinish={onFinish}
                />}
            </div>
            {extSkillList &&
            <MainSkillModal 
                open={openMainSkillModal}
                skills={extSkillList}
                mainSkill={mainSkill}
                employees={employee ? [employee] : []}
                onClose={handleCloseMainSkillModal}
                onFinish={handleFinishSave}
                onChangeMainSkill={handleChangeMainSkill}
            />}
        </>
    );
};