import { Exception } from './Exception';
import { TypeOf } from './Type/TypeOf';
import { ArgumentScopeDetector } from './Type/ArgumentScopeDetector'; 
import { customName } from './CustomNameDecorator';

@customName("ArgumentException")
export class ArgumentException extends Exception {

    protected readonly paramName: string;
    
    public GetParamName(): string { return this.paramName; }
    
    constructor();
    constructor(id: number);
    constructor(id: number, message: string);
    constructor(id: number, message: string, paramName: string);
    constructor(id: number, message: string, innerException: Exception);
    constructor(id: number, message: string, paramName: string, innerException: Exception);

    constructor(message: string);
    constructor(message: string, paramName: string);
    constructor(message: string, innerException: Exception);
    constructor(message: string, paramName: string, innerException: Exception);

    constructor(idOrMessage?: string | number, messageOrParamNameOrInnerException?: string | Exception, paramNameOrInnerException?: string | Exception, innerException?: Exception) {

        const detectedId = ArgumentScopeDetector.Detect(arguments, [
            { paramType: TypeOf.Number, paramIndex: 0 },
        ], 0);

        const detectedMessage = ArgumentScopeDetector.Detect(arguments, [
            { paramType: TypeOf.String, paramIndex: 0, like: !detectedId.hasValue },
            { paramType: TypeOf.String, paramIndex: 1 },
        ], "");

        const detectedInnerException = ArgumentScopeDetector.Detect<Exception>(arguments, [
            { paramType: Exception, paramIndex: 1 },
            { paramType: Exception, paramIndex: 2 },
            { paramType: Exception, paramIndex: 3 },
        ], null);

        if (detectedId.hasValue && detectedMessage.hasValue && detectedInnerException.hasValue) {
            super(detectedId.value as number, detectedMessage.value as string, detectedInnerException.value as Exception);
        }

        else if (detectedId.hasValue && detectedMessage.hasValue && !detectedInnerException.hasValue) {
            super(detectedId.value as number, detectedMessage.value as string);
        }
            
        else if (detectedId.hasValue && !detectedMessage.hasValue && detectedInnerException.hasValue) {
            super(detectedId.value as number, detectedInnerException.value as Exception);
        }

        else if (detectedId.hasValue && !detectedMessage.hasValue && !detectedInnerException.hasValue) {
            super(detectedId.value as number);
        }

        else if (!detectedId.hasValue && detectedMessage.hasValue && detectedInnerException.hasValue) {
            super(detectedMessage.value as string, detectedInnerException.value as Exception);
        }

        else if (!detectedId.hasValue && detectedMessage.hasValue && !detectedInnerException.hasValue) {
            super(detectedMessage.value as string);
        }
        
        else {
            super();
        }

        this.paramName = ArgumentScopeDetector.Detect(arguments, [
            { paramType: TypeOf.String, paramIndex: 2, paramCount: 3 },
            { paramType: TypeOf.String, paramIndex: 2, paramCount: 4 },
            { paramType: TypeOf.String, paramIndex: 1, paramCount: 2, like: !detectedId.hasValue },
            { paramType: TypeOf.String, paramIndex: 1, paramCount: 3, like: !detectedId.hasValue },
        ], "").value as string;
    }
}