import { useCallback, useEffect, useRef } from "react";
import { getActivityListBaseData, getEmployeeActivityList, getScheduleExtensionActivityList, getScheduleExtensionList, IScheduleBoardEmployee, IScheduleBoardFilter, ActivityRoleType } from "../../Entities/Database";
import { NullOrUndefined } from "../../System/Utils";
import { useListLoader } from "../../Hooks/useListLoader";
import useAuth from "../../Hooks/useAuth";

export interface ListProviderProps {
    loading?: boolean,
    disableControl?: boolean,
    filter: IScheduleBoardFilter,
    roleIds?: ActivityRoleType[],
    employee?: IScheduleBoardEmployee | NullOrUndefined,
    selectedEmployee?: IScheduleBoardEmployee | NullOrUndefined,
}

export function useListProvider({
    loading,
    disableControl,
    filter,
    roleIds,
    employee,
    selectedEmployee
}: ListProviderProps) {
    
    const authProvider = useAuth();
    
    const activityListLoader = useCallback(
        (signal: AbortSignal) => getEmployeeActivityList(authProvider, filter, employee, signal),
        [authProvider.data?.staticId, filter, employee]
    );

    const activityBaseListLoader = useCallback(
        (signal: AbortSignal) => getActivityListBaseData(authProvider, roleIds || [], filter.lang, signal),
        [authProvider.data?.staticId, roleIds, filter.lang]
    );

    const scheduleEmployeeExtensionListLoader = useCallback(
        (signal: AbortSignal) => getScheduleExtensionList(authProvider, filter, filter.lang, signal),
        [authProvider.data?.staticId, filter]
    );

    const scheduleEmployeeExtensionActivityListLoader = useCallback(
        (signal: AbortSignal) => getScheduleExtensionActivityList(authProvider, filter, selectedEmployee, signal),
        [authProvider.data?.staticId, filter, selectedEmployee]
    );
    
    const [activityList, updateActivityList] = useListLoader(activityListLoader);
    const [activityBaseList, updateActivityBaseList] = useListLoader(activityBaseListLoader);
    const [scheduleEmployeeExtensionList, updateScheduleEmployeeExtensionList] = useListLoader(scheduleEmployeeExtensionListLoader);
    const [scheduleEmployeeExtensionActivityList, updateScheduleEmployeeExtensionActivityList] = useListLoader(scheduleEmployeeExtensionActivityListLoader);

    const didMount = useRef(false);

    useEffect(() => {

        if(loading || !didMount.current) {
            return;
        }

        return updateActivityBaseList();

    }, [authProvider.data?.staticId, roleIds, loading]);
    
    useEffect(() => {
        
        if(loading || !didMount.current) {
            return;
        }
        
        return updateActivityList();
        
    }, [authProvider.data?.staticId, filter.from, filter.to, employee, loading]);

    useEffect(() => {

        if(loading || !didMount.current) {
            return;
        }
        
        return updateScheduleEmployeeExtensionActivityList();

    }, [authProvider.data?.staticId, filter.from, filter.to, selectedEmployee, loading]);

    useEffect(() => {

        if(loading || !didMount.current) {
            return;
        }

        return updateScheduleEmployeeExtensionList();

    }, [authProvider.data?.staticId, loading]);

    useEffect(() => {

        didMount.current = true;

        updateActivityBaseList();
        updateActivityList();
        updateScheduleEmployeeExtensionList();

        return () => {
            didMount.current = false;
        }

    }, []);

    return {
        roleIds,
        filter,
        employee,
        activityList,
        activityBaseList,
        scheduleEmployeeExtensionList,
        scheduleEmployeeExtensionActivityList,
        updateActivityList,
        updateActivityBaseList,
        updateScheduleEmployeeExtensionList,
        updateScheduleEmployeeExtensionActivityList,
    };

    /*
    return useMemo(() => ({
        roleIds,
        filter,
        employee,
        activityList,
        activityBaseList,
        scheduleEmployeeExtensionList,
        scheduleEmployeeExtensionActivityList,
        updateActivityList,
        updateActivityBaseList,
        updateScheduleEmployeeExtensionList,
        updateScheduleEmployeeExtensionActivityList,
    }),
    [
        roleIds,
        filter,
        employee,
        activityList,
        activityBaseList,
        scheduleEmployeeExtensionList,
        scheduleEmployeeExtensionActivityList,
        updateActivityList,
        updateActivityBaseList,
        updateScheduleEmployeeExtensionList,
        updateScheduleEmployeeExtensionActivityList,
    ]);
    */
}