import { Reducer, ReducerAction, ReducerState, useCallback, useMemo, useReducer, useState } from "react";
import { loadEntityReducer, ILoadEntityReducer, initialLoadEntityState, LoadEntityKind, LoadReducerAction, ILoadEntityState } from "../Reducer/LoadEntityReducer";
import { OperationAbortedByComponentDestructor } from "../System/OperationAbortedByComponentDestructor";
import { OperationAbortedByMergeException } from "../System/OperationAbortedByMergeException";
import { OperationAbortedIgnoreException } from "../System/OperationAbortedIgnoreException";
import { Out, setRef } from "../System/Ref";
import { doOrAbort, isArray, isFunction } from "../System/Utils";
import { AbortFunction, useAbortController } from "./useAbortController";

export type DependencyList = ReadonlyArray<unknown>;

export interface ILoaderProps {
    throwError?: boolean,
    clearEntity?: boolean;
    backgroundLoading?: boolean;
    signal?: AbortSignal;
}

export interface ILoaderInitProps<T, TReducer extends ILoadEntityReducer<T> = ILoadEntityReducer<T>> extends ILoaderProps {
    reducer?: TReducer,
    initialState?: Partial<ReducerState<TReducer>>
}

export interface IEntityLoaderProps<T, TReducer extends ILoadEntityReducer<T> = ILoadEntityReducer<T>> extends ILoaderInitProps<T, TReducer> {
    loader: LoaderType<T>
}

export type UpdateProps = ILoaderProps & {
    onNoMerge?: OnNoMergeCallback,
}

export type StartNewMergeProps<TResult> = UpdateProps & {
    promise?: Out<Promise<TResult>>
}

export type AbortMerge = () => void;
export type LoaderType<T> = (signal: AbortSignal) => Promise<T>;
export type OnNoMergeCallback = () => void;
export type UpdateFunction<TReturnType> = (propsOrAbortSignalOrNoMergeCallback?: OnNoMergeCallback | AbortSignal | UpdateProps) => Promise<TReturnType>;
export type StartNewMerge<TReturnType>  = (propsOrAbortSignalOrNoMergeCallback?: OnNoMergeCallback | AbortSignal | StartNewMergeProps<TReturnType>) => AbortMerge;

let dynamicIndex = 0;

export const getAbortCallback = (...abort: (AbortMerge | undefined)[]) => () => {
    for(const fn of abort) {
        if(fn) {
            fn();
        }
    }
}

export const abortByMerge = {};
export const abortByDestructor = {};

export type EntityLoaderResult<T, TReducer extends Reducer<any, any>> = [
    ReducerState<TReducer>,
    StartNewMerge<T | undefined | never>,
    UpdateFunction<T | undefined | never>,
    AbortFunction,
    React.Dispatch<ReducerAction<TReducer>>
];

export function useEntityLoader<T, TReducer extends ILoadEntityReducer<T> = ILoadEntityReducer<T>>(
    loader: LoaderType<T>
): EntityLoaderResult<T, TReducer>;

export function useEntityLoader<T, TReducer extends ILoadEntityReducer<T> = ILoadEntityReducer<T>>(
    loader: LoaderType<T>,
    deps: DependencyList
): EntityLoaderResult<T, TReducer>;

export function useEntityLoader<T, TReducer extends ILoadEntityReducer<T> = ILoadEntityReducer<T>>(
    props: IEntityLoaderProps<T, TReducer>
): EntityLoaderResult<T, TReducer>;

export function useEntityLoader<T, TReducer extends ILoadEntityReducer<T> = ILoadEntityReducer<T>>(
    loader: LoaderType<T>,
    props: ILoaderInitProps<T, TReducer>
): EntityLoaderResult<T, TReducer>;

export function useEntityLoader<T, TReducer extends ILoadEntityReducer<T> = ILoadEntityReducer<T>>(
    loader: LoaderType<T>,
    deps: DependencyList,
    props: ILoaderInitProps<T, TReducer>
): EntityLoaderResult<T, TReducer>;

export function useEntityLoader<T, TReducer extends ILoadEntityReducer<T> = ILoadEntityReducer<T>>(
    propsOrLoader: IEntityLoaderProps<T, TReducer> | LoaderType<T>,
    depsOrProps?: undefined | DependencyList | ILoaderInitProps<T, TReducer>,
    props?: undefined | ILoaderInitProps<T, TReducer>
): EntityLoaderResult<T, TReducer> {

    const normalizeDeps     = isArray(depsOrProps) ? depsOrProps : [dynamicIndex++];
    const normalizeSubProps = isArray(depsOrProps) ? props : depsOrProps;
    const normalizeProps    = isFunction(propsOrLoader)
                            ? { loader: propsOrLoader, ...normalizeSubProps }
                            : propsOrLoader;
    
    const {
        loader,
        throwError = false,
        clearEntity = true,
        backgroundLoading = false
    } = normalizeProps;

    const [reducer] = useState(() => normalizeProps.reducer
        ? normalizeProps.reducer
        : loadEntityReducer);
    
    const [initialState] = useState(() => ({
        ...initialLoadEntityState,
        ...normalizeProps.initialState
    }));

    const [internalMerge, abort] = useAbortController();
    const [entity, dispatch] = useReducer<ILoadEntityReducer<T>>(reducer, initialState);

    const normalizeLoader = useMemo(() => loader, normalizeDeps);
    
    const merge = useCallback<UpdateFunction<T | undefined | never>>((
        propsOrAbortSignalOrNoMergeCallback?: OnNoMergeCallback | AbortSignal | StartNewMergeProps<T | undefined | never>
    ) => {

        const props = isFunction(propsOrAbortSignalOrNoMergeCallback)
                    ? { onNoMerge: propsOrAbortSignalOrNoMergeCallback }
                    : propsOrAbortSignalOrNoMergeCallback instanceof AbortSignal
                    ? { signal: propsOrAbortSignalOrNoMergeCallback }
                    : propsOrAbortSignalOrNoMergeCallback || {};
        
        const { onNoMerge, signal: externalSignal } = props;

        return internalMerge(async (signal) => {

            try {

                if((props?.backgroundLoading === undefined && !backgroundLoading)
                || (props?.backgroundLoading !== undefined && !props.backgroundLoading)) {

                    const clear = props?.clearEntity === undefined
                                     ? clearEntity
                                     : props.clearEntity;
                    
                    dispatch({
                        type: LoadEntityKind.Loading,
                        clearEntity: clear,
                    });
                }

                const result = await doOrAbort(
                    (signal) => normalizeLoader(signal),
                    signal,
                    externalSignal
                );
                
                dispatch({
                    type: LoadEntityKind.LoadSuccess,
                    payload: result
                });

                return result;
            }
            catch (ex: unknown) {
                
                // Игнорируем ошибку, если запущена операция прерывания для перезапуска задачи с обновленными данными или вызван деструктор компонента
                if (ex === abortByMerge ||
                    ex === abortByDestructor ||
                    ex instanceof OperationAbortedIgnoreException ||
                    ex instanceof OperationAbortedByMergeException ||
                    ex instanceof OperationAbortedByComponentDestructor) {
                    return;
                }
                
                dispatch({
                    type: LoadEntityKind.LoadFail,
                    error: ex
                });

                if (throwError) {
                    throw ex;
                }
            }

        }, onNoMerge);

    }, [normalizeLoader, internalMerge, dispatch,
        clearEntity, backgroundLoading, throwError
    ]);
    
    const reload = useCallback<StartNewMerge<T | undefined | never>>((
        propsOrAbortSignalOrNoMergeCallback?: OnNoMergeCallback | AbortSignal | StartNewMergeProps<T | undefined | never>
    ) => {
        const props = isFunction(propsOrAbortSignalOrNoMergeCallback)
                    ? { onNoMerge: propsOrAbortSignalOrNoMergeCallback }
                    : propsOrAbortSignalOrNoMergeCallback instanceof AbortSignal
                    ? { signal: propsOrAbortSignalOrNoMergeCallback }
                    : propsOrAbortSignalOrNoMergeCallback || {};

        const { promise, ...updateProps } = props;
        const awaiter = merge(updateProps);
        setRef(promise, awaiter);
        
        return () => {
            abort(abortByMerge);
        }

    }, [merge, abort]);
    
    return [
        entity    as ReducerState<TReducer>,
        reload,
        merge,
        abort,
        dispatch  as React.Dispatch<ReducerAction<TReducer>>
    ]
}

useEntityLoader.abortByMerge = abortByMerge;
useEntityLoader.abortByDestructor = abortByDestructor;

export default useEntityLoader;