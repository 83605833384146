import { IListItemTitlesLocale } from ".";
import { GroupMember, ISpecialMark } from "../../../Entities/Database";
import { ListItemType } from "../../../Entities/EmployeeCard";

export function getItemLabel(listItemType: ListItemType, titles: IListItemTitlesLocale) {
    switch(listItemType) {
        case ListItemType.ProjectGroup: return titles.projectGroup;
        case ListItemType.Skills: return titles.skills;
        case ListItemType.SpecialMarks: return titles.specialMarks;
    }
};

const nA = "N/A";

export function getBasicItemsArray(listItemType: ListItemType) {

    switch (listItemType) {

        case ListItemType.ProjectGroup: {
            return [{
                id: 1,
                content: nA,
                groupMember: GroupMember.Member,
                
            }]
        }
        case ListItemType.Skills: {
            return [{
                id: 1,
                content: nA,
                color: "",
                isActive: true,
            }]
        }
        case ListItemType.SpecialMarks: {
            return [{
                id: 1,
                content: nA,
                color: "blue",
                isActive: true,
            }]
        }
        default: {
            throw new Error('Not supported tag type');
        }
    }
};

function hasIsActive(val: any): val is ISpecialMark {
    return val.isActive === undefined;
};

export function getActiveList(data: any[], listItemType: ListItemType) {
   
    const onlyActive = data.filter((item) => {
        if (item?.isActive === undefined) return item;
        if (item?.isActive) return item;
    });

    if (onlyActive.length) { 
        return onlyActive;
    }

    return getBasicItemsArray(listItemType);
};

