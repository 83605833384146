import { useCallback, useEffect, useRef, useState } from "react";
import { CreateScheduleShiftSchemeModalTemplate } from "../CreateScheduleShiftSchemeModalTemplate";
import { ICreateScheduleShiftScheme, IProjectGroupShortInfo, IScheduleShiftScheme, ITimeZone, addScheduleShiftScheme, getProjectGroupListForFilter, getTimeZoneList } from "../../../Entities/Database";
import useAuth from "../../../Hooks/useAuth";
import { IAuthState } from "../../../Entities/IAuthProvider";
import { message } from "antd";
import useActual from "../../../Hooks/useActual";

interface ICreateScheduleShiftSchemeModalProps {
    lang?: string,
    isModalOpen: boolean,
    handleCancel: () => void,
    isClone: boolean,
    scheme: IScheduleShiftScheme,
    onFinishSave?: (newScheme: IScheduleShiftScheme) => void,
}

const abortByClose = {};
const abortByChange = {};

export function CreateScheduleShiftSchemeModal({
    lang = 'ru',
    isModalOpen,
    handleCancel,
    isClone,
    scheme,
    onFinishSave,
}: ICreateScheduleShiftSchemeModalProps) {
    
    const auth = useAuth();
    const [timezoneList, setTimeZoneList] = useState<ITimeZone[]>([]);
    const [projectGroup, setProjectGroup] = useState<IProjectGroupShortInfo[]>([]);
    const [saving, setSaving] = useState(false);
    const [loadingTimezone, setLoadingTimezone] = useState(false);
    const [loadingProjectGroup, setLoadingProjectGroup] = useState(false);
    const [disableOk, setDisableOk] = useState(false);

    const loaderAcRef = useRef<AbortController>();
    const abortPrevReq = (reason: unknown) => {
        if (loaderAcRef.current) {
            loaderAcRef.current.abort(reason);
        }
    }
    const loadTimeZoneList = async (signal?: AbortSignal) => {
        setLoadingTimezone(true);
        const list: ITimeZone[] = await getTimeZoneList(auth, lang, signal);

        if (list && list.length) {
            setTimeZoneList(list);  
            setDisableOk(false);
        } else {
            setDisableOk(true);
            message.open({
                type: 'error',
                content: 'Не удалось загрузить часовые пояса. Повторите попытку позже',
            });
            
        }
        setLoadingTimezone(false);   
    }

    const loadProjectGroupList = async (signal?: AbortSignal) => {

        setLoadingProjectGroup(true);

        const result: IProjectGroupShortInfo[] = await getProjectGroupListForFilter(auth, signal);
        
        if (result && result.length) {
            setProjectGroup(result);
            setDisableOk(false);
        } else {
            setDisableOk(true);
            message.open({
                type: 'error',
                content: 'Не удалось загрузить список проектных групп. Повторите попытку позже',
            });
        }
        
        setLoadingProjectGroup(false);
    }

    const onFinishSaveBuf = useActual(onFinishSave);

    async function save(data: ICreateScheduleShiftScheme) {

        try {
            setSaving(true);
            const abortController = new AbortController();
            const result = await addScheduleShiftScheme(auth, data, abortController.signal);
            if (onFinishSaveBuf.current) {
                onFinishSaveBuf.current(result);
            }
        }
        finally {
            setSaving(false);
        }
    }

    const handleCreateNewScheduleShiftScheme = (data: ICreateScheduleShiftScheme) => {
       
        if (!data.SSS_Parent_ID) {
            data.SSS_Parent_ID = null;
        }

        save(data);
    }

    useEffect(() => {

        if(isModalOpen) {

            const ac = loaderAcRef.current = new AbortController();
            const signal = ac.signal;

            loadTimeZoneList(signal);
            loadProjectGroupList(signal);
           
            return () => {
                abortPrevReq(abortByChange);
            }
        }

    }, [auth.data?.staticId, lang, isModalOpen]);

    useEffect(() => () => {
        abortPrevReq(abortByClose);
    }, []);

    return (
        <CreateScheduleShiftSchemeModalTemplate 
            isModalOpen={isModalOpen}
            handleOk={handleCreateNewScheduleShiftScheme}
            handleCancel={handleCancel}
            timezoneList={timezoneList}
            projectGroupList={projectGroup}
            isClone={isClone}
            loading={loadingTimezone && loadingProjectGroup}
            saving={saving}
            scheme={scheme}
            disable={disableOk}
        />
    )
}