import { InfoCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useState } from "react";
import { ScheduleShiftWorkInfoModal } from "../ScheduleShiftWorkInfoModal";
import { IEmployee } from "../../../Entities/Database";

import "./style.css";

export interface IScheduleShiftWorkInfoProps {
    employee?: IEmployee,
}

export function ScheduleShiftWorkInfo({
    employee,
}: IScheduleShiftWorkInfoProps) {
    const [openModal, setOpenModal] = useState(false);

    const handleOpenScheduleshiftWorkInfo = () => {
        setOpenModal(!openModal);
    }

    const handleCancelScheduleshiftWorkInfo = () => {
        setOpenModal(false);
    }

    return (
        <>
            <Button 
                className="shiftWorkInfo__button"
                icon={<InfoCircleOutlined />}
                onClick={handleOpenScheduleshiftWorkInfo}
            /> 
            <ScheduleShiftWorkInfoModal 
                open={openModal}
                onCancel={handleCancelScheduleshiftWorkInfo}
                onOk={handleCancelScheduleshiftWorkInfo}
                employee={employee}
            />
        </>
        
    )
}