import VirtualTable, { ColumnsType, ColumnType, Grid, GridOnScrollCallback, VirtualTableProps } from "antd-virtual-table";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getIntervalInfoblockTableFilterParams, IIntervalInfoblockTableRecord, IntervalInfoblockRecordType, IParam, saveIntervalInfoblockTableFilterParams } from "../../Entities/Database";
import { message, TableProps } from "antd";
import useAuth from "../../Hooks/useAuth";
import { ILoaderInitProps, useListLoader } from "../../Hooks/useListLoader";
import equal from "fast-deep-equal";
import { refSetter } from "../shared/helpers";

function getTimeFormatFromMins(mins: number) {

    mins %= 1440;

    if (mins < 0) {
        throw new RangeError("Valid input should be greater than or equal to 0 and less than 1440.");
    }

    const h = mins / 60 | 0;
    const m = mins % 60 | 0;
    const hStr = h < 10 ? `0${h}` : '' + h;
    const mStr = m < 10 ? `0${m}` : '' + m;

    return `${hStr}:${mStr}`;
}

const scrollbarWidth = 20; // TODO
const rowKey = (row: IIntervalInfoblockTableRecord) => row.id;
const initUseListReducer: ILoaderInitProps<IParam> = {
    initialState: {
        loading: true
    }
}

export type FilteredInfoType = Record<keyof IIntervalInfoblockTableRecord, IntervalInfoblockRecordType[] | null>;

export interface InfoblockTableProps {
    gridRef?: VirtualTableProps<IIntervalInfoblockTableRecord>['gridRef'],
    outerGridRef?: VirtualTableProps<IIntervalInfoblockTableRecord>['outerGridRef'],
    loading?: boolean,
    interval: number,
    dataSource: Readonly<IIntervalInfoblockTableRecord[]>,
    scroll: VirtualTableProps<any>['scroll'],
    rowHeight: number,
    onScroll?: GridOnScrollCallback,
    onUpdateFilter?: () => void,
}

export function InfoblockTable({
    gridRef,
    outerGridRef,
    loading,
    interval,
    dataSource,
    scroll,
    rowHeight,
    onScroll,
    onUpdateFilter
}: InfoblockTableProps) {

    const didMouth = useRef(false);
    const internalGridRef = useRef<Grid<IIntervalInfoblockTableRecord>>(null);

    const auth = useAuth();

    const [saving, setSaving] = useState(false);
    const [filteredInfo, setFilteredInfo] = useState<FilteredInfoType>({});

    const [filterParams, reloadFilterParams, ] = useListLoader(
        signal => getIntervalInfoblockTableFilterParams(auth, signal),
        [auth.data?.staticId],
        initUseListReducer
    );

    const handleChange = useCallback<NonNullable<TableProps<any>['onChange']>>(async (pagination, filters, sorter) => {

        if(equal(filteredInfo, filters)) {
            return;
        }

        try {

            setSaving(true);
            
            const typedFilters = filters as unknown as FilteredInfoType;
            const newFilteredIds = (typedFilters === null || typedFilters.paramName === null)
                                ? filterParams.items.map(x => x.id)
                                : typedFilters.paramName;

            await saveIntervalInfoblockTableFilterParams(auth, newFilteredIds);

            if(onUpdateFilter) {
                onUpdateFilter();
            }
            
            setFilteredInfo(typedFilters);
        }
        catch(ex) {
            console.error(ex);
            message.error('Не удалось обновить данные.');
        }
        finally {
            setSaving(false);
        }

    }, [filteredInfo, filterParams.items]);

    const intervalColumns = useMemo(() => {

        const columns: ColumnsType<IIntervalInfoblockTableRecord> = [];
        const columnWidth = 74;
        const day = 24 * 60;

        // TODO: по умолчанию идет отображение 3х дней, добавим день до и день после
        if (interval === 60) {

            const from = 0;
            const to   = day;

            let tmp = from;
            while(tmp < to) {
                columns.push({
                    key: 'tmp-day-prev',
                    title: '',
                    ellipsis: true,
                    width: columnWidth,
                    onHeaderCell: () => ({ className: "infoblock-interval-column-title"}),
                    onCell: () => ({ className: "infoblock-interval-value" }),
                    render: (value, record, index) => '',
                });
                tmp += interval;
            }
        }

        const from = 0;
        const to   = day;

        let tmp = from;

        while(tmp < to) {

            const current = tmp;
            const currectKey = current;
            const title = getTimeFormatFromMins(current);

            tmp += interval;

            columns.push({
                key: currectKey,
                title: title,
                ellipsis: true,
                width: columnWidth,
                onHeaderCell: () => ({ className: "infoblock-interval-column-title"}),
                onCell: () => ({ className: "infoblock-interval-value" }),
                render: (value, record, index) => record && record[title],
            });
        }

        // TODO: по умолчанию идет отображение 3х дней, добавим день до и день после
        if (interval === 60) {

            const from = 0;
            const to   = day;

            let tmp = from;
            while(tmp < to) {
                columns.push({
                    key: 'tmp-day-prev',
                    title: '',
                    ellipsis: true,
                    width: columnWidth,
                    onHeaderCell: () => ({ className: "infoblock-interval-column-title"}),
                    onCell: () => ({ className: "infoblock-interval-value" }),
                    render: (value, record, index) => '',
                });
                tmp += interval;
            }
        }

        columns[columns.length - 1].width += scrollbarWidth;
        return columns;

    }, [interval]);

    const columns = useMemo(() => {

        const filters: ColumnType<IIntervalInfoblockTableRecord>['filters'] = filterParams
        .items
        .map(x => ({
            text: x.title,
            value: x.id,
        }));

        const paramColumn: ColumnType<IIntervalInfoblockTableRecord> = {
            key: 'paramName',
            title: 'Параметр',
            ellipsis: true,
            width: 192,
            fixed: true,
            filters: filters,
            filteredValue: filteredInfo.paramName || null,
            filterSearch: true,
            sorter: (a, b) => a.paramName.localeCompare(b.paramName),
            onFilter: (value, record) => record.id === value,
            onHeaderCell: () => ({ className: "infoblock-interval-param-column-title"}),
            onCell: () => ({ className: "infoblock-interval-param-value" }),
            render: (value, record, index) => {
                const dayValue = record && record.paramName;
                return (
                    <p title={`${dayValue}`}>
                        {dayValue}
                    </p>
                );
            }
        };

        return [
            paramColumn,
            ...intervalColumns
        ]

    }, [filterParams.load,
        filterParams.items,
        filteredInfo,
        intervalColumns,
    ]);
    
    useEffect(reloadFilterParams, []);
    
    useEffect(() => {

        if(didMouth.current
        && filterParams.load) {

            const filteredParams = filterParams
                                .items
                                .filter(x => !x.disabled)
                                .map(x => x.id);
            
            setFilteredInfo({
                paramName: filteredParams
            });
        }

    }, [filterParams.load,
        filterParams.items
    ]);

    useEffect(() => {

        if (didMouth.current && internalGridRef.current) {
            internalGridRef.current.resetAfterIndices({
                columnIndex: 0,
                rowIndex: 0,
                shouldForceUpdate: true
            });
        }

    }, [columns]);

    useEffect(() => {
        didMouth.current = true;
        return () => {
            didMouth.current = false;
        }
    }, []);

    return (
        <VirtualTable
            gridRef={refSetter(gridRef, internalGridRef)}
            bordered={true}
            rowKey={rowKey}
            onChange={handleChange}
            onScroll={onScroll}
            outerGridRef={outerGridRef}
            className="interval-infoblock-table"
            columns={columns}
            loading={saving || loading}
            dataSource={dataSource}
            scroll={scroll}
            pagination={false}
            rowHeight={rowHeight}
        />
    )
}