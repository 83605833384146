import { IFilterState, IGenericFilterState } from "../Entities/IFilterState";
import { IState } from "../Entities/IState";
import { isEmptyOrNullOrUndefined, isFunction, isNullOrUndefined, isString, isUndefined, NullOrUndefined, throwNotSupportOperation } from "../System/Utils";

export interface IColumnSelector<TItem, TValue = any> {
    title: string,
    valueSelector: (item: TItem) => TValue
}

export interface ISearchState<T> {
    searchText: React.Key,
    searchedColumn?: keyof T | IColumnSelector<T>
}

export interface ISearchFilterState<TEntity> extends IGenericFilterState<TEntity>, IState<TEntity> {
    sourceState: IGenericFilterState<TEntity>,
    searchPropertyName: keyof TEntity,
    searchStr?: string,
    items: Readonly<TEntity[]>
}

export enum SearchFilterActionKind {
    UPDATE_SOURCE_STATE = 'UPDATE_SOURCE_STATE',
    CHANGE_SEARCH_STR   = 'CHANGE_SEARCH_STR',
}

export interface SearchFilterReducerAction<TEntity> {
    type: SearchFilterActionKind;
    searchStr?: string,
    sourceState?: IGenericFilterState<TEntity>
}

export interface ILayoutReplacer {
    [index: string]: string
}

export const charReplacer: ILayoutReplacer = {
    "q": "й", "w": "ц", "e": "у", "r": "к", "t": "е", "y": "н", "u": "г",
    "i": "ш", "o": "щ", "p": "з", "[": "х", "]": "ъ", "a": "ф", "s": "ы",
    "d": "в", "f": "а", "g": "п", "h": "р", "j": "о", "k": "л", "l": "д",
    ";": "ж", "'": "э", "z": "я", "x": "ч", "c": "с", "v": "м", "b": "и",
    "n": "т", "m": "ь", ",": "б", ".": "ю", "/": ".",
};

/**
 * Изменяет раскладку клавиатуры (символов) с русской на английскую и обратно
 * @param str
 * @param toEnglish 
 * @returns 
 */
export function textLayoutFix(str?: string, toEnglish: boolean = false) {

    if(isEmptyOrNullOrUndefined(str)) {
        return str;
    }

    const replacer: ILayoutReplacer = {};

    for(const [eng, rus] of Object.entries(charReplacer)) {
        if(toEnglish) replacer[rus] = eng;
        else          replacer[eng] = rus;
    }

    let result = new Array<string>();
    for(let char of str) {
        const lchar = char.toLowerCase();
        const replace = replacer[lchar];
        if(!isUndefined(replace)) {
            char = char === lchar ?
                replace :
                replace.toUpperCase();
        }
        result.push(char);
    }

    return result.join('');
}

export type SearchRegex = RegExp | null;

/**
 * Создает регулярное выражение для поиска
 * @param searchStr строка поиска
 * @returns
 */
export function generateRegex(searchStr?: string): SearchRegex {
    try
    {
        if(isEmptyOrNullOrUndefined(searchStr)) {
            return null;
        }

        const normalizeStr = searchStr.replace(/[^a-zа-яё0-9\s]/gi, ' ');
        const blocks       = normalizeStr
                            .split(' ')
                            .filter(x => (x !== ' ' && !isEmptyOrNullOrUndefined(x)));

        if(blocks.length == 0) {
            return null;
        }

        const regexStr     = '.*' + blocks.join('.*');
        return new RegExp(regexStr, 'i');
    }
    catch
    {
        return null;
    }
}

export function satisfiesStrValue(
    value: string, 
    searchStr: string,
    ...regexs: SearchRegex[]
) {

    if(isEmptyOrNullOrUndefined(value)) {
        return false;
    }

    // Прямое вхождение строки поиска
    if(value.toLowerCase().indexOf(searchStr.toLowerCase()) !== -1) {
        return true;
    }

    // Проверка регулярок
    for(let regex of regexs) {
        
        if(regex != null && regex.test(value)) {
            return true;
        }
    }

    return false;
}

export function satisfiesValue(
    value: unknown,
    searchStr: string,
    ...regexs: SearchRegex[]
) {

    if(isEmptyOrNullOrUndefined(searchStr)) {
        return true;
    }

    // Нормализуем значение свойства в строку
    // Распаковываем поле из функций-геттеров

    if(isEmptyOrNullOrUndefined(value)) {
        return false;
    }

    if(isFunction(value)) {
        value = (value as any)();
    }

    if(!isString(value)) {
        value = (value as any).toString();
    }
    
    const normalizeStr = (value as unknown as string);
    
    return satisfiesStrValue(normalizeStr, searchStr, ...regexs);
}

export function satisfies<TEntity>(
    entity: TEntity,
    searchPropertyName: keyof TEntity,
    searchStr: string,
    ...regexs: SearchRegex[]
) {
    return satisfiesValue(
        entity[searchPropertyName],
        searchStr,
        ...regexs
    );
}

export function satisfiesGetter<TEntity>(
    entity: TEntity,
    selector: (item: Readonly<TEntity>) => string,
    searchStr: string,
    ...regexs: SearchRegex[]
) {
    return satisfiesValue(
        selector(entity),
        searchStr,
        ...regexs
    );
}

export function generateRegexesOrigRuEn(searchStr?: string): [RegExp | null, RegExp | null, RegExp | null] {

    return [
        generateRegex(searchStr),
        generateRegex(textLayoutFix(searchStr)),
        generateRegex(textLayoutFix(searchStr, true))
    ];
}

let prevSearchStr: string | NullOrUndefined;
let prevRegexs: SearchRegex[];

export interface IRegexBufItem {
    actual: number,
    searchStr: string | NullOrUndefined;
    regexs: SearchRegex[];
}

export interface ISearchRegexBuf {
    [key: string]: IRegexBufItem
}

export const serachRegexBuf: ISearchRegexBuf = {};

export function dispatchBuffer(searchStr?: string, bufIndex?: string) {

    if(bufIndex) {

        if(serachRegexBuf[bufIndex] &&
           serachRegexBuf[bufIndex].searchStr != searchStr) {
            
            serachRegexBuf[bufIndex] = {
                actual: 0,
                searchStr,
                regexs: generateRegexesOrigRuEn(searchStr)
            }
        }
        
        serachRegexBuf[bufIndex].actual = new Date().getTime();
        return serachRegexBuf[bufIndex].regexs;
    }

    if(prevSearchStr != searchStr) {
        
        prevSearchStr = searchStr;
        prevRegexs = generateRegexesOrigRuEn(searchStr);
    }

    return prevRegexs;
}

export function searchInValueBuf(value: unknown, searchStr?: string, bufIndex?: string) {

    if(isEmptyOrNullOrUndefined(searchStr)) {
        return true;
    }
    
    return satisfiesValue(
        value,
        searchStr,
        ...dispatchBuffer(searchStr, bufIndex)
    );
}

export function searchInValue(value: unknown, searchStr?: string) {

    if(isEmptyOrNullOrUndefined(searchStr)) {
        return true;
    }

    return satisfiesValue(
        value,
        searchStr,
        ...generateRegexesOrigRuEn(searchStr)
    );
}

export function searchAllEntities<TEntity>(
    items: Readonly<TEntity[]>,
    searchPropertyName: keyof TEntity,
    searchStr?: string
) {
    if(isEmptyOrNullOrUndefined(searchStr) || items.length == 0) {
        return items;
    }

    if(isEmptyOrNullOrUndefined(searchPropertyName)) {
        throw new Error(`Search property name is null or undefined`);
    }

    const regex = generateRegexesOrigRuEn(searchStr);

    return items.filter(item => {
        return satisfies(
            item,
            searchPropertyName,
            searchStr,
            ...regex
        );
    });
}

export function searchAllEntitiesBySelector<TEntity>(
    items: Readonly<TEntity[]> | undefined,
    selector: (item: Readonly<TEntity>) => string,
    searchStr?: string
) {
    if(isEmptyOrNullOrUndefined(searchStr) || !items || items.length === 0) {
        return items;
    }

    const regex = generateRegexesOrigRuEn(searchStr);

    return items.filter(item => {
        return satisfiesGetter(
            item,
            selector,
            searchStr,
            ...regex
        );
    });
}

export function searchAllEntitiesInState<TState, TEntity>(
    state: IFilterState<TState, TEntity>,
    searchPropertyName: keyof TEntity,
    searchStr?: string
) {
    return searchAllEntities(state.items, searchPropertyName, searchStr);
}

export function getSearchStateFromFilterState<TEntity>(sourceState: IFilterState<IState<TEntity> | NullOrUndefined, TEntity>, searchPropertyName: keyof TEntity, searchStr?: string): ISearchFilterState<TEntity> {
    
    return {
        sourceState: sourceState,
        searchPropertyName: searchPropertyName,
        searchStr: searchStr,
        items: searchAllEntitiesInState(sourceState, searchPropertyName, searchStr)
    }
}

export type ISearchFilterReducer<TEntity> = (prevState: ISearchFilterState<TEntity>, action: SearchFilterReducerAction<TEntity>) => ISearchFilterState<TEntity>;
export const searchFilterReducer = <TEntity>(prevState: ISearchFilterState<TEntity>, action: SearchFilterReducerAction<TEntity>): ISearchFilterState<TEntity> => {

    switch(action.type) {

        case SearchFilterActionKind.UPDATE_SOURCE_STATE:
        {
            const state = action.sourceState;

            if(isNullOrUndefined(state)) {
                throw new Error(`State is null or undefined`);
            }

            return getSearchStateFromFilterState(state, prevState.searchPropertyName, prevState.searchStr);
        }

        case SearchFilterActionKind.CHANGE_SEARCH_STR:
        {
            return getSearchStateFromFilterState(prevState.sourceState, prevState.searchPropertyName, action.searchStr);
        }

        default: return throwNotSupportOperation(`Action is not supported`);
    }
}