import { useEffect, useRef, useState } from "react";
import { Divider, Modal, Select, Skeleton, message as AntdMessage, Form, Input } from "antd";
import { IEmployee, IWorkTimeInput, getProductiveActList, saveProductiveActList, IActivitiesItem, applyEmployeeShiftScheme, IApplyEmployeeScheduleShift } from "../../../Entities/Database";
import useAuth from "../../../Hooks/useAuth";
import DraggableModal from "../../DraggableModal";
import { ExclamationCircleFilled } from "@ant-design/icons";
import useActual from "../../../Hooks/useActual";
import { BackendResponseException } from "../../../System/BackendResponseException";
import { nameof } from "../../../System/Utils";
import "./style.css";
import moment from "moment";
import DatePicker from "../../shared/DatePicker";

interface IMainActivityProps {
    onFinish: () => void,
    onClose?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    isOpen: boolean,
    employees: IEmployee[],
    data: IWorkTimeInput,
    onScheduleChange?: () => void,
}

const abortByMerge = {};
const abortByCloseFilter = {};

export function MainActivity({ 
    onFinish, 
    isOpen, 
    employees, 
    onClose, 
    onScheduleChange, 
    data 
}: IMainActivityProps) {

    const [modal, modalContextHolder] = Modal.useModal();
    const [message, messageContextHolder] = AntdMessage.useMessage();
    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(false);
    const [activitiesList, setActivitiesList] = useState<IActivitiesItem[]>();
    const auth = useAuth();
    const [form] = Form.useForm<IApplyEmployeeScheduleShift>();

    const actualOnFinish = useActual(onFinish);
    const onScheduleChangeBuf = useActual(onScheduleChange);

    const abortControllerRef = useRef<AbortController | null>();
    const abortLoading = (reason = abortByMerge) => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort(reason);
            abortControllerRef.current = null;
        }
    }

    async function save(values: IApplyEmployeeScheduleShift) {

        try {

            let confirmApply = true;

            setSaving(true);
            const abortController = new AbortController();
            await saveProductiveActList(auth, values, abortController.signal);
            await modal.confirm({
                title: 'Подтвердите действие',
                icon: <ExclamationCircleFilled />,
                content: 'Шаблон успешно назначен, выполнить перерасчет расписания у сотрудника ?',
                okText: 'Да',
                cancelText: 'Нет',
                onOk: () => applyEmployeeShiftScheme(auth, values, abortController.signal),
                onCancel: () => {
                    confirmApply = false;
                },
            });

            if (actualOnFinish.current) {
                actualOnFinish.current();
            }

            if (onScheduleChangeBuf.current && confirmApply) {
                onScheduleChangeBuf.current();
            }
        }
        catch (ex) {

            message.error(ex instanceof BackendResponseException
                ? ex.message
                : "При сохранении данных возникла ошибка."
            );
        }
        finally {
            setSaving(false);
        }
    }

    const handleFormFinish = (values: IApplyEmployeeScheduleShift) => {

        if (values.activityId === data.id) {
            message.error('Выбранная рабочая активность уже назначена.');
        } else {
            save(values);
        }
    }

    const handleOk = () => {
        form.submit();
    }

    const handleCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

        if (!saving && onClose) {

            const actualId = form.getFieldValue(nameof((x: IApplyEmployeeScheduleShift) => x.activityId));
            
            if (actualId !== data.id) {

                modal.confirm({
                    title:  (
                        <div>Внесенные изменения не сохранятся!
                            <br/> 
                            <span style={{fontWeight: 400, paddingTop: 10, display: "block"}}>
                                Выйти без сохранения?
                            </span>
                        </div>
                    ),
                    onOk: onClose
                });
            }
            else {
                onClose(e);
            }
        }
    }

    async function loadActivitiesList(signal: AbortSignal) {

        try {

            if (!employees.length) {
                setLoading(false);
                setActivitiesList([]);
                return;
            }

            setLoading(true);
            const productiveActList: IActivitiesItem[] = await getProductiveActList(auth, employees[0], signal); 
    
            setLoading(false);
            setActivitiesList(productiveActList); 
        }
        catch(ex) {

            if (ex === abortByCloseFilter ||
                ex === abortByMerge) {
                return;
            }

            console.error(ex);
            message.error('Не удалось загрузить данные по активности.');
            setLoading(false);
        }
    }

    useEffect(() => {

        if (isOpen) {

            abortControllerRef.current = new AbortController();
            loadActivitiesList(abortControllerRef.current.signal);

            form.resetFields();

            if (employees.length > 0) {
                form.setFieldValue(nameof<IApplyEmployeeScheduleShift>("employeePID"), employees[0].PID);
            }

            form.setFieldValue(nameof<IApplyEmployeeScheduleShift>("activityId"), data.id);
            form.setFieldValue(nameof<IApplyEmployeeScheduleShift>("startDate"), moment(new Date()));
        }

        return () => {
            abortLoading(isOpen ? abortByMerge : abortByCloseFilter);
        }

    }, [employees, isOpen]);

    return (
        <>
            <DraggableModal 
                title={`Изменение: Основная рабочая активность`}
                className="mainActivity__modal"
                open={isOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                cancelButtonProps={{disabled: saving}}
                okButtonProps={{loading: saving}}
                okText="Сохранить"
                cancelText="Закрыть"
                width={350}
                destroyOnClose
            >
                {loading && 
                <Skeleton 
                    active={true}
                    className="employee-skeleton"
                />}
                {!loading && 
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleFormFinish}
                    disabled={saving}
                >
                    <Form.Item
                        name={nameof<IApplyEmployeeScheduleShift>("employeePID")}
                        label="Сотрудник:"
                        rules={[{ required: true, message: "Не выбран сотрудник", }]}
                        hidden
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item 
                        name={nameof<IApplyEmployeeScheduleShift>("activityId")}
                        label="Активность:"
                        rules={[{ required: true, message: "Не выбран тип активности", }]}
                    >
                        <Select
                            options={
                                activitiesList?.length ? activitiesList.map(item => ({
                                    value: item.id,
                                    label: item.caption,
                                })) : [{
                                    value: data.id,
                                    label: data.content,
                                }]
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name={nameof<IApplyEmployeeScheduleShift>("startDate")}
                        label="Назначить с:"
                        rules={[{ required: true, message: "Дата не заполнена", }]}
                    >
                        <DatePicker        
                            style={{width: "100%"}}
                        />
                    </Form.Item>
                </Form>}
            </DraggableModal>
            {modalContextHolder}
            {messageContextHolder}
        </>
    );
}