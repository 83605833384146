import React from "react";
import { ArgumentException } from "./ArgumentException";
import { NotSupportedException } from "./NotSupportedException";
import { OperationAbortedException } from "./OperationAbortedException";
import { OperationCanceledException } from "./OperationCanceledException";

export type NotFound = -1;

export const dateFormat = "DD.MM.YYYY";
export const timeFormat = "HH:mm:ss";
export const dateTimeFormat = `${dateFormat} ${timeFormat}`;

export declare type EventValue<DateType> = DateType | null;
export declare type RangeValue<DateType> = [EventValue<DateType>, EventValue<DateType>] | null;

export type Mutable<T> = {
    -readonly [key in keyof T]: T[key];
}

export type KeyOf<T extends object> = Extract<keyof T, string>;
export type DynamicObject<T = any> = {
    [key in keyof T]: any;
};

export function isObjKey<T extends {}>(key: PropertyKey, obj: T): key is keyof T {
    return key in obj;
}

export type ValueOf<T> = T[keyof T];
export type PropertySelector<T, V extends T[keyof T]> = (x: T) => V;
export type PropertyTypeSelector<T, V extends T[keyof T]> = ValueOf<{ [K in keyof T]: T[K] extends V ? K : never }>;

export const proxyKeyReturn: unknown = new Proxy({}, {
    get: (target, key) => key
});

export function nameOf<T>(name: keyof T): keyof T
export function nameOf<T, V extends T[keyof T]>(f: PropertySelector<T, V>): PropertyTypeSelector<T, V>
export function nameOf<T, V extends T[keyof T]>(nameOrNameOfSelector: keyof T | PropertySelector<T, V>) {

    if(!isFunction(nameOrNameOfSelector)) {
        return nameOrNameOfSelector;
    }
    
    return nameOrNameOfSelector(proxyKeyReturn as T);
}

export const nameof = nameOf;

export type NullOrUndefined = null | undefined;
export type StringOrNullOrUndefined = string | NullOrUndefined;

export interface NodeWithHtml extends Node {
    innerText?: string;
    innerHTML?: string;
}

export const emptyString = '';

export const undefined = void 0;

export const SpaceRegex = /\s+/gm;

export const throwArgumentException = (message: string, argumentName?: string): never => {

    if(isNullOrUndefined(argumentName)) {
        throw new ArgumentException(message);
    }

    throw new ArgumentException(message, argumentName);
}

export const throwArgumentCounTooLong = () => {
    return throwArgumentException("Argument count too long");
}

export const throwNotSupportOperation = (message: string) => {
    throw new NotSupportedException(message);
}

export const throwIfArgumentNotBoolean = (value: unknown, argumentName: string): value is boolean => {
    
    if(!isBoolean(value)) {
        return throwArgumentException("must be boolean", argumentName);
    }

    return false;
}

export const throwIfArgumentNullOrUndefined = (value: unknown, argumentName: string): value is NullOrUndefined => {
    
    if(isNullOrUndefined(value)) {
        throwArgumentException("is null or undefined", argumentName);
    }

    return false;
}

export const valueOrEmpty = (data?: unknown): unknown | string => {

    if (data || data === false || data === 0) {
        return data;
    }

    return '';
};

export function extractText(htmlElement?: HTMLElement | NodeWithHtml | null) {

    if (htmlElement) {

        return htmlElement.innerText || htmlElement.innerHTML || void 0;
    }

    return void 0;
}

export function isArray<T>(value: T[]): value is T[]
export function isArray<T>(value: Array<T>): value is Array<T>
export function isArray<T>(value: T | T[]): value is Array<T>
export function isArray<T>(value: Readonly<T[]>): value is Readonly<T[]>
export function isArray<T>(value: Readonly<Array<T>>): value is Readonly<Array<T>>
export function isArray<T>(value: T | Readonly<T[]>): value is Readonly<Array<T>>
export function isArray(value: unknown): value is Array<unknown>
export function isArray(value: unknown): value is Array<unknown> {
    return Array.isArray(value);
}

export function isFunction(value: any): value is Function {
    const type = value && {}.toString.call(value);
    return type === '[object Function]' || type === '[object AsyncFunction]';
}

export function isDate(value: unknown): value is Date {
    return value instanceof Date;
}

export function isString(value: unknown): value is string {
    return typeof value === 'string';
}

export function isStringOfNumber(strValue: string, numValue: number): boolean {
    return '' + numValue === strValue;
}

export function isNull(value?: unknown): value is null {
    return value === null;
}

export function isUndefined(value?: unknown): value is undefined {
    return value === undefined;
}

export function isNullOrUndefined(value?: unknown | null): value is NullOrUndefined
export function isNullOrUndefined(value?: unknown | null): boolean {
    return isNull(value) || isUndefined(value);
}

export function isEmptyStrOrNullOrUndefined(value?: string | NullOrUndefined): value is NullOrUndefined
export function isEmptyStrOrNullOrUndefined(value?: string | NullOrUndefined): boolean {
    return value === emptyString || isNullOrUndefined(value);
}

export function isEmptyOrNullOrUndefined(value?: unknown): value is NullOrUndefined
export function isEmptyOrNullOrUndefined(value?: unknown): boolean {
    return value === emptyString || isNullOrUndefined(value) || (isArray(value) && value.length < 1);
}

export function isBoolean(value?: unknown): value is boolean {
    return typeof value === 'boolean';
}

export function isNumberOfType(value?: unknown): value is number {
    return typeof value === "number";
}

export function isNumber(value?: unknown | null): value is number {
    
    if (isNumberOfType(value)) {
        return !isNaN(value);
    }

    if (isString(value)) {

        if (/^-?\d*\.?\d*$/.test(value)) {
            return true;
        }
    }

    return false;
}

export function isInt(value?: unknown | null): value is number {
    return Number.isInteger(value);
}

export function isFloat(value?: unknown | null): value is number {
    return isNumber(value);
}

export function notEmptyStrOrNull(value ?: string | null): string | null {

    if (isEmptyOrNullOrUndefined(value)) {

        return null;
    }

    return value;
}

export function strOfNumberOrDefault(value?: string | null, defaultValue = -1): number {

    if (isEmptyOrNullOrUndefined(value)) {

        return defaultValue;
    }

    return parseInt(value);
}

const htmlElementContext: HTMLAnchorElement = document.createElement('a');

export function getAbsoluteUrl(url: string) {
    htmlElementContext.href = url;
    return htmlElementContext.href;
}

export function EqualsOrdinal(left: string | NullOrUndefined, right: string | NullOrUndefined) {

    if (left?.length !== left?.length)
        return false;
    
    if (left?.length === 0)  // span.Length == value.Length == 0
        return true;

    return left?.toLowerCase() == right?.toLowerCase();
}

export function anyMore(target: number, ...args: number[]) {
    for(let arg of args) {
        if(arg > target) {
            return true;
        }
    }
    return false;
}

export function anyIsNaN(...args: number[]) {
    for(let arg of args) {
        if(isNaN(arg)) {
            return true;
        }
    }
    return false;
}

export function anyMoreOrNaN(target: number, ...args: number[]) {
    for(let arg of args) {
        if(isNaN(arg) || arg > target) {
            return true;
        }
    }
    return false;
}

export const AbortErrorName = 'AbortError';
export const AbortedMessage = 'Aborted';
export const Cancel = 'Cancel';
export const CanceledMessage = 'canceled';

export const createAbortError = () => {
	const error = new OperationAbortedException(AbortedMessage);
	error.name = AbortErrorName;
	return error;
};

export type TypedReasonAbortSignal<TReason = any> = AbortSignal & { reason: TReason };
export type TypedEventAbortReason<TReason = any> = Event & { target: TypedReasonAbortSignal<TReason> };

export function getOrCreateAbortError(): any;
export function getOrCreateAbortError<TReason = any>(ev: TypedEventAbortReason<TReason>): TReason;
export function getOrCreateAbortError<TReason = any>(signal: TypedReasonAbortSignal<TReason>): TReason;
export function getOrCreateAbortError(ev: Event): any;
export function getOrCreateAbortError(ev: AbortSignal): any;
export function getOrCreateAbortError(evOrAbortSignal?: Event | TypedReasonAbortSignal<any> | NullOrUndefined): any {

    if(evOrAbortSignal instanceof AbortSignal) {
        if(evOrAbortSignal.reason) {
            return evOrAbortSignal.reason;
        }
    }
    else if (evOrAbortSignal) {
        const target = evOrAbortSignal.target;
        if(target instanceof AbortSignal) {
            if(target.reason) {
                return target.reason;
            }
        }
    }
    
    return createAbortError();
}

export function isError(maybeError: unknown): maybeError is Error {
    return maybeError instanceof Error;
}

export function isCancel(maybeCancel: unknown): boolean {

    if(maybeCancel instanceof OperationCanceledException) {
        return true;
    }

    return typeof maybeCancel == 'object'
        && maybeCancel != null
        && maybeCancel.constructor
        //&& EqualsOrdinal(maybeCancel.constructor.name, Cancel)
        && EqualsOrdinal((maybeCancel as any).message, CanceledMessage);
}

export function isAbortError(maybeError: unknown): maybeError is Error {

    if(isError(maybeError)) {
        
        if (maybeError && (
             maybeError instanceof OperationAbortedException ||
            (maybeError.name && EqualsOrdinal(maybeError.name, AbortErrorName))
        )) {

            return true;
        }
    }

    return false;
}

export interface IGroup<T> {
    [key: string | number]: T[];
}

export function groupBy<T>(array: Readonly<T[]>, predicate: keyof T): IGroup<T>
export function groupBy<T>(array: Readonly<T[]>, predicate: (v: T) => string | number): IGroup<T>
export function groupBy<T>(array: Readonly<T[]>, predicate: (keyof T) | ((v: T) => string | number)) {
    const _isFunction = isFunction(predicate);
    return array.reduce((acc, value) => {
        const key: any = _isFunction ? predicate(value) : value[predicate];
        (acc[key] ||= []).push(value);
        return acc;
    }, {} as IGroup<T>);
}

export function getShortText(text: string | NullOrUndefined, maxLength: number, overflow: string = "...") {

    if(text && text.length > maxLength) {
        const max = Math.max(maxLength - overflow.length, overflow.length);
        return text.slice(0, max) + overflow;
    }

    return text;
}

export function compareExistsProperty(obj1: any, obj2: any, ignoreEmptyOrNullOrUndefined = false) {

    for (let p in obj1) {
        
        if (obj1.hasOwnProperty(p) !== obj2.hasOwnProperty(p)) {

            let ok = ignoreEmptyOrNullOrUndefined
            && isEmptyOrNullOrUndefined(obj1[p])
            && isEmptyOrNullOrUndefined(obj2[p]);

            if(!ok) {
                return false;
            }
        }

        switch (typeof (obj1[p])) {

            case 'object':

                if (
                    obj1[p] instanceof Date &&
                    obj2[p] instanceof Date &&
                    String(obj1[p]) !== String(obj2[p])
                )
                    return false;

                if (!compare(obj1[p], obj2[p], ignoreEmptyOrNullOrUndefined))
                    return false;
                
                break;

            case 'function':

                if (typeof (obj2[p]) == 'undefined'
                || (p != 'compare' && obj1[p].toString() != obj2[p].toString()))
                    return false;

                break;

            default:

                if (obj1[p] != obj2[p]) {

                    let ok = ignoreEmptyOrNullOrUndefined
                    && isEmptyOrNullOrUndefined(obj1[p])
                    && isEmptyOrNullOrUndefined(obj2[p]);

                    if(!ok) {
                        return false;
                    }
                }
        }
    }

    return true;
}

export function equalsArrays<A1 extends Array<unknown>, A2 extends Array<unknown>>(a: A1 | NullOrUndefined, b: A2 | NullOrUndefined) {
    return a && b && a.length === b.length && a.every((v, i) => v === b[i]);
}

export function compare(obj1: any, obj2: any, ignoreEmptyOrNullOrUndefined = false) {
    
    if((isNullOrUndefined(obj1) && !isNullOrUndefined(obj2))
    || (isNullOrUndefined(obj2) && !isNullOrUndefined(obj1))) {
        return false;
    }

    if(!compareExistsProperty(obj1, obj2, ignoreEmptyOrNullOrUndefined)) {
        return false;
    }

    // Проверка объекта obj2 на дополнительные свойства:
    for (let p in obj2) {

        if (typeof (obj1[p]) == 'undefined') {

            const ok = ignoreEmptyOrNullOrUndefined
                    && isEmptyOrNullOrUndefined(obj2[p]);

            if(!ok) {
                return false;
            }
        }
    }

    return true;
}

export * from './TemporaryWraps';
export * from "./Debug";
export * from "./Ref";
export * from "./ClassNames";
export * from "./Delay";
export * from "./doOrAbort";

export function clearSelection() {
    const doc = document as Document & { selection: any };
    if(doc.selection && doc.selection.empty) {
        doc.selection.empty();
    }
    else if(window.getSelection) {
        const sel = window.getSelection();
        sel?.removeAllRanges();
    }
}

export function ignoreSelectEvent(e: MouseEvent | React.MouseEvent) {

    if(e.stopPropagation) e.stopPropagation();
    if(e.preventDefault) e.preventDefault();

    const ee = (e as React.MouseEvent).nativeEvent ? (e as React.MouseEvent).nativeEvent : e as MouseEvent;
    ee.cancelBubble = true;
    ee.returnValue = false;
    
    return false;
}

export interface Color {
    red: number,
    green: number,
    blue: number
}

export function intToRgb(int: number): Color {
    
    if(isNaN(int)
    || typeof int !== 'number'
    || Math.floor(int) !== int
    || int < 0 || int > 16777215)
        throw new ArgumentException('Must provide an integer between 0 and 16777215');
  
    const red = int >> 16;
    const green = int - (red << 16) >> 8;
    const blue = int - (red << 16) - (green << 8);
  
    return {
        red: red,
        green: green,
        blue: blue
    }
}

export function colorToStyle(color: Color | string | number | NullOrUndefined): string | undefined {
    
    if (isNullOrUndefined(color)) {
        return undefined;
    }

    if (typeof color === "string") {
        const int = parseInt(color);
        color = intToRgb(int);
    }

    else if (typeof color === "number") {
        color = intToRgb(color);
    }

    return `rgb(${color.red},${color.green},${color.blue})`;
}