import { DetectedInfo } from "./DetectedInfo";
import { ArgumentSelectors, ArgumentSelectorLikeIf, ArgumentSelectorWithCount, ArgumentSelectorWithMore, ArgumentSelectorWithLess } from "./ArgumentSelectors";

export class ArgumentScopeDetector {
    
    /**
    * Извлекает значение аргумента по условиям
    * @param inputArguments -> Входные аргументы функции
    * @param selectors      -> Селектор с условием выборки значения
    * @param defaultValue   -> Значение по умолчанию
    */
    public static Detect<T>(inputArguments: IArguments, selectors: ArgumentSelectors, defaultValue: T | null): DetectedInfo<T> {

        for (let selector of selectors) {

            // Проверка условия валидности
            if (typeof (selector as ArgumentSelectorLikeIf).like != "undefined"
                && (selector as ArgumentSelectorLikeIf).like == false) {

                continue;
            }

            // Проверка условия соответствия количества аргументов
            const argCount = (selector as ArgumentSelectorWithCount).paramCount;
            
            if ((typeof (selector as ArgumentSelectorWithCount).paramCount !== "undefined" && inputArguments.length !== argCount)
                && ((typeof (selector as ArgumentSelectorWithMore).orMoreCount !== "undefined" && inputArguments.length < argCount)
                    || (typeof (selector as ArgumentSelectorWithLess).orLessCount !== "undefined" && inputArguments.length > argCount))) {

                continue;
            }
            
            // Проверка соответствия типа
            if ((typeof selector.paramType == "string" && typeof inputArguments[selector.paramIndex] == selector.paramType)
                || (typeof selector.paramType == "function"
                    && (inputArguments[selector.paramIndex] instanceof selector.paramType
                        || (typeof selector.paramType.constructor !== "undefined"
                            && inputArguments[selector.paramIndex] instanceof selector.paramType.constructor)))) {
                
                return {
                    hasValue: true,
                    paramIndex: selector.paramIndex,
                    value: inputArguments[selector.paramIndex] as T
                };
            }
        }

        return {
            hasValue: false,
            paramIndex: -1,
            value: defaultValue
        };
    }
}