import { Moment } from "moment"
import { useEffect, useRef } from "react"
import { getFieldListForFilter, getForcastObjectListForFilter, getProductiveActivityTypeListForFilter, getProjectGroupListForFilter, getSkillListForFilter, getTagListForFilter, getTimeZoneList, IdType } from "../../Entities/Database";
import { OperationAbortedByMergeException } from "../../System/OperationAbortedByMergeException";
import { equalsArrays } from "../../System/Utils";
import { getAbortCallback, useListLoader } from "../../Hooks/useListLoader";
import useAuth from "../../Hooks/useAuth";

export interface ListProviderProps {
    from?: Moment | null,
    to?: Moment | null,
    lang?: string,
    projectGroupIds?: IdType[]
}

export function useFilterDictionaryProvider({
    from,
    to,
    lang,
    projectGroupIds
}: ListProviderProps) {

    const didMount = useRef(false);
    const auth = useAuth();

    const [projectGroupList, startUpdateProjectGroupList, updateProjectGroupList] = useListLoader(
        (signal: AbortSignal) => getProjectGroupListForFilter(auth, signal),
        [auth.data?.staticId]
    );

    const [fieldList, startUpdateFieldList, updateFieldList] = useListLoader(
        (signal: AbortSignal) => getFieldListForFilter(auth, signal),
        [auth.data?.staticId]
    );

    const [productiveActivityTypeList, startProductiveActivityTypeList, updateProductiveActivityTypeList] = useListLoader(
        (signal: AbortSignal) => getProductiveActivityTypeListForFilter(auth, projectGroupIds, signal),
        [auth.data?.staticId, projectGroupIds]
    );

    const [tagList, startUpdateTagList, updateTagList] = useListLoader(
        (signal: AbortSignal) => getTagListForFilter(auth, projectGroupIds, signal),
        [auth.data?.staticId, projectGroupIds]
    );
    
    const [forcastObjectList, startUpdateForcastObjectList, updateForcastObjectList] = useListLoader(
        (signal: AbortSignal) => getForcastObjectListForFilter(auth, projectGroupIds, signal),
        [auth.data?.staticId, projectGroupIds]
    );

    const [skillList, startUpdateSkillList, updateSkillList] = useListLoader(
        (signal: AbortSignal) => getSkillListForFilter(auth, projectGroupIds, signal),
        [auth.data?.staticId, projectGroupIds]
    );

    const [timeZoneList, startUpdateTimeZoneList, updateTimeZoneList] = useListLoader(
        (signal: AbortSignal) => getTimeZoneList(auth, lang, signal),
        [auth.data?.staticId, lang]
    );

    const prevProjectGroupIds = useRef(projectGroupIds);
    const loadDependentList = () => {

        // Кастомное сравнение
        if(!didMount.current
        || equalsArrays(prevProjectGroupIds.current, projectGroupIds)) {
            return;
        }

        const abortController = new AbortController();
        const signal = abortController.signal;

        (async () => {

            await Promise.all([
                updateProductiveActivityTypeList(signal),
                updateTagList(signal),
                updateForcastObjectList(signal),
                updateSkillList(signal),
            ]);

            if (!signal.aborted) {
                prevProjectGroupIds.current = projectGroupIds;
            }
        })();
        
        return () => {
            const reason = new OperationAbortedByMergeException();
            abortController.abort(reason);
        }
    }

    const reload = () => getAbortCallback(
        projectGroupList.load           ? undefined : startUpdateProjectGroupList(),
        fieldList.load                  ? undefined : startUpdateFieldList(),
        tagList.load                    ? undefined : startUpdateTagList(),
        forcastObjectList.load          ? undefined : startUpdateForcastObjectList(),
        skillList.load                  ? undefined : startUpdateSkillList(),
        timeZoneList.load               ? undefined : startUpdateTimeZoneList(),
        productiveActivityTypeList.load ? undefined : startProductiveActivityTypeList(),
    );

    useEffect(() => {

        if (didMount.current && !auth.isLoging) {
            return getAbortCallback(
                startUpdateProjectGroupList(),
                startUpdateFieldList(),
                startUpdateTimeZoneList(),
            );
        }

    }, [auth.data?.staticId]);

    useEffect(loadDependentList, [projectGroupIds]);
    
    useEffect(() => {
        didMount.current = true;
        return () => {
            didMount.current = false;
        }
    }, []);

    return {
        reload,
        projectGroupList,
        fieldList,
        productiveActivityTypeList,
        tagList,
        forcastObjectList,
        skillList,
        timeZoneList
    }
}