import { Debug } from "./Debug";

export const currectPropertyName = "currect";
export const outPropertyName = "out";

export type DefaultAccessIndex = 0;
export type Ref<T> = {
    [index in DefaultAccessIndex]: T;
} & {
    currect: T;
};

export type Out<T> = {
    [index in DefaultAccessIndex]: T;
} & {
    out: T
}

export type ref<T> = Ref<T>;
export type out<T> = Out<T>;

export function createRef<T>(value: T): Ref<T> {
    const ref = new Array<T>(1) as any;
    ref[0] = value;
    Object.defineProperty<Ref<T>>(ref, currectPropertyName, {
        get: () => { return ref[0]; },
        set: (v: T) => { ref[0] = v; }
    });
    return ref;
}

export function createOut<T>(): out<T>
export function createOut<T>(value: T): out<T>
export function createOut<T>(value?: T) {
    const ref = new Array<T | undefined>(1);
    ref[0] = value;
    Object.defineProperty(ref, outPropertyName, {
        get: () => { Debug.Fail("Out parameter has not get property") },
        set: (v: T) => { ref[0] = v; }
    });
    return ref as unknown as out<T>;
}

export function setRef<T>(ref: Ref<T> | Out<T> | undefined | null, value: T) {
    if(ref) {
        ref[0] = value;
    }
}