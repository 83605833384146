import { IHasEmployeeDayKey, IWithEmployable } from "./Database";

export const backendUrls = {
    "API1": "https://api.wfm.astrapage.ru",
    "Dev1": "http://localhost:5016",
};

export enum NotificationStatus {
    New      = 0,
    Sent     = 1,
    Received = 2,
    Read     = 3,
}

export enum NotificationType {
    Success = 1,
    Info    = 2,
    Warning = 3,
    Error   = 4,
}

export enum SystemEventType {
    NA             = 0,
    ShiftLate      = 1,
    ShiftAbsence   = 2,
    ShiftTruancy   = 3,
    ShiftLeave     = 4,
    NotInWork      = 5,
    UnPlannedBreak = 6,
    ShiftStart     = 10,
    BreakStart     = 11,
    EventStart     = 12,
    EventPlanned   = 13,
    ActivityChange = 14,
    BreakEnd       = 15,
    TechProblem    = 20,
}

export interface IScheduleEmployeeDayEvent extends IHasEmployeeDayKey, IWithEmployable {
    systemEventType: SystemEventType,
}

export type TEventData = IScheduleEmployeeDayEvent;

export interface INotificationBase {
    id: number,
    type: NotificationType,
    data: string,
    subj: string,
    body: string,
    dt: string,
}

export interface INotification extends INotificationBase {
    status: NotificationStatus,
}

export interface IRuntimeNotification extends INotificationBase {
    from: string,
    to: string,
    limitSec: number,
}

export interface IInitData {
    notifyUnread: number,
}

export enum MessageTypeStr {
    Info    = 'info',
    Success = 'success',
    Error   = 'error',
    Warning = 'warning',
}

export enum MessageType {
    Info    = 1,
    Success = 2,
    Error   = 3,
    Warning = 4,
}

export interface IReloadAppMessage {
    title: string,
    message: string,
}

export interface IPopupMessage {
    type: MessageType,
    message: string,
    duration: number | null,
}


export function convertMessageTypeToStr(type: MessageType) {
    switch (type) {
        case MessageType.Info: return MessageTypeStr.Info;
        case MessageType.Success: return MessageTypeStr.Success;
        case MessageType.Error: return MessageTypeStr.Error;
        case MessageType.Warning: return MessageTypeStr.Warning;
        default: return MessageTypeStr.Info;
    }
}