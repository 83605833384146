import { Collapse, Segmented, Space, Switch } from "antd";
import { TableLocale } from "antd/es/table/interface";
import moment, { Moment } from "moment";
import { forwardRef, memo, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { IScheduleBoardEmployee, IScheduleBoardFilter, IDaysInfoblockItem, RecordType } from "../../Entities/Database";
import { ILoadListState } from "../../Reducer/LoadListReducer";
import { debounce } from "../../System/TemporaryWraps";
import { classNames, ignoreSelectEvent } from "../../System/Utils";
import { ViewInfoBlockType } from ".";
import VirtualTable, { ColumnsType, ColumnType, Grid, VirtualTableProps } from "antd-virtual-table";
import { dateKeyFormat, infoblockCollapsePanelKey, infoBlockDisplayed, plugShouldCellUpdateOnScroll } from "./helpers";
import 'moment/locale/ru';
import ru from 'antd/lib/locale/ru_RU';
import './infoblock.css';

const scrollWidth = 20;
const infoblockRowHeight = 34;
const minInfoblockHeight = infoblockRowHeight * 1 + scrollWidth;
const maxInfoblockItems = 10;

export interface IInfoBlockLocale {
    momentLocale: moment.LocaleSpecifier,
    paramColumn: string,
    statistic: string,
    viewTable: string,
    viewGraph: string,
    tableLocale: TableLocale
}

export const localeRu: IInfoBlockLocale = {
    paramColumn: 'Параметр',
    momentLocale: 'ru',
    tableLocale: ru.Table as TableLocale,
    statistic: 'Статистика',
    viewTable: 'Таблица',
    viewGraph: 'График',
}

export interface IInfoBlockRef {
    collapseHeaderRef: HTMLDivElement,
    grid: Grid<IDaysInfoblockItem>,
    gridOuter: HTMLDivElement
}

export interface IInfoBlockProps {
    loading?: boolean,
    className?: string,
    viewType?: ViewInfoBlockType,
    resize?: boolean,
    locale?: IInfoBlockLocale,
    width: number,
    height: number,
    firstColumnWidth: number,
    columnWidth: number,
    filter?: IScheduleBoardFilter,
    employees?: ILoadListState<IScheduleBoardEmployee>,
    data?: ILoadListState<IDaysInfoblockItem>,
    loadingRecords?: boolean,
    recordType: RecordType,
    onChangeRecordType?: (type: RecordType) => void,
    onClickHeaderColumn?: (date: Moment, event: React.MouseEvent) => void,
    onDoubleClickHeaderColumn?: (date: Moment, event: React.MouseEvent) => void,
    onContextMenuHeaderColumn?: (date: Moment, event: React.MouseEvent) => false | void,
    onInfoBlockCollapseChange?: (key: string | string[]) => void,
    onScroll?: VirtualTableProps<IDaysInfoblockItem>['onScroll'],
    onMouseDownToResizer?: React.DOMAttributes<HTMLDivElement>['onMouseDown'],
    onChangeViewType?: (value: ViewInfoBlockType) => void, 
}

export function InternalInfoBlock({
    loading,
    className,
    viewType,
    resize,
    locale = localeRu,
    width,
    height,
    firstColumnWidth,
    columnWidth,
    filter,
    recordType,
    employees,
    loadingRecords,
    data,
    onChangeRecordType,
    onClickHeaderColumn,
    onDoubleClickHeaderColumn,
    onContextMenuHeaderColumn,
    onInfoBlockCollapseChange,
    onScroll,
    onMouseDownToResizer,
    onChangeViewType,
}: IInfoBlockProps, ref: React.ForwardedRef<IInfoBlockRef>) {

    const collapseHeaderRef = useRef<HTMLDivElement>(null);
    const infoBlockGridRef = useRef<Grid<IDaysInfoblockItem>>(null);
    const infoBlockGridOuterRef = useRef<HTMLDivElement>(null);

    const handleChangeRecordType = useCallback((record: IDaysInfoblockItem) => {
        onChangeRecordType && onChangeRecordType(record.recordType);
    }, [onChangeRecordType]);

    const tmpInfoBlockTableColumns = useMemo((): ColumnsType<IDaysInfoblockItem> => {

        if(!filter || !filter.from || !filter.to) {
            return [];
        }

        const momentLocale = locale.momentLocale;
        const from = filter.from.clone().startOf("day");
        const to   = filter.to.clone().endOf("day");
        const columns: ColumnsType<IDaysInfoblockItem> = [];

        let dayIndex = 0;
        let day = from;

        while (day < to) {

            const currentDayIndex = dayIndex++;
            const currectDay = day.clone();
            const currectKey = currectDay.format(dateKeyFormat);
            const internalCurrentDay = day.clone();

            columns.push({
                key: currectKey,
                title: internalCurrentDay.locale(momentLocale).format('DD.MM (dd)'),
                width: columnWidth,
                ellipsis: true,
                onHeaderCell: () => ({
                    className: `infoblock-day-column-title col-day-idx-${currentDayIndex}`
                }),
                onCell: () => ({
                    className: `infoblock-day-value col-day-idx-${currentDayIndex}`,
                }),
                shouldCellUpdate: plugShouldCellUpdateOnScroll,
                render: (value, record, index) => {
                    const dayValue = record && record[currectKey];
                    return dayValue;
                }
            });

            day = from.add(1, 'day');
        }

        columns[columns.length - 1].width += scrollWidth;
        return columns;

    }, [
        scrollWidth,
        columnWidth,
        filter?.from?.valueOf(),
        filter?.to?.valueOf(),
        onContextMenuHeaderColumn,
        onClickHeaderColumn,
        onDoubleClickHeaderColumn,
        locale.momentLocale,
    ]);

    const infoBlockTableColumns = useMemo(() => {

        const firstColumn: ColumnType<IDaysInfoblockItem> = {
            key: 'param',
            title: locale.paramColumn,
            width: firstColumnWidth,
            fixed: 'left',
            ellipsis: true,
            onHeaderCell: () => ({
                className: "infoblock-day-column-title"
            }),
            shouldCellUpdate: plugShouldCellUpdateOnScroll,
            onCell: () => ({
                style: {
                    background: '#fff'
                }
            }),
            render: (value, record, index, isScrolling) => record && (
                <Space
                    style={{
                        width: '100%',
                        padding: '5px',
                    }}
                >
                    <Switch
                        checked={recordType == record.recordType}
                        loading={recordType == record.recordType && (employees?.loading || loadingRecords)}
                        disabled={loading || record.disabled}
                        size='small'
                        onClick={(e) => handleChangeRecordType(record)}
                    />
                    <div 
                        style={{
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            width: '100%',
                            fontSize: 12
                        }}
                    >
                        {record.paramName}
                    </div>
                </Space>
        )};

        return [firstColumn, ...tmpInfoBlockTableColumns];
    }, [
        firstColumnWidth,
        tmpInfoBlockTableColumns,
        recordType,
        handleChangeRecordType,
        employees?.loading,
        loading,
        loadingRecords,
        locale.paramColumn
    ]);

    useEffect(() => {
        infoBlockGridRef.current?.resetAfterColumnIndex(0);
    }, [filter?.from?.valueOf(),
        filter?.to?.valueOf()
    ]);

    useImperativeHandle(ref, () => ({
        collapseHeaderRef: collapseHeaderRef.current?.parentElement?.parentElement as unknown as HTMLDivElement,
        grid: infoBlockGridRef.current!,
        gridOuter: infoBlockGridOuterRef.current!,
    }));

    return (
        <Collapse
            bordered={false}
            className="infoblock-table-collapse"
            defaultActiveKey={infoblockCollapsePanelKey}
            onChange={onInfoBlockCollapseChange}
            items={[
                {
                    key: infoblockCollapsePanelKey,
                    label: (
                        <div ref={collapseHeaderRef}>
                            <Space
                                size={24}
                            >
                                {locale.statistic}
                                <Segmented
                                    size="small"
                                    value={viewType}
                                    options={[
                                        {label: locale.viewTable, value: ViewInfoBlockType.Table},
                                        {label: locale.viewGraph, value: ViewInfoBlockType.Chart, disabled: true}
                                    ]}
                                    onClick={e => {
                                        e.stopPropagation();
                                    }}
                                    onChange={(value) => onChangeViewType && onChangeViewType(value as unknown as ViewInfoBlockType)}
                                />
                            </Space>
                        </div>
                    ),
                    children: (
                        <div className="full-ant-collapse-content-box infoblock-vitrual-table-wrapper">
                            <VirtualTable
                                rerenderFixedColumnOnHorizontalScroll={false}
                                gridRef={infoBlockGridRef}
                                outerGridRef={infoBlockGridOuterRef}
                                size="small"
                                rowHeight={infoblockRowHeight}
                                rowKey={(item) => item.recordType}
                                className={classNames(className, "infoblock-table")}
                                bordered
                                columns={infoBlockTableColumns}
                                loading={loading || data?.loading}
                                dataSource={data?.items}
                                pagination={false}
                                onScroll={onScroll}
                                scroll={{
                                    x: width,
                                    y: height,
                                    scrollToFirstRowOnChange: false
                                }}
                            />
                            {resize !== false && <div
                                className="infoblock-resizer"
                                onMouseDown={onMouseDownToResizer}
                            />}
                        </div>
                    )
                }
            ]}
        />
    )
}

export const InfoBlock = forwardRef(InternalInfoBlock);

export interface IInfoBlockWithResizerRef extends IInfoBlockRef {
    wrapper: HTMLElement
}

export interface IInfoBlockWithResizerProps extends Omit<IInfoBlockProps, 'height' | 'onInfoBlockCollapseChange'> {
    height?: number,
    syncInfoBlockScroll?: () => void,
    onChangeHeight?: (value: number) => void,
    onCollapseChange?: (collapsed: boolean) => void,
}

export function InternalInfoBlockWithResizer({
    height,
    onScroll,
    syncInfoBlockScroll,
    onChangeHeight,
    onCollapseChange,
    ...infoBlockProps
}: IInfoBlockWithResizerProps, ref: React.ForwardedRef<IInfoBlockWithResizerRef>) {

    const { data } = infoBlockProps;

    const infoBlockRef = useRef<IInfoBlockRef>(null);
    const infoBlockWrapperRef = useRef<HTMLDivElement | null>(null);

    useImperativeHandle(ref, () => ({
        wrapper: infoBlockWrapperRef.current!,
        collapseHeaderRef: infoBlockRef.current?.collapseHeaderRef!,
        grid: infoBlockRef.current?.grid!,
        gridOuter: infoBlockRef.current?.gridOuter!,
    }));

    const [maxInfoBlockHeight, setMaxInfoBlockHeight] = useState(infoblockRowHeight * 5 + scrollWidth);
    const [infoBlockHeight, setInfoBlockHeight] = useState(height || infoblockRowHeight * 5 + scrollWidth);
    const infoBlockCollapseActiveKeysRef = useRef<string | string[]>([infoblockCollapsePanelKey]);

    const mouseDownEventHandler = useCallback((mouseDownEvent: React.MouseEvent) => {

        const startSize = infoBlockHeight;
        const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };

        const updateHeight = (mouseMoveEvent: MouseEvent) => {

            const height = startSize - startPosition.y + mouseMoveEvent.pageY;
            const stepHeight = Math.floor(height / infoblockRowHeight) * infoblockRowHeight + scrollWidth;
            const normalizeHeight = Math.max(minInfoblockHeight, Math.min(maxInfoBlockHeight, stepHeight));

            setInfoBlockHeight(normalizeHeight);

            if(onChangeHeight) {
                onChangeHeight(normalizeHeight);
            }
        }

        const onMouseMove = (mouseMoveEvent: MouseEvent) => {
            ignoreSelectEvent(mouseMoveEvent);
            updateHeight(mouseMoveEvent);
        }

        const onMouseUp = () => {

            document.removeEventListener("mousemove", onMouseMove);
            // uncomment the following line if not using `{ once: true }`
            // document.body.removeEventListener("mouseup", onMouseUp);
        }
        
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp, { once: true });

    }, [minInfoblockHeight, maxInfoBlockHeight, infoBlockHeight, onChangeHeight]);

    const handleInfoBlockCollapseChange = useCallback((key: string | string[]) => {

        if(syncInfoBlockScroll) {
            syncInfoBlockScroll();
        }

        infoBlockCollapseActiveKeysRef.current = key;

        if(onChangeHeight) {
            let currentHeight: number = -1;
            setInfoBlockHeight(height => currentHeight = height);
            onChangeHeight(currentHeight);
        }
        
        if(onCollapseChange) {
            onCollapseChange(infoBlockDisplayed(key));
        }

    }, [onChangeHeight, syncInfoBlockScroll, onCollapseChange]);

    const handleScroll = useCallback<NonNullable<typeof onScroll>>((props) => {
        
        if(onScroll && infoBlockDisplayed(infoBlockCollapseActiveKeysRef.current)) {
            onScroll(props);
        }

    }, [onScroll]);

    useEffect(() => {
        
        if(!data?.load) {
            return;
        }
        
        const len = Math.min(data?.items.length ?? 5, maxInfoblockItems);
        const maxInfoBlockHeight = infoblockRowHeight * len + scrollWidth;
        setMaxInfoBlockHeight(maxInfoBlockHeight);

    }, [infoblockRowHeight, data?.items, scrollWidth]);

    useEffect(() => {

        const newHeight = Math.max(minInfoblockHeight, Math.min(maxInfoBlockHeight, infoBlockHeight));

        setInfoBlockHeight(newHeight);

        if (onChangeHeight) {
            onChangeHeight(infoBlockHeight);
        }

    }, [infoBlockHeight, minInfoblockHeight, maxInfoBlockHeight]);

    useEffect(() => {

        const updateSize = () => {

            if(onChangeHeight) {
                
                let currentHeight: number = -1;
                setInfoBlockHeight(height => currentHeight = height);
                onChangeHeight(currentHeight);
            }
        }

        const handler = debounce(updateSize, 50);
        window.addEventListener('resize', handler, { passive: true });
        handler();
        return () => window.removeEventListener('resize', handler);

    }, [onChangeHeight]);

    return (
        <div ref={infoBlockWrapperRef}>
            <InfoBlock
                {...infoBlockProps}
                ref={infoBlockRef}
                height={infoBlockHeight}
                onScroll={handleScroll}
                onInfoBlockCollapseChange={handleInfoBlockCollapseChange}
                onMouseDownToResizer={mouseDownEventHandler}
            />
        </div>
    )
}

export const InfoBlockWithResizer = memo(forwardRef(InternalInfoBlockWithResizer));

export default InfoBlockWithResizer;