import { DownloadOutlined } from "@ant-design/icons";
import { Button, Dropdown, message as AntdMessage, MenuProps } from "antd";
import { BaseButtonProps } from "antd/es/button/button";
import { downloadReport, getReportListWithoutParams } from "../../Entities/Database";
import { useEffect, useState } from "react";
import useAuth from "../../Hooks/useAuth";
import useEntityLoader from "../../Hooks/useEntityLoader";

const abortByClose = {};
const defaultMenuItems = [{ key: -1, label: 'Загрузка...', disabled: true }];

export interface IReportListWithoutParamsProps {
    size: BaseButtonProps['size'],
    disabled?: boolean,
}

export function ReportListWithoutParams({
    size,
    disabled,
}: IReportListWithoutParamsProps) {
    
    const auth = useAuth();
    const [menuItems, setMenuItems] = useState<MenuProps['items']>(() => defaultMenuItems);
    const [message, messageContextHolder] = AntdMessage.useMessage();
    const [loading, setLoading] = useState(false);

    const [reportsState, reloadReports, , abortReportsLoad] = useEntityLoader(signal => getReportListWithoutParams(auth, signal), [auth.data?.staticId]);

    useEffect(() => {

        if (reportsState.load && reportsState.entity) {

            const global: MenuProps['items'] = [];
            const items = reportsState.entity;
            const indexes: Record<number, { children: any[] | undefined }> = {};

            for (const item of items) {

                const menuItem = {
                    key: item.id,
                    disabled: loading,
                    label: item.caption,
                    onClick: item.procName ? () => downloadReportFn(item.procName) : undefined,
                    children: item.procName ? undefined : [],
                }

                if (!item.procName) {
                    indexes[item.id] = menuItem;
                }

                if (item.parentId === null) global.push(menuItem);
                else indexes[item.parentId]?.children?.push(menuItem);
            }

            setMenuItems(global);
        }
        else {
            setMenuItems(defaultMenuItems);
        }

    }, [loading, reportsState.entity]);

    const handleOpenChange = (open: boolean) => {
        
        if (open) {
            reloadReports();
        }
        else {
            abortReportsLoad(abortByClose);
        }
    }

    const downloadReportFn = async (procName: string) => {
        try {
            setLoading(true);
            await downloadReport(auth, procName);
        }
        catch (ex) {
            console.error(ex);
            message.error('Не удалось выгрузить отчет');
        }
        finally {
            setLoading(false);
        }
    }

    /**
    [
        { key: 1, label: 'Интервалы', disabled: loading, onClick: () => downloadReportFn(ReportType.Intervals)},
        { key: 2, label: 'Часы', disabled: loading, onClick: () => downloadReportFn(ReportType.Time)},
        { key: 3, label: 'Корректировки', disabled: loading, onClick: () => downloadReportFn(ReportType.Changes)},
        { key: 4, label: 'Дисциплина', disabled: loading, onClick: () => downloadReportFn(ReportType.Discipline)},
        { key: 5, label: 'Расписание День/Час', disabled: loading, onClick: () => downloadReportFn(ReportType.ScheduleDayOfHour)},
        { key: 6, label: 'ActiveTime День/15мин', disabled: loading, onClick: () => downloadReportFn(ReportType.ActiveTimeDayOf15Min)},
        { key: 7, label: 'ActiveTime День/Час', disabled: loading, onClick: () => downloadReportFn(ReportType.ActiveTimeDayOfHour)},
        { key: 8, label: 'Дисциплина.Заметки', disabled: loading, onClick: () => downloadReportFn(ReportType.DisciplineNotes)},
        { key: 9, label: 'SL Объект Прогнозирования День/Час', disabled: loading, onClick: () => downloadReportFn(ReportType.SLOPDH)},
    ]
    */

    return (
        <>
            <Dropdown
                trigger={['click']}
                onOpenChange={handleOpenChange}
                menu={{items: menuItems}}
            >
                <Button
                    title='Выгрузить в XLSX'
                    icon={<DownloadOutlined />}
                    size={size}
                    disabled={disabled || reportsState.loading}
                    loading={loading || reportsState.loading}
                />
            </Dropdown>
            {messageContextHolder}
        </>
    );
}

export default ReportListWithoutParams;