export interface IBounds {
    left: number,
    top: number,
    bottom: number,
    right: number
}

export const initialBoundsState: IBounds = {
    left: 0,
    top: 0,
    bottom: 0,
    right: 0
}