import { Menu, MenuProps, MenuRef } from "antd";
import { forwardRef, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { classNames } from "../../../System/ClassNames";
import { refSetter } from "../../shared/helpers";
import { calculateXY, isHeaderColumn, isOutsideClick, ItemType } from "./helpers";

import './style.css';

export interface ICellContextMenuLocale {
    edit: string,
    info: string,
    shiftReduction: string,
    shiftProlong: string,
    shiftChange: string,
    vacation: string,
    sick: string,
    shiftExchange: string,
}

const localeRu: ICellContextMenuLocale = {
    edit: 'Редактировать',
    info: 'Детальная информация',
    shiftReduction: 'Сокращение смены',
    shiftProlong: 'Продление смены',
    shiftChange: 'Перенос/обмен смены',
    vacation: 'Отпуск',
    sick: 'Больничный',
    shiftExchange: 'Предложить подработку',
}

type Unpacked<T> = T extends (infer U)[] ? U : T;

export type ContextMenuItem = keyof ICellContextMenuLocale;
export type CellContextMenuItems = (Unpacked<MenuProps['items']> & { key: ContextMenuItem })[];

export type OpenChangeReason = 'escape-down' | 'tab-down' | 'wheel' | 'resize' | 'outside-click';

let unicalSubPoupId = 0;

export interface ICellContextMenuProps extends Omit<MenuProps, 'items' | 'onOpenChange' | 'onSelect'> {
    popupRef?: React.Ref<HTMLDivElement>,
    locale?: ICellContextMenuLocale,
    open?: boolean,
    item?: ItemType,
    outerGridRef?: React.RefObject<HTMLElement>,
    onSelect?: (key: ContextMenuItem, item: ItemType) => void,
    onOpenChange?: (value: boolean, reason?: OpenChangeReason) => void,
    onMenuOpenChange?: MenuProps['onOpenChange'],
}

export const CellContextMenu = forwardRef<MenuRef, ICellContextMenuProps>(({
    popupRef: externalPopupRef,
    open: externalOpen,
    outerGridRef,
    locale = localeRu,
    item,
    onSelect,
    onOpenChange,
    onMenuOpenChange,
    ...menuProps
}, externalRef) => {

    const popupRef = useRef<HTMLDivElement>(null);
    const menuRef = useRef<MenuRef>(null);

    const [subMenuId] = useState(() => unicalSubPoupId++);
    const [open, setOpen] = useState(externalOpen || false);
    const [visible, setVisible] = useState(false);
    const [{x, y}, setXY] = useState({x: 0, y: 0});
    
    const items = useMemo((): CellContextMenuItems => isHeaderColumn(item)
    ?   [ 
            { key: 'shiftExchange', label: locale.shiftExchange },
            { key: 'shiftReduction', label: locale.shiftReduction, },
        ]
    :   [
            { key: 'edit', label: locale.edit, },
            { key: 'info', label: locale.info, },
            { key: 'shiftReduction', label: locale.shiftReduction, },
            { key: 'shiftProlong', label: locale.shiftProlong, },
            { key: 'shiftChange', label: locale.shiftChange },
            { key: 'vacation', label: locale.vacation },
            { key: 'sick', label: locale.sick },
            //{ key: 'sub-menu', label: 'Подработка', popupClassName: `cell-context-menu cell-context-sub-menu cell-context-sub-menu-key-{${subMenuId}}`, children: [
            //    { key: 'edit-1', label: internalLocale.edit, },
            //    { key: 'info-2', label: internalLocale.info, }
            //] }
        ], [item, locale]);

    useEffect(() => {

        if(externalOpen !== undefined) {
            setOpen(externalOpen);
        }

    }, [externalOpen]);

    useEffect(() => {

        if(!open) {
            return;
        }

        const ref = popupRef;
        const handleKeyDown = (event: KeyboardEvent) => {

            if (event.code === "Escape" || event.code === "Tab") {

                const code = event.code.toLowerCase();

                setOpen(false);

                if(onOpenChange) {
                    onOpenChange(false, `${code}-down` as unknown as OpenChangeReason);
                }

                event.preventDefault();
                return false;
            }
        }

        function handleClickOutside(event: MouseEvent) {

            const contextMenu = ref.current;
            if (isOutsideClick(event, contextMenu, subMenuId)) {

                setOpen(false);

                if(onOpenChange) {
                    onOpenChange(false, 'outside-click');
                }
            }
        }

        function handleWheel(event: WheelEvent) {

            setOpen(false);

            if(onOpenChange) {
                onOpenChange(false, 'wheel');
            }
        }

        function handleResize(event: UIEvent) {

            setOpen(false);

            if(onOpenChange) {
                onOpenChange(false, 'resize');
            }
        }
        
        document.addEventListener("mousedown", handleClickOutside, false);
        document.addEventListener('keydown', handleKeyDown, false);
        document.addEventListener('wheel', handleWheel, false);
        window.addEventListener('resize', handleResize, { passive: true });

        return () => {
            document.removeEventListener("mousedown", handleClickOutside, false);
            document.removeEventListener('keydown', handleKeyDown, false);
            document.removeEventListener('wheel', handleWheel, false);
            window.removeEventListener('resize', handleResize);
        }

    }, [subMenuId, open, setOpen, onOpenChange, setXY, setVisible]);

    useLayoutEffect(() => {

        if(open
        && item
        && menuRef.current
        && popupRef.current
        && outerGridRef
        && outerGridRef.current) {

            const {x, y} = calculateXY(
                outerGridRef.current,
                popupRef.current,
                item
            );

            setXY({x, y});
            setVisible(x > 0 && y > 0);
            return;
        }

        setXY({x: -99999, y: -99999});
        setVisible(false);

    }, [open, outerGridRef]);

    useEffect(() => {

        if(visible) {

            if(item
            && menuRef.current
            && popupRef.current
            && outerGridRef
            && outerGridRef.current) {
    
                const {x, y} = calculateXY(
                    outerGridRef.current,
                    popupRef.current,
                    item
                );
    
                setXY({x, y});
                setVisible(x > 0 && y > 0);
                return;
            }
        }

    }, [visible, x, y]);

    useEffect(() => {

        if(visible && open && menuRef.current) {
            (menuRef.current.menu?.list.firstChild as HTMLElement | undefined | null)?.focus();
        }

    }, [visible, open]);

    return (
        <div
            ref={refSetter(popupRef, externalPopupRef)}
            className="cell-context-menu-wrapper"
            style={{
                display: visible && x && y ? 'block' : 'none',
                position: 'absolute',
                left: x,
                top: y
            }}
        >
            <Menu
                {...menuProps}
                defaultActiveFirst
                defaultValue={'edit'}
                defaultSelectedKeys={['edit']}
                className={classNames("cell-context-menu", menuProps.className)}
                selectedKeys={[]}
                ref={refSetter(menuRef, externalRef)}
                onOpenChange={onMenuOpenChange}
                items={open ? items : []}
                onSelect={(info) => {

                    info.domEvent.preventDefault();

                    if(onSelect && item && info.selectedKeys.length === 1) {
                        const key = info.selectedKeys[0] as ContextMenuItem;
                        onSelect(key, item);
                    }
                }}
            />
        </div>
    );
});