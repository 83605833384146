import { Card, CardProps, Col, Collapse, ConfigProvider, Empty, Row, Skeleton } from "antd";
import moment from "moment";
import { useEffect, useRef, useContext } from "react";
import { getScheduleItemHistoryList, IEmployeeScheduleDay, IComment, IdType, IHasEmployeeDayKey } from "../../Entities/Database";
import { IAuthState } from "../../Entities/IAuthProvider";
import useAuth from "../../Hooks/useAuth";
import { ILoaderInitProps, useListLoader } from "../../Hooks/useListLoader";
import { initialLoadListState } from "../../Reducer/LoadListReducer";
import { IAuthData } from "../../Server/IAuthData";
import { groupBy } from "../../System/Utils";
import { AgendaList } from "../Agenda/list";
import { AgendaEvent } from "../Agenda/list-item";

import './history.css';

export interface IGroupWithEvents {
    id: IdType,
    title: string,
    events: AgendaEvent[]
}

const initUseListReducer: ILoaderInitProps<IGroupWithEvents> = {
    initialState: {
        ...initialLoadListState,
        loading: true
    }
}

export async function getAgendaList(auth: IAuthState<IAuthData>, item: IHasEmployeeDayKey, signal?: AbortSignal | undefined): Promise<IGroupWithEvents[]> {

    const items = await getScheduleItemHistoryList(auth, item, signal);
    const groups = groupBy(items, (item) => item.groupId);

    return Object.values(groups).map(group => ({
        id: group[0].groupId,
        title: group[0].groupTitle,
        events: group.map(item => ({
                    id: item.id,
                    start: item.start.toDate(),
                    end: item.end.toDate(),
                    text: item.caption,
                    backgroundColor: item.backgroundColor,
                    markColor: item.markColor
                }))
    }));
}

export interface ICommentsProps {
    item: IHasEmployeeDayKey,
}

export function History({
    item
}: ICommentsProps) {

    const didMount = useRef(false);
    const auth = useAuth();
    const [historyGroups, reloadHistoryGroups] = useListLoader(
        signal => getAgendaList(auth, item, signal),
        [auth.data?.staticId, item.PID, item.date],
        initUseListReducer
    );

    const {
        locale: contextLocale,
        renderEmpty,
    } = useContext(ConfigProvider.ConfigContext);
    
    const emptyText = renderEmpty?.('Table') || (
        <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
    );

    useEffect(
        reloadHistoryGroups,
        [auth.data?.staticId, item.PID, item.date]
    );

    useEffect(() => {
        didMount.current = true;
        return () => {
            didMount.current = false;
        }
    }, []);

    return (
        <div className="history-group-list-wrapper">
            {!historyGroups.load &&
            <>
                <Skeleton active={historyGroups.loading} />
                <Skeleton active={historyGroups.loading} />
                <Skeleton active={historyGroups.loading} />
            </>}
            {historyGroups.load && historyGroups.items.length === 0 && emptyText}
            {historyGroups.load && historyGroups.items.length > 0 &&
            <Collapse
                items={historyGroups.items.map(historyGroup => ({
                    key: historyGroup.id,
                    className: "history-collapse-panel",
                    label: (
                        <span
                            title={historyGroup.title}
                            className="history-collapse-panel-title"
                        >
                            {historyGroup.title}
                        </span>
                    ),
                    children: (
                        <AgendaList
                            className="history-agenda-list"
                            eventList={historyGroup.events}
                            theme="ios"
                        />
                    )
                }))}
            />}
        </div>
    );
}