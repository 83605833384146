import { createContext, useContext, useEffect } from "react";
import { IInitData } from "../Entities/BackendAPIHub";
import useSignalRHub, { IDefaultEventStore, ISignalRHubProvider } from "./useSignalRHub";
import useAuth from "./useAuth";
import { backendUrl } from "../Server/DbProvider";
import { message as AntdMessage, notification as AntdNotification } from "antd";
import { MessageInstance } from "antd/es/message/interface";
import { NotificationInstance } from "antd/es/notification/interface";

export interface IHubEvents {
    connectionSuccess: IDefaultEventStore['connectionSuccess'],
    connectionFail: IDefaultEventStore['connectionFail'],
    init: (data: IInitData) => void,
}

export interface IAPIHubEvents extends IHubEvents {

}

export interface IAPIHubMethods {

}

export const notificationUrl = `${backendUrl}/hub`;

export interface IHubProviderOptions {
    sessionId?: string,
    message: MessageInstance,
    notification: NotificationInstance
}

export function useAPIHubProvide(options?: IHubProviderOptions) {

    const auth = useAuth();
    const hub  = useSignalRHub<IAPIHubEvents, IAPIHubMethods>({
        ...options,
        url: notificationUrl,
    });

    useEffect(() => {

        if (auth.data && auth.data.staticId) {
            hub.connect().catch(x => {});
            return () => {
                hub.disconnect();
            }
        }

    }, [auth.isLogout, auth.data?.staticId]);

    return hub;
}

export const apiHubContext = createContext<ISignalRHubProvider<IAPIHubEvents, IAPIHubMethods>>({} as any);
export function APIHubProvider({ children }: any) {
    const [message, messageContextHolder] = AntdMessage.useMessage({ prefixCls: 'api-hub' });
    const [notification, notificationContextHolder] = AntdNotification.useNotification({ prefixCls: 'api-hub' });
    const hub = useAPIHubProvide({
        message,
        notification,
    });
    return (
        <apiHubContext.Provider value={hub}>
            {messageContextHolder}
            {notificationContextHolder}
            {children}
        </apiHubContext.Provider>
    );
}

export function useAPIHub<
    TEvents extends Record<keyof TEvents, (...args: any) => any> = IAPIHubEvents,
    TMethods extends Record<keyof TMethods, (...args: any) => Promise<any>> = IAPIHubMethods
>() {
    return useContext(apiHubContext as unknown as React.Context<ISignalRHubProvider<TEvents, TMethods>>);
}