import { ColumnType, ColumnsType, TableProps } from "antd/es/table";
import { Button, Input, InputRef, Space, Table } from "antd";
import { memo, useRef, useState } from "react";
import { FilterConfirmProps } from "antd/es/table/interface";
import { SearchOutlined } from '@ant-design/icons';
import { DataIndex } from "../../../Entities/EmployeeCard";
import "./style.css";
import { IExternalLogin } from "../../../Entities/Database";

export interface ITableItemProps<T> extends TableProps<T> {
}

function NotMemoizedTableItem<T extends IExternalLogin>(props: ITableItemProps<T>) {
    
    const searchInput = useRef<InputRef>(null);
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');

    const getColumnSearchProps = (dataIndex: DataIndex): ColumnType<IExternalLogin> => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
            <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
                <Input
                    ref={searchInput}
                    placeholder={`Поиск по строке`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />

                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys as string[], confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Найти
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Сбросить
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined  style={{ color: filtered ? '#1677ff' : undefined }}/>
        ),
        onFilter: (value, record) => 
            record[dataIndex]
            .toString()
            .toLowerCase()
            .includes((value as string).toLowerCase()),
            
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });

    const handleSearch = (
        selectedKeys: string[],
        confirm: (param?: FilterConfirmProps) => void,
        dataIndex: DataIndex,
    ) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
        setSearchText('');
    };

    const defColumns: ColumnsType<any> = [
        {
            filterSearch: true,
            title: 'Система',
            dataIndex: 'system',
            key: 'system',
            ...getColumnSearchProps('id'),
            ellipsis: {
                showTitle: true,
            },
        },
        {
            filterSearch: true,
            title: 'ID',
            dataIndex: 'value',
            key: 'value',
            ...getColumnSearchProps('value'),
            ellipsis: true,
        },
    ];

 
    const { columns = defColumns, size = 'small', ...otherProps } = props;

    return (
        <Table
            className="table-employee"
            {...otherProps}
            size={size}
            pagination={false}
            columns={columns} 
        />
    )
}
export const TableItem = memo(NotMemoizedTableItem);