import React, { useMemo, useRef, useState } from "react";
import { Modal, ModalProps } from "antd";
import Draggable, { DraggableData, DraggableEvent } from "react-draggable";
import { initialBoundsState } from "../../Entities/IBounds";

export interface IDraggableModalProps extends ModalProps {
    reRenderOnDrag?: boolean
}

export function DraggableModal(props: IDraggableModalProps) {

    const {title, reRenderOnDrag, ...modalProps} = props;
    const [disabledDraggable, setDisabledDraggle] = useState(true);
    const [modalDragged, setModalDragged] = useState(false);
    const [bounds, setBounds] = useState(initialBoundsState);

    const titleWrapRef = useRef<HTMLDivElement>(null);
    const draggleRef = useRef<HTMLDivElement>(null);

    const handleDragStart = (event: DraggableEvent, uiData: DraggableData) => {

        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();

        if (!targetRect) {
            return;
        }

        setModalDragged(true);
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };

    const handleDragStop = (event: DraggableEvent, uiData: DraggableData) => {
        setModalDragged(false);
    }

    const enableDraggableModal = () => {
        setDisabledDraggle(true);
    }

    const disableDraggleModal = () => {
        setDisabledDraggle(false);
    }

    const lastModalContent = useRef<React.ReactNode>(null);
    const modalContent = useMemo(() => {
        
        if(reRenderOnDrag === true) {
            return modalProps.children;
        }
        
        if(modalDragged) {
            return lastModalContent.current;
        }

        return lastModalContent.current = modalProps.children;

    }, [modalDragged, modalProps.children]);

    return (
        <Modal
            {...modalProps}
            title={
                <div
                    ref={titleWrapRef}
                    style={{
                        width: '100%',
                        cursor: 'move',
                    }}
                    onMouseOver={disableDraggleModal}
                    onMouseOut={enableDraggableModal}
                    onFocus={() => {}}
                    onBlur={() => {}}
                >
                    {title}
                </div>
            }
            modalRender={modal => {

                const rendered = (
                    <Draggable
                        disabled={disabledDraggable}
                        bounds={bounds}
                        onStart={handleDragStart}
                        onStop={handleDragStop}
                        nodeRef={draggleRef}
                    >
                        <div
                            ref={draggleRef}
                            className='ap-modal-win'
                        >
                            {modal}
                        </div>
                    </Draggable>
                );

                return modalProps.modalRender ?
                    modalProps.modalRender(rendered) :
                    rendered;
            }}
        >
            {modalContent}
        </Modal>
    )
}

export default DraggableModal;