import { EmployeeContactType } from "../../../Entities/Database";

export function getImgSrc(contactType: EmployeeContactType): string {
    
    switch (contactType) {
        case EmployeeContactType.Email: return "/assets/image/message.svg";
        case EmployeeContactType.Phone: return "/assets/image/phone.svg";
        case EmployeeContactType.Skype: return "/assets/image/Skype.png";
        case EmployeeContactType.Telegram: return "/assets/image/Telegram.png";
        case EmployeeContactType.Whatsapp: return "/assets/image/WhatsApp.png";
        default: break;
    }

    return "";
}

export function getHref(type: EmployeeContactType, url: string) {

    switch (type) {
        case EmployeeContactType.Email: return `mailto:${url}`;
        case EmployeeContactType.Phone: return `tel:${url}`;
        case EmployeeContactType.Skype: return `skype:${getSkypeUsername(url)}?chat`;
        case EmployeeContactType.Telegram: return `https://t.me/${url}`;
        case EmployeeContactType.Whatsapp: return `https://wa.me/${url}`;
        default: break;
    }

    return "";
}

function getSkypeUsername(link: string) {
    return link && link.startsWith("live:") ? link.substr(5).trim() : link;
}