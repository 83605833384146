import { Button, Drawer, DrawerProps, Form, Radio, Select, SelectProps, Skeleton, Space, Switch } from 'antd';
import useForm, { FormInstance } from 'antd/lib/form/hooks/useForm';
import { useEffect, useMemo, useRef, useState } from 'react';
import { IFieldShortInfo, IForcastShortInfo, IProjectGroupShortInfo, IScheduleBoardFilter, ITimeZone, ISkillShortInfo, ITagShortInfo, PersonFilterType, saveScheduleBoardFilter, IProductiveActivityTypeInfo } from '../../Entities/Database';
import useAuth from '../../Hooks/useAuth';
import { useFilterDictionaryProvider } from './useFilterDictionaryProvider';
import { MutationAction, MutationActionType } from '../../Reducer/MutationReducer';
import { ILoadListState } from '../../Reducer/LoadListReducer';
import { nameof } from '../../System/Utils';
import { SizeType } from '../shared/const';
import { dictionaryToOptions } from '../shared/helpers';
import { scheduleBoardFilterLangRu as scheduleBoardFilterLocaleRu } from './locale/ru';
import { OperationAbortedByMergeException } from '../../System/OperationAbortedByMergeException';
import { searchInValue } from '../../Reducer/SearchFilterReducer';
import { initialFilterValues } from '../ScheduleBoard';

const multipleSelectProps: SelectProps = {
    mode: 'multiple',
    style: { width: '100%' },
    maxTagCount: 'responsive',
    filterOption: (inputValue, option) => {
        return searchInValue(option?.children, inputValue)
    }
}

export interface ScheduleBoardFilterFormValues extends Omit<IScheduleBoardFilter, 'from' | 'to'> {}

export interface ScheduleBoardFilterLocale {
    title: string,
    projectGroup: string,
    field: string,
    role: string,
    linearPerson: string,
    adminPerson: string,
    tags: string,
    productiveActivityType: string,
    forcastObj: string,
    skills: string,
    crossEmployee: string,
    timeZone: string,
    close: string,
    cancel: string,
    save: string,
    reset: string,
}

export interface ScheduleBoardFilterDictionaries {
    projectGroupList: ILoadListState<IProjectGroupShortInfo>,
    fieldList: ILoadListState<IFieldShortInfo>,
    tagList: ILoadListState<ITagShortInfo>,
    forcastObjectList: ILoadListState<IForcastShortInfo>,
    skillList: ILoadListState<ISkillShortInfo>,
    timeZoneList: ILoadListState<ITimeZone>,
    productiveActivityTypeList: ILoadListState<IProductiveActivityTypeInfo>,
}

export interface ScheduleBoardFilterTemplateProps {
    loading?: boolean,
    form: FormInstance<ScheduleBoardFilterFormValues>,
    open?: boolean,
    size?: SizeType,
    locale?: ScheduleBoardFilterLocale,
    saving?: boolean,
    dictionaries: ScheduleBoardFilterDictionaries,
    values: ScheduleBoardFilterFormValues,
    onSave?: (values: ScheduleBoardFilterFormValues) => void,
    onClose?: DrawerProps['onClose'],
    onCancel?: DrawerProps['onClose'],
    onValuesChange?: (changedValues: Partial<ScheduleBoardFilterFormValues>, values: ScheduleBoardFilterFormValues) => void
}

export function ScheduleBoardFilterTemplate({
    loading,
    form,
    open,
    size,
    locale = scheduleBoardFilterLocaleRu,
    saving,
    dictionaries,
    values,
    onSave,
    onClose,
    onCancel,
    onValuesChange,
}: ScheduleBoardFilterTemplateProps) {

    const personTypes = useMemo(() => [
        {label: locale?.linearPerson, value: PersonFilterType.LinearPerson},
        {label: locale?.adminPerson,  value: PersonFilterType.AdminPerson}
    ], [locale]);

    const projectListOptions  = useMemo(() => dictionaryToOptions(dictionaries.projectGroupList, 'id', 'title'), [dictionaries.projectGroupList.items]);
    const fieldListOptions    = useMemo(() => dictionaryToOptions(dictionaries.fieldList, 'id', 'title'), [dictionaries.fieldList.items]);
    const tagListOptions      = useMemo(() => dictionaryToOptions(dictionaries.tagList, 'id', 'title'), [dictionaries.tagList.items]);
    const forcastListOptions  = useMemo(() => dictionaryToOptions(dictionaries.forcastObjectList, 'id', 'title'), [dictionaries.forcastObjectList.items]);
    const skillListOptions    = useMemo(() => dictionaryToOptions(dictionaries.skillList, 'id', 'title'), [dictionaries.skillList.items]);
    const timeZoneListOptions = useMemo(() => dictionaryToOptions(dictionaries.timeZoneList, 'id', 'title'), [dictionaries.timeZoneList.items]);
    const prodActListOptions  = useMemo(() => dictionaryToOptions(dictionaries.productiveActivityTypeList, 'id', 'title'), [dictionaries.productiveActivityTypeList.items]);

    const dictLoading = loading
                    || dictionaries.projectGroupList.loading
                    || dictionaries.fieldList.loading
                    || dictionaries.tagList.loading
                    || dictionaries.forcastObjectList.loading
                    || dictionaries.skillList.loading
                    || dictionaries.timeZoneList.loading
                    || dictionaries.productiveActivityTypeList.loading;
    
    const dictSomeLoad = loading !== true &&
                        (
                               dictionaries.projectGroupList.load
                            || dictionaries.fieldList.load
                            || dictionaries.tagList.load
                            || dictionaries.forcastObjectList.load
                            || dictionaries.skillList.load
                            || dictionaries.timeZoneList.load
                            || dictionaries.productiveActivityTypeList.load
                        );

    const dictLoad = loading !== true
                && dictionaries.projectGroupList.load
                && dictionaries.fieldList.load
                && dictionaries.tagList.load
                && dictionaries.forcastObjectList.load
                && dictionaries.skillList.load
                && dictionaries.timeZoneList.load
                && dictionaries.productiveActivityTypeList.load;

    useEffect(() => {

        if(dictionaries.tagList.load
        && dictionaries.forcastObjectList.load
        && dictionaries.skillList.load
        && dictionaries.productiveActivityTypeList.load) {

            const values = form.getFieldsValue();
    
            if(values) {
    
                const tagIds = dictionaries.tagList.items
                    .filter(tag => values.tagIds && values.tagIds.indexOf(tag.id) !== -1)
                    .map(tag => tag.id);
    
                const forcastObjIds = dictionaries.forcastObjectList.items
                    .filter(forcastObject => values.forcastObjIds && values.forcastObjIds.indexOf(forcastObject.id) !== -1)
                    .map(forcastObject => forcastObject.id);
    
                const skillIds = dictionaries.skillList.items
                    .filter(skill => values.skillIds && values.skillIds.indexOf(skill.id) !== -1)
                    .map(skill => skill.id);
    
                const prodActIds = dictionaries.productiveActivityTypeList.items
                    .filter(prodActId => values.prodActIds && values.prodActIds.indexOf(prodActId.id) !== -1)
                    .map(prodAct => prodAct.id);

                const changes = {
                    tagIds: tagIds,
                    skillIds: skillIds,
                    forcastObjIds: forcastObjIds,
                    prodActIds: prodActIds,
                };

                form.setFieldsValue(changes);

                if (onValuesChange) {
                    onValuesChange(changes, {
                        ...values,
                        ...changes
                    });
                }
            }
        }
    }, [
        dictionaries.tagList.items,
        dictionaries.forcastObjectList.items,
        dictionaries.skillList.items,
        dictionaries.productiveActivityTypeList.items,
    ]);

    const inputPreloader = <Skeleton.Input active={dictLoading} size={size == "middle" ? 'default' : size} block/>;
    const formTemplate = (
        <>
            <Form.Item
                label={`${locale.projectGroup}::`}
                name={nameof<ScheduleBoardFilterFormValues>('projectGroupIds')}
                labelCol={{span: 24}}
            >
                {
                    dictionaries.projectGroupList.load ?
                    <Select
                        {...multipleSelectProps}
                    >
                        {projectListOptions}
                    </Select> :
                    inputPreloader
                }
            </Form.Item>
            <Form.Item
                label={`${locale.productiveActivityType}::`}
                name={nameof<ScheduleBoardFilterFormValues>('prodActIds')}
                labelCol={{span: 24}}
            >
                {
                    dictionaries.productiveActivityTypeList.load ?
                    <Select
                        {...multipleSelectProps}
                        value
                    >
                        {prodActListOptions}
                    </Select> :
                    inputPreloader
                }
            </Form.Item>
            <Form.Item
                label={`${locale.forcastObj}::`}
                name={nameof<ScheduleBoardFilterFormValues>('forcastObjIds')}
                labelCol={{span: 24}}
            >
                {
                    dictionaries.forcastObjectList.load ?
                    <Select
                        {...multipleSelectProps}
                        value
                    >
                        {forcastListOptions}
                    </Select> :
                    inputPreloader
                }
            </Form.Item>
            <Form.Item
                label={`${locale.field}::`}
                name={nameof<ScheduleBoardFilterFormValues>('fieldIds')}
                labelCol={{span: 24}}
            >
                {
                    dictionaries.fieldList.load ?
                    <Select
                        {...multipleSelectProps}
                    >
                        {fieldListOptions}
                    </Select> :
                    inputPreloader
                }
            </Form.Item>
            <Form.Item
                label={`${locale.role}::`}
                name={nameof<ScheduleBoardFilterFormValues>('personType')}
                labelCol={{span: 24}}
                hidden
            >
                {
                    dictSomeLoad ?
                    <Radio.Group
                        style={{
                            width: '100%'
                        }}
                    >
                        <Space
                            direction="vertical"
                            align='start'
                            style={{
                                width: '100%',
                                justifyContent: 'start'
                            }}
                        >
                            {personTypes.map(personType => (
                                <Radio
                                    key={personType.value}
                                    value={personType.value}
                                >
                                    {personType.label}
                                </Radio>
                            ))}
                        </Space>
                    </Radio.Group> :
                    inputPreloader
                }
            </Form.Item>
            <Form.Item
                label={`${locale.tags}::`}
                name={nameof<ScheduleBoardFilterFormValues>('tagIds')}
                labelCol={{span: 24}}
            >
                {
                    dictionaries.tagList.load ?
                    <Select
                        {...multipleSelectProps}
                    >
                        {tagListOptions}
                    </Select> :
                    inputPreloader
                }
            </Form.Item>
            <Form.Item
                label={`${locale.skills}::`}
                name={nameof<ScheduleBoardFilterFormValues>('skillIds')}
                labelCol={{span: 24}}
            >
                {
                    dictionaries.skillList.load ?
                    <Select
                        {...multipleSelectProps}

                    >
                        {skillListOptions}
                    </Select> :
                    inputPreloader
                }
            </Form.Item>
            <Form.Item
                valuePropName="checked"
                label={locale.crossEmployee}
                name={nameof<ScheduleBoardFilterFormValues>('crossEmploee')}
            >
                {
                    dictSomeLoad
                    ? <Switch style={{display: 'flex'}} />
                    : inputPreloader
                }
            </Form.Item>
            <Form.Item
                label={locale.timeZone}
                name={nameof<ScheduleBoardFilterFormValues>('timeZone')}
            >
                {
                    dictionaries.timeZoneList.load ?
                    <Select
                        {...multipleSelectProps}
                        mode={undefined}
                        maxTagCount={1}
                        showSearch
                    >
                        {timeZoneListOptions}
                    </Select> :
                    inputPreloader
                }
            </Form.Item>
        </>
    );

    return (
        <Drawer
            autoFocus
            mask={false}
            open={open}
            title={locale.title}
            placement='left'
            getContainer={false}
            onClose={onClose}
            destroyOnClose
            rootStyle={{
                position: 'absolute',
            }}
            footer={
                <Space
                    direction='horizontal'
                    align='end'
                    style={{width: '100%', justifyContent: 'end'}}
                >
                    {!saving && dictLoad && <Button
                        onClick={() => {
                            const { from, to, timeZone, ...resetValues } = initialFilterValues;
                            form.setFieldsValue(resetValues);
                        }}
                    >
                        {locale.reset}
                    </Button>}
                    <Button
                        onClick={saving === true ? onCancel ?? onClose : onClose}
                    >
                        {saving ? locale.cancel : locale.close}
                    </Button>
                    <Button
                        type='primary'
                        disabled={!dictLoad}
                        loading={saving}
                        onClick={() => {
                            form.submit();
                        }}
                    >
                        {locale.save}
                    </Button>
                </Space>
            }
        >
            <Form<ScheduleBoardFilterFormValues>
                size={size}
                form={form}
                onValuesChange={onValuesChange}
                onFinish={onSave}
                initialValues={values}
            >
                {formTemplate}
            </Form>
        </Drawer>
    )
}

export interface ScheduleBoardFilterProps extends Omit<ScheduleBoardFilterTemplateProps, 'form' | 'values' | 'dictionaries' | 'onSave' | 'onCancel'> {
    values: IScheduleBoardFilter,
    valuesDispatch: React.Dispatch<MutationAction<IScheduleBoardFilter>>,
    onFinish?: () => void
}

export function ScheduleBoardFilter(props: ScheduleBoardFilterProps) {

    const {open, values, valuesDispatch, onClose, onValuesChange, onFinish } = props;

    const auth = useAuth();

    const onFinishRef = useRef(onFinish);
          onFinishRef.current = onFinish;

    const [internalValues, setInternalValues] = useState(values);
    const [saving, setSaving] = useState(false);
    const [form] = useForm<ScheduleBoardFilterFormValues>();

    const dictionaries = useFilterDictionaryProvider(internalValues);
    const savingAbortRef = useRef<AbortController | null>(null);
    const handleFinish = async (values: ScheduleBoardFilterFormValues) => {

        const abortController = new AbortController();
        const prevOperationAbortController = savingAbortRef.current;

        let success = false;

        savingAbortRef.current = abortController;
        prevOperationAbortController?.abort(new OperationAbortedByMergeException());

        try {
            
            setSaving(true);

            //await delay(5000, { signal: savingAbortRef.current.signal });
            await saveScheduleBoardFilter(auth, {
                ...values,
                from: props.values.from,
                to: props.values.to,
            }, abortController.signal);

            setInternalValues(prev => ({
                ...prev,
                ...values
            }));

            valuesDispatch({
                type: MutationActionType.NoSideMutate,
                completely: true,
                payload: values
            });

            success = true;
        }
        finally {
            
            const aborted = abortController.signal.aborted;
            const abortByCurrectController = aborted && savingAbortRef.current === abortController;
            const completedWithError = !success && savingAbortRef.current === abortController;
            const completed = success && savingAbortRef.current === abortController;
            const updateState = abortByCurrectController || completed || completedWithError;

            if(completed || completedWithError) {
                savingAbortRef.current = null;
            }

            if(updateState) {
                
                setSaving(false);

                if(success) {

                    const onFinish = onFinishRef.current;
                    
                    if(onFinish) {
                        
                        onFinish();
                    }
                }
            }
        }
    }

    const handleCancel = (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => {
        savingAbortRef.current?.abort();
    }

    const handleClose = (e: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>) => {
        handleCancel(e);
        onClose && onClose(e);
    }

    const handleValuesChange = (changedValues: Partial<ScheduleBoardFilterFormValues>, values: ScheduleBoardFilterFormValues) => {

        setInternalValues(prev => ({
            ...prev,
            ...values
        }));

        if (onValuesChange) {
            onValuesChange(changedValues, values);
        }
    }

    useEffect(() => {

        if (open === true) {
            form.setFieldsValue(values);
            setInternalValues(values);
            return dictionaries.reload();
        }

    }, [open, values]);

    return (
        <ScheduleBoardFilterTemplate
            {...props}
            form={form}
            values={internalValues}
            saving={saving}
            dictionaries={dictionaries}
            onSave={handleFinish}
            onClose={handleClose}
            onCancel={handleCancel}
            onValuesChange={handleValuesChange}
        />
    );
}

export default ScheduleBoardFilter