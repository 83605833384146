import { useEffect,useState } from "react";
import { Modal, Select, message as AntdMessage, Form, Input } from "antd";
import { IEmployee, ISkill, IWithEmployable } from "../../../Entities/Database";
import useAuth from "../../../Hooks/useAuth";
import DraggableModal from "../../DraggableModal";
import useActual from "../../../Hooks/useActual";
import { BackendResponseException } from "../../../System/BackendResponseException";
import { nameof } from "../../../System/Utils";
import moment from "moment";
import DatePicker from "../../shared/DatePicker";
import { saveMainSkill } from "./index.db";
import { mainSkillLocaleRu } from "./locale/ru";

export interface IEmployeeSkill {
    employeePID: IWithEmployable['PID'],
    startDate: moment.Moment,
    skillId: number,
}
export interface IMainSkillModalProps {
    open: boolean,
    employees: IEmployee[],
    skills: ISkill[],
    mainSkill: ISkill,
    onFinish: () => void,
    onClose?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    onChangeMainSkill: (x: ISkill) => void,
}

export function MainSkillModal({
    open, 
    employees,
    skills,
    mainSkill, 
    onFinish, 
    onClose, 
    onChangeMainSkill
}: IMainSkillModalProps) {

    const auth = useAuth();
    const [modal, modalContextHolder] = Modal.useModal();
    const [message, messageContextHolder] = AntdMessage.useMessage();
    const [saving, setSaving] = useState(false);
    
    const [form] = Form.useForm<IEmployeeSkill>();
    const actualOnFinish = useActual(onFinish);

    async function save(values: IEmployeeSkill) {

        try {
            setSaving(true);
            const abortController = new AbortController();
            await saveMainSkill(auth, values,  abortController.signal);

            onChangeMainSkill({
                id: values.skillId || mainSkill.id,
                content: skills.find(x => x.id === values.skillId)?.content || mainSkill.content
            })
 
            if (actualOnFinish.current) {
                actualOnFinish.current();
            }
        }
        catch (ex) {
            message.error(ex instanceof BackendResponseException
                ? ex.message
                : mainSkillLocaleRu.errorSaveMessage
            );
        }
        finally {
            setSaving(false);
        }
    }

    const handleFormFinish = (values: IEmployeeSkill) => {
      
        if (values.skillId === mainSkill.id) {
            message.error(mainSkillLocaleRu.errorApplyMessage);
        } else {
            values.employeePID = employees[0].PID;
            save(values);
        }
    }

    const handleOk = () => {
        form.submit();
    }

    const handleCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

        if (!saving && onClose) {

            const actualId = form.getFieldValue(nameof((x: IEmployeeSkill) => x.skillId));
            
            if (actualId !== mainSkill.id) {

                modal.confirm({
                    title:  (
                        <div> {mainSkillLocaleRu.cancelWarn}
                            <br/> 
                            <span style={{fontWeight: 400, paddingTop: 10, display: "block"}}>
                                {mainSkillLocaleRu.cancelQuestion}
                            </span>
                        </div>
                    ),
                    onOk: onClose
                });
            }
            else {
                onClose(e);
            }
        }
    }

    useEffect(() => {

        if (open) {
            form.resetFields();
            form.setFieldValue(nameof<IEmployeeSkill>("skillId"), mainSkill.id);
            form.setFieldValue(nameof<IEmployeeSkill>("startDate"), moment(new Date()));
        }

    }, [employees, open]);

    return (
        <>
            <DraggableModal 
                title={mainSkillLocaleRu.modalTitle}
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
                cancelButtonProps={{disabled: saving}}
                okButtonProps={{loading: saving}}
                okText={mainSkillLocaleRu.modalOkTitle}
                cancelText={mainSkillLocaleRu.modalCancelTitle}
                width={350}
                destroyOnClose
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={handleFormFinish}
                    disabled={saving}
                >
                    <Form.Item
                        name={nameof<IEmployeeSkill>("employeePID")}
                        label={mainSkillLocaleRu.employeeLabel}
                        hidden
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item 
                        name={nameof<IEmployeeSkill>("skillId")}
                        label={mainSkillLocaleRu.mainSkillLabel}
                        rules={[{ required: true, message: mainSkillLocaleRu.mainSkillError, }]}
                    >
                        <Select
                            options={
                                skills?.length ? 
                                skills.map(item => ({
                                    value: item.id,
                                    label: item.content,
                                })) : [{
                                    value: mainSkill.id,
                                    label: mainSkill.content

                                }]
                            }
                        />
                    </Form.Item>
                    <Form.Item
                        name={nameof<IEmployeeSkill>("startDate")}
                        label={mainSkillLocaleRu.dateTitle}
                        rules={[{ required: true, message: mainSkillLocaleRu.dateError, }]}
                    >
                        <DatePicker        
                            style={{width: "100%"}}
                        />
                    </Form.Item>
                </Form>
            </DraggableModal>
            {modalContextHolder}
            {messageContextHolder}
        </>
    );
}