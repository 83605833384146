import "./style.css";

export interface IScheduleShiftWorkInfoItemProps {
    title?: string, 
    content?: number,
}

export function ScheduleShiftWorkInfoItem({
    title, 
    content
}: IScheduleShiftWorkInfoItemProps) {
    return (
        <div className="shiftWorkInfoItem__wrap">
            <span className="marker-info-ico shiftWorkInfoItem__icon" />
            <p className="shiftWorkInfoItem__content">
                {title}  
                <span className="shiftWorkInfoItem__span">{` ${content} минут`}</span>
            </p>
    </div>
    )
}