import { Button, message } from "antd";
import ScheduleShiftSchemeWrap, { IScheduleShiftSchemeRef } from "../ScheduleShiftSchemeWrap";
import { CopyOutlined } from "@ant-design/icons";
import { useRef, useState } from "react";
import DraggableModal from "../../DraggableModal";
import { isError } from "../../../System/Utils";
import { IEmployee, IScheduleShiftScheme } from "../../../Entities/Database";
import "./style.css";


interface IScheduleShiftModalProps {
    employee?: IEmployee,
    defaultScheme?: IScheduleShiftScheme | IScheduleShiftScheme['sss_id'],
    open: boolean,
    onFinishBind?: () => void,
    onOk?: () => void, 
    onCancel?: () => void,
}

export function ScheduleShiftModal({
    employee,
    defaultScheme,
    open,
    onFinishBind,
    onOk,
    onCancel,
}: IScheduleShiftModalProps) {

    const [loading, setLoading] = useState(false);
    const [disabledControls, setDisabledControls] = useState(false);
    const scheduleShiftSchemeRef = useRef<IScheduleShiftSchemeRef>(null);

    const handleLoading = (x: boolean) => {
        setLoading(x);
        setDisabledControls(x);
    }

    const handleClone = () => {
        scheduleShiftSchemeRef.current?.handleClone();
    }

    const handleReset = () => {
        scheduleShiftSchemeRef.current?.handleReset();
    }

    const handleButtonCancel = () => {
        scheduleShiftSchemeRef.current?.handleCancel();
    }

    const handleSave = async () => {

        try {
            setDisabledControls(true);
            await scheduleShiftSchemeRef.current?.handleSave();
        }
        catch (ex) {
            console.error(ex);
            message.error(isError(ex) ? ex.message : "Не удалось сохранить шаблон");
        }
        finally {
            setDisabledControls(false);
        }
    }

    return (
        <DraggableModal 
            width={'100%'}
            className="SSModal__modal"
            title={employee ? "Привязка сотрудника к шаблону" : "Редактор шаблона смен"}
            style={{ top: 20 }}
            open={open}
            onOk={onOk} 
            onCancel={handleButtonCancel}
            destroyOnClose
            footer={[
                <Button 
                    key="clone" 
                    type="link"
                    icon={<CopyOutlined />}
                    onClick={handleClone}
                    disabled={disabledControls}
                >
                    Создать копию
                </Button>,
                <Button 
                    key="reset" 
                    type="default" 
                    danger
                    onClick={handleReset}
                    disabled={disabledControls}
                >
                    Сбросить
                </Button>,
                <Button
                    key="cancel"
                    onClick={handleButtonCancel}
                    disabled={disabledControls}
                >
                   Закрыть
                </Button>,
                <Button
                    key="submit"
                    type="primary"
                    onClick={handleSave}
                    disabled={disabledControls}
                >
                    Сохранить
                </Button>,
            ]}
        >
           <ScheduleShiftSchemeWrap
                employee={employee}
                defaultScheme={defaultScheme}
                open={open}
                ref={scheduleShiftSchemeRef}
                loading={loading}
                onFinishBind={onFinishBind}
                onLoading={handleLoading}
                onCancel={onCancel}
                width={1265}
           />
        </DraggableModal>
    )
}