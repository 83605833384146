
import { Space, Button, message as AntdMessage, Modal, MenuRef, Dropdown, MenuProps } from "antd";
import { scheduleBoardLangRu as scheduleBoardLocaleRu } from "./locale/ru";
import ScheduleBoardFilter from "../ScheduleBoardFilter";
import { SizeType } from "../shared/const";
import React, { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { MutationActionType, mutationReducer, MutationReducer } from "../../Reducer/MutationReducer";
import moment, { isMoment, Moment } from "moment";
import { classNames, debounce, RangeValue } from "../../System/Utils";
import { getScheduleBoardFilter, IScheduleBoardEmployee, IScheduleBoardFilter, IEmployeeScheduleDay, PersonFilterType, RecordType, publishScheduleChanges, resetScheduleChanges, IEmployee, saveRangeFilter, downloadReport, ReportType, Module } from "../../Entities/Database";
import useAuth from "../../Hooks/useAuth";
import { useListProvider } from "./useListProvider";
import { getItemKey, IItemElementPosition, recordItemComparer, RecordItemElementEvent } from "../EmployeesDayDataBoard/helpers";
import { OperationAbortedByMergeException } from "../../System/OperationAbortedByMergeException";
import { PickerLocale } from "antd/lib/date-picker/generatePicker";
import DatePicker from "../shared/DatePicker";
import EmployeesDayScheduleIntervalBoard, { IIntervalActivityBoardUpdater } from "../ScheduleIntervalBoard";

import {
    DownloadOutlined,
    ReloadOutlined,
    FilterOutlined,
    RollbackOutlined,
    PullRequestOutlined,
    HighlightOutlined,
    ExclamationCircleOutlined,
    InfoCircleOutlined,
    LeftOutlined,
    RightOutlined,
} from '@ant-design/icons';

import { getScrollItemPosition, selectItemsHandler } from "./helpers";

import EmployeesDayScheduleCellEditor from "../ScheduleCellEditor";
import EmployeesDayScheduleCellDetailInfo from "../ScheduleCellDetailInfo";

import EmployeesDayDataBoard from "../EmployeesDayDataBoard";
import { CellContextMenu, ICellContextMenuProps } from "./CellContextMenu";
import { HeaderColumnType, isHeaderColumn } from "./CellContextMenu/helpers";

import { Grid } from "antd-virtual-table";
import { ReferenceModal } from "../ReferenceModal";
import ScheduleShrinkModal from "../ScheduleShrinkModal";
import ScheduleGeneratorBoard from "../ScheduleGeneratorBoard";
import EmployeeCardDrawer from "../EmployeeCard/EmployeeCardDrawer";
import ScheduleProlongModal from "../ScheduleProlongModal";
import ScheduleShiftTransferModal from "../ScheduleShiftTransferModal";
import { VacationFromScheduleItemModal } from "../VacationEditor/VacationFromScheduleItemModal";
import { ScheduleShiftModal } from "../ScheduleShiftScheme/ScheduleShiftModal";
import ScheduleGeneratorModal from "../ScheduleGeneratorModal";
import useActual from "../../Hooks/useActual";
import CheckAccess from "../CheckAccess";
import RuntimeNotifications from "../Notifications/RuntimeNotifications";
import { NotificationBoard } from "../Notifications/NotificationBoard";
import { useAPIHub } from "../../Hooks/useAPIHub";
import { BackendResponseException } from "../../System/BackendResponseException";
import PublishButton from "./public-button";
import ScheduleSickModal from "../ScheduleSickModal";
import ReportListWithoutParams from "../ReportListWithoutParams";
import ShiftExchangeModal from "../ShiftExchange/modal";
import ScheduleRequestHandlerModal from "../ScheduleRequestHandlerModal";
import './style.css';

export interface ScheduleBoardLocale {
    filter: string,
    update: string,
    reset: string,
    publish: string,
    exportTooltip: string,
    datePickerLocale: PickerLocale,
    goToMonthlyChart: string,
    goToIntervalEditor: string,
    reference: string,
}

export const initialFilterValues: Readonly<IScheduleBoardFilter> = {
    from: moment().startOf('month'),
    to: moment().endOf('month'),
    projectGroupIds: [],
    fieldIds: [],
    personType: PersonFilterType.LinearPerson,
    prodActIds: [],
    tagIds: [],
    forcastObjIds: [],
    skillIds: [],
    crossEmploee: false,
    timeZone: moment.tz.guess(),
    lang: 'ru',
};

function childOf(child: Node | ParentNode | null | undefined, parent: Node | null) {
    while((child = child?.parentNode) && child !== parent); 
    return !!child; 
}

const abortByUpdate = {}

const drawerWidth = 378;

export const syncMessageKey = "syncMessage";
export const publishMessageKey = "publishMessage";
export const resetMessgaeKey = "resetMessage";

export const gridItemClassName = "employee-day-cell";

export interface ScheduleBoardProps<T extends MenuProps> {
    locale?: ScheduleBoardLocale,
    size?: SizeType,
    dropMenuProps?: T,
}

export function ScheduleBoard<T extends MenuProps = MenuProps>({
    locale = scheduleBoardLocaleRu,
    size = 'small',
}: ScheduleBoardProps<T>) {

    const [message, messageContextHolder] = AntdMessage.useMessage();
    const didMount = useRef(false);
    const authProvider = useAuth();
    const apiHub = useAPIHub();
    const contextMenuRef = useRef<MenuRef>(null);
    const detailDrawerFocusRef = useRef<HTMLDivElement>(null);
    const [modal, modalContextHolder] = Modal.useModal();

    const [openFilter, setShowFilter] = useState(false);
    const [recordType, setRecordType] = useState(RecordType.Schedule);
    const [filterValues, filterValuesDispatch] = useReducer<MutationReducer<IScheduleBoardFilter>>(mutationReducer, initialFilterValues);
    const [selectedItem, setSelectedItem] = useState<IEmployeeScheduleDay | HeaderColumnType>();
    const [selectedItemsKeys, setSelectedItemsKeys] = useState<Readonly<string[]>>([]);
    const [selectedEmployeeRecord, setSelectedEmployeeRecord] = useState<IScheduleBoardEmployee>();
    const [intervalEditorDay, setIntervalEditorDay] = useState<moment.Moment | undefined>();
    const [openIntervalActivityEditor, setOpenIntervalActivityModal] = useState(false);
    const [openIntervalActivityGenerator, setOpenIntervalActivityGeneratorModal] = useState(false);
    const [openRecordItemEditorModal, setOpenRecordItemEditorModal] = useState(false);
    const [openRecordItemDetailDrawer, setOpenRecordItemDetailDrawer] = useState(false);
    const [openReferenceModal, setOpenReferenceModal] = useState(false);
    const [openShrinkModal, setOpenShrinkModal] = useState(false);
    const [openProlongModal, setOpenProlongModal] = useState(false);
    const [openTransferModal, setOpenTransferModal] = useState(false);
    const [openVacationModal, setOpenVacationModal] = useState(false);
    const [openSickModal, setOpenSickModal] = useState(false);
    const [openRequestHandlerModal, setOpenRequestHandlerModal] = useState(false);
    const [cellContextMenuProps, setCellContextMenuProps] = useState<Omit<ICellContextMenuProps, 'item'>>({ open: false });
    const [openEmployeeCard, setOpenEmployeeCard] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState<IEmployee | undefined>();

    const [openShiftExchangeModal, setOpenShiftExchangeModal] = useState(false);

    const [sync, setSync] = useState(false);
    const [saving, setSaving] = useState(false);
    const [resetting, setResetting] = useState(false);
    const [publish, setPublish] = useState(false);
    const [publishAvailable, setPublishAvailable] = useState(true);
    const [loadingFilterSaveData, setLoadingFilterSaveData] = useState(false);

    const [openSSSEModal, setOpenSSSEModal] = useState(false);
    const [openGeneratorModal, setOpenGeneratorModal] = useState(false);

    const updateScrollRef = useRef<false | IItemElementPosition>(false);
    const employeeDayValueGridRef = useRef<Grid<any>>(null);
    const employeeDayValueTableFocuser = useRef<HTMLDivElement>(null);
    const employeeDayValueTableOuterGridRef = useRef<HTMLElement>(null);

    const dayScheduleIntervalBoardUpdaterRef = useRef<IIntervalActivityBoardUpdater>(null);
    
    useEffect(() => {
        
        if(!selectedItem || isMoment(selectedItem)) {
            setOpenRecordItemDetailDrawer(false);
        }

        if(isMoment(selectedItem) && selectedItemsKeys.length === 0) {
            return;
        }

        if(!selectedItem
        || isMoment(selectedItem)
        || selectedItemsKeys.length !== 1
        || selectedItemsKeys[0] !== getItemKey(selectedItem)) {
            setCellContextMenuProps({ open: false });
        }
        
        // Устанавливаем фокус на драйвер с детальной информацией если он открыт, чтобы заблокировать выборку ячейки клавишами

        if(openRecordItemDetailDrawer
        && selectedItem
        && !isMoment(selectedItem)
        && selectedItemsKeys.length === 1
        && selectedItemsKeys[0] === getItemKey(selectedItem)) {
            detailDrawerFocusRef.current?.focus();
        }

    }, [selectedItemsKeys, selectedItem, openRecordItemDetailDrawer]);

    useEffect(() => {

        // Следит за ячейками дневного редактора
        // и отслеживает потерю фокуса ячейки
        // Снимает фокус с ячейки

        if(openIntervalActivityEditor === false
        && openIntervalActivityGenerator === false
        && openRecordItemEditorModal === false
        && openRecordItemDetailDrawer === false
        && cellContextMenuProps.open === false
        && openShrinkModal === false
        && openProlongModal === false
        && openVacationModal === false
        && openSickModal === false
        && openTransferModal === false
        && employeeDayValueTableFocuser.current) {

            const focuser = employeeDayValueTableFocuser.current;

            const handleBlur = (event: FocusEvent) => {

                setSelectedItemsKeys([]);

                if (event &&
                    event.relatedTarget &&
                    event.relatedTarget instanceof HTMLElement) {
                    
                    const relatedTarget = event.relatedTarget as HTMLButtonElement;
                    
                    // Кейс:
                    // Если кликают по кнопке высозва сотрудника,
                    // то делаем пролонг клика.
                    if (childOf(relatedTarget, employeeDayValueTableOuterGridRef.current)) {
                        relatedTarget.click();
                    }
                }
            }

            focuser.focus();
            focuser.addEventListener('blur', handleBlur, false);
            return () => focuser.removeEventListener('blur', handleBlur, false);
        }
    }, [
        openIntervalActivityEditor,
        openIntervalActivityGenerator,
        openRecordItemEditorModal,
        openRecordItemDetailDrawer,
        cellContextMenuProps.open,
        openShrinkModal,
        openProlongModal,
        openVacationModal,
        openSickModal,
        openTransferModal
    ]);

    const listProvider = useListProvider({
        filter: filterValues,
        recordType
    });

    const syncIndexRef = useRef(0);
    const syncWithDb = async () => {

        const syncIndex = ++syncIndexRef.current;

        try {
            setSync(true);
            await listProvider.sync();
        }
        finally {

            if (syncIndex === syncIndexRef.current) {
                setSync(false);
            }
        }
    }

    const reloadDataFromDb = () => {
        listProvider.update();
    }

    const handleUpdate = () => {

        if (openIntervalActivityEditor) {
            dayScheduleIntervalBoardUpdaterRef.current?.reload();
            return;
        }

        reloadDataFromDb();
    }

    const handleUpdateActualRef = useActual(handleUpdate);

    const handleFinishGeneration = () => {
        setOpenIntervalActivityGeneratorModal(false);
        handleUpdate();
    }

    const handleOkCellEditor = () => {
        setOpenRecordItemEditorModal(false);
        syncWithDb();
    }

    const handleCancelCellEditor = () => {
        setOpenRecordItemEditorModal(false);
    }

    const handleOkShrinkModal = () => {
        setOpenShrinkModal(false);
        syncWithDb();
    }

    const handleOkProlongModal = () => {
        setOpenProlongModal(false);
        syncWithDb();
    }

    const handleOkTransferModal = () => {
        setOpenTransferModal(false);
        syncWithDb();
    }

    const handleVacationFinish = () => {
        setOpenVacationModal(false);
        syncWithDb();
    }

    const handleSickFinishSave = () => {
        setOpenSickModal(false);
        syncWithDb();
    }

    const handleCancelShrinkModalFn = () => setOpenShrinkModal(false);
    const handleCancelProlongModalFn = () => setOpenProlongModal(false);
    const handleCancelTransferModalFn = () => setOpenTransferModal(false);

    const openReferenceModalFn = () => setOpenReferenceModal(true);
    const closeReferenceModalFn = () => setOpenReferenceModal(false);
    const handleToogleShowFilter = () => setShowFilter(prev => !prev);
    const handleFilterClose = () => setShowFilter(false);
    const handleFilterFinish = () => {
        setShowFilter(false);
        reloadDataFromDb();
    }

    const handleChangeCellDetail = () => {
        syncWithDb();
    }

    const handleCloseCellDetail = () => {
        setOpenRecordItemDetailDrawer(false);
    }

    const handleChangeRecordType = useCallback((type: RecordType) => {
        setRecordType(type);
        setSelectedItemsKeys([]);
    }, []);

    const abortControllerRef = useRef<AbortController | null>(null);
    const [syncFilterRange, setSyncFilterRange] = useState(false);
    
    const handleRangePickerChange = async (values: RangeValue<Moment>, stringFormat: RangeValue<string>) => {

        try {

            abortControllerRef.current?.abort(abortByUpdate);
            abortControllerRef.current = new AbortController();
    
            const abortController = abortControllerRef.current;
            const from = (values === null || values[0] === null) ? null : values[0].startOf('month');
            const to   = (values === null || values[1] === null) ? null : values[1].endOf('month');
    
            if(from === null || to === null) {
                console.error("From or To is null");
                return;
            }

            setSyncFilterRange(true);

            const newFilter = {
                ...filterValues,
                from: from,
                to: to,
            }

            await saveRangeFilter(authProvider, newFilter, abortController.signal);

            filterValuesDispatch({
                type: MutationActionType.NoSideMutate,
                completely: true,
                payload: {
                    from: from,
                    to:   to
                }
            });

            setSyncFilterRange(false);
        }
        catch (ex) {

            if (ex === abortByUpdate) {
                return;
            }

            setSyncFilterRange(false);
            message.error('Не удалось обновить фильтр');
        }
    }

    const openIntervalActivityEditorByEmployeeAndDay = useCallback((employeeOrPID: IEmployee | IEmployee['PID'], date: moment.Moment) => {

        console.log(employeeOrPID);

        const pid = typeof employeeOrPID === 'string' ? employeeOrPID : employeeOrPID.PID;
        const tmpEmployee = listProvider.employeeList.items.filter(employee => employee.PID === pid);

        if (tmpEmployee.length > 0) {

            setSelectedItem(undefined);
            setSelectedItemsKeys([]);
            setIntervalEditorDay(date);
            setOpenEmployeeCard(false);
            setSelectedEmployeeRecord(tmpEmployee[0]);
            setOpenIntervalActivityModal(true);
            setOpenRecordItemDetailDrawer(false);
        }

    }, [listProvider.employeeList.items]);

    const openIntervalActivityEditorForItem = useCallback((item: IEmployeeScheduleDay) => {

        if (recordType !== RecordType.Schedule) {
            return;
        }

        const tmpEmployee = listProvider.employeeList.items.filter(employee => employee.PID === item.PID);

        setSelectedItem(item);
        setSelectedItemsKeys([getItemKey(item)]);
        setIntervalEditorDay(item.date);
        setOpenEmployeeCard(false);
        setSelectedEmployeeRecord(tmpEmployee[0]);
        setOpenIntervalActivityModal(true);
        setOpenRecordItemDetailDrawer(false);
    }, [listProvider.employeeList.items]);

    const openIntervalActivityEditorForDay = useCallback((date: moment.Moment) => {

        if(recordType !== RecordType.Schedule) {
            return;
        }

        setSelectedItem(undefined);
        setSelectedItemsKeys([]);
        setIntervalEditorDay(date);
        setOpenEmployeeCard(false);
        setSelectedEmployeeRecord(undefined);
        setOpenIntervalActivityModal(true);
        setOpenRecordItemDetailDrawer(false);
        
    }, [recordType]);

    const openRecordItemEditor = (item: IEmployeeScheduleDay) => {

        setSelectedItemsKeys([getItemKey(item)]);

        if(recordType !== RecordType.Schedule) {
            return;
        }

        setSelectedItem(item);
        setOpenRecordItemEditorModal(true);
    }

    const openRecordItemProlonger = (item: IEmployeeScheduleDay) => {

        setSelectedItemsKeys([getItemKey(item)]);

        if(recordType !== RecordType.Schedule) {
            return;
        }

        setSelectedItem(item);
        setOpenProlongModal(true);
    }

    const openRecordItemTransfer = (item: IEmployeeScheduleDay) => {

        setSelectedItemsKeys([getItemKey(item)]);

        if(recordType !== RecordType.Schedule) {
            return;
        }

        setSelectedItem(item);
        setOpenTransferModal(true);
    }

    const openVacationItemEditor = (item: IEmployeeScheduleDay) => {
        
        setSelectedItemsKeys([getItemKey(item)]);

        if(recordType !== RecordType.Schedule) {
            return;
        }

        setSelectedItem(item);
        setOpenVacationModal(true);
    }

    const openSickItemEditor = (item: IEmployeeScheduleDay) => {
        
        setSelectedItemsKeys([getItemKey(item)]);

        if(recordType !== RecordType.Schedule) {
            return;
        }

        setSelectedItem(item);
        setOpenSickModal(true);
    }

    const openRecordItemContextMenu = useCallback((item: IEmployeeScheduleDay) => {

        setSelectedItemsKeys([getItemKey(item)]);

        if(!item.contextMenuAvailable) {
            return;
        }

        if(recordType !== RecordType.Schedule) {
            return;
        }

        setSelectedItem(item);
        setCellContextMenuProps({ open: true });
    }, [recordType]);

    const openRecordItemDetail = (item: IEmployeeScheduleDay) => {

        setSelectedItemsKeys([getItemKey(item)]);

        if(recordType !== RecordType.Schedule) {
            return;
        }

        setSelectedItem(item);
        setOpenRecordItemDetailDrawer(prev => {

            if(!prev
            && employeeDayValueGridRef.current
            && employeeDayValueTableOuterGridRef.current) {

                const outerGrid = employeeDayValueTableOuterGridRef.current;
                const scrollLeft = employeeDayValueGridRef.current.state.scrollLeft;
                const prevWidth = employeeDayValueGridRef.current.props.width;
                const nextWidth = prevWidth - 378;
                const updateScroll = getScrollItemPosition(item, outerGrid, prevWidth, nextWidth, scrollLeft);

                updateScrollRef.current = updateScroll;
            }

            return true;
        });
    }

    const openDayContextMenu = useCallback((column: HeaderColumnType) => {

        setSelectedItemsKeys([]);

        if(recordType !== RecordType.Schedule) {
            return;
        }

        setSelectedItem(column);
        setCellContextMenuProps({ open: true });

    }, [recordType]);

    const openShrinkModalWin = (item: HeaderColumnType | IEmployeeScheduleDay) => {

        if(recordType !== RecordType.Schedule) {
            return;
        }

        setSelectedItem(item);
        setOpenShrinkModal(true);
    }

    const handleContextMenuEmployeeRecordItem = useCallback((item: IEmployeeScheduleDay, event: RecordItemElementEvent<React.MouseEvent>) => {

        event.preventDefault();
        
        // TODO: блокируем контекстное меню для дней, в которых есть запрос на изменение
        if (item.requestId) {
            setOpenRequestHandlerModal(true);
            setSelectedItem(item);
            setSelectedItemsKeys([getItemKey(item)]);
            return;
        }

        openRecordItemContextMenu(item);
    }, [openRecordItemContextMenu]);

    const handleContextMenuHeaderColumn = useCallback((date: moment.Moment, event: React.MouseEvent) => {

        event.preventDefault();

        const tmp = moment(date) as unknown as HeaderColumnType;
        tmp.htmlElement = event.target as unknown as HTMLElement;

        openDayContextMenu(tmp);
    }, [openDayContextMenu]);

    const itemClickRef = useRef<{time: number, key: string} | undefined>(undefined);
    const itemClickTimeoutRef = useRef<NodeJS.Timeout | undefined>(undefined);
    const doubleClickTimeout = 2000;

    const handleClickHeaderColumn = useCallback((date: moment.Moment, event: React.MouseEvent) => {

        const prevClickData = itemClickRef.current;
        const now = +new Date();
        const itemKey = date.toString();

        if (event.detail === 2 || event.type === 'dbclick' ||
           (prevClickData &&
            prevClickData.key === itemKey &&
            prevClickData.time + doubleClickTimeout > now)) {
            itemClickRef.current = undefined;
            openIntervalActivityEditorForDay(date);
        }
        else {
            itemClickRef.current = {
                key: itemKey,
                time: now,
            }
        }
    }, [openIntervalActivityEditorForDay]);

    const handleClickEmployeeRecordItem = useCallback((item: IEmployeeScheduleDay, event: RecordItemElementEvent<React.MouseEvent>) => {

        event.preventDefault();

        clearTimeout(itemClickTimeoutRef.current);
        itemClickTimeoutRef.current = undefined;

        const prevClickData = itemClickRef.current;
        const now = +new Date();
        const itemKey = getItemKey(item);

        if (event.detail === 2 || event.type === 'dbclick' ||
           (prevClickData &&
            prevClickData.key === itemKey &&
            prevClickData.time + doubleClickTimeout > now)) {
            itemClickRef.current = undefined;
            openIntervalActivityEditorForItem(item);
            return;
        }

        itemClickRef.current = {
            key: itemKey,
            time: now,
        };

        itemClickTimeoutRef.current = setTimeout(() => {

            setSelectedItemsKeys(prev => {

                const keys = selectItemsHandler(listProvider.scheduleItemList.items, prev, item, event);
    
                if (keys.length === 1 && keys[0] === itemKey) {
                    setSelectedItem(item);
                } else {
                    setSelectedItem(undefined);
                }
    
                return keys;
            });

        }, 30);

    }, [openIntervalActivityEditorForItem,
        listProvider.scheduleItemList.items
    ]);
    
    const handleKeyPress = useCallback((event: KeyboardEvent, selected: Readonly<string[]>) => {
        
        setCellContextMenuProps({ open: false });

        if(resetting
        || publish
        || event.code !== 'Enter'
        || selected.length !== 1
        || !listProvider.scheduleItemList.load) {
            return;
        }

        const itemKey = selected[0];
        const item    = listProvider.scheduleItemList.items.filter(x => recordItemComparer(itemKey, x))[0];

        if(!item) {
            return;
        }

        // При нажатии на кнопку Enter, даем возможность редактировать элемент
        // при зажатом контроле, открываем редактирование дня
        if (event.ctrlKey) openRecordItemContextMenu(item);
        else               openIntervalActivityEditorForItem(item);

    }, [openRecordItemContextMenu,
        openIntervalActivityEditorForItem,
        listProvider.scheduleItemList.items]);

    const handleResetClick = () => {

        modal.confirm({
            title: 'Подтвердите действие',
            icon: <ExclamationCircleOutlined />,
            content: 'Вы уверены, что хотите откатить изменения ?',
            okText: "Да",
            cancelText: "Нет",
            onOk: () => {
                setResetting(true);
            },
        });
    }

    const handlePublishClick = () => {
        
        modal.confirm({
            title: 'Подтвердите действие',
            icon: <ExclamationCircleOutlined />,
            content: 'Вы уверены, что хотите опубликовать изменения ?',
            okText: "Да",
            cancelText: "Нет",
            onOk: () => {
                setPublish(true);
            },
        });
    }

    const handleGoToMonthlyChart = () => {
        setIntervalEditorDay(undefined);
        setOpenIntervalActivityModal(false);
        syncWithDb();
    }

    const handleGoToIntervalEditor = () => {
        setOpenIntervalActivityGeneratorModal(false);
    }

    const handleActivitiesSave = () => setSaving(true);
    const handleFinishActivitiesSave = () => setSaving(false);

    const handleOpenGeneratorBtnClick = () => {
        setOpenIntervalActivityGeneratorModal(true);
    }

    const handleClickEmployeeCell = useCallback((employee: IEmployee, event: React.MouseEvent) => {
        setOpenEmployeeCard(true);
        setSelectedEmployee(employee);
    }, []);

    const handleOpenShiftExchangeModal = (item?: IEmployeeScheduleDay | HeaderColumnType) => {

        if(recordType !== RecordType.Schedule) {
            return;
        }

        setSelectedItem(item);
        setOpenShiftExchangeModal(true);
    }

    const handleFinishShiftExchangeModal = () => {
        setOpenShiftExchangeModal(false);
    }

    const handleCloseShiftExchangeModal = () => {
        setOpenShiftExchangeModal(false);
    }

    const handleContextMenuEmployeeCell = useCallback(() => {
        // todo
    }, []);

    const handleCloseEmployeeCard = () => {
        setOpenEmployeeCard(false);
    }

    const handleCloseVacationModal = () => {
        setOpenVacationModal(false);
    }

    const handleCloseSickModal = () => {
        setOpenSickModal(false);
    }

    const handleReqHandlerFinishSave = () => {
        setOpenRequestHandlerModal(false);
        syncWithDb();
    }

    const handleCloseReqHandlerModal = () => {
        setOpenRequestHandlerModal(false);
    }

    useEffect(() => {

        if(!listProvider.employeeList.load) {
            return;
        }

        const newEmployees = listProvider.employeeList.items;
        setSelectedEmployeeRecord(selectedEmployee => {

            if(selectedEmployee) {
                const index = newEmployees.findIndex(employee => employee.PID === selectedEmployee.PID);
                if(index !== -1) {
                    const updateEmployee = newEmployees[index];
                    return updateEmployee;
                }
            }

            return undefined;
        });

    }, [listProvider.employeeList.items]);

    useEffect(() => {
        
        setCellContextMenuProps({open: false});

        if (listProvider.scheduleItemList.load) {

            const updatedItems = listProvider.scheduleItemList.items;

            setSelectedItem(prevItem => prevItem && !isHeaderColumn(prevItem)
                ? updatedItems.filter(x => recordItemComparer(x, prevItem))[0]
                : prevItem
            );
        }

    }, [listProvider.scheduleItemList.items]);

    useEffect(() => {

        if (!didMount.current
        || authProvider.isLogout
        || authProvider.isLoging) {
            return;
        }

        setLoadingFilterSaveData(true);

        const abortController = new AbortController();

        getScheduleBoardFilter(authProvider, abortController.signal)
        .finally(() => setLoadingFilterSaveData(false))
        .then(filter => {

            filterValuesDispatch({
                type: MutationActionType.NoSideMutate,
                completely: true,
                payload: filter
            });
        });

        return () => {
            abortController?.abort(new OperationAbortedByMergeException());
        }

    }, [authProvider.data?.staticId]);

    const [workSpaceSize, setWorkSpaceSize] = useState(() => [window.innerWidth, window.innerHeight]);
    const boardContainerRef = useRef<HTMLDivElement>(null);
    const workSpaceRef = useRef<HTMLDivElement>(null);

    useEffect(() => {

        function updateSize() {

            if(workSpaceRef.current && boardContainerRef.current) {
                const workSpaceRect = workSpaceRef.current.getBoundingClientRect();
                const width  = boardContainerRef.current.clientWidth;
                const height = boardContainerRef.current.clientHeight - workSpaceRect.top;

                setWorkSpaceSize([width, height]);
            }
        }

        updateSize();
        window.addEventListener('resize', updateSize, { passive: true });
        return () => window.removeEventListener('resize', updateSize);
    }, []);

    async function publishChanges(signal?: AbortSignal) {

        try {
            message.loading({ content: 'Публикация изменений...', key: publishMessageKey });
            const exceptions = await publishScheduleChanges(authProvider, signal);

            // TODO publish exception

            message.success({
                content: 'Публикация изменений завершена',
                key: publishMessageKey,
                duration: 2
            });
        }
        catch (ex) {
            console.error(ex);
            message.error({
                content: (ex instanceof BackendResponseException ? ex.message : 'Не удалось опубликовать изменения'),
                key: publishMessageKey,
                duration: 2
            });
        }
        finally {
            setPublish(false);
        }
    }

    useEffect(() => {

        if(publish) {
            const abortController = new AbortController();
            publishChanges(abortController.signal);
        }

    }, [publish]);

    useEffect(() => {

        if(resetting !== true) {
            return;
        }

        message.loading({ content: 'Откат изменений...', key: resetMessgaeKey });

        const abortController = new AbortController();

        async function reset() {

            try {

                await resetScheduleChanges(
                    authProvider,
                    filterValues,
                    selectedEmployeeRecord,
                    intervalEditorDay?.clone().startOf('day'),
                    intervalEditorDay?.clone().add(1, 'day').startOf('day'),
                    abortController.signal
                )

                message.success({
                    content: 'Откат изменений завершен',
                    key: resetMessgaeKey,
                    duration: 2
                });
                
                handleUpdateActualRef.current();
            }
            catch(ex) {

                message.error({
                    content: 'Не удалось откатить изменения',
                    key: resetMessgaeKey,
                    duration: 2
                });

                console.error(ex);
            }
            finally {
                setResetting(false);
            }
        }

        reset();

    }, [resetting]);

    useEffect(() => {

        if (openRecordItemDetailDrawer && updateScrollRef.current) {

            const updateScroll = updateScrollRef.current;
            updateScrollRef.current = false;

            const { rowIndex, columnIndex } = updateScroll;

            const interval = setTimeout(() => {

                // TODO
                // Здесь наблюдается проблема при изменении размера,
                // не правильно расчитыволось смещение scrollLeft
                employeeDayValueGridRef.current?.scrollToItem({
                    align: 'smart',
                    rowIndex: rowIndex,
                    columnIndex: columnIndex,
                });

            }, 500);

            return () => clearTimeout(interval);
        }

    }, [openRecordItemDetailDrawer]);

    useEffect(() => {
        didMount.current = true;
        window.dispatchEvent(new Event('resize'));
        return () => {
            didMount.current = false;
        }
    }, []);

    // TODO
    const contentWidth  = workSpaceSize[0] - Number(openFilter || openEmployeeCard) * drawerWidth - Number(openRecordItemDetailDrawer) * drawerWidth;
    const contentHeight = workSpaceSize[1];

    const handleOpenSSSEModal = () => {
        setOpenSSSEModal(!openSSSEModal);
    }

    const handleOpenGeneratorModal = () => {
        setOpenGeneratorModal(!openGeneratorModal);
    }

    const handleFinishGenerateSchedule = () => {
        setOpenGeneratorModal(false);
        syncWithDb();
    }

    return (
        <div
            ref={boardContainerRef}
            className="ap-schedule-board"
        >
            {(publish || resetting || saving || sync) &&
            <div className="await-indicator">
                <div className="loader-line"></div>
            </div>}
            <Space
                align='baseline'
                className="top-space"
            >
                <Space
                    align='start'
                >
                    {/*<Dropdown menu={menu}>
                        <Button size="small">Меню</Button>
                    </Dropdown>*/}
                    {!openIntervalActivityEditor && !openIntervalActivityGenerator &&
                    <CheckAccess
                        module={Module.ScheduleOptimizerApply}
                    >
                        <Button
                            size={size}
                            onClick={handleOpenGeneratorModal}
                        >
                            Сформировать
                        </Button>
                    </CheckAccess>}
                    {!openIntervalActivityEditor && !openIntervalActivityGenerator &&
                    <DatePicker.RangePicker
                        picker="month"
                        size={size}
                        value={[filterValues.from, filterValues.to]}
                        allowClear={false}
                        onChange={handleRangePickerChange}
                        locale={locale.datePickerLocale}
                        disabled={syncFilterRange}
                    />}
                    <Button
                        type='text'
                        icon={<FilterOutlined />}
                        size={size}
                        disabled={syncFilterRange || openIntervalActivityGenerator}
                        onClick={handleToogleShowFilter}
                    >
                        {locale.filter}
                    </Button>
                    <Button
                        icon={<ReloadOutlined />}
                        size={size}
                        type='primary'
                        onClick={handleUpdate}
                        disabled={syncFilterRange || publish || resetting || saving || openIntervalActivityGenerator}
                    >
                        {locale.update}
                    </Button>
                    {!openIntervalActivityEditor && !openIntervalActivityGenerator &&
                    <ReportListWithoutParams
                        size={size}
                        disabled={syncFilterRange || publish || resetting || saving}
                    />}
                    <Button
                        disabled={syncFilterRange || publish || resetting || saving || openIntervalActivityGenerator}
                        loading={listProvider.employeeList.loading || listProvider.scheduleItemList.loading || listProvider.infoBlock.loading}
                        icon={<HighlightOutlined />}
                        size={size}
                        onClick={handleResetClick}
                    >
                        {locale.reset}
                    </Button>
                    <PublishButton
                        size={size}
                        icon={<PullRequestOutlined />}
                        disabled={syncFilterRange || publish || resetting || saving || !publishAvailable || openIntervalActivityGenerator}
                        loading={listProvider.employeeList.loading || listProvider.scheduleItemList.loading || listProvider.infoBlock.loading}
                        onClick={handlePublishClick}
                    >
                        {locale.publish}
                    </PublishButton>
                    {openIntervalActivityEditor && intervalEditorDay &&
                    <Space size={2}>
                        <Button
                            icon={<LeftOutlined />}
                            size={size}
                            disabled={
                                openIntervalActivityGenerator ||
                                publish ||
                                resetting ||
                                saving ||
                                listProvider.employeeList.loading ||
                                listProvider.scheduleItemList.loading ||
                                listProvider.infoBlock.loading
                            }
                            onClick={() => {
                                const prevDay = intervalEditorDay.clone().add(-1, 'day');
                                setIntervalEditorDay(prevDay);
                            }}
                        />
                        <DatePicker
                            value={intervalEditorDay}
                            size={size}
                            disabled={
                                openIntervalActivityGenerator ||
                                publish ||
                                resetting ||
                                saving ||
                                listProvider.employeeList.loading ||
                                listProvider.scheduleItemList.loading ||
                                listProvider.infoBlock.loading
                            }
                            onChange={(value) => {

                                if(value) {
                                    
                                    setIntervalEditorDay(value);
                                }
                            }}
                        />
                        <Button
                            icon={<RightOutlined />}
                            size={size}
                            disabled={
                                openIntervalActivityGenerator ||
                                publish ||
                                resetting ||
                                saving ||
                                listProvider.employeeList.loading ||
                                listProvider.scheduleItemList.loading ||
                                listProvider.infoBlock.loading
                            }
                            onClick={() => {
                                const nextDay = intervalEditorDay.clone().add(1, 'day');
                                setIntervalEditorDay(nextDay);
                            }}
                        />
                    </Space>}
                </Space>
                <Space align='end'>
                    <NotificationBoard />
                    {openIntervalActivityEditor && !openIntervalActivityGenerator &&
                    <Button
                        size={size}
                        icon={<RollbackOutlined />}
                        onClick={handleGoToMonthlyChart}
                        disabled={publish || resetting || saving}
                    >
                        {locale.goToMonthlyChart}
                    </Button>}
                    {openIntervalActivityGenerator &&
                    <Button
                        size={size}
                        icon={<RollbackOutlined />}
                        onClick={handleGoToIntervalEditor}
                        disabled={publish || resetting || saving}
                    >
                        {locale.goToIntervalEditor}
                    </Button>}
                    {!(openIntervalActivityEditor || openIntervalActivityGenerator) &&
                    <Button
                        size={size}
                        icon={<InfoCircleOutlined />}
                        onClick={openReferenceModalFn}
                    >
                        {locale.reference}
                    </Button>}
                </Space>
            </Space>
            <div
                ref={workSpaceRef}
                className={classNames({
                    "schedule-board": true,
                    "open-filter": openFilter || openEmployeeCard,
                    "open-detail": openRecordItemDetailDrawer
                })}
                style={{
                    height: contentHeight
                }}
            >
                {openIntervalActivityEditor && intervalEditorDay &&
                <div className="interval-activity-board-wrapper">
                    <EmployeesDayScheduleIntervalBoard
                        updaterRef={dayScheduleIntervalBoardUpdaterRef}
                        loading={listProvider.employeeList.loading || listProvider.scheduleItemList.loading}
                        disableControl={publish || resetting || openIntervalActivityGenerator}
                        filter={filterValues}
                        date={intervalEditorDay}
                        employees={listProvider.employeeList}
                        employee={selectedEmployeeRecord}
                        width={contentWidth}
                        height={contentHeight}
                        onOpenGeneratorBtnClick={handleOpenGeneratorBtnClick}
                        onActivitiesSave={handleActivitiesSave}
                        onFinishActivitiesSave={handleFinishActivitiesSave}
                    />
                </div>}
                {openIntervalActivityGenerator && intervalEditorDay &&
                <div className="interval-activity-board-wrapper">
                    <ScheduleGeneratorBoard
                        onFinish={handleFinishGeneration}
                        loading={listProvider.employeeList.loading || listProvider.scheduleItemList.loading}
                        //disableControl={publish || resetting}
                        filter={filterValues}
                        date={intervalEditorDay}
                        width={contentWidth}
                        height={contentHeight}
                        employees={listProvider.employeeList}
                    />
                </div>}
                <ScheduleBoardFilter
                    size='small'
                    loading={syncFilterRange || loadingFilterSaveData}
                    open={openFilter}
                    values={filterValues}
                    valuesDispatch={filterValuesDispatch}
                    onClose={handleFilterClose}
                    onFinish={handleFilterFinish}
                />
                <EmployeeCardDrawer
                    open={openEmployeeCard}
                    employee={selectedEmployee}
                    onClose={handleCloseEmployeeCard}
                    onScheduleChange={syncWithDb}
                />
                <EmployeesDayScheduleCellDetailInfo
                    drawerFocusRef={detailDrawerFocusRef}
                    open={openRecordItemDetailDrawer}
                    employees={listProvider.employeeList}
                    item={selectedItem}
                    filter={filterValues}
                    onChange={handleChangeCellDetail}
                    onClose={handleCloseCellDetail}
                />
                <EmployeesDayDataBoard
                    //elementsWithAllowedArrowControl={elementsWithAllowedArrowControl}
                    loading={publish || resetting}
                    width={contentWidth}
                    height={contentHeight}
                    itemClassName={gridItemClassName}
                    recordType={recordType}
                    onChangeRecordType={handleChangeRecordType}
                    filter={filterValues}
                    itemsGridFocuserRef={employeeDayValueTableFocuser}
                    itemsOuterGridRef={employeeDayValueTableOuterGridRef}
                    employees={listProvider.employeeList}
                    items={listProvider.scheduleItemList}
                    itemsGridRef={employeeDayValueGridRef}
                    itemsMarkers={listProvider.scheduleItemsMarkers}
                    infoBlockItems={listProvider.infoBlock}
                    selectedItemsKeys={selectedItemsKeys}
                    onContextMenuHeaderColumn={handleContextMenuHeaderColumn}
                    onContextMenuRecordItem={handleContextMenuEmployeeRecordItem}
                    onClickRecordItem={handleClickEmployeeRecordItem}
                    onDoubleClickRecordItem={handleClickEmployeeRecordItem}
                    onClickHeaderColumn={handleClickHeaderColumn}
                    onDoubleClickHeaderColumn={handleClickHeaderColumn}
                    onKeyPress={handleKeyPress}
                    onClickEmployeeCell={handleClickEmployeeCell}
                    onContextMenuEmployeeCell={handleContextMenuEmployeeCell}
                />
                {selectedItem &&
                <>
                    <ScheduleShrinkModal
                        open={openShrinkModal}
                        onOk={handleOkShrinkModal}
                        onCancel={handleCancelShrinkModalFn}
                        employees={listProvider.employeeList}
                        item={selectedItem}
                    />
                    <ScheduleProlongModal
                        open={openProlongModal}
                        onOk={handleOkProlongModal}
                        onCancel={handleCancelProlongModalFn}
                        employees={listProvider.employeeList}
                        item={selectedItem}
                    />
                    <ScheduleShiftTransferModal
                        open={openTransferModal}
                        employees={listProvider.employeeList}
                        item={selectedItem}
                        onCancel={handleCancelTransferModalFn}
                        onOk={handleOkTransferModal}
                    />
                </>}

                {selectedItem && !isMoment(selectedItem) &&
                <>
                    <EmployeesDayScheduleCellEditor
                        open={openRecordItemEditorModal}
                        dayTypes={listProvider.dayTypeList}
                        employees={listProvider.employeeList}
                        item={selectedItem}
                        filter={filterValues}
                        onCancel={handleCancelCellEditor}
                        onOk={handleOkCellEditor}
                    />
                    <VacationFromScheduleItemModal
                        item={selectedItem}
                        open={openVacationModal}
                        onFinish={handleVacationFinish}
                        onCancel={handleCloseVacationModal}
                        employees={listProvider.employeeList}
                    />
                    <ScheduleSickModal
                        item={selectedItem}
                        open={openSickModal}
                        onFinish={handleSickFinishSave}
                        onCancel={handleCloseSickModal}
                        employees={listProvider.employeeList}
                    />
                    <ScheduleRequestHandlerModal
                        item={selectedItem}
                        open={openRequestHandlerModal}
                        onFinish={handleReqHandlerFinishSave}
                        onCancel={handleCloseReqHandlerModal}
                        //employees={listProvider.employeeList}
                    />
                </>}
            </div>
            <CellContextMenu
                {...cellContextMenuProps}
                ref={contextMenuRef}
                item={selectedItem}
                outerGridRef={employeeDayValueTableOuterGridRef}
                onOpenChange={(value, reason) => {

                    setCellContextMenuProps(prev => ({...prev, open: value}));

                    // Восстанавливаем фокус для управления через клавиши
                    if (reason === 'escape-down' ||
                        reason === 'tab-down' ||
                        reason === 'wheel' ||
                        reason === 'resize') {
                        employeeDayValueTableFocuser.current?.focus();
                    }
                }}
                onSelect={(option, item) => {

                    setCellContextMenuProps({ open: false });

                    if (option === 'shiftExchange') {
                        handleOpenShiftExchangeModal(item);
                        return;
                    }

                    if(option === 'shiftReduction') {
                        openShrinkModalWin(item);
                        return;
                    }

                    if(isHeaderColumn(item)) {
                        return;
                    }

                    switch(option) {
                        case 'edit':         openRecordItemEditor(item); break;
                        case 'info':         openRecordItemDetail(item); break;
                        case 'shiftProlong': openRecordItemProlonger(item); break;
                        case 'shiftChange':  openRecordItemTransfer(item); break;
                        case 'vacation':     openVacationItemEditor(item); break;
                        case 'sick':         openSickItemEditor(item); break;
                        default: break;
                    }
                }}
            />
            <ReferenceModal
                open={openReferenceModal}
                title={locale.reference}
                onOk={closeReferenceModalFn}
                onCancel={closeReferenceModalFn}
            />
            <ScheduleShiftModal
                open={openSSSEModal}
                onOk={handleOpenSSSEModal}
                onCancel={handleOpenSSSEModal}
            />
            <ScheduleGeneratorModal
                open={openGeneratorModal}
                onCancel={handleOpenGeneratorModal}
                onFinish={handleFinishGenerateSchedule}
            />
            <ShiftExchangeModal
                open={openShiftExchangeModal}
                item={selectedItem}
                filter={filterValues}
                onFinish={handleFinishShiftExchangeModal}
                onCancel={handleCloseShiftExchangeModal}
            />
            {modalContextHolder}
            {messageContextHolder}
            <RuntimeNotifications />
        </div>
    );
}

export default ScheduleBoard