import { useEffect, useRef } from "react";
import { useNotificactionHub } from "../../../Hooks/useNotificactionHub";
import { INotificationBase, IRuntimeNotification } from "../../../Entities/BackendAPIHub";
import { notification, NotificationArgsProps } from "antd";
import { getNotificationType } from "../helpers";
import { CloseOutlined } from "@ant-design/icons";

type NotificationPlacement = NotificationArgsProps['placement'];

export function getNotificationSnippetKey(notification: INotificationBase) {
    return `notification_snippet_${notification.id}`;
}

export function RuntimeNotifications() {
    
    const [api, contextHolder] = notification.useNotification();
    const notificationHub = useNotificactionHub();

    const destroyNotification = (notification: INotificationBase) => {
        const key = getNotificationSnippetKey(notification);
        api.destroy(key);
    }

    const showNotification = (notification: IRuntimeNotification, placement: NotificationPlacement) => {

        notificationHub.fire('displayedNotification', notification, new Date);
        
        const readNotification = () => {
            notificationHub.fire('readNotification', notification, new Date);
        }

        const type      = getNotificationType(notification);
        const key       = getNotificationSnippetKey(notification);
        const renderer  = api[type];
        const duration  = notification.limitSec;
        const closeIcon = (
            <span onClickCapture={readNotification}>
                <CloseOutlined />
            </span>
        );
        
        renderer({
            key: key,
            message: '',//notification.subj,
            description: notification.body,
            duration,
            type,
            placement,
            closeIcon,
        });
    };

    useEffect(() => {

        const pushNotificationHandler = (notification: IRuntimeNotification) => {
            showNotification(notification, 'bottomRight');
        }

        const readNotificationHandler = (notification: INotificationBase) => {
            destroyNotification(notification);
        }

        notificationHub.on('pushNotification', pushNotificationHandler);
        notificationHub.on('readNotification', readNotificationHandler);

        return () => {
            notificationHub.off('pushNotification', pushNotificationHandler);
            notificationHub.off('readNotification', readNotificationHandler);
        }

    }, []);

    return (
        <>
            {contextHolder}
        </>
    );
}

export default RuntimeNotifications;