import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, XAxis, YAxis, Tooltip, ReferenceArea, ComposedChart, Bar } from "recharts";
import { IItem } from "./utils";
import moment, { isMoment } from "moment";
import { Ref, useEffect, useMemo, useRef, useState } from "react";
import { refSetter } from "../shared/helpers";
import { IChartDataItem } from "../../Entities/Database";

interface IChartViewItem {
    type: number,
    view: 'line' | 'bar',
    title: string,
    color: string,
}

export enum RecordItemType {
    Forecast = 1,
    Workload = 2,
    Schedule = 3,
    Agents   = 4,
    Breaks   = 5,
}

const chartLines: Record<IChartViewItem['type'], IChartViewItem> = {
    [RecordItemType.Forecast]: {type: RecordItemType.Forecast, view: 'line', title: 'Прогноз',    color: 'rgb(47, 128, 237)'},
    [RecordItemType.Workload]: {type: RecordItemType.Workload, view: 'line', title: 'Нагрузка',   color: 'rgb(0, 0, 0)'},
    [RecordItemType.Schedule]: {type: RecordItemType.Schedule, view: 'line', title: 'Расписание', color: 'rgb(130, 202, 157)'},
    [RecordItemType.Agents]:   {type: RecordItemType.Agents,   view: 'line', title: 'В работе',   color: 'rgb(160 91 197)'},
    [RecordItemType.Breaks]:   {type: RecordItemType.Breaks,   view: 'bar',  title: 'Перерывы',   color: 'rgb(255, 153, 1)'},
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface InitialState {
    left: string | number;
    right: string | number;
    refAreaLeft: number | string | undefined;
    refAreaRight: number | string | undefined;
    top: string | number | ((x: number) => number);
    bottom: string | number | ((x: number) => number);
    //top2: string | number;
    //bottom2: string | number;
    animation: boolean;
}

const initialState: InitialState = {
    left: 'dataMin',
    right: 'dataMax',
    refAreaLeft: '',
    refAreaRight: '',
    top: (dataMax) => Math.ceil(dataMax as number) + 1,
    bottom: (dataMin) => Math.floor(dataMin as number),
    //top2: 'dataMax+20',
    //bottom2: 'dataMin-20',
    animation: false,
};

export interface IChartProps {
    boardRef?: Ref<HTMLDivElement>,
    loading?: boolean,
    width?: number,
    height?: number,
    lines?: IChartDataItem['type'][],
    dataSource?: IItem[],
    cellWidth?: number,
    widthOffset?: number,
    fullWidthOnSmall?: boolean,
}

export function Chart({
    boardRef,
    loading,
    width,
    height,
    lines,
    dataSource,
    cellWidth = 74,
    widthOffset = 192,
    fullWidthOnSmall = true,
}: IChartProps) {

    const [filteredData, setFilteredData] = useState(dataSource);
    const [state, setState] = useState(initialState);
    const [xTicks, setXTicks] = useState<number[]>([]);
    const [chartWidth, setChartWidth] = useState(width);

    const getAxisYDomain = (from: number, to: number, ref: number, offset: number) => {

        const refData = dataSource!.filter(x => x.date >= from && x.date <= to);
        let [bottom, top] = [refData[0][ref], refData[0][ref]];

        refData.forEach((d) => {
            if (d[ref] > top) top = d[ref];
            if (d[ref] < bottom) bottom = d[ref];
        });
      
        return [(bottom | 0) - offset, (top | 0) + offset];
    }

    const zoom = () => {

        let { refAreaLeft, refAreaRight } = state;

        if (refAreaLeft === refAreaRight ||
            !refAreaRight ||
            !refAreaLeft ||
            typeof refAreaRight === 'string' ||
            typeof refAreaLeft === 'string' ||
            !lines ||
            lines.length < 1) {
            setState(state => ({
                ...state,
                refAreaLeft: '',
                refAreaRight: '',
            }));
            return;
        }
    
        // xAxis domain
        if (refAreaLeft > refAreaRight) [refAreaLeft, refAreaRight] = [refAreaRight, refAreaLeft];

        // yAxis domain
        let bottom = Number.MAX_SAFE_INTEGER;
        let top = Number.MIN_SAFE_INTEGER;

        for (let lineIndex = 0; lineIndex < lines.length; lineIndex++) {
            const [bottomTmp, topTmp] = getAxisYDomain(refAreaLeft, refAreaRight, lines[lineIndex], 1);
            bottom = Math.min(bottom, bottomTmp);
            top = Math.max(top, topTmp);
        }

        const left = refAreaLeft;
        const right = refAreaRight;

        setState(state => ({
            ...state,
            refAreaLeft: '',
            refAreaRight: '',
            left: left,
            right: right,
            bottom,
            top,
        }));
    }

    const reset = () => {
        setFilteredData(dataSource);
        setState(initialState);
    }

    useEffect(() => {

        reset();

        if (!dataSource || !dataSource.length) {
            setChartWidth(width);
            return;
        }

        let min = dataSource[0].dateVal;
        let max = dataSource[0].dateVal;

        for (const item of dataSource) {
            if (min > item.dateVal) min = item.dateVal;
            if (max < item.dateVal) max = item.dateVal;
        }

        const hoursCount = max.diff(min, 'hours', true);
        const fixHoursCount = Math.ceil(hoursCount);
        const chartWidth = (fixHoursCount * cellWidth) + widthOffset;

        // TODO: подгон под min max

        const ticks = [];
        const curr = min.clone();

        while (curr <= max) {
            ticks.push(curr.valueOf());
            curr.add(3, 'hour');
        }

        setXTicks(ticks);
        setChartWidth(fullWidthOnSmall && width && chartWidth < width ? width : chartWidth);

    }, [width, dataSource, cellWidth, widthOffset]);

    return (
        <Spin
            indicator={antIcon}
            spinning={loading}
        >
            <div
                ref={refSetter(boardRef)}
                onContextMenu={reset}
                style={{
                    userSelect: 'none',
                    width: width,
                    height: height,
                    overflowX: 'scroll',
                    overflowY: 'hidden'
                }}
            >
                <ComposedChart
                    width={chartWidth}
                    height={height ? height - 20 : undefined}
                    margin={{ top: 10, right: 10, bottom: 0, left: 135 }}
                    style={{fontSize: 10}}
                    data={filteredData}
                    /*
                    onMouseDown={(e) => {
                        setState(state => ({
                            ...state,
                            refAreaLeft: e?.activeLabel
                        }));
                    }}
                    onMouseMove={(e) => e && state.refAreaLeft && setState(state => ({
                        ...state,
                        refAreaRight: e.activeLabel
                    }))}
                    onMouseUp={zoom}
                    */
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis allowDataOverflow dataKey="date" domain={[state.left, state.right]} tick={<CustomizedAxisTick />} type="number" interval={'preserveStart'} ticks={xTicks} />
                    <YAxis yAxisId={"1"} allowDataOverflow domain={[state.bottom, state.top]} type="number" />
                    <Legend verticalAlign="top" />
                    {lines?.map((graphSource, index) =>
                        chartLines[graphSource] && (
                        chartLines[graphSource].view === 'line' ? (
                            <Line
                                type="monotone"
                                yAxisId={"1"}
                                key={graphSource}
                                name={chartLines[graphSource].title}
                                stroke={chartLines[graphSource].color}
                                isAnimationActive={false}
                                dataKey={graphSource}
                            />
                        ) :
                        chartLines[graphSource].view === 'bar' ? (
                            <Bar
                                yAxisId={"1"}
                                key={graphSource}
                                name={chartLines[graphSource].title}
                                stroke={chartLines[graphSource].color}
                                fill={chartLines[graphSource].color}
                                isAnimationActive={false}
                                dataKey={graphSource}
                                barSize={20}
                            />
                        ) :
                        (<></>)
                    ))}
                    <Tooltip labelFormatter={(label, payload) => {
                        return payload && payload.length > 0 && payload[0].payload.dateVal.format("YYYY-MM-DD HH:mm") || label;
                    }} />

                    {state.refAreaLeft && state.refAreaRight ? (
                        <ReferenceArea yAxisId={"1"} x1={state.refAreaLeft} x2={state.refAreaRight} strokeOpacity={0.3} />
                    ): null}
                </ComposedChart>
            </div>
        </Spin>
    )
}

export function CustomizedAxisTick(props: any) {

    const { x, y, orientation, textAnchor, verticalAnchor, fill, stroke, width, height, className, payload } = props;
    const date = payload.value;
    const text = useMemo(() => (isMoment(date) ? date : moment(date)).format("HH:mm"), [date]);

    return (
        <text
            orientation={orientation}
            width={width}
            height={height}
            stroke={stroke}
            x={x}
            y={y}
            textAnchor={textAnchor}
            fill={fill}
            className={className + "recharts-text recharts-cartesian-axis-tick-value"}
            /*transform="rotate(-35)"*/
        >
            <tspan
                x={x}
                dy={'0.71em'}
            >
                {text}
            </tspan>
        </text>
    );
}