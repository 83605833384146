import { Divider, Form, message as AntdMessage } from "antd";
import ShiftExchangeForm from ".";
import { IEmployeeScheduleDay, IScheduleBoardFilter } from "../../Entities/Database";
import DraggableModal from "../DraggableModal";
import { IValues, addShiftTradeRequest } from "./index.db";
import { HeaderColumnType } from "../ScheduleBoard/CellContextMenu/helpers";
import useActual from "../../Hooks/useActual";
import useAuth from "../../Hooks/useAuth";
import { useState } from "react";
import { BackendResponseException } from "../../System/BackendResponseException";

export interface ISchiftExchangeModalProps {
    open: boolean,
    item: IEmployeeScheduleDay | HeaderColumnType | undefined,
    filter: IScheduleBoardFilter,
    onCancel?: () => void,
    onFinish?: () => void,
}

export default function ShiftExchangeModal({
    open,
    item,
    filter,
    onCancel,
    onFinish
}: ISchiftExchangeModalProps) {

    const auth = useAuth();
    const [message, messageContexHolder] = AntdMessage.useMessage();
    const [form] = Form.useForm<IValues>();
    const [saving, setSaving] = useState(false);
    
    const actualOnFinish = useActual(onFinish);

    function handleOk() {
        form.submit();
    }

    async function handleFinish(values: IValues) {

        try {
            setSaving(true);
            await addShiftTradeRequest(auth, values, filter);
            if (actualOnFinish.current) {
                actualOnFinish.current();
            }
        }
        catch (ex) {
            message.error(ex instanceof BackendResponseException ? ex.message : "Не удалось создать заявку.");
            console.error(ex);
        }
        finally {
            setSaving(false);
        }
    }

    return (
        <>
            <DraggableModal
                title={"Предложить подработку"}
                open={open}
                onOk={handleOk}
                onCancel={onCancel}
                closable={!saving}
                keyboard={!saving}
                cancelButtonProps={{ disabled: saving }}
                okButtonProps={{ loading: saving }}
                destroyOnClose
            >
                <ShiftExchangeForm
                    form={form}
                    item={item}
                    filter={filter}
                    disabled={saving}
                    onFinish={handleFinish}
                />
            </DraggableModal>
            {messageContexHolder}
        </>
    )
}