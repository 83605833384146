import { IHubEvents, useAPIHub } from "./useAPIHub";

export interface IStatuses {
    publishQueueQuantity: number;
}

export interface IStatusesHubEvents extends IHubEvents {
    pushStatuses(statuses: IStatuses): void;
}

export interface INotificationHubMethods {
}

export function useStatusesHub() {
    return useAPIHub<IStatusesHubEvents, INotificationHubMethods>();
}

export default useStatusesHub