import { IAssignEmployeeScheduleShiftLocale } from "..";

export interface IassignEmployeeShiftModalErrors {
    employee: string,
    scheme: string,
    startDate: string,
}

export const assignEmployeeScheduleShiftModalLocaleRu: IAssignEmployeeScheduleShiftLocale = {
    title: "Назначение шаблона",
    employee: "Сотрудник:",
    scheme: "Шаблон смены:",
    startDate: "Применить с:",
}

export const assignEmployeeShiftModalErrorTextLangRu: IassignEmployeeShiftModalErrors = {
    employee: "Выберите сотрудника",
    scheme: "Выберите шаблон",
    startDate: "Укажите дату начала"
}