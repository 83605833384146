import React, { PropsWithChildren, useEffect } from "react";
import { Module, checkAccess } from "../../Entities/Database";
import useAuth from "../../Hooks/useAuth";
import { useEntityLoader } from "../../Hooks/useEntityLoader";

export interface ICheckAccessProps extends PropsWithChildren {
    module: Module,
    loadingRenderer?: () => React.ReactNode,
    deniedRenderer?: () => React.ReactNode,
}

// TODO: доделать буферку, чтобы не делать запросы каждый раз к базе

export function CheckAccess({
    module,
    loadingRenderer,
    children
}: ICheckAccessProps) {
    
    const auth = useAuth();
    const [internalState, reloadAccessStatus] = useEntityLoader((signal) => checkAccess(auth, module, signal), [auth.data?.staticId, module]);

    useEffect(reloadAccessStatus, [reloadAccessStatus]);

    if (internalState.loading) {

        if (loadingRenderer) {
            return loadingRenderer() as JSX.Element;
        }

        return (
            <></>
        );
    }

    // TODO
    if (internalState.error || (internalState.load && !internalState.entity)) {

        return (
            <></>
        );
    }
    
    return children as JSX.Element;
}

export default CheckAccess;