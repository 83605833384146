import { Collapse, CollapseProps, TableProps } from "antd";
import { TableItem } from "../TableItem";
import "./style.css";
import { IExternalLogin } from "../../../Entities/Database";

export interface ITableCollapseProps<T> {
    loading?: boolean,
    label: string,
    size?: TableProps<T>['size'],
    items?: T[],
    rowKey?: TableProps<T>['rowKey'],
}

export function TableCollapseItem<T extends IExternalLogin>({
    loading,
    label,
    size,
    items,
    rowKey,
}: ITableCollapseProps<T>) {

    const modifiedDataSource = items?.map(item => {
        return({
            system: item.system,
            value: item.value,
            id: item.value,
        })
    })
   
    const colItems: CollapseProps['items']  = [
        {
            label: label,
            children: (
                <TableItem
                    loading={loading}
                    size={size}
                    dataSource={modifiedDataSource}
                    rowKey={rowKey as any}
                />
            ),
        },
    ];

    return (
        <Collapse 
            className="employee-collapse table-employee-collapse"
            items={colItems} 
        />
    );
}