import { Button, CollapseProps, Skeleton, Space } from "antd";
import { IEmployee, GroupMember, IProjectGroup, ISkill, ISpecialMark } from "../../../Entities/Database";
import { ListItemType } from "../../../Entities/EmployeeCard";
import { ListItemCollapse } from "../ListItemCollapse";
import { classNames } from "../../../System/ClassNames";
import { useCallback, useEffect, useState } from "react";
import { TagItem } from "../TagItem";
import { getBasicItemsArray, getItemLabel } from "../ListItemContainer/helper";
import { listItemTitlesLangRu } from "../ListItemContainer/locale/ru";

export interface IProjectGroupProps {
    onFinish?: () => void,
    edit?: boolean,
    loading: boolean,
    employee?: IEmployee,
    data?: IProjectGroup[],
}

export interface IListItemTitlesLocale {
    projectGroup: string,
    skills: string,
    specialMarks: string,
}


export function ProjectGroup({onFinish, data, edit, loading, employee}: IProjectGroupProps) {

    const [itemsArray, setItemsArray] = useState<IProjectGroup[] | undefined>(data);
    const [hideTag, setHideTag] = useState(true);

    useEffect(() => {
        
        if (data && data.length) {
            setItemsArray(data);
        }
        else {
            setItemsArray(getBasicItemsArray(ListItemType.ProjectGroup));
        }
    }, [data]);

    useEffect(() => { 
        return () => setHideTag(true);
    }, [data]);

    const handleShowMore = () => {
        setHideTag(false);
    }

    const getListCollapseItems = () => {
        const items: CollapseProps['items']  = [
            {
                label: (
                    <Space className="tagCollapse__space">
                        <p className="tagCollapse__title">{getItemLabel(ListItemType.ProjectGroup, listItemTitlesLangRu)}</p>
                    </Space>
                ),
                children: (
                    <div className={classNames("tagCollapse__wrap",  "tagCollapse__bg-color")}>
                        {loading &&
                        <Skeleton 
                            active={true}
                            className="employee-skeleton"
                        />}
                        {!loading && itemsArray &&
                        <>
                            {itemsArray.map((item, index) => {

                                if (index > 3) {
                                    
                                    return ( 
                                        <TagItem 
                                            item = {item}
                                            key={item.id} 
                                            listItemType={ListItemType.ProjectGroup} 
                                            hide={hideTag}
                                            elementsAmount={itemsArray.length}
                                        />
                                    );
                                }
        
                                return (
                                    <TagItem 
                                        item = {item}
                                        key={item.id} 
                                        listItemType={ListItemType.ProjectGroup}
                                        hide={false}
                                        elementsAmount={itemsArray.length}
                                    />
                                );
                            })}
                            {itemsArray.length > 4 &&
                            <Button
                                onClick={handleShowMore}
                                className={hideTag ? "show-more" : "hide"}
                                type="text"
                            >
                                Показать все
                            </Button>}
                        </>}
                    </div>
                )
            },
        ];
    
        return items;
    }

    return (
        <ListItemCollapse 
            data={data} 
            edit={edit}
            listItemType={ListItemType.ProjectGroup}
            employee={employee}
            onFinish={onFinish}
            loading={loading}
            items={getListCollapseItems()}
        />
    );
}