import { ScheduleBoardLocale } from "..";
import { PickerLocale } from "antd/lib/date-picker/generatePicker";
import ru from 'antd/lib/locale/ru_RU'

export const scheduleBoardLangRu: ScheduleBoardLocale = {
    filter: 'Фильтр',
    update: 'Обновить',
    exportTooltip: 'Экспортировать',
    datePickerLocale: ru.DatePicker as PickerLocale,
    goToMonthlyChart: "Перейти к месячному графику",
    goToIntervalEditor: "Перейти к редактору",
    publish: "Опубликовать",
    reset: "Сбросить",
    reference: "Справка"
}