import react, { ReactNode, useState, useMemo, useEffect, useRef, useCallback } from 'react';
import { Checkbox, message, Select, Skeleton, Space } from "antd";
import DraggableModal, { IDraggableModalProps } from "../DraggableModal";
import { IdType, IActivityBase, ActivityPointType, ActivityType } from '../../Entities/Database';
import {  dictionaryToOptions } from '../shared/helpers';
import { ILoadListState } from '../../Reducer/LoadListReducer';
import { searchInValue } from '../../Reducer/SearchFilterReducer';
import TimePicker from '../shared/TimePicker';
import moment, { Moment } from 'moment';
import { PickerLocale } from 'antd/lib/date-picker/generatePicker';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';

import './edit-event-modal.css';

export interface IFields {
    typeId?: boolean,
    duration?: boolean,
    pointTypeId?: boolean,
}

export interface IValues {
    typeId?: ActivityType,
    duration?: number,
    pointTypeId?: ActivityPointType,
}

export interface IFieldsSettings {
    durationStep?: number,
    minDuration?: number,
    maxDuration?: number,
}

export interface IEditEventBag<TBase extends IActivityBase> {
    fields: IFields,
    fieldsSettings: IFieldsSettings | undefined,
    base: TBase,
    values: IValues,
}

export interface IEditEventModalLocale {
    errorNotSelectType: string,
    duration: string,
    sideJob: string,
    timePicker: PickerLocale
}

export interface IEditEventModalProps<T extends IActivityBase> {
    locale?: IEditEventModalLocale,
    roleId?: IdType,
    types: ILoadListState<T>,
    size?: 'small' | 'middle' | 'large',
    open?: boolean,
    title?: ReactNode,
    okText?: ReactNode,
    cancelText?: ReactNode,
    width?: string | number | undefined,
    height?: string | number | undefined,
    fields?: IFields,
    fieldsSettings?: IFieldsSettings,
    values?: IValues,
    onCancel?: IDraggableModalProps['onCancel'],
    onOk?: (bag: IEditEventBag<T>, event: react.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export function EditEventModal<T extends IActivityBase>({
    locale,
    roleId,
    open,
    types,
    size,
    title,
    okText,
    cancelText,
    width,
    height,
    fields: externalFields,
    fieldsSettings,
    values,
    onCancel,
    onOk
}: IEditEventModalProps<T>) {

    const fields: typeof externalFields = {
        typeId: true,
        pointTypeId: true,
        duration: true,
        ...externalFields
    };

    const disabledOkRef = useRef(true);
    const [disabledOk, setDisabledOk] = useState(true);
    const [internalValues, setInternalValues] = useState(values || {});

    useEffect(() => {
        setInternalValues(values || {});
    }, [values]);

    useEffect(() => {

        if(open && disabledOk) {
            disabledOkRef.current = false;
            setDisabledOk(false);
        }

    }, [open]);

    const options = useMemo(() => {

        if(!types.load || roleId === undefined) {
            return [];
        }

        return dictionaryToOptions(types, "typeId", "caption", "value", false, (item) => item.roleId === roleId);

    }, [roleId, types.items]);

    const handleOk: IDraggableModalProps['onOk'] = (event) => {

        if(disabledOkRef.current) {
            return;
        }

        if(onOk) {
            
            const selected = types.items.filter(item => item.typeId === internalValues?.typeId);
            if(!selected || selected.length === 0) {
                message.error(locale ? locale.errorNotSelectType : 'Не выберан тип активности');
                return;
            }
            
            disabledOkRef.current = true;
            setDisabledOk(true);

            const bag: IEditEventBag<T> = {
                fields,
                fieldsSettings,
                base: selected[0],
                values: internalValues,
            }

            try        { onOk(bag, event); }
            catch (ex) {
                disabledOkRef.current = false;
                setDisabledOk(false);
                throw ex;
            }
        }
    }

    const handleCancel: IDraggableModalProps['onCancel'] = (event) => {
        
        if (onCancel) {
            onCancel(event);
        }
    }

    const setTypeId = (value: ActivityType) => {
        setInternalValues(prev => ({...prev, typeId: value}));
    }

    const handleChangeSideJob = (e: CheckboxChangeEvent) => {
        const checked = e.target.checked;
        const type = checked ? ActivityPointType.SideJob : ActivityPointType.None;
        setInternalValues(prev => ({...prev, pointTypeId: type}));
    }

    const handleChangeDuration = (value: Moment | null) => {
        const newDuration = value ? value.hour() * 60 + value.minute() : undefined;
        setInternalValues(prev => ({...prev, duration: newDuration}));
    }

    const disableTime = useMemo(() => (now: unknown) => {

        // TODO
        // данная функция некорректно работает с ограничениями минут

        const minDuration = fieldsSettings?.minDuration;
        const maxDuration = fieldsSettings?.maxDuration;

        const minHour = minDuration !== undefined ? Math.floor(minDuration / 60) : 0;
        const maxHour = maxDuration !== undefined ? Math.floor(maxDuration / 60) : 24;

        const minMinute = minDuration !== undefined ? minDuration % 60 : 59;
        const maxMinute = maxDuration !== undefined ? maxDuration % 60 : 59;

        const disabledHours: number[] = [];
        const disabledAllMinutes = [...Array(60).keys()].map(x => x++);
        const enableZeroMinute = [...disabledAllMinutes].slice(1);

        for(let start = 0; start < minHour; start++)      disabledHours.push(start);
        for(let start = maxHour + 1; start < 25; start++) disabledHours.push(start);

        return {

            disabledHours: () => disabledHours,
            disabledMinutes: (selectedHour: number) => {

                if(selectedHour < minHour || selectedHour > maxHour) {
                    return disabledAllMinutes;
                }

                if(selectedHour === maxHour && maxMinute === 0) {
                    return enableZeroMinute;
                }

                return [];
            },
            disabledSeconds: (selectedHour: number, selectedMinute: number) => []
        }

    }, [fieldsSettings?.minDuration, fieldsSettings?.maxDuration]);

    return (
        <DraggableModal
            title={title ?? 'Выберите тип активности'}
            open={open}
            okText={okText}
            cancelText={cancelText}
            onOk={handleOk}
            onCancel={handleCancel}
            width={width ?? 350}
            okButtonProps={{loading: !types.load || disabledOk}}
        >
            {fields.typeId !== false && (
            types.load
            ? <Select
                    style={{width: '100%'}}
                    size={size}
                    value={internalValues?.typeId}
                    onChange={setTypeId}
                    showSearch
                    filterOption={(inputValue, option) => searchInValue(option?.children, inputValue)}
              >
                {options}
              </Select>
            : <Skeleton.Input active={types.loading} size={size == "middle" ? "default" : size} block />
            )}
            {fields.duration !== false &&
            <Space className="edit-event-filed">
                <p className="edit-event-filed-title">{locale ? locale.duration : 'Длительность'}:</p>
                <TimePicker
                    size="small"
                    locale={locale?.timePicker}
                    format="HH:mm"
                    showNow={false}
                    value={moment().startOf('day').add(internalValues?.duration, 'minute')}
                    onChange={handleChangeDuration}
                    minuteStep={(fieldsSettings ? fieldsSettings.durationStep : 1) as any}
                    disabledTime={disableTime}
                    needConfirm={false}
                />
            </Space>}
            {fields.pointTypeId !== false &&
            <Space className="edit-event-filed">
                <p className="edit-event-filed-title">{locale ? locale.sideJob : 'Подработка'}:</p>
                <Checkbox
                    checked={internalValues?.pointTypeId === ActivityPointType.SideJob}
                    onChange={handleChangeSideJob}
                />
            </Space>}
        </DraggableModal>
    );
}