import { Skeleton, Space, Spin, Tag } from "antd";
import { ITagItem } from "../../Entities/EmployeeCard";
import { ILoadListState } from "../../Reducer/LoadListReducer";

export interface ISkillListProps {
    itemsState: ILoadListState<ITagItem>,
}

export function SkillList({
    itemsState
}: ISkillListProps) {

    if (itemsState.load && itemsState.items.length === 0) {

        return (
            <Space size={[0, 8]} wrap>
                <Tag title={'N/A'}>
                    N/A
                </Tag>
            </Space>
        )
    }

    if (!itemsState.load) {

        return (
            <Skeleton active={itemsState.loading} />
        )
    }

    return (
        <Space
            size={[0, 8]}
            wrap
            style={{
                width: '100%',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}
        >
            {itemsState.items.map(x => x.isActive && (
                <Tag key={x.id} title={x.content}>
                    {x.content}
                </Tag>
            ))}
        </Space>
    )
}