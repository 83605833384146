import { TBreak, TOutputBreak, IScheduleWorkTime } from "../../../Entities/Database";
import { INewBreak, Paid } from "../BreakModalEditor";
import { IWorkTimeId } from "../ScheduleTemplateWorkTimeList/helpers";
import { isLinearBreak } from "../../../Entities/Database";

let breakIdCounter = 0;

export function getBreakId() {
    return `ap_break_${breakIdCounter++}`;
}

export function updateBreak(newBreak: INewBreak, breakItem: TBreak, workTime: IWorkTimeId): TBreak {

    return {
        ...breakItem,
        dayId: workTime.dayId,
        segmentId: workTime.segmentId,
        durn: newBreak.duration,
        type: newBreak.type,
        isPayed: newBreak.paid === Paid.Paid,
    }
}

export function getNewBreak(newBreak: INewBreak, workTime: IWorkTimeId, currentBreaks?: TBreak[] | undefined): TBreak {

    if (newBreak.range) {

        return {
            id: getBreakId(),
            sort: null,
            dayId: workTime.dayId,
            segmentId: workTime.segmentId,
            durn: newBreak.duration,
            isPayed: newBreak.paid === Paid.Paid,
            type: newBreak.type,
            leftOffset: newBreak.range.leftOffset,
            rightOffset: newBreak.range.rightOffset,
        }
    }

    const lastSort = currentBreaks && currentBreaks.length > 0
        ? Math.max(...currentBreaks.map(x => x.sort || 0))
        : 0;
    const sort = lastSort + 1;

    return {
        id: getBreakId(),
        sort: sort,
        dayId: workTime.dayId,
        segmentId: workTime.segmentId,
        durn: newBreak.duration,
        isPayed: newBreak.paid === Paid.Paid,
        type: newBreak.type,
        leftOffset: null,
        rightOffset: null,
    }
}