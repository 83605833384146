import { Checkbox, DatePickerProps, Divider, Modal, Table, Tag, message } from "antd";
import { ListItemType } from "../../../Entities/EmployeeCard";
import { useCallback, useEffect, useRef, useState } from "react";
import { IEmployee, IItemDetailsEdit, TEnabledSpecialMarks, getSkills, getTagList, saveEmployeesSpecialMarks, saveSkillsList } from "../../../Entities/Database";
import { ColumnsType } from "antd/es/table";
import { Key } from "antd/es/table/interface";
import DraggableModal from "../../DraggableModal";
import useAuth from "../../../Hooks/useAuth";
import { assignRef } from "../../shared/helpers";
import moment, { Moment } from "moment";
import { getActiveTagIds, getChangeList, getItemDetailsModalTitle } from "./helper";

import "./style.css";

export interface IItemDetailsEditModalProps {
    isOpen: boolean,
    onFinish?: () => void,
    onClose?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
    listItemType: ListItemType,
    employeeList: IEmployee[],
};

const columnsEmployee: ColumnsType<IEmployee> = [
    {
        title: 'Сотрудник',
        width: 350,
        render: (record: IEmployee) => {
            return `${record.surname} ${record.firstName} ${record.secondName}`
        }
    },
];

const columnsItems: ColumnsType<IItemDetailsEdit> = [
    {
        title: 'Отметка',
        dataIndex: 'content',
        className: "itemList__column",
        width: 350,
        render: (value, record) => (
            <Tag 
                color={record.color}
                className="itemList__tag"
            >
                {value}
            </Tag>
        ),
    },
];

const initialItemist: IItemDetailsEdit[] = [
    {
        id: 0,
        content: "N/A",
        color: "",
        isActive: true,
    }
];

const abortByMerge = {};
const abortByCloseFilter = {};

export function ItemDetailsEditModal({isOpen, onClose, onFinish, listItemType, employeeList}: IItemDetailsEditModalProps) {
    
    const auth = useAuth();

    const loadedList = useRef<IItemDetailsEdit[] | null>();

    const [itemList, setItemList] = useState<IItemDetailsEdit[]>([]);
    const [selectedEmployees, setSelectedEmployees] = useState<Key[]>([]);
    const [employeesItems, setEmployeesItems] = useState<TEnabledSpecialMarks>([]);
    const [selectedItems, setSelectedItems] = useState<Key[]>([]);
    const [initItems, setInitItems] = useState<TEnabledSpecialMarks>([]);
    const [startDate, setStartDate] = useState<Moment>();

    const [saving, setSaving] = useState(false);
    const [loading, setLoading] = useState(true);

    const actualOnFinish = useRef(onFinish);
    assignRef(onFinish, actualOnFinish);

    const abortControllerRef = useRef<AbortController | null>();

    const abortLoading = (reason = abortByMerge) => {
    
        if (abortControllerRef.current) {
            abortControllerRef.current.abort(reason);
            abortControllerRef.current = null;
        }
    };

    async function loadItemList(signal: AbortSignal) {

        try {

            if (!employeeList.length) {
                setLoading(false);
                // setTagList(initialTagList);
                return;
            }

            setLoading(true);
          
            if (listItemType === ListItemType.SpecialMarks) {
                loadedList.current = await getTagList(auth, employeeList[0], signal);   
            }

            if (listItemType === ListItemType.Skills) {
                loadedList.current = await getSkills(auth, employeeList[0], signal);
            }

            setLoading(false);
            setItemList(loadedList.current?.length ? loadedList.current : initialItemist);
        }
        catch (ex) {

            if (ex === abortByCloseFilter ||
                ex === abortByMerge) {
                return;
            }

            console.error(ex);
            message.error('Не удалось загрузить список.');
            setLoading(false);
        }
    }

    useEffect(() => {
        
        if (isOpen) {
            abortControllerRef.current = new AbortController();
            loadItemList(abortControllerRef.current.signal); 
        }

        return () => {
            abortLoading(isOpen ? abortByMerge : abortByCloseFilter);
        }

    }, [employeeList, isOpen]);


    const handleOk = useCallback(() => {

        const changes = getChangeList(employeesItems, initItems);
        const hasChanges = changes.some(x => x.disabled.length > 0 || x.enabled.length > 0);
        
        async function save() {

            try {
                setSaving(true);
                setLoading(true);
                const abortController = new AbortController();

                if (listItemType === ListItemType.SpecialMarks) {
                    await saveEmployeesSpecialMarks(auth, changes, abortController.signal);
                }

                if (listItemType === ListItemType.Skills) {
                    await saveSkillsList(auth, changes, abortController.signal);
                }
                
                if (actualOnFinish.current) {
                    actualOnFinish.current();
                }
                setLoading(false);
            }
            finally {
                setSaving(false);
                setLoading(false);
            }
        }

        if (hasChanges) {
            save();
        } else {
            confirmModal.current?.destroy();
            confirmModal.current = Modal.confirm({
                zIndex: 3000,
                title:  (
                    <div>Нет новых изменений.
                        <br/> 
                        <span style={{fontWeight: 400, paddingTop: 10, display: "block"}}>
                            Выйти без сохранения?
                        </span>
                    </div>
                ),
                onOk: onClose
            });
        }

    }, [auth.data?.staticId, initItems, employeesItems]);

    const confirmModal = useRef<ReturnType<typeof Modal.confirm>>();

    const handleCancel = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

        if (!saving && onClose) {

            const changes = getChangeList(employeesItems, initItems);
            const hasChanges = changes.some(x => x.disabled.length > 0 || x.enabled.length > 0);

            if (hasChanges) {
                confirmModal.current?.destroy();
                confirmModal.current = Modal.confirm({
                    zIndex: 3000,
                    title:  (
                        <div>Внесенные изменения не сохранятся!
                            <br/> 
                            <span style={{fontWeight: 400, paddingTop: 10, display: "block"}}>
                                Выйти без сохранения?
                            </span>
                        </div>
                    ),
                    onOk: onClose
                });

            } else {
                onClose(e);
            }
        }
    }

    const handleChangeEmployeesItems = (selectedKeys: Key[]) => {
        setEmployeesItems(employeesItems => {
            const newState = [...employeesItems];
            for (const selectedEmployee of selectedEmployees) {
                let index = newState.findIndex(x => x.PID === selectedEmployee);
                if (index === -1) {
                    index = newState.push({
                        PID: selectedEmployee as string,
                        enabled: []
                    }) - 1;
                }
                newState[index].enabled = [...selectedKeys];
            }
            return newState;
        });
    };
    
    const handleDatePickerChange: DatePickerProps['onChange'] = (date, dateString) => {
        setStartDate(moment(dateString));
    };

    useEffect(() => {

        setInitItems([]);
        setEmployeesItems([]);

        if (itemList) {
            setSelectedEmployees(employeeList.length === 1 ? [employeeList[0].PID] : employeeList.map(x => x.PID));

            setInitItems([{
                PID: employeeList[0].PID,
                enabled: itemList.filter(x => x.isActive).map(x => x.id)
            }]);

            const activeTags = getActiveTagIds(itemList);
            setSelectedItems(activeTags);
            // setSpecialMarks(data.map(x => ({
            //     id: x.id,
            //     content: x.content,
            //     color: x.color ? x.color : defaultColor,
            // })));

           
        } else {
            setItemList([]);
            setSelectedItems([]);
        }

    }, [itemList, isOpen]);

    /*
    useEffect(() => {

        const abortControlelr = new AbortController();
        const marks = await getSpecialMarks();
        setSpecialMarks(marks);
        return () => abortControlelr.abort();

    }, [isOpen]);
    */

    return (
        <DraggableModal 
            title={getItemDetailsModalTitle(listItemType, handleDatePickerChange)}
            open={isOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            cancelButtonProps={{disabled: saving}}
            okButtonProps={{loading: saving, disabled: saving || loading}}
            okText="Сохранить"
            cancelText="Закрыть"
            width={766}
            destroyOnClose
        >
            <Divider />
            
            <div className="itemList__table-wrap">
               
                <Table<IEmployee>
                    size="small"
                    loading={saving}
                    pagination={false}
                    rowKey={(record) => record.PID}
                    rowSelection={{
                        selectedRowKeys: selectedEmployees,
                        onChange: setSelectedEmployees,
                        getCheckboxProps: () => ({
                            disabled: selectedEmployees.length === 1
                        })
                       
                    }}
                    columns={columnsEmployee}
                    dataSource={employeeList}
                /> 
                
                <Table<IItemDetailsEdit>
                    size="small"
                    className="itemList__tag-table"
                    loading={saving || loading}
                    pagination={false}
                    rowKey={(x) => x.id}
                    rowSelection={{
                        selectedRowKeys: selectedItems,
                        onChange: (keys) => {
                            setSelectedItems(keys);
                            handleChangeEmployeesItems(keys);
                        },
                    }}
                    columns={columnsItems}
                    dataSource={itemList} 
                />
            </div>
        </DraggableModal>
    )
}