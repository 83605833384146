import { Drawer, DrawerProps, Tabs } from "antd";
import { isMoment } from "moment";
import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";
import { IEmployeeScheduleDay, IHasEmployeeDayKey, IScheduleBoardEmployee, IScheduleBoardFilter } from "../../Entities/Database";
import { useBuffer } from "../../Hooks/useBuffer";
import { ILoadListState } from "../../Reducer/LoadListReducer";
import { HeaderColumnType } from "../ScheduleBoard/CellContextMenu/helpers";
import { SizeType } from "../shared/const";
import { Comments } from "./comments";
import { History } from "./history";

import './style.css';
import { WorktimeList } from "./worktime";
import { Tickets } from "./tickets";

export interface ScheduleDetailDrawerLocale {
    lang: string,
    title: string,
    close: string,
    comments: string,
    history: string,
    worktime: string,
    tickets: string,
}

const localeRu: ScheduleDetailDrawerLocale = {
    lang: 'ru',
    title: 'День подробно',
    close: 'Закрыть',
    comments: 'Комментарии',
    history: 'История',
    worktime: 'WT',
    tickets: 'Тикеты'
}

export type TabKeys = 'comments' | 'history' | 'worktime' | 'tickets';

export const EDDateilInfo = ({
    item,
    filter,
    locale,
    onChange
}: any) => {

    const [activeTab, setActiveTab] = useState<TabKeys>('comments');
    const handleTabChange = useCallback((key: string) => {
        setActiveTab(key as unknown as TabKeys);
    }, [setActiveTab]);

    return (
        <>
            <Tabs
                activeKey={activeTab}
                onChange={handleTabChange}
                centered
                size="small"
                type="card"
                items={[
                    { label: <span style={{fontSize: 12}}>{locale.comments}</span>, key: 'comments' },
                    { label: <span style={{fontSize: 12}}>{locale.history}</span>, key: 'history' },
                    { label: <span style={{fontSize: 12}}>{locale.worktime}</span>, key: 'worktime' },
                    { label: <span style={{fontSize: 12}}>{locale.tickets}</span>, key: 'tickets' },
                ]}
            />
            <div className="tab-content">
                {activeTab === 'comments' &&
                        <Comments
                    item={item}
                    onChange={onChange}
                />}
                {activeTab === 'history' &&
                <History item={item} />}
                {activeTab === 'worktime' &&
                <WorktimeList
                    item={item}
                    filter={filter}
                />}
                {activeTab === 'tickets' &&
                <Tickets
                    item={item}
                    filter={filter}
                    lang={locale.lang}
                    onChange={onChange}
                />}
            </div>
        </>
    );
}

export interface IEmployeesDayScheduleCellDetailInfoProps {
    drawerFocusRef?: React.RefObject<HTMLDivElement>,
    loading?: boolean,
    open?: boolean,
    size?: SizeType,
    locale?: ScheduleDetailDrawerLocale,
    employees: ILoadListState<IScheduleBoardEmployee>,
    item?: IHasEmployeeDayKey | HeaderColumnType,
    filter: IScheduleBoardFilter,
    onChange?: (item: IHasEmployeeDayKey | HeaderColumnType) => void,
    onClose?: DrawerProps['onClose'],
}

export function EmployeesDayScheduleCellDetailInfo({
    drawerFocusRef,
    loading,
    open,
    size,
    locale = localeRu,
    item,
    filter,
    onChange,
    onClose
}: IEmployeesDayScheduleCellDetailInfoProps) {

    const tabContentRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(drawerFocusRef, () => tabContentRef.current
                                            ?.parentElement
                                            ?.parentElement
                                            ?.parentElement
                                            ?.parentElement
                                            ?.parentElement as unknown as HTMLDivElement);
    
    const content = useBuffer<React.ReactNode, [typeof item]>(
        (prevValue, [prevItem]) => {

            if (!item || isMoment(item)) {
                return undefined;
            }

            if(!isMoment(prevItem)
            && prevItem?.PID === item.PID
            && prevItem?.date.isSame(item.date)) {
                return prevValue;
            }

            return (
                <EDDateilInfo
                    locale={locale}
                    item={item}
                    filter={filter}
                    onChange={onChange}
                />
            );
        },
        [item],
        open === true
    );

    return (
        <Drawer
            destroyOnClose
            autoFocus
            className="detail-info-drawer"
            mask={false}
            open={open}
            title={locale.title}
            placement='right'
            onClose={onClose}
            getContainer={false}
            styles={{body: {
                padding: 0,
                paddingTop: 10
            }}}
        >
            <div ref={tabContentRef}></div>
            {content}
        </Drawer>
    )
}

export default EmployeesDayScheduleCellDetailInfo;