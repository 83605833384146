import { useEffect, useMemo } from "react";
import { IScheduleBoardFilter, getFieldListForFilter, getForcastObjectListForFilter, getProjectGroupListForFilter, getSkillListForFilter, getTagListForFilter } from "../../Entities/Database";
import useAuth from "../../Hooks/useAuth";
import { getAbortCallback, useListLoader } from "../../Hooks/useListLoader";
import { dictionaryToOptions } from "../shared/helpers";
import { Form, Select, SelectProps, Skeleton, Switch } from "antd";
import { scheduleBoardFilterLangRu } from "../ScheduleBoardFilter/locale/ru";
import { ScheduleBoardFilterFormValues, ScheduleBoardFilterLocale } from "../ScheduleBoardFilter";
import { nameof } from "../../System/Utils";
import { searchInValue } from "../../Reducer/SearchFilterReducer";
import { IValues } from "./index.db";

const multipleSelectProps: SelectProps = {
    mode: 'multiple',
    style: { width: '100%' },
    maxTagCount: 'responsive',
    filterOption: (inputValue, option) => {
        return searchInValue(option?.children, inputValue)
    }
}

export interface IFilterProps {
    locale?: ScheduleBoardFilterLocale,
    loading?: boolean,
    filter: IScheduleBoardFilter,
    onValuesChange?: () => void,
}

export default function Filter({
    locale = scheduleBoardFilterLangRu,
    loading,
    filter,
    onValuesChange,
}: IFilterProps) {

    const auth = useAuth();

    const [projectGroupList, startUpdateProjectGroupList, updateProjectGroupList] = useListLoader(
        (signal: AbortSignal) => getProjectGroupListForFilter(auth, signal),
        [auth.data?.staticId]
    );

    const [fieldList, startUpdateFieldList, updateFieldList] = useListLoader(
        (signal: AbortSignal) => getFieldListForFilter(auth, signal),
        [auth.data?.staticId]
    );

    const [tagList, startUpdateTagList, updateTagList] = useListLoader(
        (signal: AbortSignal) => getTagListForFilter(auth, filter.projectGroupIds, signal),
        [auth.data?.staticId, filter.projectGroupIds]
    );

    const [skillList, startUpdateSkillList, updateSkillList] = useListLoader(
        (signal: AbortSignal) => getSkillListForFilter(auth, filter.projectGroupIds, signal),
        [auth.data?.staticId, filter.projectGroupIds]
    );

    const projectGroupListOptions   = useMemo(() => dictionaryToOptions(projectGroupList, 'id', 'title', 'value', true), [projectGroupList.items]);
    const fieldListOptions          = useMemo(() => dictionaryToOptions(fieldList, 'id', 'title'), [fieldList.items]);
    const tagListOptions            = useMemo(() => dictionaryToOptions(tagList, 'id', 'title'), [tagList.items]);
    const skillListOptions          = useMemo(() => dictionaryToOptions(skillList, 'id', 'title'), [skillList.items]);

    useEffect(() => {

        return getAbortCallback(
            startUpdateProjectGroupList(),
            startUpdateTagList(),
            startUpdateFieldList(),
            startUpdateSkillList(),
        );

    }, [filter.projectGroupIds]);

    const dictLoading = loading === true
                || projectGroupList.loading
                || fieldList.loading
                || tagList.loading
                || skillList.loading;

    const dictLoad = loading !== true
                && projectGroupList.load
                && fieldList.load
                && tagList.load
                && skillList.load;

    const inputPreloader = <Skeleton.Input active={dictLoading} size={'small'} block />;

    return (
        <>
            <Form.Item
                label={`${locale.projectGroup}:`}
                name={nameof<IValues>('projectGroupIds')}
                labelCol={{span: 8}}
            >
                {
                    fieldList.load ?
                    <Select
                        {...multipleSelectProps}
                    >
                        {projectGroupListOptions}
                    </Select> :
                    inputPreloader
                }
            </Form.Item>
            <Form.Item
                label={`${locale.field}:`}
                name={nameof<IValues>('fieldIds')}
                labelCol={{span: 8}}
            >
                {
                    fieldList.load ?
                    <Select
                        {...multipleSelectProps}
                    >
                        {fieldListOptions}
                    </Select> :
                    inputPreloader
                }
            </Form.Item>
            <Form.Item
                label={`${locale.tags}:`}
                name={nameof<IValues>('tagIds')}
                labelCol={{span: 8}}
            >
                {
                    tagList.load ?
                    <Select
                        {...multipleSelectProps}
                    >
                        {tagListOptions}
                    </Select> :
                    inputPreloader
                }
            </Form.Item>
            <Form.Item
                label={`${locale.skills}:`}
                name={nameof<IValues>('skillIds')}
                labelCol={{span: 8}}
            >
                {
                    skillList.load ?
                    <Select
                        {...multipleSelectProps}

                    >
                        {skillListOptions}
                    </Select> :
                    inputPreloader
                }
            </Form.Item>
            <Form.Item
                valuePropName="checked"
                label={locale.crossEmployee}
                name={nameof<IValues>('crossEmploee')}
            >
                {
                    dictLoad
                    ? <Switch style={{display: 'flex'}} />
                    : inputPreloader
                }
            </Form.Item>
        </>
    )
}