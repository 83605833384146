import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { authUser } from "../Server/DbProvider";
import { AuthorizationException } from "../System/AuthorizationException";
import { IAuthData } from "../Server/IAuthData";
import { IHasAuthToken } from "../Server/IHasAuthToken";
import { EqualsOrdinal, isEmptyOrNullOrUndefined, isNullOrUndefined } from "../System/Utils";
import { contextNotInitFn, contextNotInitFnAsync } from "./helpers";
import IAuthState, { IAuthDataState } from "../Entities/IAuthProvider";
import useAuth from "./useAuth";

export interface IAuthLKData {
    pageId?: number,
    moduleId?: number,
    PID?: string,
    authToken?: string
}

export interface IAuthLKProvider extends Omit<IAuthState<IAuthData>, "login"> {
    lkData?: IAuthLKData,
    login: (authLKData?: IAuthLKData, abortSignal?: AbortSignal) => Promise<IAuthData>
    loginByToken: (data: IHasAuthToken, abortSignal?: AbortSignal) => Promise<IAuthData>
}

const authLKContext = createContext<IAuthLKProvider>({
    isLoging: false,
    isLogout: true,
    loging: contextNotInitFnAsync,
    throwIfNotLogedInAndLogout: contextNotInitFn,
    logedIn: contextNotInitFn,
    logout: contextNotInitFnAsync,
    abort: contextNotInitFnAsync,
    login: contextNotInitFnAsync,
    loginByToken: contextNotInitFnAsync,
});

export const urlPIDParamName = "PID";
export const urlSIDParamName = "SID";
export const urlPageIdParamName = "Page_id";
export const urlModuleIdParamName = "module_id";
export const urlAuthTokenParamName = "authToken";

export function extractAuthData(queryString?: string): IAuthLKData {

    queryString ??= window.location.search;

    const urlParams = new URLSearchParams(queryString);
    const result: IAuthLKData = {};
    
    for(let [name, value] of urlParams) {

        if(EqualsOrdinal(urlPIDParamName, name)) {
            result.PID = value;
            continue;
        }

        if(EqualsOrdinal(urlPageIdParamName, name)) {
            result.pageId = parseInt(value);
            continue;
        }

        if(EqualsOrdinal(urlModuleIdParamName, name)) {
            result.moduleId = parseInt(value);
            continue;
        }

        if(EqualsOrdinal(urlAuthTokenParamName, name)) {
            result.authToken = value;
            continue;
        }
    }

    return result;
}

const logout = async (state: IAuthDataState<IAuthData>, signal?: AbortSignal) => state.data;
const logedIn = async (state: IAuthDataState<IAuthData>, signal?: AbortSignal) => state.logout !== true && !state.loging && !!state.data && +state.data.expires > Date.now();

const useProvideAuth = function (): IAuthLKProvider {

    const authProvider = useAuth();
    const [lkData, setLKData] = useState<IAuthLKData>();
    
    const loginByToken = (data: IHasAuthToken, abortSignal?: AbortSignal) => {

        return authProvider.login({
                getLoginAction: () => (state, sysAbortSignal) => authUser(data, sysAbortSignal),
                getLogoutAction: () => logout,
                getLogedInAction: () => logedIn,
            },
            abortSignal
        );
    }

    const login = async (data?: IAuthLKData, abortSignal?: AbortSignal) => {
        
        if(isNullOrUndefined(data) || isEmptyOrNullOrUndefined(data.authToken)) {
            throw new AuthorizationException("Не удалось извлечь данные авторизации из личного кабинета");
        }

        const authData = await loginByToken({
            authToken: data.authToken
        }, abortSignal);

        setLKData(data);
        return authData;
    }

    return useMemo(() => ({
        ...authProvider,
        lkData,
        login,
        loginByToken
    }),
    [
        authProvider,
        lkData,
        login,
        loginByToken
    ]);
}

export function AuthLKProvider({ children }: any) {
    const auth = useProvideAuth();
    return (
        <authLKContext.Provider value={auth}>
            {children}
        </authLKContext.Provider>
    );
}

export const useLKAuth = () => {
    return useContext(authLKContext);
};