import moment from "moment";
import delay from "../../System/Delay";
import { message } from "antd";
import { IdType, IScheduleBoardEmployee, IScheduleBoardFilter, saveEmployeeActivitys, ScheduleActionEditorType } from "../../Entities/Database";
import { IScheduleEmployeeActivity } from "../../Entities/IScheduleEmployeeActivity";
import { OperationAbortedByMergeException } from "../../System/OperationAbortedByMergeException";
import { IAuthState } from "../../Entities/IAuthProvider";
import { IAuthData } from "../../Server/IAuthData";
import { doOrAbort, isString } from "../../System/Utils";
import { ILoadListState } from "../../Reducer/LoadListReducer";
import equal from "fast-deep-equal";
import { InvalidDataException } from "../../System/InvalidDataException";
import { BackendResponseException } from "../../System/BackendResponseException";

const saveAbortControllers: Record<string, AbortController | null> = {};

const saveResourceEvents = async (
    auth: IAuthState<IAuthData>,
    filter: IScheduleBoardFilter,
    employees: ILoadListState<IScheduleBoardEmployee>,
    resource: string | number | (string | number)[] | undefined,
    date: moment.Moment,
    events: IScheduleEmployeeActivity[],
    signal?: AbortSignal
) => {

    if(!isString(resource)) {
        throw new InvalidDataException("Not supported resource type");
    }

    const filteredEmployees = employees.items.filter(employee => employee.PID === resource);
    if(!filteredEmployees || filteredEmployees.length !== 1) {
        throw new InvalidDataException("Failed detect employee");
    }

    const employee = filteredEmployees[0];

    await delay(3000, { signal });
    await saveEmployeeActivitys(
        auth,
        filter,
        ScheduleActionEditorType.IntervalEditor,
        employee,
        date,
        events,
        signal
    );
}

export const safeSaveChanges = async (
    auth: IAuthState<IAuthData>,
    filter: IScheduleBoardFilter,
    employees: ILoadListState<IScheduleBoardEmployee>,
    resource: IdType | IdType[] | undefined,
    date: moment.Moment,
    oldEvents: IScheduleEmployeeActivity[],
    newEvents: IScheduleEmployeeActivity[],
    signal?: AbortSignal
) => {

    const key = JSON.stringify(resource);

    try {

        const oldResourceEvents = oldEvents.filter(event => event.resource === resource);
        const newResourceEvents = newEvents.filter(event => event.resource === resource);

        if (!equal(oldResourceEvents, newResourceEvents)) {

            saveAbortControllers[key]?.abort(new OperationAbortedByMergeException());

            const abortController = new AbortController();
            const internalSignal = abortController.signal;
    
            saveAbortControllers[key] = abortController;

            await doOrAbort(
                signal => saveResourceEvents(auth, filter, employees, resource, date, newResourceEvents, signal),
                internalSignal,
                signal,
            );

            delete saveAbortControllers[key];
            return true;
        }

        return -1;
    }
    catch (ex) {

        if (ex instanceof OperationAbortedByMergeException) {
            return -2;
        }

        message.error(ex instanceof BackendResponseException ? ex.message : 'Не удалось сохранить данные');
        console.error(ex);

        delete saveAbortControllers[key];
        return false;
    }
}

export function hasActiveSaveProcess() {

    for (let [key, value] of Object.entries(saveAbortControllers)) {

        if (value) {
            
            return true;
        }
    }

    return false;
}