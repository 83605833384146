import { IConfirmPopupLocale } from "..";

export const ru: IConfirmPopupLocale = {
    title: "Подтвердите действие",
    commentValidationText: "Заполните комментарий",
    label: "Комментарий",
    okText: "Да",
    cancelText: "Нет",
}

export default ru;