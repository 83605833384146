import { ButtonProps, Form, Popconfirm, PopconfirmProps } from "antd";
import TextArea from "antd/es/input/TextArea";
import { CSSProperties, PropsWithChildren, useEffect, useRef, useState } from "react";
import { classNames, nameof } from "../../System/Utils";
import ru from "./locale/ru";
import "./style.css";

export interface IAcceptForm {
    comment: string
}

export interface IConfirmPopupLocale {
    title: string,
    commentValidationText: string,
    label: string,
    okText: string,
    cancelText: string,
}

export interface IConfirmPromise {
    e: any,
    formValid: boolean | undefined,
    processing: boolean,
    resolve: () => void,
    reject:  () => void,
}

export interface IConfirmPopup<TResult> {
    canBeOpen?: boolean,
    locale?: IConfirmPopupLocale,
    onConfirm?: (data: TResult, e?: React.MouseEvent) => Promise<unknown> | void,
    onCancel?: PopconfirmProps['onCancel'],
    style?: CSSProperties,
    icon?: JSX.Element,
    className?: string,
}

export function ConfirmPopup<TResult extends IAcceptForm>({
    canBeOpen,
    children,
    locale = ru,
    onConfirm,
    onCancel,
    style,
    className,
    icon,
}: PropsWithChildren<IConfirmPopup<TResult>>) {

    const awaitHandleOpenChange = useRef<boolean>(false);
    const promiseStateRef = useRef<Partial<IConfirmPromise>>({});

    const [internalOpen, setInternalOpen] = useState(false);
    const [form] = Form.useForm<TResult>();
    const [processing, setProcessing] = useState(false);
    const [okButtonProps, setOkButtonProps] = useState<ButtonProps>({});
    const [cancelButtonProps, setCancelButtonProps] = useState<ButtonProps>({});

    const handleFormFinish = async (data: TResult) => {

        try {

            awaitHandleOpenChange.current = false;

            const event = promiseStateRef.current.e;

            setProcessing(true);
            setOkButtonProps({ disabled: true, loading: true, });
            setCancelButtonProps({ disabled: true });

            if (onConfirm) {
                const tmp = onConfirm(data, event);
                if (tmp instanceof Promise) {
                    await tmp;
                }
            }
        }
        finally {

            setProcessing(false);
            setOkButtonProps({ disabled: false, loading: false, });
            setCancelButtonProps({ disabled: false });

            form.resetFields();

            if (promiseStateRef.current &&
                promiseStateRef.current.resolve) {
                promiseStateRef.current.resolve();
            }
        }
    }

    const handleFormFinishFailed = (err: any) => {

        if (promiseStateRef.current &&
            promiseStateRef.current.resolve) {
            promiseStateRef.current.resolve();
        }
    }

    const handleConfirm = (e?: React.MouseEvent<HTMLElement>) => {
        return new Promise<void>((resolve, reject) => {
            awaitHandleOpenChange.current = true;
            form.submit();
            promiseStateRef.current = {
                e,
                processing: false,
                resolve,
                reject,
            };
        });
    }

    const handleCancel = (e?: React.MouseEvent<HTMLElement>) => {

        setInternalOpen(false);

        if (onCancel) {
            onCancel(e);
        }
    }

    const handleOpenChange = (maybeNewOpen: boolean, e?: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLDivElement>) => {

        if (processing) {
            setInternalOpen(true);
            return;
        }

        if (awaitHandleOpenChange.current) {
            awaitHandleOpenChange.current = false;
            setInternalOpen(true);
            return;
        }

        setInternalOpen(maybeNewOpen);
    }

    useEffect(() => {
        
        if (!canBeOpen && !processing) {
            setInternalOpen(false);
        }

    }, [canBeOpen, processing]);

    return (
        <Popconfirm
            style={style}
            className={classNames("ap-popconfirm", className)}
            destroyTooltipOnHide
            title={<div className="ap-popconfirm__title">{locale.title}</div>}
            open={internalOpen}
            description={
                <Form<TResult>
                    className="ap-popconfirm__form"
                    form={form}
                    disabled={processing}
                    onFinish={handleFormFinish}
                    onFinishFailed={handleFormFinishFailed}
                >
                    <Form.Item
                        rules={[{ required: true, message: locale.commentValidationText }]}
                        name={nameof<IAcceptForm>('comment')}
                    >
                        <TextArea
                            placeholder={locale.label}
                            size="small"
                        />
                    </Form.Item>
                </Form>
            }
            onOpenChange={handleOpenChange}
            okText={locale.okText}
            okButtonProps={okButtonProps}
            cancelText={locale.cancelText}
            cancelButtonProps={cancelButtonProps}
            onConfirm={handleConfirm}
            onCancel={handleCancel}
        >
           {children}
        </Popconfirm>
    );
}