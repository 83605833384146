import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import useEventBus from "./useEventBus";
import useAuth from "./useAuth";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getAccessTokenStr } from "../Server/DbProvider";
import { ObjectDisposedException } from "../System/ObjectDisposedException";
import delay from "../System/Delay";
import useActual from "./useActual";

const abortByNextConnection = {};
const abortByDisconnect = {};

export interface ISignalRHubOptions<TStore> {
    url: string,
    sessionId?: string,
    reconnectTimeout?: number,
}

export interface IHubProvider<TEventStore extends Record<keyof TEventStore, (...args: any) => any>> {
    sessionId: string,
    connect(): Promise<void>,
    disconnect(): Promise<void>,
    abort(error?: Error): Promise<void>,
    on<TEventName extends keyof TEventStore>(eventName: TEventName, callback: TEventStore[TEventName]): void,
    off<TEventName extends keyof TEventStore>(eventName: TEventName, callback: TEventStore[TEventName]): void,
    fire<TKey extends keyof TEventStore>(eventName: TKey, ...args: Parameters<TEventStore[TKey]>): void,
}

export interface ISignalRHubProvider<
    TEventStore extends Record<keyof TEventStore, (...args: any) => any>,
    TBackendEventStore extends Record<keyof TBackendEventStore, (...args: any) => Promise<any>>,
> extends IHubProvider<TEventStore> {
    send<TKey extends keyof TBackendEventStore>(methodName: TKey, ...args: Parameters<TBackendEventStore[TKey]>): ReturnType<TBackendEventStore[TKey]>,
    invoke<TKey extends keyof TBackendEventStore>(methodName: TKey, ...args: Parameters<TBackendEventStore[TKey]>): ReturnType<TBackendEventStore[TKey]>,
}

export interface IDefaultEventStore {
    connectionSuccess: (hub: HubConnection) => void,
    connectionFail: (hub: HubConnection) => void,
    reconnecting: (error?: Error | undefined) => void,
    recconected: (connectionId?: string | undefined) => void,
    abort: (error?: Error | undefined) => void,
}

export function useSignalRHub
<
    TEventStore extends Record<keyof TEventStore, (...args: any[]) => any> & Partial<IDefaultEventStore>,
    TBackendEventStore extends Record<keyof TBackendEventStore, (...args: any[]) => any>,
>
(options: ISignalRHubOptions<TEventStore>): ISignalRHubProvider<TEventStore, TBackendEventStore> {

    const auth = useAuth();
    const abortControllerRef = useRef<AbortController | null>(null);
    const hubConnectionRef   = useRef<HubConnection | null>(null);
    const prevTokenRef       = useRef<string | null>(null);
    const [defSessionId]     = useState(() => crypto.randomUUID());
    const {
        url,
        sessionId = defSessionId,
        reconnectTimeout = 2000,
    } = options;

    const [internalFire, internalOff, internalOn, getHandlersCount, getHandlersName] = useEventBus<IDefaultEventStore>();

    const on = useCallback(<TEventName extends keyof TEventStore>(eventName: TEventName, callback: TEventStore[TEventName]) => {
        
        const hackEventName = eventName as keyof IDefaultEventStore;

        internalOn(hackEventName, callback);

        if (hubConnectionRef.current && getHandlersCount(hackEventName) === 1) {
            hubConnectionRef.current.on(hackEventName, (...args: any) => {
                internalFire(hackEventName, ...args);
            });
        }

    }, [internalOn, getHandlersCount, internalFire]);

    const off = useCallback(<TEventName extends keyof TEventStore>(eventName: TEventName, callback: TEventStore[TEventName]) => {

        const hackEventName = eventName as keyof IDefaultEventStore;

        internalOff(hackEventName, callback);

        if (hubConnectionRef.current && getHandlersCount(hackEventName) === 0) {
            hubConnectionRef.current.off(hackEventName);
        }

    }, [internalOff, getHandlersCount]);

    const fire = useCallback(<TKey extends keyof TEventStore>(eventName: TKey, ...args: Parameters<TEventStore[TKey]>) => {
        internalFire(eventName as any, ...args as any);
    }, [internalFire]);

    const send = useCallback(<TKey extends keyof TBackendEventStore>(methodName: TKey, ...args: Parameters<TBackendEventStore[TKey]>): ReturnType<TBackendEventStore[TKey]> => {
        return hubConnectionRef.current!.send(methodName as any, args) as any;
    }, []);

    const invoke = useCallback(<TKey extends keyof TBackendEventStore>(methodName: TKey, ...args: Parameters<TBackendEventStore[TKey]>): ReturnType<TBackendEventStore[TKey]> => {
        return hubConnectionRef.current!.invoke(methodName as any, ...args) as any;
    }, []);

    const authActualRef = useActual(auth);
    const connect = useCallback(async () => {

        while (true) {
            
            const prevAbortController = abortControllerRef.current;
            const prevHubConnection   = hubConnectionRef.current;

            const auth = authActualRef.current;
            const abortController = new AbortController();

            abortControllerRef.current = abortController;
            hubConnectionRef.current   = null;
            prevTokenRef.current       = null;

            const isLogedIn = await auth.logedIn(abortController.signal);

            if (prevAbortController && !prevAbortController.signal.aborted) {
                prevAbortController.abort(abortByNextConnection);
                prevHubConnection?.stop();
            }

            if (isLogedIn) {

                const token  = getAccessTokenStr(auth.data);
                const urlObj = new URL(url);

                urlObj.searchParams.set('session_id', sessionId);
                
                const newUrl = urlObj.toString();
                const hubConnection = new HubConnectionBuilder()
                    .withUrl(newUrl, {
                        skipNegotiation: true,
                        transport: HttpTransportType.WebSockets,
                        accessTokenFactory: () => token,
                    })
                    .withAutomaticReconnect({ nextRetryDelayInMilliseconds: (context) => reconnectTimeout, })
                    .build();
                
                hubConnectionRef.current = hubConnection;
                prevTokenRef.current     = token;

                for (let eventName of getHandlersName()) {
                    hubConnection.on(eventName, (...args: any) => {
                        internalFire(eventName, ...args);
                    });
                }

                hubConnection.onreconnecting(x => {
                    if (hubConnectionRef.current === hubConnection) {
                        internalFire('reconnecting', x);
                    }
                });

                hubConnection.onreconnected(x => {
                    if (hubConnectionRef.current === hubConnection) {
                        internalFire('recconected', x);
                    }
                });
        
                hubConnection.onclose((x) => {
                    if (hubConnectionRef.current === hubConnection) {
                        internalFire('abort', x);
                    }
                });

                try {
                    await hubConnection.start();
                    if (hubConnectionRef.current === hubConnection && !abortController.signal.aborted) {
                        internalFire('connectionSuccess', hubConnection);
                        return;
                    }

                    if (abortController.signal.aborted) {
                        hubConnection.stop();
                    }
                }
                catch (ex) {
                    if (hubConnectionRef.current === hubConnection && !abortController.signal.aborted) {
                        internalFire('connectionFail', hubConnection);
                    }
                }
            }

            await delay(reconnectTimeout, { signal: abortController.signal });
        }

    }, [url, sessionId, reconnectTimeout]);

    const abort = useCallback(async () => {

        const hub = hubConnectionRef.current;

        if (hub && hub.state == HubConnectionState.Connected) {

            return await hub.send("Abort");
        }

        throw new ObjectDisposedException("Hub disposed");

    }, []);

    const disconnect = useCallback(async (reason?: any) => {

        if (abortControllerRef.current) {
            abortControllerRef.current.abort(reason ?? abortByDisconnect);
        }
        
        if (hubConnectionRef.current) {
            await hubConnectionRef.current.stop();
        }

    }, []);

    return useMemo(() => ({
        sessionId,
        connect, disconnect, abort,
        on, off, fire,
        send, invoke,
    }),
    [
        sessionId,
        connect, disconnect, abort,
        on, off, fire,
        send, invoke
    ]);
}

export default useSignalRHub;