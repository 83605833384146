import { useCallback, useEffect, useReducer, useRef } from "react";
import { getEmployeeList, getScheduleInfoBlock, IScheduleBoardEmployee, IScheduleBoardFilter, IEmployeeScheduleDay, IDaysInfoblockItem, getRecordByType as getRecordByType, RecordType, IDayTypeItem, getDayTypeList, getScheduleItemsMarkerList, IEmployeeScheduleDayMarker } from "../../Entities/Database";
import useAuth from "../../Hooks/useAuth";
import loadListReducer, { LoadListReducerType, LoadListKind } from "../../Reducer/LoadListReducer";
import { ILoaderProps, useListLoader } from "../../Hooks/useListLoader";

export type EmployeeListReducer = LoadListReducerType<IScheduleBoardEmployee>

export type SyncData = {
    newEmployees?: IScheduleBoardEmployee[],
    newItems?: IEmployeeScheduleDay[],
    newInfo?: IDaysInfoblockItem[],
    newItemsMarkers?: IEmployeeScheduleDayMarker[],
}

export type SyncHandler = (data: SyncData) => void;

const syncLoaderProps: ILoaderProps = {
    clearList: false,
    backgroundLoading: true
};

export interface ListProviderProps {
    filter: IScheduleBoardFilter,
    recordType: RecordType
}

export function useListProvider({
    filter,
    recordType
}: ListProviderProps) {

    const didMount = useRef(false);
    const auth = useAuth();

    const [dayTypeList, reloadDayTypeList] = useListLoader(
        signal => getDayTypeList(auth, signal),
        [auth.data?.staticId]
    );

    const [employeeList, reloadEmployeeList] = useListLoader(
        signal => getEmployeeList(auth, filter, signal),
        [auth.data?.staticId, filter]
    );

    const [infoBlock, reloadInfoBlock, mergeInfoBlock, , infoBlockDispatch] = useListLoader(
        signal => getScheduleInfoBlock(auth, filter, signal),
        [auth.data?.staticId, filter]
    );

    const [scheduleItemList, reloadScheduleItemList, mergeScheduleItemList, , scheduleItemListDispatch] = useListLoader(
        signal => getRecordByType(auth, filter, recordType, signal),
        [auth.data?.staticId, recordType, filter]
    );

    const [scheduleItemsMarkers, reloadScheduleItemsMarkers, mergeScheduleItemsMarkers, , scheduleItemsMarkersDispatch] = useListLoader(
        signal => getScheduleItemsMarkerList(auth, filter, recordType, signal),
        [auth.data?.staticId, recordType, filter]
    );

    const [employeeActualList, employeesActualListDisptach] = useReducer<EmployeeListReducer>(loadListReducer, employeeList);

    /*
    const [syncInfoBlock, , updateSyncInfoBlock, abortUpdateSyncInfoBlock] = useListLoader(infoBlockLoader, syncLoaderProps);
    const [syncScheduleItemList, , updateSyncScheduleItemList, abortUpdateSyncScheduleItemList ] = useListLoader(scheduleItemListLoader, syncLoaderProps);
    const [syncScheduleItemsMarkers, , updateSyncScheduleItemsMarkers, abortUpdateSyncScheduleItemsMarkers] = useListLoader(scheduleItemsMarkersLoader, syncLoaderProps);

    const sync = useCallback(async () => {

        await Promise.all([
            updateSyncInfoBlock(),
            updateSyncScheduleItemList(),
            updateSyncScheduleItemsMarkers(),
        ]);

    }, [updateSyncInfoBlock,
        updateSyncScheduleItemList,
        updateSyncScheduleItemsMarkers,
    ]);
    */

    const sync = useCallback(async () => {

        await Promise.all([
            mergeInfoBlock(syncLoaderProps),
            mergeScheduleItemList(syncLoaderProps),
            mergeScheduleItemsMarkers(syncLoaderProps),
        ]);

    }, [mergeInfoBlock,
        mergeScheduleItemList,
        mergeScheduleItemsMarkers,
    ]);

    const update = useCallback(() => {

        //const reason = new OperationAbortedByMergeException();
        //abortUpdateSyncInfoBlock(reason);
        //abortUpdateSyncScheduleItemList(reason);
        //abortUpdateSyncScheduleItemsMarkers(reason);

        reloadDayTypeList();
        reloadEmployeeList();
        reloadScheduleItemList();
        reloadInfoBlock();
        reloadScheduleItemsMarkers();

    }, [reloadDayTypeList,
        reloadEmployeeList,
        reloadScheduleItemList,
        reloadInfoBlock,
        reloadScheduleItemsMarkers,
        //abortUpdateSyncInfoBlock,
        //abortUpdateSyncScheduleItemList,
        //abortUpdateSyncScheduleItemsMarkers
    ]);
    
    /*
    useEffect(() => {

        if (!syncInfoBlock.load) {
            return;
        }

        infoBlockDispatch({
            type: LoadListKind.LoadSuccess,
            payload: syncInfoBlock.items
        });

    }, [syncInfoBlock.load, syncInfoBlock.items]);

    useEffect(() => {

        if (!syncScheduleItemList.load) {
            return;
        }

        scheduleItemListDispatch({
            type: LoadListKind.LoadSuccess,
            payload: syncScheduleItemList.items
        });

    }, [syncScheduleItemList.load, syncScheduleItemList.items]);

    useEffect(() => {

        if (!syncScheduleItemsMarkers.load) {
            return;
        }

        scheduleItemsMarkersDispatch({
            type: LoadListKind.LoadSuccess,
            payload: syncScheduleItemsMarkers.items
        });

    }, [syncScheduleItemsMarkers.load, syncScheduleItemsMarkers.items]);
    */
    
    useEffect(() => {

        if(!didMount.current) {
            return;
        }

        if(employeeList.error !== employeeActualList.error) {
            employeesActualListDisptach({
                type: LoadListKind.LoadFail,
                error: employeeList.error
            });
        }

        if(employeeList.loading !== employeeActualList.loading) {
            employeesActualListDisptach({
                type: LoadListKind.Loading,
            });
        }

        if(!employeeList.load
        || !scheduleItemList.load) {
            return;
        }
        
        const actual = employeeList.items.map(employee => {

            let index = scheduleItemList.items.findIndex(record => employee.PID == record.PID);
            let target, total;

            if(index != -1) {
                const record = scheduleItemList.items[index];
                target = record.target;
                total = record.total;
            }

            return {
                ...employee,
                valueTarget: target,
                valueTotal: total
            }
        });

        employeesActualListDisptach({
            type: LoadListKind.LoadSuccess,
            payload: actual
        });

    }, [employeeList.items,
        employeeList.load,
        scheduleItemList,
        scheduleItemList.items,
        scheduleItemList.load,
    ]);

    useEffect(() => {

        if (!didMount.current) {
            return;
        }

        reloadScheduleItemList();
        reloadScheduleItemsMarkers();
        
    }, [recordType]);

    useEffect(() => {
        didMount.current = true;
        return () => {
            didMount.current = false;
        }
    }, []);

    return {
        update,
        sync,
        employeeList: employeeActualList,
        scheduleItemList,
        dayTypeList,
        infoBlock,
        scheduleItemsMarkers,
        reloadDayTypeList,
        reloadEmployeeList,
        reloadScheduleItemList,
        reloadInfoBlock,
        reloadScheduleItemsMarkers,
    }
}