import useAuth from '../../Hooks/useAuth';
import { Button, Collapse, ConfigProvider, Empty, Form, Input, Popconfirm, Skeleton, Space, Tag, TagProps, message } from 'antd';
import { IHasEmployeeDayKey, IScheduleBoardFilter, ITicket, IdType, TicketDecision, closeTicket, getTicketsList } from '../../Entities/Database';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ILoaderInitProps, useListLoader } from '../../Hooks/useListLoader';
import { initialLoadListState } from '../../Reducer/LoadListReducer';
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, MinusCircleOutlined, SyncOutlined } from '@ant-design/icons';
import DraggableModal from '../DraggableModal';
import { isNumber, nameof } from '../../System/Utils';
import ticketsReducer, { ITicketsReducer } from './tickets-reducer';
import './tickets.css';

const useForm = Form.useForm;

const initUseListReducer: ILoaderInitProps<ITicket, ITicketsReducer> = {
    reducer: ticketsReducer,
    initialState: {
        ...initialLoadListState,
        loading: true
    }
}

const syncStatusId = -999999;

const getTagPropsByStatusId = (statusId: IdType): TagProps => {

    switch(statusId) {

        case syncStatusId: return {
            icon: <SyncOutlined spin />,
            color: "default"
        }

        case 0: return {
            icon: <SyncOutlined spin />,
            color: 'processing'
        }

        case 1: return {
            icon: <CloseCircleOutlined />,
            color: 'error'
        }

        case 2: return {
            icon: <CheckCircleOutlined />,
            color: 'success'
        }

        case 3: return {
            icon: <ClockCircleOutlined />,
            color: 'default'
        }

        default: return {
            icon: <MinusCircleOutlined />,
            color: "default"
        }
    }
}

function TicketDetailRow({title, value}: {title: string, value: string}) {
    return (
        <div
            className="ticket-detail-row"
            title={`${title}: ${value}`}
        >
            <div className="ticket-detail-title">{title}:</div>
            <div className="ticket-detail-value">{value}</div>
        </div>
    )
}

function TicketHeader({ticket}: {ticket: ITicket}) {
    return (
        <div className="ticket-header">
            <div
                className="ticket-header-title"
                title={ticket.title}
            >
                {ticket.title}
            </div>
            <div className="ticket-header-status">
                <Tag
                    {...getTagPropsByStatusId(ticket.statusId)}
                    title={ticket.statusCaption}
                >
                    {ticket.statusCaption}
                </Tag>
            </div>
        </div>
    )
}

export interface IConfirmationValues {
    ticketId?: string,
    decision?: TicketDecision
    comment?: string
}

export interface ITicketsProps {
    item: IHasEmployeeDayKey,
    filter: IScheduleBoardFilter,
    lang?: string,
    onChange?: (item: IHasEmployeeDayKey) => void,
}

export function Tickets({
    item,
    lang = 'ru',
    onChange
}: ITicketsProps) {
    
    const [confirmationForm] = useForm<IConfirmationValues>();
    const didMount = useRef(false);
    const auth = useAuth();
    const [tickets, reloadTickets, , , ticketsReducer] = useListLoader(
        signal => getTicketsList(auth, item, lang, signal),
        [auth.data?.staticId, item.PID, item.date, lang],
        initUseListReducer
    );

    const [changesCount, setChangesCount] = useState(0);
    const [saving, setSaving] = useState(false);
    const [openConfirmationMoidal, setOpenConfirmationModal] = useState(false);

    const {
        locale: contextLocale,
        renderEmpty,
    } = useContext(ConfigProvider.ConfigContext);
    
    const emptyText = renderEmpty?.('Table') || (
        <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
    );

    const handleAccept = useCallback((ticket: ITicket) => {

        setOpenConfirmationModal(true);
        confirmationForm.setFieldsValue({
            ticketId: `${ticket.id}`,
            decision: 'accept'
        });
        
    }, [confirmationForm]);

    const handleReject = useCallback((ticket: ITicket) => {
        setOpenConfirmationModal(true);
        confirmationForm.setFieldsValue({
            ticketId: `${ticket.id}`,
            decision: 'reject'
        });
    }, [confirmationForm]);

    const handleOkConfirmation = useCallback(() => {
        confirmationForm.submit();
    }, [confirmationForm]);

    const handleCancelConfirmation = useCallback(() => {
        setOpenConfirmationModal(false);
        confirmationForm.resetFields();
    }, [confirmationForm]);

    const handleFinishConfirmationForm = useCallback(async (values: IConfirmationValues) => {

        try {

            if(!values.ticketId || !isNumber(values.ticketId)) {
                throw new Error('ticketId invalid', { cause: values.ticketId });
            }

            if(values.decision !== 'accept' && values.decision !== 'reject') {
                throw new Error('decision invalid', { cause: values.decision });
            }

            const ticketId = Number(values.ticketId);

            setSaving(true);

            await closeTicket(auth, {
                ticketId: ticketId,
                decision: values.decision,
                comment: values.comment
            });

            confirmationForm.resetFields();

            ticketsReducer({
                type: 'UPDATE_TICKET',
                payload: {
                    id: ticketId,
                    statusId: syncStatusId,
                    statusCaption: "Синхронизация...",
                    allowControl: false
                }
            });
            setOpenConfirmationModal(false);
            setChangesCount(prev => ++prev);
            message.success("Тикет закрыт успешно");

            try {
                if(onChange) {
                    onChange(item);
                }
            }
            catch (ex) { }
        }
        catch (ex) {
            message.error("Не удалось закрыть тикет");
            console.error(ex);
        }
        finally {
            setSaving(false);
        }

    }, [confirmationForm,
        auth?.data?.staticId,
        item,
        onChange
    ]);

    useEffect(
        reloadTickets,
        [auth.data?.staticId, item.PID, item.date, changesCount]
    );
    
    useEffect(() => {
        didMount.current = true;
        return () => {
            didMount.current = false;
        }
    }, []);

    return (
        <div className="tickets-list-wrapper">
            <DraggableModal
                closable={!saving}
                width={320}
                title="Укажите комментарий к ответу"
                open={openConfirmationMoidal}
                onOk={handleOkConfirmation}
                onCancel={handleCancelConfirmation}
                okButtonProps={{
                    disabled: saving,
                    loading: saving
                }}
                cancelButtonProps={{
                    disabled: saving
                }}
            >
                <Form
                    className="confirmation-form"
                    form={confirmationForm}
                    onFinish={handleFinishConfirmationForm}
                    disabled={saving}
                >
                    <Form.Item
                        name={nameof<IConfirmationValues>('ticketId')}
                        required
                        hidden
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={nameof<IConfirmationValues>('decision')}
                        required
                        hidden
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name={nameof<IConfirmationValues>('comment')}
                    >
                        <Input.TextArea
                            autoSize={{minRows: 3, maxRows: 10}}
                            maxLength={32}
                            placeholder="Введите комментарий"
                        />
                    </Form.Item>
                </Form>
            </DraggableModal>
            {!tickets.load &&
            <>
                <Skeleton active={tickets.loading} />
                <Skeleton active={tickets.loading} />
                <Skeleton active={tickets.loading} />
            </>}
            {tickets.load && tickets.items.length === 0 && emptyText}
            {tickets.load && tickets.items.length > 0 &&
            <Collapse
                items={tickets.items.map(ticket => ({
                    key: ticket.id,
                    className: "ticket-collapse-panel",
                    label: (<TicketHeader ticket={ticket} />),
                    children: (
                        <>
                            {ticket.details.map(detail => <TicketDetailRow key={detail.title} {...detail} />)}
                            {ticket.allowControl && ticket.statusId === 0 &&
                            <Space className="ticket-handlers">
                                <Popconfirm
                                    title="Принять изменения"
                                    description="Вы уверены, что хотите принять изменения ?"
                                    okText="Да"
                                    cancelText="Нет"
                                    onConfirm={() => handleAccept(ticket)}
                                >
                                    <Button size="small">Принять</Button>
                                </Popconfirm>
                                <Popconfirm
                                    title="Отклонить запрос"
                                    description="Вы уверены, что хотите отклонить запрос ?"
                                    okText="Да"
                                    cancelText="Нет"
                                    onConfirm={() => handleReject(ticket)}
                                >
                                    <Button size="small">Отклонить</Button>
                                </Popconfirm>
                            </Space>}
                        </>
                    )
                }))}
            />}
        </div>
    )
}