import { useEffect, useMemo, useRef, useState } from "react";
import moment, { Moment } from "moment";
import { IEmployeeResource } from "../ScheduleRequestHandlerModal";
import useEntityLoader from "../../Hooks/useEntityLoader";
import useAuth from "../../Hooks/useAuth";
import { getLeaves, getListEmployees, ILeaves, ILeavesEmployeeDb } from "./index.db";
import { IScheduleBoardFilter } from "../../Entities/Database";
import { Checkbox, CheckboxProps, Space, Spin } from "antd";
import { getEmployeeIcon } from "../EmployeesDayDataBoard/EmployeesDayDataTable/helpers";
import { IBreakEvent } from "../ScheduleShiftBreaks/CustomBreaksListEditor/helpers";
import classNames from "classnames";
import { LeaveUpGrid } from "./LeaveUpGrid";
import { LeaveChart } from "./LeaveChart";
import { Eventcalendar, localeRu, type MbscEventcalendarView, type MbscCalendarEvent, type MbscResource, type MbscCalendarEventData } from '../shared/lib';
import { LeavePanel, Mode } from "./LeavePanel";
import Search from "antd/es/input/Search";
import { ILeavesLocale, leavesLocaleRu } from "./locale/ru";
import { searchAllEntitiesBySelector } from "../../Reducer/SearchFilterReducer";
import { LoadingOutlined } from "@ant-design/icons";
import '../shared/lib/style.css';
import "./style.css";

const emptyResources: IEmployeeResource[] = [{
    id: 'DEF_EMPTY_RES',
    employee: undefined as any,
    eventCreation: false,
}];


export interface IBadge {
    target: number,
    total: number
}

export interface IVacationEvent extends MbscCalendarEvent {
    originalItem: ILeaves
}

export interface ILeavesProps {
    locale?: ILeavesLocale,
}

export function Leaves({
    locale = leavesLocaleRu
}: ILeavesProps) {

    const auth = useAuth();
    const editorRef = useRef<Eventcalendar>(null);
    const tableGridRef = useRef<HTMLDivElement | null>(null);
    const chartBoardRef = useRef<HTMLDivElement | null>(null);

    const [mode, setMode] = useState(Mode.Week);
    const [showLeaveChart, setShowLeaveChart] = useState(true);

    const [resources, setResources] = useState<IEmployeeResource[]>(emptyResources);
    const [leaves, setLeaves] = useState<IVacationEvent[]>();
    const [filteredEmployees, setFilteredEmployees] = useState<Readonly<ILeavesEmployeeDb[]>>();
    const [nearVacationList, setNearVacationList] = useState<Readonly<ILeavesEmployeeDb[]>>();

    const [resourcesList, setResourcesList] = useState<Readonly<ILeavesEmployeeDb[]>>();

    const [range, setRange] = useState<[Moment, Moment]>(() => [
        moment().startOf('year'),
        moment().endOf('year')
    ]);

    const filter = useMemo<IScheduleBoardFilter>(() => ({
        from: range[0],
        to: range[1],
        projectGroupIds: [],
        fieldIds: [],
        personType: 0,
        tagIds: [],
        forcastObjIds: [],
        prodActIds: [],
        skillIds: [],
        crossEmploee: false,
        timeZone: "Europe/Moscow",
        lang: "ru"
    }), [range]);

    const [debugUpdateVal, setDebugUpdateVal] = useState(0);

    const [leavesLoadState, reloadLeaves,, abortLoadLeaves] = useEntityLoader((signal) => 
      getLeaves(auth, range, signal)
    , [auth.data?.staticId, range]);

    const [employeesLoadState, reloadEmployees,, abortLoadEmployees] = useEntityLoader((signal) => 
        getListEmployees(auth, filter, signal)
    , [auth.data?.staticId, filter]);

    const handleChangeChartType = (mode: Mode) => {
        setMode(mode);
    }

    const debugUpdate = () => {
        setDebugUpdateVal(prev => prev + 1);
    }

    const handleChangeChartVisibility = (newValue: boolean) => {
        setShowLeaveChart(newValue);
    }

    const handleChangeRange = (range: [Moment, Moment]) => {
        setRange(range);
    }

    const weekView = useMemo<MbscEventcalendarView>(() => ({
        timeline: {
            size: Math.ceil(range[1].diff(range[0], 'weeks', true)),
            type: 'week',
            resolution: 'week',
            startDay: 1,
            endDay: 0,
            timeCellStep: 1,
            timeLabelStep: 60,
        },
    }), [range]);

    const monthView = useMemo<MbscEventcalendarView>(() => ({
        timeline: {
            size: 12,
            type: 'month',
            resolution: 'day',
            startDay: 1,
            endDay: 0,
        },
    }), []);

    const view = useMemo<MbscEventcalendarView>(() => {
        return mode === Mode.Week ? weekView : monthView
    }, [mode]);

    const renderResource = (resource: MbscResource) => {

        if (!resource.employee) {
            return;
        }

        const badgeValues: IBadge = {target: 0, total: 0};

        if (leavesLoadState.entity) {
            const leaveObj = leavesLoadState.entity?.find(x => x.PID === resource.employee.PID);
            if (leaveObj) {
                badgeValues.target= leaveObj.target;
                badgeValues.total = leaveObj.total;
            }
        }
    
        return resource.employee && (
            <div className="timeline-employee">
                <div className="timeline-employee-title" title={`${resource.employee.surname} ${resource.employee.firstName}`}>
                    {getEmployeeIcon(resource.employee)}
                    <span>{`${resource.employee.surname} ${resource.employee.firstName}`}</span>
                </div>
                <Space
                    className="timeline-employee-data"
                    align="baseline"
                >
                    <div 
                        className="timeline-employee-role" 
                        title={resource.employee.roleCaption}
                    >
                        {resource.employee.roleCaption}
                    </div>
                    <div className="timeline-employee-badge">
                        {badgeValues.target}/{badgeValues.total}
                    </div>
                    
                </Space>
            </div>
        );
    }

    const eventRender = (data: MbscCalendarEventData) => {
        const title = data.title || leavesLocaleRu.eventTitle;
        const start = data.start;
        const end   = data.end;
        const zoneDuration = moment(data.endDate).diff(data.startDate, 'minutes');
        const originalBreakDuration = (data.original as IBreakEvent).originalBreak?.durn || 5;
        const width = (100 * (originalBreakDuration / zoneDuration)) + '%';
        const isStatic = +data.startDate + (originalBreakDuration * 60 * 1000) === +data.endDate;
        const tooltip = title;
        const expired = data.startDate < moment().toDate();

        let progress = 0;

        if (data.startDate < moment().toDate() && data.endDate < moment().toDate()) {
            progress = 100;
        }
        
        if (data.startDate < moment().toDate() && data.endDate > moment().toDate()) {
            const weeksAmount = moment(data.endDate).clone().diff(data.startDate, 'weeks');
            const weeksTillToday = moment().diff(data.startDate, 'weeks');
            progress = (weeksTillToday * 100) / weeksAmount;
        }

        return (
            <div title={tooltip}>
                <div className={classNames("mbsc-schedule-event-background", "mbsc-timeline-event-background", "mbsc-ios")}>
                    <div style={{width: `${progress}%`}} className={classNames(expired && "expired-vacation")}></div>
                </div>
                <div aria-hidden="true" className={classNames("ap-work-time-break-background", {"static": isStatic})} style={{ width: width}}></div>
                <div aria-hidden="true" className="mbsc-schedule-event-inner mbsc-ios">
                    <div className="mbsc-schedule-event-title mbsc-ios">{title}</div>
                </div>
            </div>
        );
    }

    useEffect(() => {
        reloadEmployees();
        reloadLeaves();
    }, [auth.data?.staticId,
        filter, // ?
        range]);

    useEffect(() => {

        if (leavesLoadState.entity) {
            
            const leaves = leavesLoadState.entity.map((x: ILeaves): IVacationEvent => ({
                ...x,
                id: x.id,
                resource: x.PID,
                start: x.sd.clone().toDate(),
                end: x.sd.clone().add(x.durn, 'day').toDate(),
                editable: false,
                originalItem: x,
                
            }));

            setLeaves(leaves);
        }

        if (resourcesList) {
            const resouces = resourcesList.map((x: any): IEmployeeResource => ({ //filteredEmployees
                id: x.PID,
                employee: x,
            }));

            setResources(resouces);
        }

    }, [leavesLoadState.entity, resourcesList]);

    useEffect(() => {

        const calendarRoot   = editorRef.current?.nativeElement;
        const timelineGridEl = calendarRoot?.querySelector<HTMLDivElement>(".mbsc-timeline .mbsc-timeline-grid-scroll"); 
        const tableGridEl    = tableGridRef.current;
        const chartBoardEl   = chartBoardRef.current;
        
        let currScrollLeft = 0;

        const syncScroll = () => {

            if (timelineGridEl && timelineGridEl.scrollLeft !== currScrollLeft) {
                timelineGridEl.scrollLeft = currScrollLeft;
            }

            if (tableGridEl && tableGridEl.scrollLeft !== currScrollLeft) {
                tableGridEl.scrollLeft = currScrollLeft;
            }

            if (chartBoardEl && chartBoardEl.scrollLeft !== currScrollLeft) {
                chartBoardEl.scrollLeft = currScrollLeft;
            }
        }

        const scrollTopTableHandler = function (this: HTMLElement, ev: Event) {
            if (tableGridEl) {
                currScrollLeft = tableGridEl.scrollLeft;
                syncScroll();
            }
        }

        const scrollTimelineHandler = function (this: HTMLElement, ev: Event) {
            if (timelineGridEl) {
                currScrollLeft = timelineGridEl.scrollLeft;
                syncScroll();
            }
        }

        const scrollChartBoardHandler = function (this: HTMLElement, ev: Event) {
            if (chartBoardEl) {
                currScrollLeft = chartBoardEl.scrollLeft;
                syncScroll();
            }
        }

        const fixScrollTimeout = setTimeout(() => {
            syncScroll();
        }, 150);
    
        tableGridEl?.addEventListener('scroll', scrollTopTableHandler, false);
        timelineGridEl?.addEventListener('scroll', scrollTimelineHandler, false);
        chartBoardEl?.addEventListener('scroll', scrollChartBoardHandler, false);

        syncScroll();

        return () => {
            clearTimeout(fixScrollTimeout);
            tableGridEl?.removeEventListener('scroll', scrollTopTableHandler, false);
            timelineGridEl?.removeEventListener('scroll', scrollTimelineHandler, false);
            chartBoardEl?.addEventListener('scroll', scrollChartBoardHandler, false);
        }

    }, [debugUpdateVal, showLeaveChart]);

    const [width, setWidth] = useState(window.innerWidth - 80);
    const [topTableHeight, setTopTableHeight] = useState(45);
    const [mbHeight, setMbHeight] = useState(200);
    const [chartHeight, setChartHeight] = useState(100);

    const renderWeeks = (args: any) => (
        <div>
            {args.weekNr}
        </div>
    );

    const renderDays = (args: any) => (
        <div className="leaves-padding">
            {moment(args.date).format("D")}
        </div>
    );

    useEffect(() => {

        let mbHeight = window.innerHeight - 50 - 45 - 40;
        // 50 - высота шапки страницы,
        // 45 - высота верхней таблицы,
        // 40 - высота шапки верхней таблицы

        if (showLeaveChart) {
            mbHeight = mbHeight - 300;
        }

        // const resizeObserver = new ResizeObserver((entries) => {

        //     const handler = () => {
        //         setWidth(window.innerWidth - 80);
        //         setTopTableHeight(45);
        //         setChartHeight(300);
        //         setMbHeight(mbHeight);
        //     }
    
        //     handler();

        // });

        // resizeObserver.observe(); //что тут обозревать? 

        // return () => {
        //     resizeObserver.unobserve();
        // }

        const handler = () => {
            setWidth(window.innerWidth - 80);
            setTopTableHeight(45);
            setChartHeight(300);
            setMbHeight(mbHeight);
        }

        handler();

        // TODO: перевод на https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver
        window.addEventListener('resize', handler, false);
        return () => {
            window.removeEventListener('resize', handler, false);
        }

    }, [showLeaveChart]);


    const handleEmployeeSearch = (value: any) => {
        const result = searchAllEntitiesBySelector(employeesLoadState.entity, (item) => `${item.surname} ${item.firstName}`, value);
        setFilteredEmployees(result);
    }

    const handleChangeCheckbox: CheckboxProps['onChange']= (e) => {
       
        if (!employeesLoadState.entity || !leavesLoadState.entity) return;
       
        if (e.target.checked) {
           
            const max = moment().add(2, 'weeks');
            const today = moment();
          
            const filteresLeaves = leavesLoadState.entity.filter(x => (x.sd < max && x.sd > today) || (x.sd < today && x.sd.add(x.durn, 'days') > today));
        
            if (filteresLeaves.length) {
                const filteredEmployees = employeesLoadState.entity.filter(x => filteresLeaves.find(y => y.PID === x.PID));
                setNearVacationList(filteredEmployees);
            }

        } else {
            setNearVacationList([]);
        }
        
    }

    const renderResourceHeader = () => (
        <>
            <Search
                size="small"
                placeholder={leavesLocaleRu.searchPlaceholder}
                onSearch={handleEmployeeSearch}
                allowClear
            />
            <Checkbox 
                onChange={handleChangeCheckbox}
                className="leaves-checkbox"
            >
                {leavesLocaleRu.checkboxTitle}
            </Checkbox>
        </>
    );

    useEffect(() => {
        if (employeesLoadState.entity) {
            setFilteredEmployees(employeesLoadState.entity);
        }

    }, [employeesLoadState.entity]);

    const todoRefDate = range[0].clone().startOf('year').toDate();

    useEffect(() => {
        if (!nearVacationList || nearVacationList.length === 0) { 
            setResourcesList(filteredEmployees);
        } else if (nearVacationList.length && filteredEmployees) {
            const merged = filteredEmployees.filter(x => nearVacationList.find(y => y.PID === x.PID));
            setResourcesList(merged)
        } 

    }, [nearVacationList, filteredEmployees])

    return (
        <div className="leaves-wrap">
            <LeavePanel
                mode={mode}
                year={range[0]}
                visibleChart={showLeaveChart}
                onVisibleChartChange={handleChangeChartVisibility}
                onChangeRange={handleChangeRange}
                onChangeMode={handleChangeChartType}
            />
            <LeaveUpGrid 
                range={range}
                gridElRef={tableGridRef}
                width={width}
                height={topTableHeight}
                mode={mode}
                debugUpdate={debugUpdate}
            />
            <Spin
                indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
                spinning={!leavesLoadState.load || !employeesLoadState.load}
            >
                <Eventcalendar
                    ref={editorRef}
                    className={classNames("leaves-calendar", {
                        "days": mode === Mode.Day
                    })}
                    theme="ios"
                    themeVariant="light"
                    view={view}
                    clickToCreate={false}
                    dragToMove={false}
                    dragToResize={false}
                    dragToCreate={false}
                    selectMultipleEvents={false}
                    refDate={todoRefDate}
                    selectedDate={todoRefDate}
                    dataTimezone={filter.timeZone}
                    showControls={false}
                    showOuterDays={false}
                    locale={localeRu}
                    height={mbHeight}
                    width={width}
                    resources={resources}
                    data={leaves}
                    renderScheduleEvent={eventRender}  
                    renderResource={renderResource}
                    renderResourceHeader={renderResourceHeader}
                    renderWeek={renderWeeks}
                    renderDay={renderDays}
                />
            </Spin>
            {showLeaveChart && 
            <LeaveChart
                boardRef={chartBoardRef}
                range={range}
                filter={filter}
                isWeekChart={mode === Mode.Week}
                width={width}
                height={chartHeight}
            />}
        </div>      
    )
}