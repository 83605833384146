import { Form, Modal, Select, message as AntdMessage } from "antd";
import DraggableModal from "../../DraggableModal";
import { useEffect, useRef, useState } from "react";
import { IApplyEmployeeScheduleShift, IBasicEmployee, IBindEmployeeScheduleShift, IScheduleShiftScheme, applyEmployeeShiftScheme, bindEmployeeShiftScheme, getBasicEmployeeList, getScheduleShiftSchemeList } from "../../../Entities/Database";
import { doOrAbort, nameof } from "../../../System/Utils";
import { assignEmployeeScheduleShiftModalLocaleRu, assignEmployeeShiftModalErrorTextLangRu } from "./locale/ru";
import useAuth from "../../../Hooks/useAuth";
import { searchInValue } from "../../../Reducer/SearchFilterReducer";
import DatePicker from "../../shared/DatePicker";
import { ExclamationCircleFilled } from "@ant-design/icons";
import useActual from "../../../Hooks/useActual";

import "./style.css";

export interface IAssignEmployeeScheduleShiftLocale {
    title: string,
    employee: string,
    scheme: string,
    startDate: string,
}

interface IAssignEmployeeScheduleShiftModalProps {
    isOpen?: boolean,
    onCancel?: () => void,
    onFinishSave?: () => void,
    locale?: IAssignEmployeeScheduleShiftLocale,
    employeePID?: string,
    scheme?: IScheduleShiftScheme,
    onScheduleChange?: () => void,
}

interface IAssignEmployeeScheduleShift extends IBindEmployeeScheduleShift, IApplyEmployeeScheduleShift {

}

const abortByChange = {};
const abortByClose = {};

export function AssignEmployeeScheduleShiftModal({
    isOpen,
    onCancel,
    onFinishSave,
    locale = assignEmployeeScheduleShiftModalLocaleRu,
    employeePID,
    scheme,
    onScheduleChange
}: IAssignEmployeeScheduleShiftModalProps) {

    const auth = useAuth();
    const [modal, modalContextHolder] = Modal.useModal();
    const [message, messageContextHolder] = AntdMessage.useMessage();
    const [form] = Form.useForm<IAssignEmployeeScheduleShift>();
    const [saving, setSaving] = useState(false);
    const [disableOk, setDisableOk] = useState(false);
    const [employeeList, setEmployeeList] = useState<IBasicEmployee[]>([]);
    const [schemeList, setSchemeList] = useState<IScheduleShiftScheme[]>();
    const [loadScheme, setLoadScheme] = useState(false);
    const [loadEmployee, setLoadEmployee] = useState(false);

    const destructorAcRef = useRef<AbortController>();
    const loaderAcRef = useRef<AbortController>();
    
    const abortPrevReq = (reason: unknown) => {
        if (loaderAcRef.current) {
            loaderAcRef.current.abort(reason);
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.error('Failed:', errorInfo);
    };

    const handleSubmitModal = () => {
        form.submit();
    }

    const handleFormFinish = (values: IAssignEmployeeScheduleShift) => {
        saveEmployeeShiftScheme(values);
    }

    const onFinishSaveBuf = useActual(onFinishSave);
    const onScheduleChangeBuf = useActual(onScheduleChange);

    const saveEmployeeShiftScheme = async (data: IAssignEmployeeScheduleShift) => {

        try {
            setSaving(true);
            const abortController = new AbortController();
            const signal = abortController.signal;

            let changes = true;

            await bindEmployeeShiftScheme(auth, data, signal);
            await modal.confirm({
                title: 'Подтвердите действие',
                icon: <ExclamationCircleFilled />,
                content: 'Шаблон успешно назначен, выполнить перерасчет расписания у сотрудника ?',
                okText: 'Да',
                cancelText: 'Нет',
                onOk: () => applyEmployeeShiftScheme(auth, data, signal),
                onCancel: () => { changes = false; },
            });

            if (onFinishSaveBuf.current) {
                onFinishSaveBuf.current();
            }

            if (onScheduleChangeBuf.current && changes) {
                onScheduleChangeBuf.current();
            }
        }
        catch(ex) {
            message.error("При сохранении данных возникла ошибка.")
            console.error(ex);
        }
        finally {
            setSaving(false);
        }
    }

    const loadScheduleShiftSchemeList = async (signal: AbortSignal) => {
        
        try {

            setLoadScheme(true);
           
            const loadedShiftSchemeList: IScheduleShiftScheme[] = await doOrAbort(
                signal => getScheduleShiftSchemeList(auth, signal),
                signal,
                destructorAcRef.current?.signal
            );
            
            setSchemeList(loadedShiftSchemeList);
            setLoadScheme(false);
            setDisableOk(false);
        }
        catch (ex) {

            if (ex === abortByChange || ex === abortByClose) {
                return;
            }

            console.error(ex);
            message.error('Не удалось загрузить список шаблонов смен.');
            setLoadScheme(false);
            setDisableOk(true);
        }
    }

    const loadEmployeeList = async (signal: AbortSignal) => {
            
        try {

            setLoadEmployee(true);
           
            const loadedEmployeeList: IBasicEmployee[] = await doOrAbort(
                signal => getBasicEmployeeList(auth, signal),
                signal,
                destructorAcRef.current?.signal
            );
           
            setEmployeeList(loadedEmployeeList);
            setLoadEmployee(false);
            setDisableOk(false);
        }
        catch (ex) {

            if (ex === abortByChange || ex === abortByClose) {
                return;
            }

            console.error(ex);
            message.error('Не удалось загрузить список сотрудников.');
            setLoadEmployee(false);
            setDisableOk(true);
        }
    }

    useEffect(() => {

        if(isOpen) {

            const ac = loaderAcRef.current = new AbortController();
            const signal = ac.signal;

            if (employeePID === undefined) {
                loadEmployeeList(signal);
            }

            if (scheme === undefined) {
                loadScheduleShiftSchemeList(signal);
            }
           
            return () => {
                abortPrevReq(abortByChange);
            }
        }

    }, [auth.data?.staticId, isOpen]);

    useEffect(() => {

        if (!isOpen) {

            form.resetFields();

            if (scheme) {
                form.setFieldValue(nameof<IAssignEmployeeScheduleShift>("schemeId"), scheme.sss_id);
            }

            if (employeePID) {
                form.setFieldValue(nameof<IAssignEmployeeScheduleShift>("employeePID"), employeePID);
            }
        }

    }, [isOpen, employeePID, scheme?.sss_id]);

    useEffect(() => {
        destructorAcRef.current = new AbortController();
        return () => {
            destructorAcRef.current?.abort(abortByClose);
        }
    }, []);

    return (
        <>
            <DraggableModal
                open={isOpen}
                onOk={handleSubmitModal}
                onCancel={onCancel}
                title={locale.title}
                closable={!saving}
                okButtonProps={{
                    disabled: loadScheme || loadEmployee || disableOk || saving,
                    loading: saving
                }}
                cancelButtonProps={{disabled: saving}}
                okText={"Сохранить"}
                width={361}
                className="assignModal__modal"
            >
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={handleFormFinish}
                    onFinishFailed={onFinishFailed}
                >
                    <Form.Item
                        hidden={employeePID !== undefined}
                        label={locale.employee}
                        name={nameof<IAssignEmployeeScheduleShift>("employeePID")}
                        initialValue={employeePID}
                        rules={[
                            {
                                required: true, 
                                message: assignEmployeeShiftModalErrorTextLangRu.employee
                            }
                        ]}
                    >
                        <Select
                            style={{width: "100%"}}    
                            showSearch
                            loading={loadScheme || loadEmployee || saving}
                            disabled={loadScheme || loadEmployee || saving}
                            filterOption={(inputValue, employeeList) => searchInValue(employeeList?.label, inputValue)}
                            options={!loadEmployee ? employeeList.map(item => ({
                                value: item.PID, 
                                label: item.fio,
                            })): []}
                        />
                    </Form.Item>
                    <Form.Item
                        hidden={scheme !== undefined}
                        label={locale.scheme}
                        name={nameof<IAssignEmployeeScheduleShift>("schemeId")}
                        initialValue={scheme?.sss_id}
                        rules={[
                            {
                                required: true, 
                                message: assignEmployeeShiftModalErrorTextLangRu.scheme
                            }
                        ]}
                    >
                        <Select
                            style={{width: "100%"}}    
                            showSearch
                            loading={loadScheme || loadEmployee || saving}
                            disabled={loadScheme || loadEmployee || saving || scheme !== undefined}
                            filterOption={(inputValue, schemeList) => searchInValue(schemeList?.label, inputValue)}
                            options={!loadScheme && schemeList ? schemeList.map(item => ({
                                value: item.sss_id, 
                                label: item.title,
                            })) : []}
                        /> 
                    </Form.Item>
                    <Form.Item
                        label={locale.startDate}
                        name={nameof<IAssignEmployeeScheduleShift>("startDate")}
                        rules={[
                            {
                                required: true, 
                                message: assignEmployeeShiftModalErrorTextLangRu.startDate
                            }
                        ]}
                    >
                        <DatePicker 
                            style={{width: "100%"}}
                            disabled={loadScheme || loadEmployee || saving}
                        />
                    </Form.Item>
                </Form>
            </DraggableModal>
            {modalContextHolder}
            {messageContextHolder}
        </>
    )
}