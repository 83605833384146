import type { Moment } from 'moment';
import * as React from 'react';
import DatePicker from './DatePicker';
import { PickerTimeProps, RangePickerTimeProps } from 'antd/es/time-picker';

export interface TimePickerProps extends Omit<PickerTimeProps<Moment>, 'picker'> {}

const TimePicker = React.forwardRef<any, TimePickerProps>((props, ref) => (
    <DatePicker {...props} picker="time" ref={ref} />
));

TimePicker.displayName = 'TimePicker';

export interface TimeRangePickerProps extends Omit<RangePickerTimeProps<Moment>, 'picker'> {
    popupClassName?: string;
}

export const RangePicker = React.forwardRef<any, TimeRangePickerProps>((props, ref) => (
    <DatePicker.RangePicker {...props} picker="time" ref={ref} />
));

export default TimePicker;