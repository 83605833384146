import React, { ErrorInfo, PropsWithChildren } from "react";

export interface ErrorBoundaryProps extends PropsWithChildren<any> {

}

export interface ErrorBoundaryState {
    hasError: false,
    error?: unknown,
    errorInfo?: ErrorInfo
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {

    constructor(props: ErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error: unknown) {
        // Обновить состояние с тем, чтобы следующий рендер показал запасной UI.
        return { hasError: true };
    }
  
    componentDidCatch(error: unknown, errorInfo: ErrorInfo) {
        // Можно также сохранить информацию об ошибке в соответствующую службу журнала ошибок
        console.error(error, errorInfo);
        this.setState({
            error: error,
            errorInfo: errorInfo
        });
    }
  
    render() {

        if (this.state.hasError) {
            // Можно отрендерить запасной UI произвольного вида
            return (
                <div>
                    <h1>Что-то пошло не так.</h1>
                    <p>{JSON.stringify(this.state.error)}</p>
                    <p>{JSON.stringify(this.state.errorInfo)}</p>
                </div>
            );
        }
  
        return this.props.children; 
    }
}