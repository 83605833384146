
/**
 * Создает обертку функции, которая запускается через отложенный запуск,
 * при каждом вызове обернутой функции, предыдущий отложенный запуск отменяется и перезаписывается новым
 * 
 * @param handler Отложенная функция
 * @param timeout Время (ms) до отложенного запуска
 * @param before Функция, которая выполняется перед инициализацией таймера отложенного запуска
 * @returns 
 */
export function debounce<T extends Function>(
    handler: T,
    timeout: number,
    before?: T
): T {

    let timerDebounce: number | undefined;

    return (function (this: any, ...args: any[]) {

        const self = this;

        const beforeResult = before?.apply(self, args);

        clearTimeout(timerDebounce);

        timerDebounce = window.setTimeout(() => {
            timerDebounce = undefined;
            handler.apply(self, args);
        }, timeout);

        return beforeResult;

    }) as unknown as T
}

/**
 * Создает обертку функции, которая запускается через отложенный запуск,
 * При многократном вызове функции, происходит сохранение передаваемых аргументов и указателя this
 * по истечению счетчика времени, отложенная функция запускается с последними сохраненными аргументами и указателем this
 * 
 * @param handler Отложенная функция
 * @param timeout Время (ms) до отложенного запуска
 * @param before Функция, которая выполняется перед инициализацией таймера отложенного запуска
 * @returns 
 */
export function capacitounce<T extends Function>(
    handler: T,
    timeout: number,
    before?: T
): T {
    
    let timer: number | undefined; 

    let lastThis: any;
    let lastArgs: any[];

    return (function (this: any, ...args: any[]) {

        lastThis = this;
        lastArgs = args;

        const beforeResult = before?.apply(lastThis, lastArgs);

        if(!timer) {
            timer = window.setTimeout(() => {

                clearTimeout(timer);
                timer = undefined;

                handler.apply(lastThis, lastArgs);
                
            }, timeout);
        }

        return beforeResult;

    }) as unknown as T
}

/**
 * Создает обертку функции, которая запускается с указанным интервалом,
 * При многократном вызове функции, происходит сохранение передаваемых аргументов и указателя this
 * по истечению счетчика времени, отложенная функция запускается с последними сохраненными аргументами и указателем this
 * 
 * @param handler Отложенная функция
 * @param timeout Интервал (ms)
 * @param before Функция, которая вызовается при вызове перезаписи аргументов и указателя this
 * @returns 
 */
export type PingounceDestructor = (runHandler?: boolean) => void
export function pingounce<T extends Function>(
    handler: T,
    interval: number,
    before?: T
): [T, PingounceDestructor] {

    let init = false;
    let first = true;
    let lastThis: any;
    let lastArgs: any[];

    const hole = () => {
        
        if(!init) {
            return;
        }

        handler.apply(lastThis, lastArgs);
    }

    let pingInterval: number | undefined = window.setInterval(hole, interval);

    const destructor = (runHandler?: boolean) => {

        clearInterval(pingInterval);
        pingInterval = undefined;

        if(runHandler) {
            
            hole();
        }

        init = false;
    }

    return [
        (function (this: any, ...args: any[]) {

            init = true;
            lastThis = this;
            lastArgs = args;

            const beforeResult = before?.apply(lastThis, lastArgs);
            first = false;
            return beforeResult;

        }) as unknown as T,
        destructor
    ]
}