import moment from "moment";

export const dateFormat = "DD.MM.YYYY";

export function getLastVacationColor(dayNums: number) {
    if (dayNums < 120) return "red";
    if (dayNums >= 120 && dayNums < 170) return "orange";
    return "green";
}

export function getFreeVacationDaysColor(dayNums: number) {
    if (dayNums < 15) return "green";
    if (dayNums >= 15 && dayNums < 30) return "orange";
    return "red";
}

export function getLastVacationDuration(date: moment.Moment | null, duration?: number) {

    if (!date || !duration) {
        return -1;
    }

    const lastVacationEndDate = date && duration && date.clone().add(duration);
    const diff = moment().diff(lastVacationEndDate, "days");
    return diff;
}