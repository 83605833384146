import { Button, Switch } from "antd";
import { Moment } from "moment";
import { RollbackOutlined } from "@ant-design/icons";
import { ILeavePanelLocale, leavePanelLocaleRu } from "./locale/ru";
import DatePicker from "../../shared/DatePicker";

import "./style.css";

export enum Mode {
    Day = 1,
    Week = 2,
}

export interface ILeavePanelProps {
    mode: Mode,
    visibleChart: boolean,
    year: Moment,
    onChangeMode: (mode: Mode) => void,
    onVisibleChartChange: (x: boolean) => void,
    onChangeRange: (range: [Moment, Moment]) => void,
    locale?: ILeavePanelLocale,
}

export function LeavePanel({
    mode,
    visibleChart,
    year,
    onVisibleChartChange,
    onChangeRange,
    onChangeMode,
    locale = leavePanelLocaleRu
}: ILeavePanelProps) {

    const handlePickerChange = (date: Moment) => {

        const newSd = date.clone().startOf('year');
        const newEd = date.clone().endOf('year');

        onChangeRange([newSd, newEd]);
    }

    const handleChangeMode = () => {
        if (mode === Mode.Day) {
            onChangeMode(Mode.Week);
        } else {
            onChangeMode(Mode.Day);
        }
    }

    return (
        <div className="leaves-panel-wrap">
            <Button
                onClick={handleChangeMode}
                size="small"
                color="default"
            >
                <RollbackOutlined />
                {mode === Mode.Week ? leavePanelLocaleRu.weekChartOption : leavePanelLocaleRu.dayChartOption}
            </Button>
            <DatePicker
                value={year}
                onChange={handlePickerChange} 
                picker="year" 
                size="small"
            />
            <div className="leaves-panel-flex">
                <Switch 
                    checked={visibleChart} 
                    onChange={onVisibleChartChange} 
                    size="small"
                />
                <div className="leaves-panel-chart-title">{leavePanelLocaleRu.switchTitle}</div>
            </div>
        </div>
    );
}