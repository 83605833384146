import moment from "moment";
import { getAccessTokenStr } from "../../Server/DbProvider";
import { HttpTransportType, HubConnection, HubConnectionBuilder, HubConnectionState, LogLevel } from "@microsoft/signalr";
import { useEffect, useRef, useState } from "react";
import useAuth from "../../Hooks/useAuth";
import { ObjectDisposedException } from "../../System/ObjectDisposedException";
import { IGeneratedData, IGeneratedDataDb, backendUrls, convertFromDb } from "../../Entities/BackendGenerator";
import { nameof } from "../../System/Utils";
import useActual from "../../Hooks/useActual";

//export const generatorHubUrl = `${backendUrl}/hub`;

export interface IGeneratorHub {
    Finish: (data: IGeneratedDataDb) => void,
    Fail: (message: string) => void,
}

export interface IStartData {
    from: moment.Moment,
    to: moment.Moment,
    castCount: number,
    fullSearchCount: number,
}

export interface IGeneratorHubProvider {
    connect: () => Promise<void>,
    disconnect: () => Promise<void>,
    abort: () => Promise<void>,
    start: (data: IStartData) => Promise<void>,
}

export interface IDataHubState {
    sessionId: string,
    token: string | null,
    state: HubConnectionState,
    hub: HubConnection | null,
}

export interface IGenerationHubProviderOptions {
    serverUrl?: string,
    sessionId?: string,
    onFinish?: (data: IGeneratedData) => void,
    onFail?: (message: string) => void,
    onClose?: (error?: Error | undefined) => void,
    onConnectionSuccess?: () => void,
    onConnectionFail?: () => void,
}

export function useGeneratorProvider(options?: IGenerationHubProviderOptions): IGeneratorHubProvider {

    const [defSessionId] = useState(() => crypto.randomUUID());
    const {
        serverUrl = backendUrls['Gen2'],
        sessionId = defSessionId,
        onFinish,
        onFail,
        onClose,
        onConnectionSuccess,
        onConnectionFail,
    } = options || {};

    const auth = useAuth();
    const onFinishActual = useActual(onFinish);
    const onFailActual = useActual(onFail);
    const onCloseActual = useActual(onClose);
    const onConnectionFailActual = useActual(onConnectionFail);
    const onConnectionSuccessActual = useActual(onConnectionSuccess);

    const abortControllerRef = useRef<AbortController | null>(null);
    const hubConnectionRef = useRef<HubConnection | null>(null);
    const prevTokenRef = useRef<string | null>(null);

    const initHub = async (token: string) => {

        const abortController = new AbortController();
        const hubConnection = new HubConnectionBuilder()
            .withUrl(`${serverUrl}/hub?session_id=${sessionId}`, {
                skipNegotiation: true,
                transport: HttpTransportType.WebSockets,
                accessTokenFactory: () => token,
            })
            //.configureLogging(LogLevel.Trace)
            .withAutomaticReconnect()
            .build();
        
        const prevAbortController = abortControllerRef.current;
        const prevHubConnection = hubConnectionRef.current;
        
        abortControllerRef.current = abortController;
        hubConnectionRef.current = hubConnection;

        prevTokenRef.current = token;
        prevAbortController?.abort();
        prevHubConnection?.stop();

        hubConnection.on(nameof((x: IGeneratorHub) => x.Finish), (data: IGeneratedDataDb) => {
            if (onFinishActual.current && hubConnectionRef.current === hubConnection) {
                onFinishActual.current(convertFromDb(data));
            }
        });

        hubConnection.on(nameof((x: IGeneratorHub) => x.Fail), (message: string) => {
            if (onFailActual.current && hubConnectionRef.current === hubConnection) {
                onFailActual.current(message);
            }
        });

        hubConnection.onclose((x) => {
            if (onCloseActual.current && hubConnectionRef.current === hubConnection) {
                onCloseActual.current(x);
            }
        });

        try {
            await hubConnection.start();

            if (hubConnectionRef.current === hubConnection && !abortController.signal.aborted) {

                if (onConnectionSuccessActual.current) {

                    onConnectionSuccessActual.current();
                }
            }
        }
        catch (ex) {

            if (hubConnectionRef.current === hubConnection && !abortController.signal.aborted) {

                if (onConnectionFailActual.current) {
                    onConnectionFailActual.current();
                }
            }
        }

        if (abortController.signal.aborted) {
            hubConnection.stop();
        }
    }

    const connect = () => {
        const token = getAccessTokenStr(auth.data);
        return initHub(token);
    }

    const abort = async function () {

        const hub = hubConnectionRef.current;

        if (hub && hub.state == HubConnectionState.Connected) {

            return await hub.send("Abort");
        }

        throw new ObjectDisposedException("Hub disposed");
    }

    const start = async function({ from, to, fullSearchCount, castCount }: IStartData) {

        const hub = hubConnectionRef.current;

        if (hub && hub.state == HubConnectionState.Connected) {

            return await hub.send("Start", {
                from: from.toDate(),
                to: to.toDate(),
                fullSearchCount,
                castCount
            });
        }

        throw new ObjectDisposedException("Hub disposed");
    }

    const disconnect = async () => {

        if (abortControllerRef.current) {
            abortControllerRef.current.abort();
        }
        
        if (hubConnectionRef.current) {
            await hubConnectionRef.current.stop();
        }
    }

    useEffect(() => {
        disconnect();
    }, [serverUrl, sessionId, auth.data?.staticId]);

    return {
        connect,
        disconnect,
        start,
        abort,
    }
}