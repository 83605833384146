import { isMoment } from "moment";
import { Key } from "react";
import { IEmployeeScheduleDay } from "../../../Entities/Database";
import { isArray } from "../../../System/Utils";
import { getItemElement } from "../../EmployeesDayDataBoard/helpers";
import { getScrollbarSize } from "../../shared/domHelpers";

export type HeaderColumnType = moment.Moment & {
    htmlElement: HTMLElement
}

export type ItemType = IEmployeeScheduleDay | HeaderColumnType;

export const subMenuClass = 'cell-context-sub-menu';
export const subMenuKeyClass = 'cell-context-sub-menu-key';

export function isHeaderColumn(item: any): item is HeaderColumnType {
    return item && item.htmlElement !== undefined && isMoment(item);
}

export function getSubMenuKeyClass(id: Key) {
    return `${subMenuKeyClass}-${id}`;
}

export function getSubMenuSelector(id: Key) {
    return `.${subMenuClass}.${getSubMenuKeyClass(id)}`;
}

export function getSubMenuElement(id: Key) {
    const selector = getSubMenuSelector(id);
    return document.querySelector(selector);
}

export function isOutsideClick(event: MouseEvent, contextMenuElement: HTMLElement | undefined | null, subMenuIds: Key[] | Key) {

    const normalizeSubMenuIds = isArray(subMenuIds) ? subMenuIds : [subMenuIds];
    const target = event.target as unknown as Node;
    
    if (contextMenuElement && (contextMenuElement === target || contextMenuElement.contains(target))) {
        return false;
    }

    for(let subMenuId of normalizeSubMenuIds) {
        const subMenu = getSubMenuElement(subMenuId);
        if(subMenu && (subMenu === target || subMenu.contains(target))) {
            return false;
        }
    }

    return true;
}

export function getHeaderColumnOrItemElement(item: ItemType, outerGrid: HTMLElement) {

    if(isHeaderColumn(item)) {
        return item.htmlElement;
    }

    return getItemElement(item, outerGrid);
}

export function calculateXY(outerGrid: HTMLElement, popup: HTMLElement, item: ItemType) {
    
    const element = getHeaderColumnOrItemElement(item, outerGrid);

    if (element) {

        const scrollbarSize = getScrollbarSize();
        const popupRect = popup.getBoundingClientRect();
        const outerGridRect = outerGrid.getBoundingClientRect();
        const itemElementRect =  element.getBoundingClientRect();

        let x = itemElementRect.x;
        let y = itemElementRect.y + itemElementRect.height + 4;

        const popupWidth  = popupRect.width;
        const popupHeight = popupRect.height;

        if (x + popupWidth > outerGridRect.x - outerGridRect.left + outerGridRect.width - scrollbarSize) {
            x = outerGridRect.x + outerGridRect.width - popupWidth - scrollbarSize - outerGridRect.left;
        } else {
            x -= outerGridRect.left;
        }

        if (y + popupHeight > outerGridRect.y + outerGridRect.height - scrollbarSize) {
            y = itemElementRect.y - popupHeight - 4;
        }

        return {x, y};
    }

    return {
        x: -999999,
        y: -999999
    }
}