import { useEffect, useRef } from "react";
import { IAuthData } from "../Server/IAuthData";
import useGenericAuth from "./useGenericAuth";
import useActual from "./useActual";

export function useAuth() {

    const auth = useGenericAuth<IAuthData>();
    const logountTimeoutRef = useRef<number | null>(null);
    const actualLogout = useActual(auth.logout);

    useEffect(() => {

        if (logountTimeoutRef.current) {
            window.clearTimeout(logountTimeoutRef.current);
        }

        if (auth.data) {
            const timeout = +auth.data.expires - +new Date();
            logountTimeoutRef.current = window.setTimeout(() => {
                actualLogout.current();
            }, timeout > 0 ? timeout : 0);
        }

    }, [auth.data]);

    return auth;
}

export default useAuth