import { useEffect, useMemo, useRef, useState } from "react";
import ScheduleBoard from "../ScheduleBoard";
import ShiftTradeRequestListModal from "../ShiftTradeRequestListModal";
import { LogoutOutlined } from "@ant-design/icons";
import { Button, Menu, MenuProps, Popconfirm, Tooltip } from "antd";
import { Leaves } from "../Leaves";
import { appealsKey, getNormalizedMenuItems, getStaticMenuItems, scheduleKey, vacationKey } from "./helper";
import useAuth from "../../Hooks/useAuth";
import classNames from "classnames";
import { IMainBoardLocale, mainBoardLocaleRu } from "./locale/ru";

import './style.css';

export const defaultSelectedKeys = [scheduleKey];

export interface IMainBoardProps {
    locale?: IMainBoardLocale
}

export default function MainBoard({
    locale = mainBoardLocaleRu
}: IMainBoardProps) {

    const auth = useAuth();
    const [activeItem, setActiveItem] = useState(scheduleKey);
    const [openSTRLModal, setOpenSTRLModal] = useState(false);

    const mainBoardRef= useRef<HTMLDivElement | null>(null);
    const menuClickHandler: MenuProps['onClick']  = ({ key }) => {

        if (key === appealsKey) {
            setOpenSTRLModal(true);
        } else {
            setActiveItem(key);
        }
    }

    const logout = () => {
        auth.logout();
    }

    const handleSTRLModalCancel = () => {
        setOpenSTRLModal(false);
    }

    const internalMenuItems = useMemo(() => getNormalizedMenuItems(getStaticMenuItems()), []);

    return (
        <div 
            className="ap-main-board"
            ref={mainBoardRef}
        >
            <div className="ap-main-board-menu">
                <div className="ap-main-board-menu-content">
                    <div className="">
                        <div className="ap-main-logo-wrap">
                            <img src="/assets/image/logo-short.png" alt="logo" className="ap-main-logo"/>
                        </div>
                        <Menu
                            mode="inline"
                            theme="dark"
                            inlineCollapsed={true}
                            items={internalMenuItems}
                            defaultSelectedKeys={defaultSelectedKeys}
                            onClick={menuClickHandler}
                        />
                    </div>
                    <Popconfirm
                        title={locale.confirmTitle}
                        onConfirm={logout}
                        okText={locale.exitText}
                        cancelText={locale.cancelText}
                    >
                        <div 
                            className="ap-main-footer" 
                        > 
                            <Tooltip placement="right" title={'Выход'} arrow={true}>
                                <Button icon={<LogoutOutlined/>} className="ap-main-exit" type="text" /> 
                            </Tooltip> 
                        </div>
                    </Popconfirm> 
                </div>
            </div>
            <ShiftTradeRequestListModal
                open={openSTRLModal}
                onCancel={handleSTRLModalCancel}
            />
            <div className={classNames("ap-schedule-board-wrap", {
                "visible": activeItem === scheduleKey,
            })}>
                <ScheduleBoard />
            </div>
            {activeItem === vacationKey && <Leaves />}
        </div>
    );
}