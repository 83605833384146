import { IEmployeeScheduleDay } from "../../Entities/Database";
import { getItemElement, getItemElementPosition, getItemKey, parseItemKey, recordItemComparer } from "../EmployeesDayDataBoard/helpers";

export const getScrollItemPosition = (item: IEmployeeScheduleDay, itemOuterGrid: HTMLElement, prevWidth: number, nextWidth: number, scrollLeft: number) => {

    if(nextWidth > prevWidth) {
        return false;
    }

    const itemElement = getItemElement(item, itemOuterGrid);

    if(itemElement) {

        const itemStyle = getComputedStyle(itemElement);
        const itemElementEndPos = parseFloat(itemStyle.left) + parseFloat(itemStyle.width);
        const nextGridViewEndPos = scrollLeft + nextWidth;

        if (itemElementEndPos > nextGridViewEndPos) {
            return getItemElementPosition(itemElement);
        }
    }

    return false;
}

export const selectItemsHandler = (
    all: Readonly<IEmployeeScheduleDay[]>,
    selected: Readonly<string[]>,
    item: Readonly<IEmployeeScheduleDay>,
    event: Readonly<React.MouseEvent>
) => {

    const itemKey = getItemKey(item);
    const index = selected.findIndex(x => recordItemComparer(x, itemKey));

    if(selected.length === 1) {

        const prevSelectedKey = selected[0];
        const prevSelectedItem = parseItemKey(prevSelectedKey);

        if (!prevSelectedItem) {
            return [];
        }

        if (event.shiftKey && prevSelectedItem.PID === item.PID) {

            const increase = item.date > prevSelectedItem.date;
            const fromDay = increase ? prevSelectedItem.date : item.date;
            const toDay = increase ? item.date : prevSelectedItem.date;

            if(fromDay.valueOf() === toDay.valueOf()) {
                return selected;
            }

            if(fromDay.clone().add('d').valueOf() === toDay.valueOf()) {
                return [prevSelectedKey, itemKey];
            }

            const buffer = all
            .filter(x =>
                x.PID == prevSelectedItem.PID &&
                x.date > fromDay &&
                x.date < toDay
            ).map(x => getItemKey(x))

            return [prevSelectedKey, ...buffer, itemKey];
        }
    }

    if(!event.ctrlKey && !event.shiftKey && selected.length > 1) {
        return [itemKey];
    }

    if(selected.length > 0) {

        const prevSelectedItem = parseItemKey(selected[0]);
        if(prevSelectedItem && prevSelectedItem.PID != item.PID) {
            return [itemKey];
        }
    }

    if(index === -1) {
        
        if(event.ctrlKey) {

            return [...selected, itemKey];
        }

        return [itemKey];
    }

    if(event.ctrlKey) {
        const res = [...selected];
        res.splice(index, 1);
        return res;
    }

    return [];
}