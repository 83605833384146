export interface IMainSkillLocale {
    errorSaveMessage: string,
    errorApplyMessage: string,
    cancelWarn: string,
    cancelQuestion: string,
    modalTitle: string,
    modalOkTitle: string,
    modalCancelTitle: string,
    employeeLabel: string,
    mainSkillLabel: string,
    mainSkillError: string,
    dateTitle: string,
    dateError: string,
}

export const mainSkillLocaleRu: IMainSkillLocale = {
    errorSaveMessage: 'При сохранении данных возникла ошибка.',
    errorApplyMessage: 'Выбранный навык уже назначен.',
    cancelWarn: 'Внесенные изменения не сохранятся!',
    cancelQuestion: 'Выйти без сохранения?',
    modalTitle: 'Изменение: Основной навык',
    modalOkTitle: 'Сохранить',
    modalCancelTitle: 'Закрыть',
    employeeLabel: 'Сотрудник:',
    mainSkillLabel: 'Основной навык:',
    mainSkillError: 'Не выбран навык',
    dateTitle: 'Назначить с:',
    dateError: 'Дата не заполнена',

}