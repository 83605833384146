import { IEmployeeSkill } from ".";
import { IEmployee } from "../../../Entities/Database";
import { IAuthState } from "../../../Entities/IAuthProvider";
import { execute } from "../../../Server/DbProvider";
import { IAuthData } from "../../../Server/IAuthData";
import { DateQueryParameter, IntQueryParameter, StringQueryParameter } from "../../../Server/Query";

const saveMainSkillProcName = "[Control].[dbo].[proc_WFM_Skill_Person_Common_bind]";

export async function saveMainSkill(auth: IAuthState<IAuthData>, values: IEmployeeSkill, signal?: AbortSignal) {

    await auth.throwIfNotLogedInAndLogout(signal);

    const result = await execute<any>({
        authData: auth.data,
        procedure: saveMainSkillProcName,
        parameters: [
            new StringQueryParameter("PID_selected", values.employeePID),
            new IntQueryParameter("SkGr_ID", values.skillId),
            new DateQueryParameter("SD", values.startDate)
        ]
    }, signal);

    return result;
}