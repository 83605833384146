import React, { useEffect, useLayoutEffect, useMemo, useRef } from "react";
import moment from "moment";
import VirtualTable, { ColumnType, ColumnsType, Grid, VirtualTableProps } from "antd-virtual-table";
import { Button, Space } from "antd";
import { ColumnSearchProvider, useColumnSearch } from "../../../Hooks/useColumnSearch";
import { classNames, colorToStyle, groupBy, isNullOrUndefined } from "../../../System/Utils";
import { refSetter } from "../../shared/helpers";
import { handleClick, fixedLeftHeaderHelperForValueTarget, fixedLeftHeaderHelperForValueTotal, getFIO, getItemKey, IScheduleEmployeeDataSourceItem, recordItemComparer, RecordItemElementEvent, IScheduleEmployeeDayWithMarkers, IMarkersState, getItemElement } from "../helpers";
import { IEmployeeScheduleDay, IScheduleBoardEmployee, IScheduleBoardFilter, IEmployeeScheduleDayMarker, IHasEmployeeDayKey, getEmployeeCard, ScheduleBoardEmployeeIconType } from "../../../Entities/Database";
import { ILoadListState } from "../../../Reducer/LoadListReducer";
import { TableLocale } from "antd/lib/table/interface";
import { MarkerList } from "./marker-list";
import { EllipsisOutlined } from '@ant-design/icons';
import { getEmployeeIcon } from "./helpers";
import ru from 'antd/locale/ru_RU';
import './table.css';

export interface IGridLocale {
    searchByEmployees: string,
    employees: string,
    tableLocale: TableLocale,
    momentLocale: moment.LocaleSpecifier,
}

export const localeRu: IGridLocale = {
    searchByEmployees: "Поиск по сотрудникам",
    employees: "Сотрудники",
    tableLocale: ru.Table as TableLocale,
    momentLocale: 'ru',
}

export const dateKeyFormat = "YYYY.MM.DD";
export const defaultHeaderDateFormat = 'DD.MM (dd)';
export const defaultItemKeyAttributeName = "item-key";
export const defaultItemSelectedClassName = "selected";

export type TEmployee = IScheduleBoardEmployee;
export type TItem = IEmployeeScheduleDay;
export type TMarker = IEmployeeScheduleDayMarker;
export type TDate = moment.Moment;
export type TFilter = IScheduleBoardFilter;
export type TDataSourceItem = IScheduleEmployeeDataSourceItem;

export function getDayKey(date: moment.Moment) {
    return `day_${date.format(dateKeyFormat)}`;
}

export function getEmployeeDayKey(marker: IHasEmployeeDayKey) {
    const dKey = getDayKey(marker.date);
    return `${marker.PID}_${dKey}`;
}

export function getRowKey(item: TDataSourceItem) {
    return item.PID;
}

export class ColorList {

    public readonly list: Record<string, string> = {};

    static getBorderColorClassName(color: number)     { return `br-color-${color}` }
    static getBackgroundColorClassName(color: number) { return `bg-color-${color}` }
    static getTextColorClassName(color: number)       { return `tc-color-${color}` }

    addBorderColor(color: number) {
        const className = ColorList.getBorderColorClassName(color);
        this.list[className] = `.${className}{border-color: ${colorToStyle(color)}}`;
    }

    addBackgroundColor(color: number) {
        const className = ColorList.getBackgroundColorClassName(color);
        this.list[className] = `.${className}{background-color: ${colorToStyle(color)}}`;
    }

    addTextColor(color: number) {
        const className = ColorList.getTextColorClassName(color);
        this.list[className] = `.${className}{color: ${colorToStyle(color)}}`;
    }

    getStyle() {
        return Object.values(this.list).join(" ");
    }
}

const scrollbarWidth = 20; // TODO

export interface IEmployeeDayTableProps {
    className?: string,
    gridRef?: VirtualTableProps<TDataSourceItem>['gridRef'],
    outerGridRef?: VirtualTableProps<TDataSourceItem>['outerGridRef'],
    width: number,
    height: number,
    headerDateFormat?: string,
    itemClassName?: string,
    itemKeyAttributeName?: string,
    employeeColumnWidth?: number,
    dayColumnWidth?: number,
    rowHeight?: number,
    locale?: IGridLocale,
    filter?: TFilter,
    selectedKeys?: Readonly<string[]>,
    items?: ILoadListState<TItem>,
    markers?: ILoadListState<TMarker>,
    employees?: ILoadListState<TEmployee>,
    loading?: boolean,
    onScroll?: VirtualTableProps<TDataSourceItem>['onScroll'],
    onClickRecordItem?: (item: TItem, event: RecordItemElementEvent<React.MouseEvent>) => void,
    onDoubleClickRecordItem?: (item: TItem, event: RecordItemElementEvent<React.MouseEvent>) => void,
    onContextMenuRecordItem?: (item: TItem, event: RecordItemElementEvent<React.MouseEvent>) => false | void,
    onClickHeaderColumn?: (date: TDate, event: React.MouseEvent) => void,
    onDoubleClickHeaderColumn?: (date: TDate, event: React.MouseEvent) => void,
    onContextMenuHeaderColumn?: (date: TDate, event: React.MouseEvent) => false | void,
    onClickEmployeeCell?: (employee: TEmployee, event: React.MouseEvent) => void,
    onContextMenuEmployeeCell?: (employee: TEmployee, event: React.MouseEvent) => void,
}

export function InternalEmployeesDayTable({
    className,
    gridRef,
    outerGridRef,
    width,
    height,
    itemClassName,
    headerDateFormat = defaultHeaderDateFormat,
    itemKeyAttributeName = defaultItemKeyAttributeName,
    employeeColumnWidth = 235,
    dayColumnWidth = 75,
    rowHeight = 46,
    locale = localeRu,
    filter,
    selectedKeys,
    items,
    markers,
    employees,
    loading,
    onScroll,
    onClickRecordItem,
    onDoubleClickRecordItem,
    onContextMenuRecordItem,
    onClickHeaderColumn,
    onDoubleClickHeaderColumn,
    onContextMenuHeaderColumn,
    onClickEmployeeCell,
    onContextMenuEmployeeCell,
}: IEmployeeDayTableProps) {
    
    const internalGridRef = useRef<Grid<TDataSourceItem>>(null);
    const internalOuterGridRef = useRef<HTMLElement>(null);
    const { getColumnSearchProps } = useColumnSearch<TDataSourceItem>();

    const employeeColumns = useMemo((): ColumnsType<TDataSourceItem> => {

        const firstColumn: ColumnType<TDataSourceItem> = {
            ...getColumnSearchProps({ key: 'employee' }, {
                title: "employee",
                valueSelector: (record) => getFIO(record.employee)
            }, locale.searchByEmployees),
            dataIndex: 'employee',
            title: locale.employees,
            fixed: 'left',
            ellipsis: true,
            overlap: 3,
            sorter: {
                multiple: 3,
                compare: (a, b, sortOrder) => getFIO(a.employee)?.localeCompare(getFIO(b.employee)),
            },
            onCell: () => ({
                className: "employee-info-grid-item"
            }),
            width: employeeColumnWidth - fixedLeftHeaderHelperForValueTotal.width - fixedLeftHeaderHelperForValueTarget.width,
            shouldCellUpdate: (record, prevRecord, isScrolling) => isScrolling !== true,
            render: (value, record, index, isScrolling) => {

                if(record) {

                    const hasTotal = !isNullOrUndefined(record.employee.valueTotal);
                    const hasTarget = !isNullOrUndefined(record.employee.valueTarget);

                    const fio = `${record.employee.surname} ${record.employee.firstName} ${record.employee.secondName}`;
                    const surNameFirstName = `${record.employee.surname} ${record.employee.firstName}`;
                    
                    return (
                        <div className="employee-data">
                            <Space className="employee-space">
                                <div className="employee-title" title={fio}>
                                    {getEmployeeIcon(record.employee)}
                                    <span>{surNameFirstName}</span>
                                </div>
                                <Button
                                    style={{padding: 0, height: 25}}
                                    type="text"
                                    icon={<EllipsisOutlined />}
                                    onClick={(event) => onClickEmployeeCell && onClickEmployeeCell(record.employee, event)}
                                    onContextMenu={(event) => onContextMenuEmployeeCell && onContextMenuEmployeeCell(record.employee, event)}
                                />
                            </Space>
                            
                            <Space
                                className="employee-meta"
                                align="baseline"
                            >
                                <div className="employee-role">
                                    {record.employee.roleCaption}
                                </div>
                                
                                {(hasTotal || hasTarget) &&
                                <div className="employee-hr">
                                    {hasTotal              && <span>{record.employee.valueTotal}</span>}
                                    {hasTotal && hasTarget && <span>/</span>}
                                    {hasTarget             && <span>{record.employee.valueTarget}</span>}
                                </div>}
                            </Space>
                        </div>
                    )
                }

                return value;
            }
        };

        return [
            firstColumn,
            fixedLeftHeaderHelperForValueTotal,
            fixedLeftHeaderHelperForValueTarget
        ];

    }, [locale,
        employeeColumnWidth,
        getColumnSearchProps,
        onClickEmployeeCell,
        onContextMenuEmployeeCell
    ]);

    const daysColumns = useMemo((): ColumnsType<TDataSourceItem> => {

        if(!filter || !filter.from || !filter.to) {
            return [];
        }

        const momentLocale = locale.momentLocale;
        const from = filter.from.clone().startOf("day");
        const to   = filter.to.clone().endOf("day");
        const columns: ColumnsType<TDataSourceItem> = [];

        let day = from;
        let dayIndex = 0;

        while(day < to) {

            const currentDayIndex = dayIndex++;
            const currectDay = day.clone();
            const internalCurrentDay = day.clone();
            const dateKey    = internalCurrentDay.format(dateKeyFormat);
            const currectKey = `day_${dateKey}`;

            columns.push({
                key: currectKey,
                title: internalCurrentDay.locale(momentLocale).format(headerDateFormat),
                width: dayColumnWidth,
                ellipsis: true,
                onHeaderCell: (record, index) => ({
                    className: `employee-day-header-title col-day-idx-${currentDayIndex}`,
                    'column-index': index,
                    onContextMenu: (event) => onContextMenuHeaderColumn && onContextMenuHeaderColumn(currectDay, event),
                    //onDoubleClick: (event) => onDoubleClickHeaderColumn && onDoubleClickHeaderColumn(currectDay, event),
                    onClick: (event) => onClickHeaderColumn && onClickHeaderColumn(currectDay, event),
                }),
                shouldCellUpdate: (record, prevRecord, isScrolling) => isScrolling !== true,
                onCell: (record, index) => {

                    if (record) {

                        const scheduleDay = record.scheduleDays[currectKey];

                        if(scheduleDay) {

                            const keyIndex = selectedKeys?.findIndex(selectedKey => recordItemComparer(selectedKey, scheduleDay));
                            const selected = keyIndex !== undefined && keyIndex !== -1;

                            return {
                                [itemKeyAttributeName]: getItemKey(scheduleDay),
                                onContextMenu: (event) => handleClick(onContextMenuRecordItem, record, currectKey, index, event),
                                //onDoubleClick: (event) => handleClick(onDoubleClickRecordItem, record, currectKey, index, event),
                                onClick: (event) => handleClick(onClickRecordItem, record, currectKey, index, event),
                                className: classNames(
                                    itemClassName,
                                    `col-day-idx-${currentDayIndex}`,
                                    {[defaultItemSelectedClassName]: selected}
                                )
                            }
                        }
                    }

                    return {}
                },
                render: (value, record, index) => {

                    if (record) {

                        const scheduleDay = record.scheduleDays[currectKey];
                        
                        return scheduleDay && (
                            <>
                                <MarkerList
                                    className="employee-day-markers"
                                    list={scheduleDay.markers}
                                />
                                <div className={classNames(
                                    `employee-day-data-bg`,
                                    ColorList.getTextColorClassName(scheduleDay.textColor),
                                    ColorList.getBackgroundColorClassName(scheduleDay.backgroundColor),
                                )} />
                                <div className="employee-day-data">
                                    {scheduleDay.caption1 && <div className="employee-day-data-caption employee-day-data-caption1">{scheduleDay.caption1}</div>}
                                    {scheduleDay.caption2 && <div className="employee-day-data-caption employee-day-data-caption2">{scheduleDay.caption2}</div>}
                                </div>
                            </>
                        );
                    }

                    return value;
                }
            });

            day = from.add(1, 'day');
        }

        // fix scrollbar
        columns[columns.length - 1].width += scrollbarWidth;
        return columns;

    }, [scrollbarWidth,
        dayColumnWidth,
        filter?.from?.valueOf(),
        filter?.to?.valueOf(),
        onClickRecordItem,
        onDoubleClickRecordItem,
        onContextMenuRecordItem,
        onClickHeaderColumn,
        onDoubleClickHeaderColumn,
        onContextMenuHeaderColumn,
        locale.momentLocale,
        itemClassName,

        // Переносим логику выборки ячейки в юз эффект
        // для ускорения работы и исключения дополнительных перерендеров
        //selectedKeys
    ]);

    const columns = useMemo(() => [...employeeColumns, ...daysColumns],
                                  [employeeColumns, daysColumns]);

    const [dataSource, colors] = useMemo((): [TDataSourceItem[], ColorList] => {

        if(!employees?.load || !items?.load) {
            return [[], new ColorList];
        }

        const colors = new ColorList();
        const markersGroupByEmployeeDay = groupBy(
            markers?.items || [],
            marker => {
                colors.addBackgroundColor(marker.backgroundColor);
                colors.addBorderColor(marker.borderColor);
                return getEmployeeDayKey(marker);
            }
        );
        
        const source = employees?.items.map(employee => {

            const employeeDays = items?.items.filter(day => day.PID == employee.PID);
            const scheduleEmployeeDays: Record<string, IScheduleEmployeeDayWithMarkers> = {};

            employeeDays?.forEach(employeeDay => {

                const currentDay = employeeDay.date.clone();
                const currectKey = getDayKey(currentDay);
                const employeeDayKey = getEmployeeDayKey(employeeDay);

                const markersState: IMarkersState = {
                    sourceState: null,
                    load: markers?.load || false,
                    loading: markers?.loading || false,
                    error: markers?.error,
                    items: markersGroupByEmployeeDay[employeeDayKey] || []
                }

                const employeeDayWithMarkers = {
                    ...employeeDay,
                    markers: markersState
                }
                
                scheduleEmployeeDays[currectKey] = employeeDayWithMarkers;

                colors.addBackgroundColor(employeeDay.backgroundColor);
                colors.addTextColor(employeeDay.textColor);
            });

            const data: TDataSourceItem = {
                PID: employee.PID,
                employee: employee,
                scheduleDays: scheduleEmployeeDays
            };
            
            return data;
        });

        return [source, colors];

    }, [employees?.items,
        items?.items,
        markers?.items
    ]);

    useEffect(() => {

        internalGridRef.current?.resetAfterIndices({
            columnIndex: 0,
            rowIndex: 0,
        });

    }, [filter?.from?.valueOf(),
        filter?.to?.valueOf()
    ]);

    useEffect(() => {

        if (internalOuterGridRef.current) {

            const items = internalOuterGridRef.current.querySelectorAll(`.${itemClassName}.${defaultItemSelectedClassName}`);

            for (const item of items) {
                item.classList.remove(defaultItemSelectedClassName);
            }

            if (selectedKeys) {

                for (const itemKey of selectedKeys) {
                    const item = internalOuterGridRef.current.querySelector(`.${itemClassName}[${itemKeyAttributeName}="${itemKey}"]`);
                    item?.classList.add(defaultItemSelectedClassName);
                }
            }
        }

    }, [selectedKeys,
        itemKeyAttributeName
    ]);

    useLayoutEffect(() => {

        const element = document.createElement("style");
        document.head.appendChild(element);
        element.innerHTML = colors.getStyle();

        return () => {
            document.head.removeChild(element);
        }

    }, [colors]);

    return (
        <VirtualTable<TDataSourceItem>
            rerenderFixedColumnOnHorizontalScroll={false}
            gridRef={refSetter(internalGridRef, gridRef)}
            outerGridRef={refSetter(internalOuterGridRef, outerGridRef)}
            size="small"
            rowHeight={rowHeight}
            rowKey={getRowKey}
            className={classNames(className, "employees-day-data-table")}
            bordered
            columns={columns}
            dataSource={dataSource}
            loading={loading || employees?.loading || items?.loading}
            scroll={{x: width, y: height, scrollToFirstRowOnChange: false}}
            pagination={false}
            onScroll={onScroll}
        />
    );
}

export const EmployeesDayDataTable = (props: IEmployeeDayTableProps) => (
    <ColumnSearchProvider>
        <InternalEmployeesDayTable
            {...props}
        />
    </ColumnSearchProvider>
)

export default EmployeesDayDataTable;