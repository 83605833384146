import { Badge, Button, ButtonProps } from "antd";
import { PropsWithChildren, useEffect, useState } from "react";
import useStatusesHub, { IStatuses } from "../../Hooks/useStatusesHub";

export interface IPublishButtonProps extends Omit<ButtonProps, 'type'> {

}

export function PublishButton({children, ...btnProps}: PropsWithChildren<IPublishButtonProps>) {
    
    const api = useStatusesHub();
    const [publishQueueQuantity, setPublishQueueQuantity] = useState(0);

    useEffect(() => {

        const pushNotificationHanlder = (statuses: IStatuses) => {
            setPublishQueueQuantity(statuses.publishQueueQuantity);
        }

        api.on('pushStatuses', pushNotificationHanlder);

        return () => {
            api.off('pushStatuses', pushNotificationHanlder);
        }

    }, []);

    return (
        <Badge
            style={{ backgroundColor: '#52c41a' }}
            count={publishQueueQuantity}
            title="Количество сотрудников с изменениями на публикацию"
        >
            <Button
                {...btnProps}
                type="default"
            >
                {children}
            </Button>
        </Badge>
    );
}

export default PublishButton