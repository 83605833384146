import moment, { Moment } from "moment";
import { Button, Popover, Slider, Spin, Tag, message as AntdMessage, Skeleton } from "antd";
import {Eventcalendar, MbscCalendarEvent, MbscEventcalendarView, MbscResource, localeRu as mbscLocaleRu } from "../shared/lib";
import { ConfirmPopup, IAcceptForm, IConfirmPopupLocale } from "../ConfirmPopup";
import DraggableModal from "../DraggableModal";
import { ru } from "./locale/ru";
import classNames from "classnames";
import useAuth from "../../Hooks/useAuth";
import useEntityLoader from "../../Hooks/useEntityLoader";
import { getShiftTradeData, getStatusList, IShiftTradeModalProps, IShiftTradeResource, IStatus, setScheduleShiftTradeStatus } from "../../Entities/DataBasePortal";
import { useEffect, useMemo, useRef, useState } from "react";
import { refSetter } from "../shared/helpers";
import { LoadingOutlined, MessageOutlined, UserOutlined } from "@ant-design/icons";
import { colorToStyle } from "../../System/Utils";
import { EmployeeSkillsModal, IShiftTrade } from "../EmployeeSkillsModal";
import useActual from "../../Hooks/useActual";
import "./style.css";

const defaultTimeStep = 120;
const sliderMarks = {
    5:   {label: 5},
    15:  {label: 15},
    30:  {label: 30},
    60:  {label: 60},
    120: {label: 120},
}

export interface IEmployeeResource extends MbscResource {
    employee: IShiftTradeResource,
}

export interface IEmployeeActivity extends MbscCalendarEvent {
    id: string,
    title: string,
    resource: string | number,
    start: Date,
    end: Date,
}

export interface IScheduleRequestHandlerModalLocale {
    confirmLocale: IConfirmPopupLocale,
    acceptBtnTitle: string,
    rejectBtnTitle: string,
    closeBtnTitle: string,
    skillsBtnTitle: string,
    commentTitle: string,
    activitiesViewer: typeof mbscLocaleRu,
}

export interface IScheduleRequest {
    PID: string,
    requestId?: number,
}

export interface IDates {
    refDate?: Date | undefined,
    min?: Date | undefined,
    max?: Date | undefined
}

export interface IScheduleRequestHandlerModalProps {
    locale?: IScheduleRequestHandlerModalLocale,
    open?: boolean,
    item: IScheduleRequest,
    onFinish?: () => void,
    onCancel?: () => void,
}

export default function ScheduleRequestHandlerModal({
    locale = ru,
    open,
    item,
    onCancel,
    onFinish
}: IScheduleRequestHandlerModalProps) {

    const auth = useAuth();
    const [message, messageContextHolder] = AntdMessage.useMessage();
    const [tradeInfoDataState, reloadTradeData] = useEntityLoader(signal => getShiftTradeData(auth, item, signal), [item.requestId, auth.data?.staticId]);
    const [statusListState, reloadStatusList] = useEntityLoader(signal => getStatusList(auth, signal), [auth.data?.staticId]); //нужно ли вынести загрузку выше? 
    const [modalProps, setModalProps] = useState<IShiftTradeModalProps>(); //данные отображения для модалки
    const [resources, setResources] = useState<IEmployeeResource[]>([]); //сотрудники
    const [activities, setActivities] = useState<IEmployeeActivity[]>();
    const [refDate, setRefDate] = useState<Date>();
    const [trade, setTrade] = useState<IShiftTrade>();
    const [dates, setDates] = useState<IDates>(() => ({}));
    const [timeStep, setTimeStep] = useState(defaultTimeStep);
    const [dayCount, setDayCount] = useState(2);
    const onFinishBuf = useActual(onFinish);
    const [processing, setProcessing] = useState(false);

    const [openSkillsModal, setOpenSkillsModal] = useState(false);

    const handleOpenSkillsModal = () => {
        setOpenSkillsModal(true);
    }

    const handleCloseSkillsModal = () => {
        setOpenSkillsModal(false);
    }

    const view = useMemo<MbscEventcalendarView>(() => ({
        timeline: {
            size: dayCount,
            type: 'day',
            startDay: 1,
            endDay: 0,
            timeCellStep: timeStep,
            timeLabelStep: timeStep,
            startTime: '00:00',
            endTime: '00:00',
        }
    }), [dayCount, timeStep]);

    const internalEditorRef = useRef<Eventcalendar>(null);
    const navigateEditorScrollTimeoutRef = useRef<ReturnType<typeof setTimeout>>();

    const editorScrollToDate = (date: Date, delay: number = 300) => {

        if (navigateEditorScrollTimeoutRef.current) {
            clearTimeout(navigateEditorScrollTimeoutRef.current);
        }

        navigateEditorScrollTimeoutRef.current = setTimeout(() => {
            internalEditorRef.current?.navigate(date, true);
        }, delay);
    }
    const [confirmAppeal, setConfirmAppeal] = useState(false);

    const handleAcceptConfirm = async (data: IAcceptForm) => {
        
        if (item.requestId) {
            try {
                setProcessing(true);
                setConfirmAppeal(true);
                await setScheduleShiftTradeStatus(auth, item.requestId, data.comment, true);
                if (onFinishBuf.current) {
                    onFinishBuf.current()
                }
      
            } catch (e) {
                message.error("Не удалось принять заявку.");
                console.error(e);
            } finally {
                setProcessing(false);
                setConfirmAppeal(false);
            }
        }
    }

    const handleAcceptCancel  = () => {}

    const handleRejectConfirm = async (data: IAcceptForm) => {
        
        if (item.requestId) {
            try {
                setProcessing(true);
                setConfirmAppeal(true);
                await setScheduleShiftTradeStatus(auth, item.requestId, data.comment, false);
                if (onFinishBuf.current) {
                    onFinishBuf.current()
                }
            } catch (e) {
                message.error("Не удалось отклонить заявку.");
                console.error(e);
            } finally {
                setProcessing(false);
                setConfirmAppeal(false);
            }
        }
    }

    const handleRejectCancel  = () => {}

    useEffect(() => reloadStatusList(), []);

    useEffect(() => {

        setRefDate(undefined);

        if (tradeInfoDataState.entity && tradeInfoDataState.load) {

            const tmpResources  = tradeInfoDataState.entity[2];
            const tmpActivities = tradeInfoDataState.entity[1];
            const tmpModalProps = tradeInfoDataState.entity[0];

            const resources = tmpResources.map((x): IEmployeeResource => ({
                id: x.recordId,
                eventCreation: false,
                employee: x
            }));

            const activities: IEmployeeActivity[] = [];

            let minStart: Moment | undefined;
            let maxEnd: Moment | undefined;

            tmpActivities.forEach(x => {

                if (!minStart || minStart > x.start) minStart = x.start.clone();
                if (!maxEnd || maxEnd < x.end)       maxEnd = x.end.clone();

                activities.push({
                    id: x.id,
                    resource: x.recordId,
                    title: x.caption,
                    start: x.start.toDate(),
                    end: x.end.toDate(),
                    editable: false,
                    color: colorToStyle(x.backgroundColor),
                });
            });
            
            if (minStart && maxEnd) {

                const dateSND  = minStart.clone().add(1, 'day');
                const minSOD   = minStart.clone().startOf('day');
                const maxEOD   = maxEnd.isSame(dateSND) ? dateSND.clone() : maxEnd.clone().endOf('day');
                const minS2H   = minStart.clone().subtract(2, 'hour');
                const dayCount = Math.ceil(maxEOD.diff(minSOD, 'day', true));
                const viewTime = minSOD > minS2H ? minSOD : minS2H;

                editorScrollToDate(viewTime.toDate(), 100);
                setDayCount(dayCount);
                setRefDate(minSOD.toDate());
                
                console.log(dayCount, maxEnd.toDate(), maxEOD.toDate(), minSOD.toDate());
            }

            setTrade((resources.length < 1 || resources[0].employee.PID === resources[1].employee.PID) ? undefined : {
                from: resources[0].employee,
                to: resources[1].employee,
            });
            setModalProps(tmpModalProps);
            setResources(resources);
            setActivities(activities);
        }
        else {
            setTrade(undefined);
            setModalProps(undefined);
            setResources([]);
            setActivities([]);
        }

    }, [tradeInfoDataState.load,
        tradeInfoDataState.entity]);

    useEffect(() => {

        if (open) {
            return reloadTradeData();
        }

    }, [open]);

    const handleChangeTimeStep = (value: number) => {
        setTimeStep(value);
    }

    const renderResourceHeader = () => {

        return (
            <Slider
                className="ap-scale-slider"
                defaultValue={defaultTimeStep}
                onChange={handleChangeTimeStep}
                min={5}
                max={120}
                step={null}
                marks={sliderMarks}
                included={false}
            />
        );
    }

    const renderResource = (resource: IEmployeeResource) => {

        if (!resource.employee) {
            return <></>;
        }

        return (
            <div className="ap-stsve-resource">
                <div className="ap-stsve-employee">
                    <div
                        className="ap-stsve-employee-title"
                        title={resource.employee.caption1}
                    >
                        <UserOutlined />
                        <span>{resource.employee.caption1}</span>
                    </div>
                    <div className="ap-stsve-employee-wrap">
                        <div className="ap-stsve-employee-data">
                            {resource.employee.caption2}
                        </div>
                        <Popover placement="right" content={resource.employee.comment} trigger={["click", "hover"]}> 
                            <MessageOutlined style={{color: '#828282'}}/>
                        </Popover>
                    </div>
                </div>
            </div>
        );
    }
    const accessBtn = modalProps?.showApproveBtn ? 
        <ConfirmPopup
            key="acceptBtn"
            locale={locale.confirmLocale}
            onConfirm={handleAcceptConfirm}
            onCancel={handleAcceptCancel}
            style={{background: 'red'}}
            canBeOpen={open}
        >
            <Button 
                size="small"
                disabled={!tradeInfoDataState.load || confirmAppeal }
                className={classNames('confirmButton__accept', {
                    'visible': true
                })}
            >
                {locale.acceptBtnTitle}
            </Button>
        </ConfirmPopup> : null;

    const rejectBtn =  modalProps?.showApproveBtn ? 
        <ConfirmPopup
            key="rejectBtn"
            locale={locale.confirmLocale}
            onConfirm={handleRejectConfirm}
            onCancel={handleRejectCancel}
            style={{background: 'red'}}
            canBeOpen={open}
        >
            <Button 
                size="small"
                disabled={!tradeInfoDataState.load || confirmAppeal}
                className={classNames('confirmButton__reject', {
                    'visible': true,
                })}
            >
                {locale.rejectBtnTitle}
            </Button>
        </ConfirmPopup> : null;

    const handleClose = (e: any) => {
        if (onCancel) {
            onCancel();
        }
    }
    
    return (
        <>
            <DraggableModal
                title={(
                    <div className="title-wrap">
                        {!tradeInfoDataState.load
                        ? <Skeleton.Input size="small" active={tradeInfoDataState.loading} />
                        : (<>
                            <div>{modalProps?.formCaption}</div>
                            {getStatusTag(modalProps, statusListState.entity)}
                        </>)}
                    </div>
                )}
                closable={!processing}
                keyboard={!processing}
                forceRender={true}
                width={'80%'}
                open={open}
                okButtonProps={{hidden: true}}
                onCancel={handleClose}
                footer={[
                    accessBtn,
                    rejectBtn,
                    <Button 
                        size="small"
                        key="cancelBtn"
                        type="primary"
                        onClick={handleClose}
                        disabled={confirmAppeal}
                    >
                        {locale.closeBtnTitle}
                    </Button>
                ]}
            >
                <div className="additional-hr-menu">
                    {!!modalProps?.showSkillsBtn && trade &&
                    <Button
                        size="small"
                        className="button-skills"
                        onClick={handleOpenSkillsModal}
                        loading={tradeInfoDataState.loading}
                    >
                        {locale.skillsBtnTitle}
                    </Button>}
                    <div className="additional-menu-comment-wrap">
                        <MessageOutlined style={{color: '#828282'}} />
                        <span>{locale.commentTitle}</span> {modalProps?.comment}
                    </div>
                </div>
                
                <div className="ap-shifttrade-schedule-viewer">
                    <div
                        className={classNames({
                            'ap-shifttrade-schedule-viewer-preloader': true,
                            'ap-hidden': tradeInfoDataState.load
                        })}
                    >
                        <Spin
                            indicator={
                                <LoadingOutlined
                                    spin={tradeInfoDataState.loading}
                                    style={{
                                        fontSize: 24,
                                        margin: 'auto',
                                        display: 'block'
                                    }}
                                />
                            }
                        />
                    </div>
                    <Eventcalendar
                        ref={refSetter(internalEditorRef)}
                        refDate={refDate}
                        theme="ios"
                        themeVariant="light"
                        showControls={false}
                        showOuterDays={false}
                        locale={locale.activitiesViewer}
                        width="100%"
                        height="100%"
                        view={view}
                        resources={resources}
                        data={activities}
                        renderResourceHeader={renderResourceHeader}
                        renderResource={renderResource}
                    />
                </div>
                <EmployeeSkillsModal 
                    open={openSkillsModal}
                    onCancel={handleCloseSkillsModal}
                    trade={trade}
                />
            </DraggableModal>
            {messageContextHolder}
        </>
    );
}

export function getStatusTag(props?: IShiftTradeModalProps, statusList?: IStatus[]) {

    if (!props || !statusList) {
        return;
    }
    
    const tagColorConfig = statusList.find(item => props.statusId === item.id);

    if (tagColorConfig) {

        const textColor       = colorToStyle(tagColorConfig.textColor);
        const backgroundColor = colorToStyle(tagColorConfig.backgroundColor);
        const borderColor     = colorToStyle(tagColorConfig.borderColor);

        return (
            <Tag 
                style={{
                    color: textColor, 
                    background: backgroundColor,
                    borderColor: borderColor
                }}
            >
                {props.status}
            </Tag>
        );
    }

    return (
        <Tag
            color="volcano"
        >
            {props.status}
        </Tag>
    );
}