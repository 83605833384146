import { MbscCalendarColor, MbscCalendarEvent, MbscCalendarEventData, MbscEventClickEvent, MbscEventCreateEvent, MbscEventCreatedEvent, MbscEventDeletedEvent, MbscEventUpdateEvent, MbscEventUpdatedEvent, MbscEventcalendarView, MbscLocale, MbscResource, Eventcalendar, localeRu as mbLocaleRu } from "../../shared/lib";
import { IScheduleWorkTime, ISurfaceBreak, TBreak } from "../../../Entities/Database";
import { useMemo, useRef } from "react";
import { defaultDate, lastDate, timeFormat } from "../ScheduleTemplateWorkTimeList/helpers";
import moment from "moment";
import { IBreakEvent, ICustomBreakRange, convertBreaks, convertDiffToMinutes, detectCollision, resources, workTimeResourceId } from "./helpers";
import { classNames } from "../../../System/ClassNames";
import { FiveTime } from "../../../System/Type/Time";
import { addMinMax } from "../../shared/libHelpers";
import { IValidateProps } from "../../shared/lib/src/core/util/datetime";
import './style.css';

export interface ICustomBreaksListEditorLocale {
    editorLocale: MbscLocale,
    workTime: string,
    weekDaysShortName: string[],
    weekShort: string,
    week: string,
    shortDay: string,
}

export const localeRu: ICustomBreaksListEditorLocale = {
    editorLocale: mbLocaleRu,
    workTime: 'Рабочее время',
    weekDaysShortName: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
    weekShort: 'Нд', 
    week: 'Неделя',
    shortDay: 'Д',
}

export interface ICustomBreaksListEditorProps {
    locale?: ICustomBreaksListEditorLocale,
    selectedBreak?: TBreak,
    surfaceBreaks?: ISurfaceBreak[],
    breaks?: TBreak[],
    loading?: boolean,
    workTime?: IScheduleWorkTime,
    onCreate?: (range: ICustomBreakRange) => void,
    onChange?: (breakItem: TBreak, range: ICustomBreakRange) => void,
    onDelete?: (breakItem: TBreak) => void,
    onSelect?: (breakItem: TBreak | undefined) => void,
    onDoubleClick?: (breakItem: TBreak) => void,
}

export function CustomBreaksListEditor({
    locale = localeRu,
    selectedBreak,
    surfaceBreaks,
    breaks,
    loading,
    workTime,
    onCreate,
    onChange,
    onDelete,
    onSelect,
    onDoubleClick,
}: ICustomBreaksListEditorProps) {

    const editorRef = useRef<Eventcalendar>(null);

    const timeStep = 60;
    const dragTimeStep = 5;

    const view = useMemo<MbscEventcalendarView>(() => ({
        timeline: {
            size: 2,
            type: 'day',
            startDay: 1,
            endDay: 0,
            timeCellStep: timeStep,
            timeLabelStep: timeStep,
            resolutionVertical: 'day',
            startTime: '00:00',
            endTime: '00:00',
        }
    }), [timeStep]);

    const [min, max, selectedDate, workTimeStart, colors] = useMemo((): [
        Date | undefined,
        Date | undefined,
        Date | undefined,
        Date | undefined,
        MbscCalendarColor[] | undefined
    ] => {

        if (!workTime) {
            return [undefined, undefined, undefined, undefined, undefined];
        }

        const hhmmss = moment(workTime.start, timeFormat);

        hhmmss
            .year(defaultDate.getFullYear())
            .month(defaultDate.getMonth())
            .date(defaultDate.getDate());

        const min = hhmmss.clone().add(workTime.leftOffset, 'minutes').toDate();
        const max = hhmmss.clone().add(workTime.durn, 'minutes').add(-workTime.rightOffset, 'minutes').toDate();

        const workTimeStart = hhmmss.clone().toDate();
        const workTimeEnd   = hhmmss.clone().add(workTime.durn, 'minutes').toDate();

        const colors: MbscCalendarColor[] = [
            {
                resource: workTimeResourceId,
                start: defaultDate,
                end: workTimeStart,
                cssClass: 'ap-work-time-free',
            },
            {
                resource: workTimeResourceId,
                start: workTimeEnd,
                end: lastDate,
                cssClass: 'ap-work-time-free',
            },
        ];

        // TODO: кейсы когда left / right offset выходят за рамки доступного
        if (min > max) {

            colors.push({
                resource: workTimeResourceId,
                start: workTimeStart,
                end: workTimeEnd,
                cssClass: 'ap-work-time-event-offset-zone',
            });
        }
        else {

            colors.push(
                {
                    resource: workTimeResourceId,
                    start: workTimeStart,
                    end: min,
                    cssClass: 'ap-work-time-event-offset-zone',
                },
                {
                    resource: workTimeResourceId,
                    start: min,
                    end: max,
                    cssClass: 'ap-work-time-event-avalable-zone',
                },
                {
                    resource: workTimeResourceId,
                    start: max,
                    end: workTimeEnd,
                    cssClass: 'ap-work-time-event-offset-zone',
                },
            );
        }

        const selectedDate = new Date(+workTimeStart - (60 * 60 * 1000));

        return [min, max, selectedDate, workTimeStart, colors];

    }, [workTime]);

    const events = useMemo(() => convertBreaks(locale, breaks, surfaceBreaks, workTime, selectedBreak), [locale, surfaceBreaks, workTime, breaks, selectedBreak]);

    const handleEventClick = (event: MbscEventClickEvent) => {

        if (onSelect) {
            const breakItem = (event.event as IBreakEvent).originalBreak;
            onSelect(breakItem);
        }
    }

    const handleEventDoubleClick = (event: MbscEventClickEvent) => {

        if (onDoubleClick) {
            const breakItem = (event.event as IBreakEvent).originalBreak;
            onDoubleClick(breakItem);
        }
    }

    const allowAction = (event: MbscCalendarEvent) => {

        if (max && min &&
            event.start && event.start >= min &&
            event.end   && event.end <= max) {

            const filteredEvents = events.filter(x => x.id !== event.id);

            if (detectCollision(event, filteredEvents) === false) {
                return true;
            }
        }

        return false;
    }

    const handleEventUpdate = (event: MbscEventUpdateEvent): boolean => {
        const updateEvent = event.event;
        return allowAction(updateEvent);
    }

    const handleEventCreate = (event: MbscEventCreateEvent): boolean => {
        const createEvent = event.event;
        return allowAction(createEvent);
    }

    const handleEventCreated = (event: MbscEventCreatedEvent) => {

        const createdEvent = event.event;

        if (onCreate && workTimeStart && createdEvent.start && createdEvent.end) {

            const range: ICustomBreakRange = {
                start: moment(workTimeStart).format(timeFormat) as FiveTime,
                leftOffset: convertDiffToMinutes(workTimeStart, createdEvent.start as Date),
                rightOffset: convertDiffToMinutes(workTimeStart, createdEvent.end as Date),
            };

            onCreate(range);
        }
    }

    const handleEventUpdated = (event: MbscEventUpdatedEvent) => {

        const updatedEvent = event.event;

        if (onChange && workTimeStart && updatedEvent.start && updatedEvent.end) {
            const breakItem = (event.event as IBreakEvent).originalBreak;
            const range: ICustomBreakRange = {
                start: moment(workTimeStart).format(timeFormat) as FiveTime,
                leftOffset: convertDiffToMinutes(workTimeStart, updatedEvent.start as Date),
                rightOffset: convertDiffToMinutes(workTimeStart, updatedEvent.end as Date),
            };
            onChange(breakItem, range);
        }
    }

    const handleEventDeleted = (event: MbscEventDeletedEvent) => {

        const deletedEvent = event.event;

        if (onDelete) {
            const breakItem = (deletedEvent as IBreakEvent).originalBreak;
            onDelete(breakItem);
        }
    }

    const eventRender = (data: MbscCalendarEventData) => {

        const title = data.title;
        const start = data.start;
        const end   = data.end;
        const zoneDuration = moment(data.endDate).diff(data.startDate, 'minutes');
        const originalBreakDuration = (data.original as IBreakEvent).originalBreak?.durn || 5;
        const width = (100 * (originalBreakDuration / zoneDuration)) + '%';
        const isStatic = +data.startDate + (originalBreakDuration * 60 * 1000) === +data.endDate;
        const rangePrefix = isStatic ? 'c' : 'между';
        const tooltip = `${title}, продолжительность ${originalBreakDuration} мин, ${rangePrefix} ${start} - ${end}`;

        return (
            <div title={tooltip}>
                <div className="mbsc-schedule-event-background mbsc-timeline-event-background mbsc-ios"></div>
                <div aria-hidden="true" className={classNames("ap-work-time-break-background", {"static": isStatic})} style={{ width: width }}></div>
                <div aria-hidden="true" className="mbsc-schedule-event-inner mbsc-ios">
                    <div className="mbsc-schedule-event-title mbsc-ios">{title}</div>
                    <div className="mbsc-schedule-event-range mbsc-ios">{originalBreakDuration} мин, {rangePrefix} {start} - {end}</div>
                </div>
            </div>
        );
    }

    const normalizeInvalids = useMemo(() => {
        const tmpInvalids: IValidateProps[] = [];
        addMinMax(tmpInvalids, min, max);
        return tmpInvalids;
    }, [min, max]);

    return (
        <Eventcalendar
            theme="ios"
            themeVariant="light"
            selectMultipleEvents={false}
            scaleOnHeaderPinch={false}
            showControls={false}
            showOuterDays={false}
            clickToCreate={true}
            dragToMove={true}
            eventDelete={true}
            dragToResize={true}
            dragToCreate={true}
            ref={editorRef}
            refDate={defaultDate}
            selectedDate={selectedDate}
            locale={locale.editorLocale}
            view={view}
            resources={resources}
            colors={colors}
            invalid={normalizeInvalids}
            data={events}
            //selectedEvents={selectedEvents}
            wrapperClass="ap-custom-breaks-list-editor-wrapper"
            className="ap-custom-breaks-list-editor"
            newEventText="Новый перерыв"
            width="100%"
            dragTimeStep={dragTimeStep}
            renderScheduleEvent={eventRender}
            onEventClick={handleEventClick}
            onEventDoubleClick={handleEventDoubleClick}
            onEventCreate={handleEventCreate}
            onEventUpdate={handleEventUpdate}
            onEventCreated={handleEventCreated}
            onEventUpdated={handleEventUpdated}
            onEventDeleted={handleEventDeleted}
        />
    );
}