import "./style.css"

export function Footer() {
    return (
        <div className="footer__wrap">
            <p className="footer__left-part">© {new Date().getFullYear()} Астра пейдж, Все права защищены.</p>
            {/* <div className="footer__divider">|</div>
            <a href="" className="footer__right-part">Политика конфидециальности</a> */}
        </div>
    )
}