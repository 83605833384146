import { NullOrUndefined } from "./Utils";

export type AbortSignalCollection = (AbortSignal | NullOrUndefined)[];
export type Action<TResult> = (signal: AbortSignal) => Promise<TResult>;

export async function doOrAbort<TResult>(
    action: Action<TResult>,
    ...signals: AbortSignalCollection
) {

    const abortController = new AbortController();
    const abortListener = function (this: AbortSignal, event: Event) {
        abortController.abort(this.reason);
    }

    try {

        if (signals) {

            for (let signal of signals) {
                if (signal) {
                    signal.addEventListener('abort', abortListener, {once: true});
                }
            }
        }
        
        return await action(abortController.signal);
    }
    finally {

        if (signals) {
            
            for(let signal of signals) {
                if (signal) {
                    signal.removeEventListener('abort', abortListener);
                }
            }
        }
    }
}