import { Progress } from "antd";
import { IScheduleWorkTime } from "../../../Entities/Database";
import { Paid } from "../BreakModalEditor";

import "./progress.css";

export interface IProgressShiftBreaksProps{
    workTime: IScheduleWorkTime,
    paidBreak?: Paid,
}

export function ProgressShiftBreaks({
    workTime,
    paidBreak,
}: IProgressShiftBreaksProps) {

    const breakNorm = paidBreak === Paid.Paid ? workTime.brNormPayed : workTime.brNormUnPayed;
    const breakCurrent = paidBreak === Paid.Paid ? workTime.brPayed : workTime.brUnPayed;
    const title = paidBreak === Paid.Paid ? "Оплачиваемый перерыв" : "Не оплачиваемый перерыв";

    return (
        <div className="progressBreak__wrap">
            <p className="progressBreak__title">{title}</p>
            <div className="progressBreak__inner-wrap">
                <Progress
                    className="progressBreak__progress"
                    showInfo={false}
                    percent={100 * (breakCurrent / breakNorm)}
                />
                <span className="progressBreak__details">
                    {breakCurrent}/{breakNorm}
                </span>
            </div>   
        </div>
    )
}