import { useForm } from "antd/lib/form/Form";
import { IScheduleBoardEmployee, scheduleProlong, scheduleShrink } from "../../Entities/Database";
import { ILoadListState } from "../../Reducer/LoadListReducer";
import { DraggableModal, IDraggableModalProps } from "../DraggableModal";
import { ItemType } from "../ScheduleBoard/CellContextMenu/helpers";
import moment, { isMoment } from "moment";
import { Checkbox, Form, FormProps, Input, Select, Space, message } from "antd";
import { dictionaryToOptions } from "../shared/helpers";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { nameof } from "../../System/Utils";
import { useBuffer } from "../../Hooks/useBuffer";
import TimePicker from "../shared/TimePicker";
import useAuth from "../../Hooks/useAuth";
import { flushSync } from "react-dom";
import { searchInValue } from "../../Reducer/SearchFilterReducer";

const dateKeyFormat = "DD.MM.YYYY";

export interface IProlongValues {
    employees: IScheduleBoardEmployee['PID'][],
    start: moment.Moment,
    end: moment.Moment,
    comment: string,
    isAgentRequest: boolean,
    sideJob: boolean,
}

export interface IScheduleProlongModalProps extends IDraggableModalProps {
    item?: ItemType,
    employees: ILoadListState<IScheduleBoardEmployee>,
}

export function ScheduleProlongModal({
    employees,
    item,
    open,
    onOk,
    onCancel,
    ...otherProps
}: IScheduleProlongModalProps) {

    const auth = useAuth();
    const [form] = useForm<IProlongValues>();
    const [saving, setSaving] = useState(false);

    const employeeOptions = useBuffer(
        () => dictionaryToOptions(employees, 'PID', (employee) => `${employee.surname} ${employee.firstName}`),
        [employees.items],
        open === true
    );

    const multi = isMoment(item);
    const title = `Продление смены: ${(multi ? item : item?.date)?.locale('ru').format(dateKeyFormat)}`;

    const handleChangeValues = useCallback((changedValues: any, values: Partial<IProlongValues>) => {

        if(values.start && values.end && values.start.valueOf() > values.end.valueOf()) {
            form.setFieldValue(nameof<IProlongValues>('end'), undefined);
        }

    }, []);

    const onOkEventRef = useRef<React.MouseEvent<HTMLButtonElement, MouseEvent>>();
    const onOkRef = useRef(onOk);
    onOkRef.current = onOk;

    const handleOk = useCallback<NonNullable<IDraggableModalProps['onOk']>>((e) => {
        onOkEventRef.current = e;
        form.submit();
    }, [form]);

    const handleFinish = useCallback(async (values: IProlongValues) => {

        if(!item) {
            message.error("Не выбран объект сокращения");
            return;
        }

        try {

            flushSync(() => {
                setSaving(true);
            });

            const date = isMoment(item) ? item : item.date;

            const normalizeStart = date.clone()
                .startOf('day')
                .hours(values.start.hours())
                .minutes(values.start.minutes());
            
            const normalizeEnd = date.clone()
                .startOf('day')
                .hours(values.end.hours())
                .minutes(values.end.minutes());
            
            const duration = normalizeEnd.diff(normalizeStart, 'minutes');

            await scheduleProlong(auth, {
                employees: values.employees,
                start: normalizeStart,
                duration: duration,
                comment: values.comment,
                isAgentRequest: values.isAgentRequest || false,
                sideJob: values.sideJob,
            });

            const e = onOkEventRef.current!;
            const onOk = onOkRef.current;
    
            if (onOk) {
                onOk(e);
            }
        }
        catch (ex) {
            message.error('Не удалось выполнить продление смены');
            console.error(ex);
        }
        finally {
            setSaving(false);
        }

    }, [auth.data?.staticId, item, form]);

    useEffect(() => {

        if (open) {

            const employeeFieldValue = multi ? [] : [item?.PID];

            form.resetFields();
            form.setFieldValue(nameof<IProlongValues>('employees'), employeeFieldValue);
        }

    }, [open]);

    return (
        <DraggableModal
            {...otherProps}
            width={390}
            destroyOnClose
            title={title}
            open={open}
            onOk={handleOk}
            onCancel={onCancel}
            okButtonProps={{
                disabled: !employees.load || saving,
                loading: saving
            }}
            cancelButtonProps={{
                disabled: saving
            }}
        >
            <Form
                form={form}
                onValuesChange={handleChangeValues}
                onFinish={handleFinish}
                initialValues={{
                    sideJob: true,
                }}
            >
                <Form.Item
                    name={nameof<IProlongValues>('employees')}
                    label={multi ? 'Сотрудники' : 'Сотрудник'}
                    rules={[{ required: true, message: 'Выберите сотрудника' }]}
                >
                    <Select
                        loading={employees.loading}
                        mode="multiple"
                        disabled={saving || !multi}
                        filterOption={(inputValue, option) => searchInValue(option?.children, inputValue)}
                    >
                        {employeeOptions}
                    </Select>
                </Form.Item>
                <Space
                    style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        alignContent: 'center',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                    }}
                >
                    <Form.Item
                        name={nameof<IProlongValues>('start')}
                        label={'С'}
                        rules={[{ required: true, message: 'Выберите начало интервала продления смены' }]}
                    >
                        <TimePicker
                            disabled={saving}
                            format={"HH:mm"}
                            minuteStep={5}
                            needConfirm={false}
                        />
                    </Form.Item>
                    <Form.Item
                        name={nameof<IProlongValues>('end')}
                        label={'По'}
                        rules={[{ required: true, message: 'Выберите окончание интервала продления смены' }]}
                    >
                        <TimePicker
                            disabled={saving}
                            format={"HH:mm"}
                            minuteStep={5}
                            needConfirm={false}
                        />
                    </Form.Item>
                </Space>
                <Form.Item
                    hidden={multi}
                    name={nameof<IProlongValues>('isAgentRequest')}
                    label={'По инициативе оператора'}
                    valuePropName="checked"
                >
                    <Checkbox
                        disabled={saving}
                    />
                </Form.Item>
                <Form.Item
                    hidden={multi}
                    name={nameof<IProlongValues>('sideJob')}
                    label={'Подработка'}
                    valuePropName="checked"
                >
                    <Checkbox
                        disabled={saving}
                    />
                </Form.Item>
                <Form.Item
                    name={nameof<IProlongValues>('comment')}
                    label={'Комментарий'}
                    rules={[{
                        required: true,
                        min: 3,
                        max: 4072,
                        message: 'Укажите комментарий'
                    }]}
                >
                    <Input.TextArea
                        disabled={saving}
                        placeholder="Комментарий"
                        autoSize={{ minRows: 3, maxRows: 8 }}
                        minLength={3}
                        maxLength={4072}
                    />
                </Form.Item>
            </Form>
        </DraggableModal>
    );
}

export default ScheduleProlongModal