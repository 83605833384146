import { useEffect, useRef, useState, useTransition } from "react";
import useAuth from "../../Hooks/useAuth";
import { AuthWrap } from "./AuthWrap";
import { Intro } from "./Intro";
import { classNames } from "../../System/ClassNames";
import "./style.css"

export function AuthWindow() {
    
    const auth = useAuth();
    const [hidden, setHidden] = useState(false);
    const [animation, setAnimation] = useState(false);
    const hiddenTimeoutRef = useRef<number | NodeJS.Timeout | null>();
   
    useEffect(() => {

        if (hiddenTimeoutRef.current) {
            clearTimeout(hiddenTimeoutRef.current);
            hiddenTimeoutRef.current = null;
        }

        if (auth.isLogout) {
            setHidden(false);
            setAnimation(false);
            return;
        }

        setHidden(false);
        setAnimation(true);

        hiddenTimeoutRef.current = setTimeout(() => {
            setHidden(true);
        }, 400);

    }, [auth.isLogout])
    
    if (hidden) {
        return null;
    }

    return (
        <div className="auth__hidden-wrap">
            <div className={classNames("auth", {
                "animation": animation
            })}>
                <AuthWrap />
                {/* <Intro /> */}
            </div>
        </div>
    );
}

export default AuthWindow