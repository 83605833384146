import { Input, Typography, Modal } from "antd";
import { NotSupportedException } from "../../System/NotSupportedException";
import { isAbortError, isCancel, isError, isNullOrUndefined } from "../../System/Utils";

const { Text } = Typography;
const { TextArea } = Input;

export const lkUrls = ["https://lk.astrapage.ru", "http://lk"];
export const defaultUrlIndex = 0; 

export enum LKEventType {
    updateToken = "updateToken",
    getActualToken = "getActualToken"
}

export interface ILKCmd {
    eventType?: LKEventType,
    page_id?: string | number
}

export interface ILKCmdWithToken extends ILKCmd {
    token?: string
}

export function notificationError(ex: unknown) {
    
    let detail = isError(ex) ?
                ex.stack :
                JSON.stringify(ex);

    return Modal.error({
        title: `Ошибка авторизации`,
        content: (
            <>
                <Text>Попробуйте перезагрузить страницу</Text>
                <TextArea value={`Детали ошибки: ${detail}`} />
            </>
        )
    });
}

export interface IEventData {
    [key: string]: any,
    eventType: LKEventType,
}

export function dataOrNull(data: any) {

    if (!(data.eventType in LKEventType)) {
        return null;
    }

    return data;
}

export function normalizeEventData(ev: MessageEvent<any>): IEventData | null {

    if (typeof ev.data === 'object' && ev.data !== null) {
        return dataOrNull(ev.data);
    }
    
    if (typeof ev.data === 'string') {

        try {
            const data = JSON.parse(ev.data);
            return dataOrNull(data);
        }
        catch {}
    }

    return null;
}

export function sendLKCmd<TCmd extends ILKCmd >(cmd: TCmd) {

    if (isNullOrUndefined(window.parent)) {

        throw new NotSupportedException("Приложение запущено в собственном окне");
    }

    const normalizeCmd = JSON.stringify(cmd);
    
    try
    {
        if (window.parent.location.origin === window.location.origin) {
            window.parent.postMessage(normalizeCmd, window.location.origin);
            return;
        }

        for(let url of lkUrls) {

            if (url === window.parent.location.origin) {
                
                window.parent.postMessage(normalizeCmd, url);
                return;
            }
        }
    }
    catch {}

    window.parent.postMessage(normalizeCmd, lkUrls[defaultUrlIndex]);
}

export function exAndSignalAborted(ex: unknown, signal?: AbortSignal) {
    return signal
        && signal.aborted
        && (isAbortError(ex) || isCancel(ex));
}