import { useRef } from "react";
import { EmployeeContactType } from "../../../Entities/Database";
import { Tooltip } from "antd";
import { classNames } from "../../../System/ClassNames";
import { getHref, getImgSrc } from "./helper";
import "./style.css";


export interface IContactItemProps {
    type: EmployeeContactType,
    url: string[]
}

export function ContactItem({type, url}: IContactItemProps) {
    
    const imageSrc = getImgSrc(type);
    
    const href = getHref(type, url[0]);

    return (
        <li className="personal__contact-item">  
            <a 
                href={href} 
                target="_blank"
                className={classNames({"contactItem__tooltip-link": url.length > 1} )}
            >
                {
                    url.length > 1 && 
                    <>
                        <Tooltip 
                            title={
                                <div className="contactItem__tooltip-wrap">
                                    {
                                        url.map((item, idx) => {
                                            const href = getHref(type, item);
                                            return <a href={href} target="_blank" key={idx}>{item}</a>
                                        })
                                    }
                                </div>
                            } 
                        >
                            <img src={imageSrc} alt={type} />
                        </Tooltip> 
                        <div className="contactItem__tooltip-add">{`+${url.length-1}`}</div>
                    </>
                    
                } 
                {
                    url.length === 1 &&
                    <Tooltip title={<a style={{color: "#fff"}} href={href} target="_blank">{url}</a>} >
                        <img src={imageSrc} alt={type}/>
                    </Tooltip>
                }
                
            </a> 
        </li>
    )
}
