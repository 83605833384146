import { Ref, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { IEmployee, IScheduleShiftScheme, IWorkTimeFragment, RotationType, clearScheduleShiftSchemeBuffer, getScheduleShiftScheme, saveScheduleShiftScheme, saveScheduleShiftSchemeToBuffer } from "../../../Entities/Database";
import { ScheduleShiftMenu } from "../ScheduleShiftMenu";
import { CreateScheduleShiftSchemeModal } from "../CreateScheduleShiftSchemeModal";
import { Modal, Spin, message as AntdMessage } from "antd";
import ScheduleShiftSchemeEditor from "../ScheduleShiftSchemeEditor";
import useAuth from "../../../Hooks/useAuth";
import { ExclamationCircleFilled, LoadingOutlined } from "@ant-design/icons";
import { classNames } from "../../../System/ClassNames";
import { isError } from "../../../System/Utils";
import { InvalidDataException } from "../../../System/InvalidDataException";
import { BackendResponseException } from "../../../System/BackendResponseException";
import { AssignEmployeeScheduleShiftModal } from "../../EmployeeCard/AssignEmployeeScheduleShiftModal";
import "./style.css";
import useActual from "../../../Hooks/useActual";

const abortByChange = {};
const abortByClose = {};
const duplicatedSchemesErrorId = 110007;

export interface Tmp {
    sss_id_target: number,
}

export interface IScheduleShiftSchemeRef {
    handleClone: () => void,
    handleCancel: () => void,
    handleDelete: () => Promise<void>,
    handleReset: () => Promise<void>,
    handleSave: () => Promise<void>,
}

interface IScheduleShiftSchemeWrapProps {
    employee?: IEmployee,
    width: number,
    open: boolean,
    loading: boolean,
    defaultScheme?: IScheduleShiftScheme | IScheduleShiftScheme['sss_id'],
    onFinishBind?: () => void,
    onLoading: (x: boolean) => void,
    onCancel?: () => void,
}

function ScheduleShiftSchemeWrap({
    employee,
    width,
    open,
    loading,
    defaultScheme,
    onFinishBind,
    onLoading,
    onCancel,
}: IScheduleShiftSchemeWrapProps, 
    ref: Ref<IScheduleShiftSchemeRef>
) {
    const auth = useAuth();
    const [modal, modalContextHolder] = Modal.useModal();
    const [message, messageContextHolder] = AntdMessage.useMessage();
    const [bindEmployeeOpen, setBindEmployeeOpen] = useState(false);
    const [activeCard, setActiveCard] = useState<IScheduleShiftScheme | undefined>();
    const [openCreateScheduleShiftModal, setOpenCreateScheduleShiftModal] = useState(false);
    const [isClone, setIsClone] = useState(false);
    const [hasChanges, setHasChanges] = useState(false);
    const [loadingFragments, setLoadingFragments] = useState(false);
    const [fragments, setFragments] = useState<IWorkTimeFragment[]>([]);
    const [newScheme, setNewScheme] = useState<Partial<IScheduleShiftScheme> | undefined>();
    const [shiftSchemeList, setShiftSchemeList] = useState<readonly IScheduleShiftScheme[]>([]);

    const handleBindEmployeeBtnClick = () => {
        
        if (activeCard?.canAssign) {
            setBindEmployeeOpen(true);
        } else {
            message.error("К этому шаблону нельзя привязать сотрудника, сначала сохраните изменения.");
        }
    }

    const handleBindEmployeeCancel = () => {
        setBindEmployeeOpen(false);
    }

    const onFinishBindActualRef = useActual(onFinishBind);
    const handleFinishBindEmployee = () => {

        message.success("Шаблон успешно назначен сотруднику");

        setBindEmployeeOpen(false);
        setShiftSchemeList(shiftSchemeList => {

            const newShiftSchemeList = [...shiftSchemeList];
            const index = shiftSchemeList.findIndex(shiftScheme => shiftScheme.sss_id === activeCard?.sss_id);

            if (index !== -1) {
                const prev = newShiftSchemeList[index];
                newShiftSchemeList[index] = {
                    ...prev,
                    staffCount: prev.staffCount + 1,
                };
            }

            return newShiftSchemeList;
        });
        
        setActiveCard(activeCard => {

            if (!activeCard) {
                return activeCard;
            } 

            return {
                ...activeCard,
                staffCount: activeCard.staffCount + 1,
            }
        });

        if (onFinishBindActualRef.current) {
            onFinishBindActualRef.current();
        }
    }

    const handleOpenCreateScheduleShiftModal = () => {
        setIsClone(false);
        setOpenCreateScheduleShiftModal(true);
    }

    const handleCancelCreateScheduleShiftModal = () => {
        setOpenCreateScheduleShiftModal(false);
    }

    const handleCardChange = (card: IScheduleShiftScheme) => {

        if (hasChanges) {

            modal.confirm({
                title: 'Вы уверены, что хотите закрыть карточку без сохранения ?',
                icon: <ExclamationCircleFilled />,
                content: 'Изменения шаблона будут удалены.',
                onOk() {
                    setActiveCard(card);
                },
                onCancel() {},
            });
        }
        else {
            setActiveCard(card);
        }
    }

    const handleClone = () => {

        if (!hasChanges) {
            setIsClone(true);
            setOpenCreateScheduleShiftModal(true);
        } else {
            message.warning("Перед клонированием, сохраните или сбросьте изменения.");
        }
    }

    const handleReset = async () => {
        
        await modal.confirm({
            title: 'Вы уверены, что хотите сбросить изменения ?',
            icon: <ExclamationCircleFilled />,
            content: 'Все изменения шаблона будут сброшены до последнего сохраненного состояния.',
            onOk() {
                return new Promise(async (resolve, reject) => {
                    try {
                        await clearBuffer();
                        await loadScheduleShiftScheme();
                        resolve(true);
                    } catch (ex) {
                        reject(ex);
                    }
                });
            },
            onCancel() {},
        });
    }

    const handleDelete = () => {

        return new Promise<void>((resolve, reject) => {
            resolve();
        });
    }

    const handleCancel = () => {

        if (hasChanges) {

            Modal.confirm({
                title: 'Вы уверены, что хотите выйти ?',
                icon: <ExclamationCircleFilled />,
                content: 'Изменения шаблона не будут сохранены.',
                onOk() {
                    if (onCancel) {
                        onCancel();
                    }
                },
                onCancel() {},
            });
        }
        else if (onCancel) {
            onCancel();
        }
    }

    const handleSave = async () => {

        if (!activeCard) {
            throw new InvalidDataException("Не выбрана схема");
        }

        try {

            const actualScheme: IScheduleShiftScheme = {
                ...activeCard,
                title: newScheme?.title ?? activeCard.title,
                sss_WDType_ID: newScheme?.sss_WDType_ID ?? activeCard.sss_WDType_ID,
            };

            const updatedScheme = await saveScheduleShiftScheme(auth, actualScheme);
            
            setHasChanges(false);
            setShiftSchemeList(shiftSchemeList => {

                const newShiftSchemeList = [...shiftSchemeList];
                const index = shiftSchemeList.findIndex(shiftScheme => shiftScheme.sss_id === updatedScheme.sss_id);

                if (index !== -1) {
                    newShiftSchemeList[index] = {
                        ...newShiftSchemeList[index],
                        ...updatedScheme,
                    };
                }

                return newShiftSchemeList;
            });
            
            setActiveCard(activeCard => {

                if (activeCard?.sss_id === updatedScheme.sss_id) {

                    return {
                        ...activeCard,
                        ...updatedScheme,
                    }
                }

                return activeCard;
            });

            setNewScheme(newScheme => {

                if (newScheme?.sss_id === updatedScheme.sss_id) {

                    return {
                        ...newScheme,
                        ...updatedScheme,
                    }
                }

                return newScheme;
            });
        }
        catch (ex) {

            if (ex instanceof BackendResponseException &&
                ex.GetId() === duplicatedSchemesErrorId) {

                const response = ex.GetResponse();
                const data = response?.data[0] as Tmp;

                modal.confirm({
                    title: 'Обнаружен дубликат шаблона.',
                    icon: <ExclamationCircleFilled />,
                    content: 'Нельзя создавать одинаковые шаблоны, хотите перейти в оригинальный шаблон ?',
                    onOk() {
                        const idx = shiftSchemeList.findIndex(x => x.sss_id === data.sss_id_target);
                        if (idx !== -1) {
                            setActiveCard(shiftSchemeList[idx]);
                        }
                    },
                    onCancel() {},
                });

                return;
            }

            throw ex;
        }
    }

    useImperativeHandle(ref, () => ({
        handleClone,
        handleDelete,
        handleReset,
        handleCancel,
        handleSave,
    }));

    async function saveChangesToBuffer(fragments: IWorkTimeFragment[], signal?: AbortSignal) {

        try {

            if (!activeCard) {
                throw new InvalidDataException("Не выбрана схема");
            }

            setHasChanges(true);
            setFragments(fragments);
            await saveScheduleShiftSchemeToBuffer(auth, activeCard, fragments, signal);
        }
        catch (ex) {

            if (ex === abortByChange) {
                return;
            }

            message.error(isError(ex) ? ex.message : "Не удалось сохранить данные в буффер.");
            console.error(ex);
        }
    }

    async function clearBuffer() {
        await clearScheduleShiftSchemeBuffer(auth);
        setHasChanges(false);
    }

    async function loadScheduleShiftScheme(signal?: AbortSignal) {

        try {

            if (!activeCard) {
                throw new InvalidDataException("Не выбрана схема");
            }

            setLoadingFragments(true);
            setFragments([]);
            const fragments = await getScheduleShiftScheme(auth, activeCard.sss_id, signal);
            setFragments(fragments);
            setLoadingFragments(false);
            setHasChanges(false);
        }
        catch (ex) {

            if (ex === abortByChange) {
                return;
            }

            setLoadingFragments(false);

            console.error(ex);
            message.error("Не удалось загрузить данные карточки.");
        }
    }

    const handleChangeFragments = (fragments: IWorkTimeFragment[]) => {
        saveChangesToBuffer(fragments);
    }

    const handleChangeSchemeFields = (names: (keyof IScheduleShiftScheme)[], newScheme: IScheduleShiftScheme) => {

        setNewScheme(x => ({
            ...x,
            ...newScheme,
        }));
    }

    const handleFinishSave = (newScheme: IScheduleShiftScheme) => {

        setIsClone(false);
        setFragments([]);
        setOpenCreateScheduleShiftModal(false);
        setShiftSchemeList(shiftSchemeList => {

            const newShiftSchemeList = [...shiftSchemeList];
            const index = shiftSchemeList.findIndex(shiftScheme => shiftScheme.sss_id === newScheme.sss_id);

            if (index !== -1) {
                newShiftSchemeList[index] = {
                    ...newShiftSchemeList[index],
                    ...newScheme,
                };
            }
            else {
                newShiftSchemeList.unshift(newScheme);
            }

            return newShiftSchemeList;
        });

        setActiveCard(activeCard => {

            if (activeCard?.sss_id === newScheme.sss_id) {
                
                return {
                    ...activeCard,
                    ...newScheme,
                }
            }

            return newScheme;
        });

        setNewScheme(prevNewScheme => {

            if (prevNewScheme?.sss_id === newScheme.sss_id) {
                
                return {
                    ...prevNewScheme,
                    ...newScheme,
                }
            }

            return newScheme;
        });
    }

    useEffect(() => {

        setNewScheme(activeCard);

        if (activeCard) {
            const abortController = new AbortController();
            loadScheduleShiftScheme(abortController.signal);
            return () => {
                abortController.abort(abortByChange);
            }
        }

    }, [activeCard?.sss_id]);

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            flexWrap: 'nowrap',
            overflow: 'hidden',
            position: 'relative',
            height: `calc(100vh - 160px)`,
        }}>
            <ScheduleShiftMenu
                width={235}
                defaultScheme={defaultScheme}
                activeCard={activeCard}
                onCardChange={handleCardChange}
                openModal={handleOpenCreateScheduleShiftModal}
                open={open}
                loading={loading}
                handleLoading={onLoading}
                shiftSchemeList={shiftSchemeList}
                setShiftSchemeList={setShiftSchemeList}
            />
            {activeCard && <CreateScheduleShiftSchemeModal 
                isModalOpen={openCreateScheduleShiftModal}
                handleCancel={handleCancelCreateScheduleShiftModal}
                isClone={isClone}
                scheme={activeCard}
                onFinishSave={handleFinishSave}
            />}
            <div
                style={{
                    position: 'relative',
                    overflow: 'auto',
                    width: 'calc(100% - 250px)',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div
                    className={classNames({
                        'ap-schedule-shift-scheme-editor-preloader': true,
                        'ap-hidden': !loadingFragments
                    })}
                >
                    <Spin
                        indicator={
                            <LoadingOutlined
                                spin
                                style={{
                                    fontSize: 24,
                                    margin: 'auto',
                                    display: 'block'
                                }}
                            />
                        }
                    />
                </div>
                <ScheduleShiftSchemeEditor
                    //width={editorWidth}
                    loading={loadingFragments || loading}
                    shiftScheme={activeCard}
                    newShiftScheme={newScheme}
                    fragments={fragments}
                    onChangeSchemeFields={handleChangeSchemeFields}
                    onChangeFragments={handleChangeFragments}
                    onBindEmployeeBtnClick={handleBindEmployeeBtnClick}
                />
                {activeCard && <AssignEmployeeScheduleShiftModal
                    employeePID={employee?.PID}
                    isOpen={bindEmployeeOpen}
                    onCancel={handleBindEmployeeCancel}
                    onFinishSave={handleFinishBindEmployee}
                    scheme={activeCard}
                />}
            </div>
            {modalContextHolder}
            {messageContextHolder}
        </div>
    )
}

export default forwardRef(ScheduleShiftSchemeWrap);