import { Button, message } from "antd";
import { ScheduleShiftListTemplate } from "../ScheduleShiftListTemplate";
import { CSSProperties, Ref, useEffect, useRef, useState } from "react";
import useAuth from "../../../Hooks/useAuth";
import { IScheduleShiftScheme, getScheduleShiftSchemeList } from "../../../Entities/Database";
import { filterList } from "../ScheduleShiftListTemplate/helper";
import useActual from "../../../Hooks/useActual";

const abortByClose = {};
const abortByChange = {};

interface IScheduleShiftListProps {
    defaultScheme?: IScheduleShiftScheme | IScheduleShiftScheme['sss_id'],
    searchString: string,
    activeCard?: IScheduleShiftScheme,
    onCardChange: (card: IScheduleShiftScheme) => void,
    open: boolean,
    loading: boolean,
    onLoading: (x: boolean) => void,
    shiftSchemeList: readonly IScheduleShiftScheme[],
    setShiftSchemeList: React.Dispatch<React.SetStateAction<readonly IScheduleShiftScheme[]>>,
    style?: CSSProperties,
}

export function ScheduleShiftList({
    defaultScheme,
    searchString, 
    activeCard, 
    onCardChange,
    open,
    loading,
    onLoading,
    shiftSchemeList,
    setShiftSchemeList,
    style,
}: IScheduleShiftListProps) {

    const auth = useAuth();
    
    const [filteredList, setFilteredList] = useState<readonly IScheduleShiftScheme[]>([]);

    const abortControllerRef = useRef<AbortController | null>();
    const prevActiveCardActual = useActual(activeCard);

    const abortLoading = (reason = abortByChange) => {

        if (abortControllerRef.current) {
            abortControllerRef.current.abort(reason);
            abortControllerRef.current = null;
        }
    }

    async function loadScheduleShiftSchemeList(signal: AbortSignal) {
        
        try {

            onLoading(true);

            const loadedShiftSchemeList: IScheduleShiftScheme[] = await getScheduleShiftSchemeList(auth, signal);
           
            setShiftSchemeList(loadedShiftSchemeList);
            setFilteredList(loadedShiftSchemeList);
            
            const activeCardId = defaultScheme
                ? typeof defaultScheme === 'object'
                ? defaultScheme.sss_id
                : defaultScheme
                : undefined;

            let activeCard: IScheduleShiftScheme | undefined = loadedShiftSchemeList[0];

            if (activeCardId !== undefined) {
                activeCard = loadedShiftSchemeList.find(x => x.sss_id === activeCardId);
            }

            if (!prevActiveCardActual.current && activeCard) {
                onCardChange(activeCard);
            }
            
            onLoading(false);
        }
        catch (ex) {

            if (ex === abortByChange) {
                return;
            }

            console.error(ex);
            message.error('Не удалось загрузить список шаблонов смен.');
            onLoading(false);
        }
    }

    useEffect(() => {
        
        const filtered = filterList(shiftSchemeList, searchString);
       
        if (filtered) {
            setFilteredList(filtered);
        }
       
    }, [shiftSchemeList, searchString]);

    useEffect(() => {

        if (open) {
            abortControllerRef.current = new AbortController();
            loadScheduleShiftSchemeList(abortControllerRef.current.signal);
            return () => {
                abortLoading(abortByChange);
            }
        }

    }, [open]);

    return (
        <ScheduleShiftListTemplate 
            loading={loading} 
            scheduleShiftList={filteredList} 
            activeCard={activeCard} 
            onCardChange={onCardChange}
            style={style}
        />
    )
}