import { Ref, useEffect, useState } from "react";
import useEntityLoader from "../../../Hooks/useEntityLoader";
import { getDayChartLeaves, getWeekChartLeaves } from "../index.db";
import moment, { Moment } from "moment";
import useAuth from "../../../Hooks/useAuth";
import { groupBy } from "../../../System/Utils";
import { IScheduleBoardFilter } from "../../../Entities/Database";
import { IItem, Chart } from "./chart";

export interface ILeaveChartProps {
    boardRef: Ref<HTMLDivElement>,
    range: [Moment, Moment],
    filter: IScheduleBoardFilter, // ?: TODO: логичнее было бы использовать фильтр как глобальное место хранения
    open?: boolean,
    isWeekChart: boolean,
    width: number,
    height: number,
}

export function LeaveChart({
    boardRef,
    width,
    height,
    range,
    filter,
    open,
    isWeekChart
}: ILeaveChartProps) {

    const auth = useAuth();
    const [chartDataSource, setChartDataSource] = useState<IItem[]>();
    const [chartLines, setChartLines] = useState<number[]>();
    const [weekChartLoadState, reloadWeekChart] = useEntityLoader((signal) => 
        getWeekChartLeaves(auth, range, signal)
    , [auth.data?.staticId, range]);

    const [dayChartLoadState, reloadDayChart] = useEntityLoader((signal) => 
        getDayChartLeaves(auth, range, signal)
    , [auth.data?.staticId, range]);

    const getLabel = (value: number, index: number) => {

        const tp = moment(value);

        if (isWeekChart) {
            
            //tp.startOf('week');
            //return tp.diff(range[0], 'weeks', false);
            return tp.week();
        }

        return tp.format('MM-DD');
    }

    useEffect(() => {

        const weekCharData = weekChartLoadState.entity;
        const dayChartData = dayChartLoadState.entity;

        const lines = new Set<number>();
        const source = new Map<number, IItem>();

        if (isWeekChart) {

            if (!weekCharData) {
                return;
            }

            const startOfWeekOfSd = range[0].clone().startOf('week');
            const startOfWeekOfEd = range[1].clone().startOf('week').add(1, 'week');
            const groupByWeeksNum = groupBy(weekCharData, x => +x.sdt);

            const currentWeek = startOfWeekOfSd.clone();

            while (currentWeek <= startOfWeekOfEd) {
                
                const dayItems = groupByWeeksNum[+currentWeek];
                dayItems?.forEach(x => {

                    const currTimestamp = +x.sdt;
                    const item = source.get(currTimestamp) || {
                        timestamp: currTimestamp,
                        label: `${x.sdt.isoWeek()}`,
                    };

                    item[x.recordId] = x.value;

                    lines.add(x.recordId);
                    source.set(currTimestamp, item);
                });

                currentWeek.add(1, 'week');
            }
        }
        else {

            if (!dayChartData) return;
            
            const startOfDayOfSd = range[0].clone().startOf('day');
            const startOfDayOfEd = range[1].clone().startOf('day');
            const groupByDaysNum = groupBy(dayChartData, x => +x.dt);

            const currentDay = startOfDayOfSd.clone();

            while (currentDay <= startOfDayOfEd) {

                const dayItems = groupByDaysNum[+currentDay];
                dayItems?.forEach(x => {

                    const currTimestamp = +x.dt;
                    const item = source.get(currTimestamp) || {
                        timestamp: currTimestamp,
                        label: x.dt.format("DD-MM-YYYY"),
                    };

                    item[x.recordId] = x.value;

                    lines.add(x.recordId);
                    source.set(currTimestamp, item);
                });

                currentDay.add(1, 'day');
            }
        }

        setChartLines(Array.from(lines));
        setChartDataSource(Array.from(source.values()).sort((a, b) => a.timestamp - b.timestamp));

    }, [weekChartLoadState.entity, dayChartLoadState.entity, isWeekChart, range, open]);

    useEffect(() => {
        
        if (isWeekChart)
            return reloadWeekChart();

        return reloadDayChart();
        
    }, [auth.data?.staticId, isWeekChart, range]);

    return (
        <Chart
            boardRef={boardRef}
            loading={!weekChartLoadState.load && !dayChartLoadState.load}
            lines={chartLines}
            width={width}
            height={height}
            dataSource={chartDataSource}
            cellWidth={70}
            widthOffset={210}
            fullWidthOnSmall={true}
            tickFormatter={getLabel}
        />
    );
}