import { Button, Drawer, Form, Space, message } from "antd";
import { PersonalInfo } from "../PersonalInfo";
import { InputCollapseItem } from "../InputCollapseItem";
import { TableCollapseItem } from "../TableCollapseItem";
import { memo, useEffect, useRef, useState } from "react";
import { IEmployee, getEmployeeCard } from "../../../Entities/Database";
import { ListItemType } from "../../../Entities/EmployeeCard";
import useAuth from "../../../Hooks/useAuth";
import "./style.css";
import { initialCard } from "./helper";
import { ListItemContainer } from "../ListItemContainer";
import { ProjectGroup } from "../ProjectGroup";
import { Skills } from "../Skills";
import { SpecialMarks } from "../SpecialMarks";

const abortByMerge = {};
const abortByCloseFilter = {};

export interface IFilterProps {
    open: boolean,
    employee?: IEmployee,
    onClose?: () => void,
    onScheduleChange?: () => void,
};

export function NotMemoizedEmployeeCard({
    open,
    employee,
    onClose,
    onScheduleChange: handleScheduleChange,
}: IFilterProps) {
    const auth = useAuth();
    const [loading, setLoading] = useState(true);
    const [card, setCard] = useState(initialCard);
    const [reloadIdx, setReloadIdx] = useState(false);

    async function loadEmployeeCard(signal: AbortSignal) {
        
        try {

            if (!employee) {
                setLoading(false);
                setCard(initialCard);
                return;
            }

            setLoading(true);

            const card = await getEmployeeCard(auth, employee, signal);
          
            setLoading(false);
            setCard(card ? card : initialCard);
           
        }
        catch (ex) {

            if (ex === abortByCloseFilter ||
                ex === abortByMerge) {
                return;
            }

            console.error(ex);
            message.error('Не удалось загрузить карточку сотрудника.');
            setLoading(false);
        }
    };

    const handleReload = () => {
        setReloadIdx(!reloadIdx);
    };

    const abortControllerRef = useRef<AbortController | null>();
    const abortLoading = (reason = abortByMerge) => {
        if (abortControllerRef.current) {
            abortControllerRef.current.abort(reason);
            abortControllerRef.current = null;
        }
    };

    useEffect(() => {
        
        if (open) {
            abortControllerRef.current = new AbortController();
            loadEmployeeCard(abortControllerRef.current.signal);
        }

        return () => {
            abortLoading(open ? abortByMerge : abortByCloseFilter);
        }

    }, [reloadIdx, employee, open]);

    useEffect(() => () => abortLoading(abortByCloseFilter), []);

    return (
        <Drawer
            className="employee-card"
            title="Карточка сотрудника"
            placement="left" 
            onClose={onClose}
            mask={false}
            getContainer={false}
            open={open}
            width={378}
            styles={{
                body: {
                    padding: 0
                }
            }}
            extra={
                <PersonalInfo personalInfo={card.personalInfo} loading={loading} />
            }
            footer={
                <Space className="filter__space">
                    <Button onClick={onClose}>Закрыть</Button>
                    <Button type="primary" onClick={handleReload}>
                        Обновить
                    </Button>
                </Space>
            }
        >
            <InputCollapseItem 
                label="Рабочее время и перерывы" 
                items={card.workTimeInputs}
                loading={loading}
                onFinish={handleReload}
                employee={employee}
                onScheduleChange={handleScheduleChange}
            />
            <ProjectGroup
                data={card.projectGroupTags?.tags}
                edit={card.projectGroupTags?.canEdit}
                loading={loading}
                employee={employee}
                onFinish={handleReload}
            />
            <Skills
                data={card.skills}
                loading={loading}
                employee={employee}
                onFinish={handleReload}
            />
            <SpecialMarks 
                data={card.specialMarks?.tags} 
                edit={card.specialMarks?.canEdit}
                loading={loading}
                employee={employee}
                onFinish={handleReload}
            />
            <TableCollapseItem
                label="Внешние логины"
                size="small"
                items={card.externalLogins}
                rowKey={x => x.id}
                loading={loading}
            />
        </Drawer>
    )
};

export const EmployeeCardDrawer = memo(NotMemoizedEmployeeCard);
export default EmployeeCardDrawer;