import { NotificationOutlined } from "@ant-design/icons";
import { Badge, Button, ButtonProps } from "antd";
import { forwardRef, useEffect, useState } from "react";
import { useNotificactionHub } from "../../../Hooks/useNotificactionHub";
import { classNames } from "../../../System/ClassNames";
import './style.css';

export const NotificationButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>((props, ref) => {

    const notificationHub = useNotificactionHub();
    const [count, setCount] = useState(0);

    useEffect(() => {

        const changeNotificationUnreadCountHandler = (count: number) => {
            setCount(count);
        }

        notificationHub.on('changeNotificationUnreadCount', changeNotificationUnreadCountHandler);

        return () => {
            notificationHub.off('changeNotificationUnreadCount', changeNotificationUnreadCountHandler);
        }

    }, []);

    return (
        <Badge
            count={count}
            overflowCount={99}
            className={classNames('notificationButtonBadge', {
                'hasUnread': count > 0,
                'hasUnreadMore9': count > 9,
            })}
        >
            <Button
                ref={ref}
                size="small"
                className={classNames("notificationButton", props.className)}
                icon={<NotificationOutlined />}
                {...props}
            />
        </Badge>
    );
});