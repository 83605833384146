import moment, { Moment } from "moment";
import { IAuthState } from "../../Entities/IAuthProvider";
import { IAuthData } from "../../Server/IAuthData";
import { execute } from "../../Server/DbProvider";
import { DateQueryParameter, StringQueryParameter } from "../../Server/Query";

export const getShiftTradeRequestResponsesProcName = '[Control].[dbo].[proc_WFM_ShiftTrade_RequestList_Responses]';
export const getShiftTradeRequestListProcName      = '[Control].[dbo].[proc_WFM_ShiftTrade_RequestList]';
export const abortShiftTradeRequestProcName        = '[Control].[dbo].[proc_WFM_ShiftTrade_Request_Cancel]';

export interface IShiftTradeRequestDb {
    id: string,
    dateSlot: string,
    intervalSlot: string,
    creator: string,
    projectGroup: string,
    k: number,
    dtValid: string,
    qtySlots: number,
    qtyPotentcial: number,
    qtyResponseAwait: number,
    qtyResponseApproved: number,
    qtyResponseRejected: number,
    status: number,
    canCancel: boolean,
}

export interface IShiftTradeRequest extends Omit<IShiftTradeRequestDb, 'dateSlot' | 'dtValid'> {
    dateSlot: Moment,
    dtValid: Moment,
}

export interface IShiftTradeRequestResponseDb {
    PID: string,
    fio: string,
    status: string,
    statusId: number,
    dtResponse: string | null,
    comment: string | null,
    requestId?: number,
}

export interface IShiftTradeRequestResponse extends Omit<IShiftTradeRequestResponseDb, 'dtResponse'> {
    dtResponse: Moment | null,
}

export async function getShiftTradeRequestList(
    auth: IAuthState<IAuthData>,
    range: [Moment, Moment],
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
    const response = await execute<IShiftTradeRequestDb[]>({
        authData: auth.data,
        procedure: getShiftTradeRequestListProcName,
        parameters: [
            new DateQueryParameter("sd", range[0]),
            new DateQueryParameter("ed", range[1]),
        ]
    }, signal);

    response.map((x) => {
        !x.dateSlot && console.log(x);
        !x.dtValid && console.log(x);
    });

    return response.map((x): IShiftTradeRequest => ({
        ...x,
        dateSlot: moment(x.dateSlot),
        dtValid: moment(x.dtValid),
    }));
}

export async function abortShiftTradeRequest(
    auth: IAuthState<IAuthData>,
    request: IShiftTradeRequest,
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
    return await execute<void>({
        authData: auth.data,
        procedure: abortShiftTradeRequestProcName,
        parameters: [
            new StringQueryParameter("ShTrR_ID", request.id),
        ]
    }, signal);
}

export async function getShiftTradeRequestResponses(
    auth: IAuthState<IAuthData>,
    request: IShiftTradeRequest,
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);

    const res = await execute<IShiftTradeRequestResponseDb[]>({
        authData: auth.data,
        procedure: getShiftTradeRequestResponsesProcName,
        parameters: [
            new StringQueryParameter("ShTrR_ID", request.id),
        ]
    }, signal);

    return res.map((x): IShiftTradeRequestResponse => ({
        ...x,
        dtResponse: x.dtResponse ? moment(x.dtResponse) : null,
    }));
}