import { Tag } from "antd";
import "./style.css";
import { classNames } from "../../../System/ClassNames";


export interface ITemplateBreakEditorLinearItemLocale {
    paid: string
}

export const localeRu: ITemplateBreakEditorLinearItemLocale = {
    paid: 'Оплачиваемый'
}

export interface ITemplateBreakEditorLinearItemProps {
    locale?: ITemplateBreakEditorLinearItemLocale,
    paid?: boolean,
    title?: string,
    time?: string,
}

export function TemplateBreakEditorLinearItem({
    locale = localeRu,
    paid,
    title,
    time
}: ITemplateBreakEditorLinearItemProps) {

    const paidPrefix = paid ? `[${locale.paid}]` : '';
    const itemColor = paid ? "paid" : "default";

    return (
        <Tag
            className={classNames("templateBreakEditorLinearItem", itemColor)}
            title={`${paidPrefix} ${title}: ${time}`}
        >
            <span className="templateBreakEditorLinearItem__title">{title}</span>  
            <span className="templateBreakEditorLinearItem__time">{time}</span> 
        </Tag>
    )
}