import { Collapse, CollapseProps } from "antd";
import { InputItem } from "../InputItem";
import { memo } from "react";
import { IEmployee, IWorkTimeInput } from "../../../Entities/Database";

export interface IInputCollapseItemProps {
    label: string,
    items?: IWorkTimeInput[] | undefined,
    loading: boolean,
    onFinish: () => void,
    employee?: IEmployee,
    onCardUpdate?: () => void,
    onScheduleChange?: () => void,
}

function NotMemoizedInputCollapseItem({ 
    label, 
    items, 
    loading, 
    onFinish, 
    employee,
    onCardUpdate,
    onScheduleChange 
}: IInputCollapseItemProps) {
    
    const colItems: CollapseProps['items']  = [
        {
            label: label,
            children: items && items.map(item => (
                <InputItem 
                    workTimeInput={item} 
                    key={item.form_id} 
                    loading={loading} 
                    onFinish={onFinish} 
                    employee={employee}
                    onScheduleChange={onScheduleChange}
                />
            )),
        }, 
    ]

    return (
        <Collapse 
            className="employee-collapse"
            items={colItems} 
        />
    )
}
export const InputCollapseItem = memo(NotMemoizedInputCollapseItem);