import DraggableModal from "../../DraggableModal";
import { useEffect, useRef, useState } from "react";
import useAuth from "../../../Hooks/useAuth";
import { message as AntdMessage, Empty, Spin } from "antd";
import { IEmployee, ScheduleShiftBreakInfo, getScheduleShiftBreakInfo } from "../../../Entities/Database";
import { doOrAbort } from "../../../System/doOrAbort";
import { ScheduleShiftWorkInfoItem } from "../ScheduleShiftWorkInfoItem";

export interface IScheduleShiftWorkInfoModalProps {
    open?: boolean, 
    onCancel?: () => void,
    onOk?: () => void,
    employee?: IEmployee,
}

const abortByChange = {};
const abortByClose  = {};

export function ScheduleShiftWorkInfoModal({
    open,
    onCancel,
    onOk,
    employee,
}: IScheduleShiftWorkInfoModalProps) {

    const auth = useAuth();
    const loaderAcRef = useRef<AbortController>();
    const destructorAcRef = useRef<AbortController>();
    const [loading, setLoading] = useState(false);

    const [message, messageContextHolder] = AntdMessage.useMessage();
    const [infoList, setInfoList] = useState<ScheduleShiftBreakInfo[]>();

    const abortPrevReq = (reason: unknown) => {

        if (loaderAcRef.current) {
            loaderAcRef.current.abort(reason);
        }
    }

    const loadScheduleShiftBreakInfo = async (signal: AbortSignal) => {
        if (!employee) {
            return;
        }

        try {

            setLoading(true);
            setInfoList([]);
            const loadedInfoList: ScheduleShiftBreakInfo[] = await doOrAbort(
                signal => getScheduleShiftBreakInfo(auth, employee?.PID, signal),
                signal,
                destructorAcRef.current?.signal
            );
            
            setInfoList(loadedInfoList);
            setLoading(false);
        }
        catch (ex) {

            if (ex === abortByChange || ex === abortByClose) {
                return;
            }

            console.error(ex);
            message.error('Не удалось загрузить список шаблонов смен.');
            setLoading(false);
        }
    }

    useEffect(() => {

        if(open) {

            const ac = loaderAcRef.current = new AbortController();
            const signal = ac.signal;

            if (employee !== undefined) {
               loadScheduleShiftBreakInfo(signal);  
            }

            return () => {
                abortPrevReq(abortByChange);
            }
        }

    }, [auth.data?.staticId, open]);

    return (
        <DraggableModal 
            open={open}
            onCancel={onCancel}
            onOk={onOk}
            title="Текущие бизнес правила"
            destroyOnClose
            cancelButtonProps={{
                style: {
                    display: 'none'
                }
            }}
        >
            {loading && <Spin size="large"/>}

            {!loading && 
            !infoList && 
            <Empty />}

            {!loading &&
            infoList && 
            <>
               {infoList[0].shiftStartOffset && 
               <ScheduleShiftWorkInfoItem 
                    title="Первый перерыв не ранее чем за"
                    content={infoList[0].shiftStartOffset}
                />}
                {infoList[0].shiftEndOffset && 
                <ScheduleShiftWorkInfoItem 
                    title="Последний перерыв не позднее чем за"
                    content={infoList[0].shiftEndOffset}
                />}
                {infoList[0].breakOffset_min && 
                <ScheduleShiftWorkInfoItem 
                    title="Минимальное время между перерывами:"
                    content={infoList[0].breakOffset_min}
                />}
                {infoList[0].breakOffset_max &&
                <ScheduleShiftWorkInfoItem 
                    title="Максимальное время между перерывами:"
                    content={infoList[0].breakOffset_max}
                />}
                {infoList[0].breakDurn_min && 
                <ScheduleShiftWorkInfoItem 
                    title="Минимальная длина перерыва:"
                    content={infoList[0].breakDurn_min}
                />}
                {infoList[0].breakDurn_max && 
                <ScheduleShiftWorkInfoItem 
                    title="Максимальная длина перерыва:"
                    content={infoList[0].breakDurn_max}
                />}
                {infoList[0].lunchDurn_min &&
                <ScheduleShiftWorkInfoItem 
                    title="Минимальная длина обеда:"
                    content={infoList[0].lunchDurn_min}
                />}
                {infoList[0].lunchDurn_max && 
                <ScheduleShiftWorkInfoItem 
                    title="Максимальная длина обеда:"
                    content={infoList[0].lunchDurn_max}
                />}
            </>}
        </DraggableModal>
    )
}