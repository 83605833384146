import { MbscCalendarEvent, MbscCalendarLabel } from "./lib";

export const ONE_MIN = 60000;
export const ONE_HOUR: number = 60 * ONE_MIN;
export const ONE_DAY: number = 24 * ONE_HOUR;

export function step(value: number, st: number): number {
    return Math.floor(value / st) * st;
}

export function addDays(date: Date, days: number): Date {
    const copy = new Date(date.getTime());
    copy.setTime(copy.getTime() + days * ONE_DAY);
    return copy;
}

 export function getDayStart(d: Date): Date {
    const newDate = new Date(d.getTime());
    newDate.setHours(0, 0, 0, 0);
    return newDate;
}

export function getDayEnd(d: Date): Date {
    const newDate = new Date(d.getTime());
    newDate.setHours(23, 59, 59, 999);
    return newDate;
}

export function addHours(d: Date, numOfHours: number) {
    const dateCopy = new Date(d.getTime());
    dateCopy.setTime(dateCopy.getTime() + numOfHours * ONE_HOUR);
    return dateCopy;
}

export function getDateOnly(d: Date): Date {
    return adjustedDate(d.getFullYear(), d.getMonth(), d.getDate());
}

export function adjustedDate(y: number, m: number, d: number, h?: number, i?: number, s?: number, u?: number): Date {
    const date = new Date(y, m, d, h || 0, i || 0, s || 0, u || 0);
    if (date.getHours() === 23 && (h || 0) === 0) {
        date.setHours(date.getHours() + 2);
    }
    return date;
}

export function addMinMax(
    invalid: MbscCalendarEvent[],
    min?: Date,
    max?: Date,
    prefix: string = '',
    minLabelProps?: MbscCalendarLabel,
    maxLabelProps?: MbscCalendarLabel,
) {

    if (min !== undefined) {

        const minDateOnly        = getDateOnly(min);
        const minPrevDayDateOnly = getDayEnd(addDays(minDateOnly, -1));

        invalid.push({
            id: `${prefix}main_min_repeat`,
            start: '00:00',
            end: '00:00',
            recurring: {
                repeat: 'daily',
                until: minPrevDayDateOnly
            },
            ...minLabelProps,
        });
        
        invalid.push({
            id: `${prefix}main_min_end`,
            start: minDateOnly,
            end: min,
            ...minLabelProps,
        });
    }

    if (max !== undefined) {

        const maxDateOnly = addDays(getDateOnly(max), 1);

        invalid.push({
            id: `${prefix}main_max_repeat`,
            start: '00:00',
            end: '00:00',
            recurring: {
                repeat: 'daily',
                from: maxDateOnly
            },
            ...maxLabelProps
        });

        invalid.push({
            id: `${prefix}main_max_end`,
            start: max,
            end: maxDateOnly,
            ...maxLabelProps
        });
    }
}