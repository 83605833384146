    
    export interface IDebugProvider {

        /**
         * `console.assert()` writes a message if `value` is [falsy](https://developer.mozilla.org/en-US/docs/Glossary/Falsy) or omitted. It only
         * writes a message and does not otherwise affect execution. The output always
         * starts with `"Assertion failed"`. If provided, `message` is formatted using `util.format()`.
         *
         * If `value` is [truthy](https://developer.mozilla.org/en-US/docs/Glossary/Truthy), nothing happens.
         *
         * ```js
         * console.assert(true, 'does nothing');
         *
         * console.assert(false, 'Whoops %s work', 'didn\'t');
         * // Assertion failed: Whoops didn't work
         *
         * console.assert();
         * // Assertion failed
         * ```
         * @since v0.1.101
         * @param value The value tested for being truthy.
         * @param message All arguments besides `value` are used as error message.
         */
        assert(value: any, message?: string, ...optionalParams: any[]): void;

        /**
         * The `console.debug()` function is an alias for {@link log}.
         * @since v8.0.0
         */
        debug(message?: any, ...optionalParams: any[]): void;

        /**
         * Prints to `stderr` with newline. Multiple arguments can be passed, with the
         * first used as the primary message and all additional used as substitution
         * values similar to [`printf(3)`](http://man7.org/linux/man-pages/man3/printf.3.html) (the arguments are all passed to `util.format()`).
         *
         * ```js
         * const code = 5;
         * console.error('error #%d', code);
         * // Prints: error #5, to stderr
         * console.error('error', code);
         * // Prints: error 5, to stderr
         * ```
         *
         * If formatting elements (e.g. `%d`) are not found in the first string then `util.inspect()` is called on each argument and the resulting string
         * values are concatenated. See `util.format()` for more information.
         * @since v0.1.100
         */
        error(message?: any, ...optionalParams: any[]): void;

        /**
         * The `console.info()` function is an alias for {@link log}.
         * @since v0.1.100
         */
        info(message?: any, ...optionalParams: any[]): void;

        /**
         * Prints to `stdout` with newline. Multiple arguments can be passed, with the
         * first used as the primary message and all additional used as substitution
         * values similar to [`printf(3)`](http://man7.org/linux/man-pages/man3/printf.3.html) (the arguments are all passed to `util.format()`).
         *
         * ```js
         * const count = 5;
         * console.log('count: %d', count);
         * // Prints: count: 5, to stdout
         * console.log('count:', count);
         * // Prints: count: 5, to stdout
         * ```
         *
         * See `util.format()` for more information.
         * @since v0.1.100
         */
        log(message?: any, ...optionalParams: any[]): void;
    }

    export class Debug {

        private static s_provider: IDebugProvider = console;
        
        public static SetProvider(provider: IDebugProvider)
        {
            Debug.s_provider = provider;
        }

        public static Write(message?: string) {
            Debug.s_provider.debug(message);
        }
        
        public static WriteLine(message?: string) : never | void
        public static WriteLine(value: unknown): never | void
        public static WriteLine(value: any) {
            Debug.s_provider.debug(value);
        }
        
        public static Assert(condition: boolean): void
        public static Assert(condition: boolean): never
        public static Assert(condition: boolean, message: string) : never | void
        public static Assert(condition: boolean, message?: string, detailMessage?: string)
        {
            if (!condition)
            {
                Debug.Fail(message, detailMessage);
            }
        }
        
        public static Fail(message?: string, detailMessage?: string) {
            
            try
            {
                Debug.s_provider.error(message, detailMessage);
            }
            catch (ex) {
                console.error(ex);
                const normalizeMessage = message + " " + detailMessage ?? "HEEEEEEYYYY, some wrong";
                alert(normalizeMessage);
                console.log(normalizeMessage, new Error().stack);
                throw normalizeMessage;
            }
        }
    }