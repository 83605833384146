import { createAbortError, getOrCreateAbortError, isFunction } from "./Utils";

export function createAwaiter<TResult>(task: Promise<TResult>, signal?: AbortSignal): Promise<TResult> {

    if (signal && signal.aborted) {
        return Promise.reject(getOrCreateAbortError(signal));
    }

    let interrupted: boolean;
    let settleResolve: (value: TResult | PromiseLike<TResult>) => void;
    let settleReject: (reason: any) => void | PromiseLike<void>;
    let rejectFn: (reason?: any) => void | PromiseLike<void>;

    const signalListener = (event: Event) => {
        interrupted = true;
        rejectFn(getOrCreateAbortError(event));
    };

    const cleanup = () => {
        if (signal) {
            signal.removeEventListener('abort', signalListener);
        }
    };

	const workPromise: Promise<TResult> & { clear?: () => void }  = new Promise<TResult>((resolve, reject) => {

        rejectFn = reject;

		settleResolve = (value: TResult | PromiseLike<TResult>) => {

			cleanup();

            if(!interrupted) {
                resolve(value);
            }
		};

        settleReject = (reason: any): void | PromiseLike<void> => {

            cleanup();

            if(!interrupted) {
                reject(reason);
            }
        }
        
        task
            .then(settleResolve)
            .catch(settleReject)
	});

    if (signal) {
		signal.addEventListener('abort', signalListener, {once: true});
	}

    return workPromise;
}

export const resolveEventName = "resolve";
export const rejectEventName = "reject";

export interface QueueAwaiterEventMap {
    "resolve": Event;
    "reject": Event;
}

export type QueueAwaiterEventTypes = keyof QueueAwaiterEventMap;

export type QueueAwaiterListenerOptions = any;

export type QueueAwaiterListener = {
    listener: EventListenerOrEventListenerObject,
    options?: boolean | AddEventListenerOptions
}

export type QueueAwaiterAction<TResult> = (signal?: AbortSignal) => Promise<TResult>;

export type QueueAwaiterEvent<TResult> = Event & {payload: TResult};

export function createEvent<TResult>(type: QueueAwaiterEventTypes, payload: TResult): QueueAwaiterEvent<TResult> {

    let event: any;

    try {
        event = new Event(type);
        event.payload = payload;
    } catch (e) {

        if (typeof document !== 'undefined') {

            if (!document.createEvent) {

                // For Internet Explorer 8:
                event = (document as any).createEventObject();
                event.type    = type;
                event.payload = payload;

            } else {

                // For Internet Explorer 11:
                event = document.createEvent('Event');
                event.initEvent(type, false, false);
                event.payload = payload;
            }

        } else {
            // Fallback where document isn't available:
            event = {
                type: type,
                payload: payload,
                bubbles: false,
                cancelable: false,
            };
        }
    }

    return event;
}

export class QueueAwaiter<TResult> extends EventTarget {

    private __await: boolean = false;
    private __awaitResult: Event | undefined;

    readonly abortController: AbortController;
    readonly actionTask: Promise<TResult>;

    constructor(actionOrTask: QueueAwaiterAction<TResult>) {

        super();

        this.__await = true;
        this.abortController = new AbortController();
        this.actionTask = actionOrTask(this.abortController.signal);

        const self = this;

        this.actionTask
            .then((result) => {
                self.__await = false;
                self.__awaitResult = createEvent("resolve", result);
                self.dispatchEvent(self.__awaitResult);
            })
            .catch((result) => {
                self.__await = false;
                self.__awaitResult = createEvent("reject", result);
                self.dispatchEvent(self.__awaitResult);
            });
    }

    private __dispatchEventRuntime(event: Event, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions) {

        try {

            if(isFunction(listener)) {
                listener.call(this, event);
            }
            else {
                listener.handleEvent(event);
            }
            
        } catch (e) {
            Promise
            .resolve()
            .then(() => {
                throw e;
            });
        }
        
        if (options && options !== true && options.once) {
            super.removeEventListener(event.type, listener);
        }

        return !event.defaultPrevented;
    }

    abort() {
        if(!this.abortController.signal.aborted) {
            this.abortController.abort();
        }
    }

    addEventListener<K extends keyof QueueAwaiterEventMap>(type: K, listener: (this: QueueAwaiter<TResult>, ev: QueueAwaiterEventMap[K]) => any, options?: boolean | AddEventListenerOptions): void;
    addEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | AddEventListenerOptions): void {
        
        super.addEventListener(type, listener, options);
        
        // Если результат ожидаемого события получен
        if (this.__awaitResult) {
            this.__dispatchEventRuntime(
                this.__awaitResult,
                listener,
                options
            );
        }
    }

    removeEventListener<K extends keyof QueueAwaiterEventMap>(type: K, listener: (this: QueueAwaiter<TResult>, ev: QueueAwaiterEventMap[K]) => any, options?: boolean | EventListenerOptions): void;
    removeEventListener(type: string, listener: EventListenerOrEventListenerObject, options?: boolean | EventListenerOptions): void {
        super.removeEventListener(type, listener, options);
    }
}