import { Moment } from "moment";
import { IEmployeeSpecialMarksChanges, IItemDetailsEdit, TEnabledSpecialMarks } from "../../../Entities/Database";
import moment from "moment";
import { ListItemType } from "../../../Entities/EmployeeCard";
import { DatePicker, DatePickerProps } from "antd";
import { getItemLabel } from "../ListItemContainer/helper";
import { listItemTitlesLangRu } from "../ListItemContainer/locale/ru";

import "./style.css";

export function getChangeList(employeesSpecMarks: TEnabledSpecialMarks, initSpecMarks: TEnabledSpecialMarks, date?: Moment) {

    let start = moment();
    if(date?.isValid()) start = date;

    let result = new Map<string, IEmployeeSpecialMarksChanges>();
    for (const employeeSpecMarks of employeesSpecMarks) {
        let index = initSpecMarks.findIndex(x => x.PID === employeeSpecMarks.PID);
        if (index !== -1) {

            const initEmployeeSpecMarks = initSpecMarks[index];

            for (const enabledId of employeeSpecMarks.enabled) {
                if (!initEmployeeSpecMarks.enabled.includes(enabledId)) {
                    const resultEmployeeChanges = result.get(employeeSpecMarks.PID);
                    if (resultEmployeeChanges) {
                        resultEmployeeChanges.enabled.push(enabledId);
                    } else {
                        result.set(employeeSpecMarks.PID, {
                            PID: employeeSpecMarks.PID,
                            start: start,
                            enabled: [enabledId],
                            disabled: []
                        });
                    }
                }
            }

            for (const prevEnabledId of initEmployeeSpecMarks.enabled) {
                if (!employeeSpecMarks.enabled.includes(prevEnabledId)) {
                    const resultEmployeeChanges = result.get(employeeSpecMarks.PID);
                    if (resultEmployeeChanges) {
                        resultEmployeeChanges.disabled.push(prevEnabledId);
                    } else {
                        result.set(employeeSpecMarks.PID, {
                            PID: employeeSpecMarks.PID,
                            start: start,
                            enabled: [],
                            disabled: [prevEnabledId],
                        });
                    }
                }
            }
        }
    }

    return Array.from(result.values());
};

export function getActiveTagIds(data: IItemDetailsEdit[]) {
    const activeTagIds = data
        .filter((item) => item.isActive)
        .map((item) => item.id);

    return activeTagIds;
};



export function getItemDetailsModalTitle(listItemType: ListItemType, handleDatePickerChange:  DatePickerProps['onChange'] ) {
    switch (listItemType) {
        case ListItemType.Skills: return (
            <div className="itemList__title-wrap">
                <p className="itemList__title">Изменение: {getItemLabel(listItemType, listItemTitlesLangRu)}</p>
                <div className="itemList__datepicker-wrap">
                    <p className="itemList__datepicker-title">Применить с: </p>
                    <DatePicker onChange={handleDatePickerChange} className="itemList__datepicker"/>
                </div>
                
            </div>
        );
        case ListItemType.SpecialMarks: return (<div>Изменение: {getItemLabel(listItemType, listItemTitlesLangRu)}</div>);
        default: break;
    }
    return <></>;
}