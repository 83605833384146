import moment from "moment";
import { MbscCalendarColor, MbscCalendarEvent, MbscResource } from "../../shared/lib";
import { TBreak, IScheduleWorkTime, isLinearBreak } from "../../../Entities/Database";
import { classNames, groupBy } from "../../../System/Utils";

export const defaultDate = new Date(9999, 0, 1, 0, 0, 0, 0);
export const lastDate    = new Date(9999, 0, 3, 0, 0, 0, 0);
export const nextDate    = new Date(9999, 0, 2, 0, 0, 0, 0);
export const minDate     = new Date(9999, 0, 1, 0, 0, 0, 0);
export const maxDate     = new Date(9999, 0, 2, 15, 55, 0, 0);
export const timeFormat  = "HH:mm:ss";

export interface IConvertTimeLocale {
    shortDay: string,
    workTime: string,
}

export interface IConvertBreaksLocale {

}

export interface IWorkTimeEvent extends MbscCalendarEvent {
    id: string,
    resource: IScheduleWorkTime['dayId'],
    start: Date,
    end: Date,
    originalWorkTime: IScheduleWorkTime,
}

export interface IBreakColor extends MbscCalendarColor {
    resource: IScheduleWorkTime['dayId'],
    originalBreak: TBreak,
}

export interface IWorkTimeId {
    dayId: IScheduleWorkTime['dayId'],
    segmentId: IScheduleWorkTime['segmentId'],
}

export function inOneGroup(value1: TBreak | IWorkTimeId, value2: TBreak | IWorkTimeId) {
    return value1.dayId === value2.dayId &&
           value1.segmentId === value2.segmentId;
}

export function getGroupKey(entity: TBreak | IWorkTimeId) {
    return `${entity.dayId}#${entity.segmentId}`;
}

export function getBreakDuration(entity: TBreak): number {

    if (isLinearBreak(entity)) {
        return entity.durn;
    }

    return entity.rightOffset - entity.leftOffset;
}

export function convertBreaksToColors(
    locale: IConvertBreaksLocale,
    times: IWorkTimeEvent[],
    breaks: TBreak[],
    selectedBreak: TBreak | undefined,
    roundMinutes: number = 5
): IBreakColor[] {

    const result: IBreakColor[] = [];
    const groups = groupBy(breaks, getGroupKey);

    for (const [groupKey, group] of Object.entries(groups)) {

        const workTime = times.find(x => getGroupKey(x.originalWorkTime) === groupKey);

        if (workTime) {

            const sortedGroup = group.sort((x, y) => (x.sort || 0) - (y.sort || 0));
            const workTimeStart = moment(workTime.start);
            const originalWorkTime = workTime.originalWorkTime;
            const avalableWorkTimeMinutes = originalWorkTime.durn;// - originalWorkTime.leftOffset - originalWorkTime.rightOffset;
            const breaksDuration = group.reduce((partialSum, d) => partialSum + getBreakDuration(d), 0);
            const step = Math.round(((avalableWorkTimeMinutes - breaksDuration) / (group.length + 1)) / roundMinutes) * roundMinutes;

            let offset = sortedGroup.length === 1
                ? (originalWorkTime.durn / 2) - (sortedGroup[0].durn / 2)
                : step;

            for (const breakItem of sortedGroup) {
                
                // TODO: возможно нужно будет комбинировать между линейнми и кастомными перерывами

                const isLinear = isLinearBreak(breakItem);

                let colorStart: moment.Moment;
                let colorEnd: moment.Moment;

                if (isLinear) {

                    colorStart = workTimeStart.clone().add(offset - (breakItem.durn / 2), 'minutes');
                    colorEnd   = colorStart.clone().add(breakItem.durn, 'minutes');

                    offset += step + breakItem.durn;
                }
                else {
                    colorStart = workTimeStart.clone().add(breakItem.leftOffset, 'minutes');
                    colorEnd   = colorStart.clone().add(breakItem.durn, 'minutes');

                    // Добавляем range для нестатических перерывов
                    if (breakItem.leftOffset !== breakItem.rightOffset) {

                        const rangeColorStart = workTimeStart.clone().add(breakItem.leftOffset, 'minutes').toDate();
                        const rangeColorEnd   = workTimeStart.clone().add(breakItem.rightOffset, 'minutes').toDate();

                        result.push({
                            resource: originalWorkTime.dayId,
                            start: rangeColorStart,
                            end: rangeColorEnd,
                            originalBreak: breakItem,
                            cssClass: classNames({
                                'ap-work-time-break-range': true,
                                'selected': selectedBreak === breakItem,
                            }),
                        });
                    }
                }

                result.push({
                    resource: originalWorkTime.dayId,
                    start: colorStart.toDate(),
                    end: colorEnd.toDate(),
                    originalBreak: breakItem,
                    cssClass: classNames({
                        'ap-work-time-break': true,
                        'selected': selectedBreak === breakItem,
                        'linear': isLinear,
                        'custom': !isLinear,
                        'payed': breakItem.isPayed,
                    }),
                });
            } 
        }
    }

    return result;
}

export function convertTimesToEvents(
    locale: IConvertTimeLocale,
    times: IScheduleWorkTime[],
    selectedTime: IScheduleWorkTime | undefined,
): [MbscResource[], IWorkTimeEvent[]] {

    const resources = new Map<IScheduleWorkTime['dayId'], MbscResource>();
    const events: IWorkTimeEvent[] = [];

    for (const time of times) {

        const hhmmss = moment(time.start, timeFormat);

        hhmmss
            .year(defaultDate.getFullYear())
            .month(defaultDate.getMonth())
            .date(defaultDate.getDate());
        
        const start = hhmmss.toDate();
        const end   = hhmmss.clone().add(time.durn, 'minutes').toDate();

        const event: IWorkTimeEvent = {
            id: getGroupKey(time),
            title: locale.workTime,
            resource: time.dayId,
            start: start,
            end: end,
            originalWorkTime: time,
            cssClass: classNames({
                'selected': time === selectedTime,
            }),
        }

        events.push(event);

        if (!resources.has(time.dayId)) {
            resources.set(time.dayId, {
                id: time.dayId,
                name: `${locale.shortDay}${time.dayIndex}`,
                eventCreation: false,
            });
        }
    }

    return [Array.from(resources.values()), events];
}