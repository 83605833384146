import { EmployeeContactType, Gender, IEmployeeCard, IEmployeeInfo, systemSkillId } from "../../../Entities/Database";

export const initialEmployeeInfo: IEmployeeInfo = {
    gender: Gender.None,
    fio: "",
    role: "",
    post: "",
    company: "",
    office: "",
    timezone: "",
    birthdate: "",
    canEdit: false,
    contact: [
        {
            id: '1',
            type: EmployeeContactType.Phone,
            value: [''],
        },
        {
            id: '4',
            type: EmployeeContactType.Email,
            value: [''],
        },
        {
            id: '5',
            type: EmployeeContactType.Whatsapp,
            value: [''],
        },
        {
            id: '6',
            type: EmployeeContactType.Telegram,
            value: [''],
        },
        {
            id: '7',
            type: EmployeeContactType.Skype,
            value: [''],
        },
    ]
};

export const initialCard: IEmployeeCard = {
    personalInfo: initialEmployeeInfo,
    workTimeInputs: [],
    projectGroupTags: {
        canEdit: false,
        tags: [
           
        ]
    },
    skills: {
        canEdit: false,
        mainSkillId: systemSkillId,
        tags: [
           
        ]
    },
    specialMarks: {
        canEdit: false,
        tags: [
          
        ]
    },
    externalLogins: []
};