import { AxiosResponse } from "axios";
import { IQueryResultItem } from "../Server/IQueryResult";
import { BackendException } from "./BackendException";
import { customName } from "./CustomNameDecorator";
import Exception from "./Exception";
import { ArgumentScopeDetector } from "./Type/ArgumentScopeDetector";
import { HasValue } from "./Type/DetectedInfo";
import { TypeOf } from "./Type/TypeOf";
import { NullOrUndefined } from "./Utils";

@customName("BackendResponseException")
export class BackendResponseException<T = any, D = any> extends BackendException {

    protected readonly queryId: number | string;
    protected readonly item: IQueryResultItem;
    protected readonly response: AxiosResponse<T, D> | NullOrUndefined;

    public GetQueryId(): number | string { return this.queryId; }
    public GetItem(): IQueryResultItem { return this.item }
    public GetResponse(): AxiosResponse<T, D> | NullOrUndefined { return this.response; }

    constructor(queryId: number | string, item: IQueryResultItem, response: AxiosResponse<T, D> | NullOrUndefined, id: number);
    constructor(queryId: number | string, item: IQueryResultItem, response: AxiosResponse<T, D> | NullOrUndefined, id: number, innerException: Exception);
    constructor(queryId: number | string, item: IQueryResultItem, response: AxiosResponse<T, D> | NullOrUndefined, id: number, message: string);
    constructor(queryId: number | string, item: IQueryResultItem, response: AxiosResponse<T, D> | NullOrUndefined, id: number, message: string, innerException: Exception);
    constructor(queryId: string | number, item: IQueryResultItem, response: AxiosResponse<T, D> | NullOrUndefined, id: number, messageOrInnerException?: string | Exception, innerException?: Exception) {

        const detectedId = ArgumentScopeDetector.Detect(arguments, [
            { paramType: TypeOf.Number, paramIndex: 3 },
        ], 0);

        const detectedMessage = ArgumentScopeDetector.Detect(arguments, [
            { paramType: TypeOf.String, paramIndex: 3, like: !detectedId.hasValue },
            { paramType: TypeOf.String, paramIndex: 4 },
        ], "");

        const detectedInnerException = ArgumentScopeDetector.Detect<Exception>(arguments, [
            { paramType: Exception, paramIndex: 4 },
            { paramType: Exception, paramIndex: 5 },
            { paramType: Exception, paramIndex: 6 },
        ], null);

        if (HasValue(detectedId) && HasValue(detectedMessage) && HasValue(detectedInnerException)) {
            super(detectedId.value, detectedMessage.value, detectedInnerException.value);
        }

        else if (HasValue(detectedId) && HasValue(detectedMessage) && !detectedInnerException.hasValue) {
            super(detectedId.value, detectedMessage.value);
        }
            
        else if (HasValue(detectedId) && !detectedMessage.hasValue && HasValue(detectedInnerException)) {
            super(detectedId.value, detectedInnerException.value);
        }

        else if (HasValue(detectedId) && !detectedMessage.hasValue && !detectedInnerException.hasValue) {
            super(detectedId.value);
        }

        else if (!detectedId.hasValue && HasValue(detectedMessage) && HasValue(detectedInnerException)) {
            super(detectedMessage.value, detectedInnerException.value);
        }

        else if (!detectedId.hasValue && HasValue(detectedMessage) && !detectedInnerException.hasValue) {
            super(detectedMessage.value);
        }
        
        else {
            super();
        }

        this.item = item;
        this.queryId = queryId;
        this.response = response;
    }
}