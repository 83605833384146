import { Button, Layout } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { ScheduleShiftSearchString } from '../ScheduleShiftSearchString';
import { Ref, useState } from 'react';
import { ScheduleShiftList } from '../ScheduleShiftList';
import "./style.css";
import { IScheduleShiftScheme } from '../../../Entities/Database';

interface IScheduleShiftMenuProps {
    wrapperRef?: Ref<HTMLDivElement>,
    width?: number,
    activeCard?: IScheduleShiftScheme,
    defaultScheme?: IScheduleShiftScheme | IScheduleShiftScheme['sss_id'],
    onCardChange: (card: IScheduleShiftScheme) => void,
    openModal: () => void,
    open: boolean
    loading: boolean,
    handleLoading: (x: boolean) => void,
    shiftSchemeList: readonly IScheduleShiftScheme[],
    setShiftSchemeList: React.Dispatch<React.SetStateAction<readonly IScheduleShiftScheme[]>>,
}

export function ScheduleShiftMenu({
    wrapperRef,
    width = 235,
    activeCard,
    defaultScheme,
    onCardChange, 
    openModal,
    open,
    loading,
    handleLoading,
    shiftSchemeList,
    setShiftSchemeList,
}: IScheduleShiftMenuProps) {

    const [searchString, setSearchString] = useState("");

    const updateSearchString = (currentValue: string) => { 
        if (searchString !== currentValue) {
            setSearchString(currentValue);
        }
    };

    return (
        <div
            ref={wrapperRef}
            style={{
                flex: 1,
                background: "#F2F2F7",
                height: 'calc(100% - 20px)',
                minHeight: 200,
                overflow: 'hidden',
                display: 'flex',
                flexDirection: 'column',
                flexWrap: 'nowrap',
                alignItems: 'flex-start',
                width: width,
                padding: "10px 18px"
            }}
        >
            <ScheduleShiftSearchString 
                updateValue={updateSearchString}
            />
            {/* <ScheduleShiftFilterGroup /> */}
            <Button
                className="scheduleShift__add"
                icon={<PlusOutlined style={!loading ? {color: "#1890FF"}: {}}/>}
                onClick={openModal}
                disabled={loading}
            >
                Добавить
            </Button>
            <ScheduleShiftList
                defaultScheme={defaultScheme}
                searchString={searchString}
                activeCard={activeCard}
                onCardChange={onCardChange}
                open={open}
                loading={loading}
                onLoading={handleLoading}
                shiftSchemeList={shiftSchemeList}
                setShiftSchemeList={setShiftSchemeList}
            />
        </div>
    )
}

