import { Avatar, Button, Card, Skeleton } from "antd";
import { RetweetOutlined } from '@ant-design/icons';
import "./style.css";
import { classNames } from "../../../System/ClassNames";
import { IScheduleShiftScheme } from "../../../Entities/Database";
import { getScheduleShiftTitleColor, getWDTypeTitle } from "./helper";
import { forwardRef, useEffect, useState, useImperativeHandle, Ref, useRef } from "react";

interface IScheduleShiftCardProps {
    loading?: boolean,
    cardInfo: IScheduleShiftScheme,
    activeCard?: IScheduleShiftScheme,
    onCardChange?: (cardInfo: IScheduleShiftScheme) => void,
}

export function ScheduleShiftCard({
    loading,
    cardInfo,
    activeCard,
    onCardChange
}: IScheduleShiftCardProps) {

    const { sss_id, sss_WDType_ID, fcp, isPublic, signature, title, tz } = cardInfo;

    const fieldRef = useRef<HTMLDivElement>(null);
    const handleCardCahnge = () => {
        if (onCardChange) {
            onCardChange(cardInfo);
        }
    }

    useEffect(() => {

        if (activeCard?.sss_id === sss_id && fieldRef.current) {
            fieldRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest",
            });
        }

    }, [activeCard?.sss_id]);

    return (
        <Card
            ref={fieldRef}
            className={classNames("shiftPattern__card", activeCard?.sss_id === sss_id && "card-active")}
            onClick={handleCardCahnge}
            tabIndex={0}
        >
            <div className="shiftPattern__caption">
                <div className="shiftPattern__decor" style={{background: getScheduleShiftTitleColor(isPublic)}}></div>
                {title}
            </div>
         
            <div className="shiftPattern__addInfo">
                <div className="shiftPattern__date">
                    <RetweetOutlined style={{marginRight: "5px", fontSize: "135%"}}/> 
                    {getWDTypeTitle(sss_WDType_ID)}
                </div>
                <div className="shiftPattern__number">
                    <div className="shiftPattern__number--breaks">[</div>
                    {fcp}
                    <div className="shiftPattern__number--breaks">]</div>
                </div>
            </div>
        </Card>
    )
};
