import { Button, Empty, Tabs } from "antd";
import { NotificationItem } from "../NotificationItem";
import { useEffect, useState } from "react";
import { NotificationStatus, INotification, IRuntimeNotification, INotificationBase } from '../../../Entities/BackendAPIHub';
import { useNotificactionHub } from "../../../Hooks/useNotificactionHub";
import "./style.css";

export interface INotificationTabsProps {
    notifications: INotification[]
}

export function NotificationTabs ({
    notifications
}: INotificationTabsProps) {

    const notificationHub = useNotificactionHub();
    const [unreadNotifications, setUnreadNotifications] = useState<INotification[]>([]);
    const [readNotifications, setReadNotifications] = useState<INotification[]>([]);

    useEffect(() => {

        let unread = [];
        let read   = [];

        for (let notification of notifications) {
            if (notification.status === NotificationStatus.Read) {
                read.push(notification);
            } else {
                unread.push(notification);
            }
        }

        setUnreadNotifications(unread);
        setReadNotifications(read);

    }, [notifications]);

    useEffect(() => {

        const pushHandler = (notification: IRuntimeNotification) => {

            const normalizeNotification: INotification = {
                ...notification,
                status: NotificationStatus.New
            }

            setUnreadNotifications(prev => ([normalizeNotification, ...prev]));
        }

        const readHandler = (notification: INotificationBase) => {
            
            setUnreadNotifications(prev => prev.filter(x => x.id !== notification.id));
            setReadNotifications(prev => {

                const normalizeNotification: INotification = {
                    ...notification,
                    status: NotificationStatus.Read
                }

                return [normalizeNotification, ...prev];
            });
        }

        notificationHub.on('pushNotification', pushHandler);
        notificationHub.on('readNotification', readHandler);

        return () => {
            notificationHub.off('pushNotification', pushHandler);
            notificationHub.off('readNotification', readHandler);
        }

    }, []);

    const notificationsRenderer = (list: INotification[]) => {
        
        if (list.length < 1) {
            return (
                <Empty description="Нет новых уведомлений" image={Empty.PRESENTED_IMAGE_SIMPLE} />
            );
        }

        return  list.map(notification => (
                    <NotificationItem 
                        notification={notification} 
                        key={notification.id} 
                    />
                ));
    }

    const handleReadAllClick = () => {

        let now = new Date();

        for (let i = unreadNotifications.length - 1; i > -1; i--) {
            const notification = unreadNotifications[i];
            notificationHub.fire('readNotification', notification, now);
        }
    }

    const items = [ 
        {
            key: 'unread',
            label: 'Непрочитанные',
            children: notificationsRenderer(unreadNotifications),
        },
        {
            key: 'read',
            label: 'Прочитанные',
            children: notificationsRenderer(readNotifications),
        },
    ]

    const readAllBtn = unreadNotifications.length < 1
        ? undefined
        : {right: (
            <Button
                size="small"
                onClick={handleReadAllClick}
            >
                Прочитать все
            </Button>
        )}

    return (
        <Tabs
            tabBarExtraContent={readAllBtn}
            defaultActiveKey="unread"
            items={items} 
            className="notificationTabs"
        />
    );
}