import moment from "moment";
import { IActivityBase, ActivityType, ActivityPointType } from "../../Entities/Database";
import { IScheduleEmployeeActivity, normalizeDate, RequiredActivityData } from "../../Entities/IScheduleEmployeeActivity";
import { InvalidDataException } from "../../System/InvalidDataException";
import { colorToStyle, isString } from "../../System/Utils";
import { IEditEventBag } from "./edit-event-modal";
import { getEditableFlag } from "./helpers";
import { detectCollision, detectEmbedding, getEventId, splitAndGluingEvent, splitEvent } from "./utils";
import { MbscCalendarEvent, MbscEventClickEvent, MbscEventCreateEvent, MbscEventDeleteEvent, MbscEventUpdateEvent } from "../shared/lib";

export type EventOperationActionType = 'click' | 'create';

export type EventClickAction = {
    type: 'click',
    data: MbscEventClickEvent
}

export type EventCreateAction = {
    type: 'create',
    data: MbscEventCreateEvent
}

export type EventDeleteAction = {
    type: 'delete',
    data: MbscEventDeleteEvent
}

export type EventUpdateAction = {
    type: 'update',
    data: MbscEventUpdateEvent
}

export type EventEmbeddingAction = {
    type: 'embedding',
    data: MbscEventCreateEvent & {
        splitEvent: MbscCalendarEvent,
        originalEmbeddingAction: EventClickAction | EventCreateAction,
    }
}

export type EventNotProductiveReplaceAction = {
    type: 'notProductiveReplace',
    data: (MbscEventClickEvent | MbscEventCreateEvent) & {
        originalNotProductiveAction: EventClickAction | EventCreateAction | EventEmbeddingAction
    }
}

export type EventOperationAction = EventClickAction | EventCreateAction | EventDeleteAction | EventUpdateAction | EventEmbeddingAction | EventNotProductiveReplaceAction;

export type EventEditAction<TBase extends IActivityBase> = {
    type: 'edit',
    data: {
        bag: IEditEventBag<TBase>,
        action: EventOperationAction,
        rollback: IScheduleEmployeeActivity[]
    }
}

export function normalizeAction(
    action: EventClickAction | EventCreateAction,
    events: IScheduleEmployeeActivity[],
    timeCellStep: number
) {
 
    let normalizedAction: EventOperationAction = action;

    if (normalizedAction.type === 'create') {

        const targetEvent = normalizedAction.data.event;
        const collision = detectCollision(targetEvent as any, events);

        if (collision !== false) {

            const embedding = detectEmbedding(targetEvent as any, events);

            if (embedding === false) {

                // Колизия между элементами,
                // не являющаяся врезкой нового события
                return false;
            }

            normalizedAction = {
                type: 'embedding',
                data: {
                    ...normalizedAction.data,
                    splitEvent: embedding,
                    originalEmbeddingAction: action
                },
            }
        }
    }

    const targetEvent = normalizedAction.type === 'embedding'
                    ? normalizedAction.data.splitEvent
                    : normalizedAction.data.event;

    const originalActivity = targetEvent.originalActivity as IScheduleEmployeeActivity['originalActivity'] | undefined;
    
    if(originalActivity
    && originalActivity.notProductive === true
    && (normalizedAction.type === 'click' || normalizedAction.type === 'embedding')) {

        const dataEvent = { ...normalizedAction.data.event };

        if (normalizedAction.type === 'click') {

            const newStart = new Date(normalizeDate(normalizedAction.data.event.start));
            const newEnd   = new Date(normalizeDate(normalizedAction.data.event.end));

            dataEvent.allDay = undefined;
            dataEvent.start = newStart;
            dataEvent.end = newEnd;
        }

        normalizedAction = {
            type: 'notProductiveReplace',
            data: {
                ...normalizedAction.data,
                event: dataEvent,
                originalNotProductiveAction: normalizedAction
            }
        }
    }

    return normalizedAction;
}

export function transformToNewActivity(
    event: MbscCalendarEvent
): IScheduleEmployeeActivity {

    const tmpStart = event.start;
    const tmpEnd = event.end;

    if (!tmpStart || !tmpEnd) {
        throw new Error("Event start or end value incorrect");
    }

    const resource = event.resource;

    if (!isString(resource)) {
        throw new Error("Event resource type must be string");
    }
    
    const originalActivity: RequiredActivityData = {
        id: undefined,
        typeId: ActivityType.New,
        pointTypeId: ActivityPointType.None
    };

    return {
        id: event.id as string,
        title: event.title as string,
        resource: resource,
        start: normalizeDate(tmpStart),
        end: normalizeDate(tmpEnd),
        color: event.color,
        editable: true,
        originalActivity: originalActivity
    };
}

export const transformEvent = (
    bag: IEditEventBag<IActivityBase>,
    event: IScheduleEmployeeActivity | MbscCalendarEvent,
    keepOriginal: boolean,
    keepId: boolean = false,
): IScheduleEmployeeActivity => {

    const tmpStart = event.start;
    const tmpEnd = event.end;

    if(!tmpStart || !tmpEnd) {
        throw new Error("Event start or end value incorrect");
    }

    const resource = event.resource;

    if(!isString(resource)) {
        throw new Error("Event resource type must be string");
    }

    const base  = bag.base;
    const start = normalizeDate(tmpStart);
    const end   = bag.values.duration !== undefined
                ? normalizeDate(moment(start).add(bag.values.duration, 'minute').toDate())
                : normalizeDate(tmpEnd);

    const originalActivity: IScheduleEmployeeActivity['originalActivity'] = {
        ...(keepOriginal ? event.originalActivity : {}),
        typeId: base.typeId,
        pointTypeId: bag.values.pointTypeId ?? ActivityPointType.None
    };

    const id = keepId
            ? event.id as string ?? getEventId()
            : getEventId();

    return {
        id: id,
        title: base.caption,
        resource: resource,
        start: start,
        end: end,
        color: colorToStyle(base.backgroundColor),
        editable: keepOriginal ? getEditableFlag(originalActivity) : true,
        originalActivity: originalActivity
    };
}

export function deleteActivity(action: EventDeleteAction, events: IScheduleEmployeeActivity[]) {
    const event = action.data.event;
    return events.filter(x => x.id !== event.id);
}

export function createNewActivity(action: EventCreateAction, events: IScheduleEmployeeActivity[]) {

    const event = action.data.event;
    const activity = transformToNewActivity(event);
    const newEvents = [...events];

    newEvents.push(activity);

    return newEvents;
}

export function embeddingNewActivity(action: EventEmbeddingAction, events: IScheduleEmployeeActivity[]) {

    const event = action.data.event;
    const splitter = transformToNewActivity(event);
    const newEvents: IScheduleEmployeeActivity[] = [];

    for(const newEvent of events) {

        const split = splitEvent(splitter, newEvent);

        if(split === false) {
            newEvents.push(newEvent);
        }
        else if(!isString(split)) {
            newEvents.push(...split);
        }
    }

    newEvents.push(splitter);
    return newEvents;
}

export function notProductiveReplaceByNewActivity(action: EventNotProductiveReplaceAction, events: IScheduleEmployeeActivity[]) {

    const newEvents = [...events];
    const event = action.data.event;
    const activity = transformToNewActivity(event);
    const originalNotProductiveEvent = action.data.originalNotProductiveAction;

    let replaceEvent = event;

    if (originalNotProductiveEvent.type === 'embedding') {
        replaceEvent = originalNotProductiveEvent.data.splitEvent;
    }

    const eventKey = newEvents.findIndex(event => event.id === replaceEvent.id);

    if(eventKey === -1) {
        throw new InvalidDataException(`Event width id = '${replaceEvent.id}' hot found for replace`);
    }

    newEvents[eventKey] = activity;
    
    return newEvents;
}

export function updateActivities(action: EventUpdateAction, events: IScheduleEmployeeActivity[]) {

    const targetEvent = action.data.event;
    const newStart = targetEvent.start;
    const newEnd = targetEvent.end;

    if (newStart === undefined || newEnd === undefined) {
        return [...events];
    }
    
    const resource = targetEvent.resource;
    const normalizeStart = normalizeDate(newStart);
    const normalizeEnd = normalizeDate(newEnd);
    const newEvents = [...events];
    const eventKey = newEvents.findIndex(event => event.id === targetEvent.id);

    if(eventKey === -1) {
        throw new InvalidDataException(`Event width id = '${targetEvent.id}' hot found for update`);
    }

    if(!isString(resource)) {
        throw new InvalidDataException(`Event resource must be string`);
    }

    const updateEvent = newEvents[eventKey];
    const splitter: IScheduleEmployeeActivity = {
        ...updateEvent,
        ...targetEvent,
        id: updateEvent.id,
        resource: resource,
        start: normalizeStart,
        end: normalizeEnd
    };
    
    const splitAndGluingEvents = splitAndGluingEvent(splitter, newEvents);
    
    if(splitAndGluingEvents !== false) {
        splitAndGluingEvents.push(splitter);
        return splitAndGluingEvents;
    }

    const clearEvents = newEvents.filter(event => event.id !== splitter.id && event.resource === splitter.resource);
    const collision = detectCollision(splitter, clearEvents);

    if (collision === false) {
        newEvents[eventKey] = splitter;
    }

    return newEvents;
}

export function editActivity<TBase extends IActivityBase>(action: EventEditAction<TBase>, events: IScheduleEmployeeActivity[]) {

    const initialAction = action.data.action;
    const bag = action.data.bag;
    const rollback = action.data.rollback;
    const event = initialAction.data.event;
    const keepId = initialAction.type === 'click';
    const keepOriginal   = initialAction.type === 'notProductiveReplace'
                        && initialAction.data.originalNotProductiveAction.type === 'click'
                        ? false
                        : true;
    
    const activity = transformEvent(bag, event, keepOriginal, keepId);
    const eventKey = events.findIndex(x => x.id === event.id);

    if(eventKey === -1) {
        console.error(new InvalidDataException(`Event width id = '${event.id}' hot found for replace`));
        return rollback;
    }

    const newEvents = [...events];
    newEvents[eventKey] = activity;

    return newEvents;
}

export function getEventsForAction(action: EventOperationAction | EventEditAction<any>, events: IScheduleEmployeeActivity[]) {

    if (action.type === 'update')               return updateActivities(action, events);
    if (action.type === 'delete')               return deleteActivity(action, events);
    if (action.type === 'create')               return createNewActivity(action, events);
    if (action.type === 'embedding')            return embeddingNewActivity(action, events);
    if (action.type === 'notProductiveReplace') return notProductiveReplaceByNewActivity(action, events);
    if (action.type === 'edit')                 return editActivity(action, events);

    return [...events];
}