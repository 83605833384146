import moment from "moment";
import { IScheduleWorkTime, ISurfaceBreak, TBreak, isLinearBreak } from "../../../Entities/Database";
import { defaultDate, inOneGroup, timeFormat } from "../ScheduleTemplateWorkTimeList/helpers";
import { FiveTime } from "../../../System/Type/Time";
import { RangeValue } from "../../../System/Utils";
import { MbscCalendarEvent, MbscResource } from "../../shared/lib";
export { detectCollision } from "../../ScheduleIntervalEditor/utils";

export const breakColor = "rgb(255, 153, 0)";
export const workTimeResourceId = "work-time-line";
export const resources: MbscResource[] = [{
    id: workTimeResourceId,
    color: breakColor,
}];

export interface IBreakEvent extends MbscCalendarEvent {
    id: string,
    resource: IScheduleWorkTime['dayId'],
    title: string,
    start: Date,
    end: Date,
    originalWorkTime: IScheduleWorkTime,
    originalBreak: TBreak,
}

export interface IConvertBreaksLocale {

}

export interface ICustomBreakRange {
    start: FiveTime,
    leftOffset: number,
    rightOffset: number,
}

export function rangeToDates(range: ICustomBreakRange): RangeValue<moment.Moment> {

    const hhmmss = moment(range.start, timeFormat);

    hhmmss
        .year(defaultDate.getFullYear())
        .month(defaultDate.getMonth())
        .date(defaultDate.getDate());

    const start = hhmmss.clone().add(range.leftOffset, 'minutes');
    const end   = hhmmss.clone().add(range.rightOffset, 'minutes');

    return [start, end];
}

export function getRange(workTime: IScheduleWorkTime, breakItem: TBreak): ICustomBreakRange | undefined {

    if (isLinearBreak(breakItem) ||
        !inOneGroup(workTime, breakItem)) {

        return undefined;
    }

    return {
        start: workTime.start,
        leftOffset: breakItem.leftOffset,
        rightOffset: breakItem.rightOffset,
    }
}

export function convertDiffToMinutes(start: Date, point: Date) {
    return moment(point).diff(start, 'minutes');
}

export function getBreakTitle(breakItem: TBreak, surfaceBreaks: ISurfaceBreak[] | undefined) {
    return surfaceBreaks?.find(x => x.type === breakItem.type)?.title || "Undefined";
}

export function convertBreaks(
    locale: IConvertBreaksLocale,
    breaks: TBreak[] | undefined,
    surfaceBreaks: ISurfaceBreak[] | undefined,
    selectedWorkTime: IScheduleWorkTime | undefined,
    selectedBreak: TBreak | undefined,
    roundMinutes: number = 5
): IBreakEvent[] {

    if (!selectedWorkTime || !breaks) {
        return [];
    }

    const result: IBreakEvent[] = [];

    const hhmmss = moment(selectedWorkTime.start, timeFormat);

    hhmmss
        .year(defaultDate.getFullYear())
        .month(defaultDate.getMonth())
        .date(defaultDate.getDate());

    const workTimeStart = hhmmss.clone();

    for (const breakItem of breaks) {

        if (isLinearBreak(breakItem) || !inOneGroup(selectedWorkTime, breakItem)) {
            continue;
        }

        const start = workTimeStart.clone().add(breakItem.leftOffset, 'minutes').toDate();
        const end   = workTimeStart.clone().add(breakItem.rightOffset, 'minutes').toDate();
        const title = getBreakTitle(breakItem, surfaceBreaks);
        
        result.push({
            id: breakItem.id,
            resource: workTimeResourceId,
            title,
            start,
            end,
            originalBreak: breakItem,
            originalWorkTime: selectedWorkTime,
        });
    }

    return result;
}