import { IVacation } from "../../../Entities/Database";
import { dateFormat, getFreeVacationDaysColor, getLastVacationColor, getLastVacationDuration } from "./helpers";

import "./style.css";

export interface IVacationHelpInfo {
    vacationInfo?: IVacation,
}

export function VacationHelpInfo({
    vacationInfo
}: IVacationHelpInfo) {

    if (!vacationInfo) {
        return <></>
    }

    if (!vacationInfo.completedVacationDate && vacationInfo.unusedDays < 1) {
        return <></>
    }

    const duration = getLastVacationDuration(vacationInfo.completedVacationDate, vacationInfo.completedVacationDuration);

    return (
        <>
            <p className="vacationHelpInfo__title">Справочная информация:</p>
            <p className="vacationHelpInfo__info">
                {/* {!vacationInfo.completedVacationDate &&
                <>У сотрудника не было отпусков</>} */}
                {vacationInfo.completedVacationDate &&
                <>
                    Последний отпуск: {vacationInfo.completedVacationDate.format(dateFormat)} [{vacationInfo.completedVacationDuration} д.]
                    <span 
                        className={`${getLastVacationColor(duration)}`}
                    >
                        {` (${duration} д. назад)`}
                    </span>
                </>}
            </p>
            {vacationInfo.unusedDays > 0 &&
            <p className="vacationHelpInfo__info">
                {`Неиспользованный отпуск:`} 
                <span 
                    className={`${getFreeVacationDaysColor(vacationInfo.unusedDays)}`}
                >
                    {` ${vacationInfo.unusedDays} д.`}
                </span> 
            </p>}
        </>
    )
}