let resizedFinished: number;

export const resizeBodyClassName = 'resizing';
export const resizeEventName = 'resize';
export const resizeEventHandler = (event: any) => {
    clearTimeout(resizedFinished);
    document.body.classList.add('resizing');
    resizedFinished = window.setTimeout(() => {
        document.body.classList.remove(resizeBodyClassName);
    }, 250);
}

window.addEventListener(resizeEventName, resizeEventHandler);