import { useEffect, useMemo, useRef, useState } from "react";
import { IEvent, detectCollision } from "../../ScheduleIntervalEditor/utils";
import { FiveTime } from "../../../System/Type/Time";
import { IScheduleShiftScheme, IWorkTimeFragment, RotationType } from "../../../Entities/Database";
import { classNames, isNumber, isString } from "../../../System/Utils";
import { InvalidDataException } from "../../../System/InvalidDataException";
import { Avatar, Badge, Button, Input, Popover, Segmented, Space, Tooltip } from "antd";
import { CopyOutlined, DeleteOutlined, PlusOutlined, UserAddOutlined } from "@ant-design/icons";
import moment from "moment";
import { Eventcalendar, MbscCalendarColor, MbscEventcalendarOptions, MbscEventcalendarView, MbscLocale, MbscResource, localeRu as mbLocaleRu } from "../../shared/lib";
import './style.css';
import { IValidateProps } from "../../shared/lib/src/core/util/datetime";
import { addMinMax } from "../../shared/libHelpers";

const defaultDate = new Date(9999, 0, 1, 0, 0, 0, 0);
const nextDate    = new Date(9999, 0, 2, 0, 0, 0, 0);
const minDate     = new Date(9999, 0, 1, 0, 0, 0, 0);
const maxDate     = new Date(9999, 0, 2, 15, 55, 0, 0);

const timeFormat = "HH:mm:ss";
const freeDayCssBgClassName = "ap-shift-scheme-freeday-bg";

let idCounter = 0;

function getId() {
    return `ap_ste_${idCounter++}`;
}

function weekId(index: number) {
    return `week_${index}`;
}

function parseWeekId(id: string): number | null {

    if (id.startsWith(`week_`)) {
        return +id.substring(`week_`.length);
    }

    return null;
}

export interface IScheduleShiftSchemeEditorLocale {
    editorLocale: MbscLocale,
    workTime: string,
    weekDaysShortName: string[],
    weekShort: string,
    week: string,
}

export const localeRu: IScheduleShiftSchemeEditorLocale = {
    editorLocale: mbLocaleRu,
    workTime: 'Рабочее время',
    weekDaysShortName: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
    weekShort: 'Нд', 
    week: 'Неделя',
}

export const footerResourceId = 'footerResource';
export const foterColor: MbscCalendarColor = {
    resource: footerResourceId,
    start: minDate,
    end: maxDate,
    cssClass: 'ap-shift-scheme-footer-resource-bg'
}

function allowAction(event: IEvent, events: readonly IEvent[]) {

    if (detectCollision(event, events) !== false) {
        return false;
    }

    if (!event.start || event.start >= nextDate) {
        return false;
    }

    return true;
}

export interface IInternalWorkTimeFragment {
    id: string,
    title: string,
    resource: number,
    start: Date,
    end: Date,
    color?: string,
    originalFragment: IWorkTimeFragment | null,
}

export const weekSize = 7;

export function isFreeDay(key: number | string, fragments: readonly IInternalWorkTimeFragment[]) {
    return fragments.findIndex(x => x.resource == key) === -1;
}

export function getMaxDay(days: readonly MbscResource[]) {

    let maxDay = -1;

    for (const resource of days) {

        if (resource.children) {
            let tmp = getMaxDay(resource.children);
            if (tmp > maxDay) {
                maxDay = tmp;
            }
        }
        else {

            const dayKey = +resource.id;

            if (dayKey > maxDay) {
                maxDay = dayKey;
            }
        }
    }

    return maxDay;
}

export function getMaxDayFromFragments(fragments: readonly IWorkTimeFragment[]) {
    
    let maxDay = 0;

    for (const fragment of fragments) {

        if (maxDay < fragment.dayKey) {
            maxDay = fragment.dayKey
        }
    }

    return maxDay;
}

export function convertFromWorkTimeList(locale: IScheduleShiftSchemeEditorLocale, fragments: readonly IWorkTimeFragment[]) {

    const result: IInternalWorkTimeFragment[] = [];

    for (let fragment of fragments) {

        if (fragment.isDayOFF) {
            continue; 
        } 

        const hhmmss = moment(fragment.start, timeFormat);

        hhmmss
            .year(defaultDate.getFullYear())
            .month(defaultDate.getMonth())
            .date(defaultDate.getDate());

        const start = hhmmss.toDate();
        const end   = hhmmss.clone().add(fragment.duration, 'minutes').toDate();

        result.push({
            originalFragment: fragment,
            id: getId(),
            resource: fragment.dayKey,
            title: locale.workTime,
            start: start,
            end: end,
        });
    }

    return result;
}

export function convertToWorkTimeList(
    shiftScheme: IScheduleShiftScheme | undefined,
    days: readonly MbscResource[],
    fragments: readonly IInternalWorkTimeFragment[]
): IWorkTimeFragment[] {

    const sssId = shiftScheme?.sss_id ?? -1;
    const result: IWorkTimeFragment[] = [];
    const dayKeys = new Set<Number>();

    let fragmentMaxDay = 0;

    for (let fragment of fragments) {

        if (!isNumber(fragment.resource)) {
            throw new InvalidDataException('Невалидный id ресурса фрагмента.');
        }

        const dayKey = +fragment.resource;
        const start  = moment(fragment.start);
        const end    = moment(fragment.end);

        dayKeys.add(dayKey);

        if (fragmentMaxDay < dayKey) {
            fragmentMaxDay = dayKey;
        }

        result.push({
            sssId: sssId,
            dayKey: dayKey,
            isDayOFF: false,
            start: start.format(timeFormat) as FiveTime,
            duration: end.diff(fragment.start, 'minutes', true),
        });
    }

    const realMaxDay = getMaxDay(days);
    const normalizeMaxDay = (shiftScheme === undefined || shiftScheme?.sss_WDType_ID === RotationType.Week)
        ? (Math.ceil(realMaxDay / weekSize) * weekSize)
        : realMaxDay;
    
    const normalizeMaxDayFor = normalizeMaxDay + 1;

    for (let dayKey = 1; dayKey < normalizeMaxDayFor; dayKey++) {

        if (!dayKeys.has(dayKey)) {

            result.push({
                sssId: sssId,
                dayKey: dayKey,
                isDayOFF: true,
                start: null,
                duration: null,
            });
        }
    }

    return result;
}

export function buildResources(
    rotationType: RotationType,
    maxDay: number,
    locale: IScheduleShiftSchemeEditorLocale,
    internalFragments: readonly IInternalWorkTimeFragment[],
): [MbscResource[], MbscCalendarColor[], MbscResource[]] {

    const colors: MbscCalendarColor[] = [foterColor];
    const days: MbscResource[] = [];
    const weeks: MbscResource[] = [];

    let weekIdx = 0;
    let weekBuf = 0;

    const isWeekView = rotationType === RotationType.Week;
    const normalizeMaxDay = isWeekView
        ? Math.ceil(maxDay / weekSize) * weekSize
        : maxDay;

    for (let i = 0; i < normalizeMaxDay; i++) {

        const weekDay = i % weekSize;
        const dayKey = i + 1;
        const dayResource: MbscResource = {
            id: dayKey,
            name: isWeekView
                ? locale.weekDaysShortName[weekDay]
                : `${dayKey}`,
        };

        weekBuf++;

        if (weekBuf > weekSize) {
            weekBuf = 1;
            weekIdx++;
        }

        const week = weeks[weekIdx] ?? {
            id: weekId(weekIdx),
            name: `${locale.weekShort} ${weekIdx + 1}`,
            isParent: true,
            eventCreation: false,
            children: [],
        };

        if (!weeks[weekIdx]) {
            weeks.push(week);
        }

        days.push(dayResource);
        week.children?.push(dayResource);

        if (isFreeDay(dayKey, internalFragments)) {

            colors.push({
                resource: dayKey,
                start: minDate,
                end: maxDate,
                cssClass: freeDayCssBgClassName,
            });
        }
    }

    if (rotationType === RotationType.Week) {

        return [weeks, colors, days];
    }

    return [days, colors, days];
}

export interface IScheduleShiftSchemeEditorProps {
    width?: number,
    height?: number,
    locale?: IScheduleShiftSchemeEditorLocale,
    shiftScheme?: IScheduleShiftScheme,
    newShiftScheme?: Partial<IScheduleShiftScheme>,
    fragments?: readonly IWorkTimeFragment[],
    loading?: boolean,
    onChangeSchemeFields?: (names: (keyof IScheduleShiftScheme)[], newScheme: IScheduleShiftScheme) => void,
    onChangeFragments?: (fragments: IWorkTimeFragment[]) => void,
    onBindEmployeeBtnClick?: () => void,
}

export function ScheduleShiftSchemeEditor({
    width,
    height,
    loading,
    locale = localeRu,
    shiftScheme,
    newShiftScheme,
    fragments,
    onChangeSchemeFields,
    onChangeFragments,
    onBindEmployeeBtnClick,
}: IScheduleShiftSchemeEditorProps) {

    const prevSssIdRef = useRef<IWorkTimeFragment['sssId'] | undefined>(undefined);
    const editorRef = useRef<Eventcalendar>(null);

    const timeStep = 60;
    const dragTimeStep = 5;

    const view = useMemo<MbscEventcalendarView>(() => ({
        timeline: {
            size: 2,
            type: 'day',
            startDay: 1,
            endDay: 0,
            timeCellStep: timeStep,
            timeLabelStep: timeStep,
            resolutionVertical: 'day',
            startTime: '00:00',
            endTime: '00:00',
        }
    }), [timeStep]);

    const [rotationType, setRotationType] = useState(shiftScheme?.sss_WDType_ID ?? RotationType.Month);
    const [internalFragments, setInternalFragments] = useState<IInternalWorkTimeFragment[]>([]);
    const [maxDay, setMaxDay] = useState(() => rotationType === RotationType.Week ? weekSize : 0);
    const [resources, colors, days] = useMemo(() => buildResources(rotationType, maxDay, locale, internalFragments), [rotationType, maxDay, locale, internalFragments]);
    const resourcesWithControls = useMemo((): MbscResource[] => {

        return [
            ...resources,
            {
                id: footerResourceId,
                name: '',
                eventCreation: false,
            }
        ];

    }, [resources]);

    const handleSchemeChangeByControls = <T extends keyof IScheduleShiftScheme,>(name: T, newValue: IScheduleShiftScheme[T]) => {

        if (onChangeSchemeFields && shiftScheme) {

            onChangeSchemeFields([name], {
                ...shiftScheme,
                [name]: newValue,
            });
        }
    }

    const handleInternalChanges = (newMaxDay: number, newInternalFragments: readonly IInternalWorkTimeFragment[]) => {

        const [resources] = buildResources(rotationType, newMaxDay, locale, newInternalFragments);
        const wts = convertToWorkTimeList(shiftScheme, resources, newInternalFragments);

        if (onChangeFragments) {
            onChangeFragments(wts);
        }
    }

    const handleEventDoubleClick: NonNullable<MbscEventcalendarOptions['onEventDoubleClick']> = (args, inst) => {
        
    }

    const handleEventUpdate: NonNullable<MbscEventcalendarOptions['onEventUpdate']> = (args, inst) => {

        const tmpFragment  = args.event as IInternalWorkTimeFragment;
        const tmpFragmentss = internalFragments.filter(x => x.id !== tmpFragment.id);

        return allowAction(tmpFragment, tmpFragmentss);
    }

    const handleEventUpdated: NonNullable<MbscEventcalendarOptions['onEventUpdated']> = (args, inst) => {

        const updatedFragment = args.event as IInternalWorkTimeFragment;
        const newInternalFragments = [...internalFragments];
        const index = newInternalFragments.findIndex(x => x.id === updatedFragment.id);

        if (index !== -1) {

            newInternalFragments[index] = {
                ...newInternalFragments[index],
                ...updatedFragment,
            };
        }

        setInternalFragments(newInternalFragments);
        handleInternalChanges(maxDay, newInternalFragments);
    }

    const handleEventUpdateFailed: NonNullable<MbscEventcalendarOptions['onEventUpdateFailed']> = (args, inst) => {
        
    }

    const handleEventDragStart: NonNullable<MbscEventcalendarOptions['onEventDragStart']> = (args, inst) => {

    }

    const handleEventDragEnd: NonNullable<MbscEventcalendarOptions['onEventDragEnd']> = (args, inst) => {

    }

    const handleEventDeleted: NonNullable<MbscEventcalendarOptions['onEventDeleted']> = (args, inst) => {
        
        const deletableFragment = args.event as IInternalWorkTimeFragment;
        const newInternalFragments = internalFragments.filter(x => x.id !== deletableFragment.id);

        setInternalFragments(newInternalFragments);
        handleInternalChanges(maxDay, newInternalFragments);
    }

    const handleEventCreated: NonNullable<MbscEventcalendarOptions['onEventCreated']> = (args, inst) => {
        
        const newTmpFragment = args.event;
        const newInternalFragments = [...internalFragments];

        if (allowAction(newTmpFragment, newInternalFragments)) {

            const newFragment: IInternalWorkTimeFragment = {
                ...newTmpFragment as IInternalWorkTimeFragment,
                originalFragment: null,
                id: getId(),
            }

            newInternalFragments.push(newFragment);
        }

        setInternalFragments(newInternalFragments);
        handleInternalChanges(maxDay, newInternalFragments);
    }
    
    const deleteFragments = (index: number, count: number = 1): IInternalWorkTimeFragment[] => {

        const newInternalFragments: typeof internalFragments = [];
        const moreIndex = index + count;

        for (let fragment of internalFragments) {

            const less = fragment.resource < index;
            const more = fragment.resource >= moreIndex;

            if (less || more) {

                if (more) {
                    fragment.resource -= count;
                }

                newInternalFragments.push(fragment);
            }
        }

        return newInternalFragments;
    }

    const copyFragments = (index: number): IInternalWorkTimeFragment[] => {

        const newInternalFragments: typeof internalFragments = [];

        for (let fragment of internalFragments) {

            if (fragment.resource === index) {
                newInternalFragments.push(fragment);
                newInternalFragments.push({
                    ...fragment,
                    resource: fragment.resource + 1
                });
                continue;
            }

            const less = fragment.resource < index;
            const more = fragment.resource >= index + 1;

            if (less || more) {

                if (more) {
                    fragment.resource += 1;
                }

                newInternalFragments.push(fragment);
            }
        }

        return newInternalFragments;
    }

    const calcMaxDay = (x: number, newRotationType?: RotationType) => (newRotationType ?? rotationType) === RotationType.Week
    ? Math.ceil(x / weekSize) * weekSize
    : x;

    const addDay = () => {
        const newMaxDay = calcMaxDay(maxDay + 1);
        setMaxDay(newMaxDay);
        handleInternalChanges(newMaxDay, internalFragments);
    }

    const copyDay = (index: number) => {
        const newInternalFragments = copyFragments(index);
        const newMaxDay = maxDay + 1;
        setMaxDay(newMaxDay);
        setInternalFragments(newInternalFragments);
        handleInternalChanges(newMaxDay, newInternalFragments);
    }

    const deleteDay = (index: number) => {
        const newInternalFragments = deleteFragments(index, 1);
        const newMaxDay = maxDay - 1;
        setMaxDay(newMaxDay);
        setInternalFragments(newInternalFragments);
        handleInternalChanges(newMaxDay, newInternalFragments);
    }

    const deleteWeek = (index: number) => {
        const newInternalFragments = deleteFragments(index * weekSize + 1, weekSize);
        const newMaxDay = ((Math.ceil((maxDay || 1) / weekSize) || 1) * weekSize) - weekSize;
        setMaxDay(newMaxDay);
        setInternalFragments(newInternalFragments);
        handleInternalChanges(newMaxDay, newInternalFragments);
    }

    const changeRotationType = (newRotationType: RotationType) => {

        const newMaxDay = calcMaxDay(maxDay, newRotationType);

        setRotationType(newRotationType);
        setMaxDay(newMaxDay);

        if (maxDay !== newMaxDay) {
            handleInternalChanges(newMaxDay, internalFragments);
        }

        handleSchemeChangeByControls('sss_WDType_ID', newRotationType);
    }

    const changeTitle = (newTitle: string) => {
        handleSchemeChangeByControls('title', newTitle);
    }

    useEffect(() => {

        const newRotationType = shiftScheme?.sss_WDType_ID ?? RotationType.Month;

        if (!fragments || fragments.length === 0) {
            const newMaxDay = newRotationType === RotationType.Month ? 0 : weekSize; 
            setMaxDay(newMaxDay);
        }

        if (rotationType !== newRotationType) {
            setRotationType(newRotationType);
        }

    }, [shiftScheme?.sss_id]);

    useEffect(() => {
        const newRotationType = shiftScheme?.sss_WDType_ID ?? RotationType.Month;
        setMaxDay(x => calcMaxDay(x, newRotationType));
        setRotationType(newRotationType);
    }, [shiftScheme?.sss_WDType_ID]);

    useEffect(() => {

        const normlizeFragments = fragments || [];
        const newInternalFramgents = convertFromWorkTimeList(locale, normlizeFragments);
        
        setInternalFragments(newInternalFramgents);
        
        if (newInternalFramgents.length > 0) {

            const firstFragment = newInternalFramgents.reduce((a, b) => a.start < b.start ? a : b);

            if (firstFragment.originalFragment?.sssId !== prevSssIdRef.current) {

                const firstFragmentStart = new Date(+firstFragment.start - (60 * 60 * 1000));
                editorRef.current?.navigate(firstFragmentStart);

                prevSssIdRef.current = shiftScheme?.sss_id;

                const newMaxDay = getMaxDayFromFragments(normlizeFragments);

                setMaxDay(newMaxDay);
            }
        }

    }, [locale, fragments]);
    
    const renderMyResource = (resource: MbscResource) => {

        if (resource.id === footerResourceId) {

            return (
                <div className="ap-schedule-shift-editor-footer">
                    <Tooltip title={rotationType === RotationType.Week
                        ? 'Добавить неделю'
                        : 'Добавить день'
                    }>
                        <Button
                            shape="circle"
                            icon={<PlusOutlined />}
                            onClick={addDay}
                        />
                    </Tooltip>
                </div>
            );
        }

        if (rotationType === RotationType.Week && isString(resource.id)) {

            const weekId = parseWeekId(resource.id);

            if (weekId !== null) {

                return (
                    <Space className="ap-shift-template-editor-week-title">
                        <div className="ap-shift-template-editor-week-text">{resource.name}</div>
                        <Tooltip title="Удалить неделю">
                            <Button
                                size="small"
                                type="link"
                                icon={<DeleteOutlined />}
                                onClick={x => deleteWeek(weekId)}
                                danger
                            />
                        </Tooltip>
                    </Space>
                );
            }
        }

        if (rotationType === RotationType.Month) {

            const dayIndex = +resource.id;
            const content = (
                <Space direction="vertical">
                    <Tooltip title={`Удалить ${dayIndex} день`}>
                        <Button
                            size="small"
                            type="link"
                            icon={<DeleteOutlined />}
                            onClick={x => deleteDay(dayIndex)}
                            danger
                        />
                    </Tooltip>
                    <Tooltip title={`Копировать ${dayIndex} день`}>
                        <Button
                            size="small"
                            type="link"
                            icon={<CopyOutlined />}
                            onClick={x => copyDay(dayIndex)}
                        />
                    </Tooltip>
                </Space>
            )

            return (
                <Popover placement="right" content={content} zIndex={3000}>
                    <div className="ap-schedule-shift-day-index">{resource.name}</div>
                </Popover>
            );
        }

        return (
            <div>{resource.name}</div>
        );
    }

    const normalizeInvalids = useMemo(() => {
        const tmpInvalids: IValidateProps[] = [];
        addMinMax(tmpInvalids, minDate, maxDate);
        return tmpInvalids;
    }, [minDate, maxDate]);

    return (
        <>
            <Space className="ap-shift-template-editor-header">
                <div>
                    <p className="header-item-label">Название шаблона:</p>
                    <Input
                        size="small"
                        value={newShiftScheme?.title}
                        onChange={v => changeTitle(v.target.value)}
                    />
                </div>
                <div>
                    <p className="header-item-label">Тип ротации:</p>
                    <Segmented
                        size="small"
                        value={rotationType}
                        options={[
                            {value: RotationType.Month, label: 'По дням'},
                            {value: RotationType.Week,  label: 'День недели'},
                        ]}
                        onChange={v => changeRotationType(v as RotationType)}
                    />
                </div>
                <div>
                    <p className="header-item-label">Часовой пояс:</p>
                    <Input
                        size="small"
                        value={newShiftScheme?.tz}
                        readOnly
                    />
                </div>
                <div>
                    <p className="header-item-label">Категория шаблона:</p>
                    <Segmented
                        size="small"
                        value={newShiftScheme?.isPublic ? 1 : 2}
                        options={[
                            {value: 1,  label: 'Стандартный'},
                            {value: 2,  label: 'Проектный'},
                        ]}
                        readOnly
                    />
                </div>
                <div>
                    <Badge count={shiftScheme?.staffCount ?? 0} title="Количество привязанных сотрудников к шаблону">
                        <Tooltip title="Привязать сотрудника к шаблону">
                            <Avatar
                                shape="circle"
                                icon={<UserAddOutlined />}
                                style={{cursor: 'pointer'}}
                                onClick={onBindEmployeeBtnClick}
                            />
                        </Tooltip>
                    </Badge>
                </div>
            </Space>
            <Eventcalendar
                ref={editorRef}
                wrapperClass="ap-shift-template-editor-wrapper"
                className={classNames(
                    'ap-shift-template-editor', {
                    'ap-week-view': rotationType === RotationType.Week,
                    'ap-day-view': rotationType === RotationType.Month,
                })}
                theme="ios"
                themeVariant="light"
                width="100%"
                height="100%"
                selectMultipleEvents={false}
                showControls={false}
                showOuterDays={false}
                clickToCreate
                dragToMove
                dragToResize
                dragToCreate
                refDate={defaultDate}
                dragTimeStep={dragTimeStep}
                newEventText={locale.workTime}
                locale={locale.editorLocale}
                view={view}
                resources={resourcesWithControls}
                invalid={normalizeInvalids}
                colors={colors}
                data={internalFragments}
                renderResource={renderMyResource}
                onEventDragStart={handleEventDragStart}
                onEventDragEnd={handleEventDragEnd}
                onEventDoubleClick={handleEventDoubleClick}
                onEventDeleted={handleEventDeleted}
                onEventUpdate={handleEventUpdate}
                onEventUpdated={handleEventUpdated}
                onEventUpdateFailed={handleEventUpdateFailed}
                onEventCreated={handleEventCreated}
            />
        </>
    );
}

export default ScheduleShiftSchemeEditor;