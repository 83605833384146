import { RotationType } from "../../../Entities/Database";

const pink = "#BB6BD9";
const green = "#4BAF4F";


export function getScheduleShiftTitleColor(isPublic: boolean) {
    if (isPublic) {
        return pink;
    } else {
        return green;
    }

}

export function getWDTypeTitle(sssWDTypeId: RotationType) {
    switch(sssWDTypeId) {
        case RotationType.Month: return "День месяца";
        case RotationType.Week: return "День недели";
        default: break;
    }
    return "";
}