import moment from "moment";
import { IEmployeeScheduleDay, IEmployee, IScheduleBoardEmployee, IDaysInfoblockItem, IEmployeeScheduleDayMarker } from "../../Entities/Database";
import { isNullOrUndefined, isString } from "../../System/Utils";
import { ColumnType } from "antd-virtual-table";
import { ILoadListState } from "../../Reducer/LoadListReducer";

export interface IMarkersState extends ILoadListState<IEmployeeScheduleDayMarker> {
}

export interface IScheduleEmployeeDayWithMarkers extends IEmployeeScheduleDay {
    markers?: IMarkersState
}

export interface IScheduleEmployeeDataSourceItem {
    PID: string,
    employee: IScheduleBoardEmployee,
    scheduleDays: Record<string, IScheduleEmployeeDayWithMarkers>
}

export type ScheduleEmployeeDataSource = IScheduleEmployeeDataSourceItem[];

export type RecordItemEventHandler<TEvent> = (date: IScheduleEmployeeDayWithMarkers, event: TEvent) => void;
export type RecordItemElementEvent<TEvent> = TEvent & {
    recordItem: HTMLElement | null;
}

export const virtualGridItemClassName = 'virtial-grid-item';
export const virtualGridItemSelector = `.${virtualGridItemClassName}`;
export const virtualGridRowClassName = 'virtial-grid-row';
export const virtualGridRowSelector = `.${virtualGridRowClassName}`;
export const dateKeyFormat = "YYYY.MM.DD";
export const infoblockCollapsePanelKey = '1';

export const infoBlockDisplayed = (key: string | string[]) => key === infoblockCollapsePanelKey || key.length === 1 && key[0] === infoblockCollapsePanelKey;
export const getFIO = (employee: IEmployee) => `${employee.surname} ${employee.firstName} ${employee.secondName}`;
export const plugShouldCellUpdateOnScroll: ColumnType<IDaysInfoblockItem>['shouldCellUpdate'] = (record, prevRecord, isScrolling) => {
    
    if(isScrolling === true) {
        return false;
    }

    return record !== prevRecord;
}

export interface IScheduleRecordItemBase {
    PID: IEmployeeScheduleDay['PID'],
    date: IEmployeeScheduleDay['date']
}

export interface IItemElementPosition {
    rowIndex: number,
    columnIndex: number,
    originalColumnIndex: number
}

export function recordItemComparer(a: string, b: string): boolean;
export function recordItemComparer(a: string, b: IScheduleRecordItemBase): boolean;
export function recordItemComparer(a: IScheduleRecordItemBase, b: string): boolean;
export function recordItemComparer(a: IScheduleRecordItemBase, b: IScheduleRecordItemBase): boolean;
export function recordItemComparer(a: string | IScheduleRecordItemBase, b: string | IScheduleRecordItemBase): boolean {

    if (a === b) {
        return true;
    }

    if (isString(a)) {

        if (isString(b)) {

            return a === b;
        }
        
        return a === getItemKey(b);
    }

    if (isString(b)) {

        if (isString(a)) {

            return a === b;
        }
        
        return b === getItemKey(a);
    }

    return a.PID === b.PID && a.date.valueOf() === b.date.valueOf();
}

export const itemKeyAttributeName = "item-key";
export const itemGridRowIndexAttributeName = "data-row-index";
export const itemGridColumnIndexAttributeName = "data-column-index";
export const itemGridOriginalColumnIndexAttributeName = "data-original-column-index";

export function parseItemKey(key: string | null): IScheduleRecordItemBase | undefined {
    
    if(isNullOrUndefined(key)) {
        return undefined;
    }

    const vars = key.split('_');
    const PID = vars[0];
    const date = moment(vars[1], dateKeyFormat);

    return {
        PID,
        date
    }
}

export function getItemKeyFromElement(element: HTMLElement | Element) {
    return element.getAttribute(itemKeyAttributeName);
}

export function getItemKey(item: IScheduleRecordItemBase) {
    const dateKey = item.date.format(dateKeyFormat);
    return `${item.PID}_${dateKey}`;
}

export function getItemElement(itemOrKey: string | IScheduleRecordItemBase, parent: ParentNode) {
    const key = isString(itemOrKey) ? itemOrKey : getItemKey(itemOrKey);
    return parent.querySelector<HTMLElement>(`${virtualGridItemSelector}[${itemKeyAttributeName}="${key}"]`);
}

export function isActiveItemKeyElement(item: IScheduleRecordItemBase, parent: HTMLElement) {
    return document.activeElement
        && document.activeElement.classList.contains(virtualGridItemClassName)
        && getItemKeyFromElement(document.activeElement) === getItemKey(item)
        && isParent(document.activeElement, parent, 2);
}

export function hasActiveItemElement(parent: HTMLElement) {
    return document.activeElement
        && document.activeElement.classList.contains(virtualGridItemClassName)
        && isParent(document.activeElement, parent, 2);
}

export function isActiveElement(grid: HTMLElement) {
    return document.activeElement === grid;
}

export function getFirstItemElement(grid: HTMLElement) {
    return grid.querySelector<HTMLElement>(`* > ${virtualGridRowSelector}:first-child > ${virtualGridItemSelector}:first-child`);
}

export function getLastItemElement(grid: HTMLElement) {
    return grid.querySelector<HTMLElement>(`* > ${virtualGridRowSelector}:last-child > ${virtualGridItemSelector}:last-child`);
}

export function isParent(child: Element, parent?: Element, level: number = -1) {

    if (parent) {
        
        let tmpChild : HTMLElement | Element | undefined | null = child;

        while (level > 0) {

            if(!tmpChild) {
                break;
            }

            level--;
            const tmpParnet = tmpChild = tmpChild?.parentElement;
            if (tmpParnet === parent) {
                return true;
            }
        }
    }

    return false;
}

export const isInViewport = (elem: HTMLElement) => {
    const bounding = elem.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};

export type Direction = 'up' | 'down' | 'left' | 'right';

export function getItemElementByDirection(itemOrKey: string | IEmployeeScheduleDay, direction: Direction, parent: HTMLElement) {

    const itemElement = getItemElement(itemOrKey, parent);

    if(itemElement) {

        const rowIndex = parseInt(itemElement.getAttribute(itemGridRowIndexAttributeName) || '');
        const columnIndex = parseInt(itemElement.getAttribute(itemGridColumnIndexAttributeName) || '');

        const desiredRowIndex = direction === 'up' ? rowIndex - 1
                            : direction === 'down' ? rowIndex + 1
                            : rowIndex;

        const desiredColumnIndex = direction === 'right' ? columnIndex + 1
                                : direction === 'left' ? columnIndex - 1
                                : columnIndex;
        
        const selector = `${virtualGridItemSelector}[${itemGridRowIndexAttributeName}="${desiredRowIndex}"][${itemGridColumnIndexAttributeName}="${desiredColumnIndex}"]`;

        return parent.querySelector<HTMLElement>(selector);
    }

    return undefined;
}

export function getItemInfoFromElement(element: HTMLElement | Element) {
    const key = getItemKeyFromElement(element);
    return parseItemKey(key);
}

export function getItemElementPosition(element: HTMLElement | Element): IItemElementPosition {

    const rowIndex = parseInt(element.getAttribute(itemGridRowIndexAttributeName) || '');
    const originalColumnIndex = parseInt(element.getAttribute(itemGridColumnIndexAttributeName) || '');
    const columnIndex = parseInt(element.getAttribute(itemGridColumnIndexAttributeName) || '');

    return {
        rowIndex,
        columnIndex,
        originalColumnIndex
    }
}

export function getItemInfoByDirection(item: IEmployeeScheduleDay, direction: Direction, parent: HTMLElement) {

    const desiredItemElement = getItemElementByDirection(item, direction, parent);

    if(desiredItemElement) {

        return getItemInfoFromElement(desiredItemElement);
    }

    return undefined;
}

export const handleClick = <TEvent extends React.MouseEvent | Event,>(
    handler: RecordItemEventHandler<RecordItemElementEvent<TEvent>> | undefined,
    record: IScheduleEmployeeDataSourceItem,
    dayKey: string,
    headerCellIndex: number | undefined,
    event: TEvent
) => {

    const scheduleDay = record.scheduleDays[dayKey];

    if (scheduleDay && handler) {

        const newEvent: RecordItemElementEvent<TEvent> = event as RecordItemElementEvent<TEvent>;

        newEvent.recordItem = null;

        if(newEvent.target) {
            const targetElement = (event.target as HTMLElement);
            newEvent.recordItem = targetElement.classList.contains(virtualGridItemClassName)
                ? targetElement
                : targetElement.closest(virtualGridItemSelector);
        }

        return handler(scheduleDay, newEvent);
    }
}

export const fixedLeftHeaderHelperForValueTotal: ColumnType<IScheduleEmployeeDataSourceItem> = {
    className: "hide-title",
    width: 20,
    fixed: 'left',
    title: null,
    onHeaderCell: () => ({style: {padding: 0}}),
    sorter: {
        multiple: 2,
        compare: (a, b, sortOrder) => {

            const aValueTotal = a.employee.valueTotal;
            const bValueTotal = b.employee.valueTotal;

            if (!aValueTotal) {
                return -1;
            }

            if (!bValueTotal) {
                return 1;
            }

            return aValueTotal - bValueTotal;
        }
    },
};

export const fixedLeftHeaderHelperForValueTarget: ColumnType<IScheduleEmployeeDataSourceItem> = {
    className: "hide-title",
    width: 20,
    fixed: 'left',
    title: null,
    onHeaderCell: () => ({style: {padding: 0}}),
    sorter: {
        multiple: 1,
        compare: (a, b, sortOrder) => {

            const aValueTarget = a.employee.valueTarget;
            const bValueTarget = b.employee.valueTarget;

            if (!aValueTarget) {
                return -1;
            }

            if (!bValueTarget) {
                return 1;
            }

            return aValueTarget - bValueTarget;
        }
    },
}