import { useEffect, useState } from "react";
import { IEmployee, IEmployeeScheduleDay, IVacationEditorValues, deleteVacation, getVacationInfo, saveVacation } from "../../../Entities/Database";
import { ILoadListState } from "../../../Reducer/LoadListReducer";
import { VacationModalAddEdit } from "../VacationModalAddEdit";
import useAuth from "../../../Hooks/useAuth";
import { message as AntdMessage, Form } from "antd";
import useActual from "../../../Hooks/useActual";
import { BackendResponseException } from "../../../System/BackendResponseException";
import useEntityLoader from "../../../Hooks/useEntityLoader";
import Exception from "../../../System/Exception";

export const abortByClose = {};

export interface IVacationFromScheduleItemModalProps {
    employees: ILoadListState<IEmployee>,
    item: IEmployeeScheduleDay,
    open?: boolean,
    onCancel?: () => void,
    onFinish?: () => void,
}

export function VacationFromScheduleItemModal({ 
    employees, 
    item, 
    open, 
    onCancel,
    onFinish,
}: IVacationFromScheduleItemModalProps) {

    const [form] = Form.useForm<IVacationEditorValues>();
    const auth = useAuth();
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [employee, setEmployee] = useState<IEmployee | undefined>(undefined);
    const [message, messageContextHolder] = AntdMessage.useMessage();
    const [vacationLoadState, reloadVacation,, abortLoadVacation] = useEntityLoader((signal) => getVacationInfo(auth, item.PID, item.date, signal), [item, auth.data?.staticId]);
    const actualOnFinish = useActual(onFinish);

    useEffect(() => {

        if (vacationLoadState.error && vacationLoadState.error !== abortByClose && vacationLoadState.error !== useEntityLoader.abortByMerge) {
            message.error("При загрузке данных возникла ошибка.");
            console.error(vacationLoadState.error);
        }

    }, [vacationLoadState.error]);

    useEffect(() => {
        if (open) reloadVacation();
        else      abortLoadVacation(abortByClose);
    }, [open, item]);

    useEffect(() => {

        if (!open) return;

        if (!employees.load) {
            setEmployee(undefined);
            return;
        }

        const employee = employees.items.find(x => x.PID === item.PID);

        setEmployee(employee);

    }, [open, item, employees.load, employees.items]);

    const handleDelete = async () => {

        try {
            
            if (!vacationLoadState.entity) {
                throw new Exception('Отпуск не инициализирован.');
            }

            if (!vacationLoadState.entity.main.canDelete) {
                throw new Exception('Отпуск не может быть удален.');
            }

            setDeleting(true);
            await deleteVacation(auth, vacationLoadState.entity.main.id);

            if (actualOnFinish.current) {
                actualOnFinish.current();
            }
        }
        catch (ex) {
            message.error(ex instanceof BackendResponseException ? ex.message : 'Не удалось удалить больничный');
            console.error(ex);
        }
        finally {
            setDeleting(false);
        }
    }

    const saveVacationItem = async (data: IVacationEditorValues) => {

        try {

            setSaving(true);
            
            await saveVacation(auth, data);

            if (actualOnFinish.current) {
                actualOnFinish.current();
            }
        }
        catch(ex) {
            message.error(ex instanceof BackendResponseException ? ex.message : "При сохранении данных возникла ошибка.")
            console.error(ex);
        }
        finally {
            setSaving(false);
        }
    }

    const handleFormFinish = (values: any) => {
        saveVacationItem(values);
    }

    const handleOk = () => {
        form.submit();
    }

    return (
        <>
            <VacationModalAddEdit 
                open={open}
                onCancel={onCancel}
                onOk={handleOk}
                employee={employee}
                vacationInfo={vacationLoadState.entity?.main}
                types={vacationLoadState.entity?.typeList}
                statuses={vacationLoadState.entity?.statusList}
                loading={vacationLoadState.loading}
                saving={saving}
                deleting={deleting}
                form={form}
                onFormFinish={handleFormFinish}
                onClickDelete={handleDelete}
            />
            {messageContextHolder}
        </>
    );
}