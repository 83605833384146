import { IScheduleShiftScheme } from "../../../Entities/Database";
import { searchAllEntities } from "../../../Reducer/SearchFilterReducer";

export function filterList(list: readonly IScheduleShiftScheme[] | undefined, value: string): readonly IScheduleShiftScheme[] {
    
    if (!list) {
        return [];
    }

    const result = new Map<IScheduleShiftScheme['sss_id'], IScheduleShiftScheme>();

    const first  = searchAllEntities(list, 'signature', value);
    const second = searchAllEntities(list, 'title', value);
    
    for (const tmp of [...first, ...second]) {
        if (!result.has(tmp.sss_id)) {
            result.set(tmp.sss_id, tmp);
        }
    }

    return [...result.values()];
}

export const templateShiftPatternList = [
    {
        id: "1",
        caption: "5/2 09:00-14:00; 16:00 - 20:00 (разрывная)",
        rotation: "День недели",
        pattern: "7"
    },
    {
        id: "2",
        caption: "5/2 09:00-18:00",
        rotation: "День месяца",
        pattern: "7"
    },
    {
        id: "3",
        caption: "2/2 09:00-14:00; 16:00 - 20:00 (разрывная)",
        rotation: "День недели",
        pattern: "4"
    },
    {
        id: "4",
        caption: "5/2 09:00-14:00; 16:00 - 20:00 (разрывная)",
        rotation: "День недели",
        pattern: "7"
    },
    {
        id: "5",
        caption: "5/2 09:00-18:00",
        rotation: "День месяца",
        pattern: "7"
    },
    {
        id: "6",
        caption: "2/2 09:00-14:00; 16:00 - 20:00 (разрывная)",
        rotation: "День недели",
        pattern: "4"
    },
    {
        id: "7",
        caption: "5/2 09:00-14:00; 16:00 - 20:00 (разрывная)",
        rotation: "День недели",
        pattern: "7"
    },
    {
        id: "8",
        caption: "5/2 09:00-18:00",
        rotation: "День месяца",
        pattern: "7"
    },
    {
        id: "9",
        caption: "2/2 09:00-14:00; 16:00 - 20:00 (разрывная)",
        rotation: "День недели",
        pattern: "4"
    },
];