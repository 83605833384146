import { ReloadOutlined, RiseOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Row, Segmented, Space, Tag } from "antd";
import { Ref, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getIntervalInfoBlockChartData, getIntervalInfoBlockTableData, IIntervalInfoblockTableRecord, InfoblockType, IScheduleBoardEmployee, IScheduleBoardFilter, Module } from "../../Entities/Database";
import { ILoadListState } from "../../Reducer/LoadListReducer";
import { capacitounce, debounce, ignoreSelectEvent, isArray } from "../../System/Utils";
import { DayIntervalEditor, IIntervalActivityEditorProps, TIntervalEditorUpdater } from "../ScheduleIntervalEditor";
import { embedChartItems, normalizeChartItems } from "./utils";
import { ILoaderInitProps, ILoaderProps, useListLoader } from "../../Hooks/useListLoader";
import { infoblockCollapsePanelKey, infoBlockCollapse } from "./helpers";
import { InfoblockTable } from "./table";
import { Chart } from "./chart";
import useAuth from "../../Hooks/useAuth";
import { Eventcalendar } from "../shared/lib";
import { Grid, GridOnScrollProps } from "antd-virtual-table";
import { assignRef } from "../shared/helpers";
import CheckAccess from "../CheckAccess";
import './style.css';

export const scrollWidth = 20;
export const infoblockRowHeight = 30;
export const defaultMinInfoblockContentHeight = infoblockRowHeight * 1 + scrollWidth;
export const defaultMaxInfoblockContentHeight = infoblockRowHeight * 8 + scrollWidth;

export const defaultMinChartInfoblockContentHeight = infoblockRowHeight * 3 + scrollWidth;
export const defaultMaxChartInfoblockContentHeight = infoblockRowHeight * 8 + scrollWidth;

const lang = {
    statistic: 'Статистика',
    viewTable: 'Таблица',
    viewGraph: 'График',
    fifteenMinutes: '15 Минут',
    sixtyMinutes: '60 Минут',
    update: 'Обновить',
    shiftFactor: 'К. Сменности',
    optimized: 'Оптимизировать'
}

const loadProps: ILoaderProps = {
    clearList: true,
}

const loadConstructorProps: ILoaderInitProps<any> = {
    initialState: { loading: true }
}

const reloadLoaderProps: ILoaderProps = {
    clearList: false,
}

const syncLoaderProps: ILoaderProps = {
    clearList: false,
    backgroundLoading: true
}

export interface IIntervalActivityBoardUpdater {
    reload: () => void;
}

export interface IIntervalActivityBoardProps {
    updaterRef?: Ref<IIntervalActivityBoardUpdater>,
    loading?: boolean,
    disableControl?: boolean,
    width: number,
    height: number,
    filter: IScheduleBoardFilter,
    date: moment.Moment,
    employees: ILoadListState<IScheduleBoardEmployee>,
    employee?: IScheduleBoardEmployee,
    onOpenGeneratorBtnClick?: () => void,
    onActivitiesSave?: () => void,
    onActivitiesChange?: () => void,
    onFinishActivitiesSave?: () => void
}

export function IntervalActivityBoard({
    updaterRef,
    loading,
    disableControl,
    filter,
    date,
    employees,
    employee,
    width,
    height,
    onOpenGeneratorBtnClick,
    onActivitiesSave,
    onActivitiesChange,
    onFinishActivitiesSave,
}: IIntervalActivityBoardProps) {

    const infoblockCollapsePanelRef = useRef<HTMLDivElement>(null);
    const tableGridRef = useRef<Grid<IIntervalInfoblockTableRecord>>(null);
    const outerTableGridRef = useRef<HTMLDivElement>(null)
    const collapseHeaderRef = useRef<HTMLDivElement>(null);
    const infoblockContentRef = useRef<HTMLDivElement>(null);
    const infoblockWrapperRef = useRef<HTMLDivElement>(null);
    const editorWrapperRef = useRef<HTMLDivElement>(null);

    const auth = useAuth();
    const dayFilter = useMemo(() => ({
        ...filter,
        from: date.startOf('day'),
        to: date.startOf('day')
    }), [filter, date]);

    const [hasForcastObj, setHasForcastObj] = useState(true);//useState(dayFilter.forcastObjIds.length > 0);
    const [infoblockActiveKey, setInfoblockActiveKey] = useState<string[]>(() => hasForcastObj ? [infoblockCollapsePanelKey] : []);
    const [viewInfoblockType, setViewInfoblockType] = useState(InfoblockType.Chart);
    const [viewInfoblockInterval, setViewInfoBlockInterval] = useState(15);

    const [infoblockTableData, reloadInfoBlockTableData, ] = useListLoader(
        signal => getIntervalInfoBlockTableData(auth, dayFilter, viewInfoblockInterval, employee, signal),
        [auth.data?.staticId, dayFilter, viewInfoblockInterval, employee],
        loadConstructorProps
    );
    
    const [infoblockChartData, reloadInfoBlockChartData, ] = useListLoader(
        signal => getIntervalInfoBlockChartData(auth, dayFilter, viewInfoblockInterval, employee, signal),
        [auth.data?.staticId, dayFilter, viewInfoblockInterval, employee],
        loadConstructorProps
    );

    const [infoblockContentHeight, setInfoblockContentHeight] = useState(infoblockRowHeight * 5);
    const [editorHeight, setEditorHeight] = useState(height - infoblockContentHeight);

    const [minInfoblockContentHeight, setMinInfoblockContentHeight] = useState(defaultMinInfoblockContentHeight);
    const [maxInfoblockContentHeight, setMaxInfoblockContentHeight] = useState(defaultMaxInfoblockContentHeight);

    const updateInfoblockHeightAndCalcEditorHeight = useCallback((
        newInfoblockHeight: number,
        minInfoBlockHeight: number,
        maxInfoBlockHeight: number
    ) => {

        const normalizeHeight = Math.max(minInfoBlockHeight, Math.min(maxInfoBlockHeight, newInfoblockHeight));

        setInfoblockContentHeight(normalizeHeight);

        if(infoblockWrapperRef.current) {

            const infoblockWrapperRect = infoblockWrapperRef.current.getBoundingClientRect();

            if(viewInfoblockType === InfoblockType.Table
            && outerTableGridRef.current) {
                const outerTableGridRect = outerTableGridRef.current.getBoundingClientRect();
                const gridTopOffset = outerTableGridRect.top - infoblockWrapperRect.top;
                const newHeight = hasForcastObj
                                ? height - gridTopOffset - normalizeHeight
                                : height - gridTopOffset;
                
                setEditorHeight(newHeight);
            }
            else if(infoblockContentRef.current) {

                const infoblockContentRect = infoblockContentRef.current.getBoundingClientRect();
                const collapseHeaderHeight = infoblockContentRect.top - infoblockWrapperRect.top;
                const newHeight = hasForcastObj
                                ? height - collapseHeaderHeight - normalizeHeight
                                : height - collapseHeaderHeight;
                
                setEditorHeight(newHeight);
            }
        }

    }, [height, viewInfoblockType]);

    const calculateAndUpdateEditorHeight = useCallback(() => {
        
        if(infoblockWrapperRef.current
        && editorWrapperRef.current) {

            const infoblockWrapperRect = infoblockWrapperRef.current.getBoundingClientRect();
            const editorWrapperRect    = editorWrapperRef.current.getBoundingClientRect();
            const nextInfoBlockHeight  = editorWrapperRect.top - infoblockWrapperRect.top;
            const newHeight            = height - nextInfoBlockHeight;

            setEditorHeight(newHeight);
        }
        
    }, [height]);

    const handleInfoBlockCollapseChange = useCallback((key: string | string[]) => {

        const willCollapse = infoBlockCollapse(key);
        const normalizeKey = isArray(key) ? key : [key];
        const infoblockActiveKey = hasForcastObj ? normalizeKey : [];

        setInfoblockActiveKey(infoblockActiveKey);

        if(infoblockWrapperRef.current) {

            const icollapseHeaderRect = infoblockCollapsePanelRef
                                        .current
                                        ?.querySelector('.ant-collapse-header')
                                        ?.getBoundingClientRect();

            if(!icollapseHeaderRect) {
                return;
            }

            const collapseHeaderHeight = icollapseHeaderRect.height

            if (!hasForcastObj || !willCollapse) {
                const newHeight = height - collapseHeaderHeight;
                setEditorHeight(newHeight);
                return;
            }

            if(viewInfoblockType === InfoblockType.Table) {
                // TODO calculate table header height
                const newHeight = height - collapseHeaderHeight - infoblockRowHeight - infoblockContentHeight
                setEditorHeight(newHeight);
            }
            else if(infoblockContentRef.current) {
                const newHeight = height - collapseHeaderHeight - infoblockContentHeight;
                setEditorHeight(newHeight);
            }
        }

    }, [height,
        hasForcastObj,
        viewInfoblockType,
        infoblockContentHeight
    ]);

    const mouseDownEventHandler = useCallback((mouseDownEvent: React.MouseEvent) => {

        const startSize = infoblockContentHeight;
        const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };

        const updateHeight = (mouseMoveEvent: MouseEvent) => {

            const tmpHeight = startSize - startPosition.y + mouseMoveEvent.pageY;
            const stepHeight = Math.floor(tmpHeight / infoblockRowHeight) * infoblockRowHeight + scrollWidth;

            updateInfoblockHeightAndCalcEditorHeight(
                stepHeight,
                minInfoblockContentHeight,
                maxInfoblockContentHeight
            );
        }

        const onMouseMove = (mouseMoveEvent: MouseEvent) => {
            ignoreSelectEvent(mouseMoveEvent);
            updateHeight(mouseMoveEvent);
            return false;
        }

        const onMouseUp = () => {
            document.removeEventListener("mousemove", onMouseMove);
        }
        
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp, { once: true });

    }, [height,
        updateInfoblockHeightAndCalcEditorHeight,
        minInfoblockContentHeight,
        maxInfoblockContentHeight,
        infoblockContentHeight,
        hasForcastObj
    ]);

    const handleActivitiesChange = useCallback(() => {

        if(hasForcastObj) {

            if (viewInfoblockType === InfoblockType.Table)
                reloadInfoBlockTableData(syncLoaderProps);

            else if (viewInfoblockType === InfoblockType.Chart)
                reloadInfoBlockChartData(syncLoaderProps);
        }

        if(onActivitiesChange) {
            onActivitiesChange();
        }

    }, [reloadInfoBlockTableData,
        reloadInfoBlockChartData,
        onActivitiesChange,
        viewInfoblockType,
        hasForcastObj,
    ]);

    const reloadUpdateInfoBlockData = useCallback(() => {

        if(hasForcastObj) {

            if (viewInfoblockType === InfoblockType.Table)
                reloadInfoBlockTableData(reloadLoaderProps);

            else if (viewInfoblockType === InfoblockType.Chart)
                reloadInfoBlockChartData(reloadLoaderProps);
        }

    }, [reloadInfoBlockTableData,
        reloadInfoBlockChartData,
        viewInfoblockType,
        hasForcastObj
    ]);

    const handleUpdateTableFilter = useCallback(() => {
        reloadInfoBlockTableData(loadProps);
    }, []);

    /*
    useEffect(() => {
        const hasForcastObj = dayFilter.forcastObjIds.length > 0;
        setHasForcastObj(hasForcastObj);
        setInfoblockActiveKey(prev => 
            hasForcastObj
            ? prev
            : []
        );
    }, [dayFilter.forcastObjIds]);
    */
    
    useEffect(
        reloadUpdateInfoBlockData,
        [date, hasForcastObj, viewInfoblockType, viewInfoblockInterval]
    );

    const recalcTableMinMaxInfoblockHeights = () => {

        if (hasForcastObj)
        if (infoblockTableData.load
        && viewInfoblockType === InfoblockType.Table) {

            const minInfoblockHeight = defaultMinInfoblockContentHeight;
            const tmpMaxInfoblockHeight = infoblockTableData.items.length * infoblockRowHeight + scrollWidth;
            const maxInfoblockHeight = Math.min(defaultMaxInfoblockContentHeight, tmpMaxInfoblockHeight);

            setMinInfoblockContentHeight(minInfoblockHeight);
            setMaxInfoblockContentHeight(maxInfoblockHeight);
        }
    }

    const recalcMinMaxInfoblockHeights = () => {

        if (hasForcastObj) {

            if (viewInfoblockType === InfoblockType.Chart) {
                setMinInfoblockContentHeight(defaultMinChartInfoblockContentHeight);
                setMaxInfoblockContentHeight(defaultMaxChartInfoblockContentHeight);
            }
            else {
                recalcTableMinMaxInfoblockHeights();
            }
        }
    }

    useEffect(recalcTableMinMaxInfoblockHeights, [infoblockTableData.items.length]);
    useEffect(recalcMinMaxInfoblockHeights, [viewInfoblockType]);

    useEffect(() => {

        updateInfoblockHeightAndCalcEditorHeight(
            infoblockContentHeight,
            minInfoblockContentHeight,
            maxInfoblockContentHeight
        );

    }, [minInfoblockContentHeight, maxInfoblockContentHeight]);

    useEffect(
        calculateAndUpdateEditorHeight,
        [height, hasForcastObj, viewInfoblockType]
    );

    const [chartLines, chartDataSource] = useMemo(() => {
        const min = date.clone().startOf('day').add(-1, 'day');
        const max = date.clone().endOf('day').add(1, 'day');
        const items = embedChartItems(infoblockChartData.items, min, max, viewInfoblockInterval);
        return normalizeChartItems(items);
    }, [infoblockChartData.items, date, viewInfoblockInterval]);

    const timelineGridElRef = useRef<HTMLDivElement | null>(null);
    const chartBoardRef = useRef<HTMLDivElement>(null);
    const editorRef = useRef<Eventcalendar>(null);
    const loaderProviderRef = useRef<TIntervalEditorUpdater>(null);

    useEffect(() => {

        timelineGridElRef.current = null;

        if (viewInfoblockType !== InfoblockType.Chart &&
            viewInfoblockInterval !== 60) {
            return;
        }

        if (!editorRef.current) {
            return;
        }

        const calendarRoot   = editorRef.current.nativeElement;
        const timelineGridEl = calendarRoot.querySelector<HTMLDivElement>(".mbsc-timeline .mbsc-timeline-grid-scroll");

        if (!timelineGridEl) {
            return;
        }

        timelineGridElRef.current = timelineGridEl;

        let currScrollLeft = timelineGridEl.scrollLeft;

        if (viewInfoblockType === InfoblockType.Table &&
            tableGridRef.current) {

            const tableGrid = tableGridRef.current;

            const scrollTimelineHandler = function (this: HTMLElement, ev: Event) {
                currScrollLeft = timelineGridEl.scrollLeft;
                tableGrid.scrollTo({ scrollLeft: currScrollLeft});
            }

            const fixScrollTimeout = setTimeout(() => {
                currScrollLeft = timelineGridEl.scrollLeft;
                tableGrid.resetAfterColumnIndex(0);
                tableGrid.scrollTo({ scrollLeft: currScrollLeft});
            }, 150);
    
            timelineGridEl.addEventListener('scroll', scrollTimelineHandler, false);
    
            return () => {
                clearTimeout(fixScrollTimeout);
                timelineGridEl?.removeEventListener('scroll', scrollTimelineHandler, false);
            }
        }

        if (viewInfoblockType === InfoblockType.Chart &&
            chartBoardRef.current) {

            const chartBoardEl = chartBoardRef.current;

            const scrollTimelineHandler = function (this: HTMLElement, ev: Event) {

                currScrollLeft = timelineGridEl.scrollLeft;

                if (chartBoardEl.scrollLeft !== currScrollLeft) {
                    chartBoardEl.scrollLeft = currScrollLeft;
                }
            }

            const scrollChartHandler = function (this: HTMLElement, ev: Event) {

                currScrollLeft = chartBoardEl.scrollLeft;

                if (timelineGridEl.scrollLeft !== currScrollLeft) {
                    timelineGridEl.scrollLeft = currScrollLeft;
                }
            }

            const fixScrollTimeout = setTimeout(() => {
                if (chartBoardEl && timelineGridEl) {
                    chartBoardEl.scrollLeft = timelineGridEl.scrollLeft;
                }
            }, 150);

            timelineGridEl.addEventListener('scroll', scrollTimelineHandler, false);
            chartBoardEl.addEventListener('scroll', scrollChartHandler, false);

            return () => {
                clearTimeout(fixScrollTimeout);
                timelineGridEl?.removeEventListener('scroll', scrollTimelineHandler, false);
                chartBoardEl?.removeEventListener('scroll', scrollChartHandler, false);
            }
        }

    }, [viewInfoblockType, infoblockChartData.loading, viewInfoblockInterval]);

    const handleTableScroll = useCallback((props: GridOnScrollProps) => {
        if (timelineGridElRef.current) {
            timelineGridElRef.current.scrollLeft = props.scrollLeft;
        }
    }, []);

    assignRef({

        reload: () => {
            
            reloadUpdateInfoBlockData();

            if (loaderProviderRef.current) {
                loaderProviderRef.current.updateActivityBaseList();
                loaderProviderRef.current.updateActivityList();
                loaderProviderRef.current.updateScheduleEmployeeExtensionList();
            }
        },
        
    }, updaterRef);

    return (
        <>
            <div
                ref={infoblockWrapperRef}
                className="interval-activity-info-block-wrapper"
            >
                <Collapse
                    className="interval-activity-info-block-collapse"
                    activeKey={infoblockActiveKey}
                    collapsible={!hasForcastObj ? 'disabled' : undefined}
                    bordered={false}
                    onChange={handleInfoBlockCollapseChange}
                    items={[
                        {
                            key: infoblockCollapsePanelKey,
                            ref: infoblockCollapsePanelRef,
                            label: (
                                <div ref={collapseHeaderRef}>
                                    <Row
                                        gutter={[16, 16]}
                                        style={{
                                            width: '100%'
                                        }}
                                    >
                                        <Col span={16}>
                                            <Space
                                                size={12}
                                                align="start"
                                            >
                                                {lang.statistic}
                                                <Segmented
                                                    size="small"
                                                    value={viewInfoblockType}
                                                    options={[
                                                        {label: lang.viewTable, value: InfoblockType.Table, disabled: !hasForcastObj},
                                                        {label: lang.viewGraph, value: InfoblockType.Chart, disabled: !hasForcastObj}
                                                    ]}
                                                    onClick={e => { e.stopPropagation(); }}
                                                    onChange={(value) => setViewInfoblockType(value as unknown as InfoblockType)}
                                                />
                                                {hasForcastObj &&
                                                <>
                                                    <Segmented
                                                        size="small"
                                                        value={viewInfoblockInterval}
                                                        options={[
                                                            {label: lang.fifteenMinutes, value: 15},
                                                            {label: lang.sixtyMinutes, value: 60}
                                                        ]}
                                                        onClick={e => { e.stopPropagation(); }}
                                                        onChange={(value) => setViewInfoBlockInterval(value as unknown as number)}
                                                    />
                                                    <Button
                                                        size="small"
                                                        icon={<ReloadOutlined />}
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            reloadUpdateInfoBlockData();
                                                        }}
                                                    >
                                                        {lang.update}
                                                    </Button>
                                                </>}
                                            </Space>
                                        </Col>
                                        <Col
                                            span={8}
                                            style={{
                                                paddingLeft: 0,
                                                paddingRight: 0,
                                                position: 'relative',
                                                display: 'flex',
                                                justifyContent: 'end'
                                            }}
                                        >
                                            {hasForcastObj && viewInfoblockType === InfoblockType.Chart &&
                                            <CheckAccess
                                                module={Module.ScheduleOptimizerApply}
                                            >
                                                <Space
                                                    size={12}
                                                    align="end"
                                                >
                                                    {/*infoblockChartData.load
                                                    ? <Tag style={{marginInlineEnd: 0}}>{lang.shiftFactor}: {graphStatVal}%</Tag>
                                                    : <SkeletonInput size="small" active={infoblockChartData.loading} />*/}
                                                    {!employee && <Button
                                                        size="small"
                                                        icon={<RiseOutlined />}
                                                        onClick={(e) => {
                                                            
                                                            e.stopPropagation();

                                                            if (onOpenGeneratorBtnClick)
                                                                onOpenGeneratorBtnClick();
                                                        }}
                                                    >
                                                        {lang.optimized}
                                                    </Button>}
                                                </Space>
                                            </CheckAccess>}
                                        </Col>
                                    </Row>
                                </div>
                            ),
                            children: (
                                <div
                                    ref={infoblockContentRef}
                                    className="full-ant-collapse-content-box"
                                >
                                    {hasForcastObj &&
                                    <>
                                        {viewInfoblockType === InfoblockType.Table &&
                                        <InfoblockTable
                                            onScroll={handleTableScroll}
                                            gridRef={tableGridRef}
                                            outerGridRef={outerTableGridRef}
                                            interval={viewInfoblockInterval}
                                            loading={loading || infoblockTableData.loading}
                                            dataSource={infoblockTableData.items}
                                            scroll={{x: width, y: infoblockContentHeight, scrollToFirstRowOnChange: false}}
                                            rowHeight={infoblockRowHeight}
                                            onUpdateFilter={handleUpdateTableFilter}
                                        />}
                                        {viewInfoblockType === InfoblockType.Chart &&
                                        <Chart
                                            boardRef={chartBoardRef}
                                            width={width}
                                            height={infoblockContentHeight}
                                            loading={loading || infoblockChartData.loading}
                                            lines={chartLines}
                                            dataSource={chartDataSource}
                                        />}
                                        <div
                                            tabIndex={-1}
                                            className="interval-activity-info-block-resizer"
                                            onMouseDown={mouseDownEventHandler}
                                        />
                                    </>}
                                </div>
                            )
                        }
                    ]}
                />
            </div>
            <div
                ref={editorWrapperRef}
                className="interval-activity-editor-wrapper"
            >
                <DayIntervalEditor
                    editorRef={editorRef}
                    loaderProviderRef={loaderProviderRef}
                    loading={loading}
                    disableControl={disableControl}
                    filter={filter}
                    date={date}
                    employees={employees}
                    employee={employee}
                    width={width}
                    height={editorHeight}
                    onSave={onActivitiesSave}
                    onChange={handleActivitiesChange}
                    onFinishSave={onFinishActivitiesSave}
                />
            </div>
        </>
    )
}

export default IntervalActivityBoard