import moment from "moment";
import { INotificationBase, IScheduleEmployeeDayEvent, NotificationType, TEventData } from "../../Entities/BackendAPIHub";

export function getNotificationType(notification: INotificationBase) {

    switch (notification.type) {
        case NotificationType.Success: return 'success';
        case NotificationType.Warning: return 'warning';
        case NotificationType.Error: return 'error';
        default: return 'info';
    }
}

export function isScheduleEmployeeDayEvent(data: any): data is IScheduleEmployeeDayEvent {
    return data && data.PID && data.date;
}

export function parseNotificationData(notification: INotificationBase): TEventData | null {

    if (notification.data) {

        try {
            const json = JSON.parse(notification.data);

            if (isScheduleEmployeeDayEvent(json)) {
                
                return {
                    ...json,
                    date: moment(json.date),
                }
            }

            return json;
        }
        catch (ex) { console.warn(ex); }
    }

    return null;
}