import { useState, useContext, createContext, useRef, useEffect, useMemo } from "react";
import { AuthorizationException } from "../System/AuthorizationException";
import { IAuthActionBag, IAuthDataState, IAuthProvider } from "../Entities/IAuthProvider";
import { createAwaiter } from "../System/QueueAwaiter";
import { getOrCreateAbortError, isNullOrUndefined, isUndefined } from "../System/Utils";
import { contextNotInitFn, contextNotInitFnAsync } from "./helpers";
import { InvalidOperationException } from "../System/InvalidOperationException";
import { OperationAbortedByMergeException } from "../System/OperationAbortedByMergeException";
import useActual from "./useActual";

const authContext = createContext<IAuthProvider<any>>({
    isLoging: false,
    isLogout: true,
    loging: contextNotInitFnAsync,
    throwIfNotLogedInAndLogout: contextNotInitFn,
    logedIn: contextNotInitFn,
    logout: contextNotInitFnAsync,
    abort: contextNotInitFnAsync,
    login: contextNotInitFnAsync
});

export type CustomLoginValidator<TAuthData> = (authData: IAuthDataState<TAuthData>) => Promise<boolean>;
export type CustomLogout<TAuthData> = (authData: IAuthDataState<TAuthData>) => Promise<void>;

export type ResolveLogin = (value: void | PromiseLike<void>) => void;
export type RejectLogin = (reason?: any) => void;

const useProvideAuth = function <TAuthData>(): IAuthProvider<TAuthData> {
    
    const lastLogginResultRef = useRef<unknown>(true);
    const resolveFnRef = useRef<ResolveLogin | null>(null);
    const rejectFnRef = useRef<RejectLogin | null>(null);
    const logingPromiseRef = useRef<Promise<void> | null>(null);
    const abortControllerRef = useRef<AbortController | null>(null);

    const [authBag, setAuthBag] = useState<IAuthDataState<TAuthData>>({
        loging: false,
        logout: true
    });
    
    const abortAuthorizationOperation = (reason?: unknown) => {

        if(!isNullOrUndefined(abortControllerRef.current)) {
            const tmpAbortController = abortControllerRef.current;
            abortControllerRef.current = null;
            tmpAbortController?.abort(reason);
        }
    }

    const abort = (reason?: unknown) => abortAuthorizationOperation(reason);

    const logedIn = async (signal?: AbortSignal) => {

        if (signal &&
            signal.aborted) {
            throw getOrCreateAbortError(signal);
        }

        if (authBag.actionBag &&
            authBag.actionBag.getLogedInAction) {
            return await authBag.actionBag.getLogedInAction()(authBag, signal);
        }

        if (authBag.loging === true ||
            authBag.logout ||
            isNullOrUndefined(authBag.data)) {
            return false;
        }

        return true;
    }

    const throwIfNotLogedIn = async (signal?: AbortSignal): Promise<false | never> => {

        if (signal &&
            signal.aborted) {
            throw getOrCreateAbortError(signal);
        }

        if (!await logedIn(signal)) {
            throw new AuthorizationException(`User not authorization`);
        }
        
        return false;
    }
    
    const throwIfNotLogedInAndLogout = async (signal?: AbortSignal): Promise<false | never> => {

        if (signal &&
            signal.aborted) {
            throw getOrCreateAbortError(signal);
        }

        if (!await logedIn(signal)) {
            await logout(signal);
            throw new AuthorizationException(`User not authorization`);
        }
        
        return false;
    }

    const loging = (signal?: AbortSignal) => {

        if(signal && signal.aborted) {
            const reason = getOrCreateAbortError(signal);
            return Promise.reject(reason);
        }

        if(logingPromiseRef.current == null) {
            return Promise.resolve();
        }

        return createAwaiter(logingPromiseRef.current, signal);
    }

    const logout = async (signal?: AbortSignal) => {

        if(signal && signal.aborted) {
            throw getOrCreateAbortError(signal);
        }

        // Идет процесс входа в систему
        if(logingPromiseRef.current !== null) {
            throw new InvalidOperationException("Loging is started");
        }

        const newAuthBag: IAuthDataState<TAuthData> = {
            loging: false,
            logout: true,
            actionBag: authBag.actionBag
        };

        if(authBag.actionBag) {

            const actionGetter = authBag.actionBag.getLogoutAction;

            if(actionGetter) {

                const action = actionGetter();

                if(action) {

                    const data = await action(authBag, signal);

                    if(data) {
                        newAuthBag.data = data;
                    }
                }
            }
        }

        setAuthBag(newAuthBag);
    }
    
    const loginByAction = async (
        actionBag: IAuthActionBag<TAuthData>,
        abortSignal?: AbortSignal
    ) => {
        
        if(abortSignal && abortSignal.aborted) {
            throw getOrCreateAbortError(abortSignal);
        }

        let abortedFromExternal = false;

        const tmpAbortController = new AbortController();
        const tmpAbortSignal = tmpAbortController.signal;

        const externalSignalListener = (ev: Event) => {
            abortedFromExternal = true;
            tmpAbortController.abort(ev);
        }

        if (abortSignal) {
            abortSignal.addEventListener('abort', externalSignalListener, {once: true});
        }

        let data: TAuthData | undefined = undefined;

        try {

            const newBag = {
                loging: true,
                logout: true,
                actionBag
            }

            setAuthBag(newBag);

            if (logingPromiseRef.current === null) {
                logingPromiseRef.current = new Promise<void>((resolve, reject) => {
                    resolveFnRef.current = resolve;
                    rejectFnRef.current = reject;
                });
            }
            
            const prevAbortController = abortControllerRef.current;
            abortControllerRef.current = tmpAbortController;
            prevAbortController?.abort(new OperationAbortedByMergeException());
            
            try {
                data = await actionBag.getLoginAction()(newBag, tmpAbortSignal);
            } catch (ex) {
                lastLogginResultRef.current = ex;
                throw ex;
            }

            abortControllerRef.current = null;
            lastLogginResultRef.current = true;

            return data;
        }
        finally {
            
            if (abortSignal) {
                abortSignal.removeEventListener('abort', externalSignalListener);
            }
            
            // Если было вызвано прерывание пользователем, и контроллер прерывания не изменился, значит нового вызова функции входа не было
            // Если прерывание выполнено не пользователем, а через внутренний контроллер,
            // значит функция была вызвана в момент попытки входа и мы останавливаем предыдущую попытку входа и выполняем новую.
            // Если НЕ было вызвано прерывание пользователем, и новой попытки входа нет, значит обновляем состояние
            // Если произошла ошибка, и новой попытки входа нет, значит обновляем состояние
            
            const abortByUserNoNextLogin    = abortedFromExternal && abortControllerRef.current === tmpAbortController;
            const nextLogin                 = !isNullOrUndefined(abortControllerRef.current) && abortControllerRef.current !== tmpAbortController;
            const internalAbortNoNextLogin  = !abortedFromExternal && !nextLogin;
            const throwErrorNoNextLogin     = !isNullOrUndefined(lastLogginResultRef.current) && !nextLogin;
            const updateState               = abortByUserNoNextLogin || internalAbortNoNextLogin || throwErrorNoNextLogin;

            if (updateState) {
                setAuthBag({
                    loging: false,
                    logout: isUndefined(data),
                    actionBag,
                    data,
                });
            }
        }
    }

    useEffect(() => {

        if (!authBag.loging) {

            try
            {
                if (lastLogginResultRef.current === true) {
    
                    if (resolveFnRef.current) {
                        resolveFnRef.current();
                    }
                }
                else {

                    if (rejectFnRef.current) {
                        rejectFnRef.current(lastLogginResultRef.current);
                    }
                }
            }
            catch (ex) {
                Promise.resolve().then(() => { throw ex });
            }
    
            logingPromiseRef.current = null;
            resolveFnRef.current = null;
            rejectFnRef.current = null;
        }

    }, [authBag.loging]);
    
    const isLoging = authBag.loging;
    const isLogout = authBag.logout;
    const data = authBag.data;
    const login = loginByAction;

    return useMemo(() => ({
        isLoging,
        isLogout,
        data,
        login,
        throwIfNotLogedInAndLogout,
        loging,
        logedIn,
        logout,
        abort,
    }),
    [
        isLoging,
        isLogout,
        data,
        login,
        throwIfNotLogedInAndLogout,
        loging,
        logedIn,
        logout,
        abort,
    ]);
}

export function AuthProvider<TAuthData = any>({ children }: any) {
    const auth = useProvideAuth<TAuthData>();
    return (
        <authContext.Provider value={auth}>
            {children}
        </authContext.Provider>
    );
}

export function useGenericAuth<TAuthData = any>() {
    return useContext<IAuthProvider<TAuthData>>(authContext);
}

export default useGenericAuth;