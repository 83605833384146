import { Theme } from ".";
import { classNames } from "../../System/ClassNames";
import { AgendaEvent, AgendaListItem } from "./list-item";

export interface IAgendaListProps extends React.HTMLAttributes<HTMLDivElement> {
    eventList: readonly AgendaEvent[],
    theme?: Theme,
    rtl?: boolean,
}

export function AgendaList({
    eventList,
    theme,
    rtl,
    ...other
}: IAgendaListProps) {

    const themeClassName = `mbsc-${theme}`;
    const rtlClassName = "mbsc-" + (rtl ? "rtl" : "ltr");

    return (
        <div className="mbsc-event-list mbsc-event-list-scroll">
            <div
                {...other}
                className={classNames(
                    other.className,
                    rtlClassName,
                    "mbsc-font",
                    "mbsc-list",
                    themeClassName
                )}
            >
                {eventList.map(event => (
                    <AgendaListItem
                        key={event.id}
                        event={event}
                        theme={theme}
                        rtl={rtl}
                    />
                ))}
            </div>
        </div>
    )
}