import moment from "moment";
import { isBoolean, isDate, isFloat, isInt, isNull, isNumber, NullOrUndefined } from "../System/Utils";
import { CSharpType } from "./CSharpType";
import { ArgumentException } from "../System/ArgumentException";
import { IAuthData } from "./IAuthData";
import { SqlDbType } from "./SqlDbType";

export type AccessValueType = null | boolean | number | string;

export interface IDataTableRow {
    [name: string]: AccessValueType
}

export interface IDataTable {
    [index: number]: IDataTableRow
}

export interface IDataTableColumn {
    readonly columnName: string | null,
    readonly columnType: SqlDbType | null
}

export interface IQueryDataTable {
    columns: IQueryParameter[],
    items: IDataTable,
}

export interface IQueryParameter {
    originalValue?: any;
    readonly parameterName: string,
    readonly parameterSqlType: SqlDbType,
    readonly parameterSqlTypeSize: number | null,
    readonly parameterCSharpType: CSharpType,
    readonly parameterValue: AccessValueType | IQueryDataTable
}

export interface IQueryParameterCollection extends Array<IQueryParameter> {
}

export interface IAnonymousQuery {
    procedure: string,
    parameters?: IQueryParameterCollection | NullOrUndefined,
}

export interface IQuery extends IAnonymousQuery {
    authData: IAuthData | NullOrUndefined
}

export const uuidValidationExp = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;

export class UUIDQueryParameter implements IQueryParameter {

    public readonly parameterSqlType = SqlDbType.UniqueIdentifier;
    public readonly parameterSqlTypeSize: null = null;
    public readonly parameterCSharpType = CSharpType.Guid;

    public constructor(
        readonly parameterName: string,
        readonly parameterValue: string | null) {
        //if(!isNull(parameterValue) && !uuidValidationExp.test(parameterValue)) {
        //    throw new ArgumentException(`${UUIDQueryParameter.name} value not correct '${parameterValue}'`, parameterName);
        //}
    }
}

export class StringQueryParameter implements IQueryParameter {

    public readonly parameterSqlType = SqlDbType.VarChar;
    public readonly parameterSqlTypeSize: null = null;
    public readonly parameterCSharpType = CSharpType.String;

    public constructor(
        readonly parameterName: string,
        readonly parameterValue: string | null) {
    }
}

export function jsonReplacer(this: Record<string, any>, key: string, value: unknown) {

    if (this[key] instanceof Date || this[key] instanceof moment) {
        return moment(this[key]).toISOString(true);
    }
    
    return value;
 }

export class JSONQueryParameter implements IQueryParameter {

    public readonly parameterSqlType = SqlDbType.VarChar;
    public readonly parameterSqlTypeSize: null = null;
    public readonly parameterCSharpType = CSharpType.String;
    public readonly parameterValue: string | null = null;

    public constructor(
        readonly parameterName: string,
        readonly originalValue: {} | null,
        readonly replacer: (typeof jsonReplacer) | null = null) {
        
        if(originalValue === null) {
            this.parameterValue = null;
            return;
        }
        
        this.replacer = replacer ?? jsonReplacer;
        this.parameterValue = JSON.stringify(originalValue, this.replacer);
    }
}

export class BoolQueryParameter implements IQueryParameter {

    public readonly parameterSqlType = SqlDbType.Bit;
    public readonly parameterSqlTypeSize: null = null;
    public readonly parameterCSharpType = CSharpType.Boolean;

    public constructor(
        readonly parameterName: string,
        readonly parameterValue: boolean | null) {
        if(!isBoolean(parameterValue) && !isNull(parameterValue)) {
            throw new ArgumentException(`${BoolQueryParameter.name} value not correct`, parameterName);
        }
    }
}

export abstract class AnyArrayQueryParameter implements IQueryParameter {

    static readonly Separator = ',';

    public readonly parameterSqlType: SqlDbType = SqlDbType.VarChar;
    public readonly parameterSqlTypeSize: null = null;
    public readonly parameterCSharpType: CSharpType = CSharpType.String;

    public readonly abstract parameterValue: AccessValueType | IQueryDataTable;

    constructor(readonly parameterName: string) {
    }
}

export abstract class NumArrayQueryParameter extends AnyArrayQueryParameter {

    public readonly parameterValue: string | null = null;

    public constructor(
        readonly parameterName: string,
        readonly originalValue: number[] | null) {
        
        super(parameterName);

        if(originalValue == null || originalValue.length == 0) {
            this.parameterValue = null;
            return;
        }

        for(let val of originalValue) {
            this.Check(val);
        }

        this.parameterValue = originalValue.join(NumArrayQueryParameter.Separator);
    }

    protected Check(parameterValue: number): void {
        if(!isInt(parameterValue)) {
            throw new ArgumentException(`${NumArrayQueryParameter.name} value not correct`, this.parameterName);
        }
    }
}

export class IdArrayQueryParameter extends AnyArrayQueryParameter {

    public readonly parameterValue: string | null = null;

    public constructor(
        readonly parameterName: string,
        readonly originalValue: (string | number)[] | null) {
        
        super(parameterName);

        if(originalValue == null || originalValue.length == 0) {
            this.parameterValue = null;
            return;
        }
        
        this.parameterValue = originalValue
                            .map(x => isNumber(x) ? x : JSON.stringify(x))
                            .join(NumArrayQueryParameter.Separator);
    }
}

export class IntArrayQueryParameter extends NumArrayQueryParameter {}
export class FloatArrayQueryParameter extends NumArrayQueryParameter {

    protected Check(parameterValue: number): void {
        if(!isFloat(parameterValue)) {
            throw new ArgumentException(`${FloatArrayQueryParameter.name} value not correct`, this.parameterName);
        }
    }
}

export abstract class NumQueryParameter implements IQueryParameter {

    public abstract readonly parameterSqlType: SqlDbType;
    public abstract readonly parameterCSharpType: CSharpType;
    public readonly parameterSqlTypeSize: null = null;

    public constructor(
        readonly parameterName: string,
        readonly parameterValue: number | null) {
        this.Check(parameterValue);
    }

    protected Check(parameterValue: number | null): void {
        if(!isInt(parameterValue) && !isNull(parameterValue)) {
            throw new ArgumentException(`${NumQueryParameter.name} value not correct`, this.parameterName);
        }
    }
}

export class IntQueryParameter extends NumQueryParameter {
    public readonly parameterSqlType = SqlDbType.Int;
    public readonly parameterCSharpType = CSharpType.Int;
}

export class LongQueryParameter extends NumQueryParameter {
    public readonly parameterSqlType = SqlDbType.BigInt;
    public readonly parameterCSharpType = CSharpType.Long;
}

export class FloatQueryParameter extends NumQueryParameter {

    public readonly parameterSqlType = SqlDbType.Float;
    public readonly parameterCSharpType = CSharpType.Float;

    protected Check(parameterValue: number | null): void {
        if(!isFloat(parameterValue) && isNull(parameterValue)) {
            throw new ArgumentException(`${FloatQueryParameter.name} value not correct`, this.parameterName);
        }
    }
}

export class DecimalQueryParameter extends NumQueryParameter {

    public readonly parameterSqlType = SqlDbType.Decimal;
    public readonly parameterCSharpType = CSharpType.Decimal;

    protected Check(parameterValue: number | null): void {
        if(!isFloat(parameterValue) && isNull(parameterValue)) {
            throw new ArgumentException(`${DecimalQueryParameter.name} value not correct`, this.parameterName);
        }
    }
}

export abstract class AbsDateTimeQueryParameter implements IQueryParameter {
    
    public readonly parameterSqlTypeSize = null;
    public readonly abstract parameterSqlType: SqlDbType;
    public readonly abstract parameterCSharpType: CSharpType;
    public readonly parameterValue: string | null;

    public constructor(
        readonly parameterName: string,
        readonly originalValue: moment.Moment | Date | null) {

        if(!moment.isMoment(originalValue) && !isDate(originalValue) && !isNull(originalValue)) {
            throw new ArgumentException(`${AbsDateTimeQueryParameter.name} value not correct`, this.parameterName);
        }
        
        this.parameterValue = isNull(originalValue) ?
            null :
            moment(originalValue).toISOString(true);
    }
}

export class DateQueryParameter implements IQueryParameter {

    public readonly parameterSqlTypeSize = null;
    public readonly parameterSqlType = SqlDbType.Date;
    public readonly parameterCSharpType: CSharpType = CSharpType.DateTime;
    public readonly parameterValue: string | null;
    
    public constructor(
        readonly parameterName: string,
        readonly originalValue: moment.Moment | Date | null) {

        if(!moment.isMoment(originalValue) && !isDate(originalValue) && !isNull(originalValue)) {
            throw new ArgumentException(`${DateQueryParameter.name} value not correct`, this.parameterName);
        }
        
        this.parameterValue = isNull(originalValue) ?
            null :
            moment(originalValue).startOf('day').toISOString(true);
    }
}

export class DateTimeQueryParameter extends AbsDateTimeQueryParameter {
    public readonly parameterSqlType = SqlDbType.DateTime;
    public readonly parameterCSharpType: CSharpType = CSharpType.DateTime;
}

export class DateTime2QueryParameter extends AbsDateTimeQueryParameter {
    public readonly parameterSqlType = SqlDbType.DateTime2;
    public readonly parameterCSharpType: CSharpType = CSharpType.DateTime;
}

export class DateTimeOffsetQueryParameter implements IQueryParameter {
    
    public readonly parameterSqlTypeSize = null;
    public readonly parameterSqlType = SqlDbType.DateTimeOffset;
    public readonly parameterCSharpType: CSharpType = CSharpType.DateTimeOffset;
    public readonly parameterValue: string | null;

    public constructor(
        readonly parameterName: string,
        readonly originalValue: moment.Moment | Date | null) {

        if(!moment.isMoment(originalValue) && !isDate(originalValue) && !isNull(originalValue)) {
            throw new ArgumentException(`${DateTimeOffsetQueryParameter.name} value not correct`, this.parameterName);
        }

        this.parameterValue = isNull(originalValue) ?
            null :
            moment(originalValue).toISOString(true);
    }
}

/*
export class DataTableQueryParameter<TData extends IQueryDataTable> implements IQueryParameter {

    public readonly parameterSqlType = SqlDbType.Structured;

    public constructor(
        readonly parameterName: string,
        readonly parameterValue: TData | null) {
    }
}
*/
