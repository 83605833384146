
import { SearchOutlined } from '@ant-design/icons';
import Search, { SearchProps } from 'antd/es/input/Search';
import { ITimeZone } from '../../../Entities/Database';
import { Input } from 'antd';
import { ReactEventHandler } from 'react';

interface IScheduleShiftFilterProps {
    updateValue: (value: string) => void,
}

export function ScheduleShiftSearchString({updateValue}: IScheduleShiftFilterProps) {

    const onSearch: SearchProps['onSearch'] = (value, _e, info) => { 
        updateValue(value);
    };

    const handleSearch = (e: any) => {
        if(e.target?.value !== undefined) {
            updateValue(e.target?.value);
        }
    }
    
    return (
        <Search 
            allowClear
            onSearch={onSearch} 
            style={{marginBottom: "10px"}}
        />
        
        // <Input
        //     style={{marginBottom: "10px"}}
        //     onPressEnter={handleSearch}
        //     prefix={<SearchOutlined />}
        // >
        // </Input>
    )
}