import { createContext, useContext, useEffect } from "react";
import { message as AntdMessage, notification as AntdNotification} from "antd";
import { IHubProvider, ISignalRHubProvider } from "./useSignalRHub";
import { IHubEvents, apiHubContext, useAPIHub } from "./useAPIHub";
import { INotificationBase, IRuntimeNotification, NotificationStatus } from "../Entities/BackendAPIHub";
import { IEmployee } from "../Entities/Database";
import { MessageInstance } from "antd/lib/message/interface";
import { NotificationInstance } from "antd/lib/notification/interface";

export interface INotificationHubEvents extends IHubEvents {
    changeNotificationUnreadCount: (count: number) => void,
    pushNotification: (notification: IRuntimeNotification) => void,
    readNotification: (notification: INotificationBase, date: Date) => void,
    displayedNotification: (Notification: INotificationBase, date: Date) => void,
    openIntervalActivityEditor: (employee: IEmployee['PID'] | IEmployee, date: moment.Moment) => void,
}

export interface INotificationHubMethods {
    setNotificationStatus: (id: INotificationBase['id'], status: NotificationStatus, date: Date) => Promise<void>;
}

export interface INotificationHubOptions {
    message: MessageInstance,
    notification: NotificationInstance,
}

export function useNotificationHubProvide() {

    const hub = useAPIHub<INotificationHubEvents, INotificationHubMethods>();

    useEffect(() => {

        let lastUnreadSync: Date | null = null;
        let unread = 0;

        const initHandler: IHubEvents['init'] = (data) => {
            lastUnreadSync = new Date;
            unread = data.notifyUnread;
            hub.fire('changeNotificationUnreadCount', unread);
        }

        const pushNotificationHanlder: INotificationHubEvents['pushNotification'] = (notification) => {
            hub.fire('changeNotificationUnreadCount', ++unread);
        }

        const readNotificationHandler: INotificationHubEvents['readNotification'] = async (notification, date) => {
            await hub.invoke('setNotificationStatus', notification.id, NotificationStatus.Read, date);
            hub.fire('changeNotificationUnreadCount', --unread);
        }

        const displayedNotificationHanlder: INotificationHubEvents['displayedNotification'] = async (notification, date) => {
            await hub.invoke('setNotificationStatus', notification.id, NotificationStatus.Received, date);
        }

        hub.on('init', initHandler);
        hub.on('pushNotification', pushNotificationHanlder);
        hub.on('readNotification', readNotificationHandler);
        hub.on('displayedNotification', displayedNotificationHanlder);

        return () => {
            hub.disconnect();
            hub.off('init', initHandler);
            hub.off('pushNotification', pushNotificationHanlder);
            hub.off('readNotification', readNotificationHandler);
            hub.off('displayedNotification', displayedNotificationHanlder);
        }
        
    }, []);

    return hub;
}

export const notificationHubContext = createContext<ISignalRHubProvider<INotificationHubEvents, INotificationHubMethods>>({} as any);
export function NotificationHubProvider({ children }: any) {
    const hub = useNotificationHubProvide();
    return (
        <notificationHubContext.Provider value={hub}>
            {children}
        </notificationHubContext.Provider>
    );
}

export function useNotificactionHub(): IHubProvider<INotificationHubEvents> {
    return useContext(notificationHubContext);
}