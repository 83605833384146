import { GroupMember, IExternalLogin } from "./Database";

export interface ITagItem extends ITagData {
    groupMember?: GroupMember,
    color?: string,
    isActive?: boolean,
    start?: string,
}

export interface ITagData {
    id: number,
    content: string,
}

export type DataIndex = keyof IExternalLogin;

export enum ListItemType {
    ProjectGroup = 0,
    Skills = 1, 
    SpecialMarks = 2,
}

export interface IActivitiesItem {
    id: number,
    caption: string,
}

export { GroupMember };

export const defaultColors: string[] = [
    "magenta",
    "red",
    "volcano",
    "orange",
    "gold",
    "lime",
    "green",
    "cyan",
    "blue",
    "geekblue",
    "purple",
];