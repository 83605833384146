import { Divider, Input, Select, Space, Spin } from 'antd';
import { IOriginalActivity, IScheduleBoardEmployee, IScheduleBoardFilter } from '../../Entities/Database';
import { ILoadListState } from '../../Reducer/LoadListReducer';
import { SortType, antIcon } from '../ScheduleIntervalEditor';
import moment from 'moment';
import { Ref, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { IEmployeeResource, getEmployeeResourceId, normalizeEvent } from '../ScheduleIntervalEditor/helpers';
import { sortEmploeesByActivities } from '../ScheduleIntervalEditor/utils';
import { isEmptyOrNullOrUndefined } from '../../System/Utils';
import { generateRegexesOrigRuEn, satisfiesStrValue } from '../../Reducer/SearchFilterReducer';
import { IScheduleEmployeeActivity } from '../../Entities/IScheduleEmployeeActivity';
import { UserOutlined } from '@ant-design/icons';
import { refSetter } from '../shared/helpers';
import { Eventcalendar, localeRu, MbscCalendarColor, MbscEventcalendarView } from '../shared/lib';
import { IValidateProps } from '../shared/lib/src/core/util/datetime';
import { addDays, addHours, addMinMax, getDayEnd, getDayStart } from '../shared/libHelpers';

import './interval-editor.css';

const emptyResName = 'DEF_EMPTY_RES';
const emptyResources: IEmployeeResource[] = [{
    id: emptyResName,
    employee: undefined as any,
    eventCreation: false,
}];

export interface IScheduleGeneratorIntervalEditorProps {
    editorRef?: Ref<Eventcalendar>,
    timeStep?: number,
    date: moment.Moment,
    loading?: boolean,
    disableControl?: boolean,
    employees: ILoadListState<IScheduleBoardEmployee>,
    filter: IScheduleBoardFilter,
    data: IOriginalActivity[],
    width: number,
    height: number,
    onScroll?: () => void
}

export function ScheduleGeneratorIntervalEditor({
    editorRef,
    timeStep = 60,
    loading,
    width,
    height,
    disableControl,
    employees,
    filter,
    data,
    date,
    onScroll
}: IScheduleGeneratorIntervalEditorProps) {

    const calendarViewRef = useRef<Eventcalendar>(null);
    const [searchStr, setSearchStr] = useState('');
    const [sortType, setSortType] = useState<SortType>('start');
    const [dragTimeStep, setDragTimeStep] = useState(5);
    const [events, setEvents] = useState<IScheduleEmployeeActivity[]>([]);
    const [resources, setResources] = useState<IEmployeeResource[]>([]);
    const [invalids, setInvalids] = useState<IValidateProps[]>([]);
    const [colors, setColors] = useState<MbscCalendarColor[]>([]);

    const [view, refDate, min, max] = useMemo(() => {

        let min: moment.Moment;
        let max: moment.Moment;

        if (data.length === 0) {
            min = date;
            max = date;
        }
        else {

            min = data[0].end;
            max = data[0].start;

            for (const item of data) {

                if (min > item.start) {
                    min = item.start;
                }

                if (max < item.end) {
                    max = item.end;
                }
            }
        }

        const refDate       = min.clone().startOf('day').toDate();
        const daysTmpCount  = max.diff(min, 'days');
        const dayDiff       = Math.ceil(daysTmpCount);
        const startDayOfMax = max.clone().startOf('day');
        const daysCount     = (dayDiff === 0 || startDayOfMax.isSame(max)) ? 1 : (dayDiff + 1);
        const resultMin     = addHours(getDayEnd(addDays(min.toDate(), -1)), -16);
        const resultMax     = addHours(getDayStart(addDays(max.toDate(), 1)), 16);

        const view: MbscEventcalendarView = {
            timeline: {
                size: daysCount,
                type: 'day',
                startDay: 1,
                endDay: 0,
                timeCellStep: timeStep,
                timeLabelStep: timeStep,
                startTime: '00:00',
                endTime: '00:00',
            }
        }

        return [
            view,
            refDate,
            resultMin,
            resultMax
        ];

    }, [date, data, timeStep]);

    const filteredEmployees = useMemo(() => {

        let filteredEmployees: IScheduleBoardEmployee[];

        const timeSort = sortType === 'start' || sortType === 'end';

        if(!employees.load) {
            setResources([]);
            return [];
        }

        const sortable = timeSort
        ? sortEmploeesByActivities(employees.items, events, date, sortType)
        : [...employees.items].sort((a, b) => 
            sortType === 'asc'
            ? a.surname?.localeCompare(b.surname)
            : b.surname?.localeCompare(a.surname)
        );

        if(!isEmptyOrNullOrUndefined(searchStr)) {
            const regexs = generateRegexesOrigRuEn(searchStr);
            filteredEmployees = sortable.filter(employee => satisfiesStrValue(employee.surname, searchStr, ...regexs));
        } else {
            filteredEmployees = sortable;
        }

        return filteredEmployees;

    }, [date,
        events,
        date,
        searchStr,
        sortType,
        employees.load,
        employees.items
    ]);

    useEffect(() => {

        let resources = filteredEmployees.map((employee): IEmployeeResource => ({
            id: getEmployeeResourceId(employee),
            name: `${employee.surname} ${employee.firstName}`,
            employee: employee,
            collapsed: false,
        }));

        if (resources.length === 0) {
            resources = emptyResources;
        }

        setResources(resources);

    }, [filteredEmployees]);
    
    useEffect(() => {

        if(!data) {
            setEvents([]);
            return;
        }

        const convertedEvents = data.map(normalizeEvent);
        setEvents(convertedEvents);

    }, [data]);

    useEffect(() => {

        if(refDate && calendarViewRef.current) {
            const timeout = setTimeout(() => {
                const navigateDate = refDate;
                const calendarView = calendarViewRef.current;
                calendarView?.navigate(navigateDate, false);
            }, 300);
            return () => clearTimeout(timeout);
        }

    }, [refDate]);

    const renderResourceHeader = useCallback(() => {
        
        return (
            <>
                <Input.Search size="small" placeholder="Поиск по фамилии" onSearch={(value) => setSearchStr(value)} />
                <Divider style={{
                    margin: '6px 0'
                }} />
                <Select<SortType>
                    size="small"
                    className="sort-segmented-raw"
                    defaultValue={sortType}
                    onChange={setSortType}
                    style={{width: '100%'}}
                    labelInValue={false}
                    options={[
                        { label: 'ФИО От А до Я', value: 'asc' },
                        { label: 'ФИО От Я до А', value: 'desc' },
                        { label: 'По времени начала смены', value: 'start' },
                        { label: 'По времени окончания смены', value: 'end' },
                    ]}
                />
            </>
        )
    }, [sortType]);

    const renderResource = useCallback((resource: IEmployeeResource) => {

        return resource.employee && (
            <div className="timeline-employee">
                <div className="timeline-employee-title" title={`${resource.employee.surname} ${resource.employee.firstName}`}>
                    <UserOutlined />
                    <span>{`${resource.employee.surname} ${resource.employee.firstName}`}</span>
                </div>
                <Space
                    className="timeline-employee-data"
                    align="baseline"
                >
                    <div className="timeline-employee-role" title={resource.employee.roleCaption}>
                        {resource.employee.roleCaption}
                    </div>
                </Space>
            </div>
        );

    }, []);

    const normalizeInvalids = useMemo(() => {
        const tmpInvalids = [...invalids];
        addMinMax(tmpInvalids, min, max);
        return tmpInvalids;
    }, [min, max, invalids]);

    return (
        <div className="interval-activity-view">
            <Spin
                indicator={antIcon}
                spinning={loading || disableControl}
            >
                <Eventcalendar
                    ref={refSetter(editorRef, calendarViewRef)}
                    theme="ios"
                    themeVariant="light"
                    clickToCreate={false}
                    dragToMove={false}
                    dragToResize={false}
                    dragToCreate={false}
                    selectMultipleEvents
                    refDate={refDate}
                    dataTimezone={filter.timeZone}
                    showControls={false}
                    showOuterDays={false}
                    invalid={normalizeInvalids}
                    colors={colors}
                    locale={localeRu}
                    height={height}
                    width={width}
                    dragTimeStep={dragTimeStep}
                    newEventText="Новая активность"
                    view={view}
                    resources={resources}
                    data={events}
                    renderResourceHeader={renderResourceHeader}
                    renderResource={renderResource}
                />
            </Spin>
        </div>
    )
}

export default ScheduleGeneratorIntervalEditor;