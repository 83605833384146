import { Segmented } from "antd";
import { IScheduleWorkTime } from "../../../Entities/Database";
import { SegmentedValue } from "antd/es/segmented";
import { trim } from "./helpers";
import { ProgressShiftBreaks } from "./progress";
import { Paid } from "../BreakModalEditor";

import './style.css';

export enum WorkTimeType {
    Linear,
    Custom,
}

export interface IScheduleShiftWorkTimeDetailProps {
    loading?: boolean,
    workTime?: IScheduleWorkTime,
    onChangeType?: (type: WorkTimeType) => void,
}

export function ScheduleShiftWorkTimeDetail({
    loading,
    workTime,
    onChangeType,
}: IScheduleShiftWorkTimeDetailProps) {

    const handleChangeType = (value: SegmentedValue) => {

        if (onChangeType) {
            onChangeType(value as WorkTimeType);
        }
    }

    if (!workTime) {
        return <></>
    } 

    return (
        <div className="workTimeDetail__wrap">
            <div className="workTimeDetail__left-block">
                <p className="workTimeDetail__type-title">Тип перерывов в шаблоне</p>
                <Segmented
                    size="small"
                    value={workTime.isCustom ? WorkTimeType.Custom : WorkTimeType.Linear }
                    disabled={loading}
                    onChange={handleChangeType}
                    options={[
                        { label: "Последовательный", value: WorkTimeType.Linear },
                        { label: "Нестандартный", value: WorkTimeType.Custom },
                    ]}
                />
            </div>
            <div className="workTimeDetail__right-block">
                <p className="workTimeDetail__title">Информация о сегменте</p>
                <div className="workTimeDetail__inner-wrap">
                    <div className="workTimeDetail__info-wrap">
                        <p className="workTimeDetail__workTime">Рабочее время: {trim(workTime.durn / 60, 2)} час</p>
                        <p className="workTimeDetail__break">Доступный перерыв: {workTime.brNorm} мин</p>
                    </div>
                    <div className="workTimeDetail__progressWrap">
                        <ProgressShiftBreaks 
                            workTime={workTime}
                            paidBreak={Paid.Paid}
                        />
                        <ProgressShiftBreaks 
                            workTime={workTime}
                            paidBreak={Paid.NotPaid}
                        />
                    </div>
                </div> 
            </div>
        </div>
    );
}