import { useCallback, useEffect, useRef } from "react";
import { OperationAbortedByComponentDestructor } from "../System/OperationAbortedByComponentDestructor";
import { OperationAbortedByMergeException } from "../System/OperationAbortedByMergeException";

export type MergeActionParameter<TResult> = (signal: AbortSignal) => TResult;
export type MergeFunction = <TResult>(action: MergeActionParameter<Promise<TResult>>, onNoMerge?: () => void) => Promise<TResult>;
export type AbortFunction = <TReason>(reason: TReason) => void;

/**
 * Помогает выполнять прерывание и слияние асинхронных команд
 * 
 * @returns 
 */
export function useAbortController(): [MergeFunction, AbortFunction, React.RefObject<boolean>] {

    const globalAbortController = useRef<AbortController | null>(null);
    const inProcessRef = useRef(false);
    const prevAbortRef = useRef<AbortController | null>(null);

    const abortPrev = useCallback((abortController?: AbortController) => {

        abortController ??= new AbortController();

        inProcessRef.current = true;
        prevAbortRef.current?.abort(new OperationAbortedByMergeException());
        prevAbortRef.current = abortController;

        return abortController;

    }, []);

    const abort = useCallback<AbortFunction>((reason) => {
        inProcessRef.current = reason instanceof OperationAbortedByMergeException;
        prevAbortRef.current?.abort(reason);
    }, []);

    const merge = useCallback<MergeFunction>(async (action, onNoMerge) => {

        const internalAbortController = abortPrev();
        const signal = internalAbortController.signal;

        try {
            return await action(signal);
        }
        finally {

            inProcessRef.current = false;

            if(signal.aborted
            && signal.reason instanceof OperationAbortedByMergeException) {
                inProcessRef.current = true;
            }

            if (!(signal.reason instanceof OperationAbortedByMergeException)) {

                if (onNoMerge) {
                    onNoMerge();
                }
            }
        }

    }, [abortPrev]);

    useEffect(() => {

        globalAbortController.current = new AbortController();
        return () => {
            globalAbortController.current?.abort(new OperationAbortedByComponentDestructor());
            globalAbortController.current = new AbortController();
        }

    }, []);
    
    return [
        merge,
        abort,
        inProcessRef
    ]
}