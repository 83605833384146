import { Button, Checkbox, Divider, Form, FormProps, Input } from "antd";
import { LoginType } from "../AuthForm";
import { nameof } from "../../../System/Utils";
import { ICredential } from "../../../Server/ICredential";
import useAuth from "../../../Hooks/useAuth";

export function LoginForm(props: FormProps<ICredential>) {
    
    const auth = useAuth();

    return (
        <Form
            {...props}
            name="form-login"
            initialValues={{ remember: false }}
        >                
            
            <Form.Item<ICredential>
                name={nameof<ICredential>("userName")}
            >
                <Input 
                    placeholder="e-mail"
                    disabled={auth.isLoging}
                />
            </Form.Item>

            <Form.Item<ICredential>
                name={nameof<ICredential>("password")}
            >
                <Input.Password 
                    placeholder="Пароль" 
                    disabled={auth.isLoging}
                    autoComplete="off"
                />
            </Form.Item>

            <div className="authForm__checkbox-wrap">
                <Form.Item<ICredential> 
                    name={nameof<ICredential>("remember")}
                    valuePropName="checked" 
                    noStyle>
                    <Checkbox>Запомнить меня</Checkbox>
                </Form.Item>

                <a className="authForm__link-wrap" href="">
                    Забыли пароль?
                </a>
            </div>
                          
            <Form.Item>
                <Button 
                    type="primary" 
                    htmlType="submit" 
                    className="authForm__login" 
                    loading={auth.isLoging}
                >
                    Войти
                </Button>
            </Form.Item>
    
        </Form>
    )
}