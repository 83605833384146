
import { useEffect, useRef, useState } from "react";
import { IEmployeeScheduleDay, IScheduleBoardEmployee, IScheduleBoardFilter } from "../../../Entities/Database";
import { capacitounce } from "../../../System/TemporaryWraps";
import { refSetter } from "../../shared/helpers";
import { Grid } from "antd-virtual-table";
import { isActiveElement, IScheduleEmployeeDataSourceItem } from "../helpers";
import { handleSelectOnKeyDown } from "./helpers";
import EmployeesDayDataTable, { IEmployeeDayTableProps } from "./table";

export type TEmployee = IScheduleBoardEmployee;
export type TItem = IEmployeeScheduleDay;
export type TDate = moment.Moment;
export type TFilter = IScheduleBoardFilter;
export type TDataSourceItem = IScheduleEmployeeDataSourceItem;

export interface IEmployeeDayTableWithKeyboardControlProps extends IEmployeeDayTableProps {
    focusRef?: React.Ref<HTMLDivElement>,
    elementsWithAllowedArrowControl?: React.RefObject<React.RefObject<HTMLElement>[]>,
    //onKeyDown?: (event: KeyboardEvent, selectedItems: TItem[]) => void,
    onKeyPress?: (event: KeyboardEvent, selectedItems: Readonly<string[]>) => void,
    onSelectItems?: (selectedKeys: Readonly<string[]>, prevSelectedKeys: Readonly<string[]>) => boolean | void
}

export function EmployeeDayTableWithKeyboardControl({
    focusRef,
    gridRef,
    outerGridRef,
    items,
    selectedKeys: externalSelectedKeys,
    elementsWithAllowedArrowControl,
    //onKeyDown,
    onKeyPress,
    onSelectItems,
    ...tableProps
}: IEmployeeDayTableWithKeyboardControlProps) {

    const fakePrevHelperRef = useRef<HTMLDivElement>(null);
    const fakeNextHelperRef = useRef<HTMLDivElement>(null);
    const focusHelperRef = useRef<HTMLDivElement>(null);

    const internalGridRef = useRef<Grid<TDataSourceItem>>(null);
    const internalOuterGridRef = useRef<HTMLElement>(null);

    const [selectedKeys, setSelectedKeys] = useState<Readonly<string[]>>(externalSelectedKeys || []);

    useEffect(() => {
        setSelectedKeys(externalSelectedKeys || []);
    }, [externalSelectedKeys, setSelectedKeys]);

    useEffect(() => {

        if(!items || !items?.load) {
            return;
        }

        const fakePrevHelper = fakePrevHelperRef.current;
        const fakeNextHelper = fakeNextHelperRef.current;
        const normalizeItems = items.items;
        
        const gridFocusedElement = focusHelperRef.current;
        const gridElement = internalOuterGridRef.current;
        const grid = internalGridRef.current;

        const allowArrowKeyboardControl = () => elementsWithAllowedArrowControl?.current?.some(x => x.current && isActiveElement(x.current)) ?? false;

        const internalOnKeyDown = (event: KeyboardEvent) => {

            if(grid && gridElement && event instanceof KeyboardEvent) {

                if((gridFocusedElement && isActiveElement(gridFocusedElement))
                || (event.code.startsWith('Arrow') && allowArrowKeyboardControl())) {

                    setSelectedKeys(selectedKeys => {

                        const tmp = handleSelectOnKeyDown(grid, gridElement, fakePrevHelper, fakeNextHelper, normalizeItems, selectedKeys, event);
                        
                        if (tmp) {

                            if(!onSelectItems || onSelectItems(tmp, selectedKeys)) {
                                return tmp;
                            }
                        }

                        return selectedKeys;
                    });
                }
            }
        }

        const handleKeyDown = capacitounce(internalOnKeyDown, 20, (event) => {
            
            if (grid && gridElement && event instanceof KeyboardEvent) {

                if ((gridFocusedElement && isActiveElement(gridFocusedElement)) ||
                    (event.code.startsWith('Arrow') && allowArrowKeyboardControl())) {

                    if (event.code === 'Tab' ||
                        event.code === 'ArrowLeft' ||
                        event.code === 'ArrowRight' ||
                        event.code === 'ArrowUp' ||
                        event.code === 'ArrowDown' ||
                        event.code === 'Home' ||
                        event.code === 'End') {
                        event.preventDefault();
                        return false;
                    }
                }
            }
        });

        const handleGridFocus = (event: FocusEvent) => {
            gridFocusedElement?.focus();
        }

        document.addEventListener('keydown', handleKeyDown, false);
        gridElement?.addEventListener('focus', handleGridFocus);

        return () => {
            document.removeEventListener('keydown', handleKeyDown, false);
            gridElement?.removeEventListener('focus', handleGridFocus);
        }

    }, [elementsWithAllowedArrowControl,
        items,
        onSelectItems
    ]);

    useEffect(() => {

        if(!onKeyPress) {
            return;
        }

        const focusHelper = focusHelperRef.current;
        const handleKeyPress = (event: KeyboardEvent) => {

            if(focusHelper && isActiveElement(focusHelper)) {
                onKeyPress(event, selectedKeys);
            }
        };

        document.addEventListener('keypress', handleKeyPress, false);
        return () => document.removeEventListener('keypress', handleKeyPress, false);

    }, [
        selectedKeys,
        onKeyPress
    ]);

    return (
        <>
            <div ref={fakePrevHelperRef} tabIndex={-1} />
            <div
                ref={refSetter(focusHelperRef, focusRef)}
                tabIndex={0} 
            >
                <EmployeesDayDataTable
                    {...tableProps}
                    gridRef={refSetter(internalGridRef, gridRef)}
                    outerGridRef={refSetter(internalOuterGridRef, outerGridRef)}
                    items={items}
                    selectedKeys={selectedKeys}
                />
            </div>
            <div ref={fakeNextHelperRef} tabIndex={-1} />
        </>
    );
}

export default EmployeeDayTableWithKeyboardControl;