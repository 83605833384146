import { TypeOf } from './Type/TypeOf';
import { JSError } from './_JSError';
import { ArgumentScopeDetector } from './Type/ArgumentScopeDetector';
import { customName } from './CustomNameDecorator';

export interface IException {
    GetName(): string;
    GetId(): number;
    GetMessage(): string;
    GetInnerException(): IException | null;
    GetInnerUnknown(): unknown;
}

export interface CallbackOnFailFunction extends CallableFunction {
    (err: IException): void;
}

@customName("Exception")
export class Exception extends JSError implements IException {
    
    private innerBag: unknown;

    private readonly id: number;
    private readonly innerException: Exception | null;

    public GetName(): string { return this.name; }

    public GetId(): number { return this.id; }
    public GetMessage(): string { return this.message; }
    public GetInnerException(): Exception | null { return this.innerException; }
    public GetInnerUnknown(): unknown { return this.innerBag; }

    constructor();

    constructor(id: number);
    constructor(id: number, innerException: Exception);

    constructor(id: number, message: string);
    constructor(id: number, message: string, innerException: Exception);

    constructor(message: string);
    constructor(message: string, innerException: Exception);
    
    constructor(idOrMessageOrError?: string | number, messageOrInnerException?: string | Exception, innerException?: Exception) {

        // Args count invalid
        if (arguments.length > 3) {

            throw new globalThis.Error("Max argument count is 3");
        }

        if(arguments.length == 0) {
            super();
            this.id = 0;
            this.innerException = null;
            return;
        }

        super(ArgumentScopeDetector.Detect(arguments, [
            { paramType: TypeOf.String, paramIndex: 0, paramCount: 1 },
            { paramType: TypeOf.String, paramIndex: 0, paramCount: 2 },
            { paramType: TypeOf.String, paramIndex: 1, paramCount: 2 }
        ], ""));

        this.id = ArgumentScopeDetector.Detect(arguments, [
            { paramType: TypeOf.Number, paramIndex: 0 },
        ], 0).value as number;

        this.innerException = ArgumentScopeDetector.Detect(arguments, [
            { paramType: Exception, paramIndex: 1, paramCount: 2 },
            { paramType: Exception, paramIndex: 2, paramCount: 3 },
        ], null).value;

        if(this.innerException !== null) {
            this.cause = this.innerException;
        }
    }

    static toException(ex: unknown) {
        
        if(ex instanceof global.Error) {
            const newEx = new Exception();
            newEx.message = ex.message;
            newEx.stack = ex.stack;
            newEx.cause = ex;
            return newEx;
        }

        const newEx = new Exception("Catched exception, see inner bag");
        newEx.innerBag = ex;
        return newEx;
    }
}

export default Exception