import { useCallback, useRef } from "react";

export type TPrmEventhanlder<TEventStore extends Record<keyof TEventStore, (...args: any[]) => any>> = <TEventName extends keyof TEventStore & string>(eventName: TEventName, ...args: Parameters<TEventStore[TEventName]>) => void;
export type TOprEventHanlder<TEventStore extends Record<keyof TEventStore, (...args: any[]) => any>> = <TEventName extends keyof TEventStore & string>(eventName: TEventName, callback: TEventStore[TEventName]) => void;
export type TGetEventHandlersCount<TEventStore extends Record<keyof TEventStore, (...args: any[]) => any>> = <TEventName extends keyof TEventStore & string>(eventName: TEventName) => number;
export type TGetEventsName<TEventStore extends Record<keyof TEventStore, (...args: any[]) => any>> = () => [string & keyof TEventStore];

export function useEventBus<TEventStore extends Record<keyof TEventStore, (...args: any[]) => any>>():
    [
        TPrmEventhanlder<TEventStore>,
        TOprEventHanlder<TEventStore>,
        TOprEventHanlder<TEventStore>,
        TGetEventHandlersCount<TEventStore>,
        TGetEventsName<TEventStore>
    ]
{
    const callbacksRef = useRef({} as Record<keyof TEventStore, Set<(...args: any[]) => void>>);

    const fire = useCallback(<TKey extends keyof TEventStore & string>(eventName: TKey, ...args: Parameters<TEventStore[TKey]>) => {

        if (callbacksRef.current[eventName]) {

            for (let callback of callbacksRef.current[eventName]) {
                
                callback(...args);
            }
        }

    }, []);

    const off = useCallback(<TEventName extends keyof TEventStore & string>(eventName: TEventName, callback: TEventStore[TEventName]) => {

        if (callbacksRef.current[eventName]) {
            callbacksRef.current[eventName].delete(callback);

            if (callbacksRef.current[eventName].size === 0) {
                delete callbacksRef.current[eventName];
            }
        }

    }, []);

    const on = useCallback(<TEventName extends keyof TEventStore & string>(eventName: TEventName, callback: TEventStore[TEventName]) => {

        if (callbacksRef.current[eventName] === undefined) {
            callbacksRef.current[eventName] = new Set<TEventStore[TEventName]>();
        }

        callbacksRef.current[eventName].add(callback);

    }, []);

    const getKeys  = useCallback(() => Object.keys(callbacksRef.current) as unknown as [string & keyof TEventStore], []);
    const getCount = useCallback(<TEventName extends keyof TEventStore & string>(eventName: TEventName) =>
        callbacksRef.current[eventName] ? callbacksRef.current[eventName].size : 0
    , []);

    return [
        fire,
        off,
        on,
        getCount,
        getKeys,
    ];
}

export default useEventBus;