import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { extractAuthData, IAuthLKData, urlAuthTokenParamName, urlModuleIdParamName, urlPageIdParamName, urlPIDParamName, useLKAuth } from "../../Hooks/useLKAuth";
import { isEmptyOrNullOrUndefined, nameof } from "../../System/Utils";
import { ILKCmdWithToken, LKEventType, lkUrls, normalizeEventData, notificationError, sendLKCmd } from "./helpers";
import { OperationAbortedByMergeException } from "../../System/OperationAbortedByMergeException";
import useAuth from "../../Hooks/useAuth";

export interface ILKLoginHelperProps {
    use?: boolean,
    interval?: number,
}

export const defaultProps: ILKLoginHelperProps = {
    use: true,
    interval: 5000,
}

export function LoginHelper(props: React.PropsWithChildren<ILKLoginHelperProps>) {

    const {use, interval, children} = {
        ...defaultProps,
        ...props
    }

    const auth   = useAuth();
    const lkAuth = useLKAuth();
    const [authLKData, setAuthLKData] = useState<IAuthLKData>();
    const modalDispatch = useRef<{destroy: () => void} | null>(null);

    useEffect(() => {

        if(use === false) {
            return;
        }

        const getActualLKToken = () => {
            
            sendLKCmd({
                eventType: LKEventType.getActualToken,
                page_id: authLKData?.pageId,
                token: authLKData?.authToken
            });
        }

        getActualLKToken();
        const intervalId = setInterval(() => {
            getActualLKToken();
        }, interval);

        return () => {
            clearInterval(intervalId);
        }

    }, [use, interval, authLKData]);

    useEffect(() => {

        if(use === false) {
            return;
        }

        const messageHandler = (ev: MessageEvent<any>) => {

            if (ev.origin === window.location.origin
            || (lkUrls.indexOf(ev.origin) != -1)) {

                const data = normalizeEventData(ev);
                
                if (data != null) {

                    if (data.eventType == LKEventType.updateToken
                    ||  data.eventType == LKEventType.getActualToken) {

                        const authToken = data[nameof<ILKCmdWithToken>("token")] ?? data[urlAuthTokenParamName];

                        if(authToken != authLKData?.authToken) {

                            setAuthLKData(prev => ({
                                pageId:     data[urlPageIdParamName] ?? prev?.pageId,
                                moduleId:   data[urlModuleIdParamName] ?? prev?.moduleId,
                                PID:        data[urlPIDParamName] ?? prev?.PID,
                                authToken:  authToken ?? prev?.authToken,
                            }));
                        }
                    }
                }
            }
        }
        
        window.addEventListener("message", messageHandler);
        return () => {
            window.removeEventListener("message", messageHandler);
        }
        
    }, [use, authLKData]);

    const loginAsync = useCallback(async (signal?: AbortSignal) => {

        if(!authLKData || isEmptyOrNullOrUndefined(authLKData.authToken)) {
            return;
        }

        try
        {
            modalDispatch.current?.destroy();
            await lkAuth.login(authLKData, signal);
            modalDispatch.current = null;
        }
        catch (ex: unknown) {
            
            if(ex instanceof OperationAbortedByMergeException) {
                return;
            }

            modalDispatch.current = notificationError(ex);
            console.error("Error on login from LK data: {ex}", ex);
        }
        
    }, [authLKData]);

    const login = useCallback(() => {

        if(!authLKData) {
            return;
        }

        const abortController = new AbortController();
        loginAsync(abortController.signal);
        return () => {
            abortController.abort(new OperationAbortedByMergeException);
        }

    }, [loginAsync]);

    useEffect(() => {
        return login();
    }, [login]);

    useEffect(() => {

        const data = extractAuthData(window.location.search);
        setAuthLKData(data);

        // TODO check change win url

    }, [window.location.search]);

    // Не показываем элементы, если пользователь еще ниразу не авторизовывался
    if (auth.data?.staticId === undefined && !auth.isLoging) {
        return (<></>);
    }

    return (
        <>
            {children}
        </>
    );
}

export default LoginHelper;