import { Checkbox, Divider, Form, message, Segmented, Select, Skeleton, TimePicker } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useMemo, useRef, useState } from "react";
import { getCommonShiftList, ICommonShiftItem, IEmployeeScheduleDay, IDayTypeItem, IScheduleBoardEmployee, IScheduleBoardFilter, ActivityType, IScheduleActivityToDb, ScheduleActionEditorType, DayType, IEmployee, ActivityPointType, saveEmployeeDayEditorDbActivitys } from "../../Entities/Database";
import { ILoadListState, } from "../../Reducer/LoadListReducer";
import { isString, nameof, RangeValue } from "../../System/Utils";
import { dictionaryToOptions } from "../shared/helpers";
import { getEventId } from "../ScheduleIntervalEditor/utils";
import { useListLoader } from "../../Hooks/useListLoader";
import DraggableModal, { IDraggableModalProps } from "../DraggableModal";
import useAuth from "../../Hooks/useAuth";
import dayjs from "dayjs";
import { InvalidDataException } from "../../System/InvalidDataException";
import './style.css';
import { searchInValue } from "../../Reducer/SearchFilterReducer";

export const dateKeyFormat = "DD.MM.YYYY";
export const getFIO = (employee: IEmployee) => `${employee.surname} ${employee.firstName} ${employee.secondName}`;
export type FormDataType = 'interval' | 'commonShift';

export type SuccessSaveHandler = (values: IScheduleBoardRecordEditorValues) => void;

export interface IInternalScheduleBoardRecordEditorValues {
    dayType: ActivityType,
    dataType: FormDataType,
    interval: RangeValue<dayjs.Dayjs> | undefined,
    commonShift: ICommonShiftItem | string | undefined,
    sideJob: boolean,
}

export interface IScheduleBoardRecordEditorValues extends IInternalScheduleBoardRecordEditorValues {
    item: IEmployeeScheduleDay,
    commonShift: ICommonShiftItem,
}

export interface IScheduleBoardRecordEditorProps extends IDraggableModalProps {
    filter: IScheduleBoardFilter,
    dayTypes: ILoadListState<IDayTypeItem>,
    employees: ILoadListState<IScheduleBoardEmployee>,
    item: IEmployeeScheduleDay,
}

export function EmployeesDayScheduleCellEditor({
    open,
    filter,
    dayTypes,
    employees,
    item,
    title,
    okText,
    cancelText,
    onCancel,
    ...draggableModalProps
}: IScheduleBoardRecordEditorProps) {

    const authProvider = useAuth();
    const eventRef = useRef<React.MouseEvent<HTMLButtonElement, MouseEvent>>();
    const [saving, setSaving] = useState(false);
    const [dataEditable, setDataEditable] = useState(item.typeId === DayType.Work);
    const [formDataType, setFormDataType] = useState<FormDataType>('commonShift');
    const [form] = useForm<IInternalScheduleBoardRecordEditorValues>();

    const [commonShiftList, reloadCommonShiftList] = useListLoader(
        signal => getCommonShiftList(authProvider, filter, item, signal),
        [authProvider.data?.staticId, filter, item]
    );

    useEffect(() => {

        if(!open) {
            return;
        }

        form.resetFields();
        setFormDataType('commonShift');
        setDataEditable(item.typeId === DayType.Work);
        
        return reloadCommonShiftList();

    }, [open, item, authProvider.data?.staticId]);

    const dayTypeItems = useMemo(() => dictionaryToOptions(dayTypes, 'id', 'title'), [dayTypes.items]);

    const commonShiftItems = useMemo(() => {
        return commonShiftList.items.map((item) => {
            return (
                <Select.Option
                    key={`${item.title}_${item.start}_${item.duration}`}
                    value={JSON.stringify(item)}
                >
                    {item.title}
                </Select.Option>
            )
        });
    }, [commonShiftList.items]);

    const internalTitle = useMemo(() => {

        if (!employees.load) {
            return <Skeleton.Button size="small" active={employees.loading} />
        }

        const employee = employees.items.filter(x => x.PID == item.PID)[0];
        const day = item.date.format(dateKeyFormat);

        if (!employee) {
            return <Skeleton.Button size="small" active={employees.loading} />
        }

        return `Редактирование графика: ${getFIO(employee)} [${day}]`;

    }, [employees.items, item]);

    const handleFinish = async (values: IInternalScheduleBoardRecordEditorValues) => {

        try {

            setSaving(true);

            const prevEvent = eventRef.current;
            eventRef.current = undefined;

            const abortSignal = undefined;
            const dataType    = values.dataType;
            const commonShift = values.commonShift;
            const employee    = employees.items.filter(x => x.PID == item.PID)[0];
            const dayType     = values.dayType;
            const pointTypeId = values.sideJob
            ? ActivityPointType.SideJob
            : ActivityPointType.None;

            const date = item.date.clone().startOf('day');
            let start  = date.clone();
            let end    = date.clone().endOf('day');

            if (dayType === ActivityType.WorkingTime) {

                if (dataType === 'commonShift') {

                    const normalizeCommonShift = isString(commonShift)
                    ? JSON.parse(commonShift) as ICommonShiftItem
                    : commonShift;

                    if (normalizeCommonShift === undefined) {
                        throw new InvalidDataException('CommonShift invalid');
                    }

                    start = date.clone().add(normalizeCommonShift.start, 'second');
                    end   = date.clone().add(normalizeCommonShift.start + normalizeCommonShift.duration, 'second');
                }
                else if (dataType === 'interval') {

                    const interval = values.interval;

                    if (!interval || !interval[0] || !interval[1]) {
                        throw new InvalidDataException('Interval invalid');
                    }

                    const intervalStart = interval[0];
                    const intervalEnd   = interval[1];
                    const endDayOffset  = intervalStart > intervalEnd ? 1 : 0;

                    start = date.clone().add(intervalStart.hour(), 'hour').add(intervalStart.minute(), 'minute');
                    end   = date.clone().add(intervalEnd.hour(), 'hour').add(intervalEnd.minute(), 'minute').add(endDayOffset, 'day');
                }
                else {

                    throw new InvalidDataException('DataType invalid');
                }
            }

            const id = getEventId();
            const employeeEvents: IScheduleActivityToDb[] = [{
                id: id,
                shId: undefined,
                typeId: dayType,
                pointTypeId: pointTypeId,
                start: start,
                end: end,
            }];

            await saveEmployeeDayEditorDbActivitys(
                authProvider,
                filter,
                ScheduleActionEditorType.DayEditor,
                employee,
                date,
                employeeEvents,
                abortSignal
            );

            if (draggableModalProps.onOk && prevEvent) {
                draggableModalProps.onOk(prevEvent);
            }
        }
        catch (ex) {
            console.error(ex);
            message.error('Не удалось сохранить изменение');
        }
        finally {
            setSaving(false);
        }
    }

    return (
        <DraggableModal
            width={400}
            {...draggableModalProps}
            destroyOnClose
            open={open}
            title={internalTitle || `Редактирование графика`}
            okText={okText || `Сохранить`}
            cancelText={cancelText || `Отмена`}
            onCancel={(e) => {

                if(onCancel) {
                    onCancel(e);
                }
            }}
            onOk={(e) => {
                eventRef.current = e;
                form.submit();
            }}
            okButtonProps={{
                loading: saving,
                disabled: !commonShiftList.load
            }}
        >
            <Divider type="horizontal" />
            <Form<IInternalScheduleBoardRecordEditorValues>
                form={form}
                onValuesChange={(changedValues, values) => {
                    setDataEditable(values.dayType == ActivityType.WorkingTime);
                    setFormDataType(values.dataType);
                }}
                onFinish={handleFinish}
            >
                <Form.Item
                    name={nameof<IScheduleBoardRecordEditorValues>('dayType')}
                    label={`Тип дня`}
                    rules={[{ required: true, message: 'Выберите тип дня' }]}
                    initialValue={item.typeId}
                >
                    <Select
                        loading={dayTypes.loading}
                        showSearch
                        filterOption={(inputValue, option) => searchInValue(option?.children, inputValue)}
                    >
                        {dayTypeItems}
                    </Select>
                </Form.Item>
                <Form.Item
                    style={{display: !dataEditable ? 'none' : undefined}}
                    name={nameof<IScheduleBoardRecordEditorValues>('dataType')}
                    rules={[{ required: dataEditable }]}
                    initialValue={formDataType}
                    valuePropName="checked"
                >
                    <Segmented
                        block
                        disabled={!dataEditable}
                        options={[
                            {label: 'Шаблон', value: 'commonShift'},
                            {label: 'Интервал', value: 'interval'}
                        ]}
                    />
                </Form.Item>
                <Form.Item
                    //label={`Шаблон`}
                    name={nameof<IScheduleBoardRecordEditorValues>('commonShift')}
                    style={{display: formDataType !== 'commonShift' || !dataEditable ? 'none' : undefined}}
                    rules={[{
                        required: dataEditable && formDataType === 'commonShift',
                        message: 'Выберите шаблон'
                    }]}
                >
                    <Select
                        loading={commonShiftList.loading}
                        disabled={!dataEditable}
                    >
                        {commonShiftItems}
                    </Select>
                </Form.Item>
                <Form.Item
                    //label={`Интервал`}
                    name={nameof<IScheduleBoardRecordEditorValues>('interval')}
                    style={{display: formDataType !== 'interval' || !dataEditable ? 'none' : undefined}}
                    rules={[{
                        required: dataEditable && formDataType === 'interval',
                        message: 'Введите рабочий интервал'
                    }]}
                >
                    <TimePicker.RangePicker
                        order={false}
                        format={"HH:mm"}
                        style={{width: '100%'}}
                        disabled={!dataEditable}
                        minuteStep={15}
                        needConfirm={false}
                    />
                </Form.Item>

                <Form.Item
                    name={nameof<IScheduleBoardRecordEditorValues>('sideJob')}
                    label={`Подработка`}
                    initialValue={item.pointTypeId === ActivityPointType.SideJob}
                    valuePropName="checked"
                >
                    <Checkbox />
                </Form.Item>
            </Form>
        </DraggableModal>
    );
}

export default EmployeesDayScheduleCellEditor