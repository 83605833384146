import axios, { CanceledError } from 'axios';
import moment from 'moment';
import { scheduleQueueMassEdit, type IChartDataItem, type IOriginalActivity, type IOriginalActivityDb, ScheduleActionEditorType, IScheduleEmployeeActivityToDb } from './Database';
import { getHeaders } from '../Server/DbProvider';
import { IAuthData } from '../Server/IAuthData';
import { IAuthState } from './IAuthProvider';

//export const backendUrl = "http://localhost:5094";
//export const backendUrl = "http://localhost:5099";
//export const backendUrl = "https://gen1.wfm.astrapage.ru";
//export const backendUrl = "https://gen2.wfm.astrapage.ru";
//export const client = axios.create({ baseURL: backendUrl });

export const backendUrls = {
    "Gen1": "https://gen1.wfm.astrapage.ru",
    "Gen2": "https://gen2.wfm.astrapage.ru",
    "Dev1": "http://localhost:5094",
};

export interface IGeneratedDataDb {
    id: string,
    graph: IChartDataItem[],
    activities: IOriginalActivityDb[],
}

export interface IGeneratedData {
    id: string,
    graph: IChartDataItem[],
    activities: IOriginalActivity[],
}

export function convertFromDb(data: IGeneratedDataDb): IGeneratedData {

    const activities: IOriginalActivity[] = data.activities.map(x => ({
        ...x,
        start: moment(x.start),
        end: moment(x.end),
        minStart: x.minStart ? moment(x.minStart) : undefined,
        maxEnd: x.maxEnd ? moment(x.maxEnd) : undefined
    }));

    return {
        ...data,
        activities,
    }
}

export async function generateForRange(
    auth: IAuthState<IAuthData>,
    serverUrl: string,
    start: moment.Moment,
    end: moment.Moment,
    signal?: AbortSignal
) {

    try {

        const normalizeFrom = start.clone().startOf('day').toISOString(true);
        const normalizeTo   = end.clone().endOf('day').toISOString(true);
        const params = {
            from: normalizeFrom,
            to:   normalizeTo,
            castCount: 25000,
            fullSearchCount: 25000,
        };

        const query = {
            authData: auth.data,
            procedure: 'Generate',
        }

        const response = await axios.get<IGeneratedDataDb>(serverUrl + `/Generator/GetCast`, {
            headers: getHeaders(query),
            params,
            signal,
        });
        
        return convertFromDb(response.data);
    }
    catch (ex) {

        if (ex instanceof CanceledError && signal?.aborted) {

            throw signal.reason;
        }

        throw ex;
    }
}

export async function getForcastAndActivities(auth: IAuthState<IAuthData>, serverUrl: string, date: moment.Moment, signal?: AbortSignal): Promise<IGeneratedData> {
    return generateForRange(auth, serverUrl, date, date, signal);
}

export async function applyGenerateData(auth: IAuthState<IAuthData>, data: IGeneratedData, publish: boolean = false, signal?: AbortSignal) {
    
    const toDb: IScheduleEmployeeActivityToDb[] = data.activities.map(x => ({
        PID: x.PID,
        id: x.id,
        shId: x.shId,
        originalId: undefined,
        typeId: x.typeId,
        pointTypeId: x.pointTypeId,
        start: x.start.clone(),
        end: x.end.clone(),
    }));

    return await scheduleQueueMassEdit(auth, ScheduleActionEditorType.Generator, data.id, toDb, publish, signal);
}