import { Button, Modal as AntdModal, message as AntdMessage } from "antd";
import DraggableModal from "../../DraggableModal";
import useAuth from "../../../Hooks/useAuth";
import { LinearBreaksListEditor } from "../LinearBreakListEditor";
import { ExclamationCircleFilled, PlusOutlined } from "@ant-design/icons";
import { useEffect, useMemo, useRef, useState } from "react";
import { BreakModalEditor, INewBreak, Paid } from "../BreakModalEditor";
import { TBreak, ISurfaceBreak, IEmployee, getBreakSurfaceList, getBreakList, IScheduleWorkTime, getTemplateWorkTimeList, saveScheduleBreaksTemplateToBuffer, saveScheduleBreaksTemplate, ICustomBreak, ILinearBreak, isLinearBreak } from "../../../Entities/Database";
import { doOrAbort } from "../../../System/Utils";
import { ScheduleTemplateWorkTimeList } from "../ScheduleTemplateWorkTimeList";
import { IWorkTimeId, inOneGroup } from "../ScheduleTemplateWorkTimeList/helpers";
import { getBreakId, getNewBreak, updateBreak } from "./helpers";
import { BackendResponseException } from "../../../System/BackendResponseException";
import { ScheduleShiftWorkTimeDetail, WorkTimeType } from "../ScheduleShiftWorkTimeDetail";
import { CustomBreaksListEditor } from "../CustomBreaksListEditor";
import useActual from "../../../Hooks/useActual";
import { AutoCompleteModal } from "../AutoCompleteModal";
import { ICustomBreakRange, getRange } from "../CustomBreaksListEditor/helpers";
import { ScheduleShiftWorkInfo } from "../ScheduleShiftWorkInfo";
import './style.css';

export interface IBreakSchemeData {
    bsId: string, 
    id: string, 
    name: JSX.Element,
}

const abortByChange = {};
const abortByClose = {};

export interface IScheduleShiftBreaksEditorModalProps {
    open: boolean,
    onCancel: () => void,
    onFinishSave: () => void,
    employee?: IEmployee,
}

export function ScheduleShiftBreaksEditorModal({
    open,
    onCancel,
    onFinishSave,
    employee,
}: IScheduleShiftBreaksEditorModalProps) {

    const auth = useAuth();
    const [modal, modalContextHolder] = AntdModal.useModal();
    const [message, messageContextHolder] = AntdMessage.useMessage();
    const [selectedWorkTimeId, setSelectWorkTimeId] = useState<IWorkTimeId>();
    const [selectedBreakId, setSelectedBreakId] = useState<TBreak['id']>();
    const [openBreakModalEditor, setOpenBreakModalEditor] = useState(false);
    const [loadingBreakScheme, setLoadingBreaks] = useState(false);
    const [loadingBreakSchemeList, setLoadingSurfaceBreaks] = useState(false);
    const [loadingWorkTimeList, setLoadingWorkTimeList] = useState(false);
    const [workTimeList, setWorkTimeList] = useState<IScheduleWorkTime[]>([]);
    const [breaks, setBreaks] = useState<TBreak[]>();
    const [breakSchemeListType, setBreakSchemeListType] = useState<ISurfaceBreak[]>();
    const [resetProcess, setResetProcess] = useState(false);
    const [saving, setSaving] = useState(false);
    const [openApplyTemplateModal, setOpenApplyTemplateModal] = useState(false);
    const [customBreakRange, setCustomBreakRange] = useState<ICustomBreakRange>();

    const handleCancelSelectTemplate = () => {
        setOpenApplyTemplateModal(false);
    }

    const selectedBreak = useMemo(() => {

        if (!breaks ||
            breaks.length < 1 ||
            selectedBreakId === undefined) {
            return undefined;
        }

        return breaks.find(x => x.id === selectedBreakId);

    }, [selectedBreakId, breaks]);

    const selectedWorkTime = useMemo(() => {

        if (!workTimeList ||
            workTimeList.length < 1 ||
            selectedWorkTimeId === undefined) {
            return undefined;
        }

        return workTimeList.find(x => inOneGroup(x, selectedWorkTimeId));

    }, [selectedWorkTimeId, workTimeList]);

    const currentBreaks = useMemo(() => {

        if (selectedWorkTimeId && breaks) {
            return breaks.filter(x => inOneGroup(x, selectedWorkTimeId));
        }

        return undefined;

    }, [selectedWorkTimeId, breaks]);

    const destructorAcRef = useRef<AbortController>();
    const loaderAcRef = useRef<AbortController>();
    
    const abortPrevReq = (reason: unknown) => {

        if (loaderAcRef.current) {
            loaderAcRef.current.abort(reason);
        }
    }

    const handleClickCreateBreakBtn = () => {
        setSelectedBreakId(undefined);
        setOpenBreakModalEditor(true);
    }

    const handleCloseBreakModalEditor = () => {

        setSelectedBreakId(undefined);
        setOpenBreakModalEditor(false);

        if (customBreakRange) {
            setCustomBreakRange(undefined);
            setBreaks(x => x ? [...x] : x);
        }
    }

    const handleClickWorkTime = (workTime: IScheduleWorkTime | undefined) => {
        setSelectedBreakId(undefined);
        setSelectWorkTimeId(workTime);
    }

    const handleDoubleClickWorkTime = (workTime: IScheduleWorkTime | undefined) => {

        setSelectedBreakId(undefined);
        setSelectWorkTimeId(workTime);

        if (workTime?.isCustom) {
            message.error("Автозаполнение не работает для нестандартных типов перерывов.");
            return;
        }

        setOpenApplyTemplateModal(true);
    }

    const saveChanges = async (newBreaks: TBreak[], signal?: AbortSignal): Promise<boolean> => {

        if (!employee) {
            return false;
        }

        try {
            setSaving(true);

            const result = await saveScheduleBreaksTemplateToBuffer(auth, employee.PID, newBreaks, signal);

            setBreaks(newBreaks);
            setWorkTimeList(workTimeList => {

                const newWorkTimeList = [...workTimeList];

                for (const workTimeCalc of result) {

                    const workTimeIndex = newWorkTimeList.findIndex(x =>
                        x.dayId === workTimeCalc.dayId &&
                        x.segmentId === workTimeCalc.segmentId
                    );

                    if (workTimeIndex !== -1) {

                        newWorkTimeList[workTimeIndex] = {
                            ...newWorkTimeList[workTimeIndex],
                            ...workTimeCalc,
                        }
                    }
                }

                return newWorkTimeList;
            });

            return true;
        }
        catch (ex) {
            message.error(ex instanceof BackendResponseException ? ex.message : 'Не удалось сохранить шаблон');
            console.error(ex);
            setBreaks(x => x ? [...x]: undefined);
            return false;
        }
        finally {
            setSaving(false);
        }
    }

    const saveChangesActual = useActual(saveChanges);

    const handleCreateBreak = async (breakTmp: INewBreak) => {

        setSelectedBreakId(undefined);

        if (selectedWorkTimeId) {

            const newBreak  = getNewBreak(breakTmp, selectedWorkTimeId, currentBreaks);
            const newBreaks = breaks
                ? [...breaks, newBreak]
                : [newBreak];
            
            const change = await saveChanges(newBreaks);

            if (change) {
                setOpenBreakModalEditor(false);
            }
        }
    }

    const handleChangeBreak = async (breakItem: TBreak, breakTmp: INewBreak) => {

        setSelectedBreakId(breakItem.id);

        if (selectedWorkTimeId) {
            
            const newBreaks  = breaks ? [...breaks] : [];
            const breakIndex = newBreaks.indexOf(breakItem);

            if (breakIndex !== -1) {

                newBreaks[breakIndex] = updateBreak(breakTmp, breakItem, selectedWorkTimeId);

                const change = await saveChanges(newBreaks);

                if (change) {
                    setOpenBreakModalEditor(false);
                }
            }
        }
    }

    const handleSelectBreak = (breakItem: TBreak | undefined) => {

        if (openBreakModalEditor && !breakItem) {
            return;
        }

        setSelectedBreakId(breakItem?.id);
        setCustomBreakRange(undefined);
    }

    const handleDoubleClickBreak = (breakItem: TBreak) => {

        if (selectedWorkTime) {

            const range = getRange(selectedWorkTime, breakItem);

            setSelectedBreakId(breakItem.id);
            setCustomBreakRange(range);
            setOpenBreakModalEditor(true);
        }
    }

    const loadBreaks = async (signal: AbortSignal, employee: IEmployee) => {

        try {
            setLoadingBreaks(true);
             
            const loadedBreaks = await doOrAbort(
                signal => getBreakList(auth, employee.PID, signal),
                signal,
                destructorAcRef.current?.signal
            );

            setBreaks(loadedBreaks.map(x => ({
                id: getBreakId(),
                ...x,
            })));
            setLoadingBreaks(false);
        }
        catch(ex) {

            if (ex === abortByChange || ex === abortByClose) {
                return;
            }

            message.error("При сохранении данных возникла ошибка.");
        }
    }

    const loadWorkTimeList = async (signal: AbortSignal, employee: IEmployee) => {

        try {
            setLoadingWorkTimeList(true);
            const list = await doOrAbort(
                signal => getTemplateWorkTimeList(auth, employee.PID, signal),
                signal,
                destructorAcRef.current?.signal
            );
            setWorkTimeList(list); 
            setLoadingWorkTimeList(false);
            setSelectWorkTimeId(list[0]);
        }
        catch(ex) {

            if (ex === abortByChange || ex === abortByClose) {
                return;
            }

            message.error("При загрузке рабочего времени возникла ошибка.");
        }
    }

    const loadBreakSurfaceList = async (signal: AbortSignal, employee: IEmployee) => {

        try {
            setLoadingSurfaceBreaks(true);
             
            const loadedBreakSchemeList: ISurfaceBreak[] = await doOrAbort(
                signal => getBreakSurfaceList(auth, employee.PID, signal),
                signal,
                destructorAcRef.current?.signal
            );
            setBreakSchemeListType(loadedBreakSchemeList); 
            setLoadingSurfaceBreaks(false);
        }
        catch(ex) {

            if (ex === abortByChange || ex === abortByClose) {
                return;
            }

            message.error("При загрузке данных перерывов возникла ошибка.");
        }
    }

    useEffect(() => {

        if(open) {

            const ac = loaderAcRef.current = new AbortController();
            const signal = ac.signal;

            if (employee !== undefined) {
                loadWorkTimeList(signal, employee);
                loadBreaks(signal, employee);
                loadBreakSurfaceList(signal, employee);
            }

            return () => {
                abortPrevReq(abortByChange);
            }
        }

    }, [auth.data?.staticId, open]);

    useEffect(() => () => {

        if (destructorAcRef.current) {
            destructorAcRef.current.abort(abortByClose);
        }

    }, []);

    const handleLinearBreaksSort = (sorted: TBreak['id'][]) => {

        setSelectedBreakId(undefined);
        setCustomBreakRange(undefined);

        const newBreaks = breaks ? [...breaks] : [];

        for (let newSort = 0; newSort < sorted.length; newSort++) {

            const id = sorted[newSort];
            const breakItem = currentBreaks?.find(x => x.id === id);

            if (breakItem && isLinearBreak(breakItem)) {

                const index = newBreaks.indexOf(breakItem);

                if (index !== -1) {

                    newBreaks[index] = {
                        ...breakItem,
                        sort: newSort + 1,
                    }
                }
            }
        }

        saveChanges(newBreaks);
    }

    const handleBreakDelete = (deletedBreakItem: TBreak) => {

        setSelectedBreakId(undefined);
        setCustomBreakRange(undefined);

        const newBreaks: TBreak[] = [];

        if (breaks) {

            for (let breakItem of breaks) {

                if (breakItem.id !== deletedBreakItem.id) {

                    if (inOneGroup(breakItem, deletedBreakItem) &&
                        deletedBreakItem.sort !== null &&
                        breakItem.sort !== null &&
                        deletedBreakItem.sort > breakItem.sort) {
                        breakItem = {
                            ...breakItem,
                            sort: breakItem.sort - 1,
                        };
                    }

                    newBreaks.push(breakItem);
                }
            }
        }

        saveChanges(newBreaks);
    }

    const handleOk = async () => {

        try {

            if (!employee) {
                return;
            }

            const confirmModal = modal.confirm({
                title: "Подтвердите действие",
                content: "Вы уверены, что хотите сохранить шаблон ?",
                okText: 'Да',
                cancelText: 'Нет',
                onOk: async () => {

                    confirmModal.update(x => ({
                        ...x,
                        closable: false,
                        keyboard: false,
                        okButtonProps: {
                            loading: true,
                        },
                        cancelButtonProps: {
                            disabled: true
                        },
                    }));

                    await saveScheduleBreaksTemplate(auth, employee.PID);
                },
            });

            const result = await confirmModal;

            if (result && onFinishSave) {
                onFinishSave();
            }
        }
        catch (ex) {
            message.error(ex instanceof BackendResponseException ? ex.message : 'Не удалось сохранить шаблон');
            console.error(ex);
        }
    }

    const handleCancel = () => {
        
        if (saving) {
            return;
        } 

        if (onCancel) {
            onCancel();
        } 
    }

    const handleChangeWorkTimeType = (type: WorkTimeType) => {

        if (!selectedWorkTime) {
            return;
        }

        const isCustom = type === WorkTimeType.Custom;
        const newWorkTimeList = workTimeList.map(x => ({
            ...x,
            isCustom: isCustom,
            brPayed: 0,
            brUnPayed: 0,
        }));

        setWorkTimeList(newWorkTimeList);
        setSelectedBreakId(undefined);
        setCustomBreakRange(undefined);
        setBreaks([]);
    }

    const handleApplyTemplate = (workTimeBreaks: TBreak[]) => {
        
        if (!breaks || workTimeBreaks.length < 1) {
            return;
        }

        const confirmModal = modal.confirm({
            title: 'Подтвердите перезапись',
            icon: <ExclamationCircleFilled />,
            content: 'Перерывы для всего рабочего дня будут перезаписаны.',
            okText: 'Да',
            cancelText: 'Нет',
            onOk: async () => {

                confirmModal.update(x => ({
                    ...x,
                    closable: false,
                    keyboard: false,
                    cancelButtonProps: {disabled: true },
                    okButtonProps: { loading: true },
                }));

                setSelectedBreakId(undefined);
                setCustomBreakRange(undefined);

                const dayId = workTimeBreaks[0].dayId;
                const newBreaks = breaks.filter(x => x.dayId !== dayId);

                newBreaks.push(...workTimeBreaks);
                const success = await saveChangesActual.current(newBreaks);

                if (success) {
                    setOpenApplyTemplateModal(false);
                } 
            },
        });
    }

    const handleReset = () => {

        const confirmModal = modal.confirm({
            title: 'Подтвердите действие',
            icon: <ExclamationCircleFilled />,
            content: 'Вы уверены, что хотите очистить список перерывов ?',
            okText: 'Да',
            cancelText: 'Нет',
            onOk: async () => {

                confirmModal.update(x => ({
                    ...x,
                    closable: false,
                    keyboard: false,
                    cancelButtonProps: {disabled: true },
                    okButtonProps: { loading: true },
                }));

                setSelectWorkTimeId(undefined);
                setSelectedBreakId(undefined);
                setCustomBreakRange(undefined);
                setResetProcess(true);

                await saveChangesActual.current([]);

                setResetProcess(false);
            }
        });
    }

    const handleCreateCustomEvent = async (range: ICustomBreakRange) => {
        setSelectedBreakId(undefined);
        setCustomBreakRange(range);
        setOpenBreakModalEditor(true);
    }

    const handleChangeCustomEvent = async (breakItem: TBreak, range: ICustomBreakRange) => {
        
        setSelectedBreakId(breakItem.id);
        setCustomBreakRange(range);

        if (selectedWorkTimeId) {
            
            const newBreaks = breaks ? [...breaks] : [];
            const breakIndex = newBreaks.indexOf(breakItem);

            if (breakIndex !== -1 && !isLinearBreak(breakItem)) {

                let durn = breakItem.durn;
                const maxDurn = range.rightOffset - range.leftOffset;

                if (durn > maxDurn) {
                    durn = maxDurn
                }

                newBreaks[breakIndex] = {
                    ...breakItem,
                    durn: durn,
                    leftOffset: range.leftOffset,
                    rightOffset: range.rightOffset,
                }

                const change = await saveChanges(newBreaks);

                if (change) {
                    setOpenBreakModalEditor(false);
                }
            }
        }
    }

    return(
        <>
            <DraggableModal
                open={open}
                onCancel={handleCancel}
                title="Редактор шаблона перерывов"
                okText="Сохранить"
                cancelText="Закрыть"
                width='100%'
                keyboard={!saving}
                style={{
                    top: 20,
                }}
                closable={!saving}
                footer={[
                    <Button
                        key="reset"
                        onClick={handleReset}
                        disabled={loadingBreakScheme || loadingBreakSchemeList || resetProcess || saving}
                        loading={resetProcess}
                    >
                        Очистить
                    </Button>,
                    <Button
                        key="cancel"
                        disabled={saving}
                        onClick={handleCancel}
                    >
                        Закрыть
                    </Button>,
                    <Button
                        key="ok"
                        type="primary"
                        disabled={loadingBreakScheme || loadingBreakSchemeList || saving}
                        loading={!resetProcess && saving}
                        onClick={handleOk}
                    >
                        Сохранить
                    </Button>
                ]}
            >
                <BreakModalEditor
                    open={openBreakModalEditor}
                    onCancel={handleCloseBreakModalEditor}
                    onCreate={handleCreateBreak}
                    onChange={handleChangeBreak}
                    data={breakSchemeListType}
                    breakItem={selectedBreak}
                    breakRange={customBreakRange}
                    loading={loadingBreakScheme || loadingBreakSchemeList}
                    saving={saving}
                />
                <div className="scheduleShiftBreaksEditorModal__modal-wrap">
                    {selectedWorkTime &&
                    !selectedWorkTime.isCustom &&
                    <div className="scheduleShiftBreaksEditorModal__modal-menu">
                        <LinearBreaksListEditor
                            selectedBreak={selectedBreak}
                            surfaceBreaks={breakSchemeListType}
                            breaks={currentBreaks}
                            loading={loadingBreakScheme || loadingBreakSchemeList || saving}
                            onSort={handleLinearBreaksSort}
                            onDelete={handleBreakDelete}
                            onSelect={handleSelectBreak}
                            onDoubleClick={handleDoubleClickBreak}
                        />
                        <Button
                            size="small"
                            icon={<PlusOutlined />}
                            onClick={handleClickCreateBreakBtn}
                            disabled={loadingBreakScheme || loadingBreakSchemeList || saving}
                            className="scheduleShiftBreaksEditorModal__add-button"
                        />
                    </div>}
                    <div className="scheduleShiftBreaksEditorModal__modal-template">
                        <div className="scheduleShiftBreaksEditorModal__flex-wrap">
                            <ScheduleShiftWorkTimeDetail
                                workTime={selectedWorkTime}
                                loading={saving || loadingWorkTimeList}
                                onChangeType={handleChangeWorkTimeType}
                            />
                            <ScheduleShiftWorkInfo 
                                employee={employee}
                            />
                        </div>
                        <ScheduleTemplateWorkTimeList
                            loading={loadingWorkTimeList}
                            times={workTimeList}
                            breaks={breaks}
                            selectedTime={selectedWorkTime}
                            selectedBreak={selectedBreak}
                            onClickWorkTime={handleClickWorkTime}
                            onDoubleClickWorkTime={handleDoubleClickWorkTime}
                        />
                        {selectedWorkTime &&
                        !!selectedWorkTime.isCustom &&
                        <CustomBreaksListEditor
                            selectedBreak={selectedBreak}
                            surfaceBreaks={breakSchemeListType}
                            breaks={currentBreaks}
                            loading={loadingBreakScheme || loadingBreakSchemeList || saving}
                            workTime={selectedWorkTime}
                            onCreate={handleCreateCustomEvent}
                            onChange={handleChangeCustomEvent}
                            onDelete={handleBreakDelete}
                            onSelect={handleSelectBreak}
                            onDoubleClick={handleDoubleClickBreak}
                        />}
                    </div>
                </div>
            </DraggableModal>
            <AutoCompleteModal
                open={openApplyTemplateModal}
                employee={employee}
                selectedTime={selectedWorkTime}
                surfaceBreaks={breakSchemeListType}
                onApply={handleApplyTemplate}
                onCancel={handleCancelSelectTemplate}
            />
            {modalContextHolder}
            {messageContextHolder}
        </>
    )
}

export default ScheduleShiftBreaksEditorModal;