import moment, { Moment } from "moment";
import { IAuthState } from "../../Entities/IAuthProvider";
import { IAuthData } from "../../Server/IAuthData";
import { execute } from "../../Server/DbProvider";
import { DateQueryParameter, IntQueryParameter, StringQueryParameter } from "../../Server/Query";
import { IScheduleBoardFilter } from "../../Entities/Database";

export const getLeavesProcName           = "[Control].[dbo].[proc_WFM_Leave]";

export interface ILeavesDb {
    PID: string,
    durn: number,
    id: string,
    sd: string,
    status: number
    type: number,
    target: number,
    total: number,
    requestId?: number,
}

export interface IDayLeaveDb {
    dt: string,
    recordId: number,
    title: string,
    value: number,
    weekNum: number
}


export interface IDayLeave extends Omit<IDayLeaveDb, 'dt'> {
    dt: Moment,
}

export interface IWeekLeaveDb {
    recordId: number,
    title: string,
    value: number,
    weekNum: number,
    sdt: string,
}

export interface IWeekLeave extends Omit<IWeekLeaveDb, 'sdt'> {
    sdt: Moment,
}

export interface ILeaves extends Omit<ILeavesDb, 'sd'> {
    sd: Moment,
}

export async function getLeaves(
    auth: IAuthState<IAuthData>,
    range: [Moment, Moment],
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
   
    const result = await execute<ILeavesDb[]>({
        authData: auth.data,
        procedure: getLeavesProcName,
        parameters: [
            new DateQueryParameter("sd", range[0]),
            new DateQueryParameter("ed", range[1]),
            new IntQueryParameter("RType", 201),
            new IntQueryParameter("LVSt_ID", -1),
            new IntQueryParameter("LVTp_ID", -1),
        ]
    }, signal);

    const normalizeresult: ILeaves[] = result.map(item => ({
        ...item,
        sd: moment(item.sd),
    }))


    return normalizeresult;
}

export async function getDayGridLeaves(
    auth: IAuthState<IAuthData>,
    range: [Moment, Moment],
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
   
    const result = await execute<IDayLeaveDb[]>({
        authData: auth.data,
        procedure: getLeavesProcName,
        parameters: [
            new DateQueryParameter("sd", range[0]),
            new DateQueryParameter("ed", range[1]),
            new IntQueryParameter("RType", 203),
            new IntQueryParameter("LVSt_ID", -1),
            new IntQueryParameter("LVTp_ID", -1),
        ]
    }, signal);

    return result;
}

export async function getWeekGridLeaves(
    auth: IAuthState<IAuthData>,
    range: [Moment, Moment],
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
   
    const result = await execute<IWeekLeaveDb[]>({
        authData: auth.data,
        procedure: getLeavesProcName,
        parameters: [
            new DateQueryParameter("sd", range[0]),
            new DateQueryParameter("ed", range[1]),
            new IntQueryParameter("RType", 204),
            new IntQueryParameter("LVSt_ID", -1),
            new IntQueryParameter("LVTp_ID", -1),
        ]
    }, signal);

    return result;
}

export async function getDayChartLeaves(
    auth: IAuthState<IAuthData>,
    range: [Moment, Moment],
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
   
    const result = await execute<IDayLeaveDb[]>({
        authData: auth.data,
        procedure: getLeavesProcName,
        parameters: [
            new DateQueryParameter("sd", range[0]),
            new DateQueryParameter("ed", range[1]),
            new IntQueryParameter("RType", 205),
            new IntQueryParameter("LVSt_ID", -1),
            new IntQueryParameter("LVTp_ID", -1),
        ]
    }, signal);

    const normalizeResult: IDayLeave[] = result.map(x => ({
        ...x,
        dt: moment(x.dt),
    }));
    
    return normalizeResult;
}

export async function getWeekChartLeaves(
    auth: IAuthState<IAuthData>,
    range: [Moment, Moment],
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
   
    const result = await execute<IWeekLeaveDb[]>({
        authData: auth.data,
        procedure: getLeavesProcName,
        parameters: [
            new DateQueryParameter("sd", range[0]),
            new DateQueryParameter("ed", range[1]),
            new IntQueryParameter("RType", 206),
            new IntQueryParameter("LVSt_ID", -1),
            new IntQueryParameter("LVTp_ID", -1),
        ]
    }, signal);

    const normalizeResult: IWeekLeave[] = result.map(x => ({
        ...x,
        sdt: moment(x.sdt),
    }));
    
    return normalizeResult;
}


export const getListProcName                              = "[Control].[dbo].[proc_WFM_Schedule]";

export interface ILeavesEmployeeDb {
    PID: string,
    firstName: string,
    iconType: number,
    roleCaption: string,
    roleId: number,
    secondName: string,
    surname: string,
}
export async function getListEmployees(
    auth: IAuthState<IAuthData>,
    filter: IScheduleBoardFilter,
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);

    const parameters = {

        "sd":       new DateQueryParameter("sd", filter.from),
        "ed":       new DateQueryParameter("ed", filter.to),
        "TZ":       new StringQueryParameter("TZ", filter.timeZone),
        "RType":    new IntQueryParameter("RType", 2),
    };

    return await execute<ILeavesEmployeeDb[]>({
        authData: auth.data,
        procedure: getListProcName,
        parameters: Object.values(parameters)
    }, signal);
}