import classNames from "classnames";
import { memo } from "react";
import { ContactItem } from "../ContactItem";
import { Gender, IEmployeeInfo } from "../../../Entities/Database";
import { Skeleton, Space } from "antd";
import "./style.css";

export interface IPersonalInfoProps {
    loading: boolean, 
    personalInfo: IEmployeeInfo
}

function NotMemoizedPersonalInfo(props: IPersonalInfoProps) {
    
    const {personalInfo, loading} = props;
    const {gender, fio, role, post, company, office, timezone, birthdate, contact, canEdit} = personalInfo;
   
    return (
        <div className={classNames("personal__wrap", gender && gender === Gender.Female ? "personal__pink-bg" : "personal__blue-bg") }>
            {loading &&
            <>
                <Skeleton 
                    active={true}
                    className="employee-skeleton"
                />
                <Skeleton 
                    active={true}
                    className="employee-skeleton"
                />
            </>}
            {!loading && 
            <>
                <Space className="personal__space">
                    <p className="personal__name">{fio}</p>
                </Space>
                <p className="personal__job"> 
                    {role} 
                    <span className="personal__category">({post})</span>
                    
                </p>
                <p className="personal__jobplace"> 
                    {company}
                    <span className="personal__place">{office}</span>
                    <span className="personal__uts">{timezone}</span>
                </p>
                <div className="personal__inner-wrap">
                    <div className="personal__birthday">
                        <p className="personal__birthday-title">День рождения</p>
                        <p className="personal__birthday-date">{birthdate}</p>
                    </div>
                    <div className="personal__contact">
                        <p className="personal__contact-title">Контакты</p>
                        <ul className="personal__contact-list">
                            {contact.map((item, idx) => {
                                if (typeof item.value === 'string') {                      
                                    return <ContactItem type={item.type} url={[item.value]} key={idx} />
                                }                              
                                return <ContactItem type={item.type} url={item.value} key={idx} />
                            })}
                        </ul>
                    </div>
                </div>
            </>}
        </div>
    )
}
export const PersonalInfo = memo(NotMemoizedPersonalInfo);