
import { Select } from "antd";
import { MutableRefObject } from "react";
import { ILoadListState } from "../../Reducer/LoadListReducer";
import { isFunction, isNullOrUndefined } from "../../System/Utils";

export const Option = Select.Option;

export const restartWithUpdatedData = "RestartWithUpdatedData";

export function assignRef<T>(refValue: T, ...refs: (React.Ref<T> | undefined)[]) {

    for(let i = 0; i < refs.length; i++) {

        const tmpRef = refs[i];

        if(typeof tmpRef === 'function') { tmpRef(refValue); }
        if(typeof tmpRef === 'object')   { (tmpRef as unknown as MutableRefObject<T>).current = refValue; }
    }
}

export function refSetter<T>(...refs: (React.Ref<T> | undefined)[]) {
    return (ref: T) => assignRef(ref, ...refs);
}

export function dictionaryToOptions<T>(
    state: ILoadListState<T>,
    valuePropertyName: keyof T | ((item: T) => React.Key),
    captionPropertyName: keyof T | ((item: T) => React.ReactNode),
    optionFilterProp: string = "value",
    readonly = false,
    filter = (item: T) => true
): JSX.Element[] | undefined {

    const items = state.items;

    if(!isNullOrUndefined(items)) {
        return items
        .filter(filter)
        .map(sourceItem => {
            
            const value   = isFunction(valuePropertyName) ? valuePropertyName(sourceItem) : sourceItem[valuePropertyName] as unknown as React.Key;
            const caption = isFunction(captionPropertyName) ? captionPropertyName(sourceItem) : sourceItem[captionPropertyName] as unknown as string;
            
            return (
                <Option
                    key={value}
                    value={value}
                    disabled={readonly}
                    optionFilterProp={optionFilterProp}
                >
                    {caption}
                </Option>
            )
        });
    }

    return undefined;
}