import { Reducer } from "react";
import { IFilterState } from "../Entities/IFilterState";
import { IState } from "../Entities/IState";
import { NotSupportedException } from "../System/NotSupportedException";

export interface ILoadListState<TListItem> extends IFilterState<null, TListItem>, IState<TListItem> {
    readonly load: boolean;
    readonly loading: boolean;
    readonly error?: unknown;
}

export type LIST_LOADING = 'LIST_LOADING';
export type LIST_LOAD_SUCCESS = 'LIST_LOAD_SUCCESS';
export type LIST_LOAD_FAIL = 'LIST_LOAD_FAIL';

export enum LoadListKind {
    Loading     = 'LIST_LOADING',
    LoadSuccess = 'LIST_LOAD_SUCCESS',
    LoadFail    = 'LIST_LOAD_FAIL',
}

export type LoadingAction = {
    type: LIST_LOADING,
    clearItems?: boolean
}

export type LoadFailAction = {
    type: LIST_LOAD_FAIL,
    clearItems?: boolean,
    error?: unknown;
}

export type LoadSuccessAction<TListItem> = {
    type: LIST_LOAD_SUCCESS;
    payload?: Readonly<TListItem[]>;
}

export type LoadListReducerAction<TListItem> = LoadingAction | LoadFailAction | LoadSuccessAction<TListItem>;

export const initialLoadListState: ILoadListState<any> = {
    items: [],
    sourceState: null,
    load: false,
    loading: false,
}

export const emptyArray = [];

export type LoadListReducerType<TListItem> = Reducer<ILoadListState<TListItem>, LoadListReducerAction<TListItem>>;
export type ILoadListReducer<TListItem> = (prevState: ILoadListState<TListItem>, action: LoadListReducerAction<TListItem>) => ILoadListState<TListItem>;
export function loadListReducer<TDictionaryItem>(prevState: ILoadListState<TDictionaryItem>, action: LoadListReducerAction<TDictionaryItem>): ILoadListState<TDictionaryItem> {

    const { type } = action;

    switch(type) {

        case LoadListKind.Loading: {
            
            return {
                items: action.clearItems ? emptyArray : prevState.items,
                sourceState: null,
                load: false,
                loading: true
            }
        }

        case LoadListKind.LoadSuccess: {

            return {
                items: action.payload ?? emptyArray,
                sourceState: null,
                load: true,
                loading: false
            }
        }

        case LoadListKind.LoadFail: {

            return {
                items: action.clearItems ? [] : prevState.items,
                sourceState: null,
                load: false,
                loading: false,
                error: action.error
            }
        }

        default: throw new NotSupportedException(`Action is not supported`);
    }
}

export default loadListReducer;