import './Components/shared/lib/style.css';
import 'antd-virtual-table/dist/style.css';

import { AuthProvider } from './Hooks/useGenericAuth';
import { AuthLKProvider } from './Hooks/useLKAuth';
import { LoggerProvider } from './Hooks/useLogger';
import { ConfigProvider } from 'antd';
import { AuthCredentialProvider } from './Hooks/useCredentialAuth';
import { APIHubProvider } from './Hooks/useAPIHub';
import { AuthWindow } from './Components/AuthWindow';
import { ErrorBoundary } from './Components/ErrorBoundary';
import LoginHelper from './Components/LoginHelper';
import { NotificationHubProvider } from './Hooks/useNotificactionHub';
import { RemoteControlProvider } from './Hooks/useRemoteControl';
import ru from 'antd/locale/ru_RU';
import './AppResizeEvent';
import './App.css';

import MainBoard from './Components/MainBoard';

function App() {
    return (
      <ErrorBoundary>
            <AuthProvider>
                <AuthLKProvider>
                    <AuthCredentialProvider>
                        <LoggerProvider>
                            <ConfigProvider
                                locale={ru}
                                theme={{
                                    components: {
                                        Message: {
                                            zIndexPopup: 3010,
                                        },
                                    },
                                }}
                            >
                                <APIHubProvider>
                                    <NotificationHubProvider>
                                        <RemoteControlProvider>
                                            {/*<LoginHelper />*/}
                                            <AuthWindow />
                                            <LoginHelper>
                                                <MainBoard />
                                            </LoginHelper>
                                            </RemoteControlProvider>
                                    </NotificationHubProvider>
                                </APIHubProvider>
                            </ConfigProvider>
                        </LoggerProvider>
                    </AuthCredentialProvider>
                </AuthLKProvider>
            </AuthProvider>
      </ErrorBoundary>
  );
}

export default App;
