// TODO: move to database.ts and portal app

import moment from "moment";
import { execute } from "../Server/DbProvider";
import { IAuthData } from "../Server/IAuthData";
import { IEmployeeScheduleDay, IHasRequestDay, IWithEmployable } from "./Database";
import { IAuthState } from "./IAuthProvider";
import { BoolQueryParameter, IntQueryParameter, StringQueryParameter } from "../Server/Query";
import { InvalidDataException } from "../System/InvalidDataException";

export const getShceduleShiftTradeProcName = "[Control].[dbo].[proc_WFM_ShiftTrade_Request_Response_Info]";

export interface IShiftTradeActivityDb extends IWithEmployable {
    recordId: number,
    type: number,
    start: string,
    end: string,
    caption: string,
    id: string,
    segmentId: string,
    backgroundColor: number,
}

export interface IShiftTradeActivity extends Omit<IShiftTradeActivityDb, 'start' | 'end'> {
    start: moment.Moment,
    end: moment.Moment,
}

export interface IShiftTradeResource extends IWithEmployable {
    recordId: number,
    caption1: string,
    caption2: string,
    comment: string | null,
}

export interface IShiftTradeModalProps {
    formCaption: string,
    statusId: number,
    status: string
    comment?: string | null,
    showSkillsBtn: number,
    showApproveBtn: number,
}

export async function getShiftTradeActivities(auth: IAuthState<IAuthData>, requestId: number, signal?: AbortSignal) {
    
    await auth.throwIfNotLogedInAndLogout(signal);

    const result = await execute<IShiftTradeActivityDb[]>({
        authData: auth.data,
        procedure: getShceduleShiftTradeProcName,
        parameters: [
            new IntQueryParameter("ShTrR_Resp_ID", requestId),
            new IntQueryParameter("Rtype", 1),
        ]
    }, signal);

    const normalize = result.map((x): IShiftTradeActivity => ({
        ...x,
        start: moment(x.start),
        end: moment(x.end),
    }));

    return normalize;
}

export async function getShiftTradeResources(auth: IAuthState<IAuthData>, requestId: number, signal?: AbortSignal) {
    
    await auth.throwIfNotLogedInAndLogout(signal);

    const result = await execute<IShiftTradeResource[]>({
        authData: auth.data,
        procedure: getShceduleShiftTradeProcName,
        parameters: [
            new IntQueryParameter("ShTrR_Resp_ID", requestId),
            new IntQueryParameter("Rtype", 2),
        ]
    }, signal);

    return result;
}

export async function getShiftTradeModalProps(auth: IAuthState<IAuthData>, requestId: number, signal?: AbortSignal) {
    
    await auth.throwIfNotLogedInAndLogout(signal);

    const result = await execute<IShiftTradeModalProps[]>({
        authData: auth.data,
        procedure: getShceduleShiftTradeProcName,
        parameters: [
            new IntQueryParameter("ShTrR_Resp_ID", requestId),
            new IntQueryParameter("Rtype", 3),
        ]
    }, signal);

    if (result.length !== 1) {
        throw new InvalidDataException("Server return invalid data");
    }

    return result[0];
}

export async function getShiftTradeData(auth: IAuthState<IAuthData>, item: IHasRequestDay, signal?: AbortSignal) {

    if (item.requestId) {
        
        return await Promise.all([
            getShiftTradeModalProps(auth, item.requestId, signal),
            getShiftTradeActivities(auth, item.requestId, signal),
            getShiftTradeResources(auth, item.requestId, signal),
        ]);
    }

    return undefined;
}
////////////////////////////////////////////////////////////////////////

export const getStatusListProcName   = "[Control].[dbo].[proc_WFM_Portal_Schedule_Request_Status_list_get]";

export interface IStatusDB {
    backgroundColor: number,
    borderColor: number,
    caption: string,
    id: number,
    textColor: number
}

export interface IStatus extends IStatusDB {
    text: string, 
    value: string,
}

export async function getStatusList(
    auth: IAuthState<IAuthData>,
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);

    const result = await execute<IStatusDB[]>({
        authData: auth.data,
        procedure: getStatusListProcName,
    }, signal);

    const extendedResult: IStatus[] = result.map((item) => {
        return {
            ...item,
            text: item.caption,
            value: item.caption
        }
    })

    return extendedResult;
}

//////////////////////////////////////////////////////////////////////////

export const setScheduleShiftTradeStatusProcName = "[Control].[dbo].[proc_WFM_ShiftTrade_Request_Response_Approve]";


export async function setScheduleShiftTradeStatus(
    auth: IAuthState<IAuthData>,
    requestId: number,
    comment: string,
    isAccepted: boolean,
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);

    const result = await execute<any>({
        authData: auth.data,
        procedure: setScheduleShiftTradeStatusProcName,
        parameters: [
            new IntQueryParameter("ShTrR_Resp_ID", requestId),
            new StringQueryParameter("Comment", comment),
            new BoolQueryParameter("isApproved", isAccepted)
        ]
    }, signal);

   
    console.log('TEST TEST', result);
    

    return result;
}