import { BarChartOutlined, BookOutlined, ClockCircleOutlined, FileOutlined, MonitorOutlined, ProfileOutlined, ScheduleOutlined } from "@ant-design/icons";
import { IMenuItemsLocale, menuItemsLocaleRu } from "./locale/ru";

export interface ICustomMenuItem {
    key: string, 
    icon: string | React.ReactNode,
    label: string,
    count?: number,
    children?: ICustomMenuItem[],
}

export const scheduleKey    = "1";
export const vacationKey    = "2";
export const predictionKey  = "3";
export const monitoringKey  = "4";
export const shiftChangeKey = "5";
export const appealsKey     = "5.1";
export const referencesKey  = "6";

export function getStaticMenuItems(locale: IMenuItemsLocale = menuItemsLocaleRu): ICustomMenuItem[] {
    
    return [
        { 
            key: scheduleKey, 
            icon: <ScheduleOutlined />, 
            label: locale.schedule,
        },
        { 
            key: vacationKey, 
            icon: <div><ClockCircleOutlined /></div>, 
            label: locale.vacation,
        },
        { 
            key: predictionKey, 
            icon: <div><BarChartOutlined /></div>, 
            label: locale.prediction,
        },
        { 
            key: monitoringKey, 
            icon: <div><MonitorOutlined /></div>, 
            label: locale.monitoring,
        },
        { 
            key: shiftChangeKey,
            icon: <div><ProfileOutlined /></div>, 
            label: locale.shiftChange,
            children: [
                { 
                    key: appealsKey,
                    label: locale.appeals,
                    icon: <div><FileOutlined /></div>,  
                }
            ],
        },
        { 
            key: referencesKey, 
            icon: <div><BookOutlined /></div>, 
            label: locale.references,
        },
    ];
};

export function getNormalizedMenuItems(menuItems: ICustomMenuItem[]) {
    return menuItems.map(item => ({
        ...item,
        icon: typeof item.icon === 'string' ? <img src={`/assets/icons/${item.icon}.svg`} alt={`${item.icon}`} /> : item.icon,
    }));
}