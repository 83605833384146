import { useEffect } from "react";
import { getMarkerInfoList } from "../../Entities/Database";
import { useListLoader } from "../../Hooks/useListLoader";
import DraggableModal, { IDraggableModalProps } from "../DraggableModal";
import useAuth from "../../Hooks/useAuth";
import { Skeleton } from "antd";
import { colorToStyle } from "../../System/Utils";

import './style.css';

export interface IReferenceModalProps extends IDraggableModalProps {
}

export function ReferenceModal(props: IReferenceModalProps) {

    const auth = useAuth();
    const [markerInfoList, reloadMarkerInfoList] = useListLoader(
        signal => getMarkerInfoList(auth, signal),
        [auth.data?.staticId],
        {initialState: {loading: true}}
    );

    useEffect(() => {
        if(props.open) {
            return reloadMarkerInfoList();
        }
    }, [props.open]);

    return (
        <DraggableModal
            {...props}
            cancelButtonProps={{
                ...props.cancelButtonProps,
                style: {
                    ...props.cancelButtonProps?.style,
                    display: 'none'
                }
            }}
        >
            {!markerInfoList.load &&
            <>
                <Skeleton active={markerInfoList.loading} />
            </>}
            {markerInfoList.load &&
            <div className="marker-info-list">
                {markerInfoList.items.map(markerInfo => (
                    <div
                        className="marker-info-item"
                        key={markerInfo.id}
                    >
                        <span
                            className="marker-info-ico"
                            style={{
                                backgroundColor: colorToStyle(markerInfo.backgroundColor),
                                borderColor: colorToStyle(markerInfo.borderColor)
                            }}
                        />
                        {markerInfo.caption}
                    </div>
                ))}
            </div>}
        </DraggableModal>
    )
}