import { useCallback, useEffect, useRef } from "react";
import { IEmployeeScheduleDay, IScheduleBoardEmployee, IDaysInfoblockItem } from "../../Entities/Database";
import { ILoadListState } from "../../Reducer/LoadListReducer";
import { Grid, GridOnScrollCallback } from "antd-virtual-table";

export type TEmployee = IScheduleBoardEmployee;
export type TItem = IEmployeeScheduleDay;
export type TInfoBlockItem = IDaysInfoblockItem;

export interface ScrollingSynchronizerProps {
    infoBlockDisplayed: React.RefObject<boolean>,
    infoBlockTable?: Grid<any> | null,
    employeeTable?: Grid<any> | null,
    employees?: ILoadListState<TEmployee>,
    items?: ILoadListState<TItem>,
    infoBlockItems?: ILoadListState<TInfoBlockItem>,
}

export function useScrollingSynchronizer({
    infoBlockDisplayed,
    infoBlockTable,
    employeeTable,
    employees,
    items,
    infoBlockItems
}: ScrollingSynchronizerProps) {

    const lastScrollLeft = useRef(0);

    const onInfoBlockScroll = useCallback<GridOnScrollCallback>(({ scrollLeft }) => {

        if(scrollLeft !== lastScrollLeft.current
        && infoBlockDisplayed.current
        && employeeTable
        && employeeTable.state.scrollLeft != scrollLeft
        && ((employees?.load && (items?.load || items?.error)) || (!employees?.load && !items?.load && !infoBlockItems?.load))) {

            lastScrollLeft.current = scrollLeft;
            employeeTable.scrollTo({ scrollLeft });
        }

    }, [employeeTable, infoBlockItems?.load, employees?.load, items?.load, items?.error]);

    const onEmployeeTableScroll = useCallback<GridOnScrollCallback>(({ scrollLeft }) => {

        if(scrollLeft !== lastScrollLeft.current
        && infoBlockDisplayed.current
        && infoBlockTable
        && infoBlockTable.state.scrollLeft !== scrollLeft
        && ((infoBlockItems?.load || infoBlockItems?.error) || (!employees?.load && !items?.load && !infoBlockItems?.load))) {
            lastScrollLeft.current = scrollLeft;
            infoBlockTable.scrollTo({ scrollLeft });
        }

    }, [infoBlockTable, infoBlockItems?.load, infoBlockItems?.error, employees?.load, items?.load]);

    const syncInfoBlockScroll = useCallback(() => {
        const scrollLeft = employeeTable?.state.scrollLeft;
        lastScrollLeft.current = scrollLeft || 0;
        infoBlockTable?.scrollTo({ scrollLeft });
    }, [infoBlockTable, employeeTable]);

    const syncEmployeeTableScroll = useCallback(() => {
        const scrollLeft = infoBlockTable?.state.scrollLeft;
        lastScrollLeft.current = scrollLeft || 0;
        employeeTable?.scrollTo({ scrollLeft });
    }, [employeeTable, employeeTable]);

    useEffect(() => {

        if(infoBlockItems?.load && employees?.load && items?.load) {
            syncEmployeeTableScroll();
        }

    }, [infoBlockItems?.load, employees?.load, items?.load, syncEmployeeTableScroll]);

    return {
        syncInfoBlockScroll,
        syncEmployeeTableScroll,
        onInfoBlockScroll,
        onEmployeeTableScroll
    }
}