import { Grid } from "antd-virtual-table";
import moment from "moment";
import { IEmployeeScheduleDay, IScheduleBoardEmployee, ScheduleBoardEmployeeIconType } from "../../../Entities/Database";
import { isString } from "../../../System/Utils";
import { Direction, getFirstItemElement, getItemElement, getItemElementByDirection, getItemElementPosition, getItemInfoByDirection, getItemInfoFromElement, getItemKey, getLastItemElement, IItemElementPosition, parseItemKey, recordItemComparer } from "../helpers";
import { StopOutlined, UserOutlined } from "@ant-design/icons";

export const virtualGridItemClassName = 'virtial-grid-item';
export const virtualGridItemSelector = `.${virtualGridItemClassName}`;
export const virtualGridRowIndexAttributeName = "data-row-index";
export const virtualGridColumnIndexAttributeName = "data-column-index";
export const virtualGridOriginalColumnIndexAttributeName = "data-original-column-index";

export interface INextItem<T> extends IItemElementPosition {
    nextItem: T
}

export function fakeTabDownEvent(
    event: KeyboardEvent,
    fakePrev?: HTMLElement | null,
    fakeNext?: HTMLElement | null
) {
    
    if(event.shiftKey) {
        fakePrev?.focus();
    }
    else {
        fakeNext?.focus();
    }
}

export function handleTabDownEvent(
    event: KeyboardEvent,
    items: Readonly<IEmployeeScheduleDay[]>,
    itemOrKey: string | IEmployeeScheduleDay,
    gridElement: HTMLElement
): INextItem<IEmployeeScheduleDay> | IItemElementPosition | undefined {

    if(event.code === 'Tab') {

        const direction = event.shiftKey ? 'left' : 'right';

        let nextItem: IEmployeeScheduleDay | undefined;
        let positionInfo: IItemElementPosition;
        let nextItemElement = getItemElementByDirection(itemOrKey, direction, gridElement);
        
        // TODO проблема с фиксированными столбцами
        // Сбрасываем элемент
        if(event.shiftKey && nextItemElement) {

            positionInfo = getItemElementPosition(nextItemElement);

            if(positionInfo.columnIndex === 0) {

                nextItemElement = undefined;
            }
        }

        // Если следующий элемент отсутствует, берем нижний и ищем первый элемент фильтруя его по дате
        // Если зажат shift то ищим последний элемент фильтруя его по дате
        if(!nextItemElement) {

            const prolongDirection = event.shiftKey ? 'up' : 'down';
            nextItemElement = getItemElementByDirection(itemOrKey, prolongDirection, gridElement);

            if(!nextItemElement) {
                return undefined;
            }
            
            const nextItemInfo = getItemInfoFromElement(nextItemElement);

            if(!nextItemInfo) {
                return undefined;
            }

            const dates = items.reduce((arr, item) => {

                if(item.PID === nextItemInfo.PID) {
                    arr.push(+item.date);
                }

                return arr;

            }, [] as number[]);

            const currentDate = event.shiftKey
                ? Math.max(...dates)
                : Math.min(...dates);
            
            const newNextItemInfo: typeof nextItemInfo = {
                ...nextItemInfo,
                date: moment(currentDate)
            }

            nextItem = items.filter(x => recordItemComparer(x, newNextItemInfo))[0];
            positionInfo = getItemElementPosition(nextItemElement);
            positionInfo.columnIndex = event.shiftKey ? dates.length : 0;
        }
        else {

            const nextItemInfo = getItemInfoFromElement(nextItemElement);

            if (!nextItemInfo) {
                return undefined;
            }

            nextItem = items.filter(x => recordItemComparer(x, nextItemInfo))[0];
            positionInfo = getItemElementPosition(nextItemElement);
        }

        if(nextItem) {

            return {
                ...positionInfo,
                nextItem
            }
        }
    }

    return undefined;
}

export function handleHomeOrEndDownEvent(
    event: KeyboardEvent,
    items: Readonly<IEmployeeScheduleDay[]>,
    itemOrKey: string | IEmployeeScheduleDay,
    gridElement: HTMLElement
): INextItem<IEmployeeScheduleDay> | undefined {

    if(event.code === 'Home' || event.code === 'End') {
        
        const itemBase  = isString(itemOrKey)
                        ? parseItemKey(itemOrKey)
                        : itemOrKey;

        if(itemBase) {

            const itemElement  = getItemElement(itemBase, gridElement);

            if(itemElement) {

                const dates = items.reduce((arr, item) => {

                    if(item.PID === itemBase.PID) {
                        arr.push(+item.date);
                    }

                    return arr;

                }, [] as number[]);

                const home = event.code === 'Home';
                const target = home
                            ? Math.min(...dates)
                            : Math.max(...dates);

                const nextItemInfo: typeof itemBase = {
                    ...itemBase,
                    date: moment(target)
                }
                
                const nextItem = items.filter(x => recordItemComparer(x, nextItemInfo))[0];

                if(nextItem) {
                    
                    const positionInfo = getItemElementPosition(itemElement);
                    positionInfo.columnIndex = home ? 0 : dates.length;

                    return {
                        ...positionInfo,
                        nextItem
                    }
                }
            }
        }
    }

    return undefined;
}

export function handleArrowDownEvent(
    event: KeyboardEvent,
    items: Readonly<IEmployeeScheduleDay[]>,
    itemOrKey: string | IEmployeeScheduleDay,
    gridElement: HTMLElement
): INextItem<IEmployeeScheduleDay> | undefined {
    
    if (!event.code.startsWith('Arrow')) {
        return undefined;
    }

    const direction = event.code.substring(5).toLocaleLowerCase() as Direction;
    const nextItemElement = getItemElementByDirection(itemOrKey, direction, gridElement);

    if (nextItemElement) {

        const nextItemInfo = getItemInfoFromElement(nextItemElement);
        
        if (nextItemInfo) {

            const positionInfo = getItemElementPosition(nextItemElement);
            const nextItem = items.filter(x => recordItemComparer(x, nextItemInfo))[0];

            if(nextItem) {
                
                return {
                    ...positionInfo,
                    nextItem
                }
            }
        }
    }

    return undefined;
}

export function handleNavigationKeyDown(
    event: KeyboardEvent,
    items: Readonly<IEmployeeScheduleDay[]>,
    itemOrKey: string | IEmployeeScheduleDay,
    gridElement: HTMLElement
): INextItem<IEmployeeScheduleDay> | IItemElementPosition | undefined {

    if (event.code.startsWith('Arrow')) {
        return handleArrowDownEvent(event, items, itemOrKey, gridElement);
    }

    if(event.code === 'Home' || event.code === 'End') {
        return handleHomeOrEndDownEvent(event, items, itemOrKey, gridElement);
    }

    if (event.code === 'Tab') {
        return handleTabDownEvent(event, items, itemOrKey, gridElement);
    }

    return undefined;
}

export function calculateFocusOnTabDown(
    event: KeyboardEvent,
    items: Readonly<IEmployeeScheduleDay[]>,
    gridElement: HTMLElement
): INextItem<IEmployeeScheduleDay> | undefined {

    // Элемент словил фокус
    if(event.code === 'Tab') {

        const focusItem = event.shiftKey
            ? getLastItemElement(gridElement)
            : getFirstItemElement(gridElement);

        if (focusItem) {

            const nextItemInfo = getItemInfoFromElement(focusItem);

            if (nextItemInfo) {
        
                const positionInfo = getItemElementPosition(focusItem);
                const nextItem = items.filter(x => recordItemComparer(x, nextItemInfo))[0];

                if(nextItem) {

                    return {
                        ...positionInfo,
                        nextItem
                    }
                }
            }
        }
    }

    return undefined;
}

export function calculateFocusOnHomeDown(
    event: KeyboardEvent,
    items: Readonly<IEmployeeScheduleDay[]>,
    gridElement: HTMLElement
): INextItem<IEmployeeScheduleDay> | undefined {

    return undefined;
}

export function calculateFocusOnEndDown(
    event: KeyboardEvent,
    items: Readonly<IEmployeeScheduleDay[]>,
    gridElement: HTMLElement
): INextItem<IEmployeeScheduleDay> | undefined {

    return undefined;
}

export const handleSelectOnKeyDown = <TDataSourceItem extends Record<any, any> = any>(
    grid: NonNullable<Grid<TDataSourceItem>>,
    gridElement: HTMLElement,
    fakePrevHelper: HTMLElement | null,
    fakeNextHelper: HTMLElement | null,
    normalizeItems: Readonly<IEmployeeScheduleDay[]>,
    selectedItemsOrKeys: Readonly<string[]> | Readonly<IEmployeeScheduleDay[]>,
    event: KeyboardEvent,
) => {

    if(selectedItemsOrKeys.length > 0) {

        const itemOrKey = selectedItemsOrKeys[0];
        const handleEventResult = handleNavigationKeyDown(event, normalizeItems, itemOrKey, gridElement);

        if (handleEventResult) {

            const { rowIndex, columnIndex } = handleEventResult;

            grid.scrollToItem({
                align: 'smart',
                rowIndex: rowIndex,
                columnIndex: columnIndex,
            });

            const maybeNextItem = (handleEventResult as INextItem<any>).nextItem;
            if (maybeNextItem) {
                return [getItemKey(maybeNextItem)];
            }

            return [];
        }

        // Скорее всего список закончился
        // Делаем переход на следующий элемент
        if(event.code === 'Tab') {

            fakeTabDownEvent(event, fakePrevHelper, fakeNextHelper);
            return [];
        }
    }
    else if(selectedItemsOrKeys.length === 0) {

        const handleEventResult = calculateFocusOnTabDown(event, normalizeItems, gridElement);

        if(handleEventResult) {

            const { rowIndex, columnIndex, nextItem } = handleEventResult;

            grid.scrollToItem({
                align: 'smart',
                rowIndex: rowIndex,
                columnIndex: columnIndex,
            });
    
            return [getItemKey(nextItem)];
        }
    }
}

export function getEmployeeIcon(employee: IScheduleBoardEmployee) {

    switch (employee.iconType) {
        
        case ScheduleBoardEmployeeIconType.Fired: return <StopOutlined />;
    }

    return <UserOutlined />;
}