import { getOrCreateAbortError } from "../System/Utils";

// From https://github.com/sindresorhus/random-int/blob/c37741b56f76b9160b0b63dae4e9c64875128146/index.js#L13-L15
export const randomInteger = (minimum: number, maximum: number) => Math.floor((Math.random() * (maximum - minimum + 1)) + minimum);

export interface IClearablePromise<T> extends Promise<T> {
    /**
    Clears the delay and settles the promise.
    */
    clear?: () => void;
}

export interface IOptions {
    /**
    An optional AbortSignal to abort the delay.
    If aborted, the Promise will be rejected with an AbortError.
    */
    signal?: AbortSignal;
}

export interface IOptionsWithValue<T> extends IOptions {
    /**
    Value to resolve in the returned promise.
    */
    value: T;
}

export interface IClearAndSet {
    clearTimeout: (timeoutId: any) => void;
    setTimeout: (callback: (...args: any[]) => void, milliseconds: number, ...args: any[]) => unknown;
}

export interface IDelayProps {
    defaultClear?: typeof clearTimeout,
    set?: typeof setTimeout,
    willResolve: boolean
}

export type Delay = {
	
    /**
	 Create a promise which resolves after the specified `milliseconds`.
	 @param milliseconds - Milliseconds to delay the promise.
	 @returns A promise which resolves after the specified `milliseconds`.
	 */
    (milliseconds: number, options?: IOptions): IClearablePromise<void>;

	/**
	Create a promise which resolves after the specified `milliseconds`.
	@param milliseconds - Milliseconds to delay the promise.
	@returns A promise which resolves after the specified `milliseconds`.
	*/
	<T>(milliseconds: number, options?: IOptionsWithValue<T>): IClearablePromise<T>;

	/**
	Create a promise which resolves after a random amount of milliseconds between `minimum` and `maximum` has passed.
	Useful for tests and web scraping since they can have unpredictable performance. For example, if you have a test that asserts a method should not take longer than a certain amount of time, and then run it on a CI, it could take longer. So with `.range()`, you could give it a threshold instead.
	@param minimum - Minimum amount of milliseconds to delay the promise.
	@param maximum - Maximum amount of milliseconds to delay the promise.
	@returns A promise which resolves after a random amount of milliseconds between `maximum` and `maximum` has passed.
	*/
	range<T>(minimum: number, maximum: number, options?: IOptionsWithValue<T>): IClearablePromise<T>;

	// TODO: Allow providing value type after https://github.com/Microsoft/TypeScript/issues/5413 is resolved.
	/**
	Create a promise which rejects after the specified `milliseconds`.
	@param milliseconds - Milliseconds to delay the promise.
	@returns A promise which rejects after the specified `milliseconds`.
	*/
	reject(milliseconds: number, options?: IOptionsWithValue<unknown | undefined>): IClearablePromise<never>;
};

export type DelayWithTimes = Delay & {
	// The types are intentionally loose to make it work with both Node.js and browser versions of these methods.
	createWithTimers(timers: {
		clearTimeout: (timeoutId: any) => void;
		setTimeout: (callback: (...args: any[]) => void, milliseconds: number, ...args: any[]) => unknown;
	}): Delay;
};

const createDelay = <T>({defaultClear, set, willResolve}: IDelayProps) => (ms: number, options?: IOptions | IOptionsWithValue<T>): IClearablePromise<T> => {

	const signal = options?.signal;
	const value  = (options as IOptionsWithValue<T>)?.value;

	if (signal && signal.aborted) {
		return Promise.reject<T>(getOrCreateAbortError(signal));
	}

	let timeoutId: number | undefined;
	let settle: Function;
	let rejectFn: Function;
	const clear = defaultClear || clearTimeout;

	const signalListener = (ev: Event) => {
		clear(timeoutId);
		rejectFn(getOrCreateAbortError(ev));
	};

	const cleanup = () => {
		if (signal) {
			signal.removeEventListener('abort', signalListener);
		}
	};
	
	if (signal) {
		signal.addEventListener('abort', signalListener, {once: true});
	}

	const delayPromise: IClearablePromise<T> = new Promise<T>((resolve, reject) => {

		settle = () => {

			cleanup();

			if (willResolve) {
				resolve(value);
			} else {
				reject(value);
			}
		};

		rejectFn = reject;
		timeoutId = (set || setTimeout)(settle, ms);
	});

	delayPromise.clear = () => {
		clear(timeoutId);
		timeoutId = void 0;
		settle();
	};

	return delayPromise;
};

const createWithTimers = (clearAndSet?: IClearAndSet) => {
	const delay: any = createDelay({...clearAndSet, willResolve: true});
	delay.reject = createDelay({...clearAndSet, willResolve: false});
	delay.range = <T = unknown>(minimum: number, maximum: number, options: IOptionsWithValue<T> ) => delay(randomInteger(minimum, maximum), options);
	return delay as Delay;
};

const _delay: any = createWithTimers();
      _delay.createWithTimers = createWithTimers;

export const delay: Delay = _delay;
export const delayWithTimers: DelayWithTimes = _delay;

export default delay;