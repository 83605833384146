import { SendOutlined } from "@ant-design/icons";
import { Button, Card, CardProps, Col, ConfigProvider, Empty, Form, Input, message, Row, Skeleton, Space } from "antd";
import { useEffect, useRef, useContext, useCallback, useState } from "react";
import { getScheduleItemCommentList, IEmployeeScheduleDay, IComment, addComment, IHasEmployeeDayKey } from "../../Entities/Database";
import { ILoaderInitProps, useListLoader } from "../../Hooks/useListLoader";
import { initialLoadListState } from "../../Reducer/LoadListReducer";
import { nameof } from "../../System/Utils";
import { commentsReducer, ICommentsReducer } from "./comments-reducer";
import useAuth from "../../Hooks/useAuth";

import './comments.css';

export interface ICommentProps extends CardProps {
    comment: IComment,
    loading: boolean
}

export function Comment({
    comment,
    loading,
    ...cardProps
}: ICommentProps) {

    return (
        <Card
            {...cardProps}
            loading={loading}
            tabIndex={0}
        >
            <Card.Meta
                title={(
                    <Row gutter={[16, 16]}>
                        <Col
                            span={16}
                            title={comment.author}
                            style={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                            }}
                        >
                            <span style={{fontSize: 12}}>
                                {comment.author}
                            </span>
                        </Col>
                        <Col
                            span={8}
                            style={{textAlign: 'end'}}
                        >
                            <span style={{fontSize: 12}}>{comment.date.local(true).format("DD.MM.YY HH:mm")}</span>
                        </Col>
                    </Row>
                )}
                description={comment.text}
            />
        </Card>
    );
}

const initUseListReducer: ILoaderInitProps<IComment, ICommentsReducer> = {
    reducer: commentsReducer,
    initialState: {
        ...initialLoadListState,
        loading: true
    }
}

export interface IAddCommentFormValues {
    text: string
}

export interface ICommentsProps {
    item: IHasEmployeeDayKey,
    onChange?: (item: IHasEmployeeDayKey) => void,
}

export function Comments({
    item,
    onChange
}: ICommentsProps) {

    const didMount = useRef(false);
    const auth = useAuth();
    const commentListWrapperRef = useRef<HTMLDivElement>(null);
    const [addCommentForm] = Form.useForm<IAddCommentFormValues>();
    const [comments, reloadComments, , , commentsDispatch] = useListLoader(
        signal => getScheduleItemCommentList(auth, item, signal),
        [auth.data?.staticId, item.PID, item.date],
        initUseListReducer
    );

    const {
        locale: contextLocale,
        renderEmpty,
    } = useContext(ConfigProvider.ConfigContext);
    
    const emptyText = renderEmpty?.('Table') || (
        <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
    );

    const currentCommentListRef = useRef(comments.items);
    currentCommentListRef.current = comments.items;

    const [sendingComment, setSendingComment] = useState(false);

    const handleFinish = useCallback(async (values: IAddCommentFormValues) => {

        if(values.text === undefined
        || values.text.length < 1) {
            return;
        }

        setSendingComment(true);

        try {
            const newComment = await addComment(auth, item, values.text);

            if(!didMount.current) {
                return;
            }
            
            commentsDispatch({
                type: 'ADD_COMMENT',
                payload: newComment
            });

            addCommentForm.resetFields();

            if(onChange) {
                onChange(item);
            }
        }
        catch (ex) {

            if(!didMount.current) {
                return;
            }

            message.error("Не удалось добавить комментарий");
            console.error(ex);
        }
        finally {

            if(!didMount.current) {
                return;
            }

            setSendingComment(false); 
        }

    }, [addCommentForm,
        onChange]);

    const handlePressEnter = useCallback((e: React.KeyboardEvent<HTMLTextAreaElement>) => {

        if(e.code === 'Enter' && e.shiftKey == false) {
            e.preventDefault();
            addCommentForm.submit();
            return false;
        }

    }, [addCommentForm]);

    useEffect(
        reloadComments,
        [auth.data?.staticId, item.PID, item.date]
    );

    useEffect(() => {
        commentListWrapperRef.current?.scrollTo({
            top: 0
        });
    }, [comments.items, comments.load]);

    useEffect(() => {
        didMount.current = true;
        return () => {
            didMount.current = false;
        }
    }, []);

    return (
        <>
            <div
                ref={commentListWrapperRef}
                className="comment-list-wrapper"
            >
                <div className="comment-list">
                    {!comments.load &&
                    <>
                        <Skeleton active={comments.loading} />
                        <Skeleton active={comments.loading} />
                        <Skeleton active={comments.loading} />
                    </>}
                    {comments.load && comments.items.length === 0 && emptyText}
                    {comments.load && comments.items
                    .map((comment, index) => (
                        <Comment
                            key={comment.id}
                            className="comment-item"
                            comment={comment}
                            loading={comments.loading}
                            size='small'
                        />
                    ))}
                </div>
            </div>
            <Form<IAddCommentFormValues>
                form={addCommentForm}
                layout="inline"
                className="add-comment-form"
                onFinish={handleFinish}
            >
                <Space
                    className="add-comment-form-space"
                    direction="horizontal"
                    align="end"
                    style={{
                        width: "100%",
                        justifyContent: "start"
                    }}
                >
                    <Form.Item
                        name={nameof<IAddCommentFormValues>('text')}
                        style={{
                            width: "100%",
                        }}
                    >
                        <Input.TextArea
                            placeholder="Ваш комментарий"
                            autoSize={{ minRows: 1, maxRows: 5 }}
                            style={{ resize: "none" }}
                            maxLength={4000}
                            onPressEnter={handlePressEnter}
                            disabled={sendingComment}
                        />
                    </Form.Item>
                    <Form.Item
                        className="add-comment-form-submit"
                    >
                        <Button
                            htmlType="submit"
                            type="text"
                            icon={<SendOutlined />}
                            loading={sendingComment}
                        />
                    </Form.Item>
                </Space>
            </Form>
        </>
    )
}