import moment from "moment";
import { IChartDataItem } from "../../Entities/Database";
import { groupBy, isNullOrUndefined } from "../../System/Utils";

export interface IGraphItemValue {
    dateTitle: string,
    date: number,
    value: number
}

export interface IGraphDataSource {
    [graph: string]: IGraphItemValue[],
}

export interface IItem {

    [source: string]: number,

    // @ts-ignore
    date: number,
    // @ts-ignore
    dateVal: moment.Moment,
}

export function embedChartItems(
    forcast: Readonly<IChartDataItem[]>,
    min: moment.Moment,
    max: moment.Moment,
    step: number = 15
): IChartDataItem[] {

    const entities = new Map<IChartDataItem['col'], moment.Moment>();
    const groups   = new Set<IChartDataItem['type']>();

    for (const item of forcast) {
        groups.add(item.type);
        entities.set(item.col, moment(item.col));
    }

    const sortTypedForcast = Array.from(entities.values()).sort((x, y) => y.valueOf() - x.valueOf());

    if (sortTypedForcast.length > 1) {
        const diff = sortTypedForcast[0].diff(sortTypedForcast[1], 'minutes');
        step = diff;
    }
    
    const minDt    = min.clone().startOf('day');
    const maybeMax = max.clone().startOf('day');
    const maxDt    = max.isSame(maybeMax)
        ? maybeMax
        : maybeMax.add(1, 'day');
    
    const curr = minDt.clone();
    const resultForcast: IChartDataItem[] = [];

    while (curr <= maxDt) {
        
        for (const group of groups) {
            
            const col  = curr.format("YYYY-MM-DDTHH:mm:ss");
            const type = group;

            const forcastItem = forcast.find(x => x.col === col && x.type === type);

            resultForcast.push({
                type: type,
                col: col,
                val: forcastItem?.val ?? 0,
            });
        }

        curr.add(step, 'minutes');
    }

    return resultForcast;
}

export function normalizeChartItems(items: Readonly<IChartDataItem[]>): [IChartDataItem['type'][], IItem[]] {

    const groupByDate = groupBy(items, (item) => item.col);
    const source = new Map<IChartDataItem['col'], IItem>();
    const lines  = new Set<IChartDataItem['type']>();

    for (const [key, group] of Object.entries(groupByDate)) {

        const date = moment(key);
        
        for (let item of group) {

            if(isNullOrUndefined(item.val)) {
                continue;
            }

            const tmpItem: IItem = source.get(key) ?? {
                dateVal: date,
                date: date.valueOf(),
            } as IItem;

            tmpItem[item.type] = item.val;

            source.set(key, tmpItem);
            lines.add(item.type);
        }
    }

    const keys = Array.from(lines);
    const list = Array.from(source.values()).sort((x, y) => x.dateVal.valueOf() - y.dateVal.valueOf());

    return [keys, list];
}