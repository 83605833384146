import { AuthForm } from "../AuthForm";
import { Logo } from "../Logo";
import { Footer } from "../Footer";
import "./style.css";

export function AuthWrap() {
    return(
        <div className="auth__wrap">
            <Logo /> 
            <AuthForm />   
            <Footer />         
        </div>
    )
}