export interface IMainBoardLocale {
    confirmTitle: string,
    exitText: string,
    cancelText: string,
}

export interface IMenuItemsLocale {
    schedule: string, 
    vacation: string,
    prediction: string,
    monitoring: string,
    shiftChange: string,
    appeals: string,
    reports: string,
    references: string,
}

export const mainBoardLocaleRu: IMainBoardLocale = {
    confirmTitle: 'Вы уверены, что хотите выйти?',
    exitText: 'Выход',
    cancelText: 'Отмена',

}

export const menuItemsLocaleRu = {
    schedule: 'Расписание',
    vacation: 'Отпуска', 
    prediction: 'Прогнозирование',
    monitoring: 'Мониторинг',
    shiftChange: 'Биржа смен',
    appeals: 'Заявки',
    reports: 'Отчетность',
    references: 'Справочники'
}
