import { Alert, Button, Space } from "antd";
import { getNotificationType, isScheduleEmployeeDayEvent, parseNotificationData } from "../helpers";
import { INotification, NotificationStatus } from "../../../Entities/BackendAPIHub";
import { useEffect, useLayoutEffect, useMemo } from "react";
import { CheckOutlined, EyeOutlined } from "@ant-design/icons";
import { useAPIHub } from "../../../Hooks/useAPIHub";
import "./style.css";
import { useNotificactionHub } from "../../../Hooks/useNotificactionHub";

export interface INotificationItemProps {
    notification: INotification,
    onChangeStatus?: (x: number) => void,
}

export function NotificationItem({
    notification
}: INotificationItemProps) {

    const notificationData = useMemo(() => parseNotificationData(notification), [notification.data]);
    const apiHub = useNotificactionHub();
    const onReadClick = () => {
        apiHub.fire('readNotification', notification, new Date);
    }

    const onViewClick = () => {
        if (isScheduleEmployeeDayEvent(notificationData)) {
            apiHub.fire('openIntervalActivityEditor', notificationData.PID, notificationData.date);
        }
    }

    useEffect(() => {

        if (notification.status === NotificationStatus.New)  {
            apiHub.fire('displayedNotification', notification, new Date);
        }

    }, []);

    return (
        <Alert 
            className="notificationItem"
            message={notification.body}
            type={getNotificationType(notification)}
            action={
                notification.status !== NotificationStatus.Read && 
                <Space>
                    {/*<Button 
                        size="small" 
                        type="default"
                        icon={<EyeOutlined />}
                        onClick={onViewClick}
                        title="Посметреть расписание"
                    />*/}
                    <Button 
                        size="small" 
                        type="default"
                        icon={<CheckOutlined />}
                        onClick={onReadClick}
                        title="Отметить как прочитано"
                    />
                </Space>
            }
            closable={false}
        />
    )
}