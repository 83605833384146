export interface DetectedInfo<T> {
    hasValue: boolean,
    paramIndex: number,
    value: T | null
}

export interface DetectedInfoHasValue<T> extends DetectedInfo<T> {
    value: T
}

/**
 * Определяет, имеет аргумент значение
 * @param value -> Определитель значения
 * @returns 
 */
export function HasValue<T>(value: DetectedInfo<T>): value is DetectedInfoHasValue<T> {
    return value.hasValue;
}