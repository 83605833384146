export interface ICredential {
    userName: string,
    password: string,
    remember: boolean,
}

export interface ICredentialHash {
    userName: string,
    hash: string,
    remember: boolean,
}

export function isCredential(x: any): x is ICredential {
    return x
        && typeof (x as ICredential).userName === 'string'
        && typeof (x as ICredential).password === 'string';
}

export function isCredentialHash(x: any): x is ICredentialHash {
    return x
    && typeof (x as ICredentialHash).userName === 'string'
    && typeof (x as ICredentialHash).hash     === 'string';
}