import moment from "moment";
import { NotSupportedException } from "../System/NotSupportedException";
import { IOriginalActivity, ActivityPointType, ActivityType } from "./Database";
import { IDate } from "../Components/shared/lib/src/core/util/datetime";

/**
 * Обязательные поля, заявленые в активностях
 * 
 * id отвечает за проверку существования originalActivity в базе
 */
export interface RequiredActivityData extends Omit<Partial<IOriginalActivity>, "id"> {
    id?: undefined, // Hack id must be always undefined
    typeId: ActivityType,
    pointTypeId: ActivityPointType
}

export interface IScheduleEmployeeActivity {
    originalActivity: IOriginalActivity | RequiredActivityData,
    id: string,
    title: string,
    resource: string,
    start: Date,
    end: Date,
    color: string | undefined,
    
    // False = Полный запрет на редактирование (перемещение, растягивание, удаление)
    editable: boolean
}

function isMbsc(date: any): date is IDate {
    return date._mbsc;
}

export function normalizeDate(date: Date | string | object | number | IDate | undefined): Date {
    
    if (date === undefined) {
        return new Date();
    }

    if(date instanceof Date) {
        return date;
    }

    if(isMbsc(date)) {
        return new Date(date.toISOString());
        //throw new NotSupportedException("Date type not support");
    }

    const normalizeDate = moment(date);

    if(!normalizeDate.isValid()) {
        throw new NotSupportedException("Date type not support");
    }

    return normalizeDate.toDate();
}