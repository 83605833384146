
export interface IScheduleShiftModalTitlesLangRu {
    modalTitle: string,
    scheduleShiftTitle: string,
    rotationType: string,
    timezone: string,
    projectGroup: string,
}

export interface IScheduleShiftModalCopyTextLangRu {
    text: string,
}

export interface IScheduleShiftModalErrorTextLangRu {
    title: string, 
    projectGroup: string,
    timezone: string,
}

export const scheduleShiftModalTitlesLangRu: IScheduleShiftModalTitlesLangRu = {
    modalTitle: "Создание шаблона",
    scheduleShiftTitle: "Название шаблона:",
    rotationType: "Тип ротации:",
    timezone: "Часовой пояс:",
    projectGroup: "Проектная группа:",
}

export const scheduleShiftModalCopyTextLangRu: IScheduleShiftModalCopyTextLangRu = {
    text: "Копирование не коснется связей шаблон-сотрудник. Все проектные активности будут изменены на стандартную.",
}

export const scheduleShiftModalErrorTextLangRu: IScheduleShiftModalErrorTextLangRu = {
    title: "Введите название шаблона",
    projectGroup: "Выберите проектную группу",
    timezone: "Выберите часовой пояс",
}