export enum CSharpType
{
    Null,
    Guid,
    Boolean,
    String,
    Int,
    Long,
    Float,
    Double,
    Decimal,
    DateTime,
    TimeSpan,
    DateTimeOffset ,
    DataTable
}