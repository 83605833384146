import { MbscCalendarColor, MbscCalendarEvent, MbscCalendarLabel, MbscResource } from "../shared/lib";
import { IOriginalActivity, IOriginalExtensionActivity, IScheduleBoardEmployee, IScheduleExtensionItem } from "../../Entities/Database";
import { IScheduleEmployeeActivity, RequiredActivityData } from "../../Entities/IScheduleEmployeeActivity";
import { colorToStyle, isNullOrUndefined, isUndefined } from "../../System/Utils";
import { IValidateProps } from "../shared/lib/src/core/util/datetime";
import { addDays, addMinMax, getDateOnly } from "../shared/libHelpers";

export type ResourceId = string | number | (string | number)[];

export interface IResource extends MbscResource {
}

export interface IEmployeeResource extends IResource {
    employee: IScheduleBoardEmployee;
    children?: IExtensionResource[];
}

export interface IExtensionResource extends IResource {
    employee: IScheduleBoardEmployee;
    extension: IScheduleExtensionItem,
}

export function isEmployeeResource(resource: IEmployeeResource | IExtensionResource): resource is IEmployeeResource {
    return isUndefined(resource.extension);
}

export function isExtensionResource(resource: IEmployeeResource | IExtensionResource): resource is IExtensionResource {
    return !isUndefined(resource.extension);
}

export function isExtensionAction(action: RequiredActivityData | IOriginalActivity | IOriginalExtensionActivity): action is IOriginalExtensionActivity {

    const extensionId = (action as IOriginalExtensionActivity).EmpTL_ID;
    const canNote     = (action as IOriginalExtensionActivity).canNote;

    if(isNullOrUndefined(extensionId) && isNullOrUndefined(canNote)) {
        return false;
    }

    return true;
}

export const extensionIdPrefix = 'ext_';

export function getActionResourceId(action: IOriginalActivity | IOriginalExtensionActivity) {

    const extensionId = (action as IOriginalExtensionActivity).EmpTL_ID;

    if(isNullOrUndefined(extensionId)) {
        return action.PID;
    }

    return `${extensionIdPrefix}${action.PID}_${extensionId}`;
}

export function getActionId(action: IOriginalActivity | IOriginalExtensionActivity) {

    const extensionId = (action as IOriginalExtensionActivity).EmpTL_ID;

    if(isNullOrUndefined(extensionId)) {
        return action.id;
    }

    // TODO Попросить Чауса формировать единый уникальный Id
    if(action.id === '0') {
        return `${extensionIdPrefix}${action.PID}_${extensionId}_${+action.start}_${+action.end}`
    }

    return `${extensionIdPrefix}${action.id}`;
}

export function getEmployeeResourceId(employee: IScheduleBoardEmployee) {
    return employee.PID;
}

export function getEmployeeExtensionResourceId(employee: IScheduleBoardEmployee, extension: IScheduleExtensionItem) {
    return `${extensionIdPrefix}${employee.PID}_${extension.id}`;
}

export function allowEditEvent(action: IOriginalActivity | RequiredActivityData) {
    return action.deletable && action.movable;
}

export function getEditableFlag(action: IOriginalActivity | RequiredActivityData) {
    return !(
        (!isNullOrUndefined(action.deletable) && !action.deletable) &&
        (!isNullOrUndefined(action.movable) && !action.movable) &&
        (!isNullOrUndefined(action.durnMin) && action.durnMin === action.durnMax)
    )
}

export function normalizeEvent(action: IOriginalActivity | IOriginalExtensionActivity): IScheduleEmployeeActivity {

    return {
        id: getActionId(action),
        title: action.caption,
        resource: getActionResourceId(action),
        start: action.start.toDate(),
        end: action.end.toDate(),
        color: colorToStyle(action.backgroundColor),
        originalActivity: action,
        editable: getEditableFlag(action)
    }
}

export type TMbscCalendarEvent = MbscCalendarEvent & {
    originalActivity?: IScheduleEmployeeActivity['originalActivity']
}

export type TValidateProps = IValidateProps & {
    resource?: string | number | (string | number)[]
}

export function getResource(id: TValidateProps['resource'], resources: IEmployeeResource[] | undefined) {

    if(id && resources) {

        for(const resource of resources) {

            if(resource.id === id) {

                return resource;
            }

            if(resource.children) {
                
                for (const extensionResource of resource.children) {

                    if (extensionResource.id === id) {

                        return extensionResource;
                    }
                }
            }
        }
    }

    return undefined;
}

export function getAllowExtensionSpaces(
    event: TMbscCalendarEvent,
    resources: IEmployeeResource[],
    globalMin?: Date,
    globalMax?: Date,
    color: string = '#1ad4041a'
): [TValidateProps[], MbscCalendarColor[]] {

    const resourceId = event.resource;
    const resource = getResource(resourceId, resources);

    if (!resource) {
        const all = resources.map(x => x.id);
        return [[{
            resource: all,
            recurring: {
                repeat: 'daily'
            },
        }], []];
    }

    const isExtensionRes = isExtensionResource(resource);
    const allowResource = isExtensionRes
                        ? resource.employee.PID
                        : resourceId;

    const normalizeMin = event.originalActivity?.minStart ? event.originalActivity.minStart.toDate() : globalMin;
    const normalizeMax = event.originalActivity?.maxEnd ? event.originalActivity.maxEnd.toDate() : globalMax;
    const invalidResources = resources.filter(x => x.id !== allowResource).map(x => x.id);
    const invalids: TValidateProps[] = [
        {
            resource: invalidResources,
            recurring: {
                repeat: 'daily'
            },
        }
    ];

    addMinMax(invalids, normalizeMin, normalizeMax);
    
    const colors: MbscCalendarColor[] = [{
        start: normalizeMin,
        end: normalizeMax,
        resource: [allowResource],
        background: color
    }];

    return [invalids, colors];
}