import moment from "moment-timezone";
import { LoadListReducerType } from "../Reducer/LoadListReducer";
import { execute, executeFirst, getXLSX } from "../Server/DbProvider";
import { IAuthData } from "../Server/IAuthData";
import { BoolQueryParameter, DateQueryParameter, DateTimeOffsetQueryParameter, DateTimeQueryParameter, IdArrayQueryParameter, IntArrayQueryParameter, IntQueryParameter, IQuery, IQueryParameter, JSONQueryParameter, NumQueryParameter, StringQueryParameter } from "../Server/Query";
import { Exception } from "../System/Exception";
import { InvalidCastException } from "../System/InvalidCastException";
import { isArray, isNullOrUndefined, NullOrUndefined, RangeValue } from "../System/Utils";
import { IAuthState } from "./IAuthProvider";
import { IScheduleEmployeeActivity } from "./IScheduleEmployeeActivity";
import { InvalidDataException } from "../System/InvalidDataException";
import { NotSupportedException } from "../System/NotSupportedException";
import { HHMMSS } from "./HHMMSS";
import { IActivitiesItem, ITagItem } from "./EmployeeCard";
import { FiveTime } from "../System/Type/Time";
import { INotification, INotificationBase } from "./BackendAPIHub";

export type { IActivitiesItem, ITagItem } from "./EmployeeCard";

export enum PersonFilterType {
    LinearPerson = 0,
    AdminPerson  = 1
}

export type IdType = string | number;

export enum DayType {
    Unknown = -1,
    Work,
    DayOff,
    SickLeave,
    //... dynamic value
}

export enum ActivityPointType {
    None = 0,
    SideJob = 1,
    ShiftShrink = 2,
}

export enum ActivityMarkerType {
    SideJob = 1,
    ShiftShrink = 2,
    Comment = 3
}

export interface IException {
    errorId: IdType,
    errorDesc: string,
}

export interface IPublishException extends IException {
    activityId: IdType,
}

export interface IDictionaryItem {
    id: IdType,
    title: string
}

export interface IWithEmployable {
    PID: string
}

export interface IEmployee extends IWithEmployable {
    surname: string,
    firstName: string,
    secondName: string,
}

export enum ScheduleBoardEmployeeIconType {
    Common = 1,
    Fired  = 2,
}

export interface IScheduleBoardEmployee extends IEmployee {
    valueTotal: number | null | undefined,
    valueTarget: number | null | undefined,
    roleId: ActivityRoleType,
    roleCaption: string,
    iconType: ScheduleBoardEmployeeIconType
}

export interface IProjectGroupShortInfo extends IDictionaryItem {}
export interface IFieldShortInfo extends IDictionaryItem {}
export interface ITagShortInfo extends IDictionaryItem {}
export interface IForcastShortInfo extends IDictionaryItem {}
export interface ISkillShortInfo extends IDictionaryItem {}
export interface IProductiveActivityTypeInfo extends IDictionaryItem {}

export enum ActivityType {
    New = Number.MIN_SAFE_INTEGER,
    WorkingTime = 0,
    NotWorkingTime = 1,
    //... dynamic value
}

export enum ActivityRoleType {
    //... dynamic value
}

export interface IHasColors {
    backgroundColor: number,
}

export interface IHasCaption {
    caption: string,
}

export interface IHasType {
    typeId: ActivityType,
}

export interface IHasRole {
    roleId: ActivityRoleType,
}

export interface IActivityBase extends IHasCaption, IHasColors, IHasType, IHasRole {
}

export interface ISchedulePoint {
    pointTypeId: ActivityPointType,
}

export interface IActivityRangeDb {
    minStart?: string,
    maxEnd?: string,
}

export interface IActivityRange extends Omit<IActivityRangeDb, 'minStart' | 'maxEnd'> {
    minStart?: moment.Moment,
    maxEnd?: moment.Moment
}

export interface IActivityBoundDb {
    start: string,
    end: string,
}

export interface IActivityBound extends Omit<IActivityBoundDb, 'start' | 'end'> {
    start: moment.Moment,
    end: moment.Moment
}

export interface IHasGroup {
    groupId: IdType,
    groupTitle: string
}

export interface ISimplifiedActivityDb extends IWithEmployable, IActivityBoundDb, IActivityRangeDb, IHasType, IHasCaption {
    id: IdType,
    backgroundColor: number | null,
    markColor: number | null,
}

export interface ISimplifiedActivity extends Omit<ISimplifiedActivityDb, 'start' | 'end' | 'minStart' | 'maxEnd'>, IActivityRange, IActivityBound {
}

export interface ISimplifiedActivityWithTotal extends Omit<ISimplifiedActivityDb, 'start' | 'end' | 'minStart' | 'maxEnd'>, IActivityRange, IActivityBound {
    comment: string | null,
    spt: HHMMSS,
    wt: HHMMSS,
    factBreak: HHMMSS,
    normBreak: HHMMSS,
    overBreak: HHMMSS
}

export interface IGroupedSimplifiedActivityDb extends ISimplifiedActivityDb, IHasGroup {
}

export interface IGroupedSimplifiedActivity extends Omit<IGroupedSimplifiedActivityDb, 'start' | 'end' | 'minStart' | 'maxEnd'>, IActivityRange, IActivityBound {
}

export interface IOriginalActivityDb extends IActivityBase, IActivityBoundDb, IActivityRangeDb, ISchedulePoint, IWithEmployable {
    id: string,
    shId: string, // TODO: ID сигмента смены
    notProductive: boolean,
    movable: boolean,
    deletable: boolean,
    //resizable: boolean, = durnMin <-> durnMax
    durnMin: number,
    durnMax: number,
}

export interface IOriginalActivity extends Omit<IOriginalActivityDb, 'start' | 'end' | 'minStart' | 'maxEnd'>, IActivityRange, IActivityBound {
}

export interface IOriginalExtensionActivityDb extends IOriginalActivityDb {
    EmpTL_ID: IdType,
    canNote: boolean,
}

export interface IOriginalExtensionActivity extends Omit<IOriginalExtensionActivityDb, 'start' | 'end' | 'minStart' | 'maxEnd'>, IOriginalActivity {

}

export interface IHasEmployeeDayKeyDb extends IWithEmployable {
    date: string
}

export interface IHasRequestDay extends IWithEmployable {
    requestId?: number,
}

export interface IHasEmployeeDayKey extends Omit<IHasEmployeeDayKeyDb, 'date'> {
    date: moment.Moment
}

export interface IEmployeeScheduleDayDb extends IHasRequestDay, ISchedulePoint, IHasEmployeeDayKeyDb {
    typeId: DayType,
    contextMenuAvailable: boolean,
    caption1: string,
    caption2: string,
    backgroundColor: number,
    textColor: number,
    commentQuantity: number,
    tagQuantity: number,
    ticketQuantity: number,
    target: number | null,
    total: number | null,
}

export interface IEmployeeScheduleDay extends Omit<IEmployeeScheduleDayDb, 'date'>, IHasRequestDay, IHasEmployeeDayKey {

}

export interface IScheduleExtensionItem {
    id: IdType,
    title: string,
    readonly: boolean,
}

export type ScheduleEmployeeTimelineListReducerType = LoadListReducerType<IScheduleExtensionItem>;
export type ScheduleEmployeeActivityBaseReducerType = LoadListReducerType<IActivityBase>;
export type ScheduleEmployeeActivityReducerType = LoadListReducerType<IOriginalActivity>;
export type ScheduleEmployeeExtensionActivityReducerType = LoadListReducerType<IOriginalExtensionActivity>;
export type ProjectGroupListReducerType = LoadListReducerType<IProjectGroupShortInfo>;
export type FieldListReducerType = LoadListReducerType<IFieldShortInfo>;
export type TagListReducerType = LoadListReducerType<ITagShortInfo>;
export type ForcastObjectListReducerType = LoadListReducerType<IForcastShortInfo>;
export type SkillListReducerType = LoadListReducerType<ISkillShortInfo>;
export type TimeZoneListReducerType = LoadListReducerType<ITimeZone>;
export type ScheduleItemsMarkersReducerType = LoadListReducerType<IEmployeeScheduleDayMarker>;

export enum RecordType {
    InfoBlock = 1,
    Employee = 2,
    Schedule = 3,
    OCC = 4,
    UTZ = 5,
    UTZR = 6,
    'Прогноз звонков' = 7,
    'Часов требуется' = 8,
    'Часов дефицит' = 9,
    AHT = 10,
    SL = 11,
    AR = 12,

    'Интервальный график' = 103,
    IntervalСhart = 103,

    
    IntervalChartNeedAssigned15Min = 104,
    IntervalChartNeedAssigned60Min = 105,

    IntervalTableNeedAssigned15Min = 107,
    IntervalTableNeedAssigned60Min = 108,

    ScheduleMarkers = 106,

    //EventList = 1031,
    //WorkTime = 1032,
    TimelineEmployeeExtension = 1030,

    IntervalTableNeedAssigned15MinV2 = 1070,
    IntervalTableNeedAssigned60MinV2 = 1080,
}

export enum ActivityMarker {
    
}

export interface IDaysInfoblockItem {
    [day: string]: number | boolean | string | null | undefined,
    recordType: RecordType,
    disabled: boolean,
    paramName: string,
}

export enum IntervalInfoblockRecordType {
    // dynamic value
}

export interface IIntervalInfoblockItem {
    [date: string]: number | boolean | string | null | undefined,
    typeId: IntervalInfoblockRecordType
}

export interface IIntervalInfoblockTableRecord {
    [time: string]: number | boolean | string | null | undefined,
    id: IntervalInfoblockRecordType,
    disabled: boolean,
    paramName: string
}

export interface IIntervalInfoblockTableRecordV2Db {
    paramId: IntervalInfoblockRecordType,
    paramName: string,
    dt: string,
    val: string | number,
    disabled: boolean,
}

export interface IIntervalInfoblockTableRecordV2 extends Omit<IIntervalInfoblockTableRecordV2Db, 'dt'> {
    sort: number,
    paramId: IntervalInfoblockRecordType,
    paramName: string,
    dt: moment.Moment,
    val: string | number,
    disabled: boolean,
}

export interface IScheduleBoardFilterDb {
    from: string | null,
    to: string | null,
    projectGroupIds: IdType[],
    fieldIds: IdType[],
    personType: PersonFilterType,
    tagIds: IdType[],
    forcastObjIds: IdType[],
    prodActIds: IdType[],
    skillIds: IdType[],
    crossEmploee: boolean,
    timeZone: string,
    lang: string
}

export interface IScheduleBoardFilter extends Omit<IScheduleBoardFilterDb, "from" | "to"> {
    from: moment.Moment | null,
    to: moment.Moment | null
}

export interface IScheduleActivityToDb {
    id: IdType,
    shId: IdType | undefined,
    originalId?: IdType,
    typeId: ActivityType,
    pointTypeId: ActivityPointType,
    start: moment.Moment,
    end: moment.Moment
}

export interface IScheduleEmployeeActivityToDb extends IScheduleActivityToDb, IWithEmployable {
}

export interface IDayTypeItem extends IDictionaryItem {
    shortTitle: string | null
}

export interface ICommonShiftItem {
    title: string,
    start: number,
    duration: number
}

export interface IChartDataItem {
    //title: string,
    type: number,
    col: string,
    val: number | null,
}

export interface ITimeZone extends IDictionaryItem {
    id: string,
    title: string,
    utc: string,
    offset: number,
    sort: number
}

export type MarkerPlacement = 'topLeft' | 'top' | 'topRight' | 'left' | 'right' | 'bottomLeft' | 'bottom' | 'bottomRight' |
                              'topBorder' | 'bottomBorder' | 'leftBorder' | 'rightBorder';

export interface IEmployeeScheduleDayMarkerDb extends IHasEmployeeDayKeyDb {
    typeId: ActivityMarkerType,
    placement: MarkerPlacement,
    borderColor: number,
    backgroundColor: number,
}

export interface IEmployeeScheduleDayMarker extends Omit<IEmployeeScheduleDayMarkerDb, 'date'>, IHasEmployeeDayKey {
}

export interface ICommentDb {
    id: IdType,
    text: string,
    author: string,
    date: string
}

export interface IComment extends Omit<ICommentDb, 'date'> {
    date: moment.Moment
}

export interface IParam {
    id: IntervalInfoblockRecordType,
    disabled: boolean,
    title: string
}

export interface IMarkerInfo {
    id: number,
    caption: string,
    backgroundColor: number,
    borderColor: number
}

export const getEmployeeShceduleDayMarkerStyleProcName    = "[Control].[dbo].[proc_WFM_Schedule_Marker_style]";
export const addCommentProcName                           = "[Control].[dbo].[proc_WFM_Schedule_Comment_add]";
export const getCommentListProcName                       = "[Control].[dbo].[proc_WFM_Schedule_Comment_PID_DT_get]";
export const getHistoryListProcName                       = "[Control].[dbo].[proc_WFM_Schedule_DT_PID_history_get]";
export const getWorkTimeListProcName                      = "[Control].[dbo].[proc_WFM_Schedule_DT_PID_WT_get]";
export const getEmployeeTimelineListProcName              = "[Control].[dbo].[proc_WFM_Employee_TimelineList_get]";
export const getDayTypeListProcName                       = "[Control].[dbo].[proc_WFM_DayTypeList_get]";
export const getCommonShiftListProcName                   = "[Control].[dbo].[proc_WFM_Schedule_CommonShiftList_get]";
export const getProjectGroupListProcName                  = "[Control].[dbo].[proc_WFM_Filter_PGList_get]";
export const getFieldListProcName                         = "[Control].[dbo].[proc_WFM_Filter_FieldList_get]";
export const getTagListProcName                           = "[Control].[dbo].[proc_WFM_Filter_TagList_get]";
export const getSkillListProcName                         = "[Control].[dbo].[proc_WFM_Filter_SkillList_get]";
export const getProdActListForFilterProcName              = "[Control].[dbo].[proc_WFM_Filter_ProdActList_get]";
export const saveFilterToDbProcName                       = "[Control].[dbo].[proc_WFM_MainGrid_Filter_save]";
export const getFilterFromDbProcName                      = "[Control].[dbo].[proc_WFM_MainGrid_Filter_get]";
export const getListProcName                              = "[Control].[dbo].[proc_WFM_Schedule]";
export const getForcastObjectListProcName                 = "[Control].[dbo].[proc_WFM_Filter_FOList_get]";
export const addActivitiesToQueueProcName                 = "[Control].[dbo].[proc_WFM_Schedule_queue_edit]";
export const addActivitiesQueueHandlerProcName            = "[Control].[dbo].[proc_WFM_Schedule_queue_handler]";
export const getActivitiesBaseProcName                    = "[Control].[dbo].[proc_WFM_Schedule_ActivityIntervalList_get]";
export const getTimeZoneListProcName                      = "[Control].[dbo].[proc_WFM_Schedule_TimeZoneList_get]";
export const publishSheduleChangesProcName                = "[Control].[dbo].[proc_WFM_Schedule_publish]";
export const resetScheduleChangesProcName                 = "[Control].[dbo].[proc_WFM_Schedule_queue_clear]";
export const getIntervalInfoblockTableFilterProcName      = "[Control].[dbo].[proc_WFM_InfoBlock_Inner_Filter_GRID]";
export const saveIntervalInfoblockTableFilterProcName     = "[Control].[dbo].[proc_WFM_InfoBlock_Inner_Filter_save]";
export const scheduleShrinkProcName                       = "[Control].[dbo].[proc_WFM_Schedule_Shrink]";
export const scheduleProlongProcName                      = "[Control].[dbo].[proc_WFM_Schedule_Prolong]";
export const getTicketStatusTypesProcName                 = "[Control].[dbo].[proc_WFM_Schedule_Request_Status_list_get]";
export const getTicketsListProcName                       = "[Control].[dbo].[proc_WFM_Schedule_DT_PID_Request_get]";
export const closeTicketProcName                          = "[Control].[dbo].[proc_WFM_Schedule_Request_close]";
export const pointDeleteProcName                          = "[Control].[dbo].[proc_WFM_Schedule_Point_del]";
export const getPersonCardInfoProcName                    = "[Control].[dbo].[proc_WFM_PersonCardInfo]";
export const saveRangeToDbProcName                        = "[Control].[dbo].[proc_WFM_MainGrid_Filter_DT_save]";
export const scheduleQueueMassEditProcName                = "[Control].[dbo].[proc_WFM_Schedule_queue_mass_edit]";
export const scheduleInfoBlockR1ProcName                  = "[Control].[dbo].[proc_WFM_Schedule_InfoBlock_R1]";
export const getReportProcName                            = "[Control].[dbo].[proc_WFM_REP_Schedule]";
export const getTagListInfoProcName                       = "[Control].[dbo].[proc_WFM_Person_TagList_get]";
export const saveSpecialMarksChangesProcName              = "[Control].[dbo].[proc_WFM_Person_TagList_set]";
export const getSkillsInfoProcName                        = "[Control].[dbo].[proc_WFM_Person_SkillList_get]";
export const saveSkillsChangesProcName                    = "[Control].[dbo].[proc_WFM_Person_SkillList_set]";
export const getProductiveActListProcName                 = "[Control].[dbo].[proc_WFM_Person_ProductiveActList_get]";
export const saveProductiveActListProcName                = "[Control].[dbo].[proc_WFM_Schedule_Activity_Person_Common_bind]";
export const getPersonScheduleActivitiesProcName          = "[Control].[dbo].[proc_WFM_Person_ShcActList_get]";
export const getPersonScheduleDayCaptionProcName          = "[Control].[dbo].[proc_WFM_Person_SchedDayCap_get]";
export const rescheduleProcName                           = "[Control].[dbo].[proc_WFM_Schedule_Request_Reschedule]";
export const getScheduleShiftSchemeProcName               = "[Control].[dbo].[proc_WFM_Schedule_Shift_Scheme_get]";
export const fillShiftSchemeDaysBufferProcName            = "[Control].[dbo].[proc_WFM_Schedule_Shift_Scheme_Days_buffer_fill]";
export const clearShiftSchemeDaysBufferProcName           = "[Control].[dbo].[proc_WFM_Schedule_Shift_Scheme_Days_buffer_clear]";
export const getScheduleShiftSchemeListProcName           = "[Control].[dbo].[proc_WFM_Schedule_Shift_Scheme_List_PID]";
export const addScheduleShiftSchemeProcName               = "[Control].[dbo].[proc_WFM_Schedule_Shift_Scheme_add]";
export const saveShiftSchemeProcName                      = "[Control].[dbo].[proc_WFM_Schedule_Shift_Scheme_save]";
export const getBasicEmployeeListProcName                 = "[Control].[dbo].[proc_WFM_Person_List_PID]";
export const bindEmployeeShiftSchemeProcName              = "[Control].[dbo].[proc_WFM_Schedule_Shift_Scheme_Person_bind]";
export const applyEmployeeShiftSchemeProcName             = "[Control].[dbo].[proc_WFM_Schedule_Shift_Scheme_Apply]";
export const getScheduleTemplateWorkSchemeListProcName    = "[Control].[dbo].[proc_WFM_Schedule_Break_WD_Scheme_PID_buffer_init]";
export const getBreakSchemeSegmentProcName                = "[Control].[dbo].[proc_WFM_Schedule_Break_WD_Scheme_Segment_buffer_init]";
export const getBreakSchemeListProcName                   = "[Control].[dbo].[proc_WFM_Schedule_Break_ListByRule]";
export const saveScheduleShiftBreaksToBufferProcName      = "[Control].[dbo].[proc_WFM_Schedule_Break_WD_Scheme_Segment_buffer_edit]";
export const saveScheduleShiftBreaksTemplateProcName      = "[Control].[dbo].[proc_WFM_Schedule_Break_WD_Scheme_Segment_buffer_apply]";
export const getTemplatedBreaksProcName                   = "[Control].[dbo].[proc_WFM_Schedule_Break_WD_Scheme_Segment_list]";
export const getScheduleShiftBreakInfoProcName            = "[Control].[dbo].[proc_WFM_PersonBreakRulesInfo]";
export const getVacationInfoProcName                      = "[Control].[dbo].[proc_WFM_Leave_edit_init]";
export const saveVacationProcName                         = "[Control].[dbo].[proc_WFM_Leave_edit_save]";
export const deleteVacationProcName                       = "[Control].[dbo].[proc_WFM_Leave_del]";
export const initActivityNoteProcName                     = "[Control].[dbo].[proc_WFM_Schedule_Segment_Marker_Notes_init]";
export const saveActivityNoteProcName                     = "[Control].[dbo].[proc_WFM_Schedule_Segment_Marker_Notes_save]";
export const checkAccessProcName                          = "[Control].[dbo].[proc_WFM_access_check]";
export const getNotificationListProcName                  = "[Control].[dbo].[proc_WFM_Notify_PID_list]";
export const initEditSickProcName                         = "[Control].[dbo].[proc_WFM_SICK_edit_init]";
export const saveEditSickProcName                         = "[Control].[dbo].[proc_WFM_SICK_edit_save]";
export const deleteSickProcName                           = "[Control].[dbo].[proc_WFM_SICK_del]";
export const getReportListWithoutParamsProcName           = "[Control].[dbo].[proc_WFM_PID_Report_NoParams_List]";

export type AIdType = string | number;

export interface IWorkTimeFragment {

    /** Ключ шаблона смены */
    sssId: AIdType,

    /** Ключ порядкового дня */
    dayKey: number,

    /** Флаг выходного дня */
    isDayOFF: boolean,

    /** Начало рабочего времени */
    start: FiveTime | null,

    /** Продолжительность в минутах */
    duration: number | null,
}

export enum RotationType {
    Month = 0,
    Week  = 1,
}

export interface IScheduleShiftScheme {
    fcp: number,
    isPublic: boolean,
    sss_WDType_ID: RotationType,
    sss_id: number,
    signature: string,
    title: string,
    tz: string,
    staffCount: number,
    canAssign: boolean,
}

export type Guid = string;
export type BreakType = number;

export interface IScheduleWorkTimeCalc {
    dayId: Guid,
    segmentId: Guid,
    brLength: number,
    brPayed: number,
    brUnPayed: number,
}

export interface IScheduleWorkTime extends IScheduleWorkTimeCalc {
    dayIndex: number,
    start: FiveTime,
    durn: number,
    leftOffset: number,
    rightOffset: number,
    brNorm: number,
    brNormPayed: number,
    brNormUnPayed: number,
    isCustom: boolean,
}

export interface ITemplatedBreak {
    title: string,
    segmentId: string,
    durn: number,
    isPayed: boolean,
    sort: number,
    type: number,
}

export interface IActivityNoteBase {
    id: number,
    comment: string | null | undefined,
    accepted: boolean | null,
}

export interface IActivityNote extends IActivityNoteBase {
    fio: string,
    caption: string,
    pidNote: string | null,
    comment: string | null,
    sd: string,
    durn: number,
}

export async function initActivityNote(
    auth: IAuthState<IAuthData>,
    activity: IOriginalActivity,
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
    return await executeFirst<IActivityNote>({
        authData: auth.data,
        procedure: initActivityNoteProcName,
        parameters: [
            new StringQueryParameter("id", activity.id),
        ]
    }, signal);
}

export async function saveActivityNote(
    auth: IAuthState<IAuthData>,
    note: IActivityNoteBase,
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
    return await execute<void>({
        authData: auth.data,
        procedure: saveActivityNoteProcName,
        parameters: [
            new IntQueryParameter("WSgmnt_Mrk_ID", note.id),
            new StringQueryParameter("Comment", note.comment || null),
            new BoolQueryParameter("Accepted", note.accepted || false),
        ]
    }, signal);
}

export async function getProductiveActivityTypeListForFilter(
    auth: IAuthState<IAuthData>,
    projectGroupIds?: IdType[],
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
    return await execute<IProductiveActivityTypeInfo[]>({
        authData: auth.data,
        procedure: getProdActListForFilterProcName,
        parameters: [
            new IdArrayQueryParameter("PjGrList", projectGroupIds ?? []),
        ]
    }, signal);
}

export async function getTemplatedBreaks(
    auth: IAuthState<IAuthData>,
    PID: IEmployee["PID"],
    schemeId: IScheduleWorkTime["dayId"],
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
    
    const result = await execute<ITemplatedBreak[]>({
        authData: auth.data,
        procedure: getTemplatedBreaksProcName,
        parameters: [
            new StringQueryParameter("PID_selected", PID),
            new StringQueryParameter("SSSW_ID", schemeId),
        ]
    }, signal);

    return result;
}

export async function saveScheduleBreaksTemplate(auth: IAuthState<IAuthData>, employee: IEmployee['PID'], from?: moment.Moment, signal?: AbortSignal) {

    await auth.throwIfNotLogedInAndLogout(signal);
    
    return await execute<void>({
        authData: auth.data,
        procedure: saveScheduleShiftBreaksTemplateProcName,
        parameters: [
            new StringQueryParameter("PID_selected", employee),
            new DateTimeQueryParameter("SD", from || null),
        ]
    }, signal);
}

export async function saveScheduleBreaksTemplateToBuffer(auth: IAuthState<IAuthData>, employee: IEmployee['PID'], breaks: TBreak[], signal?: AbortSignal) {

    await auth.throwIfNotLogedInAndLogout(signal);

    const convertedBreaks = convertBreaksForDb(breaks);

    return await execute<IScheduleWorkTimeCalc[]>({
        authData: auth.data,
        procedure: saveScheduleShiftBreaksToBufferProcName,
        parameters: [
            new StringQueryParameter("PID_selected", employee),
            new JSONQueryParameter("AJson", convertedBreaks),
        ]
    }, signal);
}

export async function getTemplateWorkTimeList(auth: IAuthState<IAuthData>, employee: IEmployee['PID'], signal?: AbortSignal): Promise<IScheduleWorkTime[]> {

    await auth.throwIfNotLogedInAndLogout(signal);
    
    return await execute<IScheduleWorkTime[]>({
        authData: auth.data,
        procedure: getScheduleTemplateWorkSchemeListProcName,
        parameters: [
            new StringQueryParameter("PID_selected", employee),
        ]
    }, signal);
}

export async function saveScheduleShiftScheme(auth: IAuthState<IAuthData>, scheme: IScheduleShiftScheme, signal?: AbortSignal): Promise<IScheduleShiftScheme> {

    await auth.throwIfNotLogedInAndLogout(signal);
    
    const result = await execute<IScheduleShiftScheme[]>({
        authData: auth.data,
        procedure: saveShiftSchemeProcName,
        parameters: [
            new IntQueryParameter("SSS_ID", scheme.sss_id),
            new IntQueryParameter("SSS_WDType_ID", scheme.sss_WDType_ID),
            new StringQueryParameter("Caption", scheme.title),
        ]
    }, signal);

    if (result.length === 1) {
        return result[0];
    }

    throw new InvalidDataException(`Процедура ${saveShiftSchemeProcName} вернула не корректные данные`);
}

export async function saveScheduleShiftSchemeToBuffer(auth: IAuthState<IAuthData>, scheme: IScheduleShiftScheme, fragments: IWorkTimeFragment[], signal?: AbortSignal) {

    await auth.throwIfNotLogedInAndLogout(signal);
    
    const result = await execute<void>({
        authData: auth.data,
        procedure: fillShiftSchemeDaysBufferProcName,
        parameters: [
            new IntQueryParameter("sss_id", scheme.sss_id),
            new JSONQueryParameter("SJOIN", fragments),
        ]
    }, signal);

    return result;
}

export async function clearScheduleShiftSchemeBuffer(auth: IAuthState<IAuthData>, signal?: AbortSignal) {

    await auth.throwIfNotLogedInAndLogout(signal);
    
    const result = await execute<void>({
        authData: auth.data,
        procedure: clearShiftSchemeDaysBufferProcName,
        parameters: []
    }, signal);

    return result;
}

export async function getScheduleShiftScheme(
    auth: IAuthState<IAuthData>,
    id: number,
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
    
    const result = await execute<IWorkTimeFragment[]>({
        authData: auth.data,
        procedure: getScheduleShiftSchemeProcName,
        parameters: [
            new IntQueryParameter("SSS_ID", id),
        ]
    }, signal);

    return result;
}

export async function getPersonScheduleActivities(
    auth: IAuthState<IAuthData>,
    editorType: ScheduleActionEditorType,
    id: string,
    activities: IScheduleEmployeeActivityToDb[] | undefined,
    signal?: AbortSignal
) {

}

export interface IGetPersonScheduleDayCaption {
    PID: IScheduleBoardEmployee['PID'],
    date: moment.Moment,
}

export interface IScheduleDayCaptionData {

}

export async function getPersonScheduleDayCaption(
    auth: IAuthState<IAuthData>,
    data: IGetPersonScheduleDayCaption,
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
    
    const result = await execute<IScheduleDayCaptionData>({
        authData: auth.data,
        procedure: getPersonScheduleDayCaptionProcName,
        parameters: [
            new StringQueryParameter("PID_selected", data.PID),
            new DateQueryParameter("DT", data.date),
        ]
    }, signal);

    return result;
}

export interface IShiftTransfer {
    fromPID: IScheduleBoardEmployee['PID'],
    toPID: IScheduleBoardEmployee['PID'],
    from: moment.Moment,
    to: moment.Moment,
    comment: string,
    isAgentRequest: boolean,
}

export async function reschedule(
    auth: IAuthState<IAuthData>,
    transfer: IShiftTransfer,
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
        
    const result = await execute<void>({
        authData: auth.data,
        procedure: rescheduleProcName,
        parameters: [
            new StringQueryParameter("PIDfrom", transfer.fromPID),
            new StringQueryParameter("PIDto", transfer.toPID),
            new DateQueryParameter("DTfrom", transfer.from),
            new DateQueryParameter("DTto", transfer.to),
            new BoolQueryParameter("isAgentRequest", transfer.isAgentRequest),
            new StringQueryParameter("Comment", transfer.comment ?? null),
        ]
    }, signal);

    return result;
}

export async function scheduleQueueMassEdit(
    auth: IAuthState<IAuthData>,
    editorType: ScheduleActionEditorType,
    id: string,
    activities: IScheduleEmployeeActivityToDb[] | undefined,
    publish: boolean,
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
    
    const result = await execute<void>({
        authData: auth.data,
        procedure: scheduleQueueMassEditProcName,
        parameters: [
            new StringQueryParameter("BrsId", id),
            new IntQueryParameter("lvl", editorType),
            new DateTimeOffsetQueryParameter("DTact", moment()),
            new JSONQueryParameter("Actions", activities ?? null),
            new BoolQueryParameter("doPublish", publish),
        ]
    }, signal);

    return result;
}

export async function saveRangeFilter(auth: IAuthState<IAuthData>, filter: IScheduleBoardFilter, signal?: AbortSignal) {
    await auth.throwIfNotLogedInAndLogout(signal);
    return await execute<void>({
        authData: auth.data,
        procedure: saveRangeToDbProcName,
        parameters: [
            new DateQueryParameter("sd", filter.from),
            new DateQueryParameter("ed", filter.to),
            new StringQueryParameter("timeZone", filter.timeZone),
        ]
    }, signal);
}

export async function getEmployeeCard(auth: IAuthState<IAuthData>, employee: IEmployee, signal?: AbortSignal) {
    
    await auth.throwIfNotLogedInAndLogout(signal);

    const cards = await execute<{Ajson: string}[]>({
        authData: auth.data,
        procedure: getPersonCardInfoProcName,
        parameters: [
            new StringQueryParameter("PID_selected", employee.PID)
        ]
    }, signal);
    
    if (cards.length > 0) {
        return JSON.parse(cards[0].Ajson) as IEmployeeCard;
    }

    return null;
}

export async function getTagList(auth: IAuthState<IAuthData>, employee: IEmployee, signal?: AbortSignal) {
    
    await auth.throwIfNotLogedInAndLogout(signal);
    
    const tagList = await execute<IItemDetailsEdit[]>({
        authData: auth.data,
        procedure: getTagListInfoProcName,
        parameters: [
            new StringQueryParameter("PID_selected", employee.PID)
        ]
    }, signal);

    return tagList;
}

export async function getSkills(auth: IAuthState<IAuthData>, employee: IEmployee | IEmployee['PID'], signal?: AbortSignal) {

    await auth.throwIfNotLogedInAndLogout(signal);

    const skills = await execute<IItemDetailsEdit[]>({
        authData: auth.data,
        procedure: getSkillsInfoProcName,
        parameters: [
            new StringQueryParameter("PID_selected", typeof employee === 'string' ? employee : employee.PID)
        ]
    }, signal);

    return skills;
}

export async function getProductiveActList(auth: IAuthState<IAuthData>, employee: IEmployee, signal?: AbortSignal) {

    await auth.throwIfNotLogedInAndLogout(signal);

    const productiveActList = await execute<IActivitiesItem[]>({
        authData: auth.data,
        procedure: getProductiveActListProcName,
        parameters: [
            new StringQueryParameter("PID_selected", employee.PID)
        ]
    }, signal);

    return productiveActList;
}

export async function getScheduleExtensionList(auth: IAuthState<IAuthData>, filter: IScheduleBoardFilter, locale?: string, signal?: AbortSignal) {
    await auth.throwIfNotLogedInAndLogout(signal);
    return await execute<IScheduleExtensionItem[]>({
        authData: auth.data,
        procedure: getEmployeeTimelineListProcName,
        parameters: [
            new StringQueryParameter("lang", locale ?? null)
        ]
    }, signal);
}

export async function getCommonShiftList(auth: IAuthState<IAuthData>, filter: IScheduleBoardFilter, item: IEmployeeScheduleDay, signal?: AbortSignal) {
    await auth.throwIfNotLogedInAndLogout(signal);
    return await execute<ICommonShiftItem[]>({
        authData: auth.data,
        procedure: getCommonShiftListProcName,
        parameters: [
            new IntQueryParameter("UTC_offset_min_client", 300),
            new StringQueryParameter("PID_selected", item.PID)
        ]
    }, signal);
}

export async function getDayTypeList(auth: IAuthState<IAuthData>, signal?: AbortSignal) {
    await auth.throwIfNotLogedInAndLogout(signal);
    return await execute<IDayTypeItem[]>({
        authData: auth.data,
        procedure: getDayTypeListProcName,
    }, signal);
}

export async function getProjectGroupListForFilter(auth: IAuthState<IAuthData>, signal?: AbortSignal) {
    await auth.throwIfNotLogedInAndLogout(signal);
    return await execute<IProjectGroupShortInfo[]>({
        authData: auth.data,
        procedure: getProjectGroupListProcName,
    }, signal);
}

export async function getFieldListForFilter(auth: IAuthState<IAuthData>, signal?: AbortSignal) {
    await auth.throwIfNotLogedInAndLogout(signal);
    return await execute<IFieldShortInfo[]>({
        authData: auth.data,
        procedure: getFieldListProcName
    }, signal);
}

export async function getTagListForFilter(auth: IAuthState<IAuthData>, projectGroupIds?: IdType[], signal?: AbortSignal) {
    await auth.throwIfNotLogedInAndLogout(signal);
    return await execute<ITagShortInfo[]>({
        authData: auth.data,
        procedure: getTagListProcName,
        parameters: [
            new IdArrayQueryParameter("PjGrList", projectGroupIds ?? []),
        ]
    }, signal);
}

export async function getForcastObjectListForFilter(auth: IAuthState<IAuthData>, projectGroupIds?: IdType[], signal?: AbortSignal) {
    await auth.throwIfNotLogedInAndLogout(signal);
    return await execute<IForcastShortInfo[]>({
        authData: auth.data,
        procedure: getForcastObjectListProcName,
        parameters: [
            new IdArrayQueryParameter("PjGrList", projectGroupIds ?? []),
        ]
    }, signal);
}

export async function getSkillListForFilter(auth: IAuthState<IAuthData>, projectGroupIds?: IdType[], signal?: AbortSignal) {
    await auth.throwIfNotLogedInAndLogout(signal);
    return await execute<ISkillShortInfo[]>({
        authData: auth.data,
        procedure: getSkillListProcName,
        parameters: [
            new IdArrayQueryParameter("PjGrList", projectGroupIds ?? []),
        ]
    }, signal);
}

export const dateFormat = 'YYYY-MM-DD';

export function normalizeFilterToDb(filter: IScheduleBoardFilter) {
    return JSON.stringify({
        ...filter,
        from: filter.from?.format(dateFormat),
        to:   filter.to?.format(dateFormat)
    });
}

export function normalizeFilterFromDb(filter: IScheduleBoardFilterDb): IScheduleBoardFilter {
    return {
        projectGroupIds: filter.projectGroupIds || [],
        fieldIds: filter.fieldIds || [],
        personType: filter.personType ?? PersonFilterType.LinearPerson,
        tagIds: filter.tagIds || [],
        prodActIds: filter.prodActIds || [],
        forcastObjIds: filter.forcastObjIds || [],
        skillIds: filter.skillIds || [],
        crossEmploee: filter.crossEmploee ?? false,
        timeZone: filter.timeZone ?? moment.tz.guess(),
        lang: filter.lang || 'ru',
        from: isNullOrUndefined(filter.from) ? moment().startOf('month') : moment(filter.from, dateFormat),
        to:   isNullOrUndefined(filter.to)   ? moment().endOf('month') : moment(filter.to, dateFormat),
    }
}

export async function getScheduleBoardFilter(auth: IAuthState<IAuthData>, signal?: AbortSignal) {

    await auth.throwIfNotLogedInAndLogout(signal);
    
    const result = await execute<any>({
        authData: auth.data,
        procedure: getFilterFromDbProcName,
    }, signal);

    try {
        const tmp: IScheduleBoardFilterDb = JSON.parse(result[0]["jsVal"]);
        return normalizeFilterFromDb(tmp);
    } catch (ex) {
        throw new InvalidCastException("Failed loading filter object from db", Exception.toException(ex));
    }
}

export async function saveScheduleBoardFilter(auth: IAuthState<IAuthData>, filter: IScheduleBoardFilter, signal?: AbortSignal) {
    await auth.throwIfNotLogedInAndLogout(signal);
    return await execute<void>({
        authData: auth.data,
        procedure: saveFilterToDbProcName,
        parameters: [
            new StringQueryParameter("jsVal", normalizeFilterToDb(filter)),
        ]
    }, signal);
}

export async function getPermissions(...args: any) {
    return [];
}

export async function getList<T>(
    auth: IAuthState<IAuthData>,
    filter: IScheduleBoardFilter,
    type: RecordType,
    customParameters?: Record<string, IQueryParameter>,
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);

    const parameters = {

        "sd":       new DateQueryParameter("sd", filter.from),
        "ed":       new DateQueryParameter("ed", filter.to),
        "TZ":       new StringQueryParameter("TZ", filter.timeZone),
        "RType":    new IntQueryParameter("RType", type),

        ...customParameters
    };

    return await execute<T[]>({
        authData: auth.data,
        procedure: getListProcName,
        parameters: Object.values(parameters)
    }, signal);
}

export const getEmployeeList = (auth: IAuthState<IAuthData>, filter: IScheduleBoardFilter, signal?: AbortSignal) =>
    getList<IScheduleBoardEmployee>(auth, filter, RecordType.Employee, {}, signal);

export const getRecordByType = async (auth: IAuthState<IAuthData>, filter: IScheduleBoardFilter, recordType: RecordType, signal?: AbortSignal): Promise<IEmployeeScheduleDay[]> => {
    const items = await getList<IEmployeeScheduleDayDb>(auth, filter, recordType, {}, signal);
    return items.map(x => ({
        ...x,
        date: moment(x.date) 
    }));
}

// Obsolete: Процедура вытащена в отдельную
export const getScheduleInfoBlockOld = (auth: IAuthState<IAuthData>, filter: IScheduleBoardFilter, signal?: AbortSignal) => 
    getList<IDaysInfoblockItem>(auth, filter, RecordType.InfoBlock, {}, signal);

export const getScheduleInfoBlock = async (auth: IAuthState<IAuthData>, filter: IScheduleBoardFilter, signal?: AbortSignal) => {
    await auth.throwIfNotLogedInAndLogout(signal);
    return await execute<IDaysInfoblockItem[]>({
        authData: auth.data,
        procedure: scheduleInfoBlockR1ProcName,
    }, signal);
}

export const getActivityListBaseData = async (auth: IAuthState<IAuthData>, roleIds: ActivityRoleType[], locale?: string, signal?: AbortSignal) => {
    await auth.throwIfNotLogedInAndLogout(signal);
    return await execute<IActivityBase[]>({
        authData: auth.data,
        procedure: getActivitiesBaseProcName,
        parameters: [
            new IntArrayQueryParameter("roleIds", roleIds),
            new StringQueryParameter("lang", locale ?? null)
        ]
    }, signal);
}

export const getTimeZoneList = async (auth: IAuthState<IAuthData>, lang?: string, signal?: AbortSignal) => {
    await auth.throwIfNotLogedInAndLogout(signal);
    return await execute<ITimeZone[]>({
        authData: auth.data,
        procedure: getTimeZoneListProcName,
        parameters: [
            new StringQueryParameter("lang", lang ?? null)
        ]
    }, signal);
}

export const getEmployeeActivityList = async (
    auth: IAuthState<IAuthData>,
    filter: IScheduleBoardFilter,
    employee: IScheduleBoardEmployee | null = null,
    signal?: AbortSignal
): Promise<IOriginalActivity[]> => {
    
    const items = await getList<IOriginalActivityDb>(auth, filter, RecordType.IntervalСhart, {
        "PID_selected": new StringQueryParameter("PID_selected", employee?.PID ?? null)
    }, signal);

    return items.map(x => ({
        ...x,
        start: moment(x.start),
        end: moment(x.end),
        minStart: x.minStart ? moment(x.minStart) : undefined,
        maxEnd: x.maxEnd ? moment(x.maxEnd) : undefined
    }));
}

export enum ScheduleActionEditorType {
    DayEditor = 1,
    IntervalEditor = 2,
    Generator = 3,
}

export const saveEmployeeDayEditorDbActivitys = async (
    auth: IAuthState<IAuthData>,
    filter: IScheduleBoardFilter,
    editorType: ScheduleActionEditorType,
    employee: IScheduleBoardEmployee,
    day: Date | moment.Moment,
    activities: IScheduleActivityToDb[] | undefined,
    signal?: AbortSignal
) => {

    await auth.throwIfNotLogedInAndLogout(signal);

    const result = await execute<void>({
        authData: auth.data,
        procedure: addActivitiesQueueHandlerProcName,
        parameters: [
            new IntQueryParameter("lvl", editorType),
            new DateTimeOffsetQueryParameter("DTact", moment()),
            new StringQueryParameter("PID_selected", employee.PID),
            new JSONQueryParameter("Actions", activities ?? null),
            new DateQueryParameter("DT", day)
        ]
    }, signal);

    return result;
}

export const saveEmployeeDbActivitys = async (
    auth: IAuthState<IAuthData>,
    filter: IScheduleBoardFilter,
    editorType: ScheduleActionEditorType,
    employee: IScheduleBoardEmployee,
    day: Date | moment.Moment,
    activities: IScheduleActivityToDb[] | undefined,
    signal?: AbortSignal
) => {

    await auth.throwIfNotLogedInAndLogout(signal);

    const result = await execute<void>({
        authData: auth.data,
        procedure: addActivitiesToQueueProcName,
        parameters: [
            new IntQueryParameter("lvl", editorType),
            new DateTimeOffsetQueryParameter("DTact", moment()),
            new StringQueryParameter("PID_selected", employee.PID),
            new JSONQueryParameter("Actions", activities ?? null),
            new DateQueryParameter("DT", day)
        ]
    }, signal);

    return result;
}

export const saveEmployeeActivitys = async (
    auth: IAuthState<IAuthData>,
    filter: IScheduleBoardFilter,
    editorType: ScheduleActionEditorType,
    employee: IScheduleBoardEmployee,
    day: Date | moment.Moment,
    activities?: IScheduleEmployeeActivity[],
    signal?: AbortSignal
) => {

    activities?.map((activity) => {
        if(activity.resource !== employee.PID) {
            throw new InvalidDataException("Activities must be for one employee");
        }
    });
    
    const normalizeActivities = activities?.map((activity): IScheduleActivityToDb => ({
        id: activity.id,
        shId: activity.originalActivity.shId,
        originalId: activity.originalActivity.id,
        typeId: activity.originalActivity.typeId,
        pointTypeId: activity.originalActivity.pointTypeId,
        start: moment(activity.start),
        end: moment(activity.end),
    }));

    return saveEmployeeDbActivitys(auth, filter, editorType, employee, day, normalizeActivities, signal);
}

export const publishScheduleChanges = async (
    auth: IAuthState<IAuthData>,
    signal?: AbortSignal) => {

    await auth.throwIfNotLogedInAndLogout(signal);

    return await execute<IPublishException[]>({
        authData: auth.data,
        procedure: publishSheduleChangesProcName
    }, signal);
}

export const resetScheduleChanges = async (
    auth: IAuthState<IAuthData>,
    filter: IScheduleBoardFilter,
    employee: IScheduleBoardEmployee | null = null,
    startDate?: moment.Moment | NullOrUndefined,
    endDate?: moment.Moment | NullOrUndefined,
    signal?: AbortSignal) => {

    await auth.throwIfNotLogedInAndLogout(signal);

    return await execute<void>({
        authData: auth.data,
        procedure: resetScheduleChangesProcName,
        parameters: [
            new DateQueryParameter("sd", startDate ?? null),
            new DateQueryParameter("ed", endDate ?? null),
            new StringQueryParameter("PID_selected", employee ? employee.PID : null),
            new StringQueryParameter("TZ", filter.timeZone),
            new IntQueryParameter("RType", 0),
        ]
    }, signal);
}

export enum InfoblockType {
    Table,
    Chart
}

export const getIntervalInfoBlockList = async <T>(
    auth: IAuthState<IAuthData>,
    filter: IScheduleBoardFilter,
    interval: number,
    type: InfoblockType.Table | InfoblockType.Chart,
    employee: IScheduleBoardEmployee | NullOrUndefined = null,
    signal?: AbortSignal) => {

    if([15, 60].indexOf(interval) === -1) {
        throw new NotSupportedException("Interval not supported");
    }

    const recordType = type === InfoblockType.Table
                    ? interval === 15 ? RecordType.IntervalTableNeedAssigned15Min : RecordType.IntervalTableNeedAssigned60Min
                    : interval === 15 ? RecordType.IntervalChartNeedAssigned15Min : RecordType.IntervalChartNeedAssigned60Min;
    
    return await getList<T>(auth, filter, recordType, {
        "PID_selected": new StringQueryParameter("PID_selected", employee?.PID ?? null)
    }, signal);
}

export const getIntervalInfoBlockListV2 = async <T>(
    auth: IAuthState<IAuthData>,
    filter: IScheduleBoardFilter,
    interval: number,
    type: InfoblockType.Table | InfoblockType.Chart,
    employee: IScheduleBoardEmployee | NullOrUndefined = null,
    signal?: AbortSignal) => {

    if([15, 60].indexOf(interval) === -1) {
        throw new NotSupportedException("Interval not supported");
    }

    const recordType = type === InfoblockType.Table
                    ? interval === 15 ? RecordType.IntervalTableNeedAssigned15MinV2 : RecordType.IntervalTableNeedAssigned60MinV2
                    : interval === 15 ? RecordType.IntervalChartNeedAssigned15Min : RecordType.IntervalChartNeedAssigned60Min;
    
    return await getList<T>(auth, filter, recordType, {
        "PID_selected": new StringQueryParameter("PID_selected", employee?.PID ?? null)
    }, signal);
}

export const getIntervalInfoBlockChartData = (
    auth: IAuthState<IAuthData>,
    filter: IScheduleBoardFilter,
    interval: number,
    employee: IScheduleBoardEmployee | NullOrUndefined = null,
    signal?: AbortSignal
) => getIntervalInfoBlockList<IChartDataItem>(auth, filter, interval, InfoblockType.Chart, employee, signal);

export const getIntervalInfoBlockTableData = (
    auth: IAuthState<IAuthData>,
    filter: IScheduleBoardFilter,
    interval: number,
    employee: IScheduleBoardEmployee | NullOrUndefined = null,
    signal?: AbortSignal
) => getIntervalInfoBlockList<IIntervalInfoblockTableRecord>(auth, filter, interval, InfoblockType.Table, employee, signal);

export const getIntervalInfoBlockTableDataV2 = (
    auth: IAuthState<IAuthData>,
    filter: IScheduleBoardFilter,
    interval: number,
    employee: IScheduleBoardEmployee | NullOrUndefined = null,
    signal?: AbortSignal
) => getIntervalInfoBlockListV2<IIntervalInfoblockTableRecordV2>(auth, filter, interval, InfoblockType.Table, employee, signal);

export const getScheduleExtensionActivityList = async (
    auth: IAuthState<IAuthData>,
    filter: IScheduleBoardFilter,
    employee: IScheduleBoardEmployee | NullOrUndefined = null,
    signal?: AbortSignal
): Promise<IOriginalExtensionActivity[]> => {
    
    if(!employee) {
        return [];
    }

    const items = await getList<IOriginalExtensionActivityDb>(auth, filter, RecordType.TimelineEmployeeExtension, {
        "PID_selected": new StringQueryParameter("PID_selected", employee?.PID ?? null)
    }, signal);

    return items.map(x  => ({
        ...x,
        start: moment(x.start),
        end: moment(x.end),
        minStart: x.minStart ? moment(x.minStart) : undefined,
        maxEnd: x.maxEnd ? moment(x.maxEnd) : undefined
    }));
}

export const getScheduleItemCommentList = async (
    auth: IAuthState<IAuthData>,
    employeeDay: IHasEmployeeDayKey,
    signal?: AbortSignal
): Promise<IComment[]> => {

    await auth.throwIfNotLogedInAndLogout(signal);

    const result = await execute<ICommentDb[]>({
        authData: auth.data,
        procedure: getCommentListProcName,
        parameters: [
            new StringQueryParameter("PID_selected", employeeDay.PID),
            new DateQueryParameter("DT", employeeDay.date)
        ]
    }, signal);
    
    return result.map(x => ({
        ...x,
        date: moment(x.date)
    }));
}

export const getScheduleItemHistoryList = async (
    auth: IAuthState<IAuthData>,
    employeeDay: IHasEmployeeDayKey,
    signal?: AbortSignal
): Promise<IGroupedSimplifiedActivity[]> => {

    await auth.throwIfNotLogedInAndLogout(signal);

    const result = await execute<IGroupedSimplifiedActivityDb[]>({
        authData: auth.data,
        procedure: getHistoryListProcName,
        parameters: [
            new StringQueryParameter("PID_selected", employeeDay.PID),
            new DateQueryParameter("DT", employeeDay.date)
        ]
    }, signal);
    
    return result.map(x => ({
        ...x,
        start: moment(x.start),
        end: moment(x.end),
        minStart: x.minStart ? moment(x.minStart) : undefined,
        maxEnd: x.maxEnd ? moment(x.maxEnd) : undefined
    }));
}

export const getScheduleItemWorkTimeList = async (
    auth: IAuthState<IAuthData>,
    employeeDay: IHasEmployeeDayKey,
    filter: IScheduleBoardFilter,
    signal?: AbortSignal
): Promise<ISimplifiedActivityWithTotal[]> => {

    await auth.throwIfNotLogedInAndLogout(signal);

    const result = await execute<ISimplifiedActivityWithTotal[]>({
        authData: auth.data,
        procedure: getWorkTimeListProcName,
        parameters: [
            new StringQueryParameter("PID_selected", employeeDay.PID),
            new DateQueryParameter("DT", employeeDay.date),
            new StringQueryParameter("TZ", filter.timeZone),
        ]
    }, signal);
    
    return result.map(x => ({
        ...x,
        start: moment(x.start),
        end: moment(x.end)
    }));
}

export const getScheduleItemsMarkerList = async (
    auth: IAuthState<IAuthData>,
    filter: IScheduleBoardFilter,
    recordType: RecordType,
    signal?: AbortSignal
): Promise<IEmployeeScheduleDayMarker[]> => {

    if (recordType !== RecordType.Schedule) {
        return [];
    }

    const items = await getList<IEmployeeScheduleDayMarkerDb>(auth, filter, RecordType.ScheduleMarkers, undefined, signal);
    return items.map(x => ({
        ...x,
        date: moment(x.date),
    }));
}

export const addComment = async (
    auth: IAuthState<IAuthData>,
    employeeDay: IHasEmployeeDayKey,
    text: string | undefined,
    signal?: AbortSignal
): Promise<IComment> => {

    await auth.throwIfNotLogedInAndLogout(signal);

    const result = await execute<ICommentDb[]>({
        authData: auth.data,
        procedure: addCommentProcName,
        parameters: [
            new StringQueryParameter("PID_selected", employeeDay.PID),
            new DateQueryParameter("DT", employeeDay.date),
            new StringQueryParameter("Comment", text ?? null)
        ]
    }, signal);

    if(result.length !== 1) {
        throw new Error("Failed add comment, server return");
    }

    return {
        ...result[0],
        date: moment(result[0].date)
    }
}

export async function getIntervalInfoblockTableFilterParams(
    auth: IAuthState<IAuthData>,
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
    return await execute<IParam[]>({
        authData: auth.data,
        procedure: getIntervalInfoblockTableFilterProcName,
    }, signal);
}

export async function saveIntervalInfoblockTableFilterParams(
    auth: IAuthState<IAuthData>,
    ids: IntervalInfoblockRecordType[],
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
    return await execute<IParam[]>({
        authData: auth.data,
        procedure: saveIntervalInfoblockTableFilterProcName,
        parameters: [
            new IdArrayQueryParameter("idList", ids),
        ]
    }, signal);
}

export async function getMarkerInfoList(
    auth: IAuthState<IAuthData>,
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
    return await execute<IMarkerInfo[]>({
        authData: auth.data,
        procedure: getEmployeeShceduleDayMarkerStyleProcName,
    }, signal);
}

export interface IShrinkParams {
    employees: IEmployee['PID'][],
    start: moment.Moment,
    duration: number | null,
    reason: string,
    isAgentRequest: boolean,
}

export async function scheduleShrink(
    auth: IAuthState<IAuthData>,
    params: IShrinkParams,
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
    return await execute<void>({
        authData: auth.data,
        procedure: scheduleShrinkProcName,
        parameters: [
            new StringQueryParameter("PIDlist", params.employees.join(',')),
            new DateTimeOffsetQueryParameter("SD", params.start),
            new IntQueryParameter("Durn", params.duration),
            new StringQueryParameter('Comment', params.reason),
            new BoolQueryParameter("isAgentRequest", params.isAgentRequest),
        ]
    }, signal);
}

export interface IProlongParams {
    employees: IEmployee['PID'][],
    start: moment.Moment,
    duration: number | null,
    comment: string,
    isAgentRequest: boolean,
    sideJob: boolean,
}

export async function scheduleProlong(
    auth: IAuthState<IAuthData>,
    params: IProlongParams,
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
    return await execute<void>({
        authData: auth.data,
        procedure: scheduleProlongProcName,
        parameters: [
            new StringQueryParameter("PIDlist", params.employees.join(',')),
            new DateTimeOffsetQueryParameter("SD", params.start),
            new IntQueryParameter("Durn", params.duration),
            new StringQueryParameter('Comment', params.comment),
            new BoolQueryParameter("isAgentRequest", params.isAgentRequest),
            new BoolQueryParameter("isOverPlan", params.sideJob),
        ]
    }, signal);
}

export interface ITicketStatus {
    id: IdType,
    caption: string,
    backgroundColor: number,
    borderColor: number,
    textColor: number,
}

export async function getTicketStatusTypes(
    auth: IAuthState<IAuthData>,
    lang: string = 'ru',
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
    return await execute<ITicketStatus[]>({
        authData: auth.data,
        procedure: getTicketStatusTypesProcName,
        parameters: [
            new StringQueryParameter("lang", lang),
        ]
    }, signal);
}

export interface ITicketLineByLine {
    id: number,
    statusId: ITicketStatus['id'],
    allowControl: boolean,
    key: string,
    value: string,
}

export interface ITicket {
    id: number,
    title?: string,
    allowControl: boolean,
    statusId: ITicketStatus['id'],
    statusCaption?: string,
    details: Array<{title: string, value: string}>
}

export async function getTicketsList(
    auth: IAuthState<IAuthData>,
    employeeDay: IHasEmployeeDayKey,
    lang: string = 'ru',
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
    const lines = await execute<ITicketLineByLine[]>({
        authData: auth.data,
        procedure: getTicketsListProcName,
        parameters: [
            new StringQueryParameter("PID_selected", employeeDay.PID),
            new DateQueryParameter("DT", employeeDay.date),
            new StringQueryParameter("lang", lang),
        ]
    }, signal);

    const tickets = new Map<ITicket['id'], ITicket>();

    for(const line of lines) {

        const id = line.id;
        const key = line.key;
        const value = line.value;

        let ticket = tickets.get(id);

        if(!ticket) {
            ticket = {
                id: id,
                statusId: line.statusId,
                allowControl: line.allowControl,
                details: []
            }
        }

        if (key === 'title' || key === 'statusCaption') {
            ticket[key] = value;
        }
        else {
            ticket.details.push({
                title: key,
                value: value
            });
        }

        tickets.set(id, ticket);
    }

    return Array.from(tickets.values());
}

export type TicketDecision = 'accept' | 'reject';

export interface IConfirmationValues {
    ticketId: ITicket['id'],
    decision: TicketDecision
    comment?: string
}

export async function closeTicket(
    auth: IAuthState<IAuthData>,
    response: IConfirmationValues,
    signal?: AbortSignal
) {
    const normalizeDecision = 
        response.decision === 'accept' ? 2 :
        response.decision === 'reject' ? 1 :
        new NotSupportedException('Response decision must by accept or reject');

    if(typeof normalizeDecision !== 'number') {
        throw normalizeDecision;
    }

    await auth.throwIfNotLogedInAndLogout(signal);
    return await execute<void>({
        authData: auth.data,
        procedure: closeTicketProcName,
        parameters: [
            new IntQueryParameter("Sch_Rq_ID", response.ticketId),
            new IntQueryParameter("SchRqSt_ID", normalizeDecision),
            new StringQueryParameter("CommentOUT", response.comment || ''),
        ]
    }, signal);
}

export async function deletePoint(
    auth: IAuthState<IAuthData>,
    activity: IOriginalExtensionActivity,
    signal?: AbortSignal
) {
    //@PID varchar(32) 
    //,@SID UNIQUEIDENTIFIER 
    //,@EmpTL_ID int = 3
    //,@id varchar(max) = '1681989900_2003_792110a38316936e9988d13b88b12711_34988'
    await auth.throwIfNotLogedInAndLogout(signal);
    return await execute<void>({
        authData: auth.data,
        procedure: pointDeleteProcName,
        parameters: [
            new IntQueryParameter("EmpTL_ID", activity.EmpTL_ID as unknown as number),
            new StringQueryParameter("id", activity.id),
        ]
    }, signal);
}

export enum ReportType {
    Intervals = 1,
    Time = 2,
    Changes = 3,
    Discipline = 4,
    ScheduleDayOfHour = 5,
    ActiveTimeDayOf15Min = 6,
    ActiveTimeDayOfHour = 7,
    DisciplineNotes = 8,
    SLOPDH = 9,
}

export async function downloadReport(auth: IAuthState<IAuthData>, procName: string, signal?: AbortSignal) {

    const query: IQuery = {
        authData: auth.data,
        procedure: procName,
    }

    await getXLSX(query, 'report', undefined, signal);
}

export enum Module {
    ScheduleOptimizerApply = 260,
    SelectGeneratorServer  = 261,
}

export interface CheckAccessResult {
    result: boolean;
}

export async function checkAccess(auth: IAuthState<IAuthData>, module: Module, signal?: AbortSignal) {
    
    await auth.throwIfNotLogedInAndLogout(signal);
    const ans = await execute<CheckAccessResult[]>({
        authData: auth.data,
        procedure: checkAccessProcName,
        parameters: [
            new IntQueryParameter("MOA_ID", module),
        ]
    }, signal);

    if (isArray(ans) && ans.length > 0) {
        return !!ans[0].result;
    }

    return false;
}

///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////

export enum Gender {
    None = 0,
    Male = 1,
    Female = 2,
}

export type TContactId = number;

export enum EmployeeContactType {
    Email = 'email',
    Phone = 'phone',
    Skype = 'skype',
    Whatsapp = 'whatsapp',
    Telegram = 'telegram',
}

export interface IContactInfo {
    id: TContactId,
    type: EmployeeContactType,
    value: string
}

export interface IEmployeeCard {
    personalInfo: IEmployeeInfo,
    workTimeInputs?: IWorkTimeInput[],
    projectGroupTags?: ITagsData<ITagItem>,
    skills?: ISkillsData<ITagItem>,
    specialMarks?: ITagsData<ITagItem>,
    externalLogins?: IExternalLogin[]
}

export interface IEmployeeInfo {
    gender: Gender,
    fio: string,
    role: string,
    post: string,
    company: string,
    office: string,
    timezone: string,
    birthdate: string,
    canEdit: boolean,
    contact: IContact[],
}

export interface IWorkTimeInput {
    title: string,
    content: string,
    id: number,
    canEdit: boolean,
    form_id: WorkTimeInputFormIds
}

export enum WorkTimeInputFormIds {
    MainActivity    = "1",
    WorkShiftScheme = "2",
    WorkBreakScheme = "3",
}

export interface IProjectGroup {
    id: number,
    content: string,
    groupMember?: GroupMember,
}

export interface ISkill {
    id: number,
    content: string,
    color?: string,
}

export interface ISpecialMark {
    id: number,
    content: string,
    color?: string,
    isActive?: boolean,
}

export interface IExternalLogin {
    id: number | string,
    system: string,
    value: string,
}

export const systemSkillId = - 999;

export interface ITagsData<T> {
    canEdit: boolean,
    tags: T[]
}

export interface ISkillsData<T> {
    mainSkillId?: number,
    canEdit: boolean,
    tags: T[]
}

export enum GroupMember {
    Watcher = 0,
    Member = 1
}

export interface IContact {
    id: string,
    type: EmployeeContactType, 
    value: string | string[],  
}

export type TSpecialMarksId = string | number;
export interface IEmployeeSpecialMarksChanges extends IWithEmployable {
    start: moment.Moment,
    enabled: TSpecialMarksId[],
    disabled: TSpecialMarksId[],
}

export type TSpecialMarksChanges = IEmployeeSpecialMarksChanges[];

export interface IEmployeeSpecialMarksChangesToDb extends IWithEmployable {
    start: string,
    enabled: TSpecialMarksId[],
    disabled: TSpecialMarksId[],
}

export type TSpecialMarksChangesToDb = IEmployeeSpecialMarksChangesToDb[];

export interface IEnabledEmployeeSpecialMarks extends IWithEmployable {
    enabled: TSpecialMarksId[],
}

export type TEnabledSpecialMarks = IEnabledEmployeeSpecialMarks[];

export interface ISpecialMarkEx {
    id: number,
    content: string,
    color?: string,
    isActive?: boolean
}

export interface IBasicItemData {
    id: number,
    content: string,
}

export interface IItemDetailsEdit extends IBasicItemData {
    isActive: boolean,
    color: string,
}

export interface IBasicEmployee extends IWithEmployable {
    fio: string,
}

export interface IBindEmployeeScheduleShift {
    employeePID: IWithEmployable['PID'],
    startDate: moment.Moment,
    schemeId: number
}

export interface IAssignEmployeeScheduleShift {
    employeePID: IWithEmployable['PID'],
    startDate: moment.Moment,
}

export interface IApplyEmployeeScheduleShift extends IAssignEmployeeScheduleShift {
    activityId: number,
}

export interface IBreakBase {
    dayId: string,
    segmentId: string,
    isPayed: boolean,
    type: number,
    durn: number,
}

export interface ILinearBreakDb extends IBreakBase {
    sort: number,
    leftOffset: null,
    rightOffset: null,
}

export interface ICustomBreakDb extends IBreakBase {
    sort: null,
    leftOffset: number,
    rightOffset: number,
}

export interface IBreakWithId {
    id: string,
}

export interface ILinearBreak extends ILinearBreakDb, IBreakWithId {}
export interface ICustomBreak extends ICustomBreakDb, IBreakWithId {}

export type TBreakDb = ILinearBreakDb | ICustomBreakDb;
export type BreakTypes = ILinearBreak | ICustomBreak;
export type TBreak = BreakTypes;

export interface IOutputBreak_NEEDFIX {
    payed: boolean;
    duration: number;
}

export interface IOutputLinearBreak extends Omit<ILinearBreakDb, 'durn' | 'isPayed'>, IOutputBreak_NEEDFIX {}
export interface IOutputCustomBreak extends Omit<ICustomBreakDb, 'durn' | 'isPayed'>, IOutputBreak_NEEDFIX {}

export type TOutputBreak = IOutputLinearBreak | IOutputCustomBreak;

export interface ISurfaceBreak {
    type: number,
    title: string,
    durn: number,
}

export async function getSpecialMarks(auth: IAuthState<IAuthData>, employees: IEmployee[], signal?: AbortSignal): Promise<ISpecialMarkEx[]> {
    await auth.throwIfNotLogedInAndLogout(signal);
    return [];
}

export async function getEmploSpecialMarks(auth: IAuthState<IAuthData>, employees: IEmployee[], signal?: AbortSignal): Promise<TEnabledSpecialMarks> {
    await auth.throwIfNotLogedInAndLogout(signal);
    return [];
}

export async function saveEmployeesSpecialMarks(auth: IAuthState<IAuthData>, changes: TSpecialMarksChanges, signal?: AbortSignal) {

    await auth.throwIfNotLogedInAndLogout(signal);

    const normalizeChanges: TSpecialMarksChangesToDb = changes.map(x => ({
        PID: x.PID,
        start: x.start.format(dateFormat),
        enabled: x.enabled,
        disabled: x.disabled,
    }));
    
    return await execute<void>({
        authData: auth.data,
        procedure: saveSpecialMarksChangesProcName,
        parameters: [
            new JSONQueryParameter("changeList", normalizeChanges),
        ]
    }, signal);
}

export async function saveSkillsList(auth: IAuthState<IAuthData>, changes: TSpecialMarksChanges, signal?: AbortSignal) {

    await auth.throwIfNotLogedInAndLogout(signal);

    const normalizeChanges: TSpecialMarksChangesToDb = changes.map(x => ({
        PID: x.PID,
        start: x.start.format(dateFormat),
        enabled: x.enabled,
        disabled: x.disabled,
    }));
    
    return await execute<void>({
        authData: auth.data,
        procedure: saveSkillsChangesProcName,
        parameters: [
            new JSONQueryParameter("changeList", normalizeChanges),
        ]
    }, signal);
}

export async function saveProductiveActList(auth: IAuthState<IAuthData>, data: IApplyEmployeeScheduleShift, signal?: AbortSignal) {
    
    await auth.throwIfNotLogedInAndLogout(signal);
    
    return await execute<void>({
        authData: auth.data,
        procedure: saveProductiveActListProcName,
        parameters: [
            new IntQueryParameter("SchAct_ID", data.activityId),
            new StringQueryParameter("PID_selected", data.employeePID),
            new DateQueryParameter("SD", data.startDate),
        ]
    }, signal);
}

export interface ICreateScheduleShiftScheme {
    Caption: string,
    SSS_WDType_ID: RotationType,
    TZ_ID: string,  
    SSS_Parent_ID: number | null,
    PGList: number[] | null | undefined
}

export async function getScheduleShiftSchemeList(auth: IAuthState<IAuthData>, signal?: AbortSignal) {

    await auth.throwIfNotLogedInAndLogout(signal);
    
    const scheduleShiftSchemeList = await execute<IScheduleShiftScheme[]>({
        authData: auth.data,
        procedure: getScheduleShiftSchemeListProcName,
    }, signal);

    return scheduleShiftSchemeList;
}

export async function addScheduleShiftScheme(auth: IAuthState<IAuthData>, data: ICreateScheduleShiftScheme, signal?: AbortSignal) {   

    await auth.throwIfNotLogedInAndLogout(signal);
    
    const newSheduleShiftScheme = await execute<IScheduleShiftScheme[]>({
        authData: auth.data,
        procedure: addScheduleShiftSchemeProcName,
        parameters: [
            new IntQueryParameter("SSS_Parent_ID", data.SSS_Parent_ID),
            new IntQueryParameter("SSS_WDType_ID", data.SSS_WDType_ID),
            new StringQueryParameter("TZ_ID", data.TZ_ID),
            new StringQueryParameter("Caption", data.Caption),
            new IntArrayQueryParameter("PGList", data.PGList || []),
        ]
    }, signal);

    if (!newSheduleShiftScheme || newSheduleShiftScheme.length !== 1) {
        throw new InvalidDataException(`Procedure ${addScheduleShiftSchemeProcName} return invalid result.`);
    }

    return newSheduleShiftScheme[0];
    
}

export async function getBasicEmployeeList(auth: IAuthState<IAuthData>, signal?: AbortSignal) {
    await auth.throwIfNotLogedInAndLogout(signal);
    
    const employeeList = await execute<IBasicEmployee[]>({
        authData: auth.data,
        procedure: getBasicEmployeeListProcName, 
    }, signal);

    return employeeList;
}

export async function bindEmployeeShiftScheme(auth: IAuthState<IAuthData>, data: IBindEmployeeScheduleShift, signal?: AbortSignal) {
    
    await auth.throwIfNotLogedInAndLogout(signal);
    
    const result = await execute<void>({
        authData: auth.data,
        procedure: bindEmployeeShiftSchemeProcName,
        parameters: [
            new IntQueryParameter("SSS_ID", data.schemeId),
            new DateQueryParameter("SD", data.startDate),
            new StringQueryParameter("PID_selected", data.employeePID),
        ]
    }, signal);

    return result;
}

export async function applyEmployeeShiftScheme(auth: IAuthState<IAuthData>, data: IAssignEmployeeScheduleShift, signal?: AbortSignal) {

    await auth.throwIfNotLogedInAndLogout(signal);
    
    const result = await execute<void>({
        authData: auth.data,
        procedure: applyEmployeeShiftSchemeProcName,
        parameters: [
            new DateQueryParameter("gsd", data.startDate),
            new StringQueryParameter("PID_Agent", data.employeePID),
            new IntQueryParameter("ForceUpdate", 1),
        ]
    }, signal);

    return result;
}

export function isLinearBreak(br: TBreakDb): br is ILinearBreakDb
export function isLinearBreak(br: TBreak): br is ILinearBreak
export function isLinearBreak(br: TOutputBreak): br is IOutputLinearBreak
export function isLinearBreak(br: TBreakDb | TBreak | TOutputBreak) {
    return br.sort !== null;
}

export function normalizeCustomBreak<T extends ICustomBreakDb>(x: T, forDb: boolean): T {

    const newBreak = {...x};

    if (forDb) {

        if (x.rightOffset === x.leftOffset + x.durn) {
            newBreak.rightOffset = x.leftOffset;
        }
    }
    else {

        if (x.rightOffset === x.leftOffset) {
            newBreak.rightOffset = x.leftOffset + x.durn;
        }
    }

    return newBreak;
}

export function convertBreaksForDb(breaks: TBreak[]): TOutputBreak[] {

    const result: TOutputBreak[] = [];

    for (const breakItem of breaks) {

        if (isLinearBreak(breakItem)) {

            result.push({
                dayId: breakItem.dayId,
                segmentId: breakItem.segmentId,
                sort: breakItem.sort,
                type: breakItem.type,
                duration: breakItem.durn,
                payed: breakItem.isPayed,
                leftOffset: null,
                rightOffset: null
            });
        }
        else {
            
            const normalizeBreak = normalizeCustomBreak(breakItem, true);

            result.push({
                dayId: normalizeBreak.dayId,
                segmentId: normalizeBreak.segmentId,
                sort: null,
                type: normalizeBreak.type,
                duration: normalizeBreak.durn,
                payed: normalizeBreak.isPayed,
                leftOffset: normalizeBreak.leftOffset,
                rightOffset: normalizeBreak.rightOffset
            });
        }
    }

    return result;
}

export async function getBreakList(
    auth: IAuthState<IAuthData>,
    PID: IEmployee["PID"],
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
    
    const response = await execute<TBreakDb[]>({
        authData: auth.data,
        procedure: getBreakSchemeSegmentProcName,
        parameters: [
            new StringQueryParameter("PID_selected", PID),
        ]
    }, signal);

    const result = response.map((x): TBreakDb => {
        
        if (isLinearBreak(x)) {
            return x;
        }

        return normalizeCustomBreak(x, false);
    });

    return result;
}

export async function getBreakSurfaceList(
    auth: IAuthState<IAuthData>,
    PID: IEmployee["PID"],
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
    
    const result = await execute<ISurfaceBreak[]>({
        authData: auth.data,
        procedure: getBreakSchemeListProcName,
        parameters: [
            new StringQueryParameter("PID_selected", PID),
        ]
    }, signal);

    return result;
}

export interface ScheduleShiftBreakInfo {
    breakDurn_max: number,
    breakDurn_min: number,
    breakOffset_max: number,
    breakOffset_min: number,
    lunchDurn_max: number,
    lunchDurn_min: number,
    shiftEndOffset: number,
    shiftStartOffset: number,
}

export async function getScheduleShiftBreakInfo(
    auth: IAuthState<IAuthData>,
    PID: IEmployee["PID"],
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
    
    const result = await execute<any>({
        authData: auth.data,
        procedure: getScheduleShiftBreakInfoProcName,
        parameters: [
            new StringQueryParameter("PID_selected", PID),
        ]
    }, signal);

    return JSON.parse(result[0]["Column1"]) as ScheduleShiftBreakInfo[];
}

export enum VacationType  {
    Default = 0,
    Additional = 1,
    SickLeave = 2,
    StudyLeave = 3, 
};

export interface IVacationBaseDb extends IWithEmployable {
    id: string,
    // type: VacationType,
    startDate: string,
    duration: number,
    comment?: string,
    canEdit: boolean,
    canDelete: boolean,
}

export interface IVacationBase extends Omit<IVacationBaseDb, 'startDate'> {
    startDate: moment.Moment | null,
}

export interface IVacationDb extends IVacationBaseDb {
    completedVacationDuration: number,
    completedVacationDate: string | null,
    unusedDays: number,
}

export interface IVacation extends IVacationBase, Omit<IVacationDb, 'completedVacationDate' | 'startDate'> {
    completedVacationDate: moment.Moment | null,
}

export interface IStatusDb {
    id: number,
    title: string,
    selected: boolean,
    canedit: boolean,
}

export interface ITypeDb {
    id: number,
    title: string, 
    selected: boolean,
}

export interface IVacationType {
    value: number,
    label: string,
    selected: boolean
}

export interface IVacationStatus {
    value: number,
    label: string,
    selected: boolean
}

/**
 * Запланированный статус отпуска.
 * 
 * Для скрытия отображения кнопки сохрания в модальном окне редактирования и создания отпусков.
 */
export const plannedVacationStatusId = 1;

export async function getVacationInfo(
    auth: IAuthState<IAuthData>,
    PID: IEmployee["PID"],
    start: moment.Moment,
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
    
    const result = await execute<any[]>({
        authData: auth.data,
        procedure: getVacationInfoProcName,
        parameters: [
            new StringQueryParameter("PID_selected", PID),
            new DateQueryParameter("DT", start),
        ]
    }, signal);

    const mainDb       = JSON.parse(result[0].main_json) as IVacationDb[];
    const statusListDb = JSON.parse(result[0].status_json) as IStatusDb[];
    const typeListDb   = JSON.parse(result[0].types_json) as ITypeDb[];

    if (mainDb.length !== 1) {
        throw new InvalidDataException("От базы полученые не коректные данные.");
    }

    const main: IVacation[] = mainDb.map(x => ({
        ...x,
        startDate: x.startDate === null ? null : moment(x.startDate),
        completedVacationDate: !x.completedVacationDate ? null : moment(x.completedVacationDate),
    }));

    const statusList: IVacationStatus[] = statusListDb.map(x => ({
        value: x.id,
        label: x.title,
        selected: x.selected
    }));

    const typeList = typeListDb.map(x => ({
            value: x.id,
            label: x.title,
            selected: x.selected
    }));

    return {
        main: main[0],
        statusList,
        typeList,
    };
}

export interface IVacationEditorValues {
    type: IVacationType['value'],
    status: IVacationStatus['value'],
    employee: IEmployee['PID'],
    period: RangeValue<moment.Moment>,
    duration: number,
    comment: string,
    vacationId: string,
    // range: number[],
    // rangeLeft: number,
    // rangeRight: number,
}

export async function saveVacation(
    auth: IAuthState<IAuthData>,
    data: IVacationEditorValues,
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
    
    if (!data.period) {
        throw new InvalidDataException("Period unset");
    }

    const result = await execute<void>({
        authData: auth.data,
        procedure: saveVacationProcName,
        parameters: [
            new StringQueryParameter("PID_selected", data.employee),
            new StringQueryParameter("LV_ID", data.vacationId),
            new IntQueryParameter("LVSt_ID", data.status),
            new IntQueryParameter("LVTp_ID ", data.type),
            new DateQueryParameter("SD", data.period[0]),
            new IntQueryParameter("Durn", data.duration),
            new StringQueryParameter("Comment", data.comment || null),
        ]
    }, signal);

    return result;
}

export async function deleteVacation(
    auth: IAuthState<IAuthData>,
    id: IVacation['id'],
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);

    const result = await execute<void>({
        authData: auth.data,
        procedure: deleteVacationProcName,
        parameters: [
            new StringQueryParameter("LV_ID", id),
        ]
    }, signal);

    return result;
}

export async function getNotificationList(auth: IAuthState<IAuthData>, signal?: AbortSignal) {
    
    await auth.throwIfNotLogedInAndLogout(signal);
    
    const notificationList = await execute<INotification[]>({ 
        authData: auth.data,
        procedure: getNotificationListProcName, 
    }, signal);

    return notificationList;
}

export interface ISickDb {
    id: string,
    startDate: string,
    duration: number,
    completedSickDuration: number,
    comment: string | undefined,
    canEdit: boolean,
    canDelete: boolean,
}

export interface ISick extends Omit<ISickDb, 'startDate'> {
    startDate: moment.Moment | null,
}

export interface ISickEditorValues {
    sickId: string,
    period: RangeValue<moment.Moment>,
    employee: string,
    duration: number,
    comment: string,
    type: number,
}

export async function getSickInfo(
    auth: IAuthState<IAuthData>,
    PID: IEmployee["PID"],
    start: moment.Moment,
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
    
    const result = await execute<any[]>({
        authData: auth.data,
        procedure: initEditSickProcName,
        parameters: [
            new StringQueryParameter("PID_selected", PID),
            new DateQueryParameter("DT", start),
        ]
    }, signal);

    const mainDb     = JSON.parse(result[0].main_json) as ISickDb[];
    const typeListDb = JSON.parse(result[0].types_json) as ITypeDb[];

    if (mainDb.length !== 1) {
        throw new InvalidDataException("От базы получены не коректные данные.");
    }

    const main: ISick[] = mainDb.map(x => ({
        ...x,
        startDate: x.startDate === null ? null : moment(x.startDate),
        //completedVacationDate: !x.completedVacationDate ? null : moment(x.completedVacationDate),
    }));

    const typeList = typeListDb.map(x => ({
        value: x.id,
        label: x.title,
        selected: x.selected
    }));

    return {
        main: main[0],
        typeList,
    };
}

export async function saveSick(
    auth: IAuthState<IAuthData>,
    data: ISickEditorValues,
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);
    
    if (!data.period) {
        throw new InvalidDataException("Period unset");
    }

    const result = await execute<void>({
        authData: auth.data,
        procedure: saveEditSickProcName,
        parameters: [
            new StringQueryParameter("PID_selected", data.employee),
            new StringQueryParameter("SK_ID", data.sickId),
            new IntQueryParameter("SKTp_ID", data.type),
            new DateQueryParameter("SD", data.period[0]),
            new IntQueryParameter("Durn", data.duration),
            new StringQueryParameter("Comment", data.comment || null),
        ]
    }, signal);

    return result;
}

export async function deleteSick(
    auth: IAuthState<IAuthData>,
    id: ISick['id'],
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);

    const result = await execute<void>({
        authData: auth.data,
        procedure: deleteSickProcName,
        parameters: [
            new StringQueryParameter("SK_ID", id),
        ]
    }, signal);

    return result;
}

export interface IReportTreeNode {
    id: number,
    parentId: number,
    caption: string,
    procName: null,
}

export interface IReportTreeLeaf {
    id: number,
    parentId: number,
    caption: string,
    procName: string
}

export async function getReportListWithoutParams(
    auth: IAuthState<IAuthData>,
    signal?: AbortSignal
) {
    await auth.throwIfNotLogedInAndLogout(signal);

    const result = await execute<Array<IReportTreeNode | IReportTreeLeaf>>({
        authData: auth.data,
        procedure: getReportListWithoutParamsProcName,
    }, signal);

    return result;
}