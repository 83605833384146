export const canvas = document && document.createElement("canvas");
export const ctx = canvas && canvas.getContext("2d", {
    willReadFrequently: true
});

const textColors: Record<string, string> = {};

/**
 * Calculates the text color to be used with a given color (black or white)
 * @hidden
 * @param color
 */
export function getTextColor(color: string): string {

    if (!ctx || !color) {
        return "#000";
    }

    if (textColors[color]) {
        return textColors[color]
    }

    ctx.fillStyle = color;
    ctx.fillRect(0, 0, 1, 1);

    const rgb = ctx.getImageData(0, 0, 1, 1).data;
    const delta = +rgb[0] * .299 + +rgb[1] * .587 + +rgb[2] * .114;
    const textColor = delta < 130 ? "#fff" : "#000";

    textColors[color] = textColor;
    return textColor;
}

let size: number = -1;

// This utility copied from "dom-helpers" package.
export function getScrollbarSize(recalculate: boolean = false): number {

    if (size === -1 || recalculate) {

        const div = document.createElement('div');
        const style = div.style;

        style.width = '50px';
        style.height = '50px';
        style.overflow = 'scroll';
  
        document.body.appendChild(div);
  
        size = div.offsetWidth - div.clientWidth;
  
        document.body.removeChild(div);
    }
  
    return size;
}

export type RTLOffsetType =
  | 'negative'
  | 'positive-descending'
  | 'positive-ascending';

let cachedRTLResult: RTLOffsetType | null = null;

// TRICKY According to the spec, scrollLeft should be negative for RTL aligned elements.
// Chrome does not seem to adhere; its scrollLeft values are positive (measured relative to the left).
// Safari's elastic bounce makes detecting this even more complicated wrt potential false positives.
// The safest way to check this is to intentionally set a negative offset,
// and then verify that the subsequent "scroll" event matches the negative offset.
// If it does not match, then we can assume a non-standard RTL scroll implementation.
export function getRTLOffsetType(recalculate: boolean = false): RTLOffsetType {

    if (cachedRTLResult === null || recalculate) {
        const outerDiv = document.createElement('div');
        const outerStyle = outerDiv.style;
        outerStyle.width = '50px';
        outerStyle.height = '50px';
        outerStyle.overflow = 'scroll';
        outerStyle.direction = 'rtl';

        const innerDiv = document.createElement('div');
        const innerStyle = innerDiv.style;
        innerStyle.width = '100px';
        innerStyle.height = '100px';

        outerDiv.appendChild(innerDiv);

        document.body.appendChild(outerDiv);

        if (outerDiv.scrollLeft > 0) {
            cachedRTLResult = 'positive-descending';
        } else {
            outerDiv.scrollLeft = 1;
            if (outerDiv.scrollLeft === 0) {
                cachedRTLResult = 'negative';
            } else {
                cachedRTLResult = 'positive-ascending';
            }
        }

        document.body.removeChild(outerDiv);

        return cachedRTLResult;
    }

    return cachedRTLResult;
}