import { Ref, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { IOriginalActivity, IScheduleBoardEmployee, IScheduleBoardFilter, InfoblockType } from "../../Entities/Database";
import { ILoadListState } from "../../Reducer/LoadListReducer";
import { Chart, RecordItemType } from "../ScheduleIntervalBoard/chart";
import { Button, Checkbox, CheckboxRef, Col, Collapse, Popconfirm, Row, Space, Tag, message } from "antd";
import { infoBlockCollapse, infoblockCollapsePanelKey } from "../ScheduleIntervalBoard/helpers";
import { defaultMaxChartInfoblockContentHeight, defaultMinChartInfoblockContentHeight, infoblockRowHeight, scrollWidth } from "../ScheduleIntervalBoard";
import { AppstoreOutlined, CheckOutlined, RiseOutlined } from "@ant-design/icons";
import { delay, doOrAbort, ignoreSelectEvent, isArray } from "../../System/Utils";
import { embedChartItems, normalizeChartItems } from "../ScheduleIntervalBoard/utils";
import { ILoaderInitProps, useListLoader } from "../../Hooks/useListLoader";
import { IGeneratedData, applyGenerateData, backendUrls, getForcastAndActivities } from "../../Entities/BackendGenerator";
import ScheduleGeneratorIntervalEditor from "./interval-editor";
import { Eventcalendar } from "../shared/lib";
import moment from "moment";
import useAuth from "../../Hooks/useAuth";
import { refSetter } from "../shared/helpers";
import useActual from "../../Hooks/useActual";
import { BackendResponseException } from "../../System/BackendResponseException";
import SelectServer from "./SelectServer";

import "./interval-editor.css";

const lang = {
    statistic: 'Статистика',
    viewTable: 'Таблица',
    viewGraph: 'График',
    fifteenMinutes: '15 Минут',
    sixtyMinutes: '60 Минут',
    update: 'Обновить',
    shiftFactor: 'К. Сменности',
    generate: 'Сгенерировать',
    accept: 'Применить',
    confirmAction: 'Подтвердите действие',
    applyGenData: 'Применить сгенерированные данные',
}

const loadConstructorProps: ILoaderInitProps<any> = {
    initialState: { loading: true }
}

const abortByDestroy = {};
const abortByUpdate = {};

export interface IScheduleGeneratorBoardProps {
    loading?: boolean,
    filter: IScheduleBoardFilter,
    width: number,
    height: number,
    date: moment.Moment,
    employees: ILoadListState<IScheduleBoardEmployee>,
    onFinish?: () => void,
}

export interface IScheduleGeneratorBoardWEProps extends IScheduleGeneratorBoardProps {
    calendarRef?: Ref<Eventcalendar>,
    openConfirmAcceptPop?: boolean,
    saving?: boolean,
    onRequestAcceptData?: () => void,
    onAcceptData?: (data: IGeneratedData, publish: boolean) => void,
    onCancelAcceptData?: () => void,
}

export function ScheduleGeneratorBoardWE({
    calendarRef,
    openConfirmAcceptPop,
    saving,
    onRequestAcceptData,
    onAcceptData,
    onCancelAcceptData,
    loading,
    filter,
    width,
    height,
    date,
    employees,
}: IScheduleGeneratorBoardWEProps) {

    const didMount = useRef(false);
    const calendarViewRef = useRef<Eventcalendar>(null);
    const chartBoardRef = useRef<HTMLDivElement>(null);
    const infoblockCollapsePanelRef = useRef<HTMLDivElement>(null);
    const outerTableGridRef = useRef<HTMLDivElement>(null)
    const collapseHeaderRef = useRef<HTMLDivElement>(null);
    const infoblockContentRef = useRef<HTMLDivElement>(null);
    const infoblockWrapperRef = useRef<HTMLDivElement>(null);
    const editorWrapperRef = useRef<HTMLDivElement>(null);
    const [generatorServer, setGeneratorServer] = useState(backendUrls['Gen2']);

    const auth = useAuth();
    const [hasForcastObj, setHasForcastObj] = useState(true);
    const [infoblockActiveKey, setInfoblockActiveKey] = useState<string[]>(() => hasForcastObj ? [infoblockCollapsePanelKey] : []);
    const [viewInfoblockType, setViewInfoblockType] = useState(InfoblockType.Chart);

    const [infoblockContentHeight, setInfoblockContentHeight] = useState(infoblockRowHeight * 5);
    const [editorHeight, setEditorHeight] = useState(height - infoblockContentHeight);

    const [minInfoblockContentHeight, setMinInfoblockContentHeight] = useState(defaultMinChartInfoblockContentHeight);
    const [maxInfoblockContentHeight, setMaxInfoblockContentHeight] = useState(defaultMaxChartInfoblockContentHeight);

    const [generatedData, setGeneratedData] = useState<Awaited<ReturnType<typeof getForcastAndActivities>> | null>();
    const [generatingData, setGeneratingData] = useState(false);
    const [globalAbortController] = useState(() => new AbortController());

    const [chartLines, chartDataSource] = useMemo(() => {
        
        if (!generatedData) {
            return [];
        }

        if (generatedData.activities.length > 0) {

            let min = generatedData.activities[0].end;
            let max = generatedData.activities[0].start;

            for (const item of generatedData.activities) {

                if (min > item.start) {
                    min = item.start;
                }

                if (max < item.end) {
                    max = item.end;
                }
            }
            
            const newForcast = embedChartItems(generatedData.graph, min, max);
            return normalizeChartItems(newForcast);
        }

        return normalizeChartItems(generatedData.graph);
        
    }, [generatedData]);

    const regenerateData = () => {

        const abortController = new AbortController();
        async function helper() {

            try {
                setGeneratingData(true);
                setGeneratedData(null);

                const data = await doOrAbort(
                    signal => getForcastAndActivities(auth, generatorServer, date, signal),
                    globalAbortController.signal,
                    abortController.signal
                );

                setGeneratedData(data);
                setGeneratingData(false);
            }
            catch (ex) {

                if (ex === abortByUpdate ||
                    ex === abortByDestroy) {
                    return;
                }

                setGeneratingData(false);

                console.error(ex);
                message.error('Не удалось сгенерировать данные');
            }
        }
        helper();
        return () => {
            abortController.abort(abortByUpdate);
        }
    }

    const handleInfoBlockCollapseChange = useCallback((key: string | string[]) => {
        
        const willCollapse = infoBlockCollapse(key);
        const normalizeKey = isArray(key) ? key : [key];
        const infoblockActiveKey = hasForcastObj ? normalizeKey : [];

        setInfoblockActiveKey(infoblockActiveKey);

        if(infoblockWrapperRef.current) {

            const icollapseHeaderRect = infoblockCollapsePanelRef
                                        .current
                                        ?.querySelector('.ant-collapse-header')
                                        ?.getBoundingClientRect();

            if(!icollapseHeaderRect) {
                return;
            }

            const collapseHeaderHeight = icollapseHeaderRect.height

            if (!hasForcastObj || !willCollapse) {
                const newHeight = height - collapseHeaderHeight;
                setEditorHeight(newHeight);
                return;
            }

            if(viewInfoblockType === InfoblockType.Table) {
                // TODO calculate table header height
                const newHeight = height - collapseHeaderHeight - infoblockRowHeight - infoblockContentHeight
                setEditorHeight(newHeight);
            }
            else if(infoblockContentRef.current) {
                const newHeight = height - collapseHeaderHeight - infoblockContentHeight;
                setEditorHeight(newHeight);
            }
        }

    }, [height,
        hasForcastObj,
        viewInfoblockType,
        infoblockContentHeight
    ]);

    const updateInfoblockHeightAndCalcEditorHeight = useCallback((
        newInfoblockHeight: number,
        minInfoBlockHeight: number,
        maxInfoBlockHeight: number
    ) => {

        const normalizeHeight = Math.max(minInfoBlockHeight, Math.min(maxInfoBlockHeight, newInfoblockHeight));

        setInfoblockContentHeight(normalizeHeight);

        if(infoblockWrapperRef.current) {

            const infoblockWrapperRect = infoblockWrapperRef.current.getBoundingClientRect();

            if(viewInfoblockType === InfoblockType.Table
            && outerTableGridRef.current) {
                const outerTableGridRect = outerTableGridRef.current.getBoundingClientRect();
                const gridTopOffset = outerTableGridRect.top - infoblockWrapperRect.top;
                const newHeight = hasForcastObj
                                ? height - gridTopOffset - normalizeHeight
                                : height - gridTopOffset;
                
                setEditorHeight(newHeight);
            }
            else if(infoblockContentRef.current) {

                const infoblockContentRect = infoblockContentRef.current.getBoundingClientRect();
                const collapseHeaderHeight = infoblockContentRect.top - infoblockWrapperRect.top;
                const newHeight = hasForcastObj
                                ? height - collapseHeaderHeight - normalizeHeight
                                : height - collapseHeaderHeight;
                
                setEditorHeight(newHeight);
            }
        }

    }, [height, viewInfoblockType]);

    const calculateAndUpdateEditorHeight = useCallback(() => {
        
        if(infoblockWrapperRef.current
        && editorWrapperRef.current) {

            const infoblockWrapperRect = infoblockWrapperRef.current.getBoundingClientRect();
            const editorWrapperRect    = editorWrapperRef.current.getBoundingClientRect();
            const nextInfoBlockHeight  = editorWrapperRect.top - infoblockWrapperRect.top;
            const newHeight            = height - nextInfoBlockHeight;

            setEditorHeight(newHeight);
        }
        
    }, [height]);

    const mouseDownEventHandler = useCallback((mouseDownEvent: React.MouseEvent) => {

        const startSize = infoblockContentHeight;
        const startPosition = { x: mouseDownEvent.pageX, y: mouseDownEvent.pageY };

        const updateHeight = (mouseMoveEvent: MouseEvent) => {

            const tmpHeight = startSize - startPosition.y + mouseMoveEvent.pageY;
            const stepHeight = Math.floor(tmpHeight / infoblockRowHeight) * infoblockRowHeight + scrollWidth;

            updateInfoblockHeightAndCalcEditorHeight(
                stepHeight,
                minInfoblockContentHeight,
                maxInfoblockContentHeight
            );
        }

        const onMouseMove = (mouseMoveEvent: MouseEvent) => {
            ignoreSelectEvent(mouseMoveEvent);
            updateHeight(mouseMoveEvent);
        }

        const onMouseUp = () => {
            document.removeEventListener("mousemove", onMouseMove);
        }
        
        document.addEventListener("mousemove", onMouseMove);
        document.addEventListener("mouseup", onMouseUp, { once: true });

    }, [height,
        updateInfoblockHeightAndCalcEditorHeight,
        minInfoblockContentHeight,
        maxInfoblockContentHeight,
        infoblockContentHeight,
        hasForcastObj
    ]);

    useEffect(() => {

        if (hasForcastObj
        && viewInfoblockType === InfoblockType.Chart) {
            setMinInfoblockContentHeight(defaultMinChartInfoblockContentHeight);
            setMaxInfoblockContentHeight(defaultMaxChartInfoblockContentHeight);
        }

    }, [viewInfoblockType]);

    useEffect(() => {

        updateInfoblockHeightAndCalcEditorHeight(
            infoblockContentHeight,
            minInfoblockContentHeight,
            maxInfoblockContentHeight
        );

    }, [minInfoblockContentHeight, maxInfoblockContentHeight]);

    useEffect(
        calculateAndUpdateEditorHeight,
        [height, hasForcastObj, viewInfoblockType]
    );

    useEffect(() => {

        if (calendarViewRef.current && chartBoardRef) {

            const calendarRoot = calendarViewRef.current.nativeElement;
            const timelineGridEl = calendarRoot.querySelector(".mbsc-timeline .mbsc-timeline-grid-scroll");
            const chartBoardEl = chartBoardRef.current;

            if (!timelineGridEl || !chartBoardEl) {
                return;
            }

            let currScrollLeft = -1;

            const scrollTimelineHandler = function (this: HTMLElement, ev: Event) {

                currScrollLeft = timelineGridEl.scrollLeft;

                if (chartBoardEl.scrollLeft !== currScrollLeft) {
                    chartBoardEl.scrollLeft = currScrollLeft;
                }
            }

            const scrollChartHandler = function (this: HTMLElement, ev: Event) {

                currScrollLeft = chartBoardEl.scrollLeft;

                if (timelineGridEl.scrollLeft !== currScrollLeft) {
                    timelineGridEl.scrollLeft = currScrollLeft;
                }
            }

            chartBoardEl.scrollLeft = timelineGridEl.scrollLeft;

            timelineGridEl.addEventListener('scroll', scrollTimelineHandler, false);
            chartBoardEl.addEventListener('scroll', scrollChartHandler, false);

            return () => {
                timelineGridEl?.removeEventListener('scroll', scrollTimelineHandler);
                chartBoardEl?.removeEventListener('scroll', scrollChartHandler);
            }
        }

    }, []);

    useEffect(regenerateData, [generatorServer]);
    useEffect(() => {
        didMount.current = true;
        return () => {
            didMount.current = false;
            globalAbortController.abort(abortByDestroy);
        }
    }, []);
    
    const doPublishCheckboxRef = useRef<CheckboxRef>(null);

    return (
        <>
            <div
                ref={infoblockWrapperRef}
                className="interval-activity-info-block-wrapper"
            >
                <Collapse
                    className="interval-activity-info-block-collapse"
                    activeKey={infoblockActiveKey}
                    collapsible={!hasForcastObj ? 'disabled' : undefined}
                    bordered={false}
                    onChange={handleInfoBlockCollapseChange}
                    items={[
                        {
                            key: infoblockCollapsePanelKey,
                            ref: infoblockCollapsePanelRef,
                            label: (
                                <div ref={collapseHeaderRef}>
                                    <Row
                                        gutter={[16, 16]}
                                        style={{
                                            width: '100%'
                                        }}
                                    >
                                        <Col>
                                            {hasForcastObj && viewInfoblockType === InfoblockType.Chart &&
                                            <Space
                                                size={12}
                                                align="end"
                                            >
                                                <Popconfirm
                                                    title={lang.confirmAction}
                                                    description={
                                                    <>
                                                        <p style={{ marginTop: 5, marginBottom: 5 }}>{lang.applyGenData}</p>
                                                        <Checkbox
                                                            ref={doPublishCheckboxRef}
                                                            title="Опубликовуать изменения"
                                                            defaultChecked={true}
                                                        >
                                                            Опубликовать
                                                        </Checkbox>
                                                    </>}
                                                    open={openConfirmAcceptPop}
                                                    onPopupClick={(e) => { e?.stopPropagation(); }}
                                                    onConfirm={(e) => {
                                                        e?.stopPropagation();
                                                        if (generatedData && !generatingData) {
                                                            const publish = doPublishCheckboxRef.current?.input?.checked ?? false;
                                                            if (onAcceptData) {
                                                                onAcceptData(generatedData, publish);
                                                            }
                                                        }
                                                    }}
                                                    okButtonProps={{ loading: saving }}
                                                    onCancel={(e) => {
                                                        e?.stopPropagation();
                                                        onCancelAcceptData && onCancelAcceptData();
                                                    }}
                                                >
                                                    <Button
                                                        size="small"
                                                        icon={<CheckOutlined />}
                                                        disabled={!generatedData || !generatedData.activities || !generatedData.activities.length || generatingData}
                                                        onClick={e => {
                                                            e.stopPropagation();
                                                            if (generatedData && !generatingData) {
                                                                onRequestAcceptData && onRequestAcceptData();
                                                            }
                                                        }}
                                                    >
                                                        {lang.accept}
                                                    </Button>
                                                </Popconfirm>
                                                <Button
                                                    size="small"
                                                    icon={<RiseOutlined />}
                                                    disabled={generatingData}
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        regenerateData();
                                                    }}
                                                >
                                                    {lang.generate}
                                                </Button>

                                                <Button
                                                    size="small"
                                                    icon={<AppstoreOutlined />}
                                                    disabled={!generatedData || !generatedData.activities || !generatedData.activities.length || generatingData}
                                                    onClick={(e) => {

                                                        e.stopPropagation();

                                                        let line0 = 'Time';
                                                        let line1 = 'Forecast';
                                                        let line2 = 'Shifts';
                                                        let line3 = 'Schedule';
                                                        let line4 = 'Breaks';

                                                        if (chartDataSource) {
                                                            for(const item of chartDataSource) {
                                                                line0 += ';' + item.dateVal.format('HH:mm');
                                                                line1 += ';' + item[RecordItemType.Forecast];
                                                                line2 += ';' + (item[RecordItemType.Schedule] + item[RecordItemType.Breaks]);
                                                                line3 += ';' + item[RecordItemType.Schedule];
                                                                line4 += ';' + item[RecordItemType.Breaks];
                                                            }
                                                        }

                                                        const csvContent = `data:text/csv;charset=utf-8,${line0}\n${line1}\n${line2}\n${line3}\n${line4}`;
                                                        const encodedUri = encodeURI(csvContent);
                                                        const link = document.createElement("a");

                                                        link.setAttribute("href", encodedUri);
                                                        link.setAttribute("download", "chart.csv");
                                                        document.body.appendChild(link);

                                                        link.click();

                                                        setTimeout(() => document.body.removeChild(link), 1000);
                                                    }}
                                                >
                                                    Скачать матрицу
                                                </Button>
                                                <SelectServer
                                                    size="small"
                                                    defaultValue={generatorServer}
                                                    onChange={setGeneratorServer}
                                                    onClick={(e) => e.stopPropagation()}
                                                />
                                            </Space>}
                                        </Col>
                                    </Row>
                                </div>
                            ),
                            children: (
                                <div
                                    ref={infoblockContentRef}
                                    className="full-ant-collapse-content-box"
                                >
                                    {hasForcastObj &&
                                    <>
                                        {viewInfoblockType === InfoblockType.Chart &&
                                        <Chart
                                            boardRef={chartBoardRef}
                                            width={width}
                                            height={infoblockContentHeight}
                                            loading={loading || generatingData}
                                            lines={chartLines}
                                            dataSource={chartDataSource}
                                        />}
                                        <div
                                            className="interval-activity-info-block-resizer"
                                            onMouseDown={mouseDownEventHandler}
                                        />
                                    </>}
                                </div>
                            )
                        }
                    ]}
                />
            </div>
            <div
                ref={editorWrapperRef}
                className="interval-activity-editor-wrapper"
            >
                <ScheduleGeneratorIntervalEditor
                    editorRef={refSetter(calendarRef, calendarViewRef)}
                    loading={generatingData}
                    filter={filter}
                    date={date}
                    disableControl={false}
                    employees={employees}
                    data={generatedData?.activities || emptyErr}
                    width={width}
                    height={editorHeight}
                    //onScroll={onScroll}
                />
            </div>
        </>
    )
}

export function ScheduleGeneratorBoard(props: IScheduleGeneratorBoardProps) {

    const calendarViewRef = useRef<Eventcalendar>(null);
    const [open, setOpen] = useState(false);
    const [saving, setSaving] = useState(false);
    const auth = useAuth();
    const onFinishRef = useActual(props.onFinish);

    const save = async (data: IGeneratedData, publish: boolean) => {

        try {
            const abortController = new AbortController();
            const signal = abortController.signal;

            await delay(1, { signal });
            await applyGenerateData(auth, data, publish, signal);
            
            setOpen(false);

            if (onFinishRef.current) {
                onFinishRef.current();
            }
        }
        catch (ex) {
            console.error(ex);
            message.error(ex instanceof BackendResponseException ? ex.message : 'Не удалось применить сгенерированные данные');
        }
        finally {
            setSaving(false);
        }
    }

    const handleRequestAcceptData = () => {
        setOpen(true);
    }

    const handleAcceptData = (data: IGeneratedData, publish: boolean) => {
        setSaving(true);
        save(data, publish);
    }

    const onCancelAcceptData = () => {
        setOpen(false);
        setSaving(false);
    }

    return (
        <ScheduleGeneratorBoardWE
            {...props}
            calendarRef={calendarViewRef}
            openConfirmAcceptPop={open}
            saving={saving}
            onRequestAcceptData={handleRequestAcceptData}
            onAcceptData={handleAcceptData}
            onCancelAcceptData={onCancelAcceptData}
        />
    )
}

const emptyErr: IOriginalActivity[] = [];

export default ScheduleGeneratorBoard;