import { useRef } from "react"

export function useActual<T>(value: T) {

    const buf = useRef(value);
          buf.current = value;
    
    return buf;
}

export default useActual