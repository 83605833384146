export interface ILeavePanelLocale {
    switchTitle: string,
    weekChartOption: string,
    dayChartOption: string,
}

export const leavePanelLocaleRu: ILeavePanelLocale = {
    switchTitle: 'График',
    weekChartOption: 'перейти к дневному',
    dayChartOption: 'перейти к недельному'
}