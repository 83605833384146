import { Card, Collapse, Form, Input, InputNumber, Select, Switch } from "antd";
import { RangeValue, nameof } from "../../System/Utils";
import { IEmployeeFilter, IValues, getEmployeeList } from "./index.db";
import { IEmployee, IEmployeeScheduleDay, IScheduleBoardFilter } from "../../Entities/Database";
import useEntityLoader from "../../Hooks/useEntityLoader";
import useAuth from "../../Hooks/useAuth";
import { useEffect, useState } from "react";
import moment from "moment";
import DatePicker from "../shared/DatePicker";
import { FormInstance } from "antd/lib";
import { RangePicker } from "../shared/TimePicker";
import { searchInValue } from "../../Reducer/SearchFilterReducer";
import { HeaderColumnType } from "../ScheduleBoard/CellContextMenu/helpers";
import { DownOutlined } from "@ant-design/icons";
import Filter from "./filter";

const filterCollapseItemKey = "filter";
const getFio = (employee: IEmployee) => {
    return `${employee.surname} ${employee.firstName} ${employee.secondName}`;
}

const getSF = (employee: IEmployee) => {
    return `${employee.surname} ${employee.firstName}`;
}

const filterKeys: string[] = [
    nameof<IValues>('fieldIds'),
    nameof<IValues>('skillIds'),
    nameof<IValues>('tagIds'),
    nameof<IValues>('crossEmploee')
];

type DisabledTime = (now: moment.Moment) => {
    disabledHours?: () => number[];
    disabledMinutes?: (selectedHour: number) => number[];
    disabledSeconds?: (selectedHour: number, selectedMinute: number) => number[];
    disabledMilliseconds?: (
      selectedHour: number,
      selectedMinute: number,
      selectedSecond: number,
    ) => number[];
};

const disabledAllHours: number[] = [];
const disabledAllMinutes: number[] = [];

for (let i = 0; i < 60; i++) {

    if (i < 25) {
        disabledAllHours.push(i);
    }

    disabledAllMinutes.push(i);
}

export interface ShiftExchangeProps {
    form: FormInstance<IValues>,
    item: IEmployeeScheduleDay | HeaderColumnType | undefined,
    filter: IScheduleBoardFilter,
    disabled?: boolean,
    onFinish?: (values: IValues) => void,
}

export default function ShiftExchangeForm({
    form,
    item,
    filter,
    disabled,
    onFinish,
}: ShiftExchangeProps) {
    
    const auth = useAuth();
    const [selectedEmployeeCount, setSelectedEmployeeCount] = useState(0);
    const [subFilter, setEmployeesFilter] = useState<IEmployeeFilter>();
    const [range, setRange] = useState<RangeValue<moment.Moment>>();
    const [collapseFilter, setCollapseFilter] = useState(false);
    const [disabledActualTo, setDisabledActualTo] = useState(() => (date: moment.Moment) => true);
    const [disabledActualToTime, setDisabledActualToTime] = useState<DisabledTime>(() => (now: moment.Moment) => ({ }));
    const [employeeListState, loadingEmployees, , abortLoadingEmployees] = useEntityLoader(signal => getEmployeeList(auth, range, subFilter, signal), [auth.data?.staticId, range, subFilter]);

    function handleChangeCollapse(key?: string[] | string) {
        setCollapseFilter(key === filterCollapseItemKey ? true : false);
    }

    function handleValuesChange(changedValues: any, values: IValues) {

        const keys = Object.keys(changedValues);

        if (keys.some(x => filterKeys.includes(x))) {
            setEmployeesFilter({
                projectGroupIds: values.projectGroupIds,
                forcastObjIds: values.forcastObjIds,
                fieldIds: values.fieldIds,
                skillIds: values.skillIds,
                tagIds: values.tagIds,
                crossEmploee: values.crossEmploee,
            });
        }

        setSelectedEmployeeCount(values.employees.length);

        if (!Object.hasOwn(changedValues, 'day') &&
            !Object.hasOwn(changedValues, 'interval')) {
            return;
        }

        const day           = values.day;
        const startInternal = values.interval ? values.interval[0] : null;
        const endInterval   = values.interval ? values.interval[1] : null;

        if (day && startInternal && endInterval) {

            const startOfDay = day.clone().startOf('day');

            const newStartInternal = startOfDay.clone();
            newStartInternal.hour(startInternal.hour());
            newStartInternal.minute(startInternal.minute());
            newStartInternal.second(0);
            newStartInternal.millisecond(0);

            const newEndInterval = startOfDay.clone();
            newEndInterval.hour(endInterval.hour());
            newEndInterval.minute(endInterval.minute());
            newEndInterval.second(0);
            newEndInterval.millisecond(0);

            if (+newEndInterval < +newStartInternal) {
                newEndInterval.add(1, 'day');
            }

            const interval: RangeValue<moment.Moment> = [newStartInternal, newEndInterval];

            const startIntervalHour = newStartInternal.hour();
            const startIntervalMinutes = newStartInternal.minute();
            const disabledHours: number[] = [];
            const disabledMinutes: number[] = [];

            for (let i = startIntervalHour + 1; i < 25; i++) {
                disabledHours.push(i);
            }

            for (let i = startIntervalMinutes + 1; i < 60; i++) {
                disabledMinutes.push(i);
            }

            form.setFieldValue(nameof<IValues>('interval'), interval);
            form.setFieldValue(nameof<IValues>('actualTo'), newStartInternal);
            setDisabledActualTo(() => (date: moment.Moment) => +date > +newStartInternal);
            setDisabledActualToTime(() => (now: moment.Moment) => {

                if (now < startOfDay) {
                    return {};
                }

                if (now <= newStartInternal) {

                    return {
                        disabledHours: () => disabledHours,
                        disabledMinutes: (selectedHour: number) => {
                            if (selectedHour < startIntervalHour) return [];
                            if (selectedHour > startIntervalHour) return disabledAllMinutes;
                            return disabledMinutes;
                        }
                    }
                }

                return {
                    disabledHours: () => disabledAllHours,
                    disabledMinutes: (selectedHour: number) => disabledAllMinutes,
                }
            });
            setRange(interval);
        }
    }

    useEffect(() => {

        const filterValues: IEmployeeFilter = {
            projectGroupIds: filter.projectGroupIds,
            forcastObjIds: filter.forcastObjIds,
            fieldIds: filter.fieldIds,
            skillIds: filter.skillIds,
            tagIds: filter.tagIds,
            crossEmploee: filter.crossEmploee,
        };

        form.setFieldsValue({
            day: undefined,
            interval: undefined,
            actualTo: undefined,
            employees: [],
            factor: 1,
            quantity: 1,
            comment: '',
            isTargeted: false,
            isAutoApprove: false,
            ...filterValues,
        });

        setEmployeesFilter(filterValues);

    }, []);

    useEffect(() => {

        const day = !item || moment.isMoment(item) ? item : item.date;
        form.setFieldValue(nameof((x: IValues) => x.day), day);

    }, [item]);

    useEffect(() => {
        loadingEmployees();
        return () => {
            abortLoadingEmployees(useEntityLoader.abortByMerge);
        }
    }, [range, subFilter]);

    useEffect(() => {

        // Выбираем всех доступных сотрудников, каждый раз как фильтр обновляется
        if (employeeListState.load && employeeListState.entity) {
            const pids = employeeListState.entity.map(x => x.PID);
            form.setFieldValue(nameof((x: IValues) => x.employees), pids);
            setSelectedEmployeeCount(pids.length);
        }

    }, [employeeListState.loading]);

    const suffix = (
        <>
            {employeeListState.entity && employeeListState.entity.length > 0 && <span>
                {selectedEmployeeCount} / {employeeListState.entity.length}
            </span>}
            <DownOutlined />
        </>
    );

    return (
        <>
            <Form<IValues>
                size="small"
                form={form}
                onValuesChange={handleValuesChange}
                onFinish={onFinish}
                disabled={disabled}
            >
                <Card
                    size="small"
                >
                    <Form.Item
                        name={nameof((x: IValues) => x.day)}
                        label="День"
                        labelCol={{ span: 7 }}
                        style={{ marginBottom: 10 }}
                    >
                        <DatePicker style={{width: '100%'}} />
                    </Form.Item>
                    <Form.Item
                        name={nameof((x: IValues) => x.interval)}
                        label="Интервал"
                        labelCol={{ span: 7 }}
                        style={{ marginBottom: 10 }}
                    >
                        <RangePicker format={"HH:mm"} order={false} minuteStep={5} showSecond={false} style={{width: '100%'}} needConfirm={false} />
                    </Form.Item>
                    <Form.Item
                        name={nameof((x: IValues) => x.actualTo)}
                        label="Актуально до"
                        labelCol={{ span: 7 }}
                        style={{ marginBottom: 10 }}
                    >
                        <DatePicker
                            showTime
                            showSecond={false}
                            minuteStep={5}
                            disabledDate={disabledActualTo}
                            disabledTime={disabledActualToTime}
                            hideDisabledOptions={true}
                            needConfirm={false}
                            style={{width: '100%'}}
                        />
                    </Form.Item>
                    <Form.Item
                        name={nameof((x: IValues) => x.quantity)}
                        label="Количество слотов"
                        labelCol={{ span: 7 }}
                        style={{ marginBottom: 10 }}
                    >
                        <InputNumber min={1} style={{width: '100%'}} />
                    </Form.Item>
                    <Form.Item
                        name={nameof((x: IValues) => x.factor)}
                        label="Коэффициент"
                        labelCol={{ span: 7 }}
                        style={{ marginBottom: 0 }}
                    >
                        <InputNumber min={1} max={5} step={0.01} style={{width: '100%'}} />
                    </Form.Item>
                </Card>
                <div style={{ marginBottom: 10 }} />
                <Collapse
                    size="small"
                    destroyInactivePanel={false}
                    collapsible="header"
                    onChange={handleChangeCollapse}
                    items={[{
                        key: filterCollapseItemKey,
                        label: 'Фильтр',
                        forceRender: true,
                        children: <Filter filter={filter} />,
                    }]}
                />
                <div style={{ marginBottom: 10 }} />
                <Form.Item
                    name={nameof((x: IValues) => x.employees)}
                    style={{ marginBottom: 10 }}
                >
                    <Select
                        mode="multiple"
                        placeholder="Подходящие сотрудники"
                        maxTagTextLength={40}
                        suffixIcon={suffix}
                        filterOption={(inputValue, option) => searchInValue(option?.label, inputValue)}
                        loading={employeeListState.loading}
                        options={employeeListState.load && employeeListState.entity ? employeeListState.entity.map(item => ({
                            value: item.PID, 
                            label: getSF(item),
                            title: getFio(item),
                        })): []}
                    />
                </Form.Item>
                <Form.Item
                    valuePropName="checked"
                    label="Ограничить список сотрудников"
                    name={nameof<IValues>('isTargeted')}
                    style={{ marginBottom: 10 }}
                >
                    <Switch style={{display: 'flex'}} />
                </Form.Item>
                <Form.Item
                    valuePropName="checked"
                    label="Согласование не требуется"
                    name={nameof<IValues>('isAutoApprove')}
                    style={{ marginBottom: 10 }}
                >
                    <Switch style={{display: 'flex'}} />
                </Form.Item>
                <Form.Item
                    name={nameof((x: IValues) => x.comment)}
                >
                    <Input.TextArea
                        placeholder="Комментарий"
                    />
                </Form.Item>
            </Form>
        </>
    );
}