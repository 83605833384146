import { Button, Card, Form, FormInstance, Input, Modal, Segmented, Select } from "antd";
import { useEffect, useMemo, useState } from "react";
import { dictionaryToOptions } from "../../shared/helpers";
import { useFilterDictionaryProvider } from "../../ScheduleBoardFilter/useFilterDictionaryProvider";
import { ICreateScheduleShiftScheme, IProjectGroupShortInfo, IScheduleShiftScheme, ITimeZone, RotationType } from "../../../Entities/Database";

import "./style.css";
import { nameof } from "../../../System/Utils";
import { scheduleShiftModalCopyTextLangRu, scheduleShiftModalErrorTextLangRu, scheduleShiftModalTitlesLangRu } from "./locale/ru";
import { getWDTypeTitle } from "../ScheduleShiftCard/helper";
import { BookOutlined } from "@ant-design/icons";
import { searchInValue } from "../../../Reducer/SearchFilterReducer";
import DraggableModal from "../../DraggableModal";

interface ICreateScheduleShiftSchemeModalTemplateProps {
    isModalOpen: boolean,
    handleOk: (data: any) => void,
    handleCancel: () => void,
    timezoneList: ITimeZone[],
    projectGroupList: IProjectGroupShortInfo[],
    isClone: boolean,
    loading: boolean,
    saving: boolean,
    scheme?: IScheduleShiftScheme,
    disable: boolean
};

const { Meta } = Card;

export function CreateScheduleShiftSchemeModalTemplate({
    isModalOpen, 
    handleOk, 
    handleCancel, 
    timezoneList, 
    isClone,
    projectGroupList,
    loading,
    saving,
    scheme,
    disable
}: ICreateScheduleShiftSchemeModalTemplateProps) {
    
    const [form] = Form.useForm<ICreateScheduleShiftScheme>();
    const [pgListOpen, setPgListOpen] = useState(false);

    const handleModalFinish = () => {
        form.submit();
    };

    const handleFormFinish = (values: ICreateScheduleShiftScheme) => {
        return handleOk(values); 
    };

    const onFinishFailed = (errorInfo: any) => {
        console.error('Failed:', errorInfo);
    };

    useEffect(() => {

        if (loading || !isModalOpen) {
            form.resetFields();
        }

    }, [loading, isModalOpen]);
 
    return (
        <DraggableModal 
            title={scheduleShiftModalTitlesLangRu.modalTitle} 
            open={isModalOpen} 
            onOk={handleModalFinish} 
            onCancel={handleCancel}
            cancelButtonProps={{disabled: saving}}
            width={361}
            className="scheduleModal__modal"
            footer={[
                <Button 
                    key="submit" 
                    onClick={handleModalFinish}
                    type="primary"
                    style={{borderRadius: "2px"}}
                    loading={loading || saving}
                    disabled={disable || loading || saving}
                >
                    OK
                </Button>
            ]}
        >
            <Form
                layout="vertical"
                form={form}
                onFinish={handleFormFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    label={scheduleShiftModalTitlesLangRu.scheduleShiftTitle}
                    name={nameof<ICreateScheduleShiftScheme>("Caption")}
                    initialValue={isClone ? scheme?.title : ""}
                    rules={[
                        {
                            required: true, 
                            message: scheduleShiftModalErrorTextLangRu.title
                        }
                    ]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={scheduleShiftModalTitlesLangRu.rotationType}
                    name={nameof<ICreateScheduleShiftScheme>("SSS_WDType_ID")}
                    initialValue={isClone ? scheme?.sss_WDType_ID:  RotationType.Month}
                    required
                >
                    <Segmented 
                        className="scheduleModal__segmented"
                        style={{width: "100%"}}  
                        options={[
                            {
                                label: getWDTypeTitle(RotationType.Month),
                                value: RotationType.Month,
                            }, 
                            {
                                label: getWDTypeTitle(RotationType.Week),
                                value: RotationType.Week,
                            }
                        ]} 
                    />
                </Form.Item>

                <Form.Item
                    label={scheduleShiftModalTitlesLangRu.projectGroup}
                    name={nameof<ICreateScheduleShiftScheme>("PGList")}
                    rules={[
                        {
                            required: true, 
                            message: scheduleShiftModalErrorTextLangRu.projectGroup
                        }
                    ]}
                >
                    <Select
                        mode="multiple"
                        open={pgListOpen}
                        allowClear
                        style={{width: "100%"}}    
                        showSearch
                        loading={loading}
                        filterOption={(inputValue, option) => {
                            return searchInValue(option?.label, inputValue)
                        }}
                        options={!loading ? projectGroupList.map(item => {
                            return ({
                                value: item.id, 
                                label: item.title
                            })
                        }): []}
                        onSelect={() => setPgListOpen(false)}
                        onDropdownVisibleChange={(visible) => setPgListOpen(visible)}
                    /> 
                </Form.Item>

                <Form.Item
                    label={scheduleShiftModalTitlesLangRu.timezone}
                    name={nameof<ICreateScheduleShiftScheme>("TZ_ID")}
                    initialValue={scheme?.tz}
                    rules={[
                        {
                            required: true,
                            message: scheduleShiftModalErrorTextLangRu.timezone
                        }
                    ]}
                >
                    <Select
                        style={{width: "100%"}}    
                        showSearch
                        loading={loading}
                        filterOption={(inputValue, option) => {
                            return searchInValue(option?.label, inputValue)
                        }}
                        options={!loading ? timezoneList.map(item => {
                            return ({
                                value: item.id, 
                                label: item.title
                            })
                        }): []}
                    /> 
                </Form.Item>
            </Form>
            {isClone &&
                <Card
                    style={{marginTop: "10px"}}
                    className="copyCard__card"
                >  
                    <Meta
                        avatar={<BookOutlined  style={{color: "#f29e38", fontSize: "150%"}} /> }
                        description={scheduleShiftModalCopyTextLangRu.text}
                    />
                </Card>
            }
        </DraggableModal>
    )
}

