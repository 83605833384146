import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { classNames } from "../../../System/ClassNames";
import React from "react";
import { MenuOutlined } from "@ant-design/icons";

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    "data-row-key": string;
    children: React.ReactElement[],
}

export const Row = ({ children, ...props }: RowProps) => {

    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: props["data-row-key"],
    });
  
    const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
        transition,
        ...(isDragging ? { position: "relative", zIndex: 9999 } : {}),
    };
    
    return (
        <tr
            {...props}
            ref={setNodeRef}
            style={style}
            {...attributes}
            className={classNames(props.className, {
                "active": isDragging
            })}
        >
            
            {React.Children.map(children, (child: React.ReactElement) => {  

                if (child.key === "breakId") {
                    
                    return React.cloneElement(child, {
                        children: (
                            <MenuOutlined
                                ref={setActivatorNodeRef}
                                className="linearBreakListEditorItemMove"
                                {...listeners}
                            />
                        ),
                    });
                }
                return child;
            })}
        </tr>
    );
  };