import { Moment } from "moment";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import useEntityLoader from "../../../Hooks/useEntityLoader";
import { getDayGridLeaves, getWeekGridLeaves } from "../index.db";
import useAuth from "../../../Hooks/useAuth";
import { ColumnType, Grid, VirtualTable } from "antd-virtual-table";
import moment from "moment";
import { Mode } from "../LeavePanel";
import "./style.css";

const firstColumn: ColumnType<any> = {
    key: 'title',
    title: 'Параметр',
    dataIndex: 'title', 
    width: 190,
    fixed: 'left',
}

export interface IColumn {
    title: string | number, 
    dataIndex: string | number,
    key: string | number,
}

export interface TDataSourceItem {
    key: string | number,
}

export interface ILeaveUpGridProps {
    gridElRef: React.MutableRefObject<HTMLDivElement | null>,
    range: [Moment, Moment],
    firstColumnWidth?: number,
    itemColumnWidth?: number,
    width: number,
    height: number,
    mode: Mode,
    debugUpdate: () => void,
}

export function LeaveUpGrid({
    gridElRef,
    range,
    width,
    height,
    firstColumnWidth = 190,
    itemColumnWidth = 70,
    mode,
    debugUpdate
}: ILeaveUpGridProps) {

    const auth = useAuth();
    const gridRef = useRef<Grid>(null);

    const [weekGridLoadState, reloadWeekGrid] = useEntityLoader((signal) => 
        getWeekGridLeaves(auth, range, signal)
    , [auth.data?.staticId, range]);

    const [dayGridLoadState, reloadDayGrid] = useEntityLoader((signal) => 
        getDayGridLeaves(auth, range, signal)
    , [auth.data?.staticId, range]);

    const { columns, dataSource } = useMemo(() => {

        const weekData = weekGridLoadState.entity;
        const dayData  = dayGridLoadState.entity;

        const dataObj = {} as any;
        const columns = [{
            ...firstColumn,
            width: firstColumnWidth
        }];

        let title;

        if (mode === Mode.Week) {

            if (!weekData || weekData.length < 1) return { columns };

            title = weekData[0].title;

            const startOfWeekOfSd = range[0].clone().startOf('week');
            const startOfWeekOfEd = range[1].clone().startOf('week');
            const currentWeek     = startOfWeekOfSd.clone();
            const weeksStore      = weekData.reduce((prev, curr) => {
                const sdt = moment(curr.sdt);
                prev[+sdt] = curr;
                return prev;
            }, {} as any);

            while (currentWeek <= startOfWeekOfEd) {
                
                const key  = +currentWeek;
                const item = weeksStore[key];

                columns.push({
                    ellipsis: true,
                    title: (<span title={currentWeek.isoWeek() + ''}>{currentWeek.isoWeek()}</span>),
                    dataIndex: key,
                    key: key,
                    width: itemColumnWidth,
                });

                dataObj[key] = item?.value ?? '';

                currentWeek.add(1, 'week');
            }
        }
        else {

            if (!dayData || dayData.length < 1) return { columns };

            title = dayData[0].title;

            const startOfDayOfSd = range[0].clone().startOf('day');
            const startOfDayOfEd = range[1].clone().startOf('day');
            const currentDay     = startOfDayOfSd.clone();
            const daysStore      = dayData.reduce((prev, curr) => {
                const dt = moment(curr.dt);
                prev[+dt] = curr;
                return prev;
            }, {} as any);

            while (currentDay <= startOfDayOfEd) {

                const key  = +currentDay;
                const item = daysStore[key];

                columns.push({
                    ellipsis: true,
                    title: (<span title={currentDay.format("DD-MM-YYYY")}>{currentDay.format('D')}</span>),
                    dataIndex: key,
                    key: key,
                    width: itemColumnWidth,
                });

                dataObj[key] = item?.value ?? '';

                currentDay.add(1, 'day');
            }
        }

        dataObj.key = 1;
        dataObj.title = title;

        columns[columns.length - 1].width += 20;

        return {
            columns,
            dataSource: [dataObj],
        };

    }, [weekGridLoadState.load,
        weekGridLoadState.entity,
        dayGridLoadState.load,
        dayGridLoadState.entity,
        mode,
        firstColumnWidth,
        itemColumnWidth,
        range
    ]);

    const [tick, setTick] = useState(0);

    useEffect(() => {

        if (mode === Mode.Week)
            return reloadWeekGrid();

        return reloadDayGrid();
        
    }, [auth.data?.staticId, range, mode]);

    useEffect(() => {

        if (columns.length > 1) {
            setTick(3);
            debugUpdate();
        } else {
            setTick(0);
        }
    }, [columns]);

    return (<>
        {(tick === 3) &&
        <VirtualTable
            gridRef={gridRef}
            rerenderFixedColumnOnHorizontalScroll={false}
            className="leaves-up-grid"
            dataSource={dataSource}
            loading={!weekGridLoadState.load}
            columns={columns}
            outerGridRef={gridElRef}
            pagination={false}
            bordered
            size="small"
            scroll={{x: width, y: height}}
            rowHeight={25}
        />}
    </>);
}