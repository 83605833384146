import { classNames } from "../../../System/ClassNames";
import { IMarkersState } from "../helpers";
import { ColorList } from "./table";

import './marker-list.css';

export interface IMarkerListProps {
    className?: string,
    itemClassName?: string,
    list?: IMarkersState
}

export function MarkerList({
    className,
    itemClassName,
    list
}: IMarkerListProps) {

    const sortedItems = list ?
                        [...list.items].sort((a, b) => 
                        a.placement.toLowerCase().includes('border') ?
                        b.placement.toLowerCase().includes('border') ?
                        0 : -1 : 1) :
                        undefined

    return (
        <div
            className={classNames(
                className,
                "marker-list",
            )}
        >
            {sortedItems?.map((marker, index) => (
                <span
                    key={index}
                    className={classNames(
                        itemClassName,
                        "marker",
                        marker.placement,
                        ColorList.getBorderColorClassName(marker.borderColor),
                        ColorList.getBackgroundColorClassName(marker.backgroundColor)
                    )}
                />
            ))}
        </div>
    )
}