import { useCallback, useEffect, useRef, useState } from "react";
import { Moment } from "moment";
import { IEmployeeScheduleDay, IScheduleBoardEmployee, IScheduleBoardFilter, IDaysInfoblockItem, RecordType, IEmployeeScheduleDayMarker } from "../../Entities/Database";
import { ILoadListState } from "../../Reducer/LoadListReducer";
import { IScheduleEmployeeDataSourceItem, RecordItemElementEvent } from "./helpers";
import InfoBlockWithResizer, { IInfoBlockLocale, IInfoBlockWithResizerRef } from "./infoblock";
import EmployeeDayTableWithKeyboardControl, { IEmployeeDayTableWithKeyboardControlProps } from "./EmployeesDayDataTable/keyboard-control";
import { assignRef, refSetter } from "../shared/helpers";
import { Grid } from "antd-virtual-table";
import { useScrollingSynchronizer } from "./scrolling-synchronizer";
import { classNames } from "../../System/ClassNames";

import './style.css';

let tableCounter = 0;

const scrollbarWidth = 20;
const infoBlockRowHeight = 34;
const defaultInfoBlockHeight = infoBlockRowHeight * 5 + scrollbarWidth;
const employeeTableRowHeight = 46;
const defaultEmployeeTableHeight = employeeTableRowHeight * 10 + scrollbarWidth;

export type TEmployee = IScheduleBoardEmployee;
export type TItem = IEmployeeScheduleDay;
export type TItemMarker = IEmployeeScheduleDayMarker;
export type TInfoBlockItem = IDaysInfoblockItem;
export type TDataSourceItem = IScheduleEmployeeDataSourceItem;

export enum ViewInfoBlockType {
    Table,
    Chart
}

export interface IEmployeesDayDataBoardLocale {
    infoBlock?: IInfoBlockLocale,
    employeeDayDataTable?: IEmployeesDayDataBoardLocale
}

export interface IEmployeesDayDataBoardProps {
    elementsWithAllowedArrowControl?: IEmployeeDayTableWithKeyboardControlProps['elementsWithAllowedArrowControl'],
    width: number,
    height: number,
    firstColumnWidth?: number,
    dayColumnWidth?: number,
    loading?: boolean,
    locale?: IEmployeesDayDataBoardLocale,
    filter?: IScheduleBoardFilter,
    itemClassName?: string,
    employees?: ILoadListState<TEmployee>,
    items?: ILoadListState<TItem>,
    itemsGridRef?: React.Ref<Grid<TDataSourceItem>>,
    itemsGridFocuserRef?: React.Ref<HTMLDivElement>,
    itemsOuterGridRef?: React.Ref<HTMLElement>,
    itemsMarkers?: ILoadListState<TItemMarker>,
    infoBlockItems?: ILoadListState<TInfoBlockItem>,
    selectedItemsKeys?: Readonly<string[]>,
    recordType: RecordType,
    onChangeRecordType?: (type: RecordType) => void,
    onClickRecordItem?: (item: IEmployeeScheduleDay, event: RecordItemElementEvent<React.MouseEvent>) => void,
    onDoubleClickRecordItem?: (item: IEmployeeScheduleDay, event: RecordItemElementEvent<React.MouseEvent>) => void,
    onContextMenuRecordItem?: (item: IEmployeeScheduleDay, event: RecordItemElementEvent<React.MouseEvent>) => false | void,
    onClickHeaderColumn?: (date: Moment, event: React.MouseEvent) => void,
    onDoubleClickHeaderColumn?: (date: Moment, event: React.MouseEvent) => void,
    onContextMenuHeaderColumn?: (date: Moment, event: React.MouseEvent) => false | void,
    onSelectRecordItems?: IEmployeeDayTableWithKeyboardControlProps['onSelectItems'],
    onKeyPress?: (event: KeyboardEvent, selected: Readonly<string[]>) => void,
    onClickEmployeeCell?: IEmployeeDayTableWithKeyboardControlProps['onClickEmployeeCell'],
    onContextMenuEmployeeCell?: IEmployeeDayTableWithKeyboardControlProps['onContextMenuEmployeeCell'],
}

export function EmployeesDayDataBoard({
    elementsWithAllowedArrowControl,
    width,
    height,
    firstColumnWidth,
    dayColumnWidth,
    loading,
    locale,
    filter,
    itemClassName,
    employees,
    items,
    itemsGridRef,
    itemsGridFocuserRef,
    itemsOuterGridRef,
    itemsMarkers,
    infoBlockItems,
    selectedItemsKeys,
    recordType,
    onChangeRecordType,
    onClickRecordItem,
    onDoubleClickRecordItem,
    onContextMenuRecordItem,
    onClickHeaderColumn,
    onDoubleClickHeaderColumn,
    onContextMenuHeaderColumn,
    onSelectRecordItems,
    onKeyPress,
    onClickEmployeeCell,
    onContextMenuEmployeeCell,
}: IEmployeesDayDataBoardProps) {

    const internalFirstColumnWidth = firstColumnWidth ?? 235;
    const internalDayColumnWidth = dayColumnWidth ?? 75;

    const [viewInfoBlockType, setViewInfoBlockType] = useState(ViewInfoBlockType.Table);

    const infoBlockHeaderHeightRef = useRef(0);
    const infoBlockDisplayed = useRef(true);
    const infoBlockRef = useRef<IInfoBlockWithResizerRef>(null);
    const employeeTableGridRef = useRef<Grid<TDataSourceItem> | null>(null);
    const employeeTableWrapperRef = useRef<HTMLDivElement | null>(null);
    const employeeTableOuterGridRef = useRef<HTMLElement | null>(null);

    const [infoblockHeight, setInfoblockHeight] = useState(defaultInfoBlockHeight);
    const [employeeTableHeight, setEmployeeTableHeight] = useState(defaultEmployeeTableHeight);
    
    const calculateHeight = useCallback((value: number) => {
        
        if(infoBlockRef.current
        && employeeTableWrapperRef.current
        && employeeTableOuterGridRef.current) {

            let infoBlockHeight: number;

            if (!infoBlockDisplayed.current) {
                infoBlockHeight = infoBlockHeaderHeightRef.current + value;
            }
            else {

                const infoBlockWrapperRect = infoBlockRef.current.wrapper.getBoundingClientRect();
                const infoBlockOuterRect = infoBlockRef.current.gridOuter.getBoundingClientRect();
                const infoBlockHeaderHeight = infoBlockWrapperRect.height - infoBlockOuterRect.height;

                infoBlockHeight = infoBlockHeaderHeight + value;
            }

            const employeeWrapperRect = employeeTableWrapperRef.current.getBoundingClientRect();
            const employeeOuterRect = employeeTableOuterGridRef.current.getBoundingClientRect();
            const employeeTableHeaderHeight = employeeWrapperRect.height - employeeOuterRect.height;
            const newHeight = height - infoBlockHeight - employeeTableHeaderHeight;
            
            setInfoblockHeight(value);
            setEmployeeTableHeight(newHeight);
        }

    }, [height]);

    const updateEmployeeTableHeight = useCallback((value: number) => {
        calculateHeight(value);
    }, [calculateHeight]);

    const handleInfoBlockCollapseChange = useCallback((displayed: boolean) => {

        assignRef(displayed, infoBlockDisplayed);

        if(!displayed) {

            if(infoBlockRef.current
            && employeeTableWrapperRef.current
            && employeeTableOuterGridRef.current) {

                const infoBlockWrapperRect = infoBlockRef.current.wrapper.getBoundingClientRect();
                const infoBlockOuterRect = infoBlockRef.current.gridOuter.getBoundingClientRect();
                const infoBlockHeaderHeight = infoBlockWrapperRect.height - infoBlockOuterRect.height;

                infoBlockHeaderHeightRef.current = infoBlockHeaderHeight;

                const infoBlockCollapseHeaderRect = infoBlockRef.current.collapseHeaderRef.getBoundingClientRect();
                const employeeWrapperRect = employeeTableWrapperRef.current.getBoundingClientRect();
                const employeeOuterRect = employeeTableOuterGridRef.current.getBoundingClientRect();
                const employeeTableHeaderHeight = employeeWrapperRect.height - employeeOuterRect.height;
                const newHeight = height - infoBlockCollapseHeaderRect.height - employeeTableHeaderHeight;

                setEmployeeTableHeight(newHeight);
            }
        }

    }, [height]);

    const {
        syncInfoBlockScroll,
        syncEmployeeTableScroll,
        onInfoBlockScroll,
        onEmployeeTableScroll
    } = useScrollingSynchronizer({
        infoBlockDisplayed,
        infoBlockTable: infoBlockRef.current?.grid,
        employeeTable: employeeTableGridRef.current,
        employees,
        items,
        infoBlockItems,
    });
    
    const selectStylesElRef = useRef<HTMLStyleElement>(null);
    const [tableId] = useState(() => `vt-${tableCounter++}`);

    useEffect(() => {

        const timeoutId = setTimeout(() => {

            if (!selectStylesElRef.current || !items?.items) {
                return;
            }
            
            let css = '';
            const maxDays = items.items.length + 1;
            for (let i = 0; i < maxDays; i++) {
                css += `
                    .${tableId}-wrapper:has(.col-day-idx-${i}:hover) th.col-day-idx-${i} { background: #eaeaea; }
                    .${tableId}-wrapper:has(.col-day-idx-${i}:hover) .infoblock-day-value.col-day-idx-${i} { background: #f9f9f9; }
                    .${tableId}-wrapper:has(.col-day-idx-${i}.selected) th.col-day-idx-${i} { background: #e3e3e3; }
                    .${tableId}-wrapper:has(.col-day-idx-${i}.selected) .infoblock-day-value.col-day-idx-${i} { background: #efefef; }
                `;
            }

            selectStylesElRef.current.innerHTML = css;

        }, 300);

        return () => {
            clearTimeout(timeoutId);
        }

    }, [tableId, items?.items.length]);

    return (
        <div className={`${tableId}-wrapper`}>
            <style ref={selectStylesElRef}></style>
            <InfoBlockWithResizer
                ref={infoBlockRef}
                loading={loading}
                filter={filter}
                employees={employees}
                loadingRecords={items?.loading}
                data={infoBlockItems}
                firstColumnWidth={internalFirstColumnWidth}
                columnWidth={internalDayColumnWidth}
                width={width}
                height={infoblockHeight}
                locale={locale?.infoBlock}
                viewType={viewInfoBlockType}
                onChangeViewType={setViewInfoBlockType}
                recordType={recordType}
                syncInfoBlockScroll={syncInfoBlockScroll}
                onChangeRecordType={onChangeRecordType}
                onDoubleClickHeaderColumn={onDoubleClickHeaderColumn}
                onClickHeaderColumn={onClickHeaderColumn}
                onContextMenuHeaderColumn={onContextMenuHeaderColumn}
                onScroll={onInfoBlockScroll}
                onChangeHeight={updateEmployeeTableHeight}
                onCollapseChange={handleInfoBlockCollapseChange}
            />
            <div ref={employeeTableWrapperRef}>
                <EmployeeDayTableWithKeyboardControl
                    elementsWithAllowedArrowControl={elementsWithAllowedArrowControl}
                    loading={loading}
                    className={classNames({"schedule-data": recordType === RecordType.Schedule})}
                    focusRef={itemsGridFocuserRef}
                    gridRef={refSetter(employeeTableGridRef, itemsGridRef)}
                    outerGridRef={refSetter(employeeTableOuterGridRef, itemsOuterGridRef)}
                    employeeColumnWidth={internalFirstColumnWidth}
                    dayColumnWidth={internalDayColumnWidth}
                    selectedKeys={selectedItemsKeys}
                    width={width}
                    height={employeeTableHeight}
                    filter={filter}
                    employees={employees}
                    items={items}
                    markers={itemsMarkers}
                    onScroll={onEmployeeTableScroll}
                    itemClassName={itemClassName}
                    onContextMenuHeaderColumn={onContextMenuHeaderColumn}
                    onDoubleClickHeaderColumn={onDoubleClickHeaderColumn}
                    onClickHeaderColumn={onClickHeaderColumn}
                    onContextMenuRecordItem={onContextMenuRecordItem}
                    onDoubleClickRecordItem={onDoubleClickRecordItem}
                    onClickRecordItem={onClickRecordItem}
                    onSelectItems={onSelectRecordItems}
                    onKeyPress={onKeyPress}
                    onClickEmployeeCell={onClickEmployeeCell}
                    onContextMenuEmployeeCell={onContextMenuEmployeeCell}
                />
            </div>
        </div>
    )
}

export default EmployeesDayDataBoard;