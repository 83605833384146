import { Reducer } from "react";
import { IState } from "../Entities/IState";
import { NotSupportedException } from "../System/NotSupportedException";

export interface ILoadEntityState<TEntity> extends IState<TEntity> {
    readonly load: boolean;
    readonly loading: boolean;
    readonly error?: unknown;
    readonly entity?: TEntity;
}

export type ENTITY_LOADING      = 'ENTITY_LOADING';
export type ENTITY_LOAD_SUCCESS = 'ENTITY_LOAD_SUCCESS';
export type ENTITY_LOAD_FAIL    = 'ENTITY_LOAD_FAIL';

export enum LoadEntityKind {
    Loading     = 'ENTITY_LOADING',
    LoadSuccess = 'ENTITY_LOAD_SUCCESS',
    LoadFail    = 'ENTITY_LOAD_FAIL',
}

export type LoadingAction = {
    type: ENTITY_LOADING,
    clearEntity?: boolean
}

export type LoadFailAction = {
    type: ENTITY_LOAD_FAIL,
    clearEntity?: boolean,
    error?: unknown;
}

export type LoadSuccessAction<TEntity> = {
    type: ENTITY_LOAD_SUCCESS;
    payload?: TEntity;
}

export type LoadReducerAction<TListItem> = LoadingAction | LoadFailAction | LoadSuccessAction<TListItem>;

export const initialLoadEntityState: ILoadEntityState<any> = {
    load: false,
    loading: false,
}

export const emptyArray = [];

export type LoadEntityReducerType<TListItem> = Reducer<ILoadEntityState<TListItem>, LoadReducerAction<TListItem>>;
export type ILoadEntityReducer<TListItem> = (prevState: ILoadEntityState<TListItem>, action: LoadReducerAction<TListItem>) => ILoadEntityState<TListItem>;
export function loadEntityReducer<TEntity>(prevState: ILoadEntityState<TEntity>, action: LoadReducerAction<TEntity>): ILoadEntityState<TEntity> {

    const { type } = action;

    switch(type) {

        case LoadEntityKind.Loading: {
            
            return {
                entity: action.clearEntity ? undefined : prevState.entity,
                load: false,
                loading: true
            }
        }

        case LoadEntityKind.LoadSuccess: {

            return {
                entity: action.payload ?? undefined,
                load: true,
                loading: false
            }
        }

        case LoadEntityKind.LoadFail: {

            return {
                entity: action.clearEntity ? undefined : prevState.entity,
                load: false,
                loading: false,
                error: action.error
            }
        }

        default: throw new NotSupportedException(`Action is not supported`);
    }
}

export default loadEntityReducer;