import moment from "moment";
import { IAuthState } from "../../Entities/IAuthProvider";
import { RangeValue } from "../../System/Utils";
import { execute } from "../../Server/DbProvider";
import { IEmployee, IScheduleBoardFilter, IdType } from "../../Entities/Database";
import { IAuthData } from "../../Server/IAuthData";
import { BoolQueryParameter, DateTime2QueryParameter, DateTimeQueryParameter, FloatQueryParameter, IdArrayQueryParameter, IntQueryParameter, StringQueryParameter } from "../../Server/Query";

export interface IEmployeeFilter {
    projectGroupIds: IdType[],
    forcastObjIds: IdType[],
    fieldIds: IdType[],
    skillIds: IdType[],
    tagIds: IdType[],
    crossEmploee: boolean,
}

export interface IValues extends IEmployeeFilter {
    day: moment.Moment,
    interval: RangeValue<moment.Moment>,
    quantity: number,
    factor: number,
    actualTo: moment.Moment,
    comment: string | null,
    employees: IEmployee['PID'][],
    isTargeted: boolean,
    isAutoApprove: boolean,
}

export const getEmployeeListProcName      = "[Control].[dbo].[proc_WFM_Schedule_Request_PIDListByFilter_get]";
export const addShiftTradeRequestProcName = "[Control].[dbo].[proc_WFM_ShiftTrade_Request_add]";

export async function getEmployeeList(auth: IAuthState<IAuthData>, range?: RangeValue<moment.Moment>, filter?: IEmployeeFilter, signal?: AbortSignal) {

    if (!range || !filter) {
        return;
    }

    auth.throwIfNotLogedInAndLogout(signal);

    const result = await execute<IEmployee[]>({
        authData: auth.data,
        procedure: getEmployeeListProcName,
        parameters: [
            new IdArrayQueryParameter("tagList", filter.tagIds),
            new IdArrayQueryParameter("fieldList", filter.fieldIds),
            new IdArrayQueryParameter("skillList", filter.skillIds),
            new BoolQueryParameter("showCross", filter.crossEmploee),
            new DateTime2QueryParameter("SD", range ? range[0] : null),
            new DateTime2QueryParameter("ED", range ? range[1] : null),
        ]
    }, signal);

    return result;
}

export async function addShiftTradeRequest(auth: IAuthState<IAuthData>, data: IValues, filter: IScheduleBoardFilter, signal?: AbortSignal) {

    auth.throwIfNotLogedInAndLogout(signal);

    const result = await execute<IEmployee>({
        authData: auth.data,
        procedure: addShiftTradeRequestProcName,
        parameters: [
            new DateTime2QueryParameter("sd", data.interval ? data.interval[0] : null),
            new DateTime2QueryParameter("ed", data.interval ? data.interval[1] : null),
            new IntQueryParameter("qty_need", data.quantity),
            new FloatQueryParameter("incFactor", data.factor),
            new DateTime2QueryParameter("dtValid", data.actualTo),
            new StringQueryParameter("comment", data.comment),
            new IdArrayQueryParameter("pidList", data.employees),

            // Filter
            new IdArrayQueryParameter("pgList", filter.projectGroupIds),
            new IdArrayQueryParameter("actList", filter.prodActIds),
            new IdArrayQueryParameter("foList", filter.forcastObjIds),
            new IdArrayQueryParameter("tagList", data.tagIds),
            new IdArrayQueryParameter("fieldList", data.fieldIds),
            new IdArrayQueryParameter("skillList", data.skillIds),
            new BoolQueryParameter("useCross", data.crossEmploee),
            new BoolQueryParameter("isTargeted", data.isTargeted),
            new BoolQueryParameter("isAutoApprove", data.isAutoApprove),
        ]
    }, signal);

    return result;
}