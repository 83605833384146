import { useEffect, useRef, useState } from "react";
import { Modal, message as AntdMessage, Divider, Spin, Space, Checkbox } from "antd";
import DraggableModal, { IDraggableModalProps } from "../DraggableModal";
import DatePicker from "../shared/DatePicker";
import { RangeValue } from "../../System/Utils";
import moment from "moment";
import { IGeneratedData, applyGenerateData, backendUrls } from "../../Entities/BackendGenerator";
import { InvalidDataException } from "../../System/InvalidDataException";
import useActual from "../../Hooks/useActual";
import { useGeneratorProvider } from "./useGeneratorProvider";
import { BackendResponseException } from "../../System/BackendResponseException";
import useAuth from "../../Hooks/useAuth";
import SelectServer from "../ScheduleGeneratorBoard/SelectServer";
import CheckAccess from "../CheckAccess";
import { Module } from "../../Entities/Database";

const abortByClose = {};
const abortByButton = {};
const abortByDestructor = {};

export interface IScheduleGeneratorModalProps {
    open?: boolean,
    onCancel?: IDraggableModalProps['onCancel'],
    onFinish?: () => void,
}

export function ScheduleGeneratorModal({
    open,
    onCancel,
    onFinish,
}: IScheduleGeneratorModalProps) {

    const auth = useAuth();
    const [modal, modalContextHolder] = Modal.useModal();
    const [message, messageContextHolder] = AntdMessage.useMessage();
    const [generatedData, setGeneratedData] = useState<IGeneratedData>();
    const [connecting, setConnecting] = useState(true);
    const [connectSuccess, setConnectSuccess] = useState(false);
    const [saving, setSaving] = useState(false);
    const [process, setProcess] = useState(false);
    const [success, setSuccess] = useState(false);
    const [serverUrl, setServerUrl] = useState(backendUrls['Gen2']);
    const [optimize, setOptimize] = useState(true);
    const [range, setRange] = useState<RangeValue<moment.Moment>>(() => [
        moment().startOf('month'),
        moment().endOf('month')
    ]);

    const processStartDateRef = useRef<Date>();
    const processStartElementRef = useRef<HTMLDivElement>(null);
    const processTimerRef = useRef<NodeJS.Timeout>();
    const onFinishActual = useActual(onFinish);
    const generatorProvider = useGeneratorProvider(!open ? undefined : {
        serverUrl: serverUrl,
        onFinish: async (data) => {
            try {
                setSaving(true);
                setSuccess(true);
                setProcess(false);
                setGeneratedData(data);
                await applyGenerateData(auth, data);
                if (onFinishActual.current) {
                    onFinishActual.current();
                }
            }
            catch (ex) {
                message.error(ex instanceof BackendResponseException ? ex.message : "Не удалось применить сформированное расписание");
                console.error(ex);
            }
            finally {
                setSaving(false);
            }
        },
        onFail: (err) => {
            setSuccess(false);
            setProcess(false);
            message.error("Не удалось сформировать расписание");
            console.error(err);
        },
        onClose: (err) => {
            setSuccess(false);
            setProcess(false);
            setConnecting(false);
            setConnectSuccess(false);
            message.error("Сервер разорвал соединение");
            console.error(err);
        },
        onConnectionSuccess: () => {
            setConnecting(false);
            setConnectSuccess(true);
        },
        onConnectionFail: () => {
            setSuccess(false);
            setProcess(false);
            setConnecting(false);
            setConnectSuccess(false);
            message.error("Не удалось подключиться к серверу");
        }
    });

    const handleCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {

        if (process) {
            setProcess(false);
            setSuccess(false);
            generatorProvider.abort();
            return;
        }

        if (onCancel) {
            onCancel(event);
        }
    }

    const handleOk = async () => {

        try {

            if (range === null ||
                range[0] === null ||
                range[1] === null) {
                throw new InvalidDataException("Не заполнен период, для которого необходимо сформировать расписание");
            }

            const from = range[0];
            const to = range[1];
            const castCount = optimize ? 5000 : 0;
            const fullSearchCount = optimize ? 1000 : 0;
            
            setSuccess(false);
            setProcess(true);

            processStartDateRef.current = new Date();

            await generatorProvider.abort();
            await generatorProvider.start({
                from,
                to,
                castCount,
                fullSearchCount
            });
        }
        catch (ex) {

            if (ex === abortByClose ||
                ex === abortByClose ||
                ex === abortByButton) {
                return;
            }

            message.error(ex instanceof InvalidDataException ? ex.message : "Не удалось сформировать расписание");
            console.error(ex);
        }
    }

    const handleRangeChange = (values: RangeValue<moment.Moment>) => {
        setRange(values);
    }

    const getProcessTimeText = () => {

        if (processStartDateRef.current) {

            const diff   = moment().diff(processStartDateRef.current, 'milliseconds');
            const hhmmss = moment.utc(diff).format("HH:mm:ss");

            return hhmmss;
        }

        return "00:00:00";
    }

    useEffect(() => {

        if (open) {

            setProcess(false);
            setSuccess(false);
            setConnecting(true);
            setConnectSuccess(false);

            generatorProvider.connect();

            return () => {
                processStartDateRef.current = undefined;
                generatorProvider.disconnect();
            }
        }

    }, [serverUrl, open]);

    useEffect(() => {

        clearInterval(processTimerRef.current);

        if (open && process) {

            processTimerRef.current = setInterval(() => {
                
                if (processStartElementRef.current) {
                    processStartElementRef.current.textContent = getProcessTimeText();
                }

            }, 1000);
        }

    }, [open, process]);

    useEffect(() => () => {
        clearInterval(processTimerRef.current);
        generatorProvider.disconnect();
    }, []);

    return (
        <>
            <DraggableModal
                title={process
                    ? <span>Формирую расписание: <span ref={processStartElementRef}>{getProcessTimeText()}</span></span>
                    : <span>Генератор расписания</span>
                }
                width={340}
                open={open}
                onOk={handleOk}
                onCancel={handleCancel}
                keyboard={!saving}
                okText={
                      process
                    ? 'Формирую'
                    : saving
                    ? 'Сохраняю'
                    : 'Сформировать'
                }
                cancelButtonProps={{
                    disabled: saving
                }}
                okButtonProps={{
                    disabled: connecting || !connectSuccess,
                    loading: saving || process,
                }}
            >
                <Spin spinning={connecting}>
                    <Divider />
                    <CheckAccess
                        module={Module.SelectGeneratorServer}
                    >
                        <Space
                            align="center"
                            style={{marginBottom: 15}}
                        >
                            Сервер генерации:
                            <SelectServer
                                onChange={setServerUrl}
                                defaultValue={serverUrl}
                            />
                        </Space>
                    </CheckAccess>
                    <DatePicker.RangePicker
                        value={range}
                        disabled={process || saving || !connectSuccess}
                        style={{width: '100%', marginBottom: 15}}
                        onCalendarChange={handleRangeChange}
                    />
                    <Checkbox
                        title="Оптимизировать расписание"
                        disabled={process || saving || !connectSuccess}
                        onChange={(e) => setOptimize(e.target.checked)}
                        value={optimize}
                    >
                        Оптимизировать расписание
                    </Checkbox>
                    <Divider />
                </Spin>
            </DraggableModal>
            {modalContextHolder}
            {messageContextHolder}
        </>
    );
}

export default ScheduleGeneratorModal;