import { Form, Select, InputNumber, Divider, FormInstance, Input, Button  } from "antd";
import DraggableModal from "../../DraggableModal";
import { classNames, nameof } from "../../../System/Utils";
import { VacationHelpInfo } from "../VacationHelpInfo";
import DatePicker from "../../shared/DatePicker";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { IEmployee, IVacationStatus, IVacationType, IVacation, plannedVacationStatusId, IVacationEditorValues } from "../../../Entities/Database";
import { dateFormat } from "../VacationHelpInfo/helpers";

import "./style.css";

export interface IVacationModalAddEditProps {
    open?: boolean,
    onOk?: () => void,
    onCancel?: () => void,
    vacationInfo?: IVacation,
    types?: IVacationType[],
    statuses?: IVacationStatus[],
    employee?: IEmployee,
    loading?: boolean,
    saving?: boolean,
    deleting?: boolean,
    form: FormInstance<IVacationEditorValues>,
    onFormFinish?: (x: IVacationEditorValues) => void,
    onClickDelete?: () => void,
}

export function VacationModalAddEdit({
    open,
    onOk,
    onCancel,
    vacationInfo,
    employee,
    types,
    statuses,
    loading,
    saving,
    deleting,
    form,
    onFormFinish,
    onClickDelete,
}: IVacationModalAddEditProps) {

    const [selectedStatus, setSelectedStatus] = useState<IVacationStatus | undefined>();
    const [selectedType, setSelectedType] = useState<IVacationType | undefined>();
    const [fio, range, modalTitle] = useMemo(() => {

        const fio = employee ? `${employee?.surname} ${employee?.firstName[0]}. ${employee?.secondName[0]}.` : "";

        if (!vacationInfo && !employee) {
            return [fio, undefined, "Создание нового отпуска"];
        }

        if (!vacationInfo) {
            return [fio, undefined, `Отпуск ${fio}`];
        }

        const start = moment(vacationInfo.startDate);
        const end   = moment(vacationInfo.startDate).add(vacationInfo.duration - 1, 'days');

        return [
            fio,
            [start, end],
            `Отпуск ${fio} [${start.format(dateFormat)} - ${end.format(dateFormat)}]`
        ];

    }, [employee, vacationInfo]);

    const handleDateRangeChange = (value: any, dateString: [string, string] | string) => {
        const diff = value[1].diff(value[0], "days") + 1;
        form.setFieldValue("duration", diff);
    }

    const handleFormChange = (keys: any, values: IVacationEditorValues) => {
        setSelectedStatus(statuses?.find(x => x.value == values.status));
        setSelectedType(types?.find(x => x.value == values.type));
    }
    
    useEffect(() => {

        if (open) {

            const status = statuses?.find(x => x.selected) || (statuses && statuses[0]);
            const type   = types?.find(x => x.selected) || (types && types[0]);

            setSelectedStatus(status);
            setSelectedType(type);

            if (vacationInfo) {

                form.setFieldsValue({
                    employee: employee?.PID,
                    period: range,
                    duration: vacationInfo.duration,
                    comment: vacationInfo.comment,
                    vacationId: vacationInfo.id,
                    status: status?.value,
                    type: type?.value,
                });
            }
            else {

                form.setFieldsValue({
                    employee: employee?.PID,
                    period: undefined,
                    duration: undefined,
                    comment: undefined,
                    vacationId: undefined,
                    status: status?.value,
                    type: type?.value,
                })
            }
        } 

    }, [open, types, statuses, vacationInfo]);
    
    const isPlanned = plannedVacationStatusId === selectedStatus?.value;
    const deletable = vacationInfo?.canDelete;
    const readonly  = !isPlanned || loading || saving || !vacationInfo?.canEdit;

    const internalTypes = useMemo(() => types?.map(x => ({
            ...x,
            disabled: readonly,
        }))
    , [types, readonly]);

    return (
        <DraggableModal
            open={open}
            onCancel={onCancel}
            onOk={onOk}
            title={modalTitle}
            afterOpenChange={open => {
                if (!open) {
                    form.resetFields();
                }
            }}
            width={497}
            okText="Сохранить"
            cancelText="Закрыть"
            closable={!saving}
            keyboard={!saving}
            okButtonProps={{
                loading: saving,
                disabled: readonly,
                hidden: isPlanned,
                style: {
                    display: isPlanned ? undefined : 'none',
                }
            }}
            cancelButtonProps={{
                disabled: saving,
            }}
            footer={deletable
                ? (originNode, params) => (
                    <>
                        <Button
                            disabled={deleting || saving}
                            onClick={onClickDelete}
                        >
                            Удалить
                        </Button>
                        {originNode}
                    </>
                )
                : undefined}
        >
            <p className="vacationModal__title">Параметры отпуска:</p>
            <Form
                form={form}
                preserve={false}
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                labelAlign="left"
                layout="horizontal"
                size="small" 
                onFinish={onFormFinish}
                onValuesChange={handleFormChange}
                disabled={loading || saving}
            >
                <Form.Item
                    label="Статус:"
                    className="vacationModal__status-wrap"    
                >
                    <Form.Item
                        name={nameof<IVacationEditorValues>("status")}
                        noStyle
                    >
                        <Select
                            className="vacationModal__custom"
                            loading={loading}
                            options={[{
                                value: selectedStatus?.value,
                                label: selectedStatus?.label,
                                disabled: true,
                            }]}
                        />
                    </Form.Item>
                    
                    <span className={classNames("vacationModal__status-circle", 
                        isPlanned ? "approved" : "pending")}>
                    </span>

                </Form.Item>
                <Form.Item
                    label="Сотрудник:"
                    name={nameof<IVacationEditorValues>("employee")}
                >
                    <Select
                        loading={loading}
                        options={[{
                            label: fio,
                            value: employee?.PID,
                            disabled: readonly,
                        }]}
                    />
                </Form.Item>
                <Form.Item
                    label="Вид отпуска:"
                    name={nameof<IVacationEditorValues>("type")}
                    rules={[{
                        required: true,
                        message: "Выберите тип отпуска"
                    }]}
                >
                    <Select
                        className="vacationModal__select"
                        options={internalTypes}
                        loading={loading}
                    />
                </Form.Item>
                <Form.Item
                    label="Дата начала/окончания:"
                    name={nameof<IVacationEditorValues>("period")}
                    rules={[{
                        required: true,
                        message: "Выберите дату начала и окончания"
                    }]}
                >
                    <DatePicker.RangePicker 
                        onChange={handleDateRangeChange}
                        inputReadOnly={readonly}
                        disabledDate={readonly ? () => true : undefined}
                    />
                </Form.Item>
                <Form.Item
                    label="Продолжительность:"
                    name={nameof<IVacationEditorValues>("duration")}
                >
                    <InputNumber 
                        className="vacationModal__input"
                        readOnly
                    />
                </Form.Item>
                <Form.Item
                    label="Примечание:"
                    name={nameof<IVacationEditorValues>("comment")}
                    wrapperCol={{span: 24}}
                    labelCol={{span: 24}}
                >
                    <Input.TextArea 
                        rows={2}
                        readOnly={readonly}
                    />
                </Form.Item>
                <Form.Item
                    name={nameof<IVacationEditorValues>("vacationId")}
                    hidden={true}
                >
                    <Input />
                </Form.Item>
            </Form>
            <Divider />
            <VacationHelpInfo 
                vacationInfo={vacationInfo}
            />
        </DraggableModal>
    );
}