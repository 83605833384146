import axios, { AxiosResponse, CanceledError } from 'axios';
import { IQueryResult, IQueryResultItem } from './IQueryResult';
import { EqualsOrdinal, isArray, isNullOrUndefined, NullOrUndefined } from '../System/Utils';
import { IAuthData } from './IAuthData';
import { IHasAuthToken } from './IHasAuthToken';
import { IQuery } from './Query';
import { InvalidOperationException } from '../System/InvalidOperationException';
import delay from '../System/Delay';
import { BackendException } from '../System/BackendException';
import { BackendResponseException } from '../System/BackendResponseException';
import { ICredential, ICredentialHash, isCredential } from './ICredential';
import { InvalidDataException } from '../System/InvalidDataException';
import { md5 } from 'js-md5';

declare global {
    interface Window { __backendSid: string; }
}

//export const backendUrl = "http://localhost:5016";
//export const backendUrl = "https://192.168.23.29:5016";
//export const backendUrl = "http://192.168.18.97:5016";
export const backendUrl = "https://api.wfm.astrapage.ru";

export const QIDQueryParamName   = "@QID";
export const SIDQueryParamName   = "@SID";
export const QueryIdHeaderName   = "X-Frontend-Query-Id";
export const SessionIdHeaderName = "X-Frontend-Session-Id";
export const sid = crypto.randomUUID();

export const client = axios.create({ baseURL: backendUrl });

window.__backendSid = sid;

export function getQueryId() {
    return crypto.randomUUID();
}

export function getAccessTokenStr(authData?: IAuthData | NullOrUndefined) {
    return isNullOrUndefined(authData) ? `` : `${authData.tokenType} ${authData.token}`;
}

export function getHeaders(query?: IQuery) {
    const queryId = getQueryId();
    return {
        [QueryIdHeaderName]: queryId,
        [SessionIdHeaderName]: sid,
        'Authorization': query ? getAccessTokenStr(query.authData) : undefined,
    }
}

export function getCredentialHash(data: ICredential): ICredentialHash {
    
    const userName = data.userName.toLowerCase();
    const salt = md5(userName + data.password);
    const hash = md5(userName + salt + data.password);
    const { password, ...dataWithoutPassword } = data;

    return {
        ...dataWithoutPassword,
        hash
    }
}

export async function authUser(data: IHasAuthToken | ICredential, signal?: AbortSignal) : Promise<IAuthData> {
    
    await delay(300, {signal});

    try {
        const dataIsCredential = isCredential(data);
        const normalizeData = dataIsCredential ? getCredentialHash(data)  : data;
        const method = dataIsCredential
            ? 'loginbyhash'
            : 'loginbylktoken';
        
        const response = await client.post<IAuthData>(`/user/${method}`, normalizeData, {
            headers: getHeaders(),
            signal: signal
        });

        return {
            ...response.data,
            staticId: response.data.data.userPID,
            expires: new Date(response.data.expires),
        }
    }
    catch (ex) {

        if (ex instanceof CanceledError && signal?.aborted) {

            throw signal.reason;
        }

        throw ex;
    }
}

export const backendNoErrorId = -1;
export function isBackendError(ex: unknown): ex is BackendException {
    return ex instanceof BackendException;
}

export function throwIfError<TData = any, TOptions = any>(result: IQueryResultItem, response: AxiosResponse<TData & IQueryResult, TOptions>) {

    if(!isNullOrUndefined(result._error_id)
    && result._error_id != backendNoErrorId) {

        const queryIdValue = response.config.headers
        ? response.config.headers[QueryIdHeaderName]
        : undefined;
        
        const queryId = queryIdValue?.toString() ?? "undefined";
        const errorId = result._error_id;
        const message = result._error_desc ?? "";
        
        throw new BackendResponseException(queryId, result, response, errorId, message);
    }
}

export function throwIfResultHasError<TData = any, TOptions = any>(response: AxiosResponse<TData & IQueryResult, TOptions>) {

    const result: IQueryResult = response.data;
    
    if(!isNullOrUndefined(result)) {

        if(isArray(result)) {
            for(const innerResult of result) {
                throwIfError(innerResult, response);
            }
        }
        else {
            throwIfError(result, response);
        }
    }
}

export function normalizeResultItem<TData = any, TOptions = any>(result: IQueryResultItem, response: AxiosResponse<TData & IQueryResult, TOptions>): TData {
    throwIfError(result, response);
    const { _error_id, _error_desc, ...normalized } = result;
    return normalized as TData;
}

export function normalizeResult<TData = any, TOptions = any>(response: AxiosResponse<TData & IQueryResult, TOptions>): TData {

    if(isArray(response.data)) {
        return response.data.map(resultItem => normalizeResultItem(resultItem, response)) as TData;
    }

    return normalizeResultItem<TData>(response.data, response);
}

export function normalizeParameterName(name: string) {

    if(name.startsWith("@")) {
        return name;
    }

    return `@${name}`;
}

export function normalizeParameters(query: IQuery) {

    // QID and SID system names

    const parameters = query.parameters ?? [];
    const sidParameters = parameters.filter(x => EqualsOrdinal(normalizeParameterName(x.parameterName), SIDQueryParamName));

    if(sidParameters.length > 0) {
        throw new InvalidOperationException(`${SIDQueryParamName} parameter can't be custom`);
    }
    
    const qidParameters = parameters.filter(x => EqualsOrdinal(normalizeParameterName(x.parameterName), QIDQueryParamName));

    if(qidParameters.length > 0) {
        throw new InvalidOperationException(`${QIDQueryParamName} parameter can't be custom`);
    }

    //const sidParameter = new UUIDQueryParameter(SIDQueryParamName, window.__backendSid);
    //const pidParameter = new UUIDQueryParameter(QIDQueryParamName, getQueryId());
    //parameters.push(sidParameter, pidParameter);

    return parameters.map(x => {
        // Удаляем отладочное значение
        delete x.originalValue;
        return x;
    });
}

export enum ExportFileExtension {
    XLSX = 'xlsx',
}

export async function getExportFile(query: IQuery, extension: ExportFileExtension, provider?: string, fileName?: string, signal?: AbortSignal): Promise<void> {

    await delay(300, {signal});

    try {

        const requestUrl = provider
            ? `/dynamic/${extension}/${provider}/${query.procedure}`
            : `/dynamic/${extension}/${query.procedure}`;
        
        const parameters = normalizeParameters(query);
        const response = await client.post<Blob | IQueryResultItem>(requestUrl, parameters, {
            signal: signal,
            responseType: 'blob',
            headers: getHeaders(query)
        });

        if(response.data instanceof Blob) {

            const blob = new Blob([response.data]);
            const link = document.createElement('a');

            link.href = window.URL.createObjectURL(blob);
            link.download = fileName ?? `${+ new Date()}.${extension}`;
            link.click();

            return;
        }

        throwIfResultHasError(response);
    }
    catch (ex) {

        if (ex instanceof CanceledError && signal?.aborted) {

            throw signal.reason;
        }

        throw ex;
    }
}

export const getXLSX = (query: IQuery, provider?: string, fileName?: string, signal?: AbortSignal) =>
    getExportFile(query, ExportFileExtension.XLSX, provider, fileName, signal);

export async function execute<TData>(query: IQuery, signal?: AbortSignal): Promise<TData> {

    await delay(300, {signal});
    
    try {
        const parameters = normalizeParameters(query);
        const response = await client.post<TData & IQueryResult>(`/dynamic/${query.procedure}`, parameters, {
            signal: signal,
            headers: getHeaders(query)
        });
        
        return normalizeResult(response);
    }
    catch (ex) {

        if (ex instanceof CanceledError && signal?.aborted) {

            throw signal.reason;
        }

        throw ex;
    }
}

export async function executeFirst<TItem>(query: IQuery, signal?: AbortSignal): Promise<TItem> {

    const record = await execute<TItem[]>(query, signal);

    if (record.length > 0) {
        return record[0];
    }
    
    throw new InvalidDataException("Server return empty result");
}