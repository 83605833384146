import { Dropdown, Empty, Skeleton, message as AntdMessage, Button } from "antd";
import { NotificationTabs } from "../NotificationTabs";
import useAuth from "../../../Hooks/useAuth";
import { useEffect, useState } from "react";
import { getNotificationList } from "../../../Entities/Database";
import { NotificationButton } from "../NotificationButton";
import { useEntityLoader } from "../../../Hooks/useEntityLoader";
import "./style.css";

export interface INotificationDropdownProps {
}

export function NotificationBoard({}: INotificationDropdownProps) {

    const auth = useAuth();
    const [open, setOpen] = useState(false);
    const [message, messageContextHolder] = AntdMessage.useMessage();
    const [notificationsState, reloadNotifications] = useEntityLoader((signal) => getNotificationList(auth, signal), [auth.data?.staticId]);

    useEffect(() => {

        if (notificationsState.error) {
            message.error("Не удалось загрузить список уведомлений");
        }

    }, [notificationsState.error]);

    useEffect(() => {
        
        if (open && auth.data?.staticId) {
            return reloadNotifications();
        }

    }, [open, auth.data?.staticId]);

    const handleOpenChange = (status: boolean) => {
        setOpen(status);
    }

    const notificationListRenderer = () => {

        if (!notificationsState.load) {
            return <Skeleton active={notificationsState.loading} />
        }

        if (!notificationsState.entity || notificationsState.entity.length < 1) {
            return <Empty description="Нет новых уведомлений" image={Empty.PRESENTED_IMAGE_SIMPLE} />
        }

        return (
            <NotificationTabs
                notifications={notificationsState.entity}
            />
        );
    }

    return (
        <>
            <Dropdown 
                onOpenChange={handleOpenChange}
                dropdownRender={notificationListRenderer}
                placement="bottomRight"
                overlayClassName="notificationDropdown"
                overlayStyle={{
                    width: "500px",
                }}
                arrow={true}
                trigger={['click']}
            >
                <NotificationButton />
            </Dropdown>
            {messageContextHolder}
        </>
    )
}