import { useEffect, useState } from "react";
import useAuth from "../../Hooks/useAuth";
import useEntityLoader from "../../Hooks/useEntityLoader";
import DraggableModal from "../DraggableModal";
import { getShiftTradeRequestResponses, IShiftTradeRequest, IShiftTradeRequestResponse } from "./index.db";
import { Button, Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { nameOf } from "../../System/Utils";
import { LiteralUnion } from "antd/es/_util/type";
import { PresetColorType, PresetStatusColorType } from "antd/es/_util/colors";
import { CheckCircleOutlined, ClockCircleOutlined, CloseCircleOutlined, ReadOutlined, SyncOutlined } from "@ant-design/icons";
import ScheduleRequestHandlerModal from "../ScheduleRequestHandlerModal";

/*
1   Отправлено   sent
2   Доставлено   received
3   Прочитано    read
4   Отклонено    reject 
5   Принято      accept
6   Согласовано  approved
*/

export function getStatusColor(status: number): LiteralUnion<PresetColorType | PresetStatusColorType> {
    switch (status) {
        case 1: return 'processing';
        case 2: return 'orange';
        case 3: return 'volcano';
        case 4: return 'error';
        case 5: return 'success';
        case 6: return 'purple';
        default: break;
    }

    return 'default';
}

export function getStatusIcon(status: number) {

    switch (status) {
        case 1: return <SyncOutlined spin />;
        case 2: return <ClockCircleOutlined />;
        case 3: return <ReadOutlined />;
        case 4: return <CloseCircleOutlined />;
        case 5: return <CheckCircleOutlined />;
        case 6: return <CheckCircleOutlined />;
        default: break;
    }

    return <SyncOutlined spin />;
}

export interface IShiftTradeRequestDetailProps {
    open?: boolean,
    onCancel?: () => void,
    request?: IShiftTradeRequest,
}

export function ShiftTradeRequestDetail({
    open,
    onCancel,
    request
}: IShiftTradeRequestDetailProps) {

    const auth = useAuth();
    const [openSTRHModal, setOpenSTRHModal] = useState(false);
    const [selectedResponse, setSelectedResponse] = useState<IShiftTradeRequestResponse>();
    const [responsesState, reloadResponses] = useEntityLoader(signal => request ? getShiftTradeRequestResponses(auth, request, signal) : Promise.resolve([]), [auth.data?.staticId, request]);

    const handleFinishSTRHModal = () => {
        setOpenSTRHModal(false);
        reloadResponses();
    }

    const handleCancelSTRHModal = () => {
        setOpenSTRHModal(false);
    }

    useEffect(() => {

        if (open) {
            return reloadResponses();
        }

    }, [open]);

    const columns: ColumnsType<IShiftTradeRequestResponse> = [{
        title: 'Сотрудник',
        ellipsis: true,
        dataIndex: nameOf<IShiftTradeRequestResponse>('fio'),
        sorter: (a, b) => b.fio.localeCompare(a.fio),
    }, {
        title: 'Дата отклика',
        ellipsis: true,
        sorter: (a, b) => (+(b.dtResponse || 0)) - +(a.dtResponse || 0),
        render: (value: any, item: IShiftTradeRequestResponse) => item.dtResponse?.format("YYYY-MM-DD HH:mm"),
    }, {
        title: 'Статус',
        ellipsis: true,
        dataIndex: nameOf<IShiftTradeRequestResponse>('status'),
        sorter: (a, b) => b.statusId - a.statusId,
        render: (value, item) => (
            <div>
                <Tag
                    icon={getStatusIcon(item.statusId)}
                    color={getStatusColor(item.statusId)}
                >
                    {item.status}
                </Tag>
            </div>
        ),
    }, {
        title: 'Комментарий',
        ellipsis: true,
        dataIndex: nameOf<IShiftTradeRequestResponse>('comment'),
    }, {
        title: 'Действие',
        render: (value, item) => {

            const handleClick = () => {
                setSelectedResponse(item);
                setOpenSTRHModal(true);
            }

            return !!item.requestId && (
                <Button size="small" type="primary" onClick={handleClick}>Согласовать</Button>
            );
        },
    }];

    return (
        <>
            <DraggableModal
                width={700}
                open={open}
                onCancel={onCancel}
                title="Отклики"
                okButtonProps={{ style: {display: 'none'} }}
            >
                <Table
                    size="small"
                    columns={columns}
                    dataSource={responsesState.entity}
                    loading={responsesState.loading}
                    pagination={{position: ['bottomCenter'], hideOnSinglePage: true}}
                />
            </DraggableModal>
            {selectedResponse &&
            <ScheduleRequestHandlerModal
                open={openSTRHModal}
                item={selectedResponse}
                onFinish={handleFinishSTRHModal}
                onCancel={handleCancelSTRHModal}
            />}
        </>
    );
}