import { Alert, ConfigProvider, Empty, Skeleton, Space } from 'antd';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { getScheduleItemWorkTimeList, IEmployeeScheduleDay, IHasEmployeeDayKey, IScheduleBoardFilter, ISimplifiedActivityWithTotal } from '../../Entities/Database';
import useAuth from '../../Hooks/useAuth';
import { ILoaderInitProps, useListLoader } from '../../Hooks/useListLoader';
import { initialLoadListState } from '../../Reducer/LoadListReducer';
import AgendaList from '../Agenda';
import { AgendaEvent } from '../Agenda/list-item';
import './worktime.css';

function TotalRow({title, value}: {title: string, value: string}) {
    return (
        <div
            className="worktime-total-row"
            title={`${title}: ${value}`}
        >
            <div className="worktime-total-title">{title}:</div>
            <div className="worktime-total-value">{value}</div>
        </div>
    )
}

const initUseListReducer: ILoaderInitProps<ISimplifiedActivityWithTotal> = {
    initialState: {
        ...initialLoadListState,
        loading: true
    }
}

export interface IWorktimeListProps {
    item: IHasEmployeeDayKey,
    filter: IScheduleBoardFilter
}

export function WorktimeList({
    item,
    filter
}: IWorktimeListProps) {
    
    const didMount = useRef(false);
    const auth = useAuth();
    const [worktime, reloadWorktime] = useListLoader(
        signal => getScheduleItemWorkTimeList(auth, item, filter, signal),
        [auth.data?.staticId, item.PID, item.date, filter.timeZone],
        initUseListReducer
    );

    const eventList = useMemo((): AgendaEvent[] => {

        return worktime.items.map(item => ({
            id: item.id,
            start: item.start.toDate(),
            end: item.end.toDate(),
            text: item.caption,
            backgroundColor: item.backgroundColor,
            markColor: item.markColor,
            comment: item.comment
        }));

    }, [worktime.items]);

    const { renderEmpty } = useContext(ConfigProvider.ConfigContext);
    
    const emptyText = renderEmpty?.('Table') || (
        <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
        />
    );

    useEffect(
        reloadWorktime,
        [auth.data?.staticId, item.PID, item.date, filter.timeZone]
    );

    useEffect(() => {
        didMount.current = true;
        return () => {
            didMount.current = false;
        }
    }, []);

    const firstActivity = worktime.items.length > 0 ? worktime.items[0] : undefined;

    return (
        <div
            className="worktime-list-wrapper"
            tabIndex={0}
        >
            {!worktime.load &&
            <div className="worktime-list-skeleton-wrapper">
                <Skeleton active={worktime.loading} />
                <Skeleton active={worktime.loading} />
                <Skeleton active={worktime.loading} />
            </div>}
            {worktime.load && worktime.items.length === 0 && emptyText}
            {worktime.load && worktime.items.length > 0 &&
                <>
                    {firstActivity &&
                    <div className="worktime-total-wrapper">
                        <Alert
                            type="info"
                            message={
                                <>
                                    <TotalRow title='Оплачиваемое время' value={firstActivity.spt} />
                                    <TotalRow title='Время в сеансах/акт' value={firstActivity.wt} />
                                    <TotalRow title='Фактический перерыв' value={firstActivity.factBreak} />
                                    <TotalRow title='Норма перерева' value={firstActivity.normBreak} />
                                    <TotalRow title='Превышение нормы' value={firstActivity.overBreak} />
                                </>
                            }
                        />
                    </div>}
                    <AgendaList
                        className="worktime-agenda-list"
                        eventList={eventList}
                        theme="ios"
                    />
                </>
            }
        </div>
    )
}