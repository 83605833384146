import { Reducer, ReducerAction, ReducerState, useCallback, useMemo, useReducer, useState } from "react";
import { loadListReducer, ILoadListReducer, initialLoadListState, LoadListKind, LoadListReducerAction, ILoadListState } from "../Reducer/LoadListReducer";
import { OperationAbortedByComponentDestructor } from "../System/OperationAbortedByComponentDestructor";
import { OperationAbortedByMergeException } from "../System/OperationAbortedByMergeException";
import { OperationAbortedIgnoreException } from "../System/OperationAbortedIgnoreException";
import { Out, setRef } from "../System/Ref";
import { doOrAbort, isArray, isFunction } from "../System/Utils";
import { AbortFunction, useAbortController } from "./useAbortController";

export type DependencyList = ReadonlyArray<unknown>;

export interface ILoaderProps {
    clearList?: boolean;
    backgroundLoading?: boolean;
    signal?: AbortSignal;
}

export interface ILoaderInitProps<T, TReducer extends ILoadListReducer<T> = ILoadListReducer<T>> extends ILoaderProps {
    reducer?: TReducer,
    initialState?: Partial<ReducerState<TReducer>>
}

export interface IListLoaderProps<T, TReducer extends ILoadListReducer<T> = ILoadListReducer<T>> extends ILoaderInitProps<T, TReducer> {
    loader: LoaderType<T>
}

export type UpdateProps = ILoaderProps & {
    onNoMerge?: OnNoMergeCallback,
}

export type StartNewMergeProps<TResult> = UpdateProps & {
    promise?: Out<Promise<TResult>>
}

export type AbortMerge = () => void;
export type LoaderType<T> = (signal: AbortSignal) => Promise<T[]>;
export type OnNoMergeCallback = () => void;
export type UpdateFunction<TReturnType> = (propsOrAbortSignalOrNoMergeCallback?: OnNoMergeCallback | AbortSignal | UpdateProps) => Promise<TReturnType>;
export type StartNewMerge<TReturnType> = (propsOrAbortSignalOrNoMergeCallback?: OnNoMergeCallback | AbortSignal | StartNewMergeProps<TReturnType>) => AbortMerge;

let dynamicIndex = 0;

export const getAbortCallback = (...abort: (AbortMerge | undefined)[]) => () => {
    for(const fn of abort) {
        if(fn) {
            fn();
        }
    }
}

export const abortByMerge = {};
export const abortByDestructor = {};

export type ListLoaderResult<T, TReducer extends Reducer<any, any>> = [
    ReducerState<TReducer>,
    StartNewMerge<T[] | undefined | never>,
    UpdateFunction<T[] | undefined | never>,
    AbortFunction,
    React.Dispatch<ReducerAction<TReducer>>
];

export function useListLoader<T, TReducer extends ILoadListReducer<T> = ILoadListReducer<T>>(
    loader: LoaderType<T>
): ListLoaderResult<T, TReducer>;

export function useListLoader<T, TReducer extends ILoadListReducer<T> = ILoadListReducer<T>>(
    loader: LoaderType<T>,
    deps: DependencyList
): ListLoaderResult<T, TReducer>;

export function useListLoader<T, TReducer extends ILoadListReducer<T> = ILoadListReducer<T>>(
    props: IListLoaderProps<T, TReducer>
): ListLoaderResult<T, TReducer>;

export function useListLoader<T, TReducer extends ILoadListReducer<T> = ILoadListReducer<T>>(
    loader: LoaderType<T>,
    props: ILoaderInitProps<T, TReducer>
): ListLoaderResult<T, TReducer>;

export function useListLoader<T, TReducer extends ILoadListReducer<T> = ILoadListReducer<T>>(
    loader: LoaderType<T>,
    deps: DependencyList,
    props: ILoaderInitProps<T, TReducer>
): ListLoaderResult<T, TReducer>;

export function useListLoader<T, TReducer extends ILoadListReducer<T> = ILoadListReducer<T>>(
    propsOrLoader: IListLoaderProps<T, TReducer> | LoaderType<T>,
    depsOrProps?: undefined | DependencyList | ILoaderInitProps<T, TReducer>,
    props?: undefined | ILoaderInitProps<T, TReducer>
): ListLoaderResult<T, TReducer> {

    const normalizeDeps     = isArray(depsOrProps) ? depsOrProps : [dynamicIndex++];
    const normalizeSubProps = isArray(depsOrProps) ? props : depsOrProps;
    const normalizeProps    = isFunction(propsOrLoader)
                            ? { loader: propsOrLoader, ...normalizeSubProps }
                            : propsOrLoader;
    
    const {
        loader,
        clearList = true,
        backgroundLoading = false
    } = normalizeProps;

    const [reducer] = useState(() => normalizeProps.reducer
        ? normalizeProps.reducer
        : loadListReducer);
    
    const [initialState] = useState(() => ({
        ...initialLoadListState,
        ...normalizeProps.initialState
    }));

    const [internalMerge, abort] = useAbortController();
    const [list, dispatch] = useReducer<ILoadListReducer<T>>(reducer, initialState);

    const normalizeLoader = useCallback(loader, normalizeDeps);
    
    const merge = useCallback<UpdateFunction<T[] | undefined | never>>((
        propsOrAbortSignalOrNoMergeCallback?: OnNoMergeCallback | AbortSignal | StartNewMergeProps<T[] | undefined | never>
    ) => {

        const props = isFunction(propsOrAbortSignalOrNoMergeCallback)
                    ? { onNoMerge: propsOrAbortSignalOrNoMergeCallback }
                    : propsOrAbortSignalOrNoMergeCallback instanceof AbortSignal
                    ? { signal: propsOrAbortSignalOrNoMergeCallback }
                    : propsOrAbortSignalOrNoMergeCallback || {};
        
        const { onNoMerge, signal: externalSignal } = props;

        return internalMerge(async (signal) => {

            try {

                if((props?.backgroundLoading === undefined && !backgroundLoading)
                || (props?.backgroundLoading !== undefined && !props.backgroundLoading)) {

                    const clearItems = props?.clearList === undefined
                                     ? clearList
                                     : props.clearList;
                    
                    dispatch({
                        type: LoadListKind.Loading,
                        clearItems: clearItems
                    });
                }

                const result = await doOrAbort(
                    (signal) => normalizeLoader(signal),
                    signal,
                    externalSignal
                );
                
                dispatch({
                    type: LoadListKind.LoadSuccess,
                    payload: result
                });

                return result;
            }
            catch (ex: unknown) {
                
                // Игнорируем ошибку, если запущена операция прерывания для перезапуска задачи с обновленными данными или вызван деструктор компонента
                if (ex === abortByMerge ||
                    ex === abortByDestructor ||
                    ex instanceof OperationAbortedIgnoreException ||
                    ex instanceof OperationAbortedByMergeException ||
                    ex instanceof OperationAbortedByComponentDestructor) {
                    return;
                }
                
                dispatch({
                    type: LoadListKind.LoadFail,
                    error: ex
                });

                throw ex;
            }

        }, onNoMerge);

    }, [normalizeLoader, internalMerge, dispatch,
        clearList, backgroundLoading,
    ]);
    
    const reload = useCallback<StartNewMerge<T[] | undefined | never>>((
        propsOrAbortSignalOrNoMergeCallback?: OnNoMergeCallback | AbortSignal | StartNewMergeProps<T[] | undefined | never>
    ) => {
        
        const props = isFunction(propsOrAbortSignalOrNoMergeCallback)
                    ? { onNoMerge: propsOrAbortSignalOrNoMergeCallback }
                    : propsOrAbortSignalOrNoMergeCallback instanceof AbortSignal
                    ? { signal: propsOrAbortSignalOrNoMergeCallback }
                    : propsOrAbortSignalOrNoMergeCallback || {};

        const { promise, ...updateProps } = props;
        const awaiter = merge(updateProps);
        setRef(promise, awaiter);
        return () => { abort(new OperationAbortedByMergeException()); }

    }, [merge, abort]);
    
    return [
        list           as ReducerState<TReducer>,
        reload,
        merge,
        abort,
        dispatch       as React.Dispatch<ReducerAction<TReducer>>
    ]
}