import { ColumnsType } from "antd/es/table";
import { useEffect, useRef, useState } from "react";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { SortableContext, arrayMove, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { TBreak, ISurfaceBreak } from "../../../Entities/Database";
import { TemplateBreakEditorLinearItem } from "../TemplateBreakEditorLinearItem";
import { Row } from "./helpers";
import { classNames, nameof } from "../../../System/Utils";
import { Table } from 'antd';
import "./style.css";

interface BreakRow {
    breakId: string,
    break: TBreak,
    name: JSX.Element,
}

const columns: ColumnsType<BreakRow> = [
    {
        key: nameof<BreakRow>("name"),
        dataIndex: nameof<BreakRow>("name"),
        className: "column2",
        ellipsis: true,
    },
];

const columnsWithMove: ColumnsType<BreakRow> = [
    {
        key: nameof<BreakRow>("breakId"),
        width: "24px",
        className: "column1",
    },
    ...columns,
]

export function getBreakTitle(type: ISurfaceBreak["type"], array?: ISurfaceBreak[]) {
    const res = array && array.find(el => el.type === type);
    return res?.title || "undefined";
}

export interface ILinearBreaksListEditorProps {
    selectedBreak?: TBreak,
    surfaceBreaks?: ISurfaceBreak[],
    breaks?: TBreak[],
    loading?: boolean,
    sortable?: boolean,
    onSort?: (sorted: TBreak['id'][]) => void,
    onDelete?: (breakItem: TBreak) => void,
    onSelect?: (breakItem: TBreak | undefined) => void,
    onDoubleClick?: (breakItem: TBreak) => void,
}

export function LinearBreaksListEditor({
    selectedBreak,
    surfaceBreaks,
    breaks,
    loading,
    sortable,
    onSort,
    onDelete,
    onSelect,
    onDoubleClick,
}: ILinearBreaksListEditorProps) {

    const focusedRow = useRef<BreakRow | undefined>();
    const [dataSource, setDataSource] = useState<BreakRow[]>();
    const [dataSourceSort, setDataSourceSort] = useState<BreakRow['breakId'][]>([]);

    const onDragEnd = ({ active, over }: DragEndEvent) => {

        if (dataSource && active.id !== over?.id) {

            const activeIndex = dataSource.findIndex((i) => i.breakId === active.id);
            const overIndex = dataSource.findIndex((i) => i.breakId === over?.id);

            const newDataSource = arrayMove(dataSource, activeIndex, overIndex);
            const newDataSourceSort = newDataSource.map(x => x.breakId);

            setDataSource(newDataSource);
            setDataSourceSort(newDataSourceSort);

            if (onSort) {
                onSort(newDataSourceSort);
            }
        }
    }

    useEffect(() => {
        
        if (!breaks || breaks.length < 1) {
            setDataSource(undefined);
            setDataSourceSort([]);
            return;
        }

        const dataArray = breaks
            .sort((x, y) => (x.sort || 0) - (y.sort || 0))
            .map((x): BreakRow => ({
                breakId: x.id,
                break: x,
                name: (
                    <TemplateBreakEditorLinearItem
                        paid={x.isPayed}
                        title={getBreakTitle(x.type, surfaceBreaks)}
                        time={`${x.durn} мин`}
                    />
                ),
            }));
        
        setDataSource(dataArray);
        setDataSourceSort(dataArray.map(x => x.breakId));

    }, [breaks, surfaceBreaks]);

    useEffect(() => {

        const delKeyPress = (event: KeyboardEvent) => {

            if (onDelete && event.key === 'Delete') {

                if (selectedBreak) {
                    onDelete(selectedBreak);
                    return;
                }

                if (focusedRow.current) {
                    const deleteBreak = focusedRow.current.break;
                    focusedRow.current = undefined;
                    onDelete(deleteBreak);
                }
            }
        }

        document.addEventListener("keydown", delKeyPress);

        return () => {
            document.removeEventListener("keydown", delKeyPress);
        }

    }, [selectedBreak, onDelete]);

    const handleBlurRow = (event: React.FocusEvent<HTMLElement>, row: BreakRow) => {
        focusedRow.current = undefined;
        if (onSelect) {
            onSelect(undefined);   
        }
    }

    const handleFocusRow = (event: React.FocusEvent<HTMLElement>, row: BreakRow) => {
        focusedRow.current = row;
        if (onSelect) {
            onSelect(row.break);   
        }
    }

    const handleDoubleClickRow = (event: React.MouseEvent<HTMLElement>, row: BreakRow) => {
        focusedRow.current = row;
        if (onDoubleClick) {
            onDoubleClick(row.break)
        }
    }

    if (sortable === false) {

        return (
            <Table
                rowKey={nameof<BreakRow>("breakId")}
                onRow={row => ({
                    tabIndex: 0,
                    className: "linearBreakTableRow",
                    onBlur: (event) => handleBlurRow(event, row),
                    onFocus: (event) => handleFocusRow(event, row),
                    onDoubleClick: (event) => handleDoubleClickRow(event, row),
                })}
                columns={columns}
                dataSource={dataSource}
                showHeader={false}
                className="linearBreakTable"
                pagination={false}
                loading={loading}
                size="small"
            />
        )
    }

    return (
        <DndContext
            modifiers={[restrictToVerticalAxis]}
            onDragEnd={onDragEnd}
        >
            <SortableContext
                items={dataSourceSort}
                strategy={verticalListSortingStrategy}
            >
                <Table
                    size="small"
                    components={{
                        body: {
                            row: Row,
                        },
                    }}
                    rowKey={nameof<BreakRow>("breakId")}
                    onRow={row => ({
                        tabIndex: 0,
                        className: classNames("linearBreakTableRow", {
                            "selected": selectedBreak?.id === row.break.id,
                        }),
                        onBlur: (event) => handleBlurRow(event, row),
                        onFocus: (event) => handleFocusRow(event, row),
                        onDoubleClick: (event) => handleDoubleClickRow(event, row),
                    })}
                    columns={columnsWithMove}
                    dataSource={dataSource}
                    showHeader={false}
                    className="linearBreakTable"
                    pagination={false}
                    loading={loading}
                />
            </SortableContext>
        </DndContext>
        
    )
}