import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import { CartesianGrid, Legend, Line, XAxis, YAxis, Tooltip, ReferenceArea, ComposedChart, Bar } from "recharts";
import { Ref, useEffect, useState } from "react";
import { refSetter } from "../../shared/helpers";
import { IChartDataItem } from "../../../Entities/Database";

interface IChartViewItem {
    type: number,
    view: 'line' | 'bar',
    title: string,
    color: string,
}

export interface IItem {

    [source: number]: number,

    // @ts-ignore
    timestamp: number,
    // @ts-ignore
    label: string,
}

export enum RecordItemType {
    T1 = 1,
    T2 = 2,
    T3 = 3,
}

const chartLines: Record<IChartViewItem['type'], IChartViewItem> = {
    [RecordItemType.T1]: {type: RecordItemType.T1, view: 'line', title: 'Прогноз нагрузки',    color: 'rgb(47, 128, 237)'},
    [RecordItemType.T2]: {type: RecordItemType.T2, view: 'line', title: 'Расписание с учетом отпуска',   color: 'rgb(0, 0, 0)'},
    [RecordItemType.T3]: {type: RecordItemType.T3, view: 'line', title: 'Расписание', color: 'rgb(130, 202, 157)'},
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

interface InitialState {
    left: string | number;
    right: string | number;
    refAreaLeft: number | string | undefined;
    refAreaRight: number | string | undefined;
    top: string | number | ((x: number) => number);
    bottom: string | number | ((x: number) => number);
    animation: boolean;
}

const initialState: InitialState = {
    left: 'dataMin',
    right: 'dataMax',
    refAreaLeft: '',
    refAreaRight: '',
    top: (dataMax) => Math.ceil(dataMax as number) + 1,
    bottom: (dataMin) => Math.floor(dataMin as number),
    animation: false,
};

export interface IChartProps {
    boardRef?: Ref<HTMLDivElement>,
    loading?: boolean,
    width?: number,
    height?: number,
    lines?: IChartDataItem['type'][],
    dataSource?: IItem[],
    cellWidth?: number,
    widthOffset?: number,
    fullWidthOnSmall?: boolean,
    tickFormatter: (value: IItem['timestamp'], index: number) => any,
}

export function Chart({
    boardRef,
    loading,
    width,
    height,
    lines,
    dataSource,
    cellWidth = 74,
    widthOffset = 192,
    fullWidthOnSmall = true,
    tickFormatter,
}: IChartProps) {

    const [filteredData, setFilteredData] = useState(dataSource);
    const [state, setState] = useState(initialState);
    const [xTicks, setXTicks] = useState<number[]>([]);
    const [chartWidth, setChartWidth] = useState(width);

    const reset = () => {
        setFilteredData(dataSource);
        setState(initialState);
    }

    useEffect(() => {

        reset();

        if (!dataSource || !dataSource.length) {
            setXTicks([]);
            setChartWidth(width);
            return;
        }

        let min = dataSource[0].timestamp;
        let max = dataSource[0].timestamp;

        for (const item of dataSource) {
            if (min > item.timestamp) min = item.timestamp;
            if (max < item.timestamp) max = item.timestamp;
        }

        const step = (max - min) / ((dataSource.length - 1) || 1);
        const chartWidth = (dataSource.length * cellWidth) + widthOffset;
        const ticks = [];

        if (step > 0) {

            let curr = min;
            const end = max + step;

            while (curr <= end) {
                ticks.push(curr);
                curr += step;
            }
        }

        setXTicks(ticks);
        setChartWidth(fullWidthOnSmall && width && chartWidth < width ? width : chartWidth);

    }, [width, dataSource, cellWidth, widthOffset]);

    return (
        <Spin
            indicator={antIcon}
            spinning={loading}
        >
            <div
                ref={refSetter(boardRef)}
                onContextMenu={reset}
                style={{
                    userSelect: 'none',
                    width: width,
                    height: height,
                    overflowX: 'scroll',
                    overflowY: 'hidden'
                }}
            >
                <ComposedChart
                    width={chartWidth}
                    height={height ? height - 20 : undefined}
                    margin={{ top: 10, right: 10, bottom: 0, left: 130 }}
                    style={{fontSize: 10}}
                    data={filteredData}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis allowDataOverflow dataKey="timestamp" tickFormatter={tickFormatter} domain={[state.left, state.right]} type="number" interval={'preserveStart'} ticks={xTicks} />
                    <YAxis yAxisId={"1"} allowDataOverflow domain={[state.bottom, state.top]} type="number" />
                    <Legend verticalAlign="top" />
                    {lines?.map((graphSource, index) =>
                        chartLines[graphSource] && (
                        chartLines[graphSource].view === 'line' ? (
                            <Line
                                type="monotone"
                                yAxisId={"1"}
                                key={graphSource}
                                name={chartLines[graphSource].title}
                                stroke={chartLines[graphSource].color}
                                isAnimationActive={false}
                                dataKey={graphSource}
                            />
                        ) :
                        chartLines[graphSource].view === 'bar' ? (
                            <Bar
                                yAxisId={"1"}
                                key={graphSource}
                                name={chartLines[graphSource].title}
                                stroke={chartLines[graphSource].color}
                                fill={chartLines[graphSource].color}
                                isAnimationActive={false}
                                dataKey={graphSource}
                                barSize={20}
                            />
                        ):
                        (<></>)
                    ))}
                    <Tooltip labelFormatter={(label, payload) => {
                        return payload && payload.length > 0 && payload[0].payload.label || label;
                    }} />

                    {state.refAreaLeft && state.refAreaRight ? (
                        <ReferenceArea yAxisId={"1"} x1={state.refAreaLeft} x2={state.refAreaRight} strokeOpacity={0.3} />
                    ): null}
                </ComposedChart>
            </div>
        </Spin>
    )
}