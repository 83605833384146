import { Button, Input, InputRef, Space, Tooltip, TooltipProps } from "antd";
import { TableColumnType } from "antd";
import React, { createContext, useCallback, useContext, useMemo, useRef, useState } from "react";
import { IColumnSelector, searchInValueBuf } from "../Reducer/SearchFilterReducer";
import { contextNotInitFn } from "./helpers";
import { isNumber, isString } from "../System/Utils";
import { assignRef } from "../Components/shared/helpers";

import Highlighter from 'react-highlight-words';

import {
    SearchOutlined
} from '@ant-design/icons';

export interface IColumnSearcherLang {
    search: string,
    find: string,
    reset: string,
    filter: string
}

export const defaultLang: IColumnSearcherLang = {
    search: 'Поиск',
    find: 'Найти',
    reset: 'Сбросить',
    filter: 'Отфильтровать',
}

export interface SearchColumnType<T> extends TableColumnType<T> {
    key: React.Key,
    searchRender?: (value: any, record: T, searchValue: string, index: number) => React.ReactNode;
}

export type SearchStore = {
    [key: React.Key]: React.Key | undefined
}

export type SearchState = {
    store?: SearchStore,
    searchedColumnKey?: React.Key
}

export type GetColumnSearchPropsFn<T = any> = (
    columnProps: SearchColumnType<T>,
    dataIndex: keyof T | IColumnSelector<T>,
    placeholder?: string,
    tooltip?: boolean | TooltipProps,
    lang?: IColumnSearcherLang,
    customRender?: (value: any, record: T, searchValue: React.Key | undefined, index: number) => React.ReactNode,
) => SearchColumnType<T>

export interface IColumnSearchData<T = any> {
    searchInput: React.MutableRefObject<InputRef | null>,
    searchState: SearchState,
    getColumnSearchProps: GetColumnSearchPropsFn<T>
}

const columnSearchContext = createContext<IColumnSearchData<any>>({
    searchInput: {current: null},
    searchState: {},
    getColumnSearchProps: contextNotInitFn,
});

export function useProvideColumnSearch<T>() {

    const searchInput = useRef<InputRef>();
    const [searchState, setSearchState] = useState<SearchState>({});
    
    const getColumnSearchProps = useCallback<GetColumnSearchPropsFn<T>>((columnProps, dataIndex, placeholder, tooltip, lang, customRender) => {

        const { key } = columnProps;

        lang ??= defaultLang;

        const dataIndexIsObj = typeof dataIndex === 'object';
        const dataIndexOrTitle = dataIndexIsObj ? dataIndex.title : dataIndex as string;
        const dataIndexSelector = dataIndexIsObj ? dataIndex.valueSelector : (item: T) => item[dataIndex];

        return {
            ...columnProps,
            getSearchValue: () => searchState.store && searchState.store[key],
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    <Input
                        ref={node => assignRef(node, searchInput)}
                        placeholder={placeholder ?? `${lang?.search} ${dataIndexOrTitle}`}
                        value={selectedKeys[0]}
                        onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                        onPressEnter={() => {
                            confirm();
                            setSearchState(prev => ({
                                store: {
                                    ...prev.store,
                                    [key]: selectedKeys[0]
                                },
                                searchedColumn: key,
                            }));
                        }}
                        style={{ marginBottom: 8, display: 'block' }}
                    />
                    <Space>
                        <Button
                            type="primary"
                            onClick={() => {
                                confirm();
                                setSearchState(prev => ({
                                    store: {
                                        ...prev.store,
                                        [key]: selectedKeys[0]
                                    },
                                    searchedColumn: key,
                                }));
                            }}
                            icon={<SearchOutlined />}
                            size="small"
                            style={{ width: 90 }}
                        >
                            {lang?.find}
                        </Button>
                        <Button
                            size="small"
                            style={{ width: 90 }}
                            onClick={() => {

                                if(clearFilters) {
                                    clearFilters();
                                }

                                confirm({ closeDropdown: false });
                                setSearchState(prev => ({
                                    store: {
                                        ...prev.store,
                                        [key]: selectedKeys[0]
                                    },
                                    searchedColumn: key,
                                }));
                            }}
                        >
                            {lang?.reset}
                        </Button>
                        <Button
                            type="link"
                            size="small"
                            onClick={() => {
                                confirm({ closeDropdown: false });
                                setSearchState(prev => ({
                                    store: {
                                        ...prev.store,
                                        [key]: selectedKeys[0]
                                    },
                                    searchedColumn: key,
                                }));
                            }}
                        >
                            {lang?.filter}
                        </Button>
                    </Space>
                </div>
            ),
            filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
            onFilter: (input, record) => searchInValueBuf(dataIndexSelector(record), input.toString()),
            onFilterDropdownOpenChange: visible => {
                if (visible) {
                    setTimeout(() => searchInput.current?.select(), 100);
                }
            },
            onCell: (record) => {
                return tooltip === true ? {} : {
                    title: dataIndexSelector(record)
                }
            },
            render: (text, record, index) => {

                const value = dataIndexSelector(record);
                const searchText = searchState.store && searchState.store[key];
                const content = customRender
                ? customRender(text, record, searchText, index)
                : (isString(value) || isNumber(value))
                ? (
                    <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText as string]}
                        autoEscape
                        textToHighlight={value ? value.toString() : ''}
                    />
                )
                : value ;

                if(tooltip) {

                    if(tooltip === true) {

                        return (
                            <Tooltip
                                title={value}
                                placement="leftTop"
                            >
                                {content}
                            </Tooltip>
                        );
                    }

                    return (
                        <Tooltip
                            title={value}
                            placement="leftTop"
                            {...tooltip}
                        >
                            {content}
                        </Tooltip>
                    );
                }

                return content;
            },
        }
        
    }, [searchState]);

    return {
        searchInput,
        searchState,
        getColumnSearchProps
    }
}

export function ColumnSearchProvider<T>({ children }: any) {
    const use = useProvideColumnSearch<T>() as IColumnSearchData<T>;
    return <columnSearchContext.Provider value={use}>{children}</columnSearchContext.Provider>;
}

export const useColumnSearch = <T extends unknown>() => {
    return useContext(columnSearchContext) as IColumnSearchData<T>;
};