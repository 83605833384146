import { Button, message } from "antd";
import { EditOutlined } from '@ant-design/icons';
import { useState } from "react";
import { ListItemType } from "../../../Entities/EmployeeCard";
import { IEmployee } from "../../../Entities/Database";
import { ItemDetailsEditModal } from "../ItemDetailsEditModal";
import "./style.css";

export interface IEditButtonProps<T> {
    onFinish?: () => void,
    employee?: IEmployee,
    data?: T[],
    listItemType: ListItemType,
}

export function EditButton<T>({onFinish, data, employee, listItemType}: IEditButtonProps<T>) {

    const [openModal, setOpenModal] = useState(false);
   
    const handleClick = (e: React.BaseSyntheticEvent) => {
        e.preventDefault();
        setOpenModal(true);
    }

    const handleFinishSave = () => {
        
        message.success('Данные сохранены');
        setOpenModal(false);

        if (onFinish) {
            onFinish();
        }
    }

    const handleCloseModal = () => {
        setOpenModal(false);
    }

    return (
        <>
            <Button
                className="edit__button"
                icon={<EditOutlined />}
                type="link"
                onClick={handleClick}
            />
            {listItemType === ListItemType.SpecialMarks && 
                <ItemDetailsEditModal 
                    isOpen={openModal}
                    onFinish={handleFinishSave}
                    onClose={handleCloseModal}
                    listItemType={listItemType}
                    employeeList={employee ? [employee] : []}
                />
            }
            {listItemType === ListItemType.Skills && 
                <ItemDetailsEditModal 
                    isOpen={openModal}
                    onFinish={handleFinishSave}
                    onClose={handleCloseModal}
                    listItemType={listItemType}
                    employeeList={employee ? [employee] : []}
                />
            }
        </>
    )
}