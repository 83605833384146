import {  Button, Collapse, CollapseProps, Skeleton, Space } from "antd";
import { TagItem } from "../TagItem";
import classNames from "classnames";
import { useEffect, useState } from "react";
import { ListItemType } from "../../../Entities/EmployeeCard";
import { EditButton } from "../EditButton";
import { IEmployee } from "../../../Entities/Database";
import "./style.css";


export interface ITagCollapseProps<T> {
    onFinish?: () => void,
    data?: T[],
    edit?: boolean,
    listItemType: ListItemType,
    employee?: IEmployee,
    items: CollapseProps['items'],
    loading: boolean,
}

export function ListItemCollapse<T>({onFinish, data, edit, listItemType, employee, items, loading}: ITagCollapseProps<T>) {

    return (
        <div className="tagCollapse__relative-wrap">
            
            <Collapse 
                className="employee-collapse tag-colapse" 
                items={items}  
                
            />
            {!loading &&
            edit &&
            <EditButton
                data={data}
                listItemType={listItemType}
                employee={employee}
                onFinish={onFinish}
            />}
        </div>
    );
}
// export const TagCollapseItem = memo(NotMemoizedTagCollapseItem);
