import { Select, SelectProps, Skeleton } from "antd";
import { useMemo } from "react";
import { Module } from "../../Entities/Database";
import { backendUrls } from "../../Entities/BackendGenerator";
import CheckAccess from "../CheckAccess";

export interface ISelectServerProps extends Omit<SelectProps, 'children'> {

}

export function SelectServer(props: ISelectServerProps) {

    const items = useMemo(() => Object.entries(backendUrls).map((server) => <Select.Option key={server[0]} value={server[1]}>{server[0]}</Select.Option>), [backendUrls]);
    
    return (
        <CheckAccess
            module={Module.SelectGeneratorServer}
            loadingRenderer={() => (
                <Skeleton.Input
                    active
                    size={props.size === "middle" ? "default" : props.size}
                    style={{width: 100, ...props.style}}
                />
            )}
        >
            <Select
                {...props}
                style={{width: 100, ...props.style}}
                loading={props.loading}
                defaultValue={props.defaultValue || backendUrls["Gen2"]}
            >
                {items}
            </Select>
        </CheckAccess>
    );
}

export default SelectServer;