import { Tag } from "antd";
import { memo, useEffect, useState } from "react";
import { ListItemType } from "../../../Entities/EmployeeCard";
import { GroupMember, IProjectGroup, ISkill, ISpecialMark } from "../../../Entities/Database";
import { defaultColor, getTagContent, maxLengthTag } from "./helper";
import "./style.css";

export interface ITagItemProps {
    edit?: boolean,
    listItemType: ListItemType,
    hide?: boolean,
    elementsAmount: number,
    item: IProjectGroup & ISkill & ISpecialMark,
}

function NotMemoizedTagItem(props: ITagItemProps) {
   
    const { item, listItemType, hide, elementsAmount } = props;
    const {content, id, groupMember, color } = item;

    const [tagColor, setTagColor] = useState("");
    const [tagContent, setTagContent] = useState("");

    useEffect(() => {

        if (listItemType === ListItemType.ProjectGroup) {
            if (groupMember === GroupMember.Watcher) setTagColor("volcano");
            setTagContent(getTagContent(content, maxLengthTag, elementsAmount));
        }

        else if (listItemType === ListItemType.Skills) {
            if (color) setTagColor(color);
            setTagContent(getTagContent(content, 20, elementsAmount));
        }

        else if (listItemType === ListItemType.SpecialMarks) {
            
            if (color) setTagColor(color);
            else       setTagColor(defaultColor);
            
            setTagContent(getTagContent(content, maxLengthTag, elementsAmount));
        }

    }, []);
     
    return (
        <Tag
            title={content}
            color={color}
            className={hide ? "tagItem__tag hide": "tagItem__tag"} 
        >
            {tagContent}
        </Tag>
    )
}
export const TagItem = memo(NotMemoizedTagItem);