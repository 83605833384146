import { useEffect, useState } from "react";
import { IActivityNote, IActivityNoteBase, IOriginalActivity, initActivityNote, saveActivityNote } from "../../Entities/Database";
import DraggableModal from "../DraggableModal";
import { useEntityLoader, abortByDestructor } from "../../Hooks/useEntityLoader";
import useAuth from "../../Hooks/useAuth";
import { Skeleton, message as AntdMessage, Form, Input, Divider, Alert, Switch } from "antd";
import { isEmptyOrNullOrUndefined, nameof } from "../../System/Utils";
import { BackendResponseException } from "../../System/BackendResponseException";
import useActual from "../../Hooks/useActual";
import './note-modal.css';

function InfoRow({title, value}: {title: string, value?: string | number | undefined}) {
    return (
        <div
            className="activity-note-row"
            title={`${title}: ${value}`}
        >
            <div className="activity-note-title">{title}:</div>
            <div className="activity-note-value">{value}</div>
        </div>
    )
}

export interface IActivityNoteEditorModalProps {
    open?: boolean,
    activity?: IOriginalActivity,
    onAccept?: () => void,
    onCancel?: () => void,
}

export function ActivityNoteEditorModal({
    open,
    activity,
    onAccept,
    onCancel,
}: IActivityNoteEditorModalProps) {

    const auth = useAuth();
    const [form] = Form.useForm<IActivityNoteBase>();
    const [saving, setSaving] = useState(false);
    const [message, contextMessageHolder] = AntdMessage.useMessage();
    const [noteState, reloadNote, , abortLoadingNote] = useEntityLoader<IActivityNote | undefined>(async (signal) => {

        if (!activity) {
            return undefined;
        }

        return await initActivityNote(auth, activity, signal);
    });

    const onAcceptActual = useActual(onAccept);
    const handleFinish = async (values: IActivityNoteBase) => {

        try {
            setSaving(true);
            await saveActivityNote(auth, values);
            
            if (onAcceptActual.current) {
                onAcceptActual.current();
            }
        }
        catch (ex) {
            message.error(ex instanceof BackendResponseException ? ex.message : "Не удалось сохранить заметку");
            console.error(ex);
        }
        finally {
            setSaving(false);
        }
    }

    const handleOk = async () => {
        form.submit();
    }

    useEffect(() => {
        if (open && noteState.error) {
            message.error("Не удалось загрузить заметку.");
        }
    }, [noteState.error]);

    useEffect(() => {
        
        if (!open) {
            abortLoadingNote(abortByDestructor);
            return;
        }

        return reloadNote();

    }, [activity, open]);

    useEffect(() => {

        if (open) {

            form.resetFields();

            if (noteState.entity) {

                form.setFieldsValue({
                    id: noteState.entity.id,
                    accepted: false, //noteState.entity.accepted,
                    comment: noteState.entity.comment,
                });
            }
        }

    }, [noteState.entity]);

    const accepted = noteState.load && noteState.entity && noteState.entity.accepted;

    return (
        <>
            <DraggableModal
                open={open}
                onOk={handleOk}
                onCancel={onCancel}
                width={400}
                title={accepted === null ? "Добавление заметки" : "Заметка"}
                okText={accepted === null ? "Сохранить" : "Изменить"}
                cancelText={accepted === null ? "Отмена" : "Закрыть"}
                closable={!saving}
                keyboard={!saving}
                cancelButtonProps={{
                    disabled: saving,
                }}
                okButtonProps={{
                    loading: saving,
                    disabled: noteState.loading || !noteState.entity || noteState.entity.accepted === true,
                    hidden: accepted === true,
                    style: {
                        display: accepted === true ? 'none' : undefined,
                    }
                }}
            >
                {noteState.loading && <Skeleton active={!noteState.error} loading={noteState.loading} />}
                <Form
                    form={form}
                    disabled={saving}
                    hidden={!noteState.load}
                    onFinish={handleFinish}
                    size="small"
                >
                    <Divider />
                    <Alert
                        type="info"
                        message={
                            <>
                                <InfoRow title="Сотрудник" value={noteState.entity?.fio} />
                                <InfoRow title="Событие" value={noteState.entity?.caption} />
                                <InfoRow title="Дата" value={noteState.entity?.sd} />
                                <InfoRow title="Продолжительность" value={noteState.entity?.durn} />
                                {!isEmptyOrNullOrUndefined(noteState.entity?.pidNote) &&
                                <>
                                    <InfoRow title="Комментарий сотрудника" />
                                    <Input.TextArea readOnly={true} value={noteState.entity?.pidNote} />
                                </>}
                            </>
                        }
                    />
                    <Divider />
                    <Form.Item
                        label="ID"
                        name={nameof<IActivityNote>('id')}
                        hidden
                    >
                        <Input readOnly />
                    </Form.Item>
                    <Form.Item
                        label="Оштрафовать"
                        name={nameof<IActivityNote>('accepted')}
                        htmlFor="checked"
                        valuePropName="checked"
                        hidden={accepted === true}
                    >
                        <Switch />
                    </Form.Item>
                    <Form.Item
                        label="Комментарий"
                        name={nameof<IActivityNote>('comment')}
                    >
                        <Input.TextArea
                            readOnly={accepted === true}
                            autoSize={{
                                minRows: 5,
                            }}
                        />
                    </Form.Item>
                </Form>
            </DraggableModal>
            {contextMessageHolder}
        </>
    );
}