import React, { Ref, useEffect, useMemo, useRef, useState } from 'react';
import moment from "moment";
import useAuth from '../../Hooks/useAuth';
import { Col, Divider, Input, Modal, Row, Select, Space, Spin, message as AntdMessage } from 'antd';
import { ILoadListState } from '../../Reducer/LoadListReducer';
import { IScheduleBoardEmployee, IScheduleBoardFilter, IActivityBase, ActivityRoleType, IOriginalExtensionActivity, deletePoint, IOriginalActivity } from '../../Entities/Database';
import { generateRegexesOrigRuEn, satisfiesStrValue, } from '../../Reducer/SearchFilterReducer';
import { capacitounce, delay, isEmptyOrNullOrUndefined, isString } from '../../System/Utils';
import { useListProvider } from './useListProvider';
import { ExclamationCircleOutlined, LoadingOutlined} from '@ant-design/icons';
import { IScheduleEmployeeActivity, normalizeDate } from '../../Entities/IScheduleEmployeeActivity';
import { EditEventModal, IEditEventBag, IFields, IFieldsSettings, IValues } from './edit-event-modal';
import { sortEmploeesByActivities } from './utils';
import { allowEditEvent, extensionIdPrefix, getAllowExtensionSpaces, getEmployeeExtensionResourceId, getEmployeeResourceId, IEmployeeResource, IExtensionResource, isExtensionAction, isExtensionResource, normalizeEvent, ResourceId } from './helpers';
import { EventClickAction, EventCreateAction, EventOperationAction, getEventsForAction, normalizeAction } from './action-helpers';
import { hasActiveSaveProcess, safeSaveChanges } from './save-helpers';
import { assignRef, refSetter } from '../shared/helpers';
import { getEmployeeIcon } from '../EmployeesDayDataBoard/EmployeesDayDataTable/helpers';
import equal from 'fast-deep-equal';
import useActual from '../../Hooks/useActual';
import { ActivityNoteEditorModal } from './note-modal';
import { Eventcalendar, localeRu, MbscCalendarColor, MbscCalendarEvent, MbscEventcalendarOptions, MbscEventcalendarView, MbscEventClickEvent, MbscEventCreateEvent, MbscEventDeleteEvent, MbscEventUpdateEvent, MbscEventUpdateFailedEvent } from '../shared/lib';
import { IValidateProps } from '../shared/lib/src/core/util/datetime';
import { addDays, addHours, addMinMax, getDayEnd, getDayStart } from '../shared/libHelpers';
import './style.css';

const emptyResName = 'DEF_EMPTY_RES';
const emptyResources: IEmployeeResource[] = [{
    id: emptyResName,
    employee: undefined as any,
    eventCreation: false,
}];

export type SortType = 'start' | 'end' | 'asc' | 'desc';

export const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export interface IEditEventModalProps {
    open?: boolean,
    roleId?: ActivityRoleType,
    fields?: IFields,
    fieldsSettings?: IFieldsSettings,
    values?: IValues
}

export type TIntervalEditorUpdater = ReturnType<typeof useListProvider>;

export interface IIntervalActivityEditorProps {
    editorRef?: Ref<Eventcalendar>,
    loaderProviderRef?: Ref<TIntervalEditorUpdater>,
    timeStep?: number,
    loading?: boolean,
    disableControl?: boolean,
    filter: IScheduleBoardFilter,
    date: moment.Moment,
    employees: ILoadListState<IScheduleBoardEmployee>,
    employee?: IScheduleBoardEmployee,
    width?: number | string,
    height?: number | string,
    onChange?: () => void,
    onSave?: () => void,
    onFinishSave?: () => void
}

export function DayIntervalEditor({
    editorRef,
    loaderProviderRef,
    timeStep = 60,
    loading,
    disableControl,
    filter,
    date,
    employees,
    employee,
    width,
    height,
    onChange,
    onSave,
    onFinishSave,
}: IIntervalActivityEditorProps) {

    const didMount = useRef(false);
    const auth = useAuth();
    const calendarViewRef = useRef<Eventcalendar>(null);
    const prevUpdateEventsStateRef = useRef<IScheduleEmployeeActivity[]>();

    const [modal, contextModalHolder] = Modal.useModal();
    const [message, contextMessageHolder] = AntdMessage.useMessage();

    const [openNoteModal, setOpenNoteModal] = useState(false);
    const [activityForNote, setActivityForNote] = useState<IOriginalActivity | undefined>();
    const [loadExActivityList, setLoadExActivityList] = useState(false);
    const [expandEmployee, setExpandEmployee] = useState<IScheduleBoardEmployee | undefined>();
    const [bufferedAction, setBufferedAction] = useState<EventOperationAction>();
    const [roleIds, setRoleIds] = useState<ActivityRoleType[]>([]);
    const [reloadEventsCount, setReloadEventsCount] = useState(() => 0);
    const [events, setEvents] = useState<IScheduleEmployeeActivity[]>([]);
    const [searchStr, setSearchStr] = useState('');
    const [sortType, setSortType] = useState<SortType>('start');
    const [resources, setResources] = useState<IEmployeeResource[]>([]);
    const [dragTimeStep, setDragTimeStep] = useState(5);
    const view = useMemo<MbscEventcalendarView>(() => ({
        timeline: {
            size: 3,
            type: 'day',
            startDay: 1,
            endDay: 0,
            timeCellStep: timeStep,
            timeLabelStep: timeStep,
            startTime: '00:00',
            endTime: '00:00',
        }
    }), [timeStep]);

    const [editEventModalProps, setEditEventModalProps] = useState<IEditEventModalProps>(() => ({
        open: false,
        fieldsSettings: {
            durationStep: dragTimeStep,
            minDuration: dragTimeStep,
            maxDuration: timeStep
        }
    }));

    const [refDate, min, max, dateOfJsDate] = useMemo(() => {

        const refDate = date?.toDate();

        if(!refDate) {
            return [];
        }

        return [
            addDays(refDate, -1),
            addHours(getDayEnd(addDays(refDate, -1)), -16),
            addHours(getDayStart(addDays(refDate, 1)), 16),
            refDate,
        ]

    }, [date]);

    const dayFilter = useMemo(() => ({
        ...filter,
        from: date.startOf('day'),
        to: date.startOf('day')
    }), [filter, date]);

    const listProvider = useListProvider({
        loading,
        disableControl,
        roleIds,
        employee,
        selectedEmployee: expandEmployee,
        filter: dayFilter,
    });

    assignRef(listProvider, loaderProviderRef);
    
    //#region useEffect

    useEffect(() => {

        if(loading) {
            setLoadExActivityList(false);
        }

    }, [loading]);

    useEffect(() => {

        const sortable = [ ...employees.items ].sort();
        const newRoleIds = new Set(sortable.map(x => x.roleId));

        setRoleIds(Array.from(newRoleIds));

    }, [employees.items, employees.load]);

    useEffect(() => {

        if(!listProvider.activityList.load) {
            setEvents([]);
            return;
        }

        const convertedEvents = listProvider
                                .activityList
                                .items
                                .map(normalizeEvent);
        
        setEvents(convertedEvents);
        setReloadEventsCount(x => x + 1);

    }, [listProvider.activityList.load,
        listProvider.activityList.items
    ]);
    
    useEffect(() => {
        
        if(!listProvider.scheduleEmployeeExtensionActivityList.load) {
            setEvents(events => events.filter(event => !event.id.startsWith(extensionIdPrefix)));
            return;
        }

        const extensionEvents = listProvider.scheduleEmployeeExtensionActivityList.items.map(normalizeEvent);

        setEvents(events => {
            const newEvents = events.filter(event => !event.id.startsWith(extensionIdPrefix));
            newEvents.push(...extensionEvents);
            return newEvents;
        });

    }, [
        listProvider.activityList.load,
        listProvider.activityList.items,
        listProvider.scheduleEmployeeExtensionActivityList.load,
        listProvider.scheduleEmployeeExtensionActivityList.items,
        expandEmployee
    ]);

    const filteredEmployees = useMemo(() => {

        if(employee) {
            return [employee];
        }

        const timeSort = sortType === 'start' || sortType === 'end';

        if(!employees.load) {
            setResources([]);
            return [];
        }

        const sortable = timeSort
        ? sortEmploeesByActivities(employees.items, events, date, sortType)
        : [...employees.items].sort((a, b) => 
            sortType === 'asc'
            ? a.surname?.localeCompare(b.surname)
            : b.surname?.localeCompare(a.surname)
        );

        if(!isEmptyOrNullOrUndefined(searchStr)) {
            const regexs = generateRegexesOrigRuEn(searchStr);
            return sortable.filter(employee => satisfiesStrValue(employee.surname, searchStr, ...regexs));
        }
        
        return sortable;

    }, [reloadEventsCount,
        date,
        employee,
        searchStr,
        sortType,
        employees.load,
        employees.items
    ]);

    const filteredResources = useMemo(() => {

        return filteredEmployees.map((employee): IEmployeeResource => ({
            id: getEmployeeResourceId(employee),
            name: `${employee.surname} ${employee.firstName}`,
            employee: employee,
            collapsed: expandEmployee?.PID !== employee.PID,
        }));

    }, [filteredEmployees]);

    const filteredResourcesWithExtensions = useMemo(() => {

        const extensionsList = listProvider.scheduleEmployeeExtensionList;

        if(extensionsList && extensionsList.load) {

            const extensions = extensionsList.items;

            if(extensions.length > 0) {
                
                const resources = filteredResources.map((resource) => {

                    const children: IExtensionResource[] = [];
                    const employee = resource.employee;

                    for(const extension of extensions) {

                        children.push({
                            id: getEmployeeExtensionResourceId(employee, extension),
                            name: extension.title,
                            employee: employee,
                            extension: extension,
                            eventCreation: !extension.readonly
                        });
                    }

                    return {
                        ...resource,
                        children: children
                    }
                });

                return resources;
            }
        }

        return filteredResources;

    }, [filteredResources,
        listProvider.scheduleEmployeeExtensionList.load,
        listProvider.scheduleEmployeeExtensionList.items,
    ]);

    useEffect(() => {

        const newResources = filteredResourcesWithExtensions.map(resource => ({
            ...resource,
            collapsed: expandEmployee?.PID !== resource.employee.PID
        }));
        
        setResources(newResources.length > 0
            ? newResources
            : emptyResources
        );

    }, [filteredResourcesWithExtensions, expandEmployee]);

    //#endregion

    const currentOnChangeRef = useActual(onChange);
    const currentOnFinishSaveRef = useActual(onFinishSave);
    const currentUSEEALRef = useActual(listProvider.updateScheduleEmployeeExtensionActivityList);
    
    const saveNewEvents = async (
        resource: ResourceId | undefined,
        events: IScheduleEmployeeActivity[],
        newEvents: IScheduleEmployeeActivity[]
    ) => {

        if (onSave) {
            onSave();
        }
        
        try {

            const result = await safeSaveChanges(auth, dayFilter, employees, resource, date, events, newEvents);

            if (didMount.current && result === true) {

                setExpandEmployee(value => {

                    if(value) {
                        const updater = currentUSEEALRef.current;
                        setLoadExActivityList(true);
                        updater(() => {
                            setLoadExActivityList(false);
                        });
                    }

                    return value;
                });

                if (currentOnChangeRef.current) {
                    currentOnChangeRef.current();
                }
            }
        }
        finally {

            if (currentOnFinishSaveRef.current && !hasActiveSaveProcess()) {
                currentOnFinishSaveRef.current();
            }
        }
    }

    const rollbackEventsRef = useRef(events);

    const handleEventCreateOrEditWindow = (
        action: EventClickAction | EventCreateAction,
        inst: Eventcalendar
    ) => {

        const orgAction = action.data.event.originalActivity;
        
        if (orgAction && isExtensionAction(orgAction)) {

            if (orgAction.canNote) {
                setActivityForNote(orgAction);
                setOpenNoteModal(true);
            }

            setEvents(events => [...events]);
            return;
        }

        if (disableControl) {
            setEvents(events => [...events]);
            return;
        }

        if (editEventModalProps.open) {
            console.error("Editor is open");
            setEvents(events => [...events]);
            return;
        }

        const filteredEmployees = employees.items.filter(employee => employee.PID === action.data.event.resource);
        if (!filteredEmployees || filteredEmployees.length !== 1) {
            console.error("Failed detect employee");
            setEvents(events => [...events]);
            return;
        }

        const employee = filteredEmployees[0];

        setEvents(events => {

            // Запрещаем редактирование активности, которая доступна только для чтения
            if (action.type === 'click') {
                const orgAction = action.data.event.originalActivity;
                if (orgAction && !allowEditEvent(orgAction)) {
                    return [...events];
                }
            }

            const normalizedAction = normalizeAction(action, events, timeStep);

            if (normalizedAction !== false) {

                let canEditDuration = false;

                if (normalizedAction.type === 'create' &&
                    normalizedAction.data.action === 'click') {
                    canEditDuration = true;
                }
                else if (normalizedAction.type === 'embedding') {
                    canEditDuration = false;
                }

                setBufferedAction(normalizedAction);
                setEditEventModalProps(prev => ({
                    ...prev,
                    open: true,
                    roleId: employee.roleId,
                    values: {},
                    fields: {
                        duration: canEditDuration
                    }
                }));

                rollbackEventsRef.current = [...events];
                const newEvents = getEventsForAction(normalizedAction, events);
                return newEvents;
            }

            return [...events];
        });
    }

    const handleCancelChooseModal = (event?: React.MouseEvent<HTMLElement, MouseEvent>) => {

        const newEvents = [...rollbackEventsRef.current];

        setEvents(newEvents);
        setBufferedAction(undefined);
        setEditEventModalProps(prev => ({
            ...prev,
            open: false,
        }));
    }

    const handleOkInChooseModal = (bag: IEditEventBag<IActivityBase>, htmlEvent: React.MouseEvent<HTMLElement, MouseEvent>) => {

        if (disableControl) {
            return;
        }

        if(!bufferedAction) {
            return;
        }

        const rollback = [...rollbackEventsRef.current];
        const activity = bufferedAction.data.event;
        const resource = activity.resource;

        setBufferedAction(undefined);
        setEditEventModalProps(prev => ({
            ...prev,
            open: false,
        }));

        setEvents(events => {

            const newEvents = getEventsForAction({
                type: 'edit',
                data: {
                    bag: bag,
                    action: bufferedAction,
                    rollback: rollback
                }
            }, events);

            saveNewEvents(resource, events, newEvents);

            return newEvents;
        });
    }

    const handleEventCreated = (args: MbscEventCreateEvent, inst: Eventcalendar) => {

        if(!loading
        && employees.load
        && listProvider.activityList.load) {

            handleEventCreateOrEditWindow({
                type: 'create',
                data: args
            }, inst);

            return;
        }

        setEvents(events => [...events]);
    }

    const handleDeleteExtensionActivity = async (
        modalHandler: ReturnType<typeof modal.confirm>,
        event: MbscEventDeleteEvent,
    ) => {

        if (onSave) {
            onSave();
        }

        try {

            const targetEvent = event.event as IScheduleEmployeeActivity;
            const originalActivity = targetEvent.originalActivity;

            if(originalActivity.deletable === false
            || !isExtensionAction(originalActivity)) {
                return false;
            }

            await deletePoint(auth, originalActivity);

            setEvents(events => {

                const newEvents = getEventsForAction({
                    type: 'delete',
                    data: event
                }, events);
                
                return newEvents;
            });

            if (currentOnChangeRef.current) {
                currentOnChangeRef.current();
            }
        }
        catch (ex) {
            message.error('Ошибка удаления');
            console.error(ex);
        }
        finally {

            if (currentOnFinishSaveRef.current && !hasActiveSaveProcess()) {
                currentOnFinishSaveRef.current();
            }
        }
    }

    const handleEventDelete = (args: MbscEventDeleteEvent, inst: Eventcalendar) => {
        
        if (disableControl) {
            return false;
        }

        const targetEvent = args.event as IScheduleEmployeeActivity;
        const originalActivity = targetEvent.originalActivity;
        const resource = targetEvent.resource;
        
        if (originalActivity.deletable === false) {
            return false;
        }

        if (isExtensionAction(originalActivity)) {

            const confirmModalHandler = modal.confirm({
                title: 'Подтвердите удаление',
                icon: <ExclamationCircleOutlined />,
                autoFocusButton: 'cancel', 
                content: 'Вы уверены, что хотите удалить маркер ?',
                okText: 'Да',
                cancelText: 'Нет',
                onOk: () => handleDeleteExtensionActivity(confirmModalHandler, args)
            });

            return false;
        }

        setEvents(events => {

            const newEvents = getEventsForAction({
                type: 'delete',
                data: args
            }, events);

            saveNewEvents(resource, events, newEvents);
            return newEvents;
        });
    }

    const handleEventDoubleClick = (args: MbscEventClickEvent, inst: Eventcalendar) => {
        
        if (disableControl) {
            return;
        }

        const start = new Date(normalizeDate(args.event.start));
        const end   = new Date(normalizeDate(args.event.end));

        if ((!!min && start < min) || (!!max && end > max)) {
            return;
        }

        handleEventCreateOrEditWindow({
            type: 'click',
            data: args,
        }, inst);
    }

    const handleEventUpdate = (args: MbscEventUpdateEvent, inst: Eventcalendar) => {

        // Сбрасываем буфер, чтобы откат не сработал
        // handleEventUpdate
        // handleEventUpdateFailed
        prevUpdateEventsStateRef.current = undefined;

        if (disableControl) {
            return false;
        }
        
        // Запрет перетаскивания активностей между сотрудниками
        if (args.event.resource !== args.oldEvent?.resource) {
            return false;
        }

        const targetEvent = args.event;
        const newStart = targetEvent.start;
        const newEnd = targetEvent.end;
        const resource = targetEvent.resource;

        if (targetEvent?.originalActivity?.movable === false ||
            newStart === undefined ||
            newEnd === undefined) {
            return false;
        }

        if (!isString(resource)) {
            console.error("Resource must have type string");
            return false;
        }

        setEvents(events => {

            prevUpdateEventsStateRef.current = [...events];

            const newEvents = getEventsForAction({
                type: 'update',
                data: args
            }, events);

            saveNewEvents(resource, events, newEvents);
            return newEvents;
        });

        return false;
    }

    const handleEventUpdateFailed = (args: MbscEventUpdateFailedEvent, inst: Eventcalendar) => {

        if(prevUpdateEventsStateRef.current) {

            const resource = args.event.resource;
            const newEvents = [...prevUpdateEventsStateRef.current];

            setEvents(events => {
                saveNewEvents(resource, events, newEvents);
                return newEvents;
            });
        }
    }

    const handleResourceCollapse: MbscEventcalendarOptions['onResourceCollapse'] = (args, inst) => {
        setExpandEmployee(undefined);
    }

    const handleResourceExpand: MbscEventcalendarOptions['onResourceExpand'] = (args, inst) => {
        const resource = args.resource;
        const expandEmployees = employees.items.filter(employee => employee.PID === resource);
        setExpandEmployee(expandEmployees[0]);
    }

    const [invalids, setInvalids] = useState<IValidateProps[]>([]);
    const [colors, setColors] = useState<MbscCalendarColor[]>([]);
    const [selectedEvents, setSelectedEvents] = useState<MbscCalendarEvent[]>([]);

    const handleSelectedEventsChange: MbscEventcalendarOptions['onSelectedEventsChange'] = (args, inst) => {
        setSelectedEvents(args.events);
    }

    // TODO: разобрать баг при резком бросании события
    const fastDragEndFixRef = useRef<ReturnType<typeof setTimeout>>();
    const handleEventDragStart: MbscEventcalendarOptions['onEventDragStart'] = (args) => {
        clearTimeout(fastDragEndFixRef.current);
        fastDragEndFixRef.current = setTimeout(() => {

            const targetEvent = args.event;

            if (targetEvent) {
                const [invalids, colors] = getAllowExtensionSpaces(targetEvent, resources, min, max);
                setInvalids(invalids);
                setColors(colors);
            }

        }, 100);
    }

    const handleEventDragEnd: MbscEventcalendarOptions['onEventDragEnd'] = () => {
        clearTimeout(fastDragEndFixRef.current);
        fastDragEndFixRef.current = setTimeout(() => {
            setInvalids([]);
            setColors([]);
        }, 300);
    }

    const handleCancelNoteModal = () => {
        setActivityForNote(undefined);
        setOpenNoteModal(false);
    }

    const handleAcceptNote = () => {
        message.success("Заметка успешно добавлена.");
        handleCancelNoteModal();
    }

    //#region 

    useEffect(() => {

        const timeout = setTimeout(() => {
            const navigateDate = date.startOf('day').toDate();
            calendarViewRef.current?.navigate(navigateDate, false);
        }, 300);

        return () => clearTimeout(timeout);
        
    }, [date]);

    useEffect(() => {

        const event = bufferedAction?.data.event;

        if (!event) {
            return;
        }

        const start  = event.start;
        const end    = event.end;
        const durn = start && end
        ? Math.round((normalizeDate(end).getTime() - normalizeDate(start).getTime()) / 60000)
        : undefined;

        const originalActivity = event.originalActivity as IScheduleEmployeeActivity['originalActivity'] | undefined;

        setEditEventModalProps(prev => ({
            ...prev,
            values: {
                ...prev.values,
                duration: durn,
                typeId: originalActivity?.typeId,
                pointTypeId: originalActivity?.pointTypeId,
            }
        }));

    }, [bufferedAction?.data.event]);

    //#endregion

    useEffect(() => {

        if(!calendarViewRef.current) {
            return;
        }

        const calendarInst = calendarViewRef.current;
        const handleKeyDown = (ev: KeyboardEvent) => {

            if(ev.ctrlKey && (ev.code === 'ArrowLeft' || ev.code === 'ArrowRight')) {

                // Сбрасываем буфер, чтобы откат не сработал
                // handleEventUpdate
                // handleEventUpdateFailed
                prevUpdateEventsStateRef.current = undefined;

                setEvents(events => {

                    const updateResources: ResourceId[] = [];

                    let newEvents = [...events];

                    for(const oldSelectedEvent of selectedEvents) {

                        const tmp = events.filter(event => oldSelectedEvent.id === event.id);

                        if(tmp.length !== 1) {
                            return events;
                        }

                        const selectedEvent = tmp[0];
                        const start = selectedEvent.start;
                        const end = selectedEvent.end;

                        if(selectedEvent?.originalActivity?.movable === false
                        || start === undefined
                        || end === undefined) {
                            return events;
                        }

                        const minOffset = ev.code === 'ArrowLeft' ? -5 : 5;
                        const newStart = moment(start).add(minOffset, 'minutes').toDate();
                        const newEnd = moment(end).add(minOffset, 'minutes').toDate();

                        const updateSelectedEvent = {
                            ...selectedEvent,
                            start: newStart,
                            end: newEnd
                        };

                        const updateEvents = getEventsForAction({
                            type: 'update',
                            data: {
                                domEvent: undefined,
                                event: updateSelectedEvent,
                                newEvent: updateSelectedEvent,
                                oldEvent: selectedEvent,
                                inst: calendarInst,
                                source: 'timeline'
                            }
                        }, newEvents);

                        // Нет изменений, возможно смещение не доступно
                        if (equal(newEvents, updateEvents)) {
                            return events;
                        }

                        updateResources.push(updateSelectedEvent.resource);
                        newEvents = [...updateEvents];
                    }

                    for(const updateResource of updateResources) {
                        saveNewEvents(updateResource, events, newEvents);
                    }

                    return newEvents;
                });
            }
        }

        const wrappedHandleKeyDown = capacitounce(handleKeyDown, 10);

        document.addEventListener('keydown', wrappedHandleKeyDown, false);
        return () => document.removeEventListener('keydown', wrappedHandleKeyDown, false);

    }, [selectedEvents,
        saveNewEvents
    ]);

    useEffect(() => {
        didMount.current = true;
        return () => {
            didMount.current = false;
        }
    }, []);

    const internalLoading = listProvider.activityList.loading
                         || employees.loading;
    
    const renderResourceHeader = () => {

        if (employee) {
            return undefined;
        }

        return (
            <>
                <Input.Search size="small" placeholder="Поиск по фамилии" onSearch={setSearchStr} />
                <Divider style={{
                    margin: '6px 0'
                }} />
                <Select<SortType>
                    size="small"
                    className="sort-segmented-raw"
                    defaultValue={sortType}
                    onChange={setSortType}
                    style={{width: '100%'}}
                    labelInValue={false}
                    options={[
                        { label: 'ФИО От А до Я', value: 'asc' },
                        { label: 'ФИО От Я до А', value: 'desc' },
                        { label: 'По времени начала смены', value: 'start' },
                        { label: 'По времени окончания смены', value: 'end' },
                    ]}
                />
            </>
        );
    }

    const renderResource = (resource: IEmployeeResource | IExtensionResource) => {

        if(isExtensionResource(resource)) {

            const selected = expandEmployee?.PID === resource.employee.PID;
            const loadingActivities = listProvider.scheduleEmployeeExtensionActivityList.loading || loadExActivityList;

            return (
                <div className="timeline-employee-extension" title={`${resource.employee.surname} ${resource.employee.firstName}: ${resource.extension.title}`}>
                    <Row>
                        <Col span={20}>
                            <span className="timeline-employee-extension-title">{resource.extension.title}</span>
                        </Col>
                        <Col span={4}>
                            {selected && loadingActivities &&
                            <LoadingOutlined />}
                        </Col>
                    </Row>
                </div>
            );
        }

        return resource.employee && (
            <div className="timeline-employee">
                <div className="timeline-employee-title" title={`${resource.employee.surname} ${resource.employee.firstName}`}>
                    {getEmployeeIcon(resource.employee)}
                    <span>{`${resource.employee.surname} ${resource.employee.firstName}`}</span>
                </div>
                <Space
                    className="timeline-employee-data"
                    align="baseline"
                >
                    <div className="timeline-employee-role" title={resource.employee.roleCaption}>
                        {resource.employee.roleCaption}
                    </div>
                </Space>
            </div>
        );
    }

    const normalizeInvalids = useMemo(() => {
        const tmpInvalids = [...invalids];
        addMinMax(tmpInvalids, min, max);
        return tmpInvalids;
    }, [min, max, invalids]);

    return (
        <div className="interval-activity-editor">
            <ActivityNoteEditorModal
                open={openNoteModal}
                activity={activityForNote}
                onAccept={handleAcceptNote}
                onCancel={handleCancelNoteModal}
            />
            <EditEventModal
                title={bufferedAction?.type === 'click' ? 'Изменить тип активности' : 'Выберите тип новой активности'}
                okText={bufferedAction?.type === 'click' ? 'Изменить' : 'Добавить'}
                cancelText='Отмена'
                onOk={handleOkInChooseModal}
                onCancel={handleCancelChooseModal}
                types={listProvider.activityBaseList}
                {...editEventModalProps}
            />
            <Spin
                indicator={antIcon}
                spinning={loading || disableControl || internalLoading}
            >
                <Eventcalendar
                    ref={refSetter(editorRef, calendarViewRef)}
                    theme="ios"
                    themeVariant="light"
                    clickToCreate
                    dragToMove
                    dragToResize
                    dragToCreate
                    selectMultipleEvents
                    refDate={refDate}
                    //min={min}
                    //max={max}
                    //timezonePlugin={momentTimezone}
                    dataTimezone={filter.timeZone}
                    showControls={false}
                    showOuterDays={false}
                    invalid={normalizeInvalids}
                    colors={colors}
                    selectedEvents={selectedEvents}
                    locale={localeRu}
                    height={height}
                    width={width}
                    dragTimeStep={dragTimeStep}
                    newEventText="Новая активность"
                    view={view}
                    resources={resources}
                    data={events}
                    renderResourceHeader={renderResourceHeader}
                    renderResource={renderResource}
                    onSelectedEventsChange={handleSelectedEventsChange}
                    onEventDragStart={handleEventDragStart}
                    onEventDragEnd={handleEventDragEnd}
                    onEventDoubleClick={handleEventDoubleClick}
                    onEventDelete={handleEventDelete}
                    onEventUpdate={handleEventUpdate}
                    onEventUpdateFailed={handleEventUpdateFailed}
                    onEventCreated={handleEventCreated}
                    onResourceCollapse={handleResourceCollapse}
                    onResourceExpand={handleResourceExpand}
                />
            </Spin>
            {contextModalHolder}
            {contextMessageHolder}
        </div>
    ); 
}

// TODO: запретить сплитить активности редонли
// TODO: запретить изменение типа на дабл клик по активности и выхзов модального