import { IComment } from "../../Entities/Database";
import { loadListReducer, ILoadListState, LoadListReducerAction } from "../../Reducer/LoadListReducer";

export type ADD_COMMENT = 'ADD_COMMENT';

export enum CommentsReducerActionType {
    AddComment = 'ADD_COMMENT'
}

export interface ICommentsState extends ILoadListState<IComment> { }
export type AddCommentAction = {
    type: ADD_COMMENT;
    payload: IComment;
}

export type CommentsReducerAction = LoadListReducerAction<IComment> | AddCommentAction

export function commentsReducer(prevState: ICommentsState, action: CommentsReducerAction): ICommentsState {

    if(action.type === 'ADD_COMMENT') {

        const comment = action.payload;
        
        return {
            ...prevState,
            items: [ comment, ... prevState.items ]
        }
    }

    return loadListReducer(prevState, action);
}

export type ICommentsReducer = typeof commentsReducer;
export default commentsReducer;