import { useCallback, useState } from "react";
import { Alert, Divider, Form, Segmented, message } from "antd";
import { LoginForm } from "../LoginForm";
import { TokenForm } from "../TokenForm";
import { ICredential } from "../../../Server/ICredential";
import { IHasAuthToken } from "../../../Server/IHasAuthToken";
import { useCredentialAuth } from "../../../Hooks/useCredentialAuth";
import { useLKAuth } from "../../../Hooks/useLKAuth";
import "./style.css";

export enum LoginType {
    LKToken = 0,
    Credential = 1
}

export function AuthForm() {
    const authLKProvider = useLKAuth();
    const authCredentialProvider = useCredentialAuth();
    const [loginType, setLoginType] = useState(LoginType.Credential);
    const [loginForm] = Form.useForm<ICredential>();
    const [tokenForm] = Form.useForm<IHasAuthToken>();
    const [error, setError] = useState<unknown>(); 

    const handleCredentialFormFinish = useCallback(async (credential: ICredential) => {

        try {
            setError(undefined);
            await authCredentialProvider.login(credential);
        }
        catch (ex) {
            message.error("Ошибка входа в систему");
            console.error(ex);
            setError(ex);
        }

    }, [authCredentialProvider.login]);

    const handleLKTokenFormFinish = useCallback(async (values: IHasAuthToken) => {

        try {
            setError(undefined);
            await authLKProvider.loginByToken(values);
        }
        catch (ex) {
            message.error("Ошибка входа в систему");
            console.error(ex);
            setError(ex);
        }

    }, [authCredentialProvider.login]);

    return (
        <div className="authForm__wrap">
            {/* <h1 className="authForm__title">Авторизация</h1> */}
            <div className="authForm__form-wrap">
                <Segmented
                    className="authForm__segment"
                    options={[{
                            label: "Логин и пароль",
                            value: LoginType.Credential,
                            className: "authForm__option"
                        }, {
                            label: "Токен личного кабинета",
                            value: LoginType.LKToken,
                            className: "authForm__option"
                        }]}
                    onChange={value => setLoginType(value as unknown as LoginType)}
                />
                <Divider plain className="authForm__type-divider"></Divider>

                <LoginForm
                    form={loginForm}
                    onFinish={handleCredentialFormFinish}
                    style={{display: loginType !== LoginType.Credential ? 'none' : undefined}}
                    disabled={authLKProvider.isLoging}
                />
                <TokenForm 
                    form={tokenForm}
                    onFinish={handleLKTokenFormFinish}
                    style={{display: loginType !== LoginType.LKToken ? 'none' : undefined}}
                    disabled={authLKProvider.isLoging}
                />
           
                {error
                    ? <Alert type="error" message="Ошибка входа в систему" />
                    : <></>
                }

                {/* <Divider
                    plain
                    className="authForm__button-divider"
                >
                    или
                </Divider>

                <Form.Item 
                    className="authForm__request-wrap"
                >
                    <Button 
                        type="default" 
                        htmlType="button" 
                        className="authForm__request"
                        onClick={() => setIsModalOpen(true)}
                    >
                        Запросить доступ
                    </Button>
                </Form.Item> */}
            </div>
         
            
            {/* <AccessModal
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
            /> */}
        </div>
    )
}