import moment from "moment";
import { IdType, IScheduleBoardEmployee } from "../../Entities/Database";
import { IScheduleEmployeeActivity, normalizeDate } from "../../Entities/IScheduleEmployeeActivity";
import { ArgumentException } from "../../System/ArgumentException";
import { InvalidDataException } from "../../System/InvalidDataException";
import { isString } from "../../System/Utils";
import { getDayEnd, getDayStart } from "../shared/libHelpers";
import { MbscDateType } from "../shared/lib";

let uid = 1;

/** @hidden */
export function getEventId(): string {
    return "ap_event_" + uid++;
}

export interface IEvent {
    resource?: string | number | (string | number)[],
    start?: MbscDateType,
    end?: MbscDateType,
}

export function checkEvent(event: IEvent): [number, number] {

    const tmpStart = event.start;
    const tmpEnd = event.end;

    if(tmpStart === undefined) {
        throw new InvalidDataException(`Start is undefined`);
    }

    if(tmpEnd === undefined) {
        throw new InvalidDataException(`End is undefined`);
    }

    const start = +normalizeDate(tmpStart);
    const end = +normalizeDate(tmpEnd);

    if(start > end) {

        throw new InvalidDataException(`Start more end`);
    }

    return [start, end];
}

export function detectEmbedding<T extends IEvent>(embedding: IEvent, events: T[]): T | false {

    const [embeddingStart, embeddingEnd] = checkEvent(embedding);

    for(const event of events) {

        // Разные ресурсы
        if(event.resource !== embedding.resource) {
            continue;
        }
        
        const [eventStart, eventEnd] = checkEvent(event);

        // Событие погружено в область
        // |-----------|
        //    |-----|
        if(embeddingStart > eventStart
        && embeddingEnd < eventEnd) {
            return event;
        }
    }

    return false;
}

export function detectCollision<T extends IEvent>(event: IEvent, events: readonly T[]): T | false {

    const [eventStart, eventEnd] = checkEvent(event);

    for(const maybeCollision of events) {

        // Разные ресурсы
        if(event.resource !== maybeCollision.resource) {
            continue;
        }

        const [maybeCollisionStart, maybeCollisionEnd] = checkEvent(maybeCollision);

        // Точное совпадение начал или окончаний
        // |-----|
        //       |-----|
        if(maybeCollisionStart === eventStart
        || maybeCollisionEnd === eventEnd) {
            return maybeCollision;
        }

        // Событие погружено в область
        // |-----------|
        //    |-----|
        if(maybeCollisionStart < eventStart
        && maybeCollisionEnd > eventEnd) {
            return maybeCollision;
        }

        // Событие начинается из области
        // |-------|
        //    |-----------|
        if(maybeCollisionStart < eventStart
        && maybeCollisionEnd > eventStart) {
            return maybeCollision;
        }

        // Событие заканчивается в области
        //       |-------|
        // |-----------|
        if(maybeCollisionStart > eventStart
        && maybeCollisionStart < eventEnd) {
            return maybeCollision;
        }
    }

    return false;
}

/**
 * Если сплитер больше, считается поглащением, вернется 'absorption',
 * Если события равны, считается заменой, вернется 'replacement',
 * Если нет подходящих дроблений, вернется false,
 * Иначе вернется список событий, сформированных в результате дробления (за исключением самого сплиттера)
 * @param splitter 
 * @param what 
 * @returns 
 */
export function splitEvent(splitter: IScheduleEmployeeActivity, what: IScheduleEmployeeActivity): 'absorption' | 'replacement' | false | IScheduleEmployeeActivity[] {

    // Разные ресурсы
    if(splitter.resource !== what.resource) {
        return false;
    }

    // [211223] TODO: запретить возможность резать активность, не доступную для редактирования
    if(!what.editable) {
        return false;
    }

    const [whatStart, whatEnd] = checkEvent(what);
    const [splitterStart, splitterEnd] = checkEvent(splitter);

    //      |-----W-----|
    // |----------S------------|
    // OR
    // |---W---|
    // |--------S--------|
    // OR
    //          |---W---|
    // |-------S--------|
    if((whatStart > splitterStart && whatEnd < splitterEnd)
    || (whatStart === splitterStart && whatEnd < splitterEnd)
    || (whatEnd === splitterEnd && whatStart > splitterStart)) {
        return 'absorption';
    }

    // |----W----|
    // |----S----|
    if(whatStart === splitterStart && whatEnd === splitterEnd) {
        return 'replacement';
    }

    // |-----W-----|   =>   |--W--|
    //                 =>         |-S-|
    //      |-S-|      =>             |-N-|
    if(whatStart < splitterStart && whatEnd > splitterEnd) {

        return [
            {
                ...what,
                end: splitter.start
            },
            {
                ...what,
                id: getEventId(),
                start: splitter.end,
                originalActivity: {
                    typeId: what.originalActivity.typeId,
                    pointTypeId: what.originalActivity.pointTypeId
                }
            }
        ]
    }

    //   |-----W-----|   =>        |----W----|
    //   |-S-|           =>    |-S-|
    // OR
    //   |-----W-----|   =>        |----W----|
    // |-S-|             =>    |-S-|
    if((whatEnd > splitterEnd && whatStart === splitterStart)
    || (whatEnd > splitterEnd && whatStart > splitterStart && whatStart < splitterEnd)) {

        return [
            {
                ...what,
                start: splitter.end
            }
        ]
    }

    // |-----W-----|   =>    |----W----|
    //         |-S-|   =>              |-S-|
    // OR
    // |-----W-----|     =>    |----W----|
    //           |-S-|   =>              |-S-|
    if((whatStart < splitterStart && whatEnd === splitterEnd)
    || (whatStart < splitterStart && whatEnd > splitterStart)) {

        return [
            {
                ...what,
                end: splitter.start
            },
        ]
    }

    return false;
}

function searchNext(end: number, resource: string, events: IScheduleEmployeeActivity[]): IScheduleEmployeeActivity | undefined {

    for(const event of events) {
        if(resource === event.resource
        && end === +normalizeDate(event.start)) {
            return event;
        }
    }

    return undefined;
}

function searchPrev(start: number, resource: string, events: IScheduleEmployeeActivity[]): IScheduleEmployeeActivity | undefined {

    for(const event of events) {
        if(resource === event.resource
        && start === +normalizeDate(event.end)) {
            return event;
        }
    }

    return undefined;
}

export function splitAndGluingEvent(splitter: IScheduleEmployeeActivity, events: IScheduleEmployeeActivity[]): false | IScheduleEmployeeActivity[] {

    const index = events.findIndex(event => event.id === splitter.id);

    if(index === -1) {
        throw new ArgumentException("Events must have old splitter state");
    }

    const oldSplitter = events[index];
    const [splitterStart, splitterEnd] = checkEvent(splitter);
    const [oldSplitterStart, oldSplitterEnd] = checkEvent(oldSplitter);

    const normalizeEvents: IScheduleEmployeeActivity[] = [];
    const ignoreIds: IdType[] = [oldSplitter.id];

    const prev = searchPrev(oldSplitterStart, oldSplitter.resource, events);
    const next = searchNext(oldSplitterEnd, oldSplitter.resource, events);
    
    if(prev && next) {

        ignoreIds.push(prev.id, next.id);

        const prevSplit = splitEvent(splitter, prev);
        const nextSplit = splitEvent(splitter, next);
        const similar = prev.originalActivity.typeId === next.originalActivity.typeId;

        // Объединеям события, т.к. нет дробления
        if(prevSplit === false && nextSplit === false) {

            // Сплиттер был ужат

            // |--P--||---S---||--N--|
            // |--P--|..|-S-|..|--N--|
            if((splitterStart === oldSplitterStart && splitterEnd < oldSplitterEnd)
            || (splitterEnd === oldSplitterEnd && splitterStart > oldSplitterStart)) {
                return false;
            }
            
            // Для одинакового типа выполняем объединение
            if (similar) {

                // |--P--||---S---||--N--|
                // |--P--|.........|--N--|
                // ^_____________________^

                normalizeEvents.push({
                    ...prev,
                    end: next.end
                });
            }
            else {

                // |--P--||---S---||--N--|
                // |--P--|.........|--N--|
                // ^_____^         ^_____^

                normalizeEvents.push(prev, next);
            }
        }
        else {

            // Замещение и поглащение не доступно
            if(isString(nextSplit) || isString(prevSplit)) {
                return false;
            }

            // Если произошло дробление предыдущего события
            if (nextSplit === false && prevSplit) {

                if(prevSplit.length < 1) {
                    return false;
                }

                const firstSplit  = prevSplit[0];

                if(prevSplit.length === 1 && firstSplit.start === splitter.end && similar) {

                    // |----P----||--S--||--N--|
                    // |--S--|...........|--N--|
                    //       ^_________________^

                    normalizeEvents.push({
                        ...next,
                        start: firstSplit.start,
                    });
                }
                else if (prevSplit.length === 1 && firstSplit.start === splitter.end && !similar) {

                    // |----P---||-S-||-N-|
                    // |-S-||-P-|.....|-N-|
                    //      ^___^_________^

                    normalizeEvents.push(
                        firstSplit,
                        { ...next, start: firstSplit.end }
                    );
                }
                else if (prevSplit.length === 1 && firstSplit.end === splitter.start && !similar) {

                    // |----P---||-S-||-N-|
                    // |-P-||-S-|.....|-N-|
                    // ^___^    ^_________^

                    normalizeEvents.push(
                        firstSplit,
                        { ...next, start: splitter.end }
                    );
                }
                else if ((prevSplit.length === 1 || prevSplit.length === 2) && firstSplit.end === splitter.start && similar) {

                    // |---------P---------||--S--||--N--|
                    // |--P--||--S--||--P--|.......|--N--|
                    // ^_____^       ^___________________^

                    normalizeEvents.push(
                        firstSplit,
                        { ...next, start: splitter.end }
                    );
                }
                else if (prevSplit.length === 2 && firstSplit.end === splitter.start && !similar) {

                    const secondSplit = prevSplit[1];

                    // |---------P---------||--S--||--N--|
                    // |--P--||--S--||--P--|.......|--N--|
                    // ^_____^       ^_____^_____________^

                    normalizeEvents.push(
                        firstSplit,
                        secondSplit,
                        { ...next, start: secondSplit.end }
                    );
                }
                else {
                    return false;
                }
            }

            // Если произошло дробление следующего события
            if (prevSplit === false && nextSplit) {

                if (nextSplit.length < 1) {
                    return false;
                }

                const firstSplit = nextSplit[0];

                if(nextSplit.length === 1 && firstSplit.start === splitter.end) {

                    // |--P--||--S--||----N----|
                    // |--P--|....|--S--||--N--|
                    // ^__________^      ^_____^

                    normalizeEvents.push(
                        { ...prev, end: splitter.start },
                        { ...next, start: splitter.end }
                    );
                }
                else if(nextSplit.length === 1 && firstSplit.end === splitter.start && similar) {

                    // |----P----||--S--||--N--|
                    // |----P----|.......|--S--|
                    // ^_________________^

                    normalizeEvents.push({
                        ...prev,
                        end: splitter.start
                    });
                }
                else if(nextSplit.length === 2 && firstSplit.end === splitter.start && similar) {

                    // |--P--||--S--||--------N--------|
                    // |--P--|.......|-N-||--S--||--N--|
                    // ^_________________^       ^_____^

                    normalizeEvents.push(
                        { ...prev, end: splitter.start },
                        { ...next, start: splitter.end }
                    );
                }
                else if(nextSplit.length === 1 && firstSplit.end === splitter.start && !similar) {

                    // |--P--||--S--||--------N--------|
                    // |--P--|.......|-----N----||--S--|
                    // ^_____________^__________^

                    normalizeEvents.push(
                        { ...prev, end: firstSplit.start },
                        firstSplit
                    );
                }
                else if(nextSplit.length === 2 && firstSplit.end === splitter.start && !similar) {

                    const secondSplit = nextSplit[1];

                    // |--P--||--S--||--------N--------|
                    // |--P--|.......|-N-||--S--||--N--|
                    // ^_____________^___^       ^_____^

                    normalizeEvents.push(
                        { ...prev, end: firstSplit.start },
                        firstSplit,
                        secondSplit
                    );
                }
                else {
                    return false;
                }
            }
        }
    }

    for(const what of events) {

        if(ignoreIds.includes(what.id)) {
            continue;
        }

        const [whatStart, whatEnd] = checkEvent(what);

        // Разные ресурсы
        if(splitter.resource !== what.resource) {
            normalizeEvents.push(what);
            continue;
        }

        const split = splitEvent(splitter, what);

        if(isString(split)) {
            return false;
        }

        if(split === false) {
            normalizeEvents.push(what);
        }
        else {
            normalizeEvents.push(...split);
        }
    }

    return normalizeEvents;
}

export type SortDirection = 'start' | 'end';

export type NormalizeIntervals = Record<IdType, Record<IdType, [number, number]>>;

function sortActivities(
    normalizeIntervals: NormalizeIntervals,
    activities: Readonly<IScheduleEmployeeActivity[]>,
    date: moment.Moment,
    direction: SortDirection
): IScheduleEmployeeActivity[] {

    const day = date.toDate();
    const dayStart = +getDayStart(day);
    const dayEnd = +getDayEnd(day);
    const dayEndFix = dayEnd + 1;

    const sortableActivities = [...activities]
    .filter(activity => {
        const [start, end] = normalizeIntervals[activity.resource][activity.id];
        return (start < dayEnd && end > dayStart) || (start === dayStart || end === dayEnd || end === dayEndFix);
    })
    .sort((a, b) => {

        const aProductive = !a.originalActivity.notProductive;
        const bProductive = !b.originalActivity.notProductive;
        const productives = aProductive && bProductive;

        const [aStart, aEnd] = normalizeIntervals[a.resource][a.id];
        const [bStart, bEnd] = normalizeIntervals[b.resource][b.id];

        const aInDay = (aStart < dayEnd && aEnd > dayStart) || (aStart === dayStart || aEnd === dayEnd || aEnd === dayEndFix);
        const bInDay = (bStart < dayEnd && bEnd > dayStart) || (bStart === dayStart || bEnd === dayEnd || bEnd === dayEndFix);

        if((productives && aInDay && !bInDay) || (bProductive && !aProductive)) {
            return 1;
        }

        if((productives && bInDay && !aInDay) || (aProductive && !bProductive)) {
            return -1;
        }

        if(direction === 'start') {

            if(aStart === bStart) {
                return bEnd - aEnd;
            }

            return aStart - bStart
        }

        if(aEnd === bEnd) {

            return bStart - aStart;
        }

        return bEnd - aEnd;
    });

    return sortableActivities;
}

export function sortEmploeesByActivities(
    employees: Readonly<IScheduleBoardEmployee[]>,
    activities: Readonly<IScheduleEmployeeActivity[]>,
    date: moment.Moment,
    direction: SortDirection
): IScheduleBoardEmployee[] {

    const tmpEmploeies = [...employees];
    const normalizeIntervals: NormalizeIntervals = {};

    activities.map(activity => {

        if(!normalizeIntervals[activity.resource]) {
            normalizeIntervals[activity.resource] = {}
        }

        normalizeIntervals[activity.resource][activity.id] = checkEvent(activity);
    });

    const currentDay = moment(date).startOf('day');
    const sortableActivities = sortActivities(normalizeIntervals, activities, currentDay, direction);

    tmpEmploeies.sort((a, b) => {

        const indexA = sortableActivities.findIndex((activity) => activity.resource === a.PID);
        const indexB = sortableActivities.findIndex((activity) => activity.resource === b.PID);

        if (indexA === indexB) {
            return 0;
        }

        if (indexA === -1) {
            return 1;
        }

        if (indexB === -1) {
            return -1;
        }

        return indexA - indexB;
    });

    return tmpEmploeies;
}