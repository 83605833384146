import { DetectedInfo, HasValue } from "./Type/DetectedInfo";

export function getName<T extends Error>(instanse: T, baseInstanseName: string, targetName: string) {
    return ((instanse as unknown as any).getName) ?
            (instanse as unknown as any).getName() :
            (instanse.name === baseInstanseName) ?
            targetName :
            instanse.name;
}

export abstract class JSError extends globalThis.Error {

    constructor()
    constructor(message: string)
    constructor(detectedMessage: DetectedInfo<string>)
    constructor(detectedMessageOrMessage?: DetectedInfo<string> | string | null) {

        if (detectedMessageOrMessage != null) {

            if (typeof detectedMessageOrMessage == "string") {
                super(detectedMessageOrMessage);
            }
            else if (HasValue(detectedMessageOrMessage)) {
                super(detectedMessageOrMessage.value);
            }
            else {
                super();
            }
        }
        else {
            super();
        }
        
        this.name = getName(this, globalThis.Error.name, new.target.name);
        
        Object.setPrototypeOf(this, new.target.prototype);
    }
}

export abstract class JSTypeError extends globalThis.TypeError {

    constructor()
    constructor(message: string)
    constructor(detectedMessage: DetectedInfo<string>)
    constructor(detectedMessageOrMessage?: DetectedInfo<string> | string | null) {

        if (detectedMessageOrMessage != null) {
            
            if (typeof detectedMessageOrMessage == "string") {
                super(detectedMessageOrMessage);
            }
            else if (HasValue(detectedMessageOrMessage)) {
                super(detectedMessageOrMessage.value);
            }
            else {
                super();
            }
        }
        else {
            super();
        }

        this.name = getName(this, globalThis.TypeError.name, new.target.name);
        
        Object.setPrototypeOf(this, new.target.prototype);
    }
}
