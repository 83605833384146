import moment, { isMoment } from "moment";
import { IScheduleBoardEmployee, getPersonScheduleDayCaption, getSkills, reschedule } from "../../Entities/Database";
import { ILoadListState } from "../../Reducer/LoadListReducer";
import DraggableModal, { IDraggableModalProps } from "../DraggableModal";
import { ItemType } from "../ScheduleBoard/CellContextMenu/helpers";
import useAuth from "../../Hooks/useAuth";
import { useForm } from "antd/lib/form/Form";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useBuffer } from "../../Hooks/useBuffer";
import { dictionaryToOptions } from "../shared/helpers";
import useActual from "../../Hooks/useActual";
import { Checkbox, Col, Divider, Flex, Form, Input, Progress, Row, Select, Space, Tag, message } from "antd";
import { isEmptyStrOrNullOrUndefined, nameof } from "../../System/Utils";
import { searchInValue } from "../../Reducer/SearchFilterReducer";
import DatePicker from "../shared/DatePicker";
import { useListLoader } from "../../Hooks/useListLoader";
import { SkillList } from "./skilllist";

import './style.css';
import { BackendResponseException } from "../../System/BackendResponseException";

const dateKeyFormat = "DD.MM.YYYY";

const defaultLabelCol = {
    span: 8,
    offset: 0
}

export interface IShiftTransferValues {
    fromPID: IScheduleBoardEmployee['PID'],
    toPID: IScheduleBoardEmployee['PID'],
    from: moment.Moment,
    to: moment.Moment,
    comment: string,
    isAgentRequest: boolean,
}

export interface IScheduleShiftChangeModalProps extends IDraggableModalProps {
    item?: ItemType,
    employees: ILoadListState<IScheduleBoardEmployee>,
}

export function ScheduleShiftTransferModal({
    employees,
    item,
    open,
    onOk,
    onCancel,
    ...otherProps
}: IScheduleShiftChangeModalProps) {

    const auth = useAuth();
    const [form] = useForm<IShiftTransferValues>();
    const [saving, setSaving] = useState(false);

    const [leftSkills, reloadLeftSkills] = useListLoader((signal) => {

        const fromPID = form.getFieldValue(nameof<IShiftTransferValues>('fromPID'));

        if (isEmptyStrOrNullOrUndefined(fromPID)) {
            return Promise.resolve([]);
        }

        return getSkills(auth, fromPID, signal);

    }, [auth.data?.staticId], {initialState: {loading: true}});

    const [rightSkills, reloadRightSkills] = useListLoader((signal) => {

        const toPID = form.getFieldValue(nameof((x: IShiftTransferValues) => x.toPID));

        if (isEmptyStrOrNullOrUndefined(toPID)) {
            return Promise.resolve([]);
        }

        return getSkills(auth, toPID, signal);

    }, [auth.data?.staticId], {initialState: {loading: true}});

    /*
    const [rightMainActivity, reloadRightMainActivity] = useListLoader((signal) => {

        const toPID = form.getFieldValue(nameof((x: IShiftTransferValues) => x.toPID));

        if (isEmptyStrOrNullOrUndefined(toPID)) {
            return Promise.resolve([]);
        }

        return getSkills(auth, toPID, signal);

    }, [auth.data?.staticId], {initialState: {loading: true}});
    */
    
    const employeeOptions = useBuffer(
        () => dictionaryToOptions(employees, 'PID', (employee) => `${employee.surname} ${employee.firstName}`),
        [employees.items],
        open === true
    );

    const onOkEventRef = useRef<React.MouseEvent<HTMLButtonElement, MouseEvent>>();
    const onOkRef = useActual(onOk);

    const multi = isMoment(item);
    const title = `Перенос/обмен смены: ${(multi ? item : item?.date)?.locale('ru').format(dateKeyFormat)}`;

    const handleOk = useCallback<NonNullable<IDraggableModalProps['onOk']>>((e) => {
        onOkEventRef.current = e;
        form.submit();
    }, [form]);

    const handleChangeValues = (changedValues: Partial<IShiftTransferValues>, values: IShiftTransferValues) => {
        
        if (Object.hasOwn(changedValues, nameof<IShiftTransferValues>('fromPID'))) {
            reloadLeftSkills();
        }

        if (Object.hasOwn(changedValues, nameof<IShiftTransferValues>('toPID'))) {
            reloadRightSkills();
        }
    };

    const handleFinish = useCallback(async (values: IShiftTransferValues) => {

        if(!item) {
            message.error("Не выбрана ячейка");
            return;
        }

        try {

            setSaving(true);

            await reschedule(auth, values);

            const e = onOkEventRef.current!;
            const onOk = onOkRef.current;
    
            if (onOk) {
                onOk(e);
            }
        }
        catch (ex) {
            message.error(ex instanceof BackendResponseException ? ex.message : "Не удалось выполнить трансфер смены");
            console.error(ex);
        }
        finally {
            setSaving(false);
        }

    }, [auth.data?.staticId, item, form]);

    const correspondence = useMemo(() => {

        const active = leftSkills.items.filter(x => x.isActive);
        const total = active.length;
        const count = active.filter(x => rightSkills.items.findIndex(y => y.isActive && x.id === y.id) !== -1).length;

        return Math.round((count / total) * 100);

    }, [leftSkills.items, rightSkills.items]);

    useEffect(() => {

        if (open) {

            form.resetFields();

            if (!multi) {

                form.setFieldValue(nameof<IShiftTransferValues>('fromPID'), item?.PID);
                form.setFieldValue(nameof<IShiftTransferValues>('from'), item?.date.clone());

                form.setFieldValue(nameof<IShiftTransferValues>('toPID'), item?.PID);
                form.setFieldValue(nameof<IShiftTransferValues>('to'), item?.date.clone());

                reloadLeftSkills();
                reloadRightSkills();
            }
        }

    }, [open]);

    return (
        <DraggableModal
            {...otherProps}
            closable={!saving}
            width={690}
            destroyOnClose
            title={title}
            open={open}
            onOk={handleOk}
            onCancel={onCancel}
            okButtonProps={{
                disabled: !employees.load || saving,
                loading: saving
            }}
            cancelButtonProps={{
                disabled: saving
            }}
        >
            <Form
                form={form}
                //layout="vertical"
                onValuesChange={handleChangeValues}
                onFinish={handleFinish}
                disabled={saving}
                initialValues={{
                    isAgentRequest: false,
                    sideJob: false,
                }}
            >
                <Form.Item
                    hidden={multi}
                    name={nameof<IShiftTransferValues>('isAgentRequest')}
                    label={'По инициативе оператора'}
                    valuePropName="checked"
                >
                    <Checkbox
                        disabled={saving}
                    />
                </Form.Item>
                <Row>
                    <Col span={11}>
                        <Form.Item
                            name={nameof<IShiftTransferValues>('fromPID')}
                            label={multi ? 'Сотрудники' : 'Сотрудник'}
                            rules={[{ required: true, message: 'Выберите сотрудника' }]}
                            labelCol={defaultLabelCol}
                        >
                            <Select
                                loading={employees.loading}
                                filterOption={(inputValue, option) => searchInValue(option?.children, inputValue)}
                                disabled={true}
                            >
                                {employeeOptions}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name={nameof<IShiftTransferValues>('from')}
                            label={'Дата'}
                            rules={[{ required: true, message: 'Выберите дату' }]}
                            labelCol={defaultLabelCol}
                        >
                            <DatePicker disabled={true} style={{width: '100%'}} />
                        </Form.Item>
                    </Col>
                    <Col span={2}>
                        <div className="divider-vertial"></div>
                    </Col>
                    <Col span={11}>
                        <Form.Item
                            name={nameof<IShiftTransferValues>('toPID')}
                            label={multi ? 'Сотрудники' : 'Сотрудник'}
                            rules={[{ required: true, message: 'Выберите сотрудника' }]}
                            labelCol={defaultLabelCol}
                        >
                            <Select
                                loading={employees.loading}
                                filterOption={(inputValue, option) => searchInValue(option?.children, inputValue)}
                            >
                                {employeeOptions}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name={nameof<IShiftTransferValues>('to')}
                            label={'Дата'}
                            rules={[{ required: true, message: 'Выберите дату' }]}
                            labelCol={defaultLabelCol}
                        >
                            <DatePicker
                                style={{width: '100%'}}
                                disabledDate={(date) => date.valueOf() < moment.now()}
                            />
                        </Form.Item>
                    </Col>
                    <Col span={11}>
                        <p>Навыки:</p>
                        <Divider style={{marginTop: 0}} />
                        <SkillList itemsState={leftSkills} />
                    </Col>
                    <Col span={2}>
                        <div className="divider-vertial"></div>
                    </Col>
                    <Col span={11}>
                        <p>Навыки:</p>
                        <Divider style={{marginTop: 0}} />
                        <SkillList itemsState={rightSkills} />
                    </Col>
                    <Col span={24}>
                        <Divider />
                        <Progress percent={correspondence} />
                        <Divider />
                        <Form.Item
                            name={nameof<IShiftTransferValues>('comment')}
                            label={'Комментарий'}
                            rules={[{ required: false, message: 'Заполните комментарий' }]}
                            //labelCol={defaultLabelCol}
                        >
                            <Input.TextArea />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </DraggableModal>
    );
}

export default ScheduleShiftTransferModal;