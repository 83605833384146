import { useEffect, useMemo, useState } from "react";
import { IEmployee, IEmployeeScheduleDay, IScheduleBoardEmployee, ISickEditorValues, deleteSick, getSickInfo, saveSick } from "../../Entities/Database";
import DraggableModal from "../DraggableModal";
import { Divider, Form, Input, InputNumber, Select, Skeleton, message as AntdMessage, Button } from "antd";
import { ILoadListState } from "../../Reducer/LoadListReducer";
import DatePicker from "../shared/DatePicker";
import { RangeValue, nameof } from "../../System/Utils";
import { useEntityLoader } from "../../Hooks/useEntityLoader";
import useAuth from "../../Hooks/useAuth";
import moment from "moment";
import { BackendResponseException } from "../../System/BackendResponseException";
import useActual from "../../Hooks/useActual";
import Exception from "../../System/Exception";

const TextArea = Input.TextArea;

export const dateKeyFormat = "DD.MM.YYYY";
export const getFIO = (employee: IEmployee) => `${employee.surname} ${employee.firstName} ${employee.secondName}`;

export const abortByClose = {}

export interface IScheduleSickModalProps {
    open?: boolean,
    employees: ILoadListState<IScheduleBoardEmployee>,
    item: IEmployeeScheduleDay,
    onFinish?: () => void,
    onCancel?: () => void,
}

export function ScheduleSickModal({
    open,
    item,
    employees,
    onFinish,
    onCancel,
}: IScheduleSickModalProps) {
    
    const actualOnFinish = useActual(onFinish);
    const auth = useAuth();
    const [message, messageContextHolder] = AntdMessage.useMessage();
    const [form] = Form.useForm<ISickEditorValues>();
    const [employee, setEmployee] = useState<IEmployee | undefined>(undefined);
    const [types, setTypes] = useState<Object[] | undefined>();
    const [saving, setSaving] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [sickLoadState, reloadSick, , abortLoadSick] = useEntityLoader(signal => getSickInfo(auth, item.PID, item.date, signal), [auth.data?.staticId, item]);

    const readonly  = saving || deleting || !(sickLoadState.load && sickLoadState.entity?.main.canEdit);
    const deletable = sickLoadState.load && sickLoadState.entity?.main.canDelete;
    const loading = sickLoadState.loading || employees.loading;
    const internalTitle = useMemo(() => {

        if (!employee || !employees.load) {
            return <Skeleton.Button size="small" active={employees.loading} />
        }

        const day = item.date.format(dateKeyFormat);
        return `Больничный: ${getFIO(employee)} [${day}]`;

    }, [open, employees.load, employees.loading, employee, item]);

    useEffect(() => {

        if (!open) return;

        if (!employees.load) {
            setEmployee(undefined);
            return;
        }

        setEmployee(employees.items.find(x => x.PID == item.PID));

    }, [open, employees.items, item]);

    useEffect(() => {

        if (!open) return;

        if (sickLoadState.error && sickLoadState.error !== abortByClose && sickLoadState.error !== useEntityLoader.abortByMerge) {
            message.error("При загрузке данных возникла ошибка.");
            console.error(sickLoadState.error);
        }

    }, [sickLoadState.error]);

    useEffect(() => {
        if (open) reloadSick();
        else      abortLoadSick(abortByClose);
    }, [open, item]);

    useEffect(() => {

        if (sickLoadState.load) {
            const readonly = !sickLoadState.entity?.main.canEdit;
            setTypes(sickLoadState.entity?.typeList.map(x => ({
                ...x,
                disabled: readonly,
            })));
        }

    }, [sickLoadState.load, readonly]);
    
    useEffect(() => {

        if (open) {
            
            if (sickLoadState.load && sickLoadState.entity) {
                const sick = sickLoadState.entity.main;
                form.setFieldsValue({
                    sickId: sick.id,
                    employee: employee?.PID,
                    period: [sick.startDate, moment(sick.startDate).add(sick.duration - 1, 'day')],
                    duration: sick.duration,
                    comment: sick.comment,
                    type: 1,
                });
            }
            else {
                form.resetFields();
            }
        }

    }, [open, sickLoadState.load]);

    const handleDelete = async () => {

        try {
            
            if (!sickLoadState.entity) {
                throw new Exception('Больничный не инициализирован.');
            }

            if (!sickLoadState.entity.main.canDelete) {
                throw new Exception('Больничный не может быть удален.');
            }

            setDeleting(true);
            await deleteSick(auth, sickLoadState.entity.main.id);

            if (actualOnFinish.current) {
                actualOnFinish.current();
            }
        }
        catch (ex) {
            message.error(ex instanceof BackendResponseException ? ex.message : 'Не удалось удалить больничный');
            console.error(ex);
        }
        finally {
            setDeleting(false);
        }
    }

    const handleDateRangeChange = (range: RangeValue<moment.Moment>) => {
        
        if (!range || !range[0] || !range[1]) {
            form.setFieldValue('duration', 0);
            return;
        }

        form.setFieldValue('duration', range[1].diff(range[0], 'day', false) + 1);
    }

    const handleFormFinish = async (values: ISickEditorValues) => {

        try {
            setSaving(true);
            await saveSick(auth, values);

            if (actualOnFinish.current) {
                actualOnFinish.current();
            }
        }
        catch (ex) {
            message.error(ex instanceof BackendResponseException ? ex.message : 'Не удалось сохранить больничный');
            console.error(ex);
        }
        finally {
            setSaving(false);
        }

    }

    const hanldeOk = () => {
        form.submit();
    }

    return (
        <DraggableModal
            open={open}
            title={internalTitle}
            okText="Сохранить"
            cancelText="Закрыть"
            closable={!saving && !deleting}
            keyboard={!saving && !deleting}
            okButtonProps={{
                loading: saving,
                disabled: readonly,
            }}
            cancelButtonProps={{ disabled: saving || deleting }}
            onOk={hanldeOk}
            onCancel={onCancel}
            afterOpenChange={open => {
                if (!open) {
                    form.resetFields();
                }
            }}
            footer={deletable
                ? (originNode, params) => (
                    <>
                        <Button
                            disabled={deleting || saving}
                            onClick={handleDelete}
                        >
                            Удалить
                        </Button>
                        {originNode}
                    </>
                )
                : undefined}
        >
            <Divider />
            <Form
                form={form}
                preserve={false}
                labelCol={{ span: 10 }}
                wrapperCol={{ span: 14 }}
                labelAlign="left"
                layout="horizontal"
                size="small"
                onFinish={handleFormFinish}
                //onValuesChange={handleFormChange}
                disabled={loading || saving}
            >
                <Form.Item
                    label="Сотрудник:"
                    name={nameof<ISickEditorValues>("employee")}
                >
                    <Select
                        loading={loading}
                        options={[{
                            label: employee ? getFIO(employee) : undefined,
                            value: employee ?.PID,
                            disabled: readonly,
                        }]}
                    />
                </Form.Item>
                <Form.Item
                    label="Вид больничного:"
                    name={nameof<ISickEditorValues>("type")}
                    rules={[{
                        required: true,
                        message: "Выберите тип больничного"
                    }]}
                >
                    <Select
                        className="sickModal__select"
                        options={types}
                        loading={loading}
                    />
                </Form.Item>
                <Form.Item
                    label="Дата начала/окончания:"
                    name={nameof<ISickEditorValues>("period")}
                    rules={[{
                        required: true,
                        message: "Выберите дату начала и окончания"
                    }]}
                >
                    <DatePicker.RangePicker
                        onChange={handleDateRangeChange}
                        inputReadOnly={readonly}
                        disabledDate={readonly ? () => true : undefined}
                    />
                </Form.Item>
                <Form.Item
                    label="Продолжительность:"
                    name={nameof<ISickEditorValues>("duration")}
                >
                    <InputNumber 
                        className="sickModal__input"
                        readOnly
                    />
                </Form.Item>
                <Form.Item
                    label="Примечание::"
                    name={nameof<ISickEditorValues>("comment")}
                    wrapperCol={{span: 24}}
                    labelCol={{span: 24}}
                >
                    <TextArea 
                        rows={2}
                        readOnly={readonly}
                    />
                </Form.Item>
                <Form.Item
                    name={nameof<ISickEditorValues>("sickId")}
                    hidden={true}
                >
                    <Input />
                </Form.Item>
            </Form>
            {messageContextHolder}
        </DraggableModal>
    );
}

export default ScheduleSickModal