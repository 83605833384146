export const defaultColor = "red";
export const maxLengthTag = 48;

export function getTagContent(defaultContent: string, sliceNum: number, elementsAmount: number) {

    if (defaultContent.length > sliceNum) {

        if (elementsAmount === 1) {

            if (defaultContent.length > maxLengthTag) {

                return defaultContent.slice(0, maxLengthTag) + "...";
            }

            return defaultContent.slice(0, maxLengthTag);
        }
        
        return defaultContent.slice(0, sliceNum) + "...";
    }

    return defaultContent;
}