import { NotificationInstance } from "antd/es/notification/interface";
import { MessageInstance } from "antd/es/message/interface";
import { useAPIHub } from "./useAPIHub";
import { PropsWithChildren, createContext, useContext, useEffect } from "react";
import { IPopupMessage, IReloadAppMessage, convertMessageTypeToStr } from "../Entities/BackendAPIHub";
import { Button, Space } from "antd";
import { message as AntdMessage, notification as AntdNotification } from "antd";
import { IHubProvider } from "./useSignalRHub";
import useAuth from "./useAuth";

export function reloadPage() {
    window.location.reload();
}

export interface IRemoteControlProvideOptions {
    message: MessageInstance,
    notification: NotificationInstance,
}

export interface IRemoteLogon {
    logout: (message?: IPopupMessage) => void,
}

export interface IPopupMessageHubEvents {
    popupMessage: (message: IPopupMessage) => void,
}

export interface IRemoteControlEvents extends IRemoteLogon, IPopupMessageHubEvents {
    reloadApp: (message: IReloadAppMessage) => void,
}

export function useRemoteControlProvide(options: IRemoteControlProvideOptions): IHubProvider<IRemoteControlEvents> {

    const auth = useAuth();
    const hub  = useAPIHub<IRemoteControlEvents>();

    useEffect(() => {

        const reloadAppHandler = (message: IReloadAppMessage) => {

            options?.notification.info({
                key: `app-reload-notification`,
                placement: 'top',
                message: message.title,
                description: message.message,
                duration: 0,
                btn: (
                    <Space>
                        <Button size="small" onClick={reloadPage}>Перезагрузить</Button>
                    </Space>
                )
            });
        }

        hub.on('reloadApp', reloadAppHandler);

        return () => {
            hub.off('reloadApp', reloadAppHandler);
        }

    }, [options?.notification]);

    useEffect(() => {

        const messageHandler: IPopupMessageHubEvents['popupMessage'] = (message) => {
            const typeOfStr = convertMessageTypeToStr(message.type);
            options?.message[typeOfStr](message.message, message.duration || undefined);
            console.log(message);
        }

        const logoutHanlder: IRemoteLogon['logout'] = (message) => {
            
            auth.logout();

            if (message) {
                messageHandler(message);
            }
        }

        hub.on('logout', logoutHanlder);
        hub.on('popupMessage', messageHandler);

        return () => {
            hub.off('logout', logoutHanlder);
            hub.off('popupMessage', messageHandler);
        }

    }, [options?.message, auth.logout]);

    return hub;
}

export const remoteControlContext = createContext<IHubProvider<IRemoteControlEvents>>({} as any);
export function RemoteControlProvider({
    children
}: PropsWithChildren<any>) {

    const [message, messageContextHolder] = AntdMessage.useMessage({ prefixCls: 'api-hub' });
    const [notification, notificationContextHolder] = AntdNotification.useNotification({ prefixCls: 'api-hub' });
    const hub = useRemoteControlProvide({
        message,
        notification,
    });

    return (
        <remoteControlContext.Provider value={hub}>
            {messageContextHolder}
            {notificationContextHolder}
            {children}
        </remoteControlContext.Provider>
    );
}

export function useRemoteControl() {
    return useContext(remoteControlContext);
}