export interface ISkillsLocale {
    systemSkillTitle: string,
    saveMessage: string,
    mainSkillTitle: string,
    showMoreBtnTitle: string,
}

export const skillLocaleRu: ISkillsLocale = {
    systemSkillTitle: 'Системное значение',
    saveMessage: 'Данные сохранены',
    mainSkillTitle: 'Основной навык',
    showMoreBtnTitle: 'Показать все',
}