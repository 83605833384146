
export interface ILeavesLocale {
    eventTitle: string,
    searchPlaceholder: string,
    checkboxTitle: string,
}

export const leavesLocaleRu: ILeavesLocale = {
    eventTitle: 'Отпуск',
    searchPlaceholder: 'Поиск по сотрудникам',
    checkboxTitle: 'Отпуск близко'
}