import { createContext, useContext, useState } from "react";
import ILoggerProvider, { IAlternativeLoggerProvider } from "../Entities/ILoggerProvider";
import { contextNotInitFn, contextNotInitFnAsync } from "./helpers";
import useAuth from "./useAuth";

const loggerContext = createContext<ILoggerProvider>({
    baseLogger: console,
    setBaseLogger: contextNotInitFn,
    info: contextNotInitFnAsync,
    warn: contextNotInitFnAsync,
    error: contextNotInitFnAsync
});

// Provider hook that creates auth object and handles state
const useProvideLogger = function (): ILoggerProvider {
    
    const authProvider = useAuth();
    const [baseLogger, setBaseLogger] = useState<IAlternativeLoggerProvider>(console);
    
    const info = async (template: string, ...args: any[]) => {

        try
        {
            baseLogger.log(arguments);
            //await dbInfo(authProvider.authData, template, ...args);
        }
        catch (ex: unknown)
        {
            baseLogger.error("Failed log info", ex, arguments);
        }
    }

    const warn = async (template: string, ...args: any[]) => {

        try
        {
            baseLogger.warn(arguments);
            //await dbWarn(authProvider.authData, template, ...args);
        }
        catch (ex: unknown)
        {
            baseLogger.error("Failed log warn", ex, arguments);
        }
    }

    const error = async (template: string, ...args: any[]) => {
        
        try
        {
            baseLogger.error(arguments);
            //await dbError(authProvider.authData, template, ...args);
        }
        catch (ex: unknown)
        {
            baseLogger.error("Failed log error", ex, arguments);
        }
    }

    return {
        baseLogger: baseLogger,
        setBaseLogger: setBaseLogger,
        info: info,
        warn: warn,
        error: error
    }
}

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useLogger().
export function LoggerProvider({ children }: any) {
    const logger = useProvideLogger();
    return <loggerContext.Provider value={logger}>{children}</loggerContext.Provider>
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useLogger = () => {
    return useContext(loggerContext);
};

export default useLogger;