import { Button, Form, FormProps, Input } from "antd";
import { nameof } from "../../../System/Utils";
import { IHasAuthToken } from "../../../Server/IHasAuthToken";
import useAuth from "../../../Hooks/useAuth";

export function TokenForm(props: FormProps<IHasAuthToken>) {
    const auth = useAuth();
    
    return (
        <Form
            {...props}
            name="form-token"
        >
             <Form.Item<IHasAuthToken>
                name={nameof<IHasAuthToken>("authToken")}
            >
                <Input 
                    placeholder="Токен"
                    disabled={auth.isLoging} 
                />
            </Form.Item>

            <Form.Item>
                <Button 
                    type="primary" 
                    htmlType="submit" 
                    className="authForm__login"
                    loading={auth.isLoging}
                >
                    Войти
                </Button>
            </Form.Item>
        </Form>     
    )
}