import { ScheduleBoardFilterLocale } from "..";

export const scheduleBoardFilterLangRu: ScheduleBoardFilterLocale = {
    title: 'Фильтр',
    projectGroup: 'Проектная группа',
    field: 'Офис',
    role: 'Роль',
    linearPerson: 'Линейный персонал',
    adminPerson: 'Админ персонал',
    tags: 'Теги',
    productiveActivityType: 'Типы активностей',
    forcastObj: 'Объекты прогнозирования',
    skills: 'Навыки',
    crossEmployee: 'Кросс сотрудники',
    timeZone: 'Временная зона',
    close: 'Закрыть',
    cancel: 'Отмена',
    save: 'Сохранить',
    reset: 'Сбросить',
}