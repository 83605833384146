import React from "react";
import { InvalidOperationException } from "../System/InvalidOperationException";

export enum MutationActionType {
    Start        = 'START_MUTATION',
    Mutate       = 'MUTATE',
    NoSideMutate = 'NO_SIDE_MUTATE',
    Rollback     = 'ROLLBACK_MUTATION',
    Commite      = 'COMMIT_MUTATION',
    Prolong      = 'COMMIT_MUTATION_MUTATE',
}

export interface IMutationAction<S> {
    readonly type: MutationActionType.Start | MutationActionType.Mutate | MutationActionType.Rollback | MutationActionType.Commite | MutationActionType.Prolong;
    readonly payload?: Partial<S>,
}

export interface INoSideMutationAction<S> {
    readonly type: MutationActionType.NoSideMutate,
    readonly payload?: Partial<S>,
    readonly completely?: boolean,
}

export interface IInternalMutationState<S> {
    ____MUTATION____STARTED____?: boolean,
    ____MUTATION____ROLLBACK___?: S
}

export type MutationAction<S> = IMutationAction<S> | INoSideMutationAction<S>
export type MutationReducer<S> = React.Reducer<S, MutationAction<S>>;
export function mutationReducer<S>(prevState: S & IInternalMutationState<S>, action: MutationAction<S>): S {

    if(action.type == MutationActionType.Start) {

        if(prevState.____MUTATION____STARTED____) {
            throw new InvalidOperationException('Mutation already started');
        }

        return {
            ...prevState,
            ...action.payload,
            ____MUTATION____STARTED____: true,
            ____MUTATION____ROLLBACK___: prevState,
        };
    }

    if(action.type == MutationActionType.Mutate) {

        if(!prevState.____MUTATION____STARTED____) {
            throw new InvalidOperationException(`Mutation not started, start mutation or use ${MutationActionType.NoSideMutate}`);
        }

        return {
            ...prevState,
            ...action.payload,
        }
    }

    if(action.type == MutationActionType.NoSideMutate) {

        if(prevState.____MUTATION____STARTED____) {

            if(action.completely) {

                return {
                    ...prevState,
                    ...action.payload,
                    ____MUTATION____ROLLBACK___: {
                        ...prevState.____MUTATION____ROLLBACK___,
                        ...action.payload,
                    }
                }
            }

            return {
                ...prevState,
                ____MUTATION____ROLLBACK___: {
                    ...prevState.____MUTATION____ROLLBACK___,
                    ...action.payload,
                }
            }
        }

        return {
            ...prevState,
            ...action.payload,
        }
    }

    if(action.type == MutationActionType.Rollback) {

        if(!prevState.____MUTATION____STARTED____) {
            throw new InvalidOperationException(`Mutation not started`);
        }

        if(!prevState.____MUTATION____ROLLBACK___) {
            throw new InvalidOperationException(`Mutation rollback state is undefined`);
        }

        return {
            ...prevState.____MUTATION____ROLLBACK___,
            ...action.payload
        }
    }

    if(action.type == MutationActionType.Commite) {

        return {
            ...prevState,
            ...action.payload,
            ____MUTATION____STARTED____: undefined,
            ____MUTATION____ROLLBACK___: undefined
        }
    }
    
    if(action.type == MutationActionType.Prolong) {

        if(!prevState.____MUTATION____STARTED____) {
            throw new InvalidOperationException(`Mutation not started`);
        }
        
        const newState = {
            ...prevState,
            ...action.payload,
            ____MUTATION____STARTED____: undefined,
            ____MUTATION____ROLLBACK___: undefined,
        }

        return {
            ...newState,
            ____MUTATION____STARTED____: true,
            ____MUTATION____ROLLBACK___: newState,
        };
    }

    return prevState;
}