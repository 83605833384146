import React, { CSSProperties, Ref, useCallback, useEffect, useRef, useState } from 'react';
import { ScheduleShiftCard } from '../ScheduleShiftCard';
import { filterList, templateShiftPatternList } from './helper';
import { Button, Empty, List, Skeleton, message } from 'antd';
import { useListProvider } from '../../ScheduleBoard/useListProvider';
import { IScheduleBoardFilter, IScheduleShiftScheme, PersonFilterType, RecordType, getScheduleShiftSchemeList } from '../../../Entities/Database';


import "./style.css";

interface IScheduleShiftListTemplateProps {
    loading: boolean,
    scheduleShiftList: readonly IScheduleShiftScheme[],
    activeCard?: IScheduleShiftScheme,
    onCardChange: (card: IScheduleShiftScheme) => void,
    style?: CSSProperties,
}

export function ScheduleShiftListTemplate({loading, scheduleShiftList, activeCard, onCardChange, style}: IScheduleShiftListTemplateProps) {

    if (loading) {

        return (
            <>
                <Skeleton 
                    active={true} 
                    style={{height: "100px"}} 
                    paragraph={ { rows: 5 }}
                    className="scheduleShift-skeleton"
                />
                <Skeleton 
                    active={true} 
                    style={{height: "100px"}} 
                    paragraph={ { rows: 5 }}
                    className="scheduleShift-skeleton"
                />
                <Skeleton 
                    active={true} 
                    style={{height: "100px"}} 
                    paragraph={ { rows: 5 }}
                    className="scheduleShift-skeleton"
                />
                <Skeleton 
                    active={true} 
                    style={{height: "100px"}} 
                    paragraph={ { rows: 5 }}
                    className="scheduleShift-skeleton"
                />
            </>
        )
    }

    if (!scheduleShiftList.length) {
        return (
            <Empty />
        )
    }

    return (
        <div
            style={{
                width: '100%',
                flex: 1,
                overflow: 'auto',
                ...style,
            }}
        >
            {scheduleShiftList.map(card => (
                <ScheduleShiftCard 
                    key={card.sss_id} 
                    cardInfo={card} 
                    activeCard={activeCard} 
                    onCardChange={onCardChange}
                    loading={loading}
                />
            ))}
        </div>
    );
}