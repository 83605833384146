import { Button, Input, Skeleton, Space, message } from "antd";
import { EditOutlined } from '@ant-design/icons';
import { memo, useState } from "react";
import { IEmployee, IWorkTimeInput, WorkTimeInputFormIds } from "../../../Entities/Database";
import { MainActivity } from "../MainActivity";
import { ScheduleShiftModal } from "../../ScheduleShiftScheme/ScheduleShiftModal";
import ScheduleShiftBreaksEditorModal from "../../ScheduleShiftBreaks/ScheduleShiftBreaksEditorModal";
import "./style.css";

export interface IInputItemProps {
    loading: boolean,
    onFinish: () => void,
    employee?: IEmployee,
    workTimeInput: IWorkTimeInput,
    onScheduleChange?: () => void,
};

function NotMemoizedInputItem(props: IInputItemProps) {
    
    const {loading, onFinish, employee, workTimeInput, onScheduleChange} = props;
    const {form_id, content, title, canEdit} = props.workTimeInput;

    const [openEditModal, setOpenEditModal] = useState(false);

    const handleOpenModal = (e: React.BaseSyntheticEvent) => {
        e.preventDefault();
        setOpenEditModal(true);
    };

    const handleCloseModal = () => {
        setOpenEditModal(false);
    };

    const handleFinishSave = () => {
        
        message.success('Данные сохранены');
        setOpenEditModal(false);

        if (onFinish) {
            onFinish();
        }
    }

    return (
        <> 
            {
                loading ? 
                <Skeleton 
                    active={true}
                    className="employee-skeleton"
                /> : 
                <>
                    <p style={{margin: "0 0 5px 0"}}>{title}</p> 
                    <Space.Compact style={{width: "100%"}}>
                        <Input 
                            defaultValue={content}
                            readOnly={true}
                            style={{marginBottom: "12px"}}
                        />
                        {canEdit && 
                        <Button 
                            onClick={handleOpenModal}
                        >
                            <EditOutlined />
                        </Button>} 
                    </Space.Compact>
                    {openEditModal && form_id === WorkTimeInputFormIds.MainActivity && 
                    <MainActivity  
                        isOpen={openEditModal}
                        onFinish={handleFinishSave} 
                        data={workTimeInput}
                        employees={employee ? [employee] : []}
                        onClose={handleCloseModal}
                        onScheduleChange={onScheduleChange}
                    />}
                    {openEditModal && form_id === WorkTimeInputFormIds.WorkShiftScheme &&
                    <ScheduleShiftModal
                        open={openEditModal}
                        onFinishBind={handleFinishSave}
                        onCancel={handleCloseModal}
                        employee={employee}
                        defaultScheme={workTimeInput.id}
                    />}
                    {openEditModal && form_id === WorkTimeInputFormIds.WorkBreakScheme &&
                    <ScheduleShiftBreaksEditorModal
                        open={openEditModal}
                        employee={employee}
                        onFinishSave={handleFinishSave}
                        onCancel={handleCloseModal}
                    />}
                </>
            }
        </>
    )
}
export const InputItem = memo(NotMemoizedInputItem);