import moment from "moment";
import { Theme } from ".";
import { classNames } from "../../System/ClassNames";
import { colorToStyle } from "../../System/Utils";
import { getTextColor } from "../shared/domHelpers";
import { Button, Popover } from "antd";
import { CommentOutlined } from "@ant-design/icons";
import './list-item.css';

export interface AgendaEvent {
    id: number | string,
    start: Date,
    end: Date,
    text?: string | null,
    backgroundColor?: number | null,
    markColor?: number | null,
    comment?: string | null,
}

export interface IAgendaListItemProps extends React.HTMLAttributes<HTMLDivElement> {
    event: AgendaEvent,
    theme?: Theme,
    rtl?: boolean,
}

export function AgendaListItem({
    event,
    theme,
    rtl,
    ...other
}: IAgendaListItemProps) {
    
    const themeClassName = `mbsc-${theme}`;
    const rtlClassName = "mbsc-" + (rtl ? "rtl" : "ltr");
    const hb = themeClassName === "mbsc-ios" ? "mbsc-hb" : "";

    const startText = event.start ? moment(event.start).format("HH:mm") : undefined;
    const endText = event.end ? moment(event.end).format("HH:mm") : undefined;
    const tooltip = event.text + ", " + startText + " - " + endText;
    const markColor = colorToStyle(event.markColor);
    const backgroundColor = colorToStyle(event.backgroundColor);
    const duration = moment().startOf('day').milliseconds(event.end.valueOf() - event.start.valueOf());
    const colorOrMark = markColor ? getTextColor(markColor) : undefined;

    return (
        <div
            {...other}
            className={classNames(
                {"has-marker": markColor},
                "mbsc-event",
                "mbsc-colored-event",
                "mbsc-list-item",
                themeClassName,
                hb,
                rtlClassName,
            )}
            style={{
                background: markColor
            }}
        >
            <div
                className={classNames("mbsc-event-color", themeClassName, rtlClassName)}
                style={{
                    background: backgroundColor,
                    color: colorOrMark
                }}
            />
            <div className={classNames("mbsc-event-content", themeClassName)}>
                <div
                    className={classNames("mbsc-event-text", themeClassName)}
                    title={tooltip}
                    style={{
                        color: colorOrMark
                    }}
                >
                    {event.text}
                </div>
                {event.start && event.end &&
                <div
                    className={classNames("mbsc-event-duration", themeClassName)}
                    style={{
                        color: colorOrMark
                    }}
                >
                    {duration.format("HH:mm")}
                </div>}
            </div>
            {event.comment && <div
                className={classNames("mbsc-event-comment", themeClassName, rtlClassName)}
            >
                <Popover
                    placement="leftBottom"
                    content={event.comment}
                    title="Комментарий"
                    trigger="hover"
                >
                    <Button
                        type="text"
                        icon={<CommentOutlined/>}
                        style={{
                            color: colorOrMark
                        }}
                    />
                </Popover>
            </div>}
            <div className={classNames("mbsc-event-time", themeClassName, rtlClassName)}>
                {/*allDayText && <div className={classNames("mbsc-event-all-day", themeClassName)}>{allDayText}</div>*/}
                {/*lastDay && <div className={classNames("mbsc-event-until", themeClassName)}>{lastDay}</div>*/}
                {event.start && <div className={classNames("mbsc-event-start", themeClassName)}>{startText}</div>}
                {event.start && event.end && <div className={classNames("mbsc-event-sep", themeClassName)}>-</div>}
                {event.end && <div className={classNames("mbsc-event-end", themeClassName)}>{endText}</div>}
            </div>
        </div>
    )
}