import { DependencyList, useMemo, useRef, useState } from "react";

export function shallowEquals(prev: DependencyList | undefined, next: DependencyList | undefined) {

    if (prev === undefined || next === undefined) {
       return false;
    }

    for (const key in next) {

        if (next[key] !== prev[key]) {

            return false;
        }
    }

    return true;
}

export function useBuffer<T, D extends Array<unknown>>(
    factory: (prevValue: Readonly<T>, prevDeps: Readonly<D>) => T,
    deps?: D,
    update?: boolean
): T {

    const [initDeps] = useState<D>(deps || [] as unknown as D);
    const previousDeps = useRef<D | undefined>(initDeps);
    const buffer = useRef<T>(undefined as unknown as T);
    
    if((update && !shallowEquals(previousDeps.current, deps))
    || previousDeps.current === initDeps) {

        const prevDeps = previousDeps.current || initDeps;
        const prevValue = buffer.current;
        
        previousDeps.current = deps;
        buffer.current = factory(prevValue, prevDeps);
    }

    return buffer.current;
}