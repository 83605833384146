import { ITicket } from "../../Entities/Database";
import { loadListReducer, ILoadListState, LoadListReducerAction } from "../../Reducer/LoadListReducer";

export type UPDATE_TICKET = 'UPDATE_TICKET';

export enum CommentsReducerActionType {
    UpdateTicket = 'UPDATE_TICKET'
}

export interface ITicketsState extends ILoadListState<ITicket> { }
export type UpdateTicketAction = {
    type: UPDATE_TICKET,
    payload: Partial<ITicket> & {
        id: ITicket['id'],
    }
}

export type TicketsReducerAction = LoadListReducerAction<ITicket> | UpdateTicketAction

export function ticketsReducer(prevState: ITicketsState, action: TicketsReducerAction): ITicketsState {

    if (action.type === 'UPDATE_TICKET') {

        const ticket = action.payload;
        const newItems = [...prevState.items];
        const tiketIndex = newItems.findIndex(x => x.id === ticket.id);

        if (tiketIndex === -1) {

            throw new Error("Ticket not exists", {
                cause: action
            });

        }
        
        const prevTicket = newItems[tiketIndex];
        newItems[tiketIndex] = {
            ...prevTicket,
            ...action.payload
        };

        return {
            ...prevState,
            items: newItems
        }
    }

    return loadListReducer(prevState, action);
}

export type ITicketsReducer = typeof ticketsReducer;
export default ticketsReducer;