import { Alert, Divider, Form, Modal, Select, message } from "antd";
import DraggableModal from "../../DraggableModal";
import { LinearBreaksListEditor } from "../LinearBreakListEditor";
import { useEffect, useMemo, useRef, useState } from "react";
import useAuth from "../../../Hooks/useAuth";
import { TBreak, IEmployee, IScheduleWorkTime, ISurfaceBreak, ITemplatedBreak, getTemplatedBreaks } from "../../../Entities/Database";
import { doOrAbort } from "../../../System/doOrAbort";
import { IGroup, groupBy } from "../../../System/Utils";
import { getBreakId } from "../ScheduleShiftBreaksEditorModal/helpers";
import { DefaultOptionType } from "antd/lib/select";

import "./style.css";

interface IAutoCompleteModalProps {
    open?: boolean,
    surfaceBreaks?: ISurfaceBreak[],
    onApply?: (breaks: TBreak[]) => Promise<void> | void,
    onCancel?: () => void,
    employee?: IEmployee,
    selectedTime?: IScheduleWorkTime,
}

const abortByChange = {};
const abortByClose  = {};

export function AutoCompleteModal({
    open,
    onApply,
    onCancel,
    employee,
    selectedTime,
    surfaceBreaks,
}: IAutoCompleteModalProps) {

    const destructorAcRef = useRef<AbortController>();
    const loaderAcRef = useRef<AbortController>();

    const auth = useAuth();
    const [loading, setLoading] = useState(false);
    const [templatesBreaks, setTemplatesBreaks] = useState<ITemplatedBreak[]>();
    const [template, setTemplate] = useState<ITemplatedBreak['title']>();
    const [templates, breaks] = useMemo((): [DefaultOptionType[], IGroup<TBreak>] => {

        if (!selectedTime ||
            !templatesBreaks ||
            loading) {
            return [[], {}];
        }

        const dayId = selectedTime.dayId;
        const breaks = templatesBreaks.map((x): TBreak & ITemplatedBreak => ({
            id: getBreakId(),
            dayId,
            leftOffset: null,
            rightOffset: null,
            ...x,
        }));

        const groupedBreaks = groupBy(breaks, "title");
        const options = Object.keys(groupedBreaks).map((x) => ({
            label: x,
            value: x,
        }));

        return [options, groupedBreaks];

    }, [loading, templatesBreaks, selectedTime]);

    const templateBreaks = template ? breaks[template] : undefined;

    const handleChangeTemplate = (value: any) => {
        setTemplate(value);
    }

    const abortPrevReq = (reason: unknown) => {

        if (loaderAcRef.current) {
            loaderAcRef.current.abort(reason);
        }
    }

    const loadTemplatesBreaks = async (signal: AbortSignal, employee: IEmployee, selectedTime: IScheduleWorkTime) => {

        try {
            setLoading(true);
            setTemplate(undefined);
            setTemplatesBreaks([]);

            const list = await doOrAbort(
                signal => getTemplatedBreaks(auth, employee.PID, selectedTime.dayId, signal),
                signal,
                destructorAcRef.current?.signal
            );
            const normalizeList = list.map(x => ({
                id: getBreakId(),
                ...x
            }));
            const defaultTemplate = normalizeList.length > 0 ? normalizeList[0].title : undefined;

            setTemplate(defaultTemplate);
            setTemplatesBreaks(normalizeList);
            setLoading(false);
        }
        catch(ex) {

            if (ex === abortByChange || ex === abortByClose) {
                return;
            }

            message.error("При загрузке рабочего времени возникла ошибка.");
            setLoading(false);
        }
    }

    const handleApplyTemplate = () => {

        if (!templateBreaks) {
            message.error("Не выбран шаблон");
            return;
        }

        if (onApply) {
            onApply(templateBreaks);
        }
    }

    useEffect(() => {

        if(open) {

            const ac = loaderAcRef.current = new AbortController();
            const signal = ac.signal;
            
            if (employee !== undefined && selectedTime !== undefined) {
                loadTemplatesBreaks(signal, employee, selectedTime);
            }

            return () => {
                abortPrevReq(abortByChange);
            }
        }

    }, [auth.data?.staticId, selectedTime, open]);

    return (
        <>
            <DraggableModal
                open={open}
                onCancel={onCancel}
                onOk={handleApplyTemplate}
                title="Автозаполнение перерывов рабочего времени"
                className="templateBreaks__modal"
                width={361}
                okButtonProps={{ disabled: loading || templates.length === 0 }}
            >
                {!loading && templates.length === 0 && <Alert type="info" message="Нет доступных вариантов автозаполнения" />}
                {(loading || templates.length > 0) &&
                <>
                    <Select
                        loading={loading}
                        disabled={loading}
                        onChange={handleChangeTemplate}
                        options={templates}
                        value={template}
                        className="templateBreaks__select"
                    />
                    <LinearBreaksListEditor 
                        loading={loading}
                        breaks={templateBreaks}
                        surfaceBreaks={surfaceBreaks}
                        sortable={false}
                    />
                </>}
            </DraggableModal>
        </>
    )
}