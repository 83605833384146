import { useEffect, useMemo, useRef } from "react";
import { TBreak, IScheduleWorkTime } from "../../../Entities/Database";
import { IWorkTimeEvent, convertBreaksToColors, convertTimesToEvents, defaultDate, inOneGroup, maxDate, minDate } from "./helpers";
import { MbscEventClickEvent, MbscEventcalendarView, MbscLocale, Eventcalendar, localeRu as mbLocaleRu } from "../../shared/lib";
import "./style.css";
import { addMinMax } from "../../shared/libHelpers";
import { IValidateProps } from "../../shared/lib/src/core/util/datetime";

export interface ISegment {
    
}

export interface IScheduleShiftSegmentListLocale {
    editorLocale: MbscLocale,
    workTime: string,
    weekDaysShortName: string[],
    weekShort: string,
    week: string,
    shortDay: string,
}

export const localeRu: IScheduleShiftSegmentListLocale = {
    editorLocale: mbLocaleRu,
    workTime: 'Рабочее время',
    weekDaysShortName: ['Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб', 'Вс'],
    weekShort: 'Нд', 
    week: 'Неделя',
    shortDay: 'Д',
}

export interface IScheduleTemplateWorkTimeListProps {
    locale?: IScheduleShiftSegmentListLocale,
    loading?: boolean,
    times?: IScheduleWorkTime[],
    selectedTime?: IScheduleWorkTime,
    breaks?: TBreak[],
    selectedBreak?: TBreak,
    loadingBreaks?: boolean,
    onClickWorkTime?: (time: IScheduleWorkTime | undefined) => void,
    onDoubleClickWorkTime?: (time: IScheduleWorkTime | undefined) => void,
}

export function ScheduleTemplateWorkTimeList({
    locale = localeRu,
    loading,
    loadingBreaks,
    times,
    breaks,
    selectedTime,
    selectedBreak,
    onClickWorkTime,
    onDoubleClickWorkTime,
}: IScheduleTemplateWorkTimeListProps) {

    const editorRef = useRef<Eventcalendar>(null);

    const timeStep = 60;
    const dragTimeStep = 5;

    const view = useMemo<MbscEventcalendarView>(() => ({
        timeline: {
            size: 2,
            type: 'day',
            startDay: 1,
            endDay: 0,
            timeCellStep: timeStep,
            timeLabelStep: timeStep,
            resolutionVertical: 'day',
            startTime: '00:00',
            endTime: '00:00',
        }
    }), [timeStep]);

    const [resources, events] = useMemo(() => convertTimesToEvents(locale, times || [], selectedTime), [locale, times, selectedTime]);
    const colors = useMemo(() => convertBreaksToColors(locale, events, breaks || [], selectedBreak), [locale, events, breaks, selectedBreak]);
    const selectedEvents = useMemo(() => {

        if (!selectedTime) {
            return undefined;
        }

        return events.filter(x => inOneGroup(x.originalWorkTime, selectedTime));

    }, [selectedTime, events]);

    const handleEventClick = (event: MbscEventClickEvent) => {

        if (onClickWorkTime) {
            const workTime = (event.event as IWorkTimeEvent).originalWorkTime;
            onClickWorkTime(workTime);
        }
    }

    const handleEventDoubleClick = (event: MbscEventClickEvent) => {

        if (onDoubleClickWorkTime) {
            const workTime = (event.event as IWorkTimeEvent).originalWorkTime;
            onDoubleClickWorkTime(workTime);
        }
    }

    useEffect(() => {

        const sortedStarts = events.map(x => x.start).sort((x, y) => +(x || 0) - +(y || 0));

        if (sortedStarts && sortedStarts.length > 0) {

            const firstStart = sortedStarts[0] || 0;
            const navigate = new Date(+firstStart - (60 * 60 * 1000));
            
            editorRef.current?.navigate(navigate);
        }

    }, [events]);

    const normalizeInvalids = useMemo(() => {
        const tmpInvalids: IValidateProps[] = [];
        addMinMax(tmpInvalids, minDate, maxDate);
        return tmpInvalids;
    }, [minDate, maxDate]);

    return (
        <Eventcalendar
            theme="ios"
            themeVariant="light"
            selectMultipleEvents={true}
            showControls={false}
            showOuterDays={false}
            clickToCreate={false}
            dragToMove={false}
            eventDelete={false}
            dragToResize={false}
            dragToCreate={false}
            ref={editorRef}
            refDate={defaultDate}
            locale={locale.editorLocale}
            view={view}
            resources={resources}
            colors={colors}
            invalid={normalizeInvalids}
            data={events}
            selectedEvents={selectedEvents}
            wrapperClass="ap-shifts-view-wrapper"
            className="ap-shifts-view"
            width='100%'
            height='100%'
            //min={minDate}
            //max={maxDate}
            dragTimeStep={dragTimeStep}
            onEventClick={handleEventClick}
            onEventDoubleClick={handleEventDoubleClick}
        />
    );
}

