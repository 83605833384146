import { Button, Col, Divider, Progress, Row } from "antd";
import DraggableModal from "../DraggableModal";
import { useEffect, useMemo, useState } from "react";
import { isEmptyStrOrNullOrUndefined } from "../../System/Utils";
import useAuth from "../../Hooks/useAuth";
import { SkillList } from "./skilllist";
import { useListLoader } from "../../Hooks/useListLoader";
import { getSkills } from "../../Entities/Database";
import { IShiftTradeResource } from "../../Entities/DataBasePortal";
import { localeRu } from "./locale/ru";

import "./style.css";

export interface IEmployeeSkillsModalLocale {
    modaltitle: string,
    btnCloseTitle: string,
    skillsTitle: string,
}

export interface IShiftTrade {
    from: IShiftTradeResource,
    to: IShiftTradeResource,
}

export interface IEmployeeSkillsModalProps {
    open?: boolean,
    onCancel?: () => void,
    trade?: IShiftTrade,
    locale?: IEmployeeSkillsModalLocale
}
export function EmployeeSkillsModal({
    open,
    onCancel,
    trade,
    locale = localeRu

}: IEmployeeSkillsModalProps) {

    const auth = useAuth();

    const [leftSkills, reloadLeftSkills] = useListLoader(async (signal) => {

        if (trade) {
            return await getSkills(auth, trade.from.PID, signal);
        }

        return [];

    }, [auth.data?.staticId, trade], {initialState: {loading: true}});

    const [rightSkills, reloadRightSkills] = useListLoader(async (signal) => {

        if (trade) {
            return await getSkills(auth, trade.to.PID, signal);
        }

        return [];

    }, [auth.data?.staticId, trade], {initialState: {loading: true}});

    const correspondence = useMemo(() => {

        const active = leftSkills.items.filter(x => x.isActive);
        const total = active.length;
        const count = active.filter(x => rightSkills.items.findIndex(y => y.isActive && x.id === y.id) !== -1).length;

        return Math.round((count / total) * 100);

    }, [leftSkills.items, rightSkills.items]);

    useEffect(() => {

        if (open) {

            reloadLeftSkills();
            reloadRightSkills();
        }

    }, [open]);

    return (
        <DraggableModal
            closable={false}
            width={690}
            destroyOnClose
            title={locale.modaltitle}
            open={open}
            footer={[
                <Button key="back" onClick={onCancel}>
                    {locale.btnCloseTitle}
                </Button>, 
            ]}
        >
            <Row>
                {trade &&
                <>
                    <Col span={11}>
                        <p className="skills-title">
                            {locale.skillsTitle}
                            <span>{trade.from.caption1}</span>
                        </p>
                        <Divider style={{marginTop: 0}} />
                        <SkillList itemsState={leftSkills} />
                    </Col>
                    <Col span={2}>
                        <div className="divider-vertial"></div>
                    </Col>
                    <Col span={11}>
                        <p className="skills-title">
                            {locale.skillsTitle}
                            <span>{trade.to.caption1}</span>
                        </p>
                        <Divider style={{marginTop: 0}} />
                        <SkillList itemsState={rightSkills} />
                    </Col>
                    <Col span={24}>
                        <Divider />
                        <Progress percent={correspondence} />
                    </Col>
                </>}
            </Row>
        </DraggableModal>
    );
}